import { FetchedMeeting } from "@api/meetings"
import { meetingLocationString } from "@components/shared/config"
import { AdminBriefing, PublicBriefing } from "@interfaces/briefing"

export const publicBriefingRows = (briefing: PublicBriefing) => {
  return {
    id: briefing.id,
    meeting: {
      link: `/meetings/${briefing.meeting.id}#${briefing.is_personalized ? "personal-briefing" : "briefing"}`,
      title: briefing.meeting.title,
    },
    date: briefing.meeting.published_at,
    channel: {
      link: `/channel-details/${briefing.meeting.organization_id}`,
      name: briefing.meeting.organization_name,
    },
    location: meetingLocationString(briefing.meeting as FetchedMeeting),
    type: briefing.name,
    owner: briefing.user_email,
    created_at: briefing.created_at,
    actions: briefing,
  }
}

export const adminBriefingRows = (briefing: AdminBriefing) => {
  return {
    ...publicBriefingRows(briefing),
    use_fine_tuning: briefing.use_fine_tuning,
    generated_by_model: briefing.generated_by_model,
  }
}
