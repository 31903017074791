import { RubyObject } from "./default"

export enum PageOfOrigin {
  Dashboard = 0,
  MeetingDetail = 1,
  TryItNow = 2,
  FindALeadList = 3,
  FindALeadDetail = 4,
}

export interface SearchLog extends RubyObject {
  id: number
  data: string
  page_of_origin: PageOfOrigin
  user_id: number
  campaign_id: number
  meeting_id: number
  discarded_at: string
}

export interface SanitizedSearchLog {
  searchTerms: string[]
}
