import { styled } from "@mui/material/styles"

export const styledGroupTitles = styled("ul")(({ theme }) => ({
  "& .MuiListSubheader-root": {
    ...theme.typography.h6,
    paddingTop: 2,
    paddingBottom: 2,
    fontWeight: 700,
  },
}))
