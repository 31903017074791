import { BasicGeoType, StateGeoHash, StateChannelHash, BasicOrgType } from "./interface"

export const sortGeographyByState = (
  geoList: BasicGeoType[] | undefined,
  type: string,
  statesList: BasicGeoType[] | undefined,
) => {
  if (!geoList || !statesList) {
    return []
  }
  if (type === "states") {
    return geoList
  }
  const stateHash: StateGeoHash = {} as StateGeoHash
  geoList.forEach((geo: BasicGeoType) => {
    if (geo?.state_name) {
      stateHash[geo?.state_name]
        ? stateHash[geo?.state_name].push(geo)
        : (stateHash[geo?.state_name] = [geo] as BasicGeoType[])
    }
  })
  let sortedGeos: BasicGeoType[] = [] as BasicGeoType[]

  statesList.forEach((state: BasicGeoType) => {
    if (stateHash[state.name] !== undefined && stateHash[state.name].length !== 0) {
      sortedGeos = sortedGeos.concat(stateHash[state.name])
    }
  })

  return sortedGeos
}

export const sortChannelByState = (channelList: BasicOrgType[] | undefined, statesList: BasicGeoType[] | undefined) => {
  if (!channelList || !statesList) {
    return []
  }

  const stateHash: StateChannelHash = {} as StateChannelHash

  channelList.forEach((channel) => {
    if (channel?.state) {
      stateHash[channel?.state.name]
        ? stateHash[channel?.state.name].push(channel)
        : (stateHash[channel?.state.name] = [channel] as BasicOrgType[])
    }
  })

  let sortedChannels: BasicOrgType[] = [] as BasicOrgType[]

  statesList.forEach((state: BasicGeoType) => {
    if (stateHash[state.name] !== undefined && stateHash[state.name].length !== 0) {
      sortedChannels = sortedChannels.concat(stateHash[state.name])
    }
  })

  return sortedChannels
}
