import { Autocomplete, Skeleton, SxProps, TextField, TextFieldProps, Theme, Typography } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { IChannelType } from "../../../../../api/interfaces"
import { useFetchOrganizations } from "../../../../../api/organizations"
import { FilterParamsController } from "../../../../../config/homeConfig"
import { FilterParams } from "../../../../Home/interface"

interface Lookup {
  [key: number]: string
}

export const TypeSelectorMultiple: FC<{
  filterParams: FilterParams
  setFilterParams: (filterParams: FilterParams) => void
  sx?: SxProps<Theme>
  textFieldProps?: TextFieldProps
  size?: "medium" | "small"
}> = ({ filterParams, setFilterParams, sx, textFieldProps, size }) => {
  const { data: organizationsData } = useFetchOrganizations()
  const [open, setIsOpen] = useState(false)

  const options: IChannelType[] = useMemo(() => {
    const options: IChannelType[] = []
    if (organizationsData?.organizations) {
      const typeLookup: Lookup = {}
      organizationsData.organizations.forEach((org) => {
        if (!filterParams["states"].includes(org?.state?.id || -1) && filterParams["states"].length !== 0) {
          return
        } else if (
          filterParams["organizations"].length !== 0 &&
          !filterParams["organizations"].includes(org?.id || -1)
        ) {
          return
        } else if (filterParams["cities"].length !== 0 && filterParams["counties"].length !== 0) {
          if (
            !filterParams["cities"].includes(org?.city?.id || -1) &&
            !filterParams["counties"].includes(org?.county?.id || -1)
          ) {
            return
          }
        } else if (filterParams["cities"].length !== 0 && !filterParams["cities"].includes(org?.city?.id || -1)) {
          return
        } else if (filterParams["counties"].length !== 0 && !filterParams["counties"].includes(org?.county?.id || -1)) {
          return
        }

        if (org.type) {
          typeLookup[org.type.id] = org.type.name
        }
      })
      Object.entries(typeLookup).forEach((type) => {
        const channelType: IChannelType = { id: parseInt(type[0]), name: type[1].toString() }
        options.push(channelType)
      })

      return options.sort((type1, type2) => {
        if (type1.name.toLowerCase() === "other" || type2.name.toLowerCase() === "other") {
          return 0
        } else if (type1.name < type2.name) {
          return -1
        } else if (type1.name > type2.name) {
          return 1
        }
        return 0
      })
    }

    return options
  }, [organizationsData, filterParams])

  const values: IChannelType[] = useMemo(() => {
    if (!options) {
      return []
    }

    const selections = [] as IChannelType[]
    if (filterParams["channel_types"]) {
      filterParams["channel_types"].forEach((typeId) => {
        const type = options.find((channelType) => channelType.id === typeId)
        if (type) {
          selections.push(type)
        }
      })

      return selections
    }

    return selections
  }, [filterParams, organizationsData])

  if (organizationsData) {
    return (
      <Autocomplete
        multiple
        noOptionsText="Nothing to display"
        disableCloseOnSelect
        open={open}
        id={"type-select"}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(true)}
        options={options}
        value={values || []}
        renderTags={() => (
          <Typography
            onClick={() => setIsOpen(!open)}
            sx={{ maxWidth: { xs: "85%", sm: "55%", md: "80%", lg: "75%" } }}
            noWrap
          >
            {values
              .map((item) => item?.name)
              .join(", ")
              .replace(/,\s*$/, "")}
          </Typography>
        )}
        onChange={(_event, newValue) => {
          let temp = { ...filterParams }
          const filterParamsController: FilterParamsController = new FilterParamsController(
            "channel_types",
            temp,
            newValue.map((type) => type?.id),
            options,
            organizationsData,
            options,
          )
          temp = filterParamsController.handleTypeSelection()

          setFilterParams(temp)
        }}
        renderInput={(params) => <TextField {...params} label={"Channel Type"} {...textFieldProps} />}
        getOptionLabel={(option: IChannelType | null) => option?.name || "None"}
        renderOption={(props, option) => (
          <li {...props} key={option?.id}>
            <Typography variant="body1">{option?.name}</Typography>
          </li>
        )}
        sx={{
          ".MuiOutlinedInput-root": {
            height: 60,
          },
          ...sx,
        }}
        size={size}
      />
    )
  } else {
    return <Skeleton height={56} variant="rectangular" />
  }
}
