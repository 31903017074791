import { Dispatch, FC, SetStateAction, createContext, useState } from "react"
import { BaseOrganization, OrganizationForm } from "@interfaces/organization"
import { generateSimpleActions } from "../Wizard/Context"
import { GeneralScraperDetails } from "@api/interfaces"

export interface IdentifierAction {
  identifierAction: string
  identifierActionSelection: string
  value?: string
}

export const newArchiveDetails: GeneralScraperDetails = {
  actions: generateSimpleActions("", 0),
  navigation_method: "uri",
  additional_urls: [],
  meeting_identifier: "",
  meeting_identifier_regex: false,
  iframe: false,
  head: false,
  identifier_actions: [
    {
      identifier_action: "",
      identifier_action_selection: "",
    },
  ],
  meeting_browser_wait_code: "",
  json_code: "",
}

export const OrganizationFormContext = createContext<{
  value: OrganizationForm
  setValue: Dispatch<SetStateAction<OrganizationForm>>
}>({
  value: {
    id: 0,
    name: "",
    videoArchiveId: "",
    cityId: undefined,
    countyId: undefined,
    stateId: undefined,
    homePageUrl: "",
    contactPageUrl: "",
    youtubeChannelId: undefined,
    automaticUpdate: false,
    granicusAgendaUrl: undefined,
    spamEnabled: true,
    maxDaysBetweenMeetings: undefined,
    discardedAt: undefined,
    proxyDownload: false,
    customConfig: "",
    shuffleRegion: false,
    videoArchiveDetails: "",
    videoArchiveType: "",
    immediateRetrieval: false,
    infiniteLoopback: false,
    useM4a: false,
    downloadServiceType: "",
    channelTypeId: 0,
  },
  setValue: () => {
    /* placeholder for Typescript */
  },
})

export const GeneralScraperDetailsContext = createContext<{
  value: GeneralScraperDetails | string
  setValue: Dispatch<SetStateAction<GeneralScraperDetails | string>>
}>({
  value: {
    actions: generateSimpleActions("", 0),
    navigation_method: "uri",
    additional_urls: [],
    meeting_identifier: "",
    meeting_identifier_regex: false,
    iframe: false,
    head: false,
    identifier_actions: [
      {
        identifier_action: "",
        identifier_action_selection: "",
        identifier_action_value: "",
      },
    ],
    meeting_browser_wait_code: "",
    json_code: "",
  },
  setValue: () => {
    /* placeholder for Typescript */
  },
})

export const OrganizationFormWrapper: FC<{
  organization: BaseOrganization
}> = ({ organization, children }) => {
  const [orgValue, setOrgValue] = useState<OrganizationForm>({
    id: organization.id,
    name: organization.name || "",
    videoArchiveId: organization.video_archive_id || "",
    homePageUrl: organization.home_page_url || "",
    contactPageUrl: organization.contact_page_url || "",
    automaticUpdate: organization.automatic_update,
    spamEnabled: organization.spam_enabled,
    proxyDownload: organization.proxy_download,
    customConfig: organization.custom_config,
    shuffleRegion: organization.shuffle_region,
    videoArchiveDetails: organization.video_archive_details,
    videoArchiveType: organization.video_archive_type || "",
    immediateRetrieval: organization.immediate_retrieval,
    infiniteLoopback: organization.infinite_loopback,
    useM4a: organization.use_m4a,
    downloadServiceType: organization.download_service,
    channelTypeId: organization.channel_type_id,
  })

  const archiveDetails = organization.video_archive_details

  const [videoArchiveDetails, setVideoArchiveDetails] = useState<GeneralScraperDetails | string>(
    typeof archiveDetails === "string" || archiveDetails === null
      ? archiveDetails
      : {
          actions: archiveDetails.actions,
          navigation_method: archiveDetails.navigation_method,
          additional_urls: archiveDetails.additional_urls,
          meeting_identifier: archiveDetails.meeting_identifier,
          meeting_identifier_regex: archiveDetails.meeting_identifier_regex,
          iframe: archiveDetails.iframe,
          head: archiveDetails.head,
          identifier_actions: archiveDetails.identifier_actions || [
            {
              identifier_action: "",
              identifier_action_selection: "",
              identifier_action_value: "",
            },
          ],
          meeting_browser_wait_code: archiveDetails.meeting_browser_wait_code,
          json_code: archiveDetails.json_code,
        },
  )

  return (
    <OrganizationFormContext.Provider value={{ value: orgValue, setValue: setOrgValue }}>
      <GeneralScraperDetailsContext.Provider value={{ value: videoArchiveDetails, setValue: setVideoArchiveDetails }}>
        {children}
      </GeneralScraperDetailsContext.Provider>
    </OrganizationFormContext.Provider>
  )
}
