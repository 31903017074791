import { Card, CardContent, Grid, Typography } from "@mui/material"
import moment from "moment"
import { FC, useContext, useMemo } from "react"
import { ChannelDetailContext } from "../../containers/ChannelDetailContainer"

export const ChannelInfoCard: FC = () => {
  const { organization } = useContext(ChannelDetailContext)

  const publishedDate = useMemo(() => {
    let date = "N/A"
    if (organization && organization.most_recent_finished_published_at) {
      date = moment(organization.most_recent_finished_published_at).format("MMM Do YYYY")
    }
    return date
  }, [organization])

  const population = organization?.city
    ? organization.city.population
    : organization?.county
      ? organization.county.population
      : ""

  return (
    <Card>
      <CardContent sx={{ height: 407 }}>
        <Typography variant="h3">Channel Info</Typography>
        <Grid container direction="row" marginTop={0} marginBottom={4} rowSpacing={4} paddingLeft={5} paddingRight={5}>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography variant="h5">State</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography>{organization?.state?.name}</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography variant="h5">County</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography>{organization?.county?.name}</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography variant="h5">City</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography>{organization?.city?.name}</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography variant="h5">Population</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography>{population}</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography variant="h5">Channel Type</Typography>
          </Grid>
          <Grid item xs={6} borderBottom="1px solid #878C90">
            <Typography>{organization?.channel_type}</Typography>
          </Grid>
        </Grid>
        <Typography textAlign="center">Last Meeting Published:</Typography>
        <Typography textAlign="center" variant="h6">
          {publishedDate}
        </Typography>
      </CardContent>
    </Card>
  )
}
