import { AttributeFilters, CommonUrlParams, DateFilters, TableFilters } from "./requestParams"
import { format } from "date-fns"

export class QueryParamsBuilder {
  queryParams

  constructor() {
    this.queryParams = new URLSearchParams()
  }

  build(commonParams: CommonUrlParams | undefined): string {
    if (!commonParams) return ""

    let queryParams = this.buildDateParams(this.queryParams, commonParams.dateFilters)
    queryParams = this.buildTableParams(queryParams, commonParams.tableParams)
    queryParams = this.buildFieldFilters(queryParams, commonParams.fields)
    queryParams = this.buildAttributeFilters(queryParams, commonParams.attributeFilters)
    this.queryParams = queryParams
    return this.queryParams.toString().length === 0 ? "" : `?${this.queryParams.toString()}`
  }

  toQueryKey(resourceName: string): string[] {
    const queryKey = [resourceName]
    for (const [key, value] of this.queryParams.entries()) {
      queryKey.push(`${key}=${value}`)
    }
    return queryKey
  }

  buildDateParams(queryParams: URLSearchParams, dateFilters: DateFilters[] | undefined): URLSearchParams {
    dateFilters?.forEach((dateParam) => {
      const dateUtc = new Date(dateParam.dateRange[0].toUTCString())
      let endRangeUtc
      if (dateParam.dateRange[1]) {
        endRangeUtc = new Date(dateParam.dateRange[1].toUTCString())
      }
      switch (dateParam.filterBy) {
        case "after_or_on": // inclusive
          queryParams.append(dateParam.attribute, `after(${this.dateToProxyProviderFormat(dateUtc)})`)
          break
        case "before_or_on": // inclusive
          queryParams.append(dateParam.attribute, `before(${this.dateToProxyProviderFormat(dateUtc)})`)
          break
        case "in_range": // inclusive
          if (!endRangeUtc) {
            throw new Error("Invalid date filter")
          }
          queryParams.append(
            dateParam.attribute,
            `[${this.dateToProxyProviderFormat(dateUtc)}, ${this.dateToProxyProviderFormat(endRangeUtc)}]`,
          )
          break
        case "on_day":
          queryParams.append(dateParam.attribute, `${this.dateToProxyProviderFormat(dateUtc)}`)
          break
        default:
          throw new Error("Invalid date filter")
      }
    })

    return queryParams
  }

  buildTableParams(queryParams: URLSearchParams, tableFilters: TableFilters | undefined) {
    if (!tableFilters) return queryParams
    queryParams.append("size", tableFilters.size.toString())
    queryParams.append("page", tableFilters.page.toString())
    tableFilters.order?.forEach((order) => {
      queryParams.append("order", `${order.direction}(${order.attribute})`)
    })

    return queryParams
  }

  buildFieldFilters(queryParams: URLSearchParams, fieldAttributes: string[] | undefined) {
    if (!fieldAttributes) return queryParams
    queryParams.append("fields", `(${fieldAttributes.join(", ")})`)

    return queryParams
  }

  buildAttributeFilters(queryParams: URLSearchParams, attributeFilters: AttributeFilters | undefined) {
    if (!attributeFilters) return queryParams
    for (const [key, value] of Object.entries(attributeFilters)) {
      queryParams.append(key, value.toString())
    }

    return queryParams
  }

  dateToProxyProviderFormat(date: Date) {
    return format(new Date(date.toUTCString()), "yyyy-MM-dd HH:mm:ss")
  }
}
