import { useEffect, FC } from "react"
import { useFetchCurrentUser } from "@api/users"

const HotJar: FC<{ disableUserAttributes?: boolean }> = ({ disableUserAttributes }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3543672,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  const enabled = !disableUserAttributes
  const { data: userData } = useFetchCurrentUser(enabled)

  useEffect(() => {
    if (!userData) return

    const { id, email } = userData
    const script = document.createElement("script")
    script.innerHTML = `
      var userId = ${id} || null; // Replace your_user_id with your own if available.
      window.hj('identify', userId, {
          // Add your own custom attributes here. Some EXAMPLES:
          // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
          // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
          // 'Total purchases': 15, // Send numbers without quotes.
          // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
          // 'Last refund date': null, // Send null when no value exists for a user.
          email: '${email}',
          environment: '${process.env.REACT_APP_SENTRY_ENVIRONMENT || "development"}',
      });
    `
    document.head.appendChild(script)
    return () => {
      document.head.removeChild(script)
    }
  }, [userData?.id])
  return null
}

export default HotJar
