import { FC, Dispatch, SetStateAction, useState, useContext } from "react"
import {
  Stack,
  Typography,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Checkbox,
  FormControlLabel,
  TextField,
  Chip,
} from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import DeleteIcon from "@mui/icons-material/Delete"
import { needsIframe, needsNetworkExtraction, needsRegex, needsValue, needsWaitCode, regexOnly } from "./helpers"
import { OrganizationWizardContext } from "./Context"

type ScraperActionType =
  | "EXTRACT_DATE_MDY"
  | "EXTRACT_DATE_YMD"
  | "EXTRACT_DATE_BEST_GUESS"
  | "EXTRACT_DATE_MD"
  | "EXTRACT_TITLE"
  | "EXTRACT_VIDEO_URL"
  | "URI_NAVIGATE"
  | "BROWSER_NAVIGATE"
  | "ZYTE_NAVIGATE"
  | "CLICK_ON"

const deletableTypes: ScraperActionType[] = ["URI_NAVIGATE", "BROWSER_NAVIGATE", "ZYTE_NAVIGATE", "CLICK_ON"]

const substitutionTypes: ScraperActionType[] = [
  "URI_NAVIGATE",
  "BROWSER_NAVIGATE",
  "ZYTE_NAVIGATE",
  "EXTRACT_VIDEO_URL",
]

const typeToString: Record<ScraperActionType, string> = {
  EXTRACT_DATE_MDY: "Extract date",
  EXTRACT_DATE_YMD: "Extract date",
  EXTRACT_DATE_BEST_GUESS: "Extract date",
  EXTRACT_DATE_MD: "Extract date",
  EXTRACT_TITLE: "Extract title",
  EXTRACT_VIDEO_URL: "Extract video URL",
  URI_NAVIGATE: "URI navigate",
  BROWSER_NAVIGATE: "Browser navigate",
  ZYTE_NAVIGATE: "Zyte navigate",
  CLICK_ON: "Click On",
}

export interface ScraperAction {
  type: ScraperActionType
  identifier: string
  index: number
  regex: boolean
  constant?: string
  iframe?: boolean
  browser_wait_code?: string
  value?: boolean
  network_request?: boolean
}

export type SelectionTypes = "SELECTION" | "SUBSTITUTION"

export const ActionCard: FC<{
  scraperAction: ScraperAction
  index: number
  last: boolean
  setScraperActions: Dispatch<SetStateAction<ScraperAction[]>>
  isGeneralScraper?: boolean
}> = ({ scraperAction, index, last, setScraperActions, isGeneralScraper = true }) => {
  const setScraperAction = <Type extends keyof ScraperAction>(key: Type, value: ScraperAction[Type]) => {
    setScraperActions((prevValue) => {
      const newValue = [...prevValue]
      const newAction = { ...prevValue[index] }
      newAction[key] = value
      newValue[index] = newAction
      return newValue
    })
  }

  const { value } = useContext(OrganizationWizardContext)
  const { navigationMethod } = value
  const [selectionType, setSelectionType] = useState<SelectionTypes>(
    scraperAction?.constant && scraperAction?.constant?.length > 0 ? "SUBSTITUTION" : "SELECTION",
  )

  const changeSelectionType = (type: SelectionTypes) => {
    if (type === "SELECTION") {
      setScraperAction("constant", undefined)
      setSelectionType("SELECTION")
    } else {
      setSelectionType("SUBSTITUTION")
    }
  }

  const setNetworkRequest = () => {
    if (!scraperAction.network_request) {
      setScraperAction("regex", true)
    }
    setScraperAction("network_request", !scraperAction.network_request)
  }

  return (
    <Card>
      <CardContent>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">{index + 1}</Typography>
            {deletableTypes.includes(scraperAction.type) ? (
              <>
                <FormControl size="small">
                  <InputLabel>Action Type</InputLabel>
                  <Select
                    value={scraperAction.type}
                    label="Action Type"
                    onChange={(event) => setScraperAction("type", event.target.value as ScraperActionType)}
                  >
                    {deletableTypes.map((type) => (
                      <MenuItem value={type} key={type}>
                        {typeToString[type]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton
                  size="small"
                  onClick={() =>
                    setScraperActions((prevActions) => {
                      const newActions = [...prevActions]
                      newActions.splice(index, 1)
                      return newActions
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ) : (
              <Typography>{typeToString[scraperAction.type]}</Typography>
            )}
            {scraperAction.type.includes("EXTRACT_DATE_") && (
              <FormControl size="small">
                <InputLabel>Date Format</InputLabel>
                <Select
                  value={scraperAction.type}
                  label="Date Format"
                  onChange={(event) => setScraperAction("type", event.target.value as ScraperActionType)}
                >
                  <MenuItem value="EXTRACT_DATE_MDY">Month Date Year</MenuItem>
                  <MenuItem value="EXTRACT_DATE_YMD">Year Month Date</MenuItem>
                  <MenuItem value="EXTRACT_DATE_MD">Month Date</MenuItem>
                  <MenuItem value="EXTRACT_DATE_BEST_GUESS">Best Guess</MenuItem>
                </Select>
              </FormControl>
            )}
            {substitutionTypes.includes(scraperAction.type) && (
              <FormControl size="small">
                <InputLabel>Extraction Type</InputLabel>
                <Select
                  value={selectionType}
                  label="Extraction Type"
                  onChange={(event) => {
                    changeSelectionType(event.target.value as SelectionTypes)
                    if (event.target.value === "SUBSTITUTION") {
                      setScraperAction("regex", true)
                    }
                  }}
                >
                  <MenuItem value="SELECTION">Selection</MenuItem>
                  <MenuItem value="SUBSTITUTION">Substitution</MenuItem>
                </Select>
              </FormControl>
            )}
            {isGeneralScraper && navigationMethod === "json" && <Chip color="primary" label={"General Scraper"} />}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={() =>
                setScraperActions((prevActions) => {
                  const newActions = [...prevActions]
                  newActions.splice(index, 1)
                  newActions.splice(index - 1, 0, scraperAction)
                  return newActions
                })
              }
              disabled={index === 0}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                setScraperActions((prevActions) => {
                  const newActions = [...prevActions]
                  newActions.splice(index, 1)
                  newActions.splice(index + 1, 0, scraperAction)
                  return newActions
                })
              }
              disabled={last}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            label="Identifier"
            size="small"
            value={scraperAction.identifier}
            onChange={(event) => setScraperAction("identifier", event.target.value)}
            sx={{ flex: "1" }}
          />
          <TextField
            label="Index"
            size="small"
            value={scraperAction.index}
            onChange={(event) => {
              const newValue = parseInt(event.target.value, 10)
              if (Number.isNaN(newValue)) {
                setScraperAction("index", 0)
              } else {
                setScraperAction("index", newValue)
              }
            }}
            inputProps={{
              sx: {
                width: "4ch",
              },
            }}
          />
          {needsRegex(scraperAction) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={scraperAction.regex}
                  onChange={(event) =>
                    setScraperAction("regex", regexOnly(selectionType, scraperAction) ? true : event.target.checked)
                  }
                />
              }
              label="Regex"
            />
          )}
          {needsIframe(scraperAction) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={scraperAction.iframe}
                  onChange={() => setScraperAction("iframe", !scraperAction.iframe)}
                />
              }
              label="Iframe"
            />
          )}
          {needsValue(scraperAction) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={scraperAction.value}
                  onChange={() => setScraperAction("value", !scraperAction.value)}
                />
              }
              label="Value"
            />
          )}
          {needsNetworkExtraction(scraperAction) && (
            <FormControlLabel
              control={<Checkbox checked={scraperAction.network_request} onChange={setNetworkRequest} />}
              label="Network Requests"
            />
          )}
        </Stack>
        <Stack direction="column" spacing={2}>
          {selectionType === "SUBSTITUTION" && (
            <TextField
              label="Constant"
              size="small"
              helperText="http://example.com/${REPLACE}/watch"
              value={scraperAction.constant}
              onChange={(event) => setScraperAction("constant", event.target.value)}
              sx={{ flex: "1", marginTop: 3 }}
            />
          )}
        </Stack>
        {needsWaitCode(scraperAction) && (
          <TextField
            label={scraperAction.network_request ? "Wait Seconds" : "Browser Wait Code"}
            size="small"
            fullWidth
            helperText={scraperAction.network_request ? "5" : "@browser.video.wait_until(timeout: 30, &:exists?)"}
            value={scraperAction.browser_wait_code}
            onChange={(event) => setScraperAction("browser_wait_code", event.target.value)}
            sx={{ marginTop: 2 }}
          />
        )}
      </CardContent>
    </Card>
  )
}
