import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Industry } from "@interfaces/industry"

const prefix = "/industries"

export const useFetchIndustries = (onboardingOnly?: boolean, opportunitiesOnly?: boolean) => {
  const axiosInstance = useAuthAxios()

  return useQuery<Industry[]>(
    ["industries", { show_for_onboarding: onboardingOnly, show_for_opportunities: opportunitiesOnly }],
    async () => {
      const response = await (
        await axiosInstance
      ).get(prefix, { params: { show_for_onboarding: onboardingOnly, show_for_opportunities: opportunitiesOnly } })

      return response.data
    },
  )
}

export const useFetchIndustryIdsMap = (onboardingOnly?: boolean, opportunitiesOnly?: boolean) => {
  const fetchData = useFetchIndustries(onboardingOnly, opportunitiesOnly)
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((industry: Industry) => [industry.id.toString(), industry])),
    }
  }
  return fetchData
}
