import { Button, Grid, TextField } from "@mui/material"
import { FC, useState } from "react"
import { useTrainSimilarWords } from "../../../api/settings"

export const TrainSimilarWords: FC = () => {
  const { mutate: trainSimilarWords } = useTrainSimilarWords()
  const [sampleSize, setSampleSize] = useState("0.2")

  return (
    <Grid container spacing={1}>
      <Grid item>
        <TextField value={sampleSize} onChange={(event) => setSampleSize(event.target.value)} />
      </Grid>
      <Grid item alignItems="center">
        <Button onClick={() => trainSimilarWords({ sample_size: sampleSize })} variant="contained">
          Train
        </Button>
      </Grid>
    </Grid>
  )
}
