import { InputBase } from "@mui/material"
import { FC, Dispatch, SetStateAction } from "react"

export const SearchFormInput: FC<{
  input: string
  setInput: Dispatch<SetStateAction<string>>
  addTerm: () => void
  setInputFocused?: Dispatch<SetStateAction<boolean>>
}> = ({ input, setInput, addTerm, setInputFocused }) => (
  <>
    <InputBase
      value={input}
      sx={{ flex: 1, height: { xs: "5ch", sm: "auto" }, width: { xs: "30ch", sm: "35ch", md: "58ch" } }}
      inputProps={{
        "aria-label": "enter a search term/phrase",
        sx: {
          "&::placeholder": {
            opacity: "0.7",
          },
        },
      }}
      placeholder="e.g. broadband, rfp, funding"
      onChange={(event) => setInput(event.target.value)}
      onKeyPress={(event) => {
        if (event.key === "Enter" && input) {
          addTerm()
        }
      }}
      onFocus={setInputFocused && (() => setInputFocused(true))}
      onBlur={setInputFocused && (() => setInputFocused(false))}
    />
  </>
)
