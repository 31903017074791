import { FC, useState, useEffect, ReactElement } from "react"
import {
  Button,
  Card,
  CardContent,
  Dialog,
  Stack,
  DialogContent,
  DialogTitle,
  Fab,
  CircularProgress,
} from "@mui/material"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { getMeetingPersonalBriefing, useFetchMeeting } from "../../../api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useCreatePDFAndDownload } from "../Shared/BriefingPDF"
import { getPrompts } from "../../../api/prompts"
import { BriefingCard } from "../../shared/BriefingCard"
import { PersonalBriefingCreation } from "./PersonalBriefingCreation"
import { useToast } from "../../shared/ToastHook"
import AddIcon from "@mui/icons-material/Add"
import { BriefingContent } from "../Shared/BriefingContent"
import { PublicBriefing } from "@src/interfaces/briefing"

export const PersonalBriefing: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: fetchedMeeting } = useFetchMeeting(meetingId)
  const { data: unfilteredBriefings, refetch } = getMeetingPersonalBriefing(meetingId, (briefingData) => {
    if (
      briefingData.some((briefing) => {
        if (!briefing.result) {
          const createTime = new Date(briefing.created_at)
          if (Date.now() - createTime.valueOf() < 120000) {
            // briefing was created less than 2 minutes ago so possibly still processing
            return true
          }
        }
        return false
      })
    ) {
      setTimeout(refetch, 10000) // check again in 10 seconds
    }
  })
  const { data: prompts, isFetched: promptsIsFetched } = getPrompts("personal")
  const toast = useToast()
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const close = () => setIsDialogOpen(false)
  const refetchAfterCreation = () => {
    setIsGenerating(true)
    setTimeout(() => {
      setIsGenerating(false)
      if (refetch) {
        refetch()
      }
    }, 40000) // briefing job takes something like 30 seconds to start up but add 10 seconds to be safe
  }

  const briefingsMap = new Map()
  unfilteredBriefings?.forEach((briefing) => {
    if (briefing.name) {
      briefingsMap.set(briefing.id, briefing)
    }
  })

  useEffect(() => {
    if (prompts && promptsIsFetched && selectedPrompts.size === 0) {
      setSelectedPrompts(new Set([prompts[0].id]))
    }
  }, [promptsIsFetched])

  // this is backwards compatibility stuff
  // briefings in the new format have a name
  const newBriefings = unfilteredBriefings?.filter(({ name }) => name) || []

  const briefings: PublicBriefing[] = []
  const briefingCards: ReactElement[] = []
  if (newBriefings.length) {
    const briefingsMap = new Map()
    newBriefings.forEach((briefing) => {
      const briefingWithSameName = briefingsMap.get(briefing.name)
      if (
        !briefingWithSameName ||
        new Date(briefing.updated_at).valueOf() > new Date(briefingWithSameName.updated_at).valueOf()
      ) {
        briefingsMap.set(briefing.name, briefing)
      }
    })
    briefings.push(...briefingsMap.values())
    briefingCards.push(...briefings.map((briefing) => <BriefingCard key={briefing.id} briefing={briefing} />))
  } else if (unfilteredBriefings && unfilteredBriefings.length) {
    // we only have old format briefings so we should select the newest briefing
    let newestBriefing = unfilteredBriefings[0]
    for (let i = 1; i < unfilteredBriefings.length; i++) {
      if (unfilteredBriefings[i].created_at > newestBriefing.created_at) {
        newestBriefing = unfilteredBriefings[i]
      }
    }
    briefings.push(newestBriefing)
    briefingCards.push(<BriefingContent promptResult={newestBriefing.result} />)
  }

  const createPDFAndDownload = useCreatePDFAndDownload(
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(
          marked.parse(
            briefings.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n---\n\n") || "",
          ) as string,
        ),
      }}
    />,
    fetchedMeeting?.meeting,
  )

  if (unfilteredBriefings && unfilteredBriefings.length > 0) {
    return (
      <>
        <Stack spacing={1}>
          <Stack direction="row">
            <Stack direction="row" spacing={1} flex="1">
              <Button variant="contained" onClick={createPDFAndDownload}>
                Full Briefing PDF
              </Button>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    briefings.map(({ name, result }) => `### ${name}\n\n${result}`).join("\n\n"),
                  )
                  toast("Full Briefing Copied to clipboard")
                }}
              >
                Copy Full Briefing to Clipboard
              </Button>
            </Stack>
            {isGenerating ? (
              <CircularProgress />
            ) : (
              <Fab size="small" color="primary" onClick={() => setIsDialogOpen(true)}>
                <AddIcon />
              </Fab>
            )}
          </Stack>
          {briefingCards}
        </Stack>
        <Dialog open={isDialogOpen} onClose={close}>
          <DialogTitle>Create Personal Briefing</DialogTitle>
          <DialogContent>
            <PersonalBriefingCreation
              blockedPrompts={briefings.map(({ name }) => name)}
              onSuccess={() => {
                refetchAfterCreation()
                close()
              }}
              isLoading={isGenerating}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  } else {
    return (
      <Card>
        <CardContent>
          <PersonalBriefingCreation onSuccess={refetchAfterCreation} isLoading={isGenerating} />
        </CardContent>
      </Card>
    )
  }
}
