import { Grid } from "@mui/material"
import { GeneralStepProps } from "@src/interfaces/newUserOrgForm"
import { CreateUserOrganization, DetailedUserOrganization } from "@src/interfaces/userOrganization"
import { FC, useState } from "react"
import { DialogActionsContainer } from "../NewUserOrganizationWizardGeneralContent"
import { NewPermissionState } from "../../NewPermissionState"

export const StatePermissionsOrgFormFields: FC<GeneralStepProps> = ({
  newUserOrganization,
  setNewUserOrganization,
  setStep,
  handleOnClose,
}) => {
  const [localNewUserOrganization, setLocalNewUserOrganization] =
    useState<Partial<CreateUserOrganization>>(newUserOrganization)

  const handleClickNext = () => {
    setNewUserOrganization(localNewUserOrganization)
    setStep((prevVal) => prevVal + 1)
  }

  const handleClickBack = () => {
    setStep((prevVal) => prevVal - 1)
  }

  const handleSelectionChange = (states: number[]) => {
    setLocalNewUserOrganization({ ...localNewUserOrganization, state_permissions: states })
  }

  return (
    <>
      <Grid item>
        <NewPermissionState
          selectedStates={localNewUserOrganization.state_permissions || []}
          handleSelectionChange={handleSelectionChange}
          userOrganization={localNewUserOrganization as DetailedUserOrganization}
          includeInternal={false}
        />
      </Grid>
      <DialogActionsContainer
        handleOnClose={handleOnClose}
        handleOnNext={handleClickNext}
        handleOnBack={handleClickBack}
      />
    </>
  )
}
