import { Box, Tab, Tabs } from "@mui/material"
import { FC, useState } from "react"
import { ScraperUat } from "./ScraperUat"
import { SignalsUatContainer } from "@src/containers/SignalsUatContainer"
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const Uat: FC = () => {
  const [tab, setTab] = useState(0)
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={(_event: React.SyntheticEvent, newValue: number) => setTab(newValue)}
          aria-label="monitors tabs"
        >
          <Tab label="Signals" {...a11yProps(0)} />
          <Tab label="Scraper" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {tab === 0 ? <SignalsUatContainer /> : <ScraperUat />}
    </>
  )
}
