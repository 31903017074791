import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useTriggerSignals } from "@src/api/admin/uat"
import { SignalsContainerContext } from "@src/containers/SignalsUatContainer"
import React from "react"
import { FC, useContext } from "react"

export const SignalsTestForm: FC = () => {
  const {
    daysBack,
    setDaysBack,
    selectedUsersType,
    setSelectedUsersType,
    setSignalsProgressData,
    setIsProcessLoading,
    setKillFetch,
  } = useContext(SignalsContainerContext)

  const { mutate: triggerSignalsUat } = useTriggerSignals()

  const onDaysBackChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(event.target.value)
    setDaysBack(value)
  }

  const onSelectedUsersTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedUsersType(event.target.value)
  }

  const handleSendClick = () => {
    setSignalsProgressData([])
    setKillFetch(false)
    triggerSignalsUat({ days_back_to_process: daysBack, users_to_process_type: selectedUsersType })
    setIsProcessLoading(true)
  }

  return (
    <Paper elevation={2} sx={{ width: "50vw", height: 250 }}>
      <Stack spacing={2} padding={1}>
        <Typography variant="h4">Signals Test</Typography>
        <TextField
          label="Number of Days Back Used By Searches"
          type="number"
          value={daysBack}
          onChange={onDaysBackChange}
        />
        <FormControl fullWidth>
          <InputLabel id="select-users-label">Users To Be Processed By Signals</InputLabel>
          <Select
            labelId="select-users-label"
            id="select-users"
            value={selectedUsersType}
            label="Users to be processed by Signals"
            onChange={onSelectedUsersTypeChange}
          >
            <MenuItem value={"allowed"}>Allowed Users</MenuItem>
            <MenuItem value={"all"}>All Users</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSendClick}>
          Send
        </Button>
      </Stack>
    </Paper>
  )
}
