import { Button } from "@mui/material"
import { FC } from "react"

export const CopyFormButton: FC<{
  copy: () => void
}> = ({ copy }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      marginRight: 1,
      marginTop: 1,
      opacity: "1",
      color: "white",
      flexShrink: "0",
    }}
    disableElevation
    onClick={copy}
  >
    Copy
  </Button>
)
