import { FC, useMemo } from "react"
import { Box, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useFetchUserOrganizationPermissions } from "../../../api/userOrganizations"
import { PermissionListItem } from "./PermissionListItem"
import { AugmentedStatus } from "../../shared/LoadingEllipsis"
import { UserOrganizationPermission } from "@src/interfaces/userOrganizationPermission"

export interface IHashedPermissions {
  permissionByState?: Record<string, UserOrganizationPermission[] | undefined>
  permissionAutoUpdateByState?: Record<string, Record<string, number>>
}

export const PermissionsList: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: permissions, status } = useFetchUserOrganizationPermissions(parseInt(userOrganizationId))

  const hashedPermissions: IHashedPermissions = useMemo(() => {
    const permissionByState: Record<string, UserOrganizationPermission[] | undefined> = {}
    const permissionAutoUpdateByState: Record<string, Record<string, number>> = {}
    if (!permissions) {
      return {}
    }

    permissions.permissions.forEach((p) => {
      if (!permissionAutoUpdateByState[p.state]) {
        permissionAutoUpdateByState[p.state] = {}
      }
      permissionByState[p.state] = [...(permissionByState[p.state] || []), ...[p]]
      if (p.auto_update) {
        const newCount = (permissionAutoUpdateByState[p.state]["autoUpdateOnCount"] || 0) + 1
        permissionAutoUpdateByState[p.state]["autoUpdateOnCount"] = newCount
      }
    })
    return { permissionByState, permissionAutoUpdateByState }
  }, [permissions])

  if (!permissions) {
    return (
      <Typography>
        <AugmentedStatus status={status} />
      </Typography>
    )
  }

  return (
    <Box marginTop={2}>
      {hashedPermissions?.permissionByState &&
        Object.keys(hashedPermissions.permissionByState)
          .sort()
          .map((stateName) => (
            <PermissionListItem
              stateName={stateName}
              hashedPermissions={hashedPermissions}
              userOrgId={parseInt(userOrganizationId)}
              key={stateName}
              totalPermissions={permissions?.auto_update_on_count_by_state[stateName]}
            />
          ))}
    </Box>
  )
}
