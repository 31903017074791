import { FC } from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useFetchUsers } from "../../api/users"

export const UserSelector: FC<{
  userValue: any
  setUser: (filterParams: any) => void
}> = ({ userValue, setUser }) => {
  const { data: usersData } = useFetchUsers()

  if (usersData === undefined) {
    return <></>
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="user-select-label">Users</InputLabel>
      <Select
        value={userValue}
        onChange={(event) => setUser(event.target.value)}
        labelId="user-select-label"
        label="Users"
      >
        {usersData.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
