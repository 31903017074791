import { Column } from "../components/shared/DataTable"

export const columns: Column[] = [
  { id: "link", sortProp: "name", label: "Name" },
  { id: "industry", label: "Industry" },
  { id: "size", label: "Size" },
  { id: "created_at", label: "Created At" },
]

export const archivedUserColumns: Column[] = [
  { id: "link", sortProp: "name", label: "Name" },
  { id: "industry", label: "Industry" },
  { id: "created_at", label: "Created At" },
  { id: "trial_started_at", label: "Trial Started At" },
  { id: "trial_expired_at", label: "Trial Expired At" },
  { id: "discarded_at", label: "Discarded At" },
]

export const trialUserColumns: Column[] = [
  { id: "link", sortProp: "name", label: "Name" },
  { id: "industry", label: "Industry" },
  { id: "created_at", label: "Created At" },
  { id: "trial_started_at", label: "Trial Started At" },
  { id: "trial_expired_at", label: "Trial Expired At" },
]
