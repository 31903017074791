import { FC, useRef, useState } from "react"
import DownloadIcon from "@mui/icons-material/Download"
import { Box, Button, Dialog, DialogActions, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { WidgetContainer as DisplayWidgetContainer } from "./display_widget/WidgetContainer"
import { IDashboardData } from "../../api/interfaces"
import html2canvas from "html2canvas"
import moment from "moment"

export const DownloadDashboardPng: FC<{ dashboardData: IDashboardData }> = ({ dashboardData }) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const previewRefs = useRef<HTMLDivElement>(null)

  const onClickHandler = () => {
    if (previewRefs.current !== null) {
      html2canvas(previewRefs.current, { useCORS: true, scale: 2 }).then((canvas) => {
        const urlImage = canvas.toDataURL("image/png")
        downloadImage(urlImage, `${dashboardData?.dashboard.name}_${moment().format("MMDDYYYYTHHmmss")}`)
      })
      setPreviewOpen(!previewOpen)
    }
  }

  const downloadImage = (dataUrl: string, fileName: string) => {
    const fakeLink = window.document.createElement("a")
    fakeLink.download = fileName
    fakeLink.href = dataUrl
    fakeLink.click()
  }

  return (
    <>
      <Tooltip title="Download Dashboard PNG">
        <IconButton size="medium" onClick={() => setPreviewOpen(!previewOpen)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={previewOpen} onClose={() => setPreviewOpen(!previewOpen)} fullWidth maxWidth="xl">
        <Grid container direction="row" marginBottom={1} ref={previewRefs}>
          <Grid item xs={12} margin={5}>
            <Box
              component="img"
              sx={{ width: { sm: 360 }, height: { sm: "100%" }, cursor: "pointer" }}
              src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cl.ai.text.and.logo.png"
            />
          </Grid>
          <Grid item xs={12} marginLeft={5}>
            <Typography variant="h5">{dashboardData?.dashboard.name}</Typography>
            <Typography variant="body1">{dashboardData?.dashboard.description}</Typography>
          </Grid>
          <DisplayWidgetContainer dashboardId={dashboardData?.dashboard.id.toString()} />
        </Grid>
        <DialogActions sx={{ margin: 2 }}>
          <Button onClick={() => onClickHandler()} variant="contained">
            Download
          </Button>
          <Button onClick={() => setPreviewOpen(!previewOpen)} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
