import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useCreateWordFromCsv } from "../../../api/most_used_words"

export const WordFilters: FC = () => {
  const { mutate: uploadWordCsv } = useCreateWordFromCsv()
  const [csvFile, setCsvFile] = useState<File | undefined>()

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" component="label" size="small">
          Choose Words CSV
          <input
            onChange={(event: any) => {
              setCsvFile(event.target.files[0])
            }}
            type="file"
            hidden
          />
        </Button>
        <Grid container padding={1}>
          {csvFile && <Typography>{csvFile?.name}</Typography>}
        </Grid>
      </Grid>
      <Grid item>
        <Button onClick={() => uploadWordCsv({ file: csvFile })}>UPLOAD</Button>
      </Grid>
    </Grid>
  )
}
