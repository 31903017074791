import { FormControlLabel, Switch } from "@mui/material"
import React, { FC, SetStateAction, createContext, useContext } from "react"
import { WarningDialog } from "./WarningDialog"
import { useIsSuperAdmin } from "../../../api/users"
import { useOpenModalKey } from "../OpenModalHook"
import { CampaignContext } from "../../Campaigns/CampaignListItem"

export const EnableGeneralBriefingContext = createContext<{
  briefing: boolean
  setBriefing: React.Dispatch<SetStateAction<boolean>>
  dialogOpen: boolean
  setDialogOpen: React.Dispatch<SetStateAction<boolean>>
}>({
  briefing: false,
  setBriefing: () => {
    /* placeholder for typescript */
  },
  dialogOpen: false,
  setDialogOpen: () => {
    /* placeholder for typescript */
  },
})

export const EnableGeneralBriefing: FC<{
  generalBriefing: {
    briefing: boolean
    setBriefing: React.Dispatch<SetStateAction<boolean>>
  }
}> = ({ generalBriefing }) => {
  const campaignContext = useContext(CampaignContext)
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const generalBriefingWarningModalKey = `GENERAL_BRIEFING_WARNING_MODAL_KEY_${campaignContext.campaign.id}`
  const open = useOpenModalKey(generalBriefingWarningModalKey)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      open()
    } else {
      generalBriefing.setBriefing(e.target.checked)
    }
  }

  const contextProviderObject = {
    ...generalBriefing,
    dialogOpen,
    setDialogOpen,
  }

  return (
    <>
      {isSuperAdmin && (
        <EnableGeneralBriefingContext.Provider value={contextProviderObject}>
          <FormControlLabel
            control={<Switch checked={generalBriefing.briefing} onChange={onChange} />}
            label="Enable General Briefing"
          />
          <WarningDialog
            modalKey={generalBriefingWarningModalKey}
            onCancel={() => generalBriefing.setBriefing(false)}
            onSubmit={() => generalBriefing.setBriefing(true)}
          />
        </EnableGeneralBriefingContext.Provider>
      )}
    </>
  )
}
