import { Dispatch, FC, SetStateAction } from "react"
import { InputAdornment, Stack, TextField } from "@mui/material"

export const TimeDuration: FC<{
  proximity: number | null
  setProximity: Dispatch<SetStateAction<number | null>>
}> = ({ proximity, setProximity }) => {
  const onChange = (value: string) => {
    if (Number.isInteger(parseInt(value))) {
      setProximity(parseInt(value))
    } else {
      setProximity(null)
    }
  }

  return (
    <Stack className="proximity-time-duration" width={150}>
      <TextField
        type="number"
        InputProps={{ endAdornment: <InputAdornment position="end">sec</InputAdornment> }}
        InputLabelProps={{
          shrink: true,
        }}
        value={proximity}
        onChange={(event) => onChange(event.target.value)}
      />
    </Stack>
  )
}
