import { DataGrid, GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import { getBriefings } from "@api/briefings"
import { FC, useContext, useMemo, useState } from "react"
import { BriefingsContainerContext } from "@src/containers/BriefingContainers/BriefingsContainer"
import { publicBriefingColumns } from "@src/dataGridSchema/briefings/briefingColumns"
import { publicBriefingRows } from "@src/dataGridSchema/briefings/briefingRows"

export const BriefingsDataGrid: FC = () => {
  const { params, urlParamState, setUrlParamState } = useContext(BriefingsContainerContext)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: urlParamState.count,
    page: urlParamState.page,
  })

  const paramsWithOrder = new URLSearchParams(params)
  if (!paramsWithOrder.has("sort")) {
    paramsWithOrder.set("sort", "created")
    paramsWithOrder.set("order", "desc")
  }

  const { data, isLoading } = getBriefings(paramsWithOrder)

  const columns = publicBriefingColumns
  const rows = useMemo(() => {
    if (data?.briefings) {
      return data.briefings.map((briefing) => publicBriefingRows(briefing))
    }
    return []
  }, [data])

  const handlePaginationModelChange = (mode: GridPaginationModel) => {
    setPaginationModel(mode)
    setUrlParamState({
      ...urlParamState,
      page: mode.page,
    })
  }

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setUrlParamState({
      ...urlParamState,
      sort: sortModel[0]?.field,
      order: sortModel[0]?.sort || "asc",
      page: 0,
    })
  }

  return (
    <DataGrid
      autoHeight
      columns={columns}
      disableRowSelectionOnClick
      initialState={{
        pagination: { paginationModel },
      }}
      loading={isLoading}
      onPaginationModelChange={handlePaginationModelChange}
      onSortModelChange={handleSortModelChange}
      paginationMode="server"
      pageSizeOptions={[25]}
      rows={rows}
      rowCount={data?.total ?? -1}
      rowHeight={100}
      sortingMode="server"
    />
  )
}
