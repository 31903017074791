import { useQuery } from "react-query"
import { useAuthAxios } from "../axiosInstance"

const prefix = "/admin/wizard"

interface ExecutionLog {
  status: string
  message: string
  hint: string
}

export interface WizardResponseContactSourceUrl {
  response?: string
  execution_log?: ExecutionLog[]
}

export const useContactSourceUrl = (params?: string, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [params],
    async () => {
      const result = await (
        await axiosInstance
      ).get<WizardResponseContactSourceUrl>(`${prefix}/contact_source_url?${params}`)
      return result.data
    },
    {
      enabled: params !== undefined,
      retry: false,
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export interface WizardResponseGatherMeetingCandidates {
  response?: string[]
  execution_log?: ExecutionLog[]
}

export const useGatherMeetingCandidates = (
  params?: string,
  onSuccess?: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [params],
    async () => {
      const result = await (
        await axiosInstance
      ).get<WizardResponseGatherMeetingCandidates>(`${prefix}/gather_meeting_candidates?${params}`)
      return result.data
    },
    {
      enabled: params !== undefined,
      retry: false,
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data.response || [""])
        }
      },
    },
  )
}

export interface MeetingHashResponse {
  title: string
  published_at: string
  source_video_url: string
}

export interface WizardResponseInspectMeetings {
  response?: MeetingHashResponse[]
  execution_log?: ExecutionLog[]
}

export const useInspectMeetings = (
  params?: string,
  onSuccess?: React.Dispatch<React.SetStateAction<MeetingHashResponse[]>>,
) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [params],
    async () => {
      const result = await (
        await axiosInstance
      ).get<WizardResponseInspectMeetings>(`${prefix}/inspect_meetings?${params}`)
      return result.data
    },
    {
      enabled: params !== undefined,
      retry: false,
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data.response || [])
        }
      },
    },
  )
}
