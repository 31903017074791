import { FC, useState } from "react"
import { Button, CardContent, Grid, Slider, Typography } from "@mui/material"
import Loading from "../../shared/Loading"
import { useFetchScrapingEngine } from "../../../api/meetings"
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

export const EngineGraph: FC = () => {
  const [daysBack, setDaysBack] = useState(7)
  const [weeksBack, setWeeksBack] = useState(1)
  const { data: graphData } = useFetchScrapingEngine(daysBack)

  if (graphData === undefined) {
    return <Loading />
  }

  const variant = daysBack === weeksBack * 7 ? "outlined" : "contained"

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>Weeks Back</Typography>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={8}>
          <Slider
            aria-label="Weeks Back"
            value={weeksBack}
            onChange={(_event, value) => setWeeksBack(value as number)}
            getAriaValueText={() => weeksBack.toString()}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={10}
          />
        </Grid>
        <Grid item xs={2}>
          <Button disabled={daysBack === weeksBack * 7} variant={variant} onClick={() => setDaysBack(weeksBack * 7)}>
            SET
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CardContent>
          <Grid container direction="row" justifyContent="center">
            <ResponsiveContainer width={1000} height={300}>
              <LineChart data={graphData.engine_details}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line key="Meetings Created" type="monotone" dataKey="meetings" stroke="green" />
                <Line key="Transcription Jobs Completed" type="monotone" dataKey="transcription_jobs" stroke="purple" />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </CardContent>
      </Grid>
    </Grid>
  )
}
