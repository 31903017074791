import React, { FC, SetStateAction, createContext, useState } from "react"
import { Button, TextField, Typography, ListItem, ListItemText } from "@mui/material"
import { useParams } from "react-router-dom"
import { FetchedCampaign, useFetchUserOrganizationUsers } from "@api/admin/user_organizations"
import { useSendCampaignEmail } from "@api/admin/campaigns"
import { Confirm } from "@components/shared/Confirm"
import { AssignDialogMenu } from "@components/Campaigns/AssignDialogMenu"
import { UnassignDialogMenu } from "@components/Campaigns/UnassignDialogMenu"
import { useAssignCampaign, useUnassignCampaign } from "@api/campaigns"
import { CampaignDialog } from "@components/shared/CampaignDialog"
import { CampaignEditButton } from "./CampaignEditButton"
import { SubscribersDialog } from "./SubscribersDialog/SubscribersDialog"
import { EmailCadence } from "@src/interfaces/campaign"

export const CampaignListItemContext = createContext<{
  subscribersDialogOpen: boolean
  setSubscribersDialogOpen: React.Dispatch<SetStateAction<boolean>>
  campaign: FetchedCampaign
}>({
  subscribersDialogOpen: false,
  setSubscribersDialogOpen: () => {
    /* placeholder for typescript */
  },
  campaign: {} as FetchedCampaign,
})

export const CampaignListItem: FC<{ campaign: FetchedCampaign }> = ({ campaign }) => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { mutate: assignCampaignToUser, isSuccess: assignSuccess } = useAssignCampaign()
  const { mutate: unassignCampaignToUser, isSuccess: unassignSuccess } = useUnassignCampaign()
  const { data } = useFetchUserOrganizationUsers(userOrganizationId)
  const [emailTo, setEmailTo] = useState("")
  const { mutate: sendEmail } = useSendCampaignEmail()
  const [showEmailConfirm, setShowEmailConfirm] = useState(false)
  const [assignDialogOpen, setAssignDialogOpen] = useState(false)
  const [unassignDialogOpen, setUnssignDialogOpen] = useState(false)
  const [selectedAssignUsers, setSelectedAssignUsers] = useState<any[]>([])
  const [selectedUnassignUsers, setSelectedUnassignUsers] = useState<any[]>([])
  const [emailCadence, setEmailCadence] = useState<EmailCadence>("Unsubscribed")
  const [assignDialogConfirmOpen, setAssignDialogConfirmOpen] = useState(false)
  const [unassignDialogConfirmOpen, setUnassignDialogConfirmOpen] = useState(false)
  const [subscribersDialogOpen, setSubscribersDialogOpen] = useState(false)

  const onEmailSend = () => {
    setShowEmailConfirm(false)
    sendEmail({ emailTo, id: campaign.id.toString() })
  }
  const handleClose = () => {
    setAssignDialogConfirmOpen(false)
    setUnassignDialogConfirmOpen(false)
    setSelectedAssignUsers([])
    setSelectedUnassignUsers(campaign?.assignees)
    setEmailCadence("Unsubscribed")
  }

  return (
    <ListItem>
      <ListItemText sx={{ flex: "1 1 0" }}>
        <Typography variant="body1">
          Name:
          <strong>{` ${campaign.name}`}</strong>
        </Typography>
        <Typography variant="body2">{`Created By: ${campaign.user?.email || "who know????"}`}</Typography>
      </ListItemText>
      <ListItemText disableTypography sx={{ flex: "1 1 0", display: "flex" }}>
        <TextField value={emailTo} onChange={(event: any) => setEmailTo(event.target.value)} label="Send Email To:" />
        <Button onClick={() => setShowEmailConfirm(true)}>Send</Button>
        <CampaignEditButton initialData={campaign}>Edit</CampaignEditButton>
        <Button onClick={() => setAssignDialogOpen(true)}>Assign</Button>
        {campaign?.assignees?.length > 0 && (
          <Button
            onClick={() => {
              setSelectedUnassignUsers(campaign?.assignees)
              setUnssignDialogOpen(true)
            }}
          >
            Unassign
          </Button>
        )}
        <Button
          onClick={() => setSubscribersDialogOpen(true)}
          sx={campaign?.subscriptions?.length === 0 ? { display: "none" } : {}}
        >
          Subscribers
        </Button>
      </ListItemText>
      <Confirm
        open={showEmailConfirm}
        onClose={() => setShowEmailConfirm(false)}
        onSucceed={onEmailSend}
        title="Are you sure you want to send the email?"
      />
      <AssignDialogMenu
        handleClose={() => {
          setAssignDialogOpen(false)
        }}
        isOpen={assignDialogOpen}
        availableUsers={data}
        selectedUsers={selectedAssignUsers}
        setSelectedUsers={setSelectedAssignUsers}
        emailCadence={emailCadence}
        setEmailCadence={setEmailCadence}
        onSave={() => {
          setAssignDialogOpen(false)
          setAssignDialogConfirmOpen(true)
        }}
        assignees={campaign.assignees}
      />
      <CampaignDialog
        dialogOpen={assignDialogConfirmOpen}
        setDialogOpen={setAssignDialogConfirmOpen}
        onComplete={() => handleClose()}
        onSubmit={() =>
          assignCampaignToUser({
            userIds: selectedAssignUsers.map((user: any) => user.id),
            campaignIds: [campaign.id.toString()],
            emailCadence: emailCadence,
          })
        }
        requestStatus={assignSuccess}
        dialogAction="assign"
      />
      <UnassignDialogMenu
        handleClose={() => {
          setUnssignDialogOpen(false)
          setUnassignDialogConfirmOpen(false)
        }}
        isOpen={unassignDialogOpen}
        availableUsers={campaign.assignees}
        onSave={() => {
          setUnssignDialogOpen(false)
          setUnassignDialogConfirmOpen(true)
        }}
        selectedUsers={selectedUnassignUsers}
        setSelectedUsers={setSelectedUnassignUsers}
      />
      <CampaignDialog
        dialogOpen={unassignDialogConfirmOpen}
        setDialogOpen={setUnassignDialogConfirmOpen}
        onComplete={() => handleClose()}
        onSubmit={() => {
          unassignCampaignToUser({
            userIds: selectedUnassignUsers.map((user: any) => user.id),
            campaignIds: [campaign.id.toString()],
          })
        }}
        requestStatus={unassignSuccess}
        dialogAction="unassign"
      />
      {subscribersDialogOpen && (
        <CampaignListItemContext.Provider
          value={{
            subscribersDialogOpen,
            setSubscribersDialogOpen,
            campaign,
          }}
        >
          <SubscribersDialog />
        </CampaignListItemContext.Provider>
      )}
    </ListItem>
  )
}
