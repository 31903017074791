import { NaicsCode, NaicsCodeNode } from "@src/interfaces/naicsCode"

interface SectorRange {
  // For cases like Manufacturing (31-33)
  prefixes: string[]
  parentCode: string
}

export function buildNaicsTree(codes: NaicsCode[]): NaicsCodeNode[] {
  const codeMap: Map<string, NaicsCode> = new Map()
  const codeToNode: Map<string, NaicsCodeNode> = new Map()
  const rootNodes: NaicsCodeNode[] = []

  for (const codeEntry of codes) {
    codeMap.set(codeEntry.code, codeEntry)
  }

  const sectorRanges: SectorRange[] = []

  // Find all sector ranges by finding codes with hyphen
  for (const codeEntry of codes) {
    if (codeEntry.code.includes("-")) {
      const [start, end] = codeEntry.code.split("-")
      const startNum = parseInt(start, 10)
      const endNum = parseInt(end, 10)
      const prefixes: string[] = []

      for (let i = startNum; i <= endNum; i++) {
        prefixes.push(i.toString())
      }

      sectorRanges.push({
        prefixes,
        parentCode: codeEntry.code,
      })
    }
  }

  function getParentCode(code: string): string | null {
    if (code.length <= 2 || code.includes("-")) {
      // Top-level code or code with dash; no parent
      return null
    }

    let possibleParentCode = code.slice(0, code.length - 1)
    while (possibleParentCode.length >= 2) {
      if (codeMap.has(possibleParentCode)) {
        return possibleParentCode
      }
      possibleParentCode = possibleParentCode.slice(0, possibleParentCode.length - 1)
    }

    // Handle codes like 31-33
    for (const sector of sectorRanges) {
      if (sector.prefixes.some((prefix) => code.startsWith(prefix))) {
        if (codeMap.has(sector.parentCode)) {
          return sector.parentCode
        }
      }
    }

    return null
  }

  for (const codeEntry of codes) {
    const node: NaicsCodeNode = {
      id: codeEntry.id,
      code: codeEntry.code,
      title: codeEntry.title,
      created_at: codeEntry.created_at,
      updated_at: codeEntry.updated_at,
      discarded_at: codeEntry.discarded_at,
      parent: null,
      children: [],
    }
    codeToNode.set(codeEntry.code, node)
  }

  for (const codeEntry of codes) {
    const code = codeEntry.code
    const node = codeToNode.get(code)!
    const parentCode = getParentCode(code)

    if (parentCode) {
      const parentNode = codeToNode.get(parentCode)
      if (parentNode) {
        node.parent = parentNode
        parentNode.children.push(node)
      } else {
        // Parent code not found; treat as root
        rootNodes.push(node)
      }
    } else {
      // No parent; node is a root node
      rootNodes.push(node)
    }
  }
  return rootNodes
}
