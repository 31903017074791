import { FC, useMemo } from "react"
import { Typography, Link, ListItemText, ListItem, Stack, useTheme, useMediaQuery, List } from "@mui/material"
import { Organization } from "../../../../interfaces/organization"
import LaunchIcon from "@mui/icons-material/Launch"

interface TableConfig {
  title: string
  value: string | undefined
}

export const ChannelDetailsContent: FC<{ organization?: Organization }> = ({ organization }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isMedium = useMediaQuery(theme.breakpoints.down("lg"))
  const islarge = useMediaQuery(theme.breakpoints.down("xl"))

  let population: number | undefined

  if (organization?.city) {
    population = organization.city.population
  } else if (organization?.county) {
    population = organization.county.population
  } else {
    population = undefined
  }

  const columnOneConfig: TableConfig[] = useMemo(() => {
    return [
      {
        title: "Name:",
        value: organization?.name,
      },
      {
        title: "Type:",
        value: organization?.channel_type,
      },
      {
        title: "Population:",
        value: population?.toLocaleString("en-US"),
      },
    ]
  }, [organization, population])

  const columnTwoConfig: TableConfig[] = useMemo(() => {
    return [
      {
        title: "Home Page",
        value: organization?.home_page_url,
      },
      {
        title: "Contact Page",
        value: organization?.contact_page_url,
      },
    ]
  }, [organization, population])

  return (
    <Stack
      direction={isMobile || isMedium || islarge ? "column" : "row"}
      display="flex"
      sx={{ maxHeight: { xs: 400, md: 400 } }}
      marginBottom={1}
      spacing={islarge ? 1 : 15}
    >
      <Stack direction="row" spacing={isMobile ? 0 : 1}>
        <List sx={{ display: "flex", flexDirection: "column" }} component="div">
          {columnOneConfig.map((config) => {
            if (config.value) {
              return (
                <ListItem key={config.title} sx={{ height: 50 }}>
                  <ListItemText sx={{ width: "8em" }}>
                    <Typography variant="body1">{config.title}</Typography>
                  </ListItemText>
                </ListItem>
              )
            }
          })}
        </List>
        <List sx={{ marginTop: 1, display: "flex", flexDirection: "column" }} component="div">
          {columnOneConfig.map((config) => {
            if (config.value) {
              return (
                <ListItem key={config.title} sx={{ height: 50, display: "flex" }}>
                  <ListItemText>
                    {config.value ? (
                      <Typography variant="body1">{config.value}</Typography>
                    ) : (
                      <Typography variant="body1">
                        <i>Not Available</i>
                      </Typography>
                    )}
                  </ListItemText>
                </ListItem>
              )
            }
          })}
        </List>
      </Stack>
      <Stack direction="row" spacing={1}>
        <List sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }} component="div">
          <ListItem>
            <ListItemText sx={{ width: "8em" }}>
              <Typography variant="body1">External Links:</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <List sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }} component="div">
          {columnTwoConfig[0].value || columnTwoConfig[1].value ? (
            columnTwoConfig.map((config) => {
              if (config.value) {
                return (
                  <ListItem key={`links_${config.title}`}>
                    <ListItemText sx={{ marginTop: "2px" }}>
                      <Link
                        sx={{ "&:hover": { color: "#0a5dc2" } }}
                        key={config.title}
                        fontWeight={400}
                        variant="body1"
                        href={config.value}
                        target="_blank"
                        rel="noopener"
                      >
                        {config.title}
                        <LaunchIcon sx={{ paddingTop: "3px", "&:hover": { color: "#0a5dc2" } }} fontSize="small" />
                      </Link>
                    </ListItemText>
                  </ListItem>
                )
              }
            })
          ) : (
            <ListItem>
              <ListItemText sx={{ marginTop: "4px" }}>Not Available</ListItemText>
            </ListItem>
          )}
        </List>
      </Stack>
    </Stack>
  )
}
