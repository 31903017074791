import { FC, useState } from "react"
import { Button, Grid, Menu, MenuItem } from "@mui/material"
import { Confirm } from "../../shared/Confirm"
import { EditLocationModal } from "./EditLocationModal"
import Loading from "../../shared/Loading"
import { useFetchGeographiesAdmin } from "../../../api/admin/geographies"
import { getSingular } from "../../../config/geographyAdminConfig"

export const ActionsMenu: FC<{
  geoIds: number[]
  onActionComplete: any
  geoType: any
  createGeography: any
  updateGeography: any
  deleteGeography: any
}> = ({ geoIds, onActionComplete, geoType, createGeography, updateGeography, deleteGeography }) => {
  const disableActions = geoIds.length === 0

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openNewModal, setOpenNewModal] = useState(false)

  const { data: geographiesData } = useFetchGeographiesAdmin()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpenConfirm(false)
    setOpenEditModal(false)
    setOpenNewModal(false)
  }

  if (geographiesData === undefined) {
    return <Loading />
  }

  const selectedGeography =
    geoIds.length === 1 ? geographiesData[geoType].find((geo: any) => geo.id === geoIds[0]) : undefined

  return (
    <Grid>
      <Button
        id="actions-menu-button"
        aria-controls="actions-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="large"
        variant="outlined"
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "actions-menu-button" }}
      >
        <MenuItem onClick={() => setOpenNewModal(true)} disabled={geoIds.length !== 0}>
          {`New ${getSingular(geoType)}`}
        </MenuItem>
        <MenuItem onClick={() => setOpenEditModal(true)} disabled={geoIds.length !== 1}>
          {`Update ${getSingular(geoType)}`}
        </MenuItem>
        <MenuItem onClick={() => setOpenConfirm(true)} disabled={disableActions}>
          {`Delete ${getSingular(geoType)}(s)`}
        </MenuItem>
      </Menu>
      <Confirm
        onSucceed={() => {
          deleteGeography({ geography_id: geoIds.join(","), type: getSingular(geoType) })
          handleClose()
          setOpenConfirm(false)
          onActionComplete()
        }}
        onClose={handleClose}
        open={openConfirm}
        title={`Delete ${geoType}`}
        body={`Are you sure you want to delete these ${geoType}? This action is irreversible.`}
      />
      {selectedGeography && (
        <EditLocationModal
          open={openEditModal}
          onClose={handleClose}
          type={geoType}
          updateGeography={updateGeography}
          selectedGeography={selectedGeography}
          formType="Edit"
          onActionComplete={onActionComplete}
        />
      )}
      {geoIds.length === 0 && (
        <EditLocationModal
          open={openNewModal}
          onClose={handleClose}
          type={geoType}
          createGeography={createGeography}
          selectedGeography={undefined}
          formType="New"
          onActionComplete={onActionComplete}
        />
      )}
    </Grid>
  )
}
