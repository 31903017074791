import { FC, useMemo, useState } from "react"
import { Typography, Paper, Button, List, ListItemButton, ListItemText, ListItem, Stack } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Link } from "react-router-dom"
import Highlighter from "react-highlight-words"
import { Meeting } from "../../interfaces/meeting"
import { ElasticSearchAggResult, useLogTranscriptClick } from "../../api/search"
import { MeetingItemBase } from "../shared/MeetingItemBase"
import { centerTermInText } from "../shared/textTransformers"
import { generateURL } from "../../functions/generateURL"
import { formatSeconds } from "../shared/config"
import { getHighlighterRegexArray } from "../../functions/highlighter"
import { Bookmark } from "./MeetingList/bookmark"

const typographyProps = {
  fontSize: "1rem",
  lineHeight: "2",
  color: "black",
}

const buttonStyles = {
  marginY: 1,
  maxHeight: 30,
}

const TranscriptListItem: FC<{
  meeting: Meeting
  transcript: {
    id: number
    text: string
    start_time: number
  }
  searchWords: string[]
  proximity: number | null
  mustHaveTerms: string[]
  exclude: boolean | null
}> = ({ meeting, transcript: { id, text, start_time }, searchWords, proximity, mustHaveTerms, exclude }) => {
  const { mutate: logTranscriptHitClick } = useLogTranscriptClick(
    id,
    searchWords.filter((word) => text.toLowerCase().includes(word.toLowerCase())),
  )
  const xsText = centerTermInText(text, 64, [...searchWords, ...mustHaveTerms])
  const smText = centerTermInText(text, 64, [...searchWords, ...mustHaveTerms])
  const mdText = centerTermInText(text, 64, [...searchWords, ...mustHaveTerms])
  const isValidStartTime = typeof start_time === "number"

  const getFirstFoundTerm = (text: string, searchWords: string[]): string => {
    // Given a list of search words, find which one appears first in the text
    let firstFoundTerm = searchWords[0]
    let firstFoundIndex = text.length
    searchWords.forEach((word) => {
      const index = text.toLowerCase().indexOf(word.toLowerCase())
      if (index !== -1 && index < firstFoundIndex) {
        firstFoundIndex = index
        firstFoundTerm = word
      }
    })
    return firstFoundTerm
  }

  return (
    <ListItem key={id} disablePadding>
      <ListItemButton
        component={Link}
        to={generateURL(meeting.id, {
          terms: searchWords,
          transcriptId: id,
          activeTerm: xsText.centerTerm,
          proximity,
          mustHaveTerms,
          exclude,
        })}
        sx={{ width: 0, display: { sm: "none" } }}
        onClick={() => logTranscriptHitClick()}
      >
        <ListItemText>
          <Stack direction="row" spacing={2}>
            {isValidStartTime && (
              <Button size="small" color="primary" variant="outlined" sx={buttonStyles}>
                {formatSeconds(start_time)}
              </Button>
            )}
            <Typography {...typographyProps}>
              <Highlighter
                textToHighlight={`...${xsText.snippet}...`}
                searchWords={getHighlighterRegexArray([...searchWords, ...mustHaveTerms])}
              />
            </Typography>
          </Stack>
        </ListItemText>
      </ListItemButton>
      <ListItemButton
        component={Link}
        to={generateURL(meeting.id, {
          terms: searchWords,
          transcriptId: id,
          activeTerm: smText.centerTerm,
          proximity,
          mustHaveTerms,
          exclude,
        })}
        sx={{ width: 0, display: { xs: "none", sm: "inherit", md: "none" } }}
        onClick={() => logTranscriptHitClick()}
      >
        <ListItemText>
          <Stack direction="row" spacing={2}>
            {isValidStartTime && (
              <Button size="small" color="primary" variant="outlined" sx={buttonStyles}>
                {formatSeconds(start_time)}
              </Button>
            )}
            <Typography {...typographyProps}>
              <Highlighter
                textToHighlight={`...${smText.snippet}...`}
                searchWords={getHighlighterRegexArray([...searchWords, ...mustHaveTerms])}
              />
            </Typography>
          </Stack>
        </ListItemText>
      </ListItemButton>
      <ListItemButton
        component={Link}
        to={generateURL(meeting.id, {
          terms: searchWords,
          transcriptId: id,
          activeTerm: mdText.centerTerm,
          proximity,
          mustHaveTerms,
          exclude,
        })}
        sx={{ width: 0, display: { xs: "none", md: "inherit" } }}
        onClick={() => logTranscriptHitClick()}
      >
        <ListItemText>
          <Stack direction="row" spacing={2}>
            {isValidStartTime && (
              <Button size="small" color="primary" variant="outlined" sx={buttonStyles}>
                {formatSeconds(start_time)}
              </Button>
            )}
            <Typography {...typographyProps} flexGrow={1}>
              <Highlighter
                textToHighlight={`...${mdText.snippet}...`}
                searchWords={getHighlighterRegexArray([...searchWords, ...mustHaveTerms])}
              />
            </Typography>
            <Bookmark
              meeting={meeting}
              timestamp={formatSeconds(start_time)}
              transcriptId={id}
              keyword={getFirstFoundTerm(text, searchWords)}
            />
          </Stack>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

export const MeetingListItem: FC<{
  meeting: Meeting
  hits?: ElasticSearchAggResult
  searchWords: string[]
  blur?: boolean | undefined
  hasViewed?: boolean
  proximity: number | null
  mustHaveTerms: string[]
  exclude: boolean | null
}> = ({ meeting, hits, searchWords, blur = false, hasViewed = false, proximity, mustHaveTerms, exclude }) => {
  const [showOtherTranscripts, setShowOtherTranscripts] = useState(false)
  const sortedTranscripts = useMemo(() => {
    const compareFn = (
      hit: { id: number; text: string; start_time: number },
      otherhit: { id: number; text: string; start_time: number },
    ) => {
      if (hit.start_time > otherhit.start_time) {
        return 1
      } else if (hit.start_time < otherhit.start_time) {
        return -1
      } else {
        return 0
      }
    }

    if (hits) {
      const sortedTranscripts = [...hits.transcripts]
      if (showOtherTranscripts) {
        return sortedTranscripts.sort(compareFn)
      } else {
        return hits.transcripts
      }
    } else {
      return []
    }
  }, [showOtherTranscripts])

  const separatedTranscripts = useMemo(() => {
    if (sortedTranscripts) {
      const transcriptHolder = [...(sortedTranscripts || [])]
      if (transcriptHolder.length < 4) {
        return [transcriptHolder, []]
      }
      const shownTranscripts = []
      for (let i = 0; i < 3; i++) {
        shownTranscripts.push(transcriptHolder.splice(Math.floor(Math.random() * transcriptHolder.length), 1)[0])
      }
      return [shownTranscripts, transcriptHolder]
    }
    return [[], []]
  }, [hits])

  const cardSx = { marginY: 1, filter: "blur(0px)" }
  if (blur) {
    cardSx.filter = "blur(10px)"
  }
  const paperSx = { maxWidth: "900px", flexGrow: "1", padding: 0.5, marginX: 1, backgroundColor: "white" }
  if (hasViewed) {
    paperSx.backgroundColor = "#8c929617"
  }

  return (
    <MeetingItemBase
      meeting={meeting}
      terms={hits?.terms.filter(({ count }) => count) || []}
      link={generateURL(meeting.id, {
        terms: searchWords,
        proximity: proximity,
        mustHaveTerms: mustHaveTerms,
        exclude: exclude,
      })}
      hasViewed={hasViewed}
    >
      <Paper sx={{ ...paperSx }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" marginLeft={2}>
            {showOtherTranscripts ? "All" : "Selected"} Transcript Hits
          </Typography>
          {separatedTranscripts[1].length > 0 && (
            <Button
              onClick={() => setShowOtherTranscripts((prevValue) => !prevValue)}
              variant="outlined"
              size="small"
              endIcon={showOtherTranscripts ? <RemoveIcon /> : <AddIcon />}
            >
              {showOtherTranscripts ? "Less" : "More"}
            </Button>
          )}
        </Stack>
        <List>
          {!showOtherTranscripts &&
            separatedTranscripts[0].map((transcript) => (
              <TranscriptListItem
                key={transcript.id}
                meeting={meeting}
                transcript={transcript}
                searchWords={searchWords}
                proximity={proximity}
                mustHaveTerms={mustHaveTerms}
                exclude={exclude}
              />
            ))}
          {showOtherTranscripts &&
            sortedTranscripts.map((transcript) => (
              <TranscriptListItem
                key={transcript.id}
                meeting={meeting}
                transcript={transcript}
                searchWords={searchWords}
                proximity={proximity}
                mustHaveTerms={mustHaveTerms}
                exclude={exclude}
              />
            ))}
        </List>
      </Paper>
    </MeetingItemBase>
  )
}
