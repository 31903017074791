import { Typography, IconButton, Stack, Skeleton } from "@mui/material"
import { FC } from "react"
import { ClipShareBookmarkView } from "../../../interfaces/clipShare"

export const TeamsButton: FC<{ clipShare: ClipShareBookmarkView | undefined }> = ({ clipShare }) => {
  if (!clipShare) return <Skeleton variant="circular" width="60px" height="60px" />

  return (
    <IconButton
      sx={{
        borderRadius: 1,
        backgroundColor: "#F9F9F9",
        "&:active": {
          backgroundColor: "neutral.main",
        },
      }}
      size="large"
      disableRipple
    >
      <Stack direction="column" alignItems="center" sx={{ paddingTop: 0.5 }}>
        <div
          className="teams-share-button"
          data-href={
            clipShare?.transcript_id
              ? `${window.location.origin}/share/${clipShare?.id}?type=clip`
              : `${window.location.origin}/share/${clipShare?.meeting.id}`
          }
          data-icon-px-size="65"
          style={{ backgroundColor: "white", borderRadius: "90px", marginBottom: -3 }}
        />
        <Typography variant="h5" sx={{ marginBottom: 1, color: "black", paddingTop: 0.25 }}>
          Teams
        </Typography>
      </Stack>
    </IconButton>
  )
}
