import { FC } from "react"
import {
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControl,
} from "@mui/material"

export const UnassignDialogMenu: FC<{
  isOpen: any
  handleClose: any
  availableUsers: any
  onSave: any
  selectedUsers: any
  setSelectedUsers: any
}> = ({ isOpen, handleClose, availableUsers, onSave, selectedUsers, setSelectedUsers }) => {
  const noUsersSelected = selectedUsers?.length === 0
  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>Unassign Users</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ marginY: 1 }}>
          <InputLabel id="unassign-select-label">Users</InputLabel>
          <Select
            labelId="unassign-select-label"
            value={selectedUsers}
            renderValue={(selected: any) => selected?.map((user: any) => user.email).join(", ")}
            label="Users"
            onChange={(e: any) => setSelectedUsers(e.target.value)}
            multiple
            fullWidth
          >
            {availableUsers?.map((user: any) => (
              <MenuItem key={user.id} value={user}>
                <Checkbox size="small" checked={selectedUsers.map((user: any) => user.id).indexOf(user.id) !== -1} />
                {user.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSave} size="medium" variant="contained" color="primary" disabled={noUsersSelected}>
          Unassign
        </Button>
        <Button onClick={handleClose} size="medium" variant="text">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
