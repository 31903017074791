interface SetMaxDateRangeProps {
  dateRange: [Date, Date]
  maxRange?: number
  handleChange?: (dateRange: [Date, Date]) => void
}

export const setMaxDateRange = ({
  dateRange,
  maxRange = 1000 * 60 * 60 * 24 * 365, // 1 year in milliseconds
  handleChange,
}: SetMaxDateRangeProps): { start: Date; end: Date } => {
  const [startDate, endDate] = dateRange
  const timeDiff = Math.abs(endDate.valueOf() - startDate.valueOf())

  if (timeDiff > maxRange) {
    const newEndDate = new Date(startDate.valueOf() + maxRange)
    const newDateRange: [Date, Date] = [startDate, newEndDate]

    if (handleChange) {
      handleChange(newDateRange)
    }

    return { start: startDate, end: newEndDate }
  }

  return { start: startDate, end: endDate }
}
