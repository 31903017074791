import { Transcript, TranscriptWithPerson, TranscriptWord } from "../interfaces/transcript"

export const customSplitArray = (searchTerm: string) => {
  const splitString = []
  let currentLetters = ""

  for (let i = 0; i < searchTerm.length; i++) {
    if (searchTerm[i] === "&" || searchTerm[i] === "\u0026") {
      splitString.push(currentLetters)
      currentLetters = ""
      currentLetters = searchTerm[i]
    } else if (searchTerm[i] === " " || searchTerm[i] === "\u0020") {
      splitString.push(currentLetters)
      currentLetters = ""
    } else {
      currentLetters = currentLetters + searchTerm[i]
    }

    if (i === searchTerm.length - 1) {
      splitString.push(currentLetters)
      currentLetters = ""
    }
  }

  return splitString
}

export const getWordTime = (searchTerm: string, transcript: Transcript | TranscriptWithPerson): number => {
  const regex = /[.,?!]/
  const searchTerms = customSplitArray(searchTerm.trim())
  let transcriptWords: TranscriptWord[] = []
  let lastWordIndex = 0
  let currentWordsIndex = 0

  searchTerms.forEach((term) => {
    while (currentWordsIndex < transcript.words.length) {
      const currentWord = transcript.words[currentWordsIndex]
      const wordsEqual = currentWord.word.replace(regex, "").trim().toLowerCase() === term.trim().toLowerCase()

      if (wordsEqual && (lastWordIndex === 0 || currentWordsIndex === lastWordIndex + 1)) {
        transcriptWords.push(currentWord)
        lastWordIndex = currentWordsIndex
        currentWordsIndex += 1
        break
      } else if (lastWordIndex > 0) {
        transcriptWords = []
        lastWordIndex = 0
      }
      currentWordsIndex += 1
    }
  })

  if (transcriptWords.length > 0) {
    if (typeof transcriptWords[0].end_time === "object") {
      return transcriptWords[0].end_time.seconds
    } else {
      return transcriptWords[0].end_time
    }
  }
  return transcript.start_time
}
