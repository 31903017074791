import { FC, useState } from "react"
import { Voice } from "@interfaces/voice"
import { useFetchPersons, useFetchPersonsMap } from "@api/admin/persons"
import { useUnverifyVoice, useVerifyVoice } from "@api/admin/voices"
import { Autocomplete, Checkbox, createFilterOptions, Skeleton, TableCell, TableRow, TextField } from "@mui/material"
import { PersonCells } from "./PersonCells"
import { useLocation, Link } from "react-router-dom"

export const VoiceRow: FC<{ voice: Voice & { speaker: string } }> = ({
  voice: { id, speaker, person_id: origPersonId, verified },
}) => {
  const [textValue, setTextValue] = useState("")
  const [personId, setPersonId] = useState(origPersonId)
  const { data: personData } = useFetchPersons(new URLSearchParams({ count: "-1" })) // TODO - we need to refactor this to use a smaller limit for performance
  const { data: personsMap } = useFetchPersonsMap(new URLSearchParams({ count: "-1" }))
  const { mutate: verifyVoice, isLoading: verifyIsLoading } = useVerifyVoice()
  const { mutate: unverifyVoice, isLoading: unVerifyIsLoading } = useUnverifyVoice()
  const newURLParams = new URLSearchParams(useLocation().search)
  newURLParams.set("voice-id", id.toString())
  return (
    <TableRow>
      <TableCell>
        <Link
          to={{
            search: newURLParams.toString(),
          }}
        >
          {id}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          to={{
            search: newURLParams.toString(),
          }}
        >
          {speaker}
        </Link>
      </TableCell>
      <TableCell>
        {personData ? (
          <Autocomplete
            inputValue={textValue}
            onInputChange={(_event, value) => setTextValue(value)}
            value={personId}
            onChange={(_event, value) => setPersonId(value)}
            renderInput={(params) => <TextField {...params} label="Person" size="small" />}
            options={personData.persons.map((person) => person.id)}
            filterOptions={createFilterOptions({ limit: 1000 })}
            getOptionLabel={(option) => {
              const person = personsMap?.get(option)
              if (person) {
                return `${person.id}: ${person.name}${person.organization ? `, ${person.organization}` : ""}`
              }
              return ""
            }}
          />
        ) : null}
      </TableCell>
      <PersonCells voiceId={personId !== origPersonId ? id : null} personId={personId} />
      <TableCell align="center">
        {verifyIsLoading || unVerifyIsLoading ? (
          <Skeleton variant="rounded" width={24} height={24} sx={{ margin: "auto" }} />
        ) : (
          <Checkbox
            checked={verified}
            onChange={(event) => {
              if (event.target.checked) {
                verifyVoice({ id: id })
              } else {
                unverifyVoice({ id: id })
              }
            }}
          />
        )}
      </TableCell>
    </TableRow>
  )
}
