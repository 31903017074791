import { FC, useState, useMemo } from "react"
import { Button, Grid, Tab, Tabs, Typography } from "@mui/material"
import { columns, trialUserColumns, archivedUserColumns } from "../../../tableColumns/userOrganizationColumns"
import { Link, useHistory, useLocation } from "react-router-dom"
import { NewUserOrganization } from "./NewUserOrganization/NewUserOrganization"
import { DataTable } from "../../shared/DataTable"
import { SearchTextField } from "../../shared/SearchTextField"
import { UserOrganization } from "../../../interfaces/userOrganization"
import { useAdminQuery } from "../../../api/api"

interface UserOrgObject {
  archived: UserOrganization[]
  trial: UserOrganization[]
  active: UserOrganization[]
}

export const UserOrganizationList: FC = () => {
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const urlSearchParams = new URLSearchParams(locationSearchParams)

  const { data: userOrganizations } = useAdminQuery<UserOrganization[]>(["user_organizations"])
  const [showNewOrganization, setShowNewOrganization] = useState(false)
  const [userOrgFilter, setUserOrgFilter] = useState("")
  const [tabIndex, setTabIndex] = useState(parseInt(urlSearchParams.get("tabIndex") || "0"))

  const handleTabClick = (newTabIndex: number) => {
    setTabIndex(newTabIndex)
    urlSearchParams.set("tabIndex", newTabIndex.toString())
    history.push({ search: urlSearchParams.toString() })
  }

  const sortedOrgs = useMemo(() => {
    if (!userOrganizations) {
      return undefined
    }
    const userOrgsTypes: UserOrgObject = { archived: [], trial: [], active: [] }

    userOrganizations.forEach((userOrg) => {
      if (userOrg.discarded_at !== null) {
        userOrgsTypes.archived.push(userOrg)
      } else if (userOrg?.name.includes("(*Trial*)")) {
        userOrgsTypes.trial.push(userOrg)
      } else {
        userOrgsTypes.active.push(userOrg)
      }
    })

    return userOrgsTypes
  }, [userOrganizations])

  const filteredOrgs = useMemo(() => {
    let userOrgs: UserOrganization[] = []
    switch (tabIndex) {
      case 0:
        userOrgs = sortedOrgs?.active || []
        break
      case 1:
        userOrgs = sortedOrgs?.archived || []
        break
      case 2:
        userOrgs = sortedOrgs?.trial || []
        break
    }
    return userOrgs?.filter((userOrganization) =>
      userOrganization.name.toLowerCase().includes(userOrgFilter.toLowerCase()),
    )
  }, [userOrgFilter, userOrganizations, tabIndex, sortedOrgs])

  const activeColumns = useMemo(() => {
    if (tabIndex === 0) {
      return columns
    } else if (tabIndex === 1) {
      return archivedUserColumns
    } else {
      return trialUserColumns
    }
  }, [tabIndex])

  return (
    <Grid container>
      <Button onClick={() => setShowNewOrganization(true)} size="small" variant="contained" sx={{ marginY: 1 }}>
        New User Organization
      </Button>
      <NewUserOrganization open={showNewOrganization} onClose={() => setShowNewOrganization(false)} />
      <SearchTextField
        search={userOrgFilter}
        setSearch={setUserOrgFilter}
        value={userOrgFilter}
        label="Filter User Organizations"
        sx={{ marginY: 1 }}
        fullWidth
      />
      <Tabs value={tabIndex} onChange={(_, newValue) => handleTabClick(newValue)}>
        <Tab label="Active User Organizations" value={0} />
        <Tab label="Archived User Organizations" value={1} />
        <Tab label="Trial Organizations" value={2} />
      </Tabs>
      <Grid item xs={12} height="70vh">
        <DataTable
          data={filteredOrgs?.map((organization) => {
            const organizationCopy = {
              ...organization,
              link: (
                <Typography component={Link} to={`user_organizations/${organization.id}`} color="unset">
                  {organization.name}
                </Typography>
              ),
            }
            return organizationCopy
          })}
          columns={activeColumns}
          columnKey="id"
          defaultSort={tabIndex === 2 ? "trial_started_at" : "created_at"}
        />
      </Grid>
    </Grid>
  )
}
