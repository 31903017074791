import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { useFetchCurrentUser } from "./users"

export const useFetchDashboardMetrics = (userOrgId?: number) => {
  const axiosInstance = useAuthAxios()

  if (userOrgId === undefined) {
    const { data: currentUserData } = useFetchCurrentUser()
    userOrgId = currentUserData?.user_organization_id
  }

  return useQuery(
    ["custom_metrics", userOrgId],
    async () => {
      const response = await (await axiosInstance).get(`/custom_metrics/dashboard?user_organization_id=${userOrgId}`)

      return response.data
    },
    { enabled: userOrgId !== undefined },
  )
}
