import { FC, useState, useMemo } from "react"
import { Autocomplete, Button, Stack, TextField } from "@mui/material"
import { getMainSalesforceObjectList } from "../../../api/userOrganizations/salesforce"
import { useSalesforceShare } from "../../../api/clip_shares"
import { useToast } from "../ToastHook"
import { useCloseModalKey } from "../OpenModalHook"
import { ClipShare } from "../../../interfaces/clipShare"
import { SObjectInstance } from "../../../interfaces/salesforce"

import { shareModalKey } from "./index"

export const ShareButton: FC<{ clipData: ClipShare; value: string }> = ({ clipData, value }) => {
  const toast = useToast()
  const closeModalKey = useCloseModalKey(shareModalKey)
  const { mutate: salesforceShare } = useSalesforceShare(clipData, () => {
    closeModalKey()
    toast("Transcript successfully sent to Salesforce")
  })
  return (
    <Button
      variant="contained"
      size="small"
      onClick={() => {
        salesforceShare({
          baseUrl: window.location.host,
          sobjectId: value,
        })
      }}
    >
      Share
    </Button>
  )
}

export const SalesforceOptions: FC<{ clipData?: ClipShare }> = ({ clipData }) => {
  const [value, setValue] = useState<null | string>(null)
  const { data } = getMainSalesforceObjectList()
  const salesforceObjectMap = useMemo(() => {
    const map = new Map<string, SObjectInstance>()
    if (data) {
      data.forEach((sObjectInstance) => map.set(sObjectInstance.Id, sObjectInstance))
    }
    return map
  }, [data])

  const canShare = clipData && value

  return (
    <Stack direction="row" width="100%" spacing={1}>
      <Autocomplete
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        size="small"
        fullWidth
        options={Array.from(salesforceObjectMap.keys())}
        getOptionLabel={(option) => salesforceObjectMap.get(option)?.Name || ""}
        renderInput={(params) => <TextField {...params} label="Account" />}
      />
      {canShare ? (
        <ShareButton clipData={clipData} value={value} />
      ) : (
        <Button variant="contained" size="small" disabled>
          Share
        </Button>
      )}
    </Stack>
  )
}
