import { useMutation, useQuery, useQueryClient, UseMutationOptions } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { AxiosError } from "axios"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { useIsOpportunitiesEnabled } from "./users"
import { ApiErrorResponse } from "./api"
import { OpportunityData } from "./opportunities"

const prefix = "/opportunity_campaigns"

export const useFetchOpportunityCampaigns = () => {
  const axiosInstance = useAuthAxios()
  const { data: isOpportunitiesEnabled } = useIsOpportunitiesEnabled()

  return useQuery(
    ["opportunityCampaigns"],
    async () => {
      const response = await (await axiosInstance).get<OpportunityCampaign[]>(prefix)

      return response.data
    },
    {
      enabled: Boolean(isOpportunitiesEnabled),
    },
  )
}

export const useFetchOpportunityCampaignsMap = () => {
  const fetchData = useFetchOpportunityCampaigns()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((opportunityCampaign) => [opportunityCampaign.id, opportunityCampaign])),
    }
  }
  return fetchData
}

export const useCreateOpportunityCampaign = (onSuccess?: () => void, onError?: (errorMessage: string) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name: string; data: OpportunityData; email_cadence: string }) => {
      return (await axiosInstance).post(`${prefix}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
        queryClient.invalidateQueries(["opportunityCampaignsUsers"])
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<ApiErrorResponse>) => {
        if (onError) {
          onError(error.response?.data?.errorMessage || "An unknown error occurred")
        }
      },
    },
  )
}

export interface OpportunityCampaignUpdateParams {
  name: string
  data: OpportunityData
}

export const useUpdateOpportunityCampaigns = (
  id: unknown,
  onSuccess?: () => void,
  onError?: (errorMessage: string) => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: OpportunityCampaignUpdateParams) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["opportunityCampaigns"])
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError<ApiErrorResponse>) => {
        if (onError) {
          onError(error.response?.data?.errorMessage || "An unknown error occurred")
        }
      },
    },
  )
}

export const useUpdateOpportunityCampaignsOwnRelation = (
  id: unknown,
  options?: Omit<UseMutationOptions<unknown, AxiosError<ApiErrorResponse>, { email_cadence: string }>, "mutationFn">,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params) => {
      return (await axiosInstance).put(`${prefix}/${id}/users`, params)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["opportunityCampaignsUsers"])
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context)
        }
      },
    },
  )
}
