import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"

const prefix = "/prompts"

export const getPrompts = (type: string) => {
  const axiosInstance = useAuthAxios()
  const searchParams = new URLSearchParams({ type })

  return useQuery(["prompts", { type }], async () => {
    const response = await (
      await axiosInstance
    ).get<{ id: number; name: string }[]>(`${prefix}?${searchParams.toString()}`)

    return response.data
  })
}
