import { ICampaign, ICampaignRow } from "../interfaces/campaign"
import { goToSearch } from "../components/Home/SearchContainerFunctions"
import { useLocation } from "react-router"
import { defaultDateRange } from "../components/shared/config"
import { Column } from "../components/shared/DataTable"

export interface CampaignColumn {
  id: "created_at" | "name" | "creator" | "email_cadence" | "id" | "email" | "assignees" | "details" | "view"
  type: "string" | "datetime" | "array"
  label: string
  minWidth?: number
  width?: number
  maxWidth?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  link?: any
  component?: any
  title?: string
  listKey?: string
}

export const organizationColumns: readonly Column[] = [
  {
    id: "name",
    label: "Name",
    headerCellProps: {
      sx: {
        minWidth: 100,
        backgroundColor: "neutral.main",
        zIndex: 1,
      },
    },
  },
  {
    id: "email_cadence",
    label: "Notification Schedule",
    headerCellProps: {
      sx: {
        maxWidth: 200,
        backgroundColor: "neutral.main",
        zIndex: 1,
      },
    },
  },
  {
    id: "creator",
    label: "Creator",
    headerCellProps: {
      sx: {
        minWidth: 200,
        backgroundColor: "neutral.main",
        zIndex: 1,
      },
    },
    formatter: (creator: any) => creator?.email || "Unknown",
    sortProp: ["creator", "email"],
  },
  {
    id: "actions",
    label: "",
    headerCellProps: { sx: { minWidth: 170, backgroundColor: "neutral.main", zIndex: 1 } },
  },
]

export const createCampaignsRow = (campaign: ICampaign): ICampaignRow => {
  const locationSearchParams = useLocation().search
  const goToLink = () => {
    goToSearch(
      campaign?.data?.filterParams,
      campaign?.data?.searchTerms,
      campaign.id.toString(),
      locationSearchParams,
      defaultDateRange,
      campaign?.data?.mustIncludeTerms,
    )
  }
  const viewObj = { action: goToLink, label: "View" }
  return {
    id: campaign?.id,
    name: campaign?.name,
    creator: campaign?.creator?.email || "Unknown",
    created_at: campaign?.created_at,
    email_cadence: campaign?.email_cadence,
    assignees: campaign?.assignees,
    details: campaign,
    view: viewObj,
  }
}

const options = {
  delete: { message: "Campain sucessfully deleted!" },
  deleteMany: { message: "Campain sucessfully deleted!" },
  clone: { message: "Campaign sucessfully cloned!" },
  cloneMany: { message: "Campaign sucessfully cloned!" },
  update: { message: "Campaign successfully edited!" },
  unassign: { message: "Campain sucessfully unassigned!" },
  assign: { message: "Campaign successfully assigned to user." },
  notify: { message: "Notifications successfully updated!" },
}
export const getSuccessMessage = (currentOperation: string) => {
  if (currentOperation in options) {
    return options[currentOperation as keyof typeof options]
  } else {
    return { message: undefined, action: undefined }
  }
}

export const getDialogInfo = (campaign?: any, dialogOpen?: string) => {
  const options: Record<string, { title: string; text: string }> = {
    delete: {
      title: "Delete Campaign",
      text: "Are you sure you want to delete the campaign? This action is irreversible.",
    },
    deleteMany: {
      title: "Delete Campaign",
      text: "Are you sure you want to delete the campaign(s)? This action is irreversible.",
    },
    clone: {
      title: "Clone Campaign",
      text: `Are you sure you want to clone ${campaign?.name}?`,
    },
    cloneMany: {
      title: "Clone Campaigns",
      text: "Are you sure you want to clone these campaigns?",
    },
    update: {
      title: "Update Campaign",
      text: `Are you sure you want to update ${campaign?.name}?`,
    },
    unassign: {
      title: "Unassign Campaign",
      text: "Are you sure your want to unassign this campaign(s)?",
    },
    assign: {
      title: "Assign Campaign",
      text: "Are you sure your want to assign the campaign(s)?",
    },
    notify: {
      title: "Notification Emails",
      text: "How often would you like to be notified?",
    },
    "": {
      title: "",
      text: "",
    },
  }

  return options[dialogOpen || ""]
}
