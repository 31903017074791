import { Skeleton } from "@mui/material"
import { useFetchOrganizations } from "@api/organizations"
import { ChannelSelectorMultiple } from "@components/shared/Selectors/Multiple/Channel"
import { Dispatch, FC, SetStateAction, useContext } from "react"
import { FilterParams } from "@components/Home/interface"
import { BriefingsContainerContext } from "@src/containers/BriefingContainers/BriefingsContainer"

export const BriefingChannelAutocomplete: FC = () => {
  const { data: organizationsData, isFetched: organizationsIsFetched } = useFetchOrganizations()
  const { urlParamState, setUrlParamState } = useContext(BriefingsContainerContext)
  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, page: 0 })) as Dispatch<SetStateAction<FilterParams>>

  if (!organizationsIsFetched) {
    return <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
  } else {
    return (
      <ChannelSelectorMultiple
        options={organizationsData ? organizationsData.organizations : []}
        filterParams={urlParamState}
        setFilterParams={setUrlParamStateWithPage0}
        sx={{ flex: "30ch 1 0" }}
      />
    )
  }
}
