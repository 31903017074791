import { useQuery } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { OrganizationsMonitor } from "../../interfaces/organizationsMonitor"

export const useFetchOrganizationsMonitors = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["organizations_monitors"], async () => {
    const response = await (
      await axiosInstance
    ).get<{ monitors: OrganizationsMonitor[] }>("/admin/organizations_monitors")

    return response.data
  })
}
