import { RubyObject } from "./default"

export interface Bookmark extends RubyObject {
  title: string
  user_id: number
  visible: boolean
  industry_id: number
  bookmark_type: BookmarkType
}

export enum BookmarkType {
  Industry = "industry",
  Standard = "standard",
  WatchLater = "watch_later",
  Promotion = "promotion",
}
