import { FC } from "react"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip as RechartTooltip } from "recharts"
import { CustomToolTip } from "../shared/RechartsCustomTooltip"

export const HorizontalChart: FC<{ data: any[]; yAxisName: string }> = ({ data, yAxisName }) => {
  const tickFormatter = (value: string) => {
    if (value.length > 12) {
      value = value.substring(0, 12).trim() + "..."
    }
    return value
  }

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <XAxis type="number" />
        <YAxis type="category" dataKey={yAxisName} interval={0} tickFormatter={tickFormatter} width={115} />
        <RechartTooltip content={<CustomToolTip />} />
        <Bar dataKey="total_hits" fill="rgba(86, 111, 136, .5)" />
      </BarChart>
    </ResponsiveContainer>
  )
}
