import { FC, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { useSendDailyDigest } from "../../../api/admin/users"
import { DailyDigestPreview } from "./DailyDigestPreview"

export const SendDailyDigestModal: FC<{
  open: any
  onClose: any
  user: any
}> = ({ open, onClose, user }) => {
  const [timeValue, setTimeValue] = useState("")
  const [showPreview, setShowPreview] = useState(false)
  const { mutate: sendDailyDigest } = useSendDailyDigest()

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{`Send Daily Digest to ${user.email}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {!showPreview && (
            <>
              <Grid item xs={12}>
                <Typography>How many days back?</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={timeValue}
                  onChange={(event) => setTimeValue(event.target.value)}
                  label="Days"
                  size="small"
                />
              </Grid>
            </>
          )}
          {showPreview && (
            <Grid item xs={12}>
              <DailyDigestPreview user={user} timeValue={timeValue} />
            </Grid>
          )}
        </Grid>
        <DialogActions>
          {showPreview ? (
            <>
              <Button
                onClick={() => {
                  onClose()
                  sendDailyDigest({ id: user.id, daysBefore: timeValue })
                }}
              >
                Send
              </Button>
              <Button onClick={() => setShowPreview(false)}>Go Back</Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setShowPreview(true)
                }}
              >
                Confirm
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
