import { FC, useEffect, useMemo, useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Chip,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Redirect } from "react-router-dom"
import { useShareDashboard, useFetchDashboardPermissions, useUnshareDashboard } from "../../api/dashboards"
import { useFetchUsers, useFetchCurrentUser } from "../../api/users"
import { useToast } from "../shared/ToastHook"

export const ShareDashboardButton: FC<{ dashboardId: number; owner: string }> = ({ dashboardId, owner }) => {
  const { data } = useFetchUsers()
  const [open, setOpen] = useState(false)
  const setToast = useToast()

  const [selectedList, setSelectedList] = useState<string[]>([])
  const { data: dashboardPermissions } = useFetchDashboardPermissions(dashboardId)

  const { data: self } = useFetchCurrentUser()
  const canUnshare = (email: string) => self?.email === owner || self?.email === email

  const { mutate: shareDashboard } = useShareDashboard(dashboardId, () => {
    setToast("Successfully shared dashboard")
    setOpen(false)
  })

  const orgUsers = useMemo(() => data?.filter((user) => user.email !== self?.email), [self, data])

  const [redirect, setRedirect] = useState(false)
  const { mutate: unshareDashboard } = useUnshareDashboard(dashboardId, self?.email !== owner, (email) => {
    if (self?.email !== owner) {
      setRedirect(true)
    } else {
      setToast(`Successfully unshared dashboard with ${email}`)
    }
  })

  let sharedList: string[] = []
  if (dashboardPermissions) {
    sharedList = dashboardPermissions.map(({ email }) => email)
  }
  useEffect(() => {
    setSelectedList((list) => list.filter((email) => !sharedList.includes(email)))
  }, [dashboardPermissions])

  if (redirect) {
    return <Redirect to="/dashboards" />
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Share
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Share</DialogTitle>
        <DialogContent sx={{ overflowY: "unset" }}>
          <Autocomplete
            multiple
            value={selectedList}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                // getTagProps writes key for us but ESLint doesn't know that

                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Email" />}
            options={orgUsers ? orgUsers.map((user) => user.email) : []}
            getOptionDisabled={(email) => sharedList.includes(email)}
            onChange={(event, value) => setSelectedList(value.filter((email) => !sharedList.includes(email)))}
          />
          <Typography paddingTop={2}>People this dashboard is shared with:</Typography>
          <List dense>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <Typography variant="body1" component="span">{`${owner} `}</Typography>
                    <Typography variant="subtitle1" component="span" fontWeight="normal">
                      Creator
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {sharedList.map((email) => (
              <ListItem
                key={email}
                secondaryAction={
                  canUnshare(email) && (
                    <IconButton edge="end" aria-label="delete" onClick={() => unshareDashboard(email)}>
                      <CloseIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemText primary={<Typography variant="body1">{email}</Typography>} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => shareDashboard(selectedList)}>
            Share
          </Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
