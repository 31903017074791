import { GridSize } from "@mui/material"
import moment from "moment"
import { IOrganizationMeeting } from "../../api/interfaces"
import { startOfDay, endOfDay, addDays } from "date-fns"
import { FilterParams, FilterParamsSingle } from "../Home/interface"
import { FetchedMeeting } from "../../api/meetings"

export interface MeetingColumn {
  id:
    | "id"
    | "title"
    | "source_video_url"
    | "started_at"
    | "scraped_at"
    | "diarized_at"
    | "transcribed_at"
    | "transcoded_at"
    | "indexed_at"
    | "finished_at"
    | "published_at"
    | "run_job"
    | "duration"
    | "created_at"
    | "updated_at"
    | "user_organization"
    | "organization"
    | "name"
    | "is_spam"
    | "spam_certainty"
    | "in_progress"
  label: string
  type: "string" | "number" | "datetime" | "boolean" | "float"
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: () => void
  link?: any
}

export const formatDatetime: any = (datetime: string) => {
  const momentDate = moment(datetime)
  if (momentDate.isValid() === false) {
    return ""
  }

  return moment(datetime, "YYYY-MM-DDTh:mm:ss").format("MMMM Do YYYY, h:mm:ss a")
}

export const formatDate: any = (datetime: string) => {
  const momentDate = moment(datetime)
  if (momentDate.isValid() === false) {
    return ""
  }

  return moment(datetime, "YYYY-MM-DDTh:mm:ss").format("MMMM Do YYYY")
}

export const formatSeconds = (seconds: number) => {
  if (seconds === null) {
    return ""
  }
  try {
    return new Date(seconds * 1000).toISOString().substr(11, 8)
  } catch (e) {
    console.log("Error formatting seconds", e)
    return ""
  }
}

export const formatBool: any = (bool: boolean) => {
  return bool ? "Yes" : "No"
}

export const formatPercent: any = (nmbr: any) => {
  return nmbr?.toLocaleString("en", { style: "percent" })
}

export const capitalizeWord: any = (word: string) => {
  return word[0]?.toUpperCase() + word?.slice(1)
}

export const meetingTableColumns: readonly MeetingColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  { id: "title", label: "Title", minWidth: 170, link: "/admin/meetings", type: "string" },
  {
    id: "duration",
    label: "Duration",
    format: formatSeconds,
    type: "number",
  },
  {
    id: "source_video_url",
    label: "Source Video Url",
    minWidth: 170,
    type: "string",
  },
  {
    id: "published_at",
    label: "Published At",
    minWidth: 170,
    format: formatDate,
    type: "datetime",
  },
  {
    id: "is_spam",
    label: "Spam?",
    type: "boolean",
    format: formatBool,
  },
  {
    id: "spam_certainty",
    label: "Spam Certainty",
    type: "float",
    format: formatPercent,
  },
  {
    id: "in_progress",
    label: "In Progress?",
    type: "boolean",
    format: formatBool,
    minWidth: 200,
  },
  {
    id: "started_at",
    label: "Started At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "scraped_at",
    label: "Scraped At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "transcoded_at",
    label: "Transcoded At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "diarized_at",
    label: "Diarized At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "transcribed_at",
    label: "Transcribed At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "indexed_at",
    label: "Indexed At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "finished_at",
    label: "Finished At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
]

export const createMeetingRow: any = (meeting: IOrganizationMeeting) => {
  return {
    id: meeting.id,
    title: meeting.title,
    duration: meeting.duration_seconds,
    source_video_url: meeting?.source_video_url,
    published_at: meeting.published_at,
    started_at: meeting?.transcription_job?.started_at,
    scraped_at: meeting?.transcription_job?.scraped_at,
    in_progress: meeting?.transcription_job?.in_progress,
    is_spam: meeting?.is_spam,
    spam_certainty: meeting?.spam_certainty,
    diarized_at: meeting?.transcription_job?.diarized_at,
    transcribed_at: meeting?.transcription_job?.transcribed_at,
    transcoded_at: meeting?.transcription_job?.transcoded_at,
    indexed_at: meeting?.transcription_job?.indexed_at,
    finished_at: meeting?.transcription_job?.finished_at,
    created_at: meeting.created_at,
    updated_at: meeting.updated_at,
  }
}

export const a11yProps = (index: number | string) => {
  return {
    id: `${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  }
}

export const geographySelectorConfig: { label: string; type: "states" | "counties" | "cities"; xs: GridSize }[] = [
  { label: "State", type: "states", xs: 8 as GridSize },
  { label: "County", type: "counties", xs: 14 as GridSize },
  { label: "City", type: "cities", xs: 12 as GridSize },
]

export const countyStateSelectorConfig = [
  { label: "State", type: "states", xs: 6 as GridSize },
  { label: "County", type: "counties", xs: 6 as GridSize },
]

export const defaultLocationFilterParams: FilterParams = {
  cities: [] as number[],
  counties: [] as number[],
  states: [] as number[],
  organizations: [] as number[],
  channel_types: [] as number[],
  city_population: [-1, -1],
  county_population: [-1, -1],
  city_income: [-1, -1],
  county_income: [-1, -1],
}

export const defaultCountyStateFilterParams = {
  counties: [] as number[],
  states: [] as number[],
}

export const defaultCountyStateFilterParamsSingle: FilterParamsSingle = {
  counties: null,
  states: null,
  cities: null,
  organizations: null,
}

export const defaultOrganizationFilterParams = {
  organizations: [],
}

export const meetingLocationString = (meeting: FetchedMeeting) => {
  if (meeting?.city === null) {
    if (meeting?.county === null) {
      return meeting?.state
    }

    return `${meeting?.county}, ${meeting?.state}`
  }

  return `${meeting?.city}, ${meeting?.state}`
}

export const rowsToCsvDownload = (rows: string[][], title: string) => {
  const csvContent = `${rows.map((r) => r.join(",")).join("\n")}`
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", `${title.replace(/ /g, "_")}.csv`)
  link.click()
}

export const defaultDateRange: [Date, Date] = [startOfDay(addDays(new Date(), -7)), endOfDay(new Date())]
export const defaultCreateMeetingsDateRange: [Date, Date] = [
  startOfDay(addDays(new Date(), -365)),
  endOfDay(new Date()),
]
export const defaultDateRangeGeogMeetings: [Date, Date] = [startOfDay(addDays(new Date(), -7)), endOfDay(new Date())]

export const getLimit = (min: number, max: number, value: any) => {
  if (value > max) {
    return max
  } else if (value < min) {
    return min
  } else {
    return value
  }
}

export const formatUrl = (url: string) => {
  return url?.substring(0, 40)
}
