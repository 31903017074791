import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useFetchUserOrganization, useUpdateCredits } from "../../../../api/admin/user_organizations"
import { useCloseModalKey, useModalKey, useOpenModalKey } from "../../../shared/OpenModalHook"
import { useToast } from "../../../shared/ToastHook"

const modalKey = "EDIT_TOTAL_CREDITS_MODAL"

export const EditCreditsModal: FC<{
  userOrgId: string
  totalCredits?: number | null
}> = ({ userOrgId, totalCredits }) => {
  const open = useModalKey(modalKey)
  const closeModalKey = useCloseModalKey(modalKey)
  const [credits, setCredits] = useState(totalCredits)
  const { mutate: updateCredits, isSuccess, isError } = useUpdateCredits(userOrgId)
  const setToast = useToast()

  const onConfirmation = () => {
    if (typeof credits === "number" && credits !== totalCredits) {
      updateCredits({ total_credits: credits })
    }
    closeModalKey()
  }

  const onClose = () => {
    closeModalKey()
    setCredits(totalCredits)
  }

  useEffect(() => {
    if (isSuccess) {
      setToast("Total credits updated successfully", 3000)
    }
    if (isError) {
      setToast("Failed to update total credits", 5000)
    }
  }, [isSuccess, isError])

  return (
    <Dialog open={open} onClose={closeModalKey}>
      <DialogTitle>Edit Total Credits</DialogTitle>
      <DialogContent>
        <Stack direction="row" padding={1}>
          <TextField
            value={credits}
            type="number"
            label="Total Credits"
            inputProps={{ min: 0 }}
            onChange={(e) => setCredits(parseFloat(e.target.value))}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirmation}>Confirm</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export const TotalCredits: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: userOrganization } = useFetchUserOrganization(userOrganizationId)
  const openModalKey = useOpenModalKey(modalKey)

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography>
          Total Credits:&nbsp;
          {userOrganization?.total_credits?.toLocaleString()}
        </Typography>
        <IconButton onClick={openModalKey}>
          <EditIcon />
        </IconButton>
      </Stack>
      <EditCreditsModal userOrgId={userOrganizationId} totalCredits={userOrganization?.total_credits} />
    </>
  )
}
