import { FC } from "react"
import { Box, Stack } from "@mui/material"
import { useFetchMeeting } from "@api/promotions/meetings"
import { useMeetingSearch } from "@api/search"
import { ShareDisplay } from "./ShareDisplay"
import { useLocation } from "react-router-dom"

export const MeetingShare: FC<{ meetingId: string; searchTerms?: string[] | null }> = ({ meetingId, searchTerms }) => {
  const { data, isLoading: meetingIsLoading, isError } = useFetchMeeting(meetingId)
  const searchParams = searchTerms?.map((term) => ["terms", term]) || []
  const allParams = new URLSearchParams(useLocation().search)
  const videoStartTime = allParams.get("start_time")
  const otherParams: string[][] = []
  allParams.forEach((value, key) => otherParams.push([key, value]))
  const { data: transcriptsByPhrase, isLoading: transcriptsLoading } = useMeetingSearch(
    [...searchParams, ...otherParams],
    meetingId,
  )
  return (
    <Stack width="100%" alignItems="center">
      <Box padding={4} width={"100%"} boxSizing={"border-box"}>
        <ShareDisplay
          isError={isError}
          isLoading={meetingIsLoading}
          transcripts={transcriptsByPhrase ?? { terms: [], total_hits: 0 }}
          transcriptsLoading={transcriptsLoading}
          organization={data?.organization}
          meeting={data?.meeting}
          searchTerms={searchTerms ? searchTerms : []}
          startTime={videoStartTime ? parseInt(videoStartTime) : undefined}
        />
      </Box>
    </Stack>
  )
}
