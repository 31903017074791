import { FC, useContext } from "react"
import { useLocation } from "react-router-dom"
import { HomePageContext } from "../../../pages/HomePage"
import { useSearch } from "../../../api/search"
import { MeetingListItem } from "../MeetingListItem"
import { Meeting } from "../../../interfaces/meeting"

export const ListView: FC = () => {
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const homePageContext = useContext(HomePageContext)
  const { searchTerms, mustIncludeTerms, proximity, exclude } = homePageContext.homePageParams
  const { data: meetingsSearchData } = useSearch(queryParams)

  const meetingsMap = new Map<number, Meeting>()
  meetingsSearchData?.meetings.forEach((meeting) => meetingsMap.set(meeting.id, meeting))
  const searchResult = meetingsSearchData?.meeting_hits.map((meetingHit) => {
    const meeting = meetingsMap.get(meetingHit.id)
    if (meeting) {
      return (
        <MeetingListItem
          meeting={meeting}
          hits={meetingHit}
          key={meeting.id}
          searchWords={searchTerms}
          hasViewed={meetingHit.already_viewed}
          proximity={proximity}
          mustHaveTerms={mustIncludeTerms}
          exclude={exclude}
        />
      )
    } else {
      return null
    }
  })
  return <>{searchResult}</>
}
