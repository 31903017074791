import { FC, ReactElement } from "react"
import { Tabs, Tab, Stack, StackProps } from "@mui/material"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

const TabPanel: FC<{ tabs: { hash: string; label: string; component: ReactElement }[] } & StackProps> = (props) => {
  const { tabs } = props
  const { hash } = useLocation()

  const activeTab = tabs.findIndex((tab) => tab.hash === hash.slice(1))

  return (
    <Stack {...props}>
      <Tabs value={activeTab}>
        {tabs.map(({ label, hash }, index) => (
          <Tab label={label} key={index} component={Link} to={(location: object) => ({ ...location, hash })} />
        ))}
      </Tabs>
      {tabs[activeTab]?.component}
    </Stack>
  )
}

export default TabPanel
