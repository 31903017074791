import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Prompt } from "@src/interfaces/prompt"

const prefix = "/admin/prompts"

export const getPrompts = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["prompts"], async () => {
    const response = await (await axiosInstance).get<Prompt[]>(prefix)
    return response.data
  })
}

interface PromptParams {
  name: string
  text: string
  is_personal: boolean
}

export const useCreatePrompt = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: PromptParams) => {
      return (await axiosInstance).post(prefix, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["prompts"])
      },
    },
  )
}

export const useUpdatePrompt = (id: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: PromptParams) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["prompts"])
      },
    },
  )
}

export const getPlaygroundResult = (time: number, meetingId: unknown, prompt: string, userId?: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["prompts", "playground", time],
    async () => {
      let url = `${prefix}/playground?meeting_id=${meetingId}&prompt=${prompt}`
      if (userId) {
        url += `&user_id=${userId}`
      }
      // this is an incomplete type, the actual return is fairly complicated so I just list what I need
      const response = await (
        await axiosInstance
      ).get<
        {
          candidates?: {
            content: {
              parts?: {
                text: string
              }[]
              finishReason: string
            }
            safetyRatings: {
              category: string
              blocked?: boolean
            }[]
          }[]
          promptFeedback?: {
            blockReason: string
          }
        }[]
      >(url)

      return response.data
    },
    {
      enabled: Boolean(time),
    },
  )
}
