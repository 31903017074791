import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { PublicFlag } from "@src/interfaces/flag"

const prefix = "feature_flags"

export const useGetFeatureFlagByName = (name: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["flags", name], async () => {
    const response = await (await axiosInstance).get<PublicFlag>(`${prefix}/${name}`)
    if (response.data === null) {
      return undefined
    }
    return response.data
  })
}
