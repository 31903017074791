import { FC, useEffect, useState } from "react"
import AddCircleIcon from "@mui/icons-material/AddCircleOutlined"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import { ListItemIcon, Typography, List, Divider, ListItem, ListItemButton, ListItemText, Chip } from "@mui/material"
import moment from "moment"
import ShareIcon from "@mui/icons-material/Share"
import { ConstructionOutlined } from "@mui/icons-material"
import {
  FetchedBookmarksItem,
  FetchedClipShare,
  useFetchAllUserBookmarks,
  useFetchSharedBookmarks,
} from "../../../api/bookmarks"
import { useFetchIndustryBookmarks } from "../../../api/admin/bookmarks"
import { Bookmark } from "../../../interfaces/bookmark"
import { LoadingEllipsis } from "../../shared/LoadingEllipsis"

const BookmarkAddSelector: FC<{
  transcriptId: number | string
  onClickAddToBookmark: (id: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>, clip_type: string) => void
  onClickRemoveBookmark: (
    clip: FetchedClipShare,
    bookmark_id: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void
}> = ({ transcriptId, onClickAddToBookmark, onClickRemoveBookmark }) => {
  const { data: myBookmarksData, isLoading: personalLoading } = useFetchAllUserBookmarks()
  const { data: sharedBookmarksData, isLoading: sharedLoading } = useFetchSharedBookmarks()
  const { data: industryBookmarksData, isLoading: industryLoading } = useFetchIndustryBookmarks()
  const [isLoading, setIsLoading] = useState(false)
  const [bookmarks, setBookmarks] = useState<FetchedBookmarksItem[]>([])

  useEffect(() => {
    setIsLoading(personalLoading || sharedLoading || industryLoading)
  }, [personalLoading, sharedLoading, industryLoading])

  useEffect(() => {
    if (!myBookmarksData || !sharedBookmarksData || !industryBookmarksData) return

    let fetchedBookmarks: FetchedBookmarksItem[] = [
      ...(myBookmarksData || []),
      ...(sharedBookmarksData || []),
      ...(industryBookmarksData || []),
    ]
    fetchedBookmarks = fetchedBookmarks.filter(
      (bookmark: Bookmark, index, self) => index === self.findIndex((p: Bookmark) => p.id === bookmark.id),
    )
    const watchLaterIndex = fetchedBookmarks.findIndex((bookmark: Bookmark) => bookmark.title === "Watch Later")
    if (watchLaterIndex > -1) {
      const watchLater = fetchedBookmarks.splice(watchLaterIndex, 1)[0]
      fetchedBookmarks.unshift(watchLater)
    }
    setBookmarks(fetchedBookmarks)
  }, [myBookmarksData, sharedBookmarksData, industryBookmarksData])

  const handleClick = (bookmark: FetchedBookmarksItem, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const clip = bookmark.clips.find((clip) => clip.transcript_id === transcriptId)
    if (clip) {
      setBookmarks((prevBookmarks) =>
        prevBookmarks.map((prevBookmark) => {
          if (prevBookmark.id === bookmark.id) {
            prevBookmark.clips = prevBookmark.clips.filter((prevClip) => prevClip.transcript_id !== transcriptId)
          }
          return prevBookmark
        }),
      )
      onClickRemoveBookmark(clip, bookmark.id, event)
    } else {
      setBookmarks((prevBookmarks) =>
        prevBookmarks.map((prevBookmark) => {
          if (prevBookmark.id === bookmark.id) {
            prevBookmark.clips.push({ transcript_id: transcriptId } as FetchedClipShare)
          }
          return prevBookmark
        }),
      )
      onClickAddToBookmark(bookmark.id, event, bookmark.bookmark_type === "industry" ? "industry" : "standard")
    }
  }

  return (
    <>
      <Typography variant="h5" marginTop={1} color="primary.main" paddingLeft={3} marginBottom={1}>
        Save to...
      </Typography>
      <Divider sx={{ width: "100%" }} />
      {isLoading ? (
        <>
          <Typography>Folders Loading</Typography> <LoadingEllipsis />{" "}
        </>
      ) : (
        <List sx={{ bgcolor: "white" }}>
          {bookmarks.length > 0 &&
            bookmarks.map(
              (bookmark: FetchedBookmarksItem) =>
                bookmark && (
                  <ListItem
                    key={bookmark.id}
                    sx={{ paddingLeft: 1, backgroundColor: "white" }}
                    disablePadding
                    disableGutters
                  >
                    <ListItemButton onClick={(event) => handleClick(bookmark, event)} dense>
                      <ListItemIcon>
                        {bookmark.clips.find((clip) => clip.transcript_id === transcriptId) ? (
                          <RemoveCircleIcon color="error" />
                        ) : (
                          <AddCircleIcon color="primary" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "caption",
                          color: "primary.main",
                          fontWeight: 600,
                        }}
                        id={bookmark.id.toString()}
                        primary={bookmark.title}
                        secondary={moment(bookmark.updated_at).format("MM-DD-YYYY")}
                      />
                      {bookmark.visible && !bookmark.industry_id && (
                        <Chip sx={{ margin: 1 }} color="primary" icon={<ShareIcon />} label="Public" />
                      )}
                      {bookmark.industry_id && (
                        <Chip sx={{ margin: 1 }} color="primary" icon={<ConstructionOutlined />} label="Industry" />
                      )}
                    </ListItemButton>
                  </ListItem>
                ),
            )}
        </List>
      )}
    </>
  )
}

export default BookmarkAddSelector
