import { Autocomplete, Button, IconButton, Stack, TextField } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { FetchedUsers, useAddSignalsUatAllowedUsers, useFetchUsers } from "@src/api/admin/users"
import { FC, useEffect, useState } from "react"
import { useToast } from "@src/components/shared/ToastHook"
import TaskAltIcon from "@mui/icons-material/TaskAlt"

export const AddAllowedUsersKey = "AddWhitelistedUser"

export const AddAllowedUsers: FC = () => {
  const { data: users } = useFetchUsers()
  const { mutate: addSignalsUatAllowedUser, isSuccess: addSignalsUatUsersSuccess } = useAddSignalsUatAllowedUsers()

  const [selectedUsers, setSelectedUsers] = useState<FetchedUsers[]>([])
  const [formVisible, setFormVisible] = useState(false)
  const setToast = useToast()

  const handleAddSignalsUatAllowedUsers = () => {
    addSignalsUatAllowedUser({ user_ids: selectedUsers.map((user) => user.id) })
  }

  useEffect(() => {
    if (addSignalsUatUsersSuccess) {
      setToast("User/s added successfully", 3000, <TaskAltIcon />)
      setSelectedUsers([])
    }
  }, [addSignalsUatUsersSuccess])

  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <IconButton onClick={() => setFormVisible(!formVisible)}>
        <AddIcon />
      </IconButton>
      <Stack display={formVisible ? "flex" : "none"} spacing={1} direction="row" alignItems="center">
        <Autocomplete
          multiple
          options={users?.sort((user1, user2) => user1?.email.localeCompare(user2?.email)) || []}
          value={selectedUsers}
          onChange={(_, newValue) => setSelectedUsers(newValue)}
          getOptionLabel={(option) => option.email}
          renderInput={(params) => <TextField {...params} label="Select Users" />}
          size="small"
          sx={{ minWidth: 300 }}
        />
        <Button variant="contained" sx={{ minWidth: 95 }} onClick={handleAddSignalsUatAllowedUsers}>
          Add Users
        </Button>
      </Stack>
    </Stack>
  )
}
