import { FC } from "react"
import { Box, Tabs, Tab } from "@mui/material"
import { useLocation, Link, useRouteMatch, Switch, Route } from "react-router-dom"
import { Layout } from "../components/shared/Layout"
import { SettingsTab } from "../components/Account/SettingsTab"
import { OrganizationTab } from "../components/Account/OrganizationTab"
import { useIsAdmin } from "../api/users"

export const AccountPage: FC = () => {
  const locationArray = useLocation().pathname.split("/")
  let tab = "settings"
  if (locationArray[2] && locationArray[2] === "organization") {
    tab = "organization"
  }
  const { url } = useRouteMatch()
  const isAdmin = useIsAdmin()

  return (
    <Layout title="Account">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} paddingX="120px">
        <Tabs value={tab}>
          <Tab label="Settings" value="settings" component={Link} to={url} />
          {isAdmin && <Tab label="Organization" value="organization" component={Link} to={`${url}/organization`} />}
        </Tabs>
      </Box>
      <Box paddingX="120px">
        <Switch>
          {isAdmin && <Route path={`${url}/organization`} component={OrganizationTab} />}
          <Route path={url} component={SettingsTab} />
        </Switch>
      </Box>
    </Layout>
  )
}
