import { FC, useState, useContext, Fragment, useEffect } from "react"
import {
  TextField,
  Stack,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material"
import { useNewOrganization } from "../../../../api/organizations"
import { unpackSetters } from "../../../shared/unpackSetters"
import { OrganizationWizardContext } from "./Context"
import { ActionCard } from "./ActionCard"
import { generateSimpleActions } from "./Context"
import { Divider } from "./Divider"
import Loading from "../../../shared/Loading"
import { GeneralScraperDetailsContext } from "../OrganizationForm/Context"
import { GeneralScraperDetails } from "../../../../api/interfaces"
import { MeetingHashResponse, useGatherMeetingCandidates, useInspectMeetings } from "../../../../api/admin/wizard"
import { gatherMeetingsParams, inspectMeetingsParams } from "./searchParams"

export const IdentifyData: FC = () => {
  const [meetingData, setMeetingData] = useState<MeetingHashResponse[]>([])
  const { value, setValue } = useContext(OrganizationWizardContext)
  const {
    name,
    separatedMeetingHtml,
    meetingIdentifier,
    meetingIdentifierRegex,
    url,
    additionalUrls,
    navigationMethod,
    titleIdentifier,
    titleIndex,
    scraperActions,
    setStep,
    meetingIdentifierIframe,
    meetingBrowserWaitCode,
    head,
    jsonReplaceCode,
  } = value
  const { setTitleIdentifier, setTitleIndex, setScraperActions, setSeparatedMeetingHtml } = unpackSetters(
    value,
    setValue,
  )
  const { value: generalDetailsValue } = useContext(GeneralScraperDetailsContext)
  const { identifier_actions } = generalDetailsValue as GeneralScraperDetails
  const [advanced, setAdvanced] = useState(navigationMethod === "json" ? true : false)
  const onAdvanceToggleClick = (checked: boolean) => {
    if (navigationMethod === "json") {
      setAdvanced(true)
    }
    setAdvanced(checked)
  }
  let isJsonScraper = navigationMethod === "json"
  const { mutate: createOrganization } = useNewOrganization()
  const [searchOpts, setSearchOpts] = useState<string | undefined>(undefined)
  const [gatherMeetingSearchOpts, setGatherMeetingSearchOpts] = useState<string | undefined>(undefined)
  const { isLoading, isFetching, refetch } = useInspectMeetings(searchOpts, setMeetingData)
  const { isLoading: gatherLoading } = useGatherMeetingCandidates(gatherMeetingSearchOpts, setSeparatedMeetingHtml)
  const [useSearch, setUseSearch] = useState(false)

  const inspectMeetings = () => {
    setSearchOpts(inspectMeetingsParams(value, identifier_actions, advanced))
    setUseSearch(!useSearch)
  }

  const addAction = (index: number) => () =>
    setScraperActions((prevActions) => {
      const newActions = [...prevActions]
      newActions.splice(index, 0, {
        type: "URI_NAVIGATE",
        identifier: "",
        index: 0,
        regex: false,
      })
      return newActions
    })

  const validated = meetingData.some(
    ({ title, published_at, source_video_url }) => title && published_at && source_video_url,
  )

  useEffect(() => {
    if (searchOpts === undefined) return
    refetch()
  }, [useSearch])

  useEffect(() => {
    if (!separatedMeetingHtml.length) {
      setGatherMeetingSearchOpts(gatherMeetingsParams(value, identifier_actions))
    }
  }, [])

  return (
    <>
      <Stack spacing={1} marginX="20%">
        <Typography>{`URL: ${url}`}</Typography>
        <Typography>{`Meeting identifier: ${meetingIdentifier}`}</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h4">Data Extraction</Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={advanced} onChange={(event) => onAdvanceToggleClick(event.target.checked)} />}
              label="Advanced"
            />
          </FormGroup>
        </Stack>
        <Stack>
          {advanced ? (
            <>
              {scraperActions.map((scraperAction, index) => {
                if (scraperAction.type === "BROWSER_NAVIGATE") {
                  isJsonScraper = false
                }

                return (
                  <Fragment key={`${scraperAction.type}${index}`}>
                    <Divider onClick={addAction(index)} />
                    <ActionCard
                      scraperAction={scraperAction}
                      index={index}
                      last={index === scraperActions.length - 1}
                      setScraperActions={setScraperActions}
                      isGeneralScraper={!isJsonScraper}
                    />
                  </Fragment>
                )
              })}
              <Divider onClick={addAction(scraperActions.length)} />
            </>
          ) : (
            <Stack direction="row" spacing={1}>
              <TextField
                label="Title Identifier"
                size="small"
                value={titleIdentifier}
                onChange={(event) => setTitleIdentifier(event.target.value)}
                sx={{ flex: "1" }}
              />
              <TextField
                label="Index"
                size="small"
                value={titleIndex}
                onChange={(event) => {
                  const newValue = parseInt(event.target.value, 10)
                  if (Number.isNaN(newValue)) {
                    setTitleIndex(0)
                  } else {
                    setTitleIndex(newValue)
                  }
                }}
              />
            </Stack>
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button onClick={() => inspectMeetings()}>Try Data Extraction</Button>
          {(isLoading || gatherLoading || isFetching) && <Loading />}
          <Stack direction="row" spacing={1}>
            <Button onClick={() => setStep(1)}>Back</Button>
            <Button
              disabled={!validated}
              onClick={() =>
                createOrganization({
                  name,
                  video_archive_id: url,
                  video_archive_type: "general",
                  video_archive_details: {
                    identifier_actions: identifier_actions,
                    head: head,
                    navigation_method: navigationMethod,
                    json_code: jsonReplaceCode,
                    additional_urls: additionalUrls,
                    meeting_identifier: meetingIdentifier,
                    meeting_identifier_regex: meetingIdentifierRegex,
                    meeting_browser_wait_code: meetingBrowserWaitCode,
                    actions: advanced ? scraperActions : generateSimpleActions(titleIdentifier, titleIndex),
                    iframe: meetingIdentifierIframe,
                  },
                })
              }
            >
              Create Channel
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="70%">Raw HTML</TableCell>
              <TableCell width="30%">Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {separatedMeetingHtml.map((html, index) => (
              <TableRow key={html}>
                <TableCell>{html}</TableCell>
                <TableCell>
                  {meetingData[index] && (
                    <>
                      {(!meetingData[index].title ||
                        !meetingData[index].published_at ||
                        !meetingData[index].source_video_url) && <Typography fontStyle="italic">Skipped</Typography>}
                      <Typography>{`Title: ${meetingData[index].title}`}</Typography>
                      <Typography>{`Date: ${meetingData[index].published_at}`}</Typography>
                      <Typography>{`Source video url: ${meetingData[index].source_video_url}`}</Typography>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
