import { useState, FC } from "react"
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Dialog,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"
import { ProxyRegionParams, useCreateProxyRegion } from "../../../api/admin/proxies"

const createKey = "CREATE_PROXY_REGION"

export const CreateModal: FC = () => {
  const open = useModalKey(createKey)
  const closeModalKey = useCloseModalKey(createKey)
  const [formData, setFormData] = useState<ProxyRegionParams>({
    region: "",
    abbreviation: "",
    youtube_blocked: false,
    granicus_blocked: false,
    swagit_blocked: false,
    active: true,
  })
  const { mutate: createProxyRegion } = useCreateProxyRegion(() =>
    setFormData({
      region: "",
      abbreviation: "",
      youtube_blocked: false,
      granicus_blocked: false,
      swagit_blocked: false,
      active: true,
    }),
  )

  const handleSubmit = () => {
    createProxyRegion(formData)
    closeModalKey()
  }

  return (
    <Dialog fullWidth open={open} onClose={closeModalKey}>
      <DialogTitle>Create Proxy Region</DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Stack spacing={2}>
          <TextField
            value={formData.abbreviation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, abbreviation: e.target.value })
            }
            label="Abbreviation"
          />
          <TextField
            value={formData.region}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, region: e.target.value })}
            label="Region"
          />
          <FormControl>
            <InputLabel id="active-id">Active</InputLabel>
            <Select
              labelId="active-select-label"
              id="active-select"
              value={formData.active}
              label="Active"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, active: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="youtube-block-id">Youtube Block</InputLabel>
            <Select
              labelId="youtube-block-select-label"
              id="youtube-block-select"
              value={formData.youtube_blocked}
              label="Youtube Block"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, youtube_blocked: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="granicus-block-id">Granicus Block</InputLabel>
            <Select
              labelId="granicus-block-select-label"
              id="granicus-block-select"
              value={formData.granicus_blocked}
              label="Granicus Block"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, granicus_blocked: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="swagit-block-id">Swagit Block</InputLabel>
            <Select
              labelId="swagit-block-select-label"
              id="swagit-block-select"
              value={formData.swagit_blocked}
              label="Swagit Block"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, swagit_blocked: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={formData.abbreviation === "" || formData.region === ""} onClick={handleSubmit}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
