import { useContext, FC, useMemo, useEffect } from "react"
import { Typography, Stack } from "@mui/material"
import { GeographySelectorMultiple } from "../Selectors/Multiple/Geographies"
import { ChannelSelectorMultiple } from "../Selectors/Multiple/Channel"
import { unpackSetters } from "../unpackSetters"
import { CampaignWizardContext } from "./Context"
import { useFetchGeographies } from "../../../api/geographies"
import { useFetchOrganizations } from "../../../api/organizations"
import { useFetchChannelTypes } from "../../../api/channel_types"
import { ChannelType } from "../../../interfaces/channelType"
import { TypeSelectorMultiple } from "../Selectors/Multiple/Types"
import { RangeSlider } from "../Selectors/Multiple/Range"

const filterSx = {
  flexGrow: "1",
  minWidth: "0",
  ".MuiOutlinedInput-root": {},
}

export const Geographies: FC = () => {
  const { value, setValue } = useContext(CampaignWizardContext)
  const { filterParams } = value
  const { setFilterParams } = unpackSetters(value, setValue)
  const { data: geographiesData } = useFetchGeographies()
  const { data: organizationsData } = useFetchOrganizations()
  const { data: channelTypesData } = useFetchChannelTypes()
  const modifiedGeographiesData = {
    cities: [],
    counties: [],
    states: [],
    organizations: [],
    ...geographiesData,
  }

  const channelTypeOptions = useMemo(() => {
    let types: ChannelType[] = []
    if (channelTypesData && organizationsData && organizationsData.organizations) {
      types = [...channelTypesData]
      if (filterParams.states.length > 0) {
        const channelTypeIds = organizationsData.organizations
          .filter((org: any) => filterParams.states.includes(org.state_id))
          .map((org: any) => org.channel_type_id)
        types = types.filter((channelType) => channelTypeIds.includes(channelType.id))
      }

      if (filterParams.counties.length > 0 || filterParams.cities.length > 0) {
        const channelTypeIds = organizationsData.organizations
          .filter(
            (org: any) => filterParams.counties.includes(org.county_id) || filterParams.cities.includes(org.city_id),
          )
          .map((org: any) => org.channel_type_id)
        types = types.filter((channelType) => channelTypeIds.includes(channelType.id))
      }

      if (types.length > 0) {
        types = [{ id: -2, name: "Select All" } as ChannelType, ...types]
      }
    }
    return types
  }, [channelTypesData, filterParams, organizationsData])

  const filteredOrgs = useMemo(() => {
    const orgs = { ...organizationsData }
    if (organizationsData && organizationsData.organizations && filterParams.channel_types.length > 0) {
      orgs.organizations = orgs.organizations?.filter((org: any) =>
        filterParams.channel_types.includes(org.channel_type_id),
      )
    }
    return orgs
  }, [organizationsData, filterParams.channel_types])

  const filteredChannelTypes = useMemo(() => {
    let types: ChannelType[] = []
    if (filterParams && channelTypeOptions) {
      if (filterParams.channel_types.includes(-2)) {
        types = channelTypeOptions.filter((channelType) => channelType.id !== -2)
      } else {
        types = channelTypeOptions.filter((channelType) => filterParams.channel_types.includes(channelType.id))
      }
    }
    return types
  }, [filterParams, channelTypeOptions])

  // UseEffect to setFilterParams when there is a mismatch between filterParams.channelTypesIds and filteredChannelTypes
  useEffect(() => {
    if (filteredChannelTypes) {
      const mapTypes = filteredChannelTypes.map((channelType) => channelType.id)
      if (filterParams.channel_types.length !== mapTypes.length) {
        const params = { ...filterParams }
        params.channel_types = mapTypes
        setFilterParams(params)
      }
    }
  }, [filterParams, filteredChannelTypes])

  return (
    <>
      <Stack spacing={1} marginBottom={2}>
        <Typography variant="body2">Step Three</Typography>
        <Typography variant="h1">Choose Where To Monitor</Typography>
        <Typography variant="body1">
          Select and customize where and on which channels you&apos;d like us to focus our efforts. You can update this
          and any other information on your campaign using the Edit button later on.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0.5} width="100%">
        <GeographySelectorMultiple
          options={modifiedGeographiesData}
          label="State"
          type="states"
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "5ch", ...filterSx }}
        />
        <GeographySelectorMultiple
          options={modifiedGeographiesData}
          label="County"
          type="counties"
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "12ch", ...filterSx }}
        />
        <GeographySelectorMultiple
          options={modifiedGeographiesData}
          label="City"
          type="cities"
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "12ch", ...filterSx }}
        />
        <TypeSelectorMultiple
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "13ch", ...filterSx }}
        />
        <ChannelSelectorMultiple
          options={filteredOrgs?.organizations || []}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          size="small"
          sx={{ flexBasis: "15ch", ...filterSx }}
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" width="100%">
        <RangeSlider
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          filterType="city_population"
          label="City Population"
        />
        <RangeSlider
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          filterType="county_population"
          label="County Population"
        />
        <RangeSlider
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          filterType="city_income"
          label="City Household Income"
        />
        <RangeSlider
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          filterType="county_income"
          label="County Household Income"
        />
      </Stack>
    </>
  )
}
