import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"

export const useFetchDashboards = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["dashboards"], async () => {
    const response = await (
      await axiosInstance
    ).get<{
      dashboards: {
        id: number
        email: string
        name: string
        description: string | null
        updated_at: string
      }[]
    }>("/dashboards")

    return response.data
  })
}

export const useFetchDashboard = (dashboardId: number, onError?: (error: Error) => void) => {
  const axiosInstance = useAuthAxios()
  return useQuery(["dashboards", dashboardId], async () => {
    try {
      const response = await (await axiosInstance).get(`/dashboards/${dashboardId}`)
      return response.data
    } catch (error) {
      if (onError && error instanceof Error) {
        onError(error)
      }
      throw error
    }
  })
}

export const useCreateDashboard = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("/dashboards", params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards"])
      },
    },
  )
}

export const useUpdateDashboard = (dashboardId: number) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name?: string; description?: string }) => {
      return (await axiosInstance).put(`/dashboards/${dashboardId}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards"])
      },
    },
  )
}

export const useFetchDashboardPermissions = (dashboardId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["dashboards", dashboardId, "permissions"], async () => {
    const response = await (
      await axiosInstance
    ).get<{ id: string; email: string }[]>(`/dashboards/${dashboardId}/permissions`)

    return response.data
  })
}

export const useShareDashboard = (dashboardId: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (emails: string[]) => {
      return (await axiosInstance).post(`/dashboards/${dashboardId}/permissions`, {
        users: emails.map((email) => ({ email })),
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards", dashboardId, "permissions"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUnshareDashboard = (
  dashboardId: number,
  invalidateDashboardList?: boolean,
  onSuccess?: (email: string) => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (email: string) => {
      return (await axiosInstance).delete(`/dashboards/${dashboardId}/permissions/${email}`)
    },
    {
      onSuccess: (_data, email) => {
        queryClient.invalidateQueries(["dashboards", dashboardId, "permissions"])
        if (invalidateDashboardList) {
          queryClient.invalidateQueries(["dashboards"])
        }
        if (onSuccess) {
          onSuccess(email)
        }
      },
    },
  )
}

export const useRemoveDashboards = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (removeList: number[]) => {
      return (await axiosInstance).post("/dashboards/remove", { remove: removeList })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboards"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useFetchDashboardWidgets: any = (dashboardId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["widgets", dashboardId], async () => {
    const response = await (await axiosInstance).get(`/dashboards/${dashboardId}/widgets`)

    return response.data
  })
}

export const useAddWidgetToDashboard = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(`/dashboards/${params.dashboardId}/add_widget`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("widgets")
      },
    },
  )
}
