import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { FC, useMemo, useState } from "react"
import { Divider } from "rsuite"
import { useFetchStatesAdmin } from "../../../api/admin/geographies"
import { State } from "../../../interfaces/geography"
import Loading from "../../shared/Loading"

export const CostCalculator: FC<{
  open: any
  onClose: any
}> = ({ open, onClose }) => {
  const [costPerMeeting, setCostPerMeeting] = useState(0.25)
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [selectedStates, setSelectedStates] = useState<any[]>([])
  const [daysBack, setDaysBack] = useState(30)
  const [serverDaysBack, setServerDaysBack] = useState(30)

  const { data: statesData } = useFetchStatesAdmin(serverDaysBack)
  const sortedSelectedStates = useMemo(
    () => selectedStates.sort((a: State, b: State) => (a?.name > b?.name ? 1 : -1)),
    [selectedStates],
  )

  if (statesData === undefined) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Cost Calculator</DialogTitle>
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    )
  }

  const sumMinutes = selectedStates.reduce((sum: number, obj: State) => sum + obj.processed.minutes, 0)
  const sumMeetings = selectedStates.reduce((sum: number, obj: State) => sum + obj.processed.meeting_count, 0)

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Cost Calculator</DialogTitle>
      <DialogContent>
        <Grid container marginTop={1} spacing={2} alignItems="center">
          <Grid item xs={3}>
            <TextField
              label="Days Back"
              value={daysBack}
              onChange={(event: any) => setDaysBack(event.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                setServerDaysBack(daysBack)
                setSelectedStates([])
              }}
              size="small"
              variant="contained"
            >
              SET
            </Button>
          </Grid>
          <Grid item xs={12} marginBottom={1}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="state-multiple-select"
              options={statesData.states}
              value={selectedStates || null}
              open={autocompleteOpen}
              multiple
              onOpen={() => setAutocompleteOpen(true)}
              onClose={() => setAutocompleteOpen(false)}
              onClick={() => setAutocompleteOpen(true)}
              renderTags={() => (
                <Typography onClick={() => setAutocompleteOpen(!autocompleteOpen)} sx={{ maxWidth: "90%" }} noWrap>
                  {selectedStates?.map((state: State) => state?.name).join(", ")}
                </Typography>
              )}
              onChange={(_event, values) => {
                const temp = [...values]
                setSelectedStates(temp)
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="States" />}
              getOptionLabel={(option: any) => option?.name || "None"}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <Typography variant="body1">{option?.name}</Typography>
                </li>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{`Minutes processed last ${serverDaysBack} days:`}</Typography>
            {sortedSelectedStates.map((state: any) => {
              const averageInHrs = state?.processed.minutes / state?.processed.meeting_count
              return (
                <Grid container key={state.id} alignItems="center" justifyContent="space-between">
                  <Grid item xs={3}>
                    <Typography variant="h6">{`${state?.name}`}</Typography>
                  </Grid>
                  <Grid container item xs={9} direction="row" alignItems="center">
                    <Typography
                      variant="body1"
                      paddingX={1}
                    >{`${(state?.processed.minutes / 60).toFixed(1)} hrs`}</Typography>
                    <Typography variant="body2">{`(${state?.processed.meeting_count} meetings,`}</Typography>
                    <Typography
                      variant="body2"
                      paddingLeft={1}
                    >{`~${Math.trunc(averageInHrs)} minutes/meeting)`}</Typography>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
          <Grid item xs={12} marginBottom={1}>
            <Divider />
          </Grid>
          <Stack direction="row" padding={2} alignItems="center" spacing={3}>
            <Typography variant="h5">{`Total hours: ${(sumMinutes / 60).toLocaleString("en-US")}`}</Typography>
            <Typography variant="subtitle2">X</Typography>
            <Typography variant="h6">$0.96</Typography>
            <Typography variant="subtitle2">&nbsp;(cost per hour)</Typography>
            <Typography variant="subtitle2">=</Typography>
            <Typography variant="h4">{`$${(sumMinutes * 0.016).toLocaleString("en-US")}`}</Typography>
          </Stack>
          <Stack direction="row" padding={2} alignItems="center" spacing={2}>
            <Typography variant="h5">{`Total meetings: ${sumMeetings}`}</Typography>
            <Typography variant="subtitle2">X</Typography>
            <TextField
              label="Cost Per Meeting"
              value={costPerMeeting}
              onChange={(event: any) => setCostPerMeeting(event.target.value)}
            />
            <Typography variant="subtitle2">=</Typography>
            <Typography variant="h4">{`$${(sumMeetings * costPerMeeting).toLocaleString("en-US")}`}</Typography>
          </Stack>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
