import { ClipShareBookmarkView } from "../../interfaces/clipShare"
import { MeetingDetailsParamsStringProps, generateParamsString } from "../../functions/generateURL"
import { generateUtmParamsString } from "./urlParams"

export const redirectIfPaidUser = (
  params: URLSearchParams,
  clipShareData?: ClipShareBookmarkView,
  isPaidUser?: boolean,
) => {
  const transcriptId = params.get("transcript_id")
  if (isPaidUser && clipShareData?.meeting.id) {
    const paramsStringProps: MeetingDetailsParamsStringProps = {}
    if (transcriptId) {
      paramsStringProps.transcriptId = transcriptId
    }
    window.location.replace(
      `/meetings/${clipShareData.meeting.id}?${generateParamsString({
        transcriptId: transcriptId,
        terms: [clipShareData.keyword],
        activeTerm: clipShareData.keyword,
      })}&${generateUtmParamsString(params.toString())}`,
    )
  }
}
