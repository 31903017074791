import { FC, useState, useMemo } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  FormLabel,
  FormGroup,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { useToast } from "@components/shared/ToastHook"
import { useModalKey, useOpenModalKey, useCloseModalKey } from "@components/shared/OpenModalHook"
import { useFetchChannelTypes } from "@api/channel_types"
import { useFetchOrganizations } from "@api/organizations"
import { usePatchManyOrganizations } from "@src/api/admin/organizations"

const modalKey = "ADMIN_ORGANIZATION_UPDATE_CHANNEL_TYPE"

export const UpdateChannelTypeMenuItem: FC<{ orgIds: number[] }> = ({ orgIds }) => {
  const openModalKey = useOpenModalKey(modalKey)
  return (
    <MenuItem
      onClick={() => {
        openModalKey()
      }}
      disabled={!orgIds.length}
    >
      Update Channel Type
    </MenuItem>
  )
}

export const UpdateChannelTypeDialog: FC<{ orgIds: number[]; onClose: () => void }> = ({ orgIds, onClose }) => {
  const { data: channelTypes } = useFetchChannelTypes()
  const { data: orgs } = useFetchOrganizations()
  const [channelTypeId, setChannelTypeId] = useState("")

  const open = useModalKey(modalKey)
  const closeModalKey = useCloseModalKey(modalKey)
  const setToast = useToast()

  const selectedOrgs = useMemo(() => {
    if (orgs && orgs.organizations && orgIds) {
      const orgFilter = orgs.organizations.filter((org: any) => orgIds.includes(org.id))
      if (orgFilter[0]) {
        setChannelTypeId(orgFilter[0].channel_type_id)
        return orgFilter
      }
    }
  }, [orgs, orgIds])

  const close = () => {
    onClose()
    closeModalKey()
  }

  const { mutate: updateOrgs, isLoading } = usePatchManyOrganizations(() => {
    setToast("Successfully updated channel types", 3000, <TaskAltIcon />)
    close()
  })

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle>Update Channel Type</DialogTitle>
      <DialogContent>
        <DialogContentText>This will affect the following channels:</DialogContentText>
        <List style={{ maxHeight: "300px" }}>
          {selectedOrgs?.map((org: any) => (
            <ListItem key={org.id} disablePadding>
              <ListItemText>{org.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogContent sx={{ flexShrink: "0" }}>
        <FormLabel>Select Channel Type:</FormLabel>
        <FormGroup sx={{ marginTop: 2 }}>
          <FormControl>
            <InputLabel id="channel-type-label">Channel Type</InputLabel>
            <Select
              labelId="channel-type-select-label"
              id="cahnnel-type-select"
              value={channelTypeId}
              label="Channel Type"
              onChange={(event) => setChannelTypeId(event.target.value)}
            >
              {channelTypes?.map((channel_type) => (
                <MenuItem key={channel_type.id} value={channel_type.id}>
                  {channel_type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateOrgs({
              ids: orgIds,
              attributes: { channel_type_id: parseInt(channelTypeId) },
            })
          }}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Assign"}
        </Button>
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
