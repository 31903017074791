import { FC } from "react"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

interface ExpandMoreProps extends IconButtonProps {
  expand: number
  disableFocus: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props
  return (
    <IconButton
      sx={{
        "&:hover": {
          background: "none",
        },
      }}
      {...other}
    />
  )
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}))

const ExpandingText: FC<{
  text: string
  isViewing: boolean
  setIsViewing: any
}> = ({ text, isViewing, setIsViewing }) => {
  return (
    <ExpandMore
      disableFocusRipple
      disableTouchRipple
      expand={isViewing ? 1 : 0}
      onClick={() => setIsViewing(!isViewing)}
      aria-expanded={isViewing ? true : false}
      disableFocus
    >
      {!isViewing && (
        <Typography variant="body1" fontWeight={800} color="primary">
          {text}
        </Typography>
      )}
      <ExpandMoreIcon />
    </ExpandMore>
  )
}

export default ExpandingText
