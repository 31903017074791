import { Button } from "@mui/material"
import { FC } from "react"

export const SearchFormButton: FC<{
  addTerm: () => void
  show: boolean
}> = ({ addTerm, show }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      opacity: show ? "1" : "0",
      color: "white",
      flexShrink: "0",
    }}
    disabled={!show}
    disableElevation
    onClick={addTerm}
  >
    Add Term
  </Button>
)
