import { FormControl, FormHelperText, TextField } from "@mui/material"
import { FC, useContext } from "react"
import { unpackSetters } from "../../../shared/unpackSetters"
import { OrganizationWizardContext } from "./Context"

export const JsonSelectors: FC = () => {
  const { value, setValue } = useContext(OrganizationWizardContext)
  const { jsonReplaceCode } = value
  const { setJsonReplaceCode } = unpackSetters(value, setValue)

  return (
    <FormControl sx={{ alignSelf: "flex-start", minWidth: "50ch" }}>
      <TextField
        size="small"
        onChange={(event) => setJsonReplaceCode(event.target.value)}
        value={jsonReplaceCode}
        label="Ruby strftime format"
      ></TextField>
      <FormHelperText>%Y-%m-%d</FormHelperText>
    </FormControl>
  )
}
