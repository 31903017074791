import { Column } from "../components/shared/DataTable"
import { formatDate } from "../components/shared/DataTable/formatters"
import { ClipShareWithMethod } from "../interfaces/clipShare"
import { Meeting } from "../interfaces/meeting"
import { formatSeconds } from "../components/shared/config"

const copy = (clipShare: ClipShareWithMethod) => {
  clipShare.shareMethod()
}

const unpackPublishedAt = (meeting: Meeting) => {
  return formatDate(meeting.published_at)
}

const formatArrayString = (users: string[]) => {
  if (!users || users.length < 1) {
    return ""
  }
  return users.reduce((emails, email) => `${emails}, ` + email)
}

export const columns: Column[] = [
  {
    id: "start_time_in_seconds",
    formatter: formatSeconds,
    label: "Start Time",
    headerCellProps: { sx: { minWidth: 80 } },
  },
  { id: "title", label: "Title", headerCellProps: { sx: { minWidth: 80 } } },
  { id: "description", label: "Notes", headerCellProps: { sx: { minWidth: 400 } } },
  {
    id: "tagged_users",
    label: "Tagged Users",
    formatter: formatArrayString,
    headerCellProps: { sx: { minWidth: 30 } },
  },
  {
    id: "meeting",
    label: "Published At",
    formatter: unpackPublishedAt,
    headerCellProps: { sx: { minWidth: 80 } },
    sortProp: ["meeting", "published_at"],
  },
  { id: "copy", buttonTitle: "Share", buttonAction: copy },
]
