import { Dispatch, FC, SetStateAction } from "react"
import { TextField, TextFieldProps, InputAdornment, IconButton } from "@mui/material"
import Search from "@mui/icons-material/Search"
import Close from "@mui/icons-material/Close"

export const SearchTextField: FC<
  {
    search: string
    setSearch: Dispatch<SetStateAction<string>>
  } & TextFieldProps
> = (props) => {
  const { search, setSearch } = props
  const propsCopy: {
    search?: string
    setSearch?: Dispatch<SetStateAction<string>>
  } & TextFieldProps = { ...props }
  delete propsCopy.search
  delete propsCopy.setSearch

  return (
    <TextField
      onChange={(event) => setSearch(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearch("")} edge="end" size="small" disabled={!search}>
              {search === "" ? <Search /> : <Close />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={search}
      {...propsCopy}
    />
  )
}
