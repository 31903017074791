import { FC, useMemo, Dispatch, SetStateAction } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material"
import { useRemoveDashboards, useFetchDashboards } from "../../api/dashboards"
import { useToast } from "../shared/ToastHook"

export const RemoveDashboardDialog: FC<{
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedDashboards: number[]
  onComplete: () => void
}> = ({ open, setOpen, selectedDashboards, onComplete }) => {
  const { mutate: removeDashboards } = useRemoveDashboards(() => {
    setOpen(false)
    if (selectedDashboards.length === 1) {
      setToast("Successfully removed dashboard")
    } else {
      setToast("Successfully removed dashboards")
    }
    onComplete()
  })
  const { data: dashboardsData } = useFetchDashboards()
  const setToast = useToast()

  const dashboardsMap = useMemo(() => {
    if (dashboardsData) {
      const map = new Map<number, string>()
      dashboardsData.dashboards.forEach((dashboard) => {
        map.set(dashboard.id, dashboard.name)
      })
      return map
    }
    return new Map<number, string>()
  }, [dashboardsData])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>Remove Dashboards</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to remove these dashboards?</Typography>
        <List dense>
          {selectedDashboards.map((id) => (
            <ListItem key={id}>
              <ListItemText>{dashboardsMap.get(id)}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => removeDashboards(selectedDashboards)}>
          Remove
        </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
