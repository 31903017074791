import { Dispatch, FC, SetStateAction, createContext, useState, useEffect } from "react"
import {
  Stack,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Typography,
  Link as MuiLink,
  Skeleton,
  CardActions,
  Button,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material"
import { Link } from "react-router-dom"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { useFetchGeographies, useFetchGeographiesMap } from "@src/api/geographies"
import { ChipField } from "@src/components/shared/ChipField"
import { GeographySelectorMultiple } from "@src/components/shared/Selectors/Multiple/Geographies"
import { useFetchOrganizations } from "@src/api/organizations"
import { FilterParams } from "@src/components/Home/interface"
import { ChannelSelectorMultiple } from "@src/components/shared/Selectors/Multiple/Channel"
import { OpportunityCampaignsUsers } from "@src/interfaces/opportunityCampaignsUsers"
import { User } from "@src/interfaces/user"
import { EmailCadencesList } from "./EmailCadencesList"
import { OwnEmailCadence } from "./OwnEmailCadence"
import { EmailCadence } from "@src/interfaces/campaign"
import { OpportunityCampaignUpdateParams } from "@src/api/opportunityCampaigns"
import { useFetchIndustryIdsMap } from "@src/api/industries"
import { useGetFeatureFlagByName } from "@src/api/feature_flags"

export const OpportunityCampaignCardContext = createContext<{
  id: number
  ownEmailCadence?: string
  emailCadences?: OpportunityCampaignsUsers[]
  onEmailCadencesUpdate?: (newUserEmailCadenceMap: Map<number, EmailCadence>) => void
  userMapById?: Map<number, User>
  setOwnEmailCadenceAction: Dispatch<SetStateAction<() => void>>
  setEmailCadencesAction: Dispatch<SetStateAction<() => void>>
}>({
  id: 0,
  setOwnEmailCadenceAction: () => null,
  setEmailCadencesAction: () => null,
})

export const OpportunityCampaignCard: FC<{
  opportunityCampaign: OpportunityCampaign
  ownEmailCadence?: string
  emailCadences?: OpportunityCampaignsUsers[]
  onEmailCadencesUpdate?: (newUserEmailCadenceMap: Map<number, EmailCadence>) => void
  userMapById?: Map<number, User>
  onUpdate?: (updateParams: OpportunityCampaignUpdateParams) => void
  editIsLoading?: boolean
}> = ({
  opportunityCampaign,
  ownEmailCadence,
  emailCadences,
  onEmailCadencesUpdate,
  userMapById,
  onUpdate,
  editIsLoading,
}) => {
  const {
    id,
    name,
    data: { terms, industry, naics_code, organizations, cities, counties, states },
  } = opportunityCampaign
  const [isEditing, setIsEditing] = useState(false)
  const [ownEmailCadenceAction, setOwnEmailCadenceAction] = useState(() => () => {})
  const [emailCadencesAction, setEmailCadencesAction] = useState(() => () => {})
  const [campaignName, setCampaignName] = useState(name)
  const [termsSet, setTermsSet] = useState<Set<string>>(new Set(terms))
  const [newNaicsCodes, setNewNaicsCodes] = useState(naics_code || [])
  const [newIndustries, setNewIndustries] = useState<string[]>(industry || [])

  const defaultFilterParams: FilterParams = {
    organizations: organizations || [],
    cities: cities || [],
    counties: counties || [],
    states: states || [],
    channel_types: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
  }
  const [filterParams, setFilterParams] = useState<FilterParams>(defaultFilterParams)

  const reset = () => {
    setFilterParams(defaultFilterParams)
    setCampaignName(name)
    setTermsSet(new Set(terms))
    setIsEditing(false)
  }
  useEffect(reset, [opportunityCampaign])

  const { data: industriesDataMap, isLoading: industriesLoading } = useFetchIndustryIdsMap(false, true)
  const { data: showNaicsCodesFilter, isLoading: showNaicsCodesFilterLoading } = useGetFeatureFlagByName(
    "OPPORTUNITY_NAICS_CODE_SEARCH_FILTER",
  )
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()
  const filterTexts: string[] = []
  if (industry?.length) {
    filterTexts.push(
      industry
        .map((industryId) => {
          const industry = industriesDataMap?.get(industryId)
          return industry?.name
        })
        .join(", "),
    )
  } else if (naics_code?.length) {
    filterTexts.push(
      naics_code
        .map((code) => {
          const naicsCode = naicsCodesMap?.get(code)
          return `${naicsCode?.code}: ${naicsCode?.title}`
        })
        .join(", "),
    )
  }

  const { data: organizationsData, isFetched: organizationsIsFetched } = useFetchOrganizations()
  const { data: organizationsMap } = useFetchOrganizationsMap()
  if (organizations?.length) {
    filterTexts.push(organizations?.map((id) => organizationsMap?.get(id)?.name).join(", "))
  }

  const { data: geographiesData, isFetched: geographiesIsFetched } = useFetchGeographies()
  const { data: geographiesMap } = useFetchGeographiesMap()
  if (cities?.length) {
    filterTexts.push(cities?.map((id) => geographiesMap?.cities.get(id)?.name).join(", "))
  }
  if (counties?.length) {
    filterTexts.push(counties?.map((id) => geographiesMap?.counties.get(id)?.name).join(", "))
  }
  if (states?.length) {
    filterTexts.push(states?.map((id) => geographiesMap?.states.get(id)?.name).join(", "))
  }

  let actions: React.ReactNode | undefined
  if (editIsLoading) {
    actions = undefined
  } else if (onUpdate) {
    actions = (
      <>
        {isEditing ? (
          <>
            <Button onClick={reset}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => {
                onUpdate({
                  name: campaignName,
                  data: {
                    ...filterParams,
                    naics_code: newNaicsCodes,
                    industry: newIndustries,
                    terms: Array.from(termsSet),
                  },
                })
                ownEmailCadenceAction()
                emailCadencesAction()
                reset()
              }}
            >
              Save
            </Button>
          </>
        ) : (
          <Button size="small" variant="contained" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </>
    )
  } else {
    actions = <></>
  }

  return (
    <OpportunityCampaignCardContext.Provider
      value={{
        id,
        ownEmailCadence,
        emailCadences,
        onEmailCadencesUpdate,
        userMapById,
        setOwnEmailCadenceAction,
        setEmailCadencesAction,
      }}
    >
      <OpportunityCampaignCardSkeleton
        title={
          isEditing ? (
            <TextField
              value={campaignName}
              onChange={(event) => {
                setCampaignName(event.target.value)
              }}
              fullWidth
              label="Name"
              size="small"
            />
          ) : (
            <MuiLink component={Link} to={`/opportunities?campaign=${id}`}>
              {name}
            </MuiLink>
          )
        }
        content={
          <>
            {isEditing ? (
              <Stack spacing={1}>
                <ChipField
                  terms={termsSet}
                  setTerms={setTermsSet}
                  placeholder="What opportunities would you like to find?"
                />
                {/* REMOVE THIS ONCE WE REMOVE NAICS CODES OPP SEARCH FEATURE FLAG */}
                {showNaicsCodesFilterLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={56} />
                ) : showNaicsCodesFilter === undefined || showNaicsCodesFilter.value === "true" ? (
                  <Autocomplete
                    multiple
                    value={newNaicsCodes}
                    onChange={(_, newValue) => setNewNaicsCodes(newValue)}
                    getOptionLabel={(option) => {
                      const naicsCode = naicsCodesMap?.get(option)
                      return `${naicsCode?.code}: ${naicsCode?.title}`
                    }}
                    loading={showNaicsCodesFilterLoading}
                    options={naicsCodesMap ? Array.from(naicsCodesMap.keys()) : []}
                    renderInput={(params) => <TextField {...params} label="NAICS Code" />}
                    renderOption={(props, option) => {
                      const naicsCode = naicsCodesMap?.get(option)
                      return (
                        <Box component="li" {...props} key={option}>
                          {`${naicsCode?.code}: ${naicsCode?.title}`}
                        </Box>
                      )
                    }}
                  />
                ) : (
                  <Autocomplete
                    multiple
                    value={newIndustries}
                    onChange={(_, newValue) => setNewIndustries(newValue)}
                    getOptionLabel={(option) => {
                      const industry = industriesDataMap?.get(option)
                      return industry?.name || ""
                    }}
                    loading={industriesLoading}
                    options={industriesDataMap ? Array.from(industriesDataMap.keys()) : []}
                    renderInput={(params) => <TextField {...params} label="Industry" />}
                    renderOption={(props, option) => {
                      const industry = industriesDataMap?.get(option)
                      return (
                        <Box component="li" {...props} key={option}>
                          {industry?.name}
                        </Box>
                      )
                    }}
                  />
                )}
                <Stack direction="row" gap={1} flexWrap="wrap">
                  {!geographiesIsFetched || !geographiesData ? (
                    <>
                      <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
                      <Skeleton height={56} variant="rounded" sx={{ flex: "20ch 1 0" }} />
                      <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
                    </>
                  ) : (
                    <>
                      <GeographySelectorMultiple
                        options={geographiesData}
                        label="State"
                        type="states"
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        sx={{ flex: "20ch 1 0" }}
                      />
                      <GeographySelectorMultiple
                        options={geographiesData}
                        label="County"
                        type="counties"
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        sx={{ flex: "20ch 1 0" }}
                      />
                      <GeographySelectorMultiple
                        options={geographiesData}
                        label="City"
                        type="cities"
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        sx={{ flex: "30ch 1 0" }}
                      />
                    </>
                  )}
                  {!organizationsIsFetched ? (
                    <Skeleton height={56} variant="rounded" sx={{ flex: "30ch 1 0" }} />
                  ) : (
                    <ChannelSelectorMultiple
                      options={organizationsData ? organizationsData.organizations : []}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                      sx={{ flex: "30ch 1 0" }}
                    />
                  )}
                </Stack>
              </Stack>
            ) : (
              <>
                <Stack direction="row" gap={1}>
                  {terms?.map((term) => <Chip key={term} label={term} />)}
                </Stack>
                <Typography>{filterTexts.join(" in ")}</Typography>
              </>
            )}
            <Box marginTop={1}>
              <EmailCadencesList isEditing={isEditing} />
              <OwnEmailCadence opportunityCampaignId={id} isEditing={isEditing} />
            </Box>
          </>
        }
        actions={actions}
      />
    </OpportunityCampaignCardContext.Provider>
  )
}

export const OpportunityCampaignCardSkeleton: FC<{
  title?: React.ReactNode
  content?: React.ReactNode
  actions?: React.ReactNode
}> = ({ title, content, actions }) => (
  <Card>
    <CardHeader title={title || <Skeleton width="24ch" />} sx={{ paddingBottom: 0 }} />
    <CardContent sx={{ paddingTop: 1 }}>
      {content || (
        <>
          <Stack direction="row" gap={1}>
            <Chip label={<Skeleton width="6ch" />} />
            <Chip label={<Skeleton width="6ch" />} />
            <Chip label={<Skeleton width="6ch" />} />
          </Stack>
          <Typography>
            <Skeleton sx={{ maxWidth: "64ch" }} />
          </Typography>
        </>
      )}
    </CardContent>
    {actions !== null && <CardActions>{actions || <Skeleton sx={{ width: "24ch" }} />}</CardActions>}
  </Card>
)
