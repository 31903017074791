import { FC, useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useFetchUserOrganizations, useAddUserToUserOrganization } from "@api/admin/user_organizations"
import { RolesOptions } from "@src/interfaces/roles"

export const NewUser: FC<{
  open: boolean
  onClose: () => void
}> = ({ open, onClose }) => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const [emailInput, setEmailInput] = useState("")
  const [roleOption, setRoleOption] = useState<number>()
  const [userOrgId, setUserOrgId] = useState(userOrganizationId)
  const [signalsNotifications, setSignalsNotifications] = useState(true)
  const [accountSummaryNotifications, setAccountSummaryNotifications] = useState(true)

  const { mutate: addUser } = useAddUserToUserOrganization(userOrganizationId)
  const { data: userOrgData } = useFetchUserOrganizations()
  const handleSetRoleOption = (event: SelectChangeEvent) => {
    const value = event.target.value
    setRoleOption(parseInt(value))
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Grid container item marginTop={2} spacing={2} direction="column">
          <Grid item>Add user to user organization. This will create a new user if one does not already exist.</Grid>
          <Grid item>
            <TextField
              size="small"
              label="Email"
              value={emailInput}
              onChange={(event) => setEmailInput(event.target.value)}
              fullWidth
            />
          </Grid>
          {userOrgData && (
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id="user-org-select-label">User Organization</InputLabel>
                <Select
                  value={userOrgId}
                  onChange={(event) => setUserOrgId(event.target.value)}
                  labelId="user-org-select-label"
                  label="User Organization"
                >
                  {userOrgData.map((userOrg) => (
                    <MenuItem key={userOrg.id} value={userOrg.id}>
                      {userOrg.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                value={roleOption?.toString() || ""}
                onChange={handleSetRoleOption}
                labelId="role-select-label"
                label="Role"
              >
                {RolesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={signalsNotifications}
                  onChange={(event) => setSignalsNotifications(event.target.checked)}
                />
              }
              label="Requires Signals Notifications?"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountSummaryNotifications}
                  onChange={(event) => setAccountSummaryNotifications(event.target.checked)}
                />
              }
              label="Requires Account Summary Notifications?"
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              if (roleOption !== undefined) {
                addUser({
                  email: emailInput,
                  requires_signals_notifications: signalsNotifications,
                  requires_account_summary_notifications: accountSummaryNotifications,
                  role_ids: [roleOption],
                })
              }
              onClose()
            }}
          >
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
