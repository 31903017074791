import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Stack } from "@mui/material"
import { FC } from "react"
import { useDeleteClipShare } from "../../api/bookmarks/clip_shares"
import { useCloseModalKey, useModalKey } from "../shared/OpenModalHook"
import { FetchedClipShare } from "../../api/bookmarks"

export const DeleteClipsModal: FC<{
  clips: FetchedClipShare[]
  bookmarkId: number
  clearSelected: () => void
}> = ({ clips, bookmarkId, clearSelected }) => {
  const deleteModalKey = `DELETE_CLIP_SHARE_KEY_${bookmarkId}`
  const open = useModalKey(deleteModalKey)
  const closeModalKey = useCloseModalKey(deleteModalKey)

  const { mutate: removeClips } = useDeleteClipShare()

  return (
    <Dialog
      open={open}
      onClose={closeModalKey}
      fullWidth
      PaperProps={{ sx: { maxWidth: "80%", paddingX: 6, paddingTop: 6, paddingBottom: 6 } }}
    >
      <DialogTitle>
        <Typography variant="h3" component="div">
          Remove Clips
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="div" variant="h5">
          You will remove the following clips. The share links will remain active.
        </Typography>
        <Stack direction="column" sx={{ paddingTop: 2 }} spacing={2}>
          {clips?.map((clip) => (
            <Typography key={clip.id} variant="body2">
              {clip?.title ? clip.title : "Unknown Title"}
            </Typography>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            removeClips(clips)
            clearSelected()
            closeModalKey()
          }}
          variant="contained"
          color="primary"
        >
          Delete
        </Button>
        <Button onClick={closeModalKey}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
