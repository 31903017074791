import { FC, useEffect, useState } from "react"
import { useFetchLeadsForIndustry } from "@api/promotions/leads"
import { useFetchIndustry } from "@api/promotions/industries"
import { useHistory, useParams } from "react-router-dom"
import Loading from "@components/shared/Loading"
import { Box, Fab, Stack, Typography, useMediaQuery } from "@mui/material"
import { theme } from "@src/theme"
import { MeetingListItem } from "@src/components/Promo/MeetingListItem"
import { ArrowCircleRight } from "@mui/icons-material"
import { useCreateSearchLogs, CreateSearchLogParams } from "@src/api/promotions/searchlogs"
import { PageOfOrigin } from "@src/interfaces/searchLogs"

export const FindALeadIndustryPage: FC = () => {
  // Page for when the user has selected an industry and are now displayed with the results
  const { id } = useParams<{ id: string }>()
  const { data: industryData, isLoading: industryLoading } = useFetchIndustry(id)
  const { data: meetingData = { leads: [], count: 0 }, isLoading: meetingsLoading } = useFetchLeadsForIndustry(id)
  const { mutate: createSearchLog } = useCreateSearchLogs()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const handleShowMore = () => {
    history.push(`/find-a-lead/${id}/form`)
  }

  useEffect(() => {
    setIsLoading(meetingsLoading || industryLoading)
  }, [meetingsLoading, industryLoading])

  useEffect(() => {
    if (industryData?.id) {
      const searchLogData: CreateSearchLogParams = {
        data: {
          industry_id: industryData.id,
        },
        page_of_origin: PageOfOrigin.FindALeadList,
      }
      createSearchLog(searchLogData)
    }
  }, [industryData?.id])

  return isLoading || !industryData ? (
    <Loading useCloverleafIcon />
  ) : (
    <Stack
      width={"90%"}
      minHeight="100vh"
      sx={{ [theme.breakpoints.down("md")]: { paddingY: 7 }, [theme.breakpoints.up("sm")]: { paddingY: 8 } }}
      display={"flex"}
      justifyContent={"center"}
      margin={"auto"}
    >
      <Box paddingY={6} width={"90%"} margin={"auto"}>
        <Typography variant="h1" fontSize={{ xs: 28, sm: 40 }} fontWeight={600} color={"black"}>
          Cloverleaf AI detected {meetingData.count} pre-RFP qualified leads in{" "}
          <span style={{ color: "#4cb2dc" }}>{industryData.name}</span> over the last 14 days.
        </Typography>
        <Typography variant={isSmallScreen ? "body2" : "body1"}>
          *Qualified leads: Pre-RFP conversations from government officials
        </Typography>
        <Box width={"100%"} marginTop={6}>
          {meetingData.leads.map((lead) => (
            <MeetingListItem
              meeting={lead.meeting}
              key={lead.meeting.id}
              industryId={id}
              searchWords={[lead.clip_share.keyword]}
              clip_share={lead.clip_share}
              total_hits={meetingData.count}
            />
          ))}
          <Box width={"100%"}>
            {meetingData.leads.slice(0, 1).map((lead) => (
              <MeetingListItem
                meeting={lead.meeting}
                key={lead.meeting.id}
                industryId={id}
                searchWords={[lead.clip_share.keyword]}
                clip_share={lead.clip_share}
                total_hits={meetingData.count}
                blur
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} position={"fixed"} top={isSmallScreen ? 60 : 80} right={30}>
        <Fab
          variant="extended"
          style={{ background: "linear-gradient(40deg, rgba(4,4,24,1) 87%, rgba(4,26,55,.88))", color: "white" }}
          onClick={handleShowMore}
          size="medium"
        >
          View All Opportunities <ArrowCircleRight sx={{ ml: 1, color: "#4cb2dc" }} />
        </Fab>
      </Box>
    </Stack>
  )
}
