import { FC, useState, useEffect, useCallback } from "react"
import { Button, Grid, IconButton, Stack, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import { Helmet } from "react-helmet"
import { useHistory, useParams, Redirect } from "react-router-dom"
import { useFetchDashboard, useUpdateDashboard } from "../api/dashboards"
import { IDashboardDetailRouteParams } from "../api/interfaces"
import ArrowBack from "@mui/icons-material/ArrowBack"
import { NameTextField } from "../components/Dashboards/NameTextField"
import { WidgetContainer as AddWidgetContainer } from "../components/Dashboards/add_widget/WidgetContainer"
import { WidgetContainer as DisplayWidgetContainer } from "../components/Dashboards/display_widget/WidgetContainer"
import { DownloadDashboardPng } from "../components/Dashboards/DownloadDashboardPng"
import { ShareDashboardButton } from "../components/Dashboards/ShareDashboardButton"
import { useFetchGeographies } from "../api/geographies"
import { useFetchOrganizations } from "../api/organizations"
import Loading from "../components/shared/Loading"

export const DashboardDetailPage: FC = () => {
  const history = useHistory()
  const { dashboardId } = useParams<IDashboardDetailRouteParams>()

  const [redirect, setRedirect] = useState(false)
  useFetchGeographies() // triggering these when container loads so the filters aren't blank when widget is edited
  useFetchOrganizations()
  const { data: dashboardData, isLoading } = useFetchDashboard(parseInt(dashboardId), (error) => {
    if (error.message.includes("401") || error.message.includes("404")) {
      setRedirect(true)
    }
  })
  const isShared = Boolean(dashboardData?.shared)

  const { mutate: updateDashboard } = useUpdateDashboard(parseInt(dashboardId))

  const [editing, setEditing] = useState<boolean>()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  // this keeps track of how wide the name text is to know where to put the pencil icon
  const [textWidth, setTextWidth] = useState(0)
  const NameTextFieldRef = useCallback(
    (node: HTMLElement) => {
      if (node !== null) {
        setTextWidth(node.getBoundingClientRect().width)
      }
    },
    [editing, isLoading],
  )

  let nameErrorText = ""
  if (name.length > 100) {
    nameErrorText = "Name must be under 100 characters"
  }

  let descriptionErrorText = ""
  if (description.length > 500) {
    descriptionErrorText = "Description must be under 500 characters"
  }

  useEffect(() => {
    if (!editing && dashboardData && name !== dashboardData.dashboard.name) {
      setEditing(true)
    }
  }, [name])

  useEffect(() => {
    if (!editing && dashboardData && description !== dashboardData.dashboard.description) {
      setEditing(true)
    }
  }, [description])

  useEffect(() => {
    if (dashboardData) {
      setName(dashboardData.dashboard.name)
      setDescription(dashboardData.dashboard.description || "")
      setEditing(false)
    }
  }, [dashboardData])

  const [showWidget, setShowWidget] = useState(false)

  if (redirect) return <Redirect to="/dashboards" />

  if (isLoading) return <Loading />

  return (
    <>
      <Helmet>
        <title>Cloverleaf.AI | Dashboards</title>
      </Helmet>
      <Grid container direction="row">
        <Grid item sm={2} md={1}>
          <Button onClick={() => history.push("/dashboards")} startIcon={<ArrowBack />}>
            Back
          </Button>
        </Grid>
        <Grid item sm={7} md={8.5} paddingX={1}>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1} alignItems="flex-start" position="relative">
              <NameTextField
                ref={NameTextFieldRef}
                sx={{ width: "50%" }}
                value={name}
                onChange={(event) => setName(event.target.value)}
                size="small"
                textVariant="h5"
                editing={editing}
                error={Boolean(nameErrorText)}
                helperText={nameErrorText}
                skeletonWidth={isLoading && "25ch"}
              />
              {!isLoading &&
                !isShared &&
                (editing ? (
                  <>
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      disabled={name.length === 0 || Boolean(descriptionErrorText) || Boolean(nameErrorText)}
                      onClick={() => {
                        const params: { name?: string; description?: string } = {}
                        let paramsChanged = false
                        if (name !== dashboardData.dashboard.name) {
                          params.name = name
                          paramsChanged = true
                        }
                        if (description !== dashboardData.dashboard.description) {
                          params.description = description
                          paramsChanged = true
                        }
                        if (paramsChanged) {
                          updateDashboard(params)
                        }
                        setEditing(false)
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        setName(dashboardData.dashboard.name)
                        setDescription(dashboardData.dashboard.description)
                        setEditing(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <IconButton
                    onClick={() => setEditing(true)}
                    sx={{
                      position: "absolute",
                      left: `min(${textWidth}px, 50%)`,
                      opacity: "0.8",
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ))}
            </Stack>
            <NameTextField
              multiline
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              size="small"
              textVariant="body1"
              editing={editing}
              error={Boolean(descriptionErrorText)}
              helperText={descriptionErrorText}
              skeletonWidth={isLoading && "60ch"}
            />
          </Stack>
        </Grid>
        <Grid item sm={3} md={2.5}>
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="stretch">
            <ShareDashboardButton dashboardId={parseInt(dashboardId)} owner={dashboardData?.dashboard.email} />
            <Tooltip
              title={
                isShared
                  ? `This dashboard is owned by ${dashboardData.dashboard.email}, you cannot edit the dashboard`
                  : ""
              }
              placement="bottom-end"
            >
              <span>
                <Button
                  sx={{ height: "100%" }}
                  variant="contained"
                  onClick={() => setShowWidget(true)}
                  disabled={isShared}
                >
                  Add Widget
                </Button>
              </span>
            </Tooltip>
            <DownloadDashboardPng dashboardData={dashboardData} />
          </Stack>
        </Grid>
      </Grid>
      <DisplayWidgetContainer dashboardId={dashboardId} />
      <AddWidgetContainer open={showWidget} setClose={setShowWidget} dashboardId={dashboardId} />
    </>
  )
}
