export const generateUtmParamsString = (urlSearchParams: string) => {
  const existingParams = new URLSearchParams(urlSearchParams)
  const source = existingParams.get("utm-source")
  const medium = existingParams.get("utm-medium")
  const campaign = existingParams.get("utm-campaign")
  const content = existingParams.get("utm-content")

  const params = new URLSearchParams()

  if (source) {
    params.set("utm-source", source)
  }
  if (medium) {
    params.set("utm-medium", medium)
  }
  if (campaign) {
    params.set("utm-campaign", campaign)
  }
  if (content) {
    params.set("utm-content", content)
  }
  return params.toString()
}
