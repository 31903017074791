import { Dispatch, SetStateAction, useEffect, useRef, CSSProperties } from "react"
import { Typography, TableRow, TableCell, Checkbox, Link as MuiLink, CardMedia } from "@mui/material"
import { Link } from "react-router-dom"
import type { Column } from "."
import { Button } from "@mui/material"
import { PopoverButton } from "../PopoverButton"
import { rowClickStyle } from "./styles"

export const Row = <Type extends Record<string, any>>({
  data,
  columns,
  setSelected,
  selected,
  setRowHeight,
  sx,
  widthList,
}: {
  data: Type
  columns: Array<keyof Type | Column>
  setSelected?: () => void
  selected?: boolean
  setRowHeight?: Dispatch<SetStateAction<number>>
  sx?: CSSProperties
  widthList?: number[]
}) => {
  const ref = useRef<HTMLElement>(null)
  useEffect(() => {
    if (ref.current && setRowHeight) {
      setRowHeight(ref.current.getBoundingClientRect().height)
    }
  }, [])

  const isRowClickable = columns.some((column) => {
    return typeof column === "object" && data.rowClick
  })

  if (data.rowClick) {
    sx = { ...sx, ...rowClickStyle }
  }
  return (
    <TableRow sx={sx} hover={isRowClickable} onClick={data.rowClick ? () => data.rowClick() : undefined}>
      {setSelected && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={setSelected} />
        </TableCell>
      )}
      {columns.map((column, index) => {
        let cellComponent
        if (typeof column === "object") {
          const {
            id,
            image,
            formatter,
            link,
            linkTarget,
            buttonAction,
            popoverTitle,
            typographyProps,
            buttonTitle,
            buttonVariant = "outlined",
          } = column
          if (image) {
            cellComponent = (
              <CardMedia
                component="img"
                image={formatter ? formatter(data[id]) || "/no-video.png" : data[id] || "/no-video.png"}
                sx={{ maxWidth: 120 }}
              />
            )
          } else if (link) {
            if (typeof link === "string") {
              cellComponent = (
                <MuiLink component={Link} to={data[link]} target={linkTarget} rel="noopener">
                  <Typography>{data[id]}</Typography>
                </MuiLink>
              )
            } else {
              cellComponent = (
                <MuiLink component={Link} to={link(data)} target={linkTarget} rel="noopener">
                  <Typography>{data[id]}</Typography>
                </MuiLink>
              )
            }
          } else if (buttonAction) {
            cellComponent = (
              <Button
                id={data.id}
                aria-describedby={`${buttonTitle}-button`}
                color="primary"
                variant={buttonVariant}
                onClick={() => buttonAction(data)}
              >
                {buttonTitle}
              </Button>
            )
          } else if (popoverTitle) {
            cellComponent = <PopoverButton title={popoverTitle} contents={data[id]} variant={buttonVariant} />
          } else if (typeof data[id] === "object" && data[id] !== null) {
            if (formatter) {
              cellComponent = formatter(data[id])
            } else {
              cellComponent = data[id]
            }
          } else {
            cellComponent = (
              <Typography style={{ whiteSpace: "pre-line" }} {...typographyProps}>
                {formatter ? formatter(data[id]) : data[id]}
              </Typography>
            )
          }
        } else {
          cellComponent = <Typography>{data[column]}</Typography>
        }

        return (
          <TableCell
            key={typeof column === "object" ? (column.key ? column.key(data, index) : column.id) : column.toString()}
            sx={{
              width: widthList && widthList[index],
            }}
            ref={index === 0 ? ref : undefined}
          >
            {cellComponent}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
