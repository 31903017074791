declare let pendo: any

// in your authentication promise handler or callback
export const pendoInit = (user: any, marketing: boolean = false) => {
  if (user === undefined && !marketing) {
    return
  }

  if (marketing) {
    // Initialize Pendo for marketing pages
    // This is when we don't have a logged in user so we initialize with visitor data
    return pendo.initialize({
      visitor: {
        id: "VISITOR-UNIQUE-ID",
      },
    })
  }

  const role = user?.roles?.length > 0 ? user?.roles[0]?.name : ""

  return pendo.initialize({
    visitor: {
      id: user?.email,
      email: user?.email,
    },
    account: {
      id: user?.email,
      email: user?.email,
      creationDate: user?.created_at_unix,
      user_organization: user?.user_organization_name,
      role: role,
    },
  })
}
