import { FC } from "react"
import { Link } from "react-router-dom"
import { Toolbar, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import ArticleIcon from "@mui/icons-material/Article"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { useIsBriefingsEnabled, useIsOpportunitiesEnabled } from "../../api/users"
import { navConfig, checkMatchingPath, useCanSeeAdmin } from "./config"

const neutralStyle = {
  color: "common.white",
  textDecoration: "none",
}

const ListLink: FC<{
  label: string
  Icon: OverridableComponent<any>
  to: string
  closeNav: () => void
}> = ({ label, Icon, to, closeNav }) => {
  return (
    <ListItem disablePadding onClick={closeNav}>
      <ListItemButton
        component={Link}
        to={to}
        selected={checkMatchingPath(to)}
        sx={{
          color: "common.white",
          "&:active": neutralStyle,
          "&:focus": neutralStyle,
          "&:hover": neutralStyle,
          "&.Mui-selected": {
            backgroundColor: "primary.dark",
          },
        }}
      >
        <ListItemIcon>
          <Icon sx={{ color: "common.white" }} />
        </ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

const drawerWidth = 200

export const MobileSideBar: FC<{ open: boolean; closeNav: () => void }> = ({ open, closeNav }) => {
  const { data: canSeeAdmin } = useCanSeeAdmin()
  const { data: briefingsEnabled } = useIsBriefingsEnabled()
  const { data: opportunitiesEnabled } = useIsOpportunitiesEnabled()

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "primary.light",
        },
      }}
      anchor="left"
      open={open}
      onClose={closeNav}
    >
      <Toolbar />
      <List>
        {navConfig.map(({ label, to, icon }) => (
          <ListLink key={to} label={label} to={to} Icon={icon} closeNav={closeNav} />
        ))}
        {briefingsEnabled && <ListLink label="Briefings" to="/briefings" Icon={ArticleIcon} closeNav={closeNav} />}
        {opportunitiesEnabled && (
          <ListLink label="Opportunities" to="/opportunities" Icon={RequestQuoteIcon} closeNav={closeNav} />
        )}
        {canSeeAdmin && <ListLink label="Admin" to="/admin" Icon={AdminPanelSettingsIcon} closeNav={closeNav} />}
      </List>
    </Drawer>
  )
}
