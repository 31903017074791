import { FC } from "react"
import { Button, TableCell } from "@mui/material"
import { Link } from "react-router-dom"
import { TableButton } from "./TableButton"

export const TableCellContent: FC<{
  column: any
  value: any
  row: any
}> = ({ column, value, row }) => {
  let content: any
  if (column.action) {
    content = <Button onClick={value.action}>{value.label}</Button>
  } else if (column.link) {
    content = (
      <Link key={column.id} to={`${column.link}/${row.id}`} style={{ textDecoration: "none" }}>
        {column.format ? column.format(value) : value}
      </Link>
    )
  } else if (column.component === "popover") {
    content = <TableButton userList={value} campaign={value} popoverTitle={column.title} />
  } else {
    content = column.format ? column.format(value) : value
  }

  return (
    <TableCell key={column.id} align={column.align} style={{ cursor: column.link || "pointer" }}>
      {content}
    </TableCell>
  )
}
