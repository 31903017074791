import { useState, FC } from "react"
import { Typography, Button, Dialog, DialogContent, DialogActions, Stepper, Step, StepLabel, Link } from "@mui/material"
import { useCreateCampaign } from "../../../api/campaigns"
import { NewCampaign, CampaignWizardContext } from "./Context"
import { NameCampaign } from "./NameCampaign"
import { SearchTerms } from "./SearchTerms"
import { Geographies } from "./Geographies"
import { useModalKey, useCloseModalKey } from "../OpenModalHook"

export const campaignWizardModalKey = "CAMPAIGN_WIZARD"

const defaultCampaignValues: NewCampaign = {
  name: "",
  emailFrequency: "Daily",
  searchTerms: new Set(),
  advancedSearch: new Set(),
  filterParams: {
    cities: [],
    states: [],
    counties: [],
    organizations: [],
    channel_types: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
  },
  proximity: null,
  exclude: false,
}

export const CampaignWizard: FC = () => {
  const [newCampaignParameters, setNewCampaignParameters] = useState<NewCampaign>(defaultCampaignValues)
  const stepComplete = [
    Boolean(newCampaignParameters.name && newCampaignParameters.emailFrequency),
    true,
    Boolean(newCampaignParameters.searchTerms.size),
  ]
  const [step, setStep] = useState(0)
  const open = useModalKey(campaignWizardModalKey)
  const closeMenu = useCloseModalKey(campaignWizardModalKey)

  const close = () => {
    setStep(0)
    setNewCampaignParameters(defaultCampaignValues)
    closeMenu()
  }

  const { mutate: createCampaign, isLoading } = useCreateCampaign(close)
  let nextText = "Next"
  if (isLoading) {
    nextText = "Loading..."
  } else if (step === 2) {
    nextText = "Create My Campaign"
  }

  return (
    <CampaignWizardContext.Provider value={{ value: newCampaignParameters, setValue: setNewCampaignParameters }}>
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        PaperProps={{ sx: { maxWidth: "80%", paddingX: 6, paddingTop: 6 } }}
      >
        <DialogContent sx={{ paddingX: 0, flexShrink: "0" }}>
          <Link
            href="https://meetings.hubspot.com/jeremy-becker/jeremy-30-minutes-only"
            target="_blank"
            display="block"
            marginBottom={5}
            marginRight={1}
            sx={{ textAlign: "right" }}
          >
            Schedule Campaign Setup Call
          </Link>
          <Stepper activeStep={step} alternativeLabel>
            <Step>
              <StepLabel>
                <Typography variant="h4">Name Your Campaign</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                <Typography variant="h4">Choose Where to Monitor</Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                <Typography variant="h4">Enter Your Search Terms</Typography>
              </StepLabel>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogContent>
          {step === 0 && <NameCampaign />}
          {step === 1 && <Geographies />}
          {step === 2 && <SearchTerms />}
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 6 }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (step === 0) {
                close()
              } else {
                setStep((prevStep) => prevStep - 1)
              }
            }}
            sx={{ paddingX: 4 }}
          >
            {step === 0 ? "Cancel" : "Back"}
          </Button>
          <Button
            variant={stepComplete[step] ? "contained" : "outlined"}
            disabled={!stepComplete[step]}
            onClick={() => {
              if (step === 2) {
                if (newCampaignParameters["emailFrequency"]) {
                  createCampaign({
                    name: newCampaignParameters["name"],
                    filterParams: { ...newCampaignParameters["filterParams"] },
                    searchTerms: Array.from(newCampaignParameters["searchTerms"]),
                    emailCadence: newCampaignParameters["emailFrequency"],
                    mustIncludeInput: Array.from(newCampaignParameters["advancedSearch"]),
                    proximity:
                      newCampaignParameters["advancedSearch"].size > 0 ? newCampaignParameters["proximity"] : null,
                    exclude:
                      newCampaignParameters["advancedSearch"].size > 0 ? newCampaignParameters["exclude"] : false,
                  })
                } else {
                  setStep(0)
                }
              } else {
                setStep((prevValue) => prevValue + 1)
              }
            }}
            sx={{ paddingX: 4 }}
          >
            {nextText}
          </Button>
        </DialogActions>
      </Dialog>
    </CampaignWizardContext.Provider>
  )
}
