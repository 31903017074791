import { FC } from "react"
import { Button, Tooltip, Card, CardContent } from "@mui/material"

import { TranscriptListItemDescription } from "./TranscriptListItemDescription"
import { Transcript } from "../../../interfaces/transcript"
import { getWordTime } from "../../../functions/transcript-words-array"

export const buttonStylesTimeStamp = {
  background: "linear-gradient(280deg, rgba(4,4,24,1) 27%, rgba(4,26,55,.80))",
  color: "neutral.light",
  marginY: 1,
}

export const TranscriptListItem: FC<{
  transcript: Transcript
  setVideoStartTime: (startTime: any) => void
  searchTerm: string
  isSelected?: boolean
}> = ({ transcript, setVideoStartTime, searchTerm, isSelected = false }) => {
  const wordTime = getWordTime(searchTerm, transcript)
  const timestamp = new Date(wordTime * 1000).toISOString().slice(11, 19)

  return (
    <Card
      key={`${timestamp}-${searchTerm}-${transcript.id}`}
      sx={{
        border: isSelected ? 3 : 1,
        borderColor: "primary.dark",
        marginBottom: 1,
        position: "relative",
      }}
      variant="outlined"
    >
      <CardContent sx={{ paddingY: 0, "&:last-child": { paddingBottom: 0.5 } }}>
        <Tooltip title={`Seek to ${timestamp}`}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            sx={buttonStylesTimeStamp}
            onClick={() => {
              setVideoStartTime({ start: wordTime - 5, change: true })
              document.getElementById("meeting-video-player-anchor-element")?.scrollIntoView()
            }}
          >
            {timestamp}
          </Button>
        </Tooltip>
        <TranscriptListItemDescription transcript={transcript} searchTerm={searchTerm} />
      </CardContent>
    </Card>
  )
}
