import { Link } from "@mui/material"

export const formatDateTime = (date: string) => {
  return new Date(date)
    .toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    })
    .replace(" at", ",")
}

export const formatDate = (date: string) => {
  if (date === null) {
    return ""
  } else {
    return new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }
}

export const formatNumber = (num: number) => num?.toLocaleString("en-US") || "N/A"

export const formatYesNo = (value: any) => (value ? "Yes" : "No")

export const isPresent = (value: any) => (value ? "Yes" : "No")

export const formatLink = (linkText: string) => {
  return (
    <Link href={`mailto:${linkText}`} target="_blank">
      {linkText}
    </Link>
  )
}
