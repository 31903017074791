import { FC, useEffect, useMemo, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { IOrganizationRouteParams } from "@api/interfaces"
import { useParams } from "react-router-dom"
import { FetchedOrgMeeting, useFetchOrganizationMeetings } from "@api/admin/organizations"

export const NewMeeting: FC<{
  open: boolean
  onClose: () => any
  onConfirm: any
  meetingId?: number
  type: string
}> = ({ open, onClose, onConfirm, meetingId, type }) => {
  const [titleInput, setTitleInput] = useState("")
  const [sourceVideoUrl, setSourceVideoUrl] = useState("")
  const [publishedAt, setPublishedAt] = useState("")
  const [duration, setDuration] = useState("")
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: meetingsData } = useFetchOrganizationMeetings({ id: parseInt(organizationId) })

  const selectedMeeting = useMemo(() => {
    if (type === "edit") {
      return meetingsData?.meetings?.find((meeting: FetchedOrgMeeting) => meeting.id === meetingId)
    }
  }, [meetingsData, meetingId, type])

  useEffect(() => {
    if (type === "edit" && selectedMeeting) {
      setTitleInput(selectedMeeting?.title)
      setSourceVideoUrl(selectedMeeting?.source_video_url)
      setPublishedAt(selectedMeeting?.published_at)
      setDuration(selectedMeeting?.duration_seconds)
    }
  }, [selectedMeeting, type])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`${type} Meeting`}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="meeting-title"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          value={titleInput}
          onChange={(event: any) => setTitleInput(event.target.value)}
        />
        <TextField
          margin="dense"
          id="meeting-source-video-url"
          label="Source Video Url"
          type="text"
          fullWidth
          variant="standard"
          value={sourceVideoUrl}
          onChange={(event: any) => setSourceVideoUrl(event.target.value)}
        />
        <TextField
          margin="dense"
          id="meeting-published-at"
          label="Published At"
          type="text"
          fullWidth
          variant="standard"
          value={publishedAt}
          onChange={(event: any) => setPublishedAt(event.target.value)}
        />
        <TextField
          margin="dense"
          id="meeting-duration"
          label="Duration (seconds)"
          type="text"
          fullWidth
          variant="standard"
          value={duration}
          onChange={(event: any) => setDuration(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onConfirm({
              title: titleInput,
              sourceVideoUrl: sourceVideoUrl,
              publishedAt: publishedAt,
              duration: duration,
              meetingId: meetingId,
              orgId: organizationId,
            })
            onClose()
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
