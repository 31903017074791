import { RubyObject } from "./default"

export enum RoleTypes {
  Admin = "Admin",
  SuperAdmin = "Super Admin",
  Basic = "Basic",
  Contractor = "Contractor",
  Free = "Free",
  SpeakerIndentification = "Speaker Identification",
}

export enum RolesMap {
  "Admin" = 1,
  "Super Admin" = 2,
  "Basic" = 3,
  "Contractor" = 4,
  "Free" = 5,
  "Speaker Identification" = 6,
}

export const RolesOptions = Object.keys(RolesMap)
  .filter((key) => isNaN(Number(key)))
  .map((key) => ({
    label: key,
    value: RolesMap[key as keyof typeof RolesMap],
  }))

export interface Role extends RubyObject {
  name: RoleTypes
  resource_id: string | null
  resource_type: string | null
}
