import { FC, useEffect, useState } from "react"
import { Box, Button, Link } from "@mui/material"
import { useOpenModalKey, useSetData } from "../../shared/OpenModalHook"
import { FetchPersonsResponse, useUpdatePerson } from "@api/admin/persons"
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridActionsCellItem,
  GridPaginationModel,
  GridSortModel,
  GridSortDirection,
  GridRowParams,
  GridValidRowModel,
  GridInputRowSelectionModel,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarProps,
} from "@mui/x-data-grid"
import { formatDateTime } from "@src/components/shared/DataTable"
import { MenuOpenOutlined } from "@mui/icons-material"
import { VOICE_MODAL_KEY } from "@src/components/shared/VoicesModal"
import { PersonVoicesTranscripts } from "@src/interfaces/person"
import { useToast } from "@src/components/shared/ToastHook"
import { isEqual } from "lodash"
import useSetUrlParamState from "@src/components/shared/UseSetUrlParamState"
import { AdminPersonPageParams } from "./index"
import { MergePersonsModalKey } from "./mergePersonModal"

function CustomToolbar(props: GridToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }}>
        <Button variant="text" color="primary" disabled={props.disableMerge} onClick={props.openMerge}>
          Merge Persons
        </Button>
      </Box>
    </GridToolbarContainer>
  )
}

export interface PersonsDataGridProps {
  filterParams: AdminPersonPageParams
  data: FetchPersonsResponse
  loading?: boolean
}

export const PersonsDataGrid: FC<PersonsDataGridProps> = ({ filterParams, data, loading }) => {
  const setFilterParams = useSetUrlParamState<AdminPersonPageParams>()

  const openVoices = useOpenModalKey(VOICE_MODAL_KEY)
  const openMergePeople = useOpenModalKey(MergePersonsModalKey)

  const toast = useToast()
  const { mutate: updatePerson, isLoading: personIsLoading } = useUpdatePerson(() =>
    toast("Person updated", undefined, undefined, "info"),
  )
  const [isLoading, setIsLoading] = useState(loading)

  useEffect(() => {
    setIsLoading(loading || personIsLoading)
  }, [loading, personIsLoading])

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: filterParams.count,
    page: filterParams.page,
  })

  const [sortingModel, setSortingModel] = useState<GridSortModel>(
    filterParams.sort_by
      ? [
          {
            field: filterParams.sort_by,
            sort: filterParams.sort_order ? (filterParams.sort_order as GridSortDirection) : "asc",
          },
        ]
      : [],
  )

  const [selectionModel, setSelectionModel] = useState<GridInputRowSelectionModel>()

  const setData = useSetData()

  const hasChanges = ({
    id,
    name,
    title,
    organization,
  }: {
    id: number
    name: string
    title: string
    organization: string
  }) => {
    const originalRow = data?.persons.find((person) => person.id === id)
    if (!originalRow) {
      return false
    }
    const obj1 = { name: name, title: title, organization: organization }
    const obj2 = { name: originalRow.name, title: originalRow.title, organization: originalRow.organization }
    return !isEqual(obj1, obj2)
  }

  const handleOpenVoiceModal = (person: PersonVoicesTranscripts) => {
    setData(person)
    openVoices()
  }

  const handleOpenMergePeopleModal = () => {
    const selectedRows = rows?.filter((row) => (selectionModel as Array<number>)?.includes(row.id as number))
    if (!selectedRows) {
      toast("No rows selected", undefined, undefined, "error")
      return
    }
    setData({
      rows: selectedRows,
    })
    openMergePeople()
  }

  const handleUpdatePerson = (row: GridValidRowModel) => {
    updatePerson({
      id: row.id as number,
      name: row.name as string,
      title: row.title as string,
      organization: row.organization as string,
    })
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      renderCell(params) {
        return <Link href={`/persons/${params.row.id}`}>{params.row.id}</Link>
      },
    },
    { field: "name", headerName: "Name", flex: 3, editable: true },
    { field: "title", headerName: "Title", flex: 3, editable: true },
    { field: "organization", headerName: "Organization", flex: 4, editable: true },
    { field: "created_at", headerName: "Created At", flex: 2 },
    { field: "updated_at", headerName: "Updated At", flex: 2 },
    {
      field: "voices",
      headerName: "Voices",
      flex: 1,
      sortable: false,
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          label="Voices"
          disabled={!params.row.voices || params.row.voices.length === 0}
          onClick={() => {
            handleOpenVoiceModal(params.row)
          }}
          icon={<MenuOpenOutlined />}
        />,
      ],
    },
    {
      field: "actions",
      type: "actions",
      getActions: ({ row }) => {
        return [
          <GridActionsCellItem
            label="Update"
            disabled={
              personIsLoading ||
              !hasChanges({ id: row.id, name: row.name, title: row.title, organization: row.organization })
            }
            onClick={() => handleUpdatePerson(row)}
            showInMenu={true}
          />,
        ]
      },
    },
  ]

  const [rows, setRows] = useState<GridRowsProp>()

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (data) {
      setRows(
        data.persons.map((person) => ({
          id: person.id,
          name: person.name,
          title: person.title,
          organization: person.organization,
          created_at: formatDateTime(person.created_at),
          updated_at: formatDateTime(person.updated_at),
          voices: person.voices,
        })),
      )
    }
  }, [data, isLoading])

  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
    setFilterParams({
      ...filterParams,
      page: paginationModel.page,
      count: paginationModel.pageSize,
    })
  }

  const handleSortingChange = (sortingModel: GridSortModel) => {
    setSortingModel(sortingModel)
    setPaginationModel({ ...paginationModel, page: 0 })
    setFilterParams({
      ...filterParams,
      sort_by: sortingModel[0]?.field,
      sort_order: sortingModel[0]?.sort || "asc",
      page: 0,
    })
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isLoading}
      checkboxSelection
      sx={{
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
      }}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      paginationMode="server"
      sortingMode="server"
      paginationModel={paginationModel}
      sortModel={sortingModel}
      onRowSelectionModelChange={(newSelection) => {
        setSelectionModel(newSelection)
      }}
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{
        toolbar: {
          disableMerge: selectionModel === undefined || (selectionModel as Array<number>).length < 2,
          openMerge: handleOpenMergePeopleModal,
        },
      }}
      rowSelectionModel={selectionModel}
      autoHeight={true}
      onSortModelChange={handleSortingChange}
      onPaginationModelChange={handlePaginationChange}
      rowCount={data?.total ?? -1}
    />
  )
}
