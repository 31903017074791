import { Dispatch, SetStateAction, useState, useEffect, createContext, FC, ReactNode, useRef } from "react"
import { TableBody, TableCellProps, TypographyProps, TableRow, TableCell, Checkbox, Skeleton } from "@mui/material"
import { SxProps, Theme, styled } from "@mui/material/styles"
import { FixedSizeList as List } from "react-window"
import UntypedAutoSizer from "react-virtualized-auto-sizer"
import { Row } from "./Row"
import { TableWithHeader } from "./TableWithHeader"

export { formatDateTime, formatDate, formatYesNo, isPresent } from "./formatters"

export interface Action {
  action: () => void
  text: string
  icon?: ReactNode
}

export interface Size {
  height: number
  width: number
}

export interface AutoSizerProps {
  /** Function responsible for rendering children. */
  children: (size: Size) => React.ReactNode

  /** Optional custom CSS class name to attach to root AutoSizer element.    */
  className?: string | undefined

  /** Default height to use for initial render; useful for SSR */
  defaultHeight?: number | undefined

  /** Default width to use for initial render; useful for SSR */
  defaultWidth?: number | undefined

  /** Disable dynamic :height property */
  disableHeight?: boolean | undefined

  /** Disable dynamic :width property */
  disableWidth?: boolean | undefined

  /** Nonce of the inlined stylesheet for Content Security Policy */
  nonce?: string | undefined

  /** Callback to be invoked on-resize */
  onResize?: ((size: Size) => void) | undefined

  /** Optional inline style */
  style?: React.CSSProperties | undefined
}

const AutoSizer = UntypedAutoSizer as unknown as FC<AutoSizerProps>
type SortOrder = "asc" | "desc"

export interface TableSort {
  name: string
  method: SortOrder
}

interface OrderObject {
  order: TableSort["method"]
  sortColumn: string | number | symbol | string[]
}

export interface Column {
  id: string
  key?: (data: any, index: number) => string
  sortProp?: string | string[]
  label?: string
  link?: string | ((data: any) => string)
  linkTarget?: string
  image?: string
  buttonAction?: (a: any) => void
  buttonTitle?: string
  buttonVariant?: "text" | "outlined" | "contained"
  popoverTitle?: string
  formatter?: (value: any) => ReactNode
  headerCellProps?: TableCellProps
  sortingEnabled?: boolean
  typographyProps?: TypographyProps
  onClick?: () => void
}

const RelativeTableBody = styled(TableBody)({ position: "relative" })

interface ContextValue {
  columns: readonly (symbol | number | string | Column)[]
  size?: "small" | "medium"
  orderObject: OrderObject
  setOrderObject: Dispatch<SetStateAction<OrderObject>>
  toggleAllSelected?: () => void
  checked?: boolean
  indeterminate?: boolean
  addRef?: (node: unknown) => void
  stickyHeader?: boolean
  headerSortFunction?: (sortBy: TableSort) => any
  headerSortInitialOrder?: SortOrder
  selected?: (string | number)[]
  actions?: Action[]
  actionsBarEnabled?: boolean
}

export const TableContext = createContext<ContextValue>({
  columns: [],
  orderObject: { order: "desc", sortColumn: "" },
  setOrderObject: () => {
    /* placeholder */
  },
})

function descendingComparator<T>(a: T, b: T, orderBy: keyof T | string[]) {
  let firstElement
  let secondElement
  // unpack nested
  if (Array.isArray(orderBy)) {
    firstElement = orderBy.reduce((obj: any, accessor: string) => (obj ? obj[accessor] : undefined), a)
    secondElement = orderBy.reduce((obj: any, accessor: string) => (obj ? obj[accessor] : undefined), b)
  } else {
    firstElement = a[orderBy]
    secondElement = b[orderBy]
  }
  // unpack hyperlink
  if (firstElement && firstElement.props && firstElement.props.children) {
    firstElement = firstElement.props.children
    secondElement = secondElement.props.children
  }
  if (secondElement < firstElement) {
    return -1
  }
  if (secondElement > firstElement) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  orderObject: OrderObject,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return orderObject.order === "desc"
    ? (a, b) => descendingComparator(a, b, orderObject.sortColumn as Key)
    : (a, b) => -descendingComparator(a, b, orderObject.sortColumn as Key)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export interface DataTableProps<Row extends Record<string, any>, IdType extends string | number> {
  data?: Row[]
  columns: readonly (keyof Row | Column)[]
  columnKey?: keyof Row
  defaultSort?: keyof Row
  defaultSortOrder?: TableSort["method"]
  height?: number
  selected?: IdType[]
  setSelected?: Dispatch<SetStateAction<IdType[]>>
  size?: "small" | "medium"
  virtualize?: boolean
  virtualizeBreakpoint?: number
  showSkeleton?: boolean
  skeletonRows?: number
  tableStyle?: SxProps<Theme>
  stickyHeader?: boolean
  headerSortFunction?: (sortBy: TableSort) => any
  headerSortInitialOrder?: SortOrder
  actions?: Action[]
  actionsBarEnabled?: boolean
}

export const DataTable = <Row extends Record<string, any>, IdType extends string | number>({
  data,
  columns,
  columnKey = "id",
  height: forcedHeight,
  size,
  selected,
  setSelected,
  virtualize,
  virtualizeBreakpoint = 100,
  defaultSort,
  defaultSortOrder,
  showSkeleton,
  skeletonRows = 3,
  tableStyle,
  stickyHeader = false,
  headerSortFunction,
  headerSortInitialOrder,
  actions = [],
  actionsBarEnabled = true,
}: DataTableProps<Row, IdType>) => {
  const isInitialSortingRender = useRef(true)
  const [orderObject, setOrderObject] = useState<OrderObject>({
    order: defaultSortOrder || "asc",
    sortColumn: defaultSort || "",
  })
  const [widthList, setWidthList] = useState<number[]>([])
  const [rowHeight, setRowHeight] = useState(10)
  // autoSizerWidth is only used for re-rendering the element to get column widths
  const [autoSizerWidth, setAutoSizerWidth] = useState(0)
  useEffect(() => setWidthList([]), [columns])
  const useCheckbox = selected && setSelected

  const tempList: HTMLElement[] = []
  const shouldVirtualize = virtualize || (data && data.length > virtualizeBreakpoint && virtualize !== false)

  // When order object changes we need to call header sort function if available
  useEffect(() => {
    if (
      headerSortFunction &&
      typeof orderObject?.sortColumn === "string" &&
      orderObject?.sortColumn !== "" &&
      !isInitialSortingRender.current
    ) {
      const sortBy: TableSort = { name: orderObject.sortColumn, method: orderObject.order }
      headerSortFunction(sortBy)
    }
  }, [orderObject])

  useEffect(() => {
    function handleResize() {
      if (shouldVirtualize) {
        setWidthList(tempList.map((element) => element.getBoundingClientRect().width))
      }
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [columns, data, autoSizerWidth])

  const contextValue: ContextValue = {
    columns,
    size,
    orderObject,
    setOrderObject,
    stickyHeader,
    selected,
    actions,
    actionsBarEnabled,
    headerSortInitialOrder,
  }
  let makeSetSelected: ((id: IdType) => () => void) | undefined
  if (useCheckbox) {
    makeSetSelected = (id: IdType) => () => {
      setSelected((prevSelected) => {
        const index = prevSelected.indexOf(id as IdType)
        if (index === -1) {
          return [...prevSelected, id]
        } else {
          prevSelected.splice(index, 1)
          return [...prevSelected]
        }
      })
    }
    contextValue.toggleAllSelected = () =>
      setSelected((prevSelected) => {
        if (data && !prevSelected.length) {
          return data.map((row) => row[columnKey])
        } else {
          return []
        }
      })
    contextValue.checked = Boolean(data && data.length && selected.length === data.length)
    contextValue.indeterminate = Boolean(!contextValue.checked && selected.length)
  }

  // this effect should always be the last one, because it detects if it's the initial render or not
  useEffect(() => {
    isInitialSortingRender.current = false
  }, [])

  let content: ReactNode

  if (showSkeleton || !data) {
    content = (
      <TableWithHeader>
        <TableBody>
          {new Array(skeletonRows).fill(null).map((_, index) => (
            <TableRow key={index}>
              {useCheckbox && (
                <TableCell padding="checkbox">
                  <Checkbox disabled />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell key={typeof column === "object" ? column.id : column.toString()}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableWithHeader>
    )
  } else {
    const sortedData = stableSort<Row>(data, getComparator(orderObject))

    if (shouldVirtualize) {
      contextValue.addRef = (node: unknown) => {
        if (node !== null && node instanceof HTMLElement) {
          tempList.push(node)
        }
      }
      content = (
        <AutoSizer disableHeight={Boolean(forcedHeight)} onResize={({ width }) => setAutoSizerWidth(width)}>
          {({ height, width }) => (
            <List
              height={forcedHeight || height}
              itemCount={data.length}
              itemSize={rowHeight}
              width={width}
              outerElementType={TableWithHeader}
              innerElementType={RelativeTableBody}
            >
              {({ index, style }) => {
                const rowData = sortedData[index]
                return (
                  <Row
                    data={rowData}
                    selected={selected?.includes(rowData[columnKey])}
                    setSelected={makeSetSelected && makeSetSelected(rowData[columnKey])}
                    setRowHeight={index === 0 ? setRowHeight : undefined}
                    columns={columns as (string | Column)[]}
                    sx={style}
                    widthList={widthList}
                  />
                )
              }}
            </List>
          )}
        </AutoSizer>
      )
    } else {
      content = (
        <TableWithHeader sx={tableStyle}>
          <TableBody>
            {sortedData.map((rowData) => (
              <Row
                key={rowData[columnKey]}
                data={rowData}
                selected={selected?.includes(rowData[columnKey])}
                setSelected={makeSetSelected && makeSetSelected(rowData[columnKey])}
                columns={columns as (string | Column)[]}
              />
            ))}
          </TableBody>
        </TableWithHeader>
      )
    }
  }
  return <TableContext.Provider value={contextValue}>{content}</TableContext.Provider>
}
