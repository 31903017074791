import { BriefingsContainerContext } from "@src/containers/BriefingContainers/BriefingsContainer"
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { FC, ReactNode, useContext, useState } from "react"
import { getGeneratedByModelList } from "@api/admin/briefings"

export const BriefingGeneratedByModelAutocomplete: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(BriefingsContainerContext)
  const [modelType, setModelType] = useState<string | null>(urlParamState.generated_by_model ?? null)

  const { data } = getGeneratedByModelList()

  const handleOnChange = (event: React.SyntheticEvent, value: string | null) => {
    setModelType(value)
    setUrlParamState({
      ...urlParamState,
      generated_by_model: value,
      page: 0,
    })
  }

  if (data && data.length > 0) {
    return (
      <FormControl sx={{ minWidth: 200 }}>
        <Autocomplete
          onChange={handleOnChange}
          options={data}
          renderInput={(params) => <TextField {...params} label="Generated By Model" />}
          value={modelType}
        />
      </FormControl>
    )
  }
  return <></>
}
