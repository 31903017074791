import { FC, useMemo, useState } from "react"
import { useCloseModalKey, useModalKey, useOpenModalKey } from "../shared/OpenModalHook"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useSendRequestEmail } from "../../api/organizations"

import AddIcon from "@mui/icons-material/Add"
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined"
import RemoveIcon from "@mui/icons-material/Remove"

const modalKey = "REQUEST_CHANNEL_KEY"

export interface ChannelRequest {
  channelName: string
  url: string
}

export const RequestChannelMenuItem: FC = () => {
  const openModalKey = useOpenModalKey(modalKey)

  return <MenuItem onClick={openModalKey}>Request New Channel</MenuItem>
}

export const RequestChannelDialog: FC = () => {
  const open = useModalKey(modalKey)
  const closeModalKey = useCloseModalKey(modalKey)
  const [showSubmitMessage, setShowSubmitMessage] = useState(false)
  const initialChannelState = [{ channelName: "", url: "" }]
  const [channels, setChannels] = useState<ChannelRequest[]>(initialChannelState)
  const { mutate: sendRequest } = useSendRequestEmail()

  const addChannel = () => {
    const copyChannels = [...channels]
    copyChannels.push({ channelName: "", url: "" })
    setChannels(copyChannels)
  }

  const changeChannelName = (channelName: string, index: number) => {
    const copyChannels = [...channels]
    copyChannels[index].channelName = channelName
    setChannels(copyChannels)
  }

  const changeUrl = (url: string, index: number) => {
    const copyChannels = [...channels]
    copyChannels[index].url = url
    setChannels(copyChannels)
  }

  const removeChannel = (index: number) => {
    const copyChannels = [...channels]
    copyChannels.splice(index, 1)
    setChannels(copyChannels)
  }

  const handleSubmit = () => {
    setShowSubmitMessage(true)
    sendRequest(channels)
  }

  const handleClose = () => {
    setChannels(initialChannelState)
    setShowSubmitMessage(false)
    closeModalKey()
  }

  const submitDisabled = useMemo(() => {
    let disabled = false

    channels.forEach((channel) => {
      if (channel.channelName === "" && channel.url === "") {
        disabled = true
      }
    })
    return disabled
  }, [channels])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Request New Channels</DialogTitle>
      <DialogContent>
        {showSubmitMessage ? (
          <Stack direction="row" margin={5} spacing={2} justifyContent="center">
            <Typography variant="h2">We are on it! Thanks for your help.</Typography>
            <CheckBoxOutlinedIcon fontSize="large" />
          </Stack>
        ) : (
          <>
            {channels.map((channel: ChannelRequest, index) => (
              <Stack key={`stack${index}123`} direction="row" margin={2} spacing={2}>
                <TextField
                  value={channel.channelName}
                  label="Enter name of govt. agency, commission, etc"
                  onChange={(event) => changeChannelName(event.target.value, index)}
                  fullWidth
                />
                <TextField
                  value={channel.url}
                  label="Enter url location"
                  onChange={(event) => changeUrl(event.target.value, index)}
                  fullWidth
                />
                <Box display="flex" visibility={index > 0 ? "visible" : "hidden"} alignItems="center">
                  <IconButton size="small" onClick={() => removeChannel(index)}>
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Stack>
            ))}
            <Box>
              <Button startIcon={<AddIcon />} onClick={addChannel}>
                Add another channel
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!showSubmitMessage && (
          <Button onClick={handleSubmit} disabled={submitDisabled}>
            Submit
          </Button>
        )}
        <Button onClick={handleClose}>{showSubmitMessage ? "Close" : "Cancel"}</Button>
      </DialogActions>
    </Dialog>
  )
}
