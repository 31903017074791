import { FC } from "react"
import { HoursAnalyzed } from "../customize_widget/HoursAnalyzed"
import { TopChannels } from "../customize_widget/TopChannels"
import { KeywordsOverTime } from "../customize_widget/KeywordsOverTime"
import { Configuration, Widget, WidgetConfig } from "../../../interfaces/dashboard"
import { GeographiesOverTime } from "../customize_widget/GeographiesOverTime"

export const EditableWidget: FC<{
  actionWidget: Widget
  setActionWidgetConfig: (config: Configuration) => void
}> = ({ actionWidget, setActionWidgetConfig }) => {
  const editConfig: WidgetConfig = {
    hours: <HoursAnalyzed setConfiguration={setActionWidgetConfig} defaultConfig={actionWidget?.configuration} />,
    top_channels: <TopChannels setConfiguration={setActionWidgetConfig} defaultConfig={actionWidget?.configuration} />,
    keywords_over_time: (
      <KeywordsOverTime setConfiguration={setActionWidgetConfig} defaultConfig={actionWidget?.configuration} />
    ),
    geographies_over_time: (
      <GeographiesOverTime setConfiguration={setActionWidgetConfig} defaultConfig={actionWidget?.configuration} />
    ),
  }
  return <>{editConfig[actionWidget?.widget_type]}</>
}
