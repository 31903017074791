import { FC, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { useToast } from "../../shared/ToastHook"
import { useCreatePerson } from "../../../api/admin/persons"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"

export const CREATE_PERSON_MODAL_KEY = "CREATE_PERSON"

export const CreatePersonModal: FC = () => {
  const isOpen = useModalKey(CREATE_PERSON_MODAL_KEY)
  const close = useCloseModalKey(CREATE_PERSON_MODAL_KEY)
  const toast = useToast()
  const onSuccess = () => {
    toast("Person Created")
    close()
  }

  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [organization, setOrganization] = useState("")

  const { mutate: createPerson } = useCreatePerson(onSuccess)

  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle>Create Person</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack spacing={1}>
          <TextField
            size="small"
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Name"
            fullWidth
          />
          <TextField
            size="small"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label="Title"
            fullWidth
            multiline
          />
          <TextField
            size="small"
            value={organization}
            onChange={(event) => setOrganization(event.target.value)}
            label="Organization"
            fullWidth
            multiline
          />
        </Stack>
        <DialogActions>
          <Button variant="contained" onClick={() => createPerson({ name, title, organization })}>
            Create
          </Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
