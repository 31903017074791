import { FC, useState, useEffect } from "react"
import { Button, Typography, Stack, TextField } from "@mui/material"
import { useCreateFreeUser } from "../../../api/promotions/users"
import { useToast } from "../ToastHook"

export const PromoFooter: FC = () => {
  const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const [email, setEmail] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(false)
  const toast = useToast()

  const { mutate } = useCreateFreeUser(toast)

  useEffect(() => {
    if (validEmailRegex.test(email)) {
      setIsValidEmail(true)
    } else {
      setIsValidEmail(false)
    }
  }, [email])

  return (
    <Stack
      width="50vh"
      spacing={3}
      border={3}
      borderColor="primary.dark"
      padding={2}
      color="primary"
      bgcolor="white"
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        marginLeft: 0,
        marginBottom: 2,
      }}
    >
      <Typography variant="h1" color="primary.dark" textAlign="center">
        Learn more about Cloverleaf. Sign up for your FREE account.
      </Typography>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
          type="email"
          label="Email"
          variant="outlined"
          error={!isValidEmail}
          color="primary"
          sx={{ width: "80%" }}
        />
        <Button size="small" variant="contained" color="primary" disabled={!isValidEmail} onClick={() => mutate(email)}>
          Sign Up
        </Button>
      </Stack>
    </Stack>
  )
}
