import { FC, useContext, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useFetchClipShare } from "../api/promotions/clip_shares"
import { ClipShare } from "../components/Promo/share/ClipShare"
import { PromoContext } from "../App"
import { useIsPaidUser } from "../api/users"
import { useFetchClipShare as useFetchPromotionalClipShare } from "../api/promotions/clip_shares"
import { redirectIfPaidUser } from "../components/Promo/utilities"
import { PromoFooter } from "../components/shared/PromoFooter"
import { Stack } from "@mui/material"

export const NoAuthSharePage: FC = () => {
  const { id } = useParams<{ id: string }>()

  const isPromo = useContext(PromoContext)
  const { isError, isLoading, data } = useFetchClipShare(parseInt(id), isPromo)
  const {
    data: promoData,
    isLoading: promoLoading,
    isError: promoIsError,
  } = useFetchPromotionalClipShare(parseInt(id), isPromo)
  const { data: isPaidUser } = useIsPaidUser()
  const params = new URLSearchParams(useLocation().search)

  useEffect(() => redirectIfPaidUser(params, isPromo ? promoData : data, isPaidUser), [isPaidUser, data, promoData])

  return (
    <Stack width="100%" alignItems="center" sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 4 }}>
      <Stack width={"100%"}>
        <ClipShare
          clipShareData={isPromo ? promoData : data}
          isLoading={isPromo ? promoLoading : isLoading}
          isError={isPromo ? promoIsError : isError}
        />
      </Stack>
      <PromoFooter />
    </Stack>
  )
}
