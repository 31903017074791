import { useMutation, useQuery, useQueryClient } from "react-query"
import { User } from "../interfaces/user"
import { useAuthAxios } from "./axiosInstance"
import { Bookmark } from "../interfaces/bookmark"
import { BaseClipShare } from "../interfaces/clipShare"
import { BaseMeeting } from "../interfaces/meeting"
import { Organization } from "../interfaces/organization"

interface BookmarkParams {
  id?: number
  title: string
  visible: boolean
}

interface AddClipToBookmarkParams extends BookmarkParams {
  clipTitle: string
  meetingId: number
  startTimeInSeconds: number
  transcriptId: number | string
  keyword: string
  description: string
  taggedUsers: User[]
}
export interface FetchedClipShare extends BaseClipShare {
  meeting: BaseMeeting
  organization: Organization
  raw_text: string
  tagged_users: string[]
}

export interface FetchedBookmarksItem extends Bookmark {
  clips: FetchedClipShare[]
}

const prefix = "/bookmarks"

export const useFetchAllUserBookmarks = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["bookmarks"], async () => {
    const response = await (await axiosInstance).get<FetchedBookmarksItem[]>(prefix)
    return response.data
  })
}

export const useFetchSharedBookmarks = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["shared_bookmarks"], async () => {
    const response = await (await axiosInstance).get<FetchedBookmarksItem[]>(`${prefix}/shared`)
    return response.data
  })
}

export const useUpdateBookmark = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: BookmarkParams) => {
      return (await axiosInstance).put(`${prefix}/${params.id}`, {
        title: params.title,
        visible: params.visible,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
      },
    },
  )
}

export const useCreateBookmark = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: BookmarkParams) => {
      return (await axiosInstance).post(prefix, {
        title: params.title,
        visible: params.visible,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
      },
    },
  )
}

export const useCreateBookmarkAddClip = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: AddClipToBookmarkParams) => {
      return (await axiosInstance).post(prefix, {
        title: params.title,
        clip_title: params.clipTitle,
        meeting_id: params.meetingId,
        start_time_in_seconds: params.startTimeInSeconds,
        transcript_id: params.transcriptId,
        keyword: params.keyword,
        description: params.description,
        visible: params.visible,
        tagged_users: params.taggedUsers.map((user) => user.id),
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
      },
    },
  )
}

export const useDeleteBookmark = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(`${prefix}/${params.id}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
      },
    },
  )
}
