import { Grid, Card, CardContent } from "@mui/material"
import { FC } from "react"
import { HoursAnalyzed as CustomizeHoursAnalyzed } from "./HoursAnalyzed"
import { TopChannels as CustomizeTopChannels } from "./TopChannels"
import { TopChannels as TopChannelsDisplay } from "../display_widget/TopChannels"
import { HoursAnalyzed as HoursAnalyzedDisplay } from "../display_widget/HoursAnalyzed"
import { KeywordsOverTime as CustomizeKeywordsOverTime } from "./KeywordsOverTime"
import { KeywordsOverTime as KeywordsOverTimeDisplay } from "../display_widget/KeywordsOverTime"
import { GeographiesOverTime as CustomizeGeographiesOverTime } from "./GeographiesOverTime"
import { GeographiesOverTime as GeographiesOverTimeDisplay } from "../display_widget/GeographiesOverTime"

export const WidgetContainer: FC<{
  type: string
  setConfiguration: any
  configuration: any
}> = ({ type, setConfiguration, configuration }) => {
  const customizeWidgetConfig: any = {
    hoursAnalyzed: {
      input: <CustomizeHoursAnalyzed setConfiguration={setConfiguration} />,
      preview: <HoursAnalyzedDisplay configuration={configuration} />,
    },
    topChannels: {
      input: <CustomizeTopChannels setConfiguration={setConfiguration} />,
      preview: <TopChannelsDisplay configuration={configuration} />,
    },
    keywordsOverTime: {
      input: <CustomizeKeywordsOverTime setConfiguration={setConfiguration} />,
      preview: <KeywordsOverTimeDisplay configuration={configuration} />,
    },
    geographiesOverTime: {
      input: <CustomizeGeographiesOverTime setConfiguration={setConfiguration} />,
      preview: <GeographiesOverTimeDisplay configuration={configuration} />,
    },
  }

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={12} padding={1}>
        {customizeWidgetConfig[type].input}
      </Grid>
      <Grid item xs={12} padding={1}>
        <Card>
          <CardContent>{customizeWidgetConfig[type].preview}</CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
