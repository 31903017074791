import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material"
import { useOpenModalKey } from "@src/components/shared/OpenModalHook"
import { useContext } from "react"
import { VideoArchiveDetails } from "./VideoArchiveDetails"
import { useFetchChannelTypes } from "@src/api/channel_types"
import { unpackSetters } from "@src/components/shared/unpackSetters"
import { OrganizationFormContext } from "./Context"
import { useFetchOrganizationMetadata } from "@src/api/organizations"

export const updateModalKey = "UPDATE_ORG_KEY"

export const OrganizationDetails = () => {
  const openUpdateModalKey = useOpenModalKey(updateModalKey)
  const { data: channelTypes } = useFetchChannelTypes()
  const { value: orgValue, setValue: setOrgValue } = useContext(OrganizationFormContext)
  const { data: orgMetadataData } = useFetchOrganizationMetadata()

  const {
    name,
    videoArchiveId,
    homePageUrl,
    contactPageUrl,
    videoArchiveType,
    videoArchiveDetails: vidArchiveDetailsString,
    channelTypeId,
  } = orgValue

  const {
    setName,
    setVideoArchiveId,
    setHomePageUrl,
    setContactPageUrl,
    setVideoArchiveType,
    setVideoArchiveDetails,
    setChannelTypeId,
  } = unpackSetters(orgValue, setOrgValue)

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">Organization Details</Typography>
        <Button onClick={openUpdateModalKey} variant="contained">
          Update
        </Button>
      </Stack>
      <TextField size="small" label="Name" value={name} onChange={(event) => setName(event.target.value)} fullWidth />
      {videoArchiveType !== "general" && (
        <TextField
          size="small"
          label="Channel Id"
          value={videoArchiveId}
          onChange={(event) => setVideoArchiveId(event.target.value)}
          fullWidth
        />
      )}
      <FormControl fullWidth>
        <InputLabel id="channel-type-label">Channel Type</InputLabel>
        <Select
          value={channelTypeId}
          onChange={(event) => setChannelTypeId(event.target.value as number)}
          labelId="channel-type-label"
          label="Channel Type"
        >
          {channelTypes?.map((channel_type) => (
            <MenuItem key={channel_type.id} value={channel_type.id}>
              {channel_type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="video-archive-type-select-label">Video Archive Type</InputLabel>
        <Select
          value={videoArchiveType}
          onChange={(event) => setVideoArchiveType(event.target.value)}
          labelId="video-archive-type-select-label"
          label="Video Archive Type"
        >
          {orgMetadataData?.video_archive_type.map((archive_type) => (
            <MenuItem key={archive_type.id} value={archive_type.value}>
              {archive_type.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {videoArchiveType === "general" ? (
        <VideoArchiveDetails />
      ) : (
        <TextField
          size="small"
          label="Video Archive Details"
          value={vidArchiveDetailsString}
          onChange={(event) => setVideoArchiveDetails(event.target.value)}
          fullWidth
          multiline
        />
      )}
      <TextField
        size="small"
        label="Home Page URL"
        value={homePageUrl}
        onChange={(event) => setHomePageUrl(event.target.value)}
        fullWidth
      />
      <TextField
        size="small"
        label="Contact Page URL"
        value={contactPageUrl}
        onChange={(event) => setContactPageUrl(event.target.value)}
        fullWidth
      />
    </Stack>
  )
}
