import { FC } from "react"
import { Stack } from "@mui/material"
import { SelectMainSalesforceObject } from "./selectMainSalesforceObject"
import { SelectMainSalesforceObjectAttachment } from "./selectMainSalesforceObjectAttachment"
import { SelectMainSalesforceObjectAttachmentFields } from "./selectMainSalesforceObjectAttachmentFields"

export const Salesforce: FC = () => (
  <Stack marginTop={4} alignItems="flex-start" spacing={1}>
    <SelectMainSalesforceObject />
    <SelectMainSalesforceObjectAttachment />
    <SelectMainSalesforceObjectAttachmentFields />
  </Stack>
)
