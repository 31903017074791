import { createContext, Dispatch, SetStateAction } from "react"

interface ContextData {
  id: number | null
  name: string
  text: string
  isPersonal: boolean
  setId: Dispatch<SetStateAction<number | null>>
  setName: Dispatch<SetStateAction<string>>
  setText: Dispatch<SetStateAction<string>>
  setIsPersonal: Dispatch<SetStateAction<boolean>>
  close: () => void
}
const contextData: ContextData = {
  id: null,
  name: "",
  text: "",
  isPersonal: false,
  setId: () => {
    /* placeholder for typescript */
  },
  setName: () => {
    /* placeholder for typescript */
  },
  setText: () => {
    /* placeholder for typescript */
  },
  setIsPersonal: () => {
    /* placeholder for typescript */
  },
  close: () => {
    /* placeholder for typescript */
  },
}
export const PromptContext = createContext(contextData)
