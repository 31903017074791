import { FC, useMemo, useState } from "react"
import { Stack, Menu, MenuItem, Button } from "@mui/material"
import { Table } from "../../shared/Table"
import { useGetProxyRegions, useDeleteProxyRegion } from "../../../api/admin/proxies"
import { proxyRegionColumns, createProxyRoleRow } from "../../../config/proxyRegionConfig"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreateModal } from "./CreateModal"
import { EditModal } from "./EditModal"

const createKey = "CREATE_PROXY_REGION"
const updateKey = "UPDATE_PROXY_REGION"

export const ZyteContainer: FC = () => {
  const { data: proxyRegionsData } = useGetProxyRegions()
  const openCreate = useOpenModalKey(createKey)
  const openUpdate = useOpenModalKey(updateKey)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selected, setSelected] = useState([])
  const clearSelected = () => setSelected([])
  const { mutate: deleteProxyRegions } = useDeleteProxyRegion(clearSelected)

  const selectedRegion = useMemo(() => {
    return proxyRegionsData?.find((region) => region.id === selected[0])
  }, [selected])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (open: () => void) => {
    handleClose()
    open()
  }

  return (
    <Stack>
      <Button size="large" variant="contained" onClick={handleClick} sx={{ width: 150, margin: 1 }}>
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleOpen(openCreate)}>Create</MenuItem>
        <MenuItem disabled={selected.length < 1} onClick={() => handleOpen(() => deleteProxyRegions(selected))}>
          Delete
        </MenuItem>
        <MenuItem disabled={selected.length !== 1} onClick={() => handleOpen(openUpdate)}>
          Update
        </MenuItem>
      </Menu>
      <Table
        data={proxyRegionsData as any[]}
        selected={selected}
        setSelected={setSelected}
        columns={proxyRegionColumns}
        createRow={createProxyRoleRow}
      />
      <CreateModal />
      {selectedRegion && <EditModal proxyRegion={selectedRegion} onSuccess={clearSelected} />}
    </Stack>
  )
}
