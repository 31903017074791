import { FC, useState } from "react"
import { Wizard } from "../Wizard"
import { WidgetSelectableContainer } from "./WidgetSelectableContainer"
import { WidgetContainer as CustomizeWidgetContainer } from "../customize_widget/WidgetContainer"
import { useAddWidgetToDashboard } from "../../../api/dashboards"
import { widgetSelectableDisplayConfig } from "../../../config/dashboardConfig"

export const WidgetContainer: FC<{
  open: boolean
  setClose: any
  dashboardId: string
}> = ({ open, setClose, dashboardId }) => {
  const { mutate: addWidgetToDashboard } = useAddWidgetToDashboard()

  const [widgetType, setWidgetType] = useState("")
  const [activeStep, setActiveStep] = useState(1)
  const [configuration, setConfiguration] = useState<any>({})

  const chooseWidget = (type: string) => {
    setWidgetType(type)
    setActiveStep(activeStep + 1)
  }

  const onSubmit = () => {
    addWidgetToDashboard({
      dashboardId: dashboardId,
      configuration: configuration,
      type: widgetSelectableDisplayConfig[widgetType].enum,
    })
    closeWidget()
  }

  const closeWidget = () => {
    setClose(false)
    setWidgetType("")
    setActiveStep(1)
    setConfiguration({})
  }

  const stepLabels = ["Add a Widget", "Customize Your Widget"]
  const steps = [
    <WidgetSelectableContainer setWidgetType={chooseWidget} key="add" />,
    <CustomizeWidgetContainer
      type={widgetType}
      configuration={configuration}
      setConfiguration={setConfiguration}
      key="customize"
    />,
  ]

  const disableSubmitConfig: any = {
    hoursAnalyzed: configuration.name && configuration.name.length > 0,
    topKeywords: configuration.name && configuration.name.length > 0,
    topChannels: configuration.name && configuration.name.length > 0,
    keywordsOverTime: configuration.name && configuration.name.length > 0 && configuration.search_terms?.length > 0,
    geographiesOverTime: configuration.name && configuration.name.length > 0 && configuration.search_terms?.length > 0,
  }

  return (
    <Wizard
      stepLabels={stepLabels}
      components={steps}
      open={open}
      onClose={closeWidget}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      disableSubmit={disableSubmitConfig[widgetType]}
      onSubmit={onSubmit}
    />
  )
}
