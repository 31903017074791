export enum SearchQueryParams {
  SearchTerms = "search-terms",
  MustIncludeTerms = "must-include-terms",
  FilterParams = "filter-params",
  DateRange = "date-range",
  CampaignId = "campaign-id",
  Proximity = "proximity",
  Exclude = "exclude",
  SortBy = "sort-by",
  Page = "page",
}

export interface SearchParams {
  searchTerms: string[]
  mustIncludeTerms: string[]
  base64FilterParams: string | null
  dateRange: number[]
  campaignId: string | null
  searchDateRange: [Date, Date]
  displayDateRange: [Date, Date]
  page: number
  sortBy: string
  proximity: number | null
  exclude: boolean
}

export enum SearchOptions {
  Standard = "standard",
  Proximity = "proximity",
}

export enum SearchTypes {
  Standard = "dashboard_search",
  Promotion = "meeting_search",
}
