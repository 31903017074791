import { FC } from "react"
import { ClipShareBookmarkView } from "../../../interfaces/clipShare"
import { Typography, IconButton, Stack, Avatar, Skeleton } from "@mui/material"
import { useNavigatorShareFormatBrowser } from "../../../config/clipShareConfig"
import EmailRoundedIcon from "@mui/icons-material/EmailRounded"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useParams } from "react-router-dom"

export const EmailButton: FC<{ clipShare: ClipShareBookmarkView | undefined }> = ({ clipShare }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const navigatorShareFormatBrowser = useNavigatorShareFormatBrowser(meetingId, true)

  const openEmail = () => {
    if (clipShare) {
      const body = navigatorShareFormatBrowser({
        shareId: clipShare.id,
        channelName: clipShare.organization_name,
        meetingTitle: clipShare.meeting.title,
        clipText: clipShare.raw_text,
      })
      window.open(`mailto:?subject=<<Your Text Here>>&body=${body}`)
    }
  }

  if (!clipShare) return <Skeleton variant="circular" width="60px" height="60px" />

  return (
    <IconButton
      sx={{
        borderRadius: 1,
        backgroundColor: "#F9F9F9",
        "&:active": {
          backgroundColor: "neutral.main",
        },
      }}
      size="large"
      disableRipple
      onClick={openEmail}
    >
      <Stack direction="column" alignItems="center">
        <Avatar
          aria-label="email"
          variant="circular"
          sx={{ backgroundColor: "#888888", height: "60px", width: "60px" }}
        >
          <EmailRoundedIcon fontSize="large" />
        </Avatar>
        <Typography variant="h5" sx={{ color: "black" }}>
          Email
        </Typography>
      </Stack>
    </IconButton>
  )
}
