import { useQuery } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"

const prefix = "/promotions"

export const useFetchMeeting = (meetingId: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [`meeting_${meetingId}`],
    async () => {
      const response = await (await axiosInstance).get(`${prefix}/meetings/${meetingId}`)

      return response.data
    },
    { retry: 0 },
  )
}

export const useFetchMeetingNoAuth = (meetingId: string) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery(
    ["meetings_for_lead", meetingId],
    async () => {
      const response = await (await axiosInstance).get(`${prefix}/meetings/${meetingId}/show_lead`)

      return response.data
    },
    { retry: 0 },
  )
}
