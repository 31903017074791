import { Grid } from "@mui/material"
import { FC } from "react"
import { SelectableWidgetWrapper } from "./SelectableWidgetWrapper"

export const WidgetSelectableContainer: FC<{ setWidgetType: any }> = ({ setWidgetType }) => {
  const widgetTypes = ["hoursAnalyzed", "keywordsOverTime", "topChannels", "geographiesOverTime"]

  return (
    <Grid container>
      {widgetTypes.map((type: any) => (
        <Grid item xs={12} md={4} padding={3} key={type} display="flex">
          <SelectableWidgetWrapper setWidgetType={setWidgetType} type={type} />
        </Grid>
      ))}
    </Grid>
  )
}
