import { useMutation } from "react-query"
import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"
import { PageOfOrigin } from "@src/interfaces/searchLogs"

const prefix = "/promotions/search_logs"

export interface CreateSearchLogData {
  search_terms?: string[]
  industry_id: number
}

export interface CreateSearchLogParams {
  data: CreateSearchLogData
  meeting_id?: number
  page_of_origin: PageOfOrigin
}

export const useCreateSearchLogs = () => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useMutation(async (data: CreateSearchLogParams) => {
    const response = await (await axiosInstance).post(`${prefix}`, data)

    return response.data
  })
}
