import { FC, useEffect } from "react"
import { TextField, Autocomplete } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject, OpportunityQueryObjectWithCampaign } from "@api/opportunities"
import { useFetchOpportunityCampaignsMap } from "@api/opportunityCampaigns"
import useSetUrlParamState from "../../shared/UseSetUrlParamState"
import { SaveCampaignButton } from "./SaveCampaignButton"
import { UpdateCampaignButton } from "./UpdateCampaignButton"

export const CampaignFields: FC = () => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const campaignIdString = params.get("campaign")
  const campaignId = campaignIdString ? parseInt(campaignIdString, 10) : undefined

  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const setUrlParamState = useSetUrlParamState<OpportunityQueryObjectWithCampaign>()

  const { data: opportunityCampaigns, isFetched: isOpportunityCampaignsFetched } = useFetchOpportunityCampaignsMap()

  useEffect(() => {
    if (campaignId && isOpportunityCampaignsFetched) {
      setUrlParamState({
        ...urlParamState,
        ...(opportunityCampaigns?.get(campaignId)?.data || {}),
        campaign: `${campaignId}`,
        page: 0,
      })
    }
  }, [campaignId, isOpportunityCampaignsFetched])

  return (
    <>
      <Autocomplete
        sx={{ flex: "1" }}
        options={opportunityCampaigns ? Array.from(opportunityCampaigns.keys()) : []}
        value={campaignId}
        onChange={(_, newValue) => {
          if (newValue && newValue > 0) {
            setUrlParamState({
              ...urlParamState,
              campaign: `${newValue}`,
              page: 0,
            })
          }
        }}
        renderInput={(params) => <TextField {...params} label="Opportunity Campaigns" />}
        getOptionLabel={(id) => opportunityCampaigns?.get(id)?.name || ""}
      />
      <UpdateCampaignButton />
      <SaveCampaignButton />
    </>
  )
}
