import { useQueryClient, useMutation } from "react-query"
import { ClipShare } from "../../interfaces/clipShare"
import { User } from "../../interfaces/user"
import { useAuthAxios } from "../axiosInstance"
import { FetchedClipShare } from "../bookmarks"

export const useDeleteClipShare = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (clips: ClipShare[] | FetchedClipShare[]) => {
      const bookmarkId = clips[0].bookmark_id
      const clipIds = clips.map((clip) => clip.id)
      return (await axiosInstance).delete(`/bookmarks/${bookmarkId}/clip_shares/${clipIds.join(",")}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
      },
    },
  )
}

export const useCreateClipShare = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(`/bookmarks/${params.bookmarkId}/clip_shares`, {
        title: params.optionalTitle,
        meeting_id: params.meetingId,
        start_time_in_seconds: params.startTimeInSeconds,
        transcript_id: params.transcriptId,
        keyword: params.keyword,
        description: params.description,
        tagged_users: params.taggedUsers.map((user: User) => user.id),
        clip_type: params.clip_type,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
      },
    },
  )
}
