import { useHistory } from "react-router-dom"

function useSetUrlParamState<UrlParamObject extends Record<string, unknown>>() {
  const history = useHistory()
  return (newFilterParams: UrlParamObject) => {
    const newParams = new URLSearchParams()
    Object.entries(newFilterParams).forEach(([key, value]) => {
      if (value) {
        // values that evaluate to false shouldn't be added
        const valueCollection = !Array.isArray(value) ? [value] : value
        valueCollection.forEach((id) => newParams.append(key, id.toString()))
      }
    })

    history.push({ search: newParams.toString() })
  }
}

export default useSetUrlParamState
