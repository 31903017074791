import { FC, useContext, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { ClipShare } from "../components/Promo/share/ClipShare"
import { MeetingShare } from "../components/Promo/share/MeetingShare"
import { useFetchClipShare } from "../api/clip_shares"
import { useIsPaidUser } from "../api/users"
import { useFetchClipShare as useFetchPromotionalClipShare } from "../api/promotions/clip_shares"
import { PromoContext } from "../App"
import { redirectIfPaidUser } from "../components/Promo/utilities"
import { Box } from "@mui/material"

export const SharePage: FC = () => {
  const { id } = useParams<{ id: string }>()
  const params = new URLSearchParams(useLocation().search)
  const type = params.get("type")
  const searchTermsParam = params.get("searchTerms")
  let searchTerms = null
  if (searchTermsParam) {
    searchTerms = searchTermsParam.split(",")
  }
  const isPromo = useContext(PromoContext)
  const { isError, isLoading, data } = useFetchClipShare(parseInt(id), isPromo)
  const {
    data: promoData,
    isLoading: promoLoading,
    isError: promoIsError,
  } = useFetchPromotionalClipShare(parseInt(id), isPromo)
  const { data: isPaidUser } = useIsPaidUser()

  useEffect(() => redirectIfPaidUser(params, isPromo ? promoData : data, isPaidUser), [isPaidUser, data, promoData])

  return (
    <Box>
      {type === "clip" || !isError ? (
        <ClipShare
          clipShareData={isPromo ? promoData : data}
          isLoading={isPromo ? promoLoading : isLoading}
          isError={isPromo ? promoIsError : isError}
        />
      ) : (
        <MeetingShare meetingId={id} searchTerms={searchTerms} />
      )}
    </Box>
  )
}
