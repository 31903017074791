import { useMutation } from "react-query"
import { useAuthAxios } from "../axiosInstance"

let prefix = "minnie/admin/opportunities"

if (!process.env.REACT_APP_GOOGLE_ENV) {
  const searchHostUrl = process.env.REACT_APP_SEARCH_API_URL || "http://localhost:8000"
  prefix = `${searchHostUrl}${prefix}`
}

export const useAnalyzeOpportunities = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { id: number | number[] }) => {
      return (await axiosInstance).post(`${prefix}/analyze`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
