import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useValidateGeographies } from "../../../api/geographies"
import Loading from "../../shared/Loading"

export const ValidateGeographies: FC = () => {
  const [time, setTime] = useState(0)
  const { data, isLoading } = useValidateGeographies(time)
  const showValidationResults = () => {
    return (
      <>
        <Typography>
          <strong>File Errors: {data.file_errors.length}</strong>
        </Typography>
        {data.file_errors.map((row: any, index: number) => (
          <Typography key={index} marginLeft={2}>
            {row}
          </Typography>
        ))}
        <br />
        <Typography>
          <strong>Invalid Counties: {data.county_errors.length}</strong>
        </Typography>
        {data.county_errors.map((row: any, index: number) => (
          <Typography key={index} marginLeft={2}>
            {row.error}&nbsp;
            {row.link ? (
              <a href={row.link} target="_blank" rel="noreferrer">
                Channel Link
              </a>
            ) : (
              "No organization found."
            )}
          </Typography>
        ))}
        <br />
        <Typography>
          <strong>Invalid Cities: {data.city_errors.length}</strong>
        </Typography>
        {data.city_errors.map((row: any, index: number) => (
          <Typography key={index} marginLeft={2}>
            {row.error}&nbsp;
            {row.link ? (
              <a href={row.link} target="_blank" rel="noreferrer">
                Channel Link
              </a>
            ) : (
              "No organization found."
            )}
          </Typography>
        ))}
      </>
    )
  }

  return (
    <Grid container direction="column">
      <Grid item paddingTop={2} paddingLeft={2}>
        <h6>Validation will take a few moments to process</h6>
        <Button onClick={() => setTime(Date.now())} variant="contained" disabled={data}>
          Validate
        </Button>
      </Grid>
      <Grid item padding={5}>
        {isLoading && <Loading />}
        {data && showValidationResults()}
      </Grid>
    </Grid>
  )
}
