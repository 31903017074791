import { FC, createContext, useMemo, useEffect, useState, useContext } from "react"
import { Stack } from "@mui/material"
import { useMeetingSearch } from "../../../api/search"
import { ShareDisplay } from "./ShareDisplay"
import { getWordTime } from "../../../functions/transcript-words-array"
import { useLocation } from "react-router-dom"
import { ClipShareBookmarkView } from "../../../interfaces/clipShare"
import { PromoContext } from "../../../App"

export const ClipShareContext = createContext({ description: "", activeTerm: "" })

export const ClipShare: FC<{ clipShareData?: ClipShareBookmarkView; isLoading: boolean; isError: boolean }> = ({
  clipShareData,
  isLoading,
  isError,
}) => {
  const params = new URLSearchParams(useLocation().search)
  const [searchParams, setSearchParams] = useState<string[][]>([])
  const isPromo = useContext(PromoContext)
  const { data: transcriptHits, isLoading: transcriptsLoading } = useMeetingSearch(
    searchParams,
    clipShareData?.meeting?.id.toString(),
    isPromo,
  )
  const transcriptId = params.get("transcript_id")

  const activeTerm = useMemo(() => {
    if (clipShareData && clipShareData.keyword && transcriptHits) {
      return transcriptHits.terms.find(({ term }) => term === clipShareData.keyword)
    }
  }, [clipShareData, transcriptHits])

  const startTime = useMemo(() => {
    if (clipShareData && activeTerm && transcriptId) {
      const transcriptHit = activeTerm.transcripts.find((transcript) => {
        if (typeof transcriptId === "number") {
          return transcript.id === parseInt(transcriptId)
        }
        return transcript.id === transcriptId
      })

      if (transcriptHit) {
        return getWordTime(clipShareData.keyword, transcriptHit) - 5
      }
    }
  }, [clipShareData, transcriptId, activeTerm])

  useEffect(() => {
    if (clipShareData?.keyword) {
      const otherParams: string[][] = []
      params.forEach((value, key) => {
        if (key !== "terms" && value !== clipShareData.keyword) {
          otherParams.push([key, value])
        }
      })
      setSearchParams([["terms", clipShareData.keyword], ...otherParams])
    }
  }, [clipShareData?.keyword])

  return (
    <Stack width="100%" alignItems="center">
      <ClipShareContext.Provider
        value={{
          description: clipShareData?.description || "",
          activeTerm: clipShareData?.keyword || "",
        }}
      >
        <ShareDisplay
          isError={isError}
          isLoading={isLoading}
          transcripts={transcriptHits ?? { terms: [], total_hits: 0 }}
          transcriptsLoading={transcriptsLoading}
          meeting={clipShareData?.meeting}
          organization={clipShareData?.organization}
          searchTerms={clipShareData ? [clipShareData.keyword] : []}
          startTime={startTime}
        />
      </ClipShareContext.Provider>
    </Stack>
  )
}
