import { FC, useEffect } from "react"
import { CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material"
import { useFetchDailyDigestPreview } from "../../../api/admin/users"
import { getToken } from "../../../auth/auth0Token"

export const DailyDigestPreview: FC<{
  user: any
  timeValue: any
}> = ({ user, timeValue }) => {
  let token = ""
  getToken().then((response) => (token = response))

  const { fetchRequest, response, loading } = useFetchDailyDigestPreview(user?.id, timeValue)

  useEffect(() => {
    if (token.length > 0) {
      fetchRequest(token)
    }
  }, [])

  if (loading) {
    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <CircularProgress />
        {response.eventName === "status" && <Typography>{response.data}</Typography>}
      </Stack>
    )
  }

  if (response.eventName === "response" && response.data?.campaigns_with_meetings?.length === 0) {
    return (
      <Grid container>
        <Grid item>
          <Typography variant="h6">No results, go further back.</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Preview:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {response.data?.campaigns_with_meetings?.map((campaignMeeting: any) => (
            <Grid container spacing={1} item key={campaignMeeting.campaign.id} xs={12}>
              <Grid container direction="column">
                <Typography variant="h6">{campaignMeeting.campaign.name}</Typography>
                <Typography>{`Total Hits: ${campaignMeeting.campaign.totalHits}`}</Typography>
              </Grid>
              <Grid item container direction="column" justifyContent="flex-end">
                {campaignMeeting.meetings.map((meeting: any) => (
                  <Grid container direction="column" alignItems="flex-end" marginBottom={2} key={meeting.id}>
                    <Typography variant="body1">{meeting.title}</Typography>
                    <Typography variant="body2">{meeting.searchResults ? "Search Results" : "Briefing"}</Typography>
                    {meeting.searchResults?.map((result: any) => (
                      <Typography key={`${result.word}-${result.count}`}>
                        {`${result.word} - ${result.count}`}
                      </Typography>
                    ))}
                    {meeting.briefing && <Typography variant="body2">{meeting.briefing.slice(0, 50)}</Typography>}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} marginY={1}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
