import { Dispatch, FC, Fragment, SetStateAction, useContext } from "react"
import { GeneralScraperDetailsContext, OrganizationFormContext, newArchiveDetails } from "./Context"
import { unpackSetters } from "../../../shared/unpackSetters"
import {
  TextField,
  Checkbox,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Typography,
} from "@mui/material"
import { ActionCard } from "../Wizard/ActionCard"
import { IdentifierActionSelection } from "../Wizard/IdentifierActionSelection"
import { AdditionalUrl } from "../Wizard/VerifyUrl"
import { Divider as WizardDivider } from "../Wizard/Divider"
import { GeneralScraperDetails } from "../../../../api/interfaces"

export const VideoArchiveDetails: FC = () => {
  const { value, setValue } = useContext(GeneralScraperDetailsContext)
  const { value: orgValue, setValue: setOrgValue } = useContext(OrganizationFormContext)

  if (typeof value === "string" || value === undefined || value === null) {
    setValue(newArchiveDetails)
    return null
  }

  const {
    actions,
    navigation_method,
    additional_urls,
    meeting_identifier,
    meeting_identifier_regex,
    iframe,
    head,
    identifier_actions,
    meeting_browser_wait_code,
    json_code,
  } = value

  const { videoArchiveId } = orgValue
  const { setVideoArchiveId } = unpackSetters(orgValue, setOrgValue)

  const {
    setActions,
    setNavigation_method,
    setAdditional_urls,
    setMeeting_identifier,
    setMeeting_identifier_regex,
    setIframe,
    setHead,
    setMeeting_browser_wait_code,
    setJson_code,
  } = unpackSetters(value, setValue as Dispatch<SetStateAction<GeneralScraperDetails>>)

  const addAction = (index: number) => () =>
    setActions((prevActions) => {
      const newActions = [...prevActions]
      newActions.splice(index, 0, {
        type: "URI_NAVIGATE",
        identifier: "",
        index: 0,
        regex: false,
      })
      return newActions
    })

  return (
    <>
      <Stack direction="row" spacing={1}>
        <TextField
          size="small"
          label="Channel Id"
          value={videoArchiveId}
          onChange={(event) => setVideoArchiveId(event.target.value)}
          fullWidth
        />
        <FormControl sx={{ alignSelf: "flex-start", minWidth: "20ch" }}>
          <InputLabel id="demo-simple-select-label">Navigation Method</InputLabel>
          <Select
            value={navigation_method}
            label="Navigation Method"
            onChange={(event) => setNavigation_method(event.target.value as "uri" | "selenium" | "zyte" | "json")}
            size="small"
          >
            <MenuItem value="uri">URI</MenuItem>
            <MenuItem value="selenium">Selenium</MenuItem>
            <MenuItem value="zyte-selenium">Zyte Selenium</MenuItem>
            <MenuItem value="zyte">Zyte</MenuItem>
            <MenuItem value="json">JSON</MenuItem>
          </Select>
        </FormControl>
        {navigation_method === "json" && (
          <FormControl sx={{ alignSelf: "flex-start", minWidth: "50ch" }}>
            <TextField
              size="small"
              onChange={(event) => setJson_code(event.target.value)}
              value={json_code}
              label="Ruby strftime format"
            ></TextField>
            <FormHelperText>%Y-%m-%d</FormHelperText>
          </FormControl>
        )}
      </Stack>
      <WizardDivider onClick={() => setAdditional_urls((prevValue) => ["", ...prevValue])} />
      {(additional_urls || []).map((url, index) => (
        <AdditionalUrl key={index} url={url} index={index} setAdditionalUrls={setAdditional_urls} />
      ))}
      <Stack direction="row" spacing={1}>
        <TextField
          label="Meeting Identifier"
          size="small"
          value={meeting_identifier}
          onChange={(event) => setMeeting_identifier(event.target.value)}
          sx={{ flex: 1 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={meeting_identifier_regex}
              onChange={(event) => setMeeting_identifier_regex(event.target.checked)}
            />
          }
          label="Regex"
        />
        <FormControlLabel
          control={<Checkbox checked={iframe} onChange={(event) => setIframe(event.target.checked)} />}
          label="Iframe"
        />
        <FormControlLabel
          control={<Checkbox checked={head} onChange={(event) => setHead(event.target.checked)} />}
          label="Use Head"
        />
      </Stack>
      <Stack direction="row">
        <TextField
          label="Meeting Browser Wait Code"
          size="small"
          value={meeting_browser_wait_code}
          onChange={(event) => setMeeting_browser_wait_code(event.target.value)}
          sx={{ flex: 1, marginTop: 2 }}
        />
      </Stack>
      <Stack marginTop={3} spacing={2}>
        <Typography variant="h2">Inital Actions</Typography>
        {identifier_actions?.map((_action, index) => <IdentifierActionSelection index={index} key={index} />)}
      </Stack>
      <Stack>
        {actions.map((action, index) => (
          <Fragment key={`${action.type}${index}`}>
            <WizardDivider onClick={addAction(index)} />
            <ActionCard
              scraperAction={action}
              index={index}
              last={index === actions.length - 1}
              setScraperActions={setActions}
            />
          </Fragment>
        ))}
        <WizardDivider onClick={addAction(actions.length)} />
      </Stack>
    </>
  )
}
