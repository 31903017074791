import { Stack } from "@mui/material"
import { createContext, FC, useEffect, useState } from "react"
import { SignalsTestForm } from "@src/components/Admin/Uat/SignalsUat/SignalsTestForm"
import { AllowedUsers } from "@src/components/Admin/Uat/SignalsUat/AllowedUsers"
import { SignalsUatAllowedUsers, useFetchSignalsUatAllowedUsers } from "@src/api/admin/users"
import { ServerEvent } from "@src/api/interfaces"
import { SignalsOutputs } from "@src/components/Admin/Uat/SignalsUat/SignalsOutputs"
import { useFetchSignalsProgress } from "@src/api/admin/uat"
import moment from "moment"

export interface SignalsContainerProps {
  daysBack: number
  setDaysBack: (value: number) => void
  allowedUsers: SignalsUatAllowedUsers[]
  selectedUsersType: string
  setSelectedUsersType: (value: string) => void
  signalsProgressData: ServerEvent[]
  setSignalsProgressData: (value: ServerEvent[]) => void
  isProcessLoading: boolean
  setIsProcessLoading: (value: boolean) => void
  killFetch: boolean
  setKillFetch: (value: boolean) => void
}

export const SignalsContainerContext = createContext<SignalsContainerProps>({
  daysBack: 1,
  setDaysBack: () => {
    /* Empty for linter */
  },
  allowedUsers: [],
  selectedUsersType: "allowed",
  setSelectedUsersType: () => {
    /* Empty for linter */
  },
  signalsProgressData: [{ eventName: "", data: {} } as ServerEvent],
  setSignalsProgressData: () => {
    /* Empty for linter */
  },
  isProcessLoading: false,
  setIsProcessLoading: () => {
    /* Empty for linter */
  },
  killFetch: false,
  setKillFetch: () => {
    /* Empty for linter */
  },
})

export const SignalsUatContainer: FC = () => {
  const [daysBack, setDaysBack] = useState<number>(1)
  const [selectedUsersType, setSelectedUsersType] = useState<string>("allowed")
  const { data: allowedUsers } = useFetchSignalsUatAllowedUsers()
  const [signalsProgressData, setSignalsProgressData] = useState<ServerEvent[]>([{ eventName: "", data: null }])
  const [isProcessLoading, setIsProcessLoading] = useState<boolean>(false)
  const [killFetch, setKillFetch] = useState<boolean>(false)

  const { mutate: fetchSignalsProgress, data: fetchSignalsProgressData } = useFetchSignalsProgress()

  const contextObject = {
    daysBack,
    setDaysBack,
    allowedUsers: allowedUsers || [],
    selectedUsersType,
    setSelectedUsersType,
    signalsProgressData,
    setSignalsProgressData,
    isProcessLoading,
    setIsProcessLoading,
    killFetch,
    setKillFetch,
  }

  // useEffect that will fetch the signals progress initially and then
  // every 30 seconds when isProcessLoading is true
  useEffect(() => {
    console.log("in useEffect")

    if (isProcessLoading) {
      // initial fetch to get the logs going faster since the timer
      // waits 30 seconds before calling
      setTimeout(() => fetchSignalsProgress(), 3000)
      const startTime = moment()

      const timerInterval = setInterval(() => {
        if (killFetch || moment().diff(startTime, "minutes") > 30) {
          setIsProcessLoading(false)
          clearInterval(timerInterval)
        }

        fetchSignalsProgress()
      }, 30000)
      return () => clearInterval(timerInterval)
    }
  }, [isProcessLoading, killFetch])

  // useEffect whose purpose is to check if the fetchSignalsProgressData has data
  // and update the signalsProgressData state with the new data when applicable
  useEffect(() => {
    if (fetchSignalsProgressData && fetchSignalsProgressData.data && fetchSignalsProgressData.data.length > 0) {
      const newSignalsData = [] as ServerEvent[]
      const timeFormat = "hh:mm:ss A"

      fetchSignalsProgressData.data.forEach((signal) => {
        const time = moment(signal.date_time).format(timeFormat) || moment().format(timeFormat)
        const serverEvent = { eventName: signal.event_type, data: signal.data, time } as ServerEvent
        newSignalsData.push(serverEvent)

        if (signal.event_type === "error" || signal.event_type === "response") {
          setKillFetch(true)
          setIsProcessLoading(false)
        }
      })
      setSignalsProgressData([...newSignalsData])
    }
  }, [fetchSignalsProgressData])

  return (
    <SignalsContainerContext.Provider value={contextObject}>
      <Stack padding={1} spacing={3} direction="row">
        <SignalsTestForm />
        <AllowedUsers />
      </Stack>
      <SignalsOutputs />
    </SignalsContainerContext.Provider>
  )
}
