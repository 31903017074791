import { useMutation } from "react-query"
import { RubyObject } from "../interfaces/default"
import { useAuthAxios } from "./axiosInstance"

export interface UserCampaignNotification extends RubyObject {
  user_id: number
  campaign_id: number
  email_cadence: "Unsubscribed" | "Weekly" | "Daily"
}

export interface UserCampaignNotificationUpdateParams {
  user_campaign_notification_id: number
  email_cadence: string
  automated_general_briefings: boolean
  briefings: number[]
}

const adminPrefix = "/admin/user_campaign_notifications"

export const useUpdateUserCampaignNotification = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: UserCampaignNotificationUpdateParams) => {
      return (await axiosInstance).put(`${adminPrefix}/${params.user_campaign_notification_id}`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
