import {
  Box,
  Stack,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Chip,
  Typography,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { SearchFormInput } from "./SearchFormInput"
import { SearchFormTerms } from "./SearchFormTerms"
import { SearchFormButton } from "./SearchFormButton"
import moment from "moment"
import DateRangeIcon from "@mui/icons-material/DateRange"

export const SearchContainer: FC<{
  searchTerms: Set<string>
  setSearchTerms: Dispatch<SetStateAction<Set<string>>>
  updateSearchParams: () => void
  channelType: string
  setChannelType: Dispatch<React.SetStateAction<string>>
}> = ({ searchTerms, setSearchTerms, updateSearchParams, channelType, setChannelType }) => {
  const [input, setInput] = useState("")

  const changeChannelType = (event: SelectChangeEvent) => {
    setChannelType(event.target.value as string)
  }
  const theme = useTheme()
  const pageIsSmall = useMediaQuery(theme.breakpoints.down("md"))
  const addTerm = () => {
    if (searchTerms.size < 3) {
      searchTerms.add(input)
      setInput("")
    }
  }

  const formatDateRange = `${moment().subtract(7, "d").format("MM/DD/YY")} - ${moment().format("MM/DD/YY")}`

  return (
    <Box
      sx={{
        width: "100%",
        height: 250,
        bgcolor: "neutral.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: 7, sm: 4 },
      }}
    >
      <Stack>
        <Stack direction={pageIsSmall ? "column" : "row"} spacing={4} marginBottom={1}>
          <Grid
            container
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              backgroundColor: "white",
              borderRadius: 10,
              paddingBottom: 1,
              width: { xs: "45ch", sm: "54ch", md: "86ch" },
              marginLeft: { xs: 2, sm: 0 },
            }}
          >
            <Grid item xs={8} md={10}>
              <SearchFormInput input={input} setInput={setInput} addTerm={addTerm} />
            </Grid>
            <Grid item xs={4} md={2}>
              {searchTerms.size < 3 && <SearchFormButton addTerm={addTerm} show={Boolean(input)} />}
            </Grid>
          </Grid>
          <Button
            onClick={updateSearchParams}
            variant="contained"
            type="button"
            size="large"
            sx={{ minWidth: "15ch", maxHeight: "15ch", backgroundColor: "#4CB2DC", marginBottom: 1 }}
            disabled={searchTerms.size === 0}
          >
            Search
          </Button>
        </Stack>
        <Grid container>
          <Grid container item sm={12} md={10} alignItems={"center"}>
            <Grid item xs={6} md={8}>
              {searchTerms.size > 2 && input.length !== 0 && (
                <Typography sx={{ color: "red", paddingBottom: 1 }} variant="body2">
                  Only three terms allowed.
                </Typography>
              )}
              <SearchFormTerms terms={searchTerms} setTerms={setSearchTerms} />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth size="small" sx={{ paddingRight: 2, marginTop: 1 }}>
                <InputLabel id="channel-simple-select-label">Channel Type</InputLabel>
                <Select
                  labelId="channel-type-label"
                  id="channel-type-select"
                  label="Channel Type"
                  value={channelType}
                  onChange={changeChannelType}
                  sx={{ boxShadow: "none", ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
                >
                  <MenuItem value={4}>All</MenuItem>
                  <MenuItem value={1}>Local</MenuItem>
                  <MenuItem value={2}>State</MenuItem>
                  <MenuItem value={3}>Federal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h6" marginTop={1}>
            <Chip icon={<DateRangeIcon />} color="default" label={formatDateRange} />
          </Typography>
        </Grid>
      </Stack>
    </Box>
  )
}
