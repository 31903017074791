import { useMutation, useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"

export const useReindexAllMeetings: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/reindex_all_meetings")
  })
}

export const useTrainSpamModel: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: any) => {
    const formData = new FormData()

    formData.append("train_file", params.train_file)

    formData.append("test_file", params.test_file)

    return (await axiosInstance).post("/admin/settings/train_spam", formData)
  })
}

export const useTrainSimilarWords: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params) => {
    return (await axiosInstance).post("/admin/settings/train_similar_words", params)
  })
}

export const useGeneratePdfs: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/generate_all_pdfs")
  })
}

export const useDeleteOrphanedMeetingsInit: any = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["orphaned_meetings"], async () => {
    const response = await (await axiosInstance).get("/admin/settings/delete_orphaned_meetings_init")

    return response.data
  })
}

export const useDeleteOrphanedMeetingsConfirm: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/delete_orphaned_meetings_confirm")
  })
}

export const useTrefoliumMeetingInformation: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).get("/admin/settings/trefolium_meeting_information")
  })
}

export const useDetectSpamForAllMeetings: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/detect_spam_all_meetings")
  })
}

export const useCalculateMinhashAllMeetings: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/calculate_min_hash_all_meetings")
  })
}

export const useCalculateMissingMeetingMetadata: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/calculate_missing_meeting_metadata")
  })
}

export const useCalculateMostUsedWords: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/calculate_most_used_words")
  })
}

export const useDeleteSpamTranscripts: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/delete_spam_transcripts")
  })
}

export const useUpdateCityCountyData: any = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post("/admin/settings/update_city_county_data")
  })
}

export const useUpdateYtOverride = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (settings: { override: string; proxy: boolean; shuffleRegion: boolean }) => {
    return (await axiosInstance).post("/admin/settings/update_yt_override", {
      override: settings.override,
      proxy: settings.proxy,
      shuffle_region: settings.shuffleRegion,
    })
  })
}
