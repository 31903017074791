import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import { FC, useContext, useEffect, useMemo, useState } from "react"
import { useCloseModalKey, useModalKey } from "../shared/OpenModalHook"
import { UserContext } from "../../pages/UsersPage"
import { useFetchUsers, useUpdateCreditLimit } from "../../api/users"
import { useToast } from "../shared/ToastHook"

export const updateCreditLimitModalKey = "UPDATE_CREDIT_LIMIT_MODAL"

export const UpdateCreditLimit: FC = () => {
  const open = useModalKey(updateCreditLimitModalKey)
  const closeModal = useCloseModalKey(updateCreditLimitModalKey)
  const usersContext = useContext(UserContext)
  const setToast = useToast()

  const { data: users } = useFetchUsers()
  const selectedUsers = useMemo(() => {
    return users?.filter((user) => usersContext.ids.includes(user.id))
  }, [users, usersContext.ids])
  const { mutate: updateCreditLimit, isSuccess, isError } = useUpdateCreditLimit()
  const [creditLimit, setCreditLimit] = useState<number>(0)

  useEffect(() => {
    if (selectedUsers?.length === 1 && open) {
      setCreditLimit(selectedUsers[0].credit_limit)
    } else {
      setCreditLimit(0)
    }
  }, [selectedUsers, open])

  const onConfirmation = () => {
    updateCreditLimit({ ids: usersContext.ids, credit_limit: creditLimit })
    onClose()
  }

  const onClose = () => {
    closeModal()
    setCreditLimit(0)
  }

  useEffect(() => {
    if (isSuccess) {
      setToast("Credit Limit Updated Successfully", 3000, <CheckCircleIcon />)
    }
    if (isError) {
      setToast("Failed to update credit limit", 5000, <ErrorIcon />, "error")
    }
  }, [isSuccess, isError])

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Update Credit Limit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will affect the following users:
          <List>
            {selectedUsers?.map((user) => (
              <ListItem key={user.id}>
                <ListItemText primary={user.email} />
              </ListItem>
            ))}
          </List>
        </DialogContentText>
        <Stack direction="row" padding={1}>
          <TextField
            value={creditLimit}
            type="number"
            label="Credit Limit"
            inputProps={{ min: 0 }}
            onChange={(e) => setCreditLimit(parseFloat(e.target.value))}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirmation}>Confirm</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
