export const disabledButtonStyle = {
  color: "grey",
  borderColor: "grey !important",
  display: {
    xs: "none",
    md: "flex",
  },
}

export const enabledButtonStyle = {
  borderColor: "#566F88 !important",
  borderWidth: 2,
  display: { xs: "none", md: "flex" },
}
