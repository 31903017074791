import { FC, useState } from "react"
import { Grid, TextField, Button, Chip, FormControlLabel, Switch } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { theme } from "../../../theme"

export const CreateFolderEditor: FC<{
  showCreateFolder: boolean
  createFolder: (title: string, visible: boolean) => void
  setShowCreateFolder: (show: boolean) => void
}> = ({ showCreateFolder, createFolder, setShowCreateFolder }) => {
  const [visible, setVisibility] = useState(false)
  const [title, setTitle] = useState("")

  return (
    <Grid container paddingLeft={3} paddingBottom={2}>
      {showCreateFolder ? (
        <Grid container item direction="row">
          <Grid item xs={12} paddingRight={3}>
            <TextField
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              label="Folder Title"
              fullWidth
              InputLabelProps={{ style: { color: theme.palette.primary.main, fontWeight: 500 } }}
              sx={{ paddingBottom: 1 }}
            />
          </Grid>
          <Grid item xs={12} display="flex">
            <FormControlLabel
              control={<Switch checked={visible} onChange={() => setVisibility(!visible)} />}
              label={visible ? "Public " : "Private"}
              componentsProps={{ typography: { variant: "body1" } }}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontWeight: 500,
                  width: "100%",
                },
                paddingLeft: 1,
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button size="medium" color="primary" variant="contained" onClick={() => createFolder(title, visible)}>
              Create
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Chip
          icon={<AddIcon />}
          color="primary"
          sx={{ backgroundColor: "white", color: "primary.main" }}
          label="CREATE NEW FOLDER"
          variant="outlined"
          onClick={() => setShowCreateFolder(true)}
        />
      )}
    </Grid>
  )
}
