import { Typography } from "@mui/material"
import { FC, useContext, useEffect, useMemo } from "react"
import { TranscriptWord } from "../../../../interfaces/transcript"
import { Context } from "../../Shared/Context"

const highlightLead = 4
const secondsMargin = 1
const nearEndMargin = 5

const toNumber = (
  number:
    | number
    | {
        seconds: number
        nanos: number
      },
) => {
  if (typeof number === "number") {
    return number
  } else {
    return number.seconds + number.nanos / 1000000000
  }
}

export const HighlightedText: FC<{
  words?: TranscriptWord[]
  searchTerm: string
  ellipsis: boolean
  onNearEnd?: () => void
}> = ({ words, searchTerm, ellipsis, onNearEnd }) => {
  // progress[0] = previous progress, progress[1] = current progress
  const { state } = useContext(Context)
  const { progress } = state
  let highlight = false
  let highlightCount = 0
  let searchTermHighlightCount = 0

  // merge words in the array that have a special character with the previous word ["check", "-in"] to ["check-in"]
  const cleansedWords = useMemo(() => {
    const regex = new RegExp("^[\\W_].", "gi")
    const cleansedWords: TranscriptWord[] = []
    words?.forEach((word, index) => {
      const wordCopy = { ...word }
      if (wordCopy?.word.match(regex) && index !== 0) {
        cleansedWords[cleansedWords.length - 1].word = `${cleansedWords[cleansedWords.length - 1]?.word}${word.word}`
      } else {
        cleansedWords.push(wordCopy)
      }
    })

    return cleansedWords
  }, [words])

  const searchTermHits = useMemo(() => {
    const hitsHash: { [wordIndex: number]: boolean[] } = {}
    const termArray = searchTerm.split(" ")

    cleansedWords?.forEach((_word, wordIndex) => {
      termArray.forEach((term, termIndex) => {
        if (
          term.localeCompare(cleansedWords[wordIndex + termIndex]?.word, "en", {
            sensitivity: "base",
            ignorePunctuation: true,
          }) === 0
        ) {
          if (Array.isArray(hitsHash[wordIndex])) {
            hitsHash[wordIndex].push(true)
          } else {
            hitsHash[wordIndex] = [true]
          }
        }
      })
    })
    for (const [key, value] of Object.entries(hitsHash)) {
      if (value.length !== termArray.length) {
        delete hitsHash[parseInt(key)]
      }
    }

    return hitsHash
  }, [cleansedWords, searchTerm])

  useEffect(() => {
    if (!onNearEnd || !state.playingVideo || !(cleansedWords.length > 0)) {
      return
    }

    const lastWord = cleansedWords[cleansedWords.length - 1]
    if (toNumber(lastWord.start_time) - progress[1] <= nearEndMargin && onNearEnd) {
      onNearEnd()
    }
  }, [progress[1], cleansedWords])

  return (
    <div style={{ overflowWrap: "break-word" }}>
      {ellipsis && "..."}
      {cleansedWords?.map((word, index) => {
        // highlight search terms
        if (searchTermHits[index] || searchTermHighlightCount > 0) {
          searchTermHighlightCount = searchTermHighlightCount + 1

          if (searchTermHighlightCount === searchTerm.split(" ").length) {
            searchTermHighlightCount = 0
          }
          return (
            <Typography
              variant="body1"
              key={`${word.word}${index}`}
              sx={{
                backgroundColor: "rgb(255,249,230)",
                fontWeight: 700,
                display: "inline-block",
              }}
            >
              {word.word}
              &nbsp;
            </Typography>
          )
        }

        // begin highlight at time
        if (
          ((toNumber(word.start_time) >= progress[0] && toNumber(word.start_time) <= progress[1] + secondsMargin) ||
            highlight) &&
          progress[1] !== 0 &&
          progress[0] !== 0
        ) {
          highlightCount = highlightCount + 1
          //end of transcript
          if (index === cleansedWords.length - 1) {
            highlight = false
          } else if (highlightCount < highlightLead) {
            highlight = true
            // end of leading highlight
            if (highlightCount === highlightLead) {
              highlight = false
            }
          } else {
            highlight = false
          }

          return (
            <Typography
              variant="body1"
              key={`${word.word}${index}`}
              sx={{
                backgroundColor: "rgb(255,249,230)",
                fontWeight: 400,
                display: "inline-block",
                transition: "backgroung-color .5s ease",
              }}
            >
              {word.word}
              &nbsp;
            </Typography>
          )
        } else {
          return (
            <Typography key={`${word.word}${index}`} variant="body1" sx={{ display: "inline-block" }}>
              {`${word.word}`}
              &nbsp;
            </Typography>
          )
        }
      })}
      {ellipsis && "..."}
    </div>
  )
}
