import { FC, useState, useEffect } from "react"
import {
  BaseTranscriptionJob,
  useFetchTranscriptionJob,
  useFetchTranscriptionJobByMeeting,
  useUpdateTranscriptionJob,
} from "../../../api/admin/transcription_jobs"
import { useCloseModalKey, useModalKey } from "../OpenModalHook"
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, Button } from "@mui/material"

export const EDIT_TRANSCRIPTION_JOB_MODAL_KEY = "EDIT_TRANSCRIPTION_JOB_MODAL_KEY"

export const EditModal: FC<{ meetingId: number; transcriptionJobId?: number }> = ({
  meetingId,
  transcriptionJobId,
}) => {
  const [newTranscriptionJob, setTranscriptionJob] = useState<BaseTranscriptionJob>({} as BaseTranscriptionJob)
  const { data: transcriptionJob } = useFetchTranscriptionJob(transcriptionJobId)
  const { data: transcriptionJobByMeeting } = useFetchTranscriptionJobByMeeting(meetingId)
  const open = useModalKey(EDIT_TRANSCRIPTION_JOB_MODAL_KEY)
  const closeModal = useCloseModalKey(EDIT_TRANSCRIPTION_JOB_MODAL_KEY)
  const { mutate: updateTranscriptionJob } = useUpdateTranscriptionJob()

  useEffect(() => {
    if (transcriptionJob) {
      setTranscriptionJob(transcriptionJob)
    } else if (transcriptionJobByMeeting) {
      setTranscriptionJob(transcriptionJobByMeeting)
    }
  }, [transcriptionJob, transcriptionJobByMeeting])

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Edit Transcript {newTranscriptionJob.id}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          key={newTranscriptionJob.id}
          label={"Download in progress?"}
          control={
            <Checkbox
              checked={newTranscriptionJob.in_progress}
              color="primary"
              onChange={() =>
                setTranscriptionJob({ ...newTranscriptionJob, in_progress: !newTranscriptionJob.in_progress })
              }
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={() => {
            updateTranscriptionJob(newTranscriptionJob)
            closeModal()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
