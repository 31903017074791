import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { SObject } from "../interfaces/salesforce"

const prefix = "/account"

export const useIsSalesforceConnected = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["salesforce"], async () => {
    return (await (await axiosInstance).get(`${prefix}/salesforce`)).data
  })
}

export const useConnectSalesforce = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { code: string; verifier: string }) => {
      return (await (await axiosInstance).post(`${prefix}/salesforce`, params)).data
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["salesforce"])
      },
    },
  )
}

export const getSalesforceSObjects = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["salesforce", "sobjects"], async () => {
    return (
      await (
        await axiosInstance
      ).get<{
        encoding: string
        maxBatchSize: number
        sobjects: SObject[]
      } | null>(`${prefix}/salesforce/sobjects`)
    ).data
  })
}
