import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import ScheduleIcon from "@mui/icons-material/Schedule"
import AssignmentIcon from "@mui/icons-material/Assignment"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import CloseIcon from "@mui/icons-material/Close"
import { FC } from "react"
import { demoLink, trialLink } from "./PromoLinks"

export const MobilePromoLinks: FC<{ isFindALead: boolean }> = ({ isFindALead }) => {
  const actions = [{ icon: <ScheduleIcon />, text: "Demo", onClick: () => window.open(demoLink, "_blank") }]
  if (!isFindALead) {
    actions.push({ icon: <AssignmentIcon />, text: "Trial", onClick: () => window.open(trialLink, "_blank") })
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="speed-dial-mobile-promo-links"
        sx={{ position: "fixed", bottom: 10, right: 10 }}
        icon={<SpeedDialIcon icon={<LocalPhoneIcon />} openIcon={<CloseIcon />} />}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.text}
            icon={action.icon}
            tooltipTitle={action.text}
            tooltipOpen
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}
