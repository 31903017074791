import { Grid, Divider } from "@mui/material"
import { Table } from "../../shared/Table"
import { FC, useMemo } from "react"
import { createStatePermissionRow, statePermissionsColumns } from "@config/geographyAdminConfig"
import { useFetchUserOrganizationPermissions } from "@api/userOrganizations"
import { useFetchGeographies } from "@api/geographies"
import { UserOrganizationPermission } from "@src/interfaces/userOrganizationPermission"
import { State } from "@src/interfaces/geography"
import { DetailedUserOrganization } from "@src/interfaces/userOrganization"

export const NewPermissionState: FC<{
  selectedStates: number[]
  handleSelectionChange: (selectedStates: number[]) => void
  userOrganization: DetailedUserOrganization
  includeInternal?: boolean
}> = ({ selectedStates, handleSelectionChange, userOrganization, includeInternal = true }) => {
  const { data: fetchGeographies } = useFetchGeographies()
  const { data: permissions } = useFetchUserOrganizationPermissions(userOrganization.id)

  const availableStates = useMemo(() => fetchGeographies?.states || [], [fetchGeographies])

  const getPermissionCounts = () => {
    const permissionByState: Record<string, UserOrganizationPermission[] | undefined> = {}
    const permissionAutoUpdateByState: Record<string, Record<string, number>> = {}
    permissions?.permissions.forEach((p) => {
      if (!permissionAutoUpdateByState[p.state]) {
        permissionAutoUpdateByState[p.state] = {}
      }
      permissionByState[p.state] = [...(permissionByState[p.state] || []), ...[p]]
      if (p.auto_update) {
        const newCount = (permissionAutoUpdateByState[p.state]["autoUpdateOnCount"] || 0) + 1
        permissionAutoUpdateByState[p.state]["autoUpdateOnCount"] = newCount
      } else {
        const newCount = (permissionAutoUpdateByState[p.state]["autoUpdateOffCount"] || 0) + 1
        permissionAutoUpdateByState[p.state]["autoUpdateOffCount"] = newCount
      }
    })

    return permissionAutoUpdateByState
  }

  const addCountsToAvailableStates = (
    availableStates: State[],
    permissionAutoUpdateByState: Record<string, Record<string, number>>,
  ) => {
    availableStates.forEach((state: any) => {
      const totalOrgs = permissions?.org_totals_by_state[state.name] || 0
      const ownedAutoUpdateOnPermissions = permissionAutoUpdateByState?.[state.name]?.autoUpdateOnCount || 0
      const ownedAutoUpdateOffPermissions = permissionAutoUpdateByState?.[state.name]?.autoUpdateOffCount || 0
      const totalAutoUpdateOn = permissions?.auto_update_on_count_by_state[state.name] || 0
      const totalAutoUpdateOff = permissions?.auto_update_off_count_by_state[state.name] || 0
      state["totalChannels"] = `${totalOrgs}`
      state["autoUpdateCount"] = `${ownedAutoUpdateOnPermissions} / ${totalAutoUpdateOn}`
      state["nonAutoUpdateCount"] = `${ownedAutoUpdateOffPermissions} / ${totalAutoUpdateOff}`
    })

    return availableStates
  }

  const availableStatesWithPermissionCounts = useMemo(() => {
    if (!permissions) {
      return availableStates
    }
    const permissionAutoUpdateByState = getPermissionCounts()
    const availableStatesWithCounts = addCountsToAvailableStates(availableStates, permissionAutoUpdateByState)
    if (!includeInternal) {
      return availableStatesWithCounts.filter((state) => state.name !== "Cloverleaf Internal")
    }
    return availableStatesWithCounts
  }, [permissions, availableStates])

  return (
    <Grid container item spacing={1} direction="column">
      <Grid item>
        Give <strong>{userOrganization.name}</strong> access to the following States
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid item>
        <Table
          data={availableStatesWithPermissionCounts}
          selected={selectedStates}
          setSelected={handleSelectionChange}
          createRow={createStatePermissionRow}
          columns={statePermissionsColumns}
          sortKey="name"
          sortOrder="asc"
        />
      </Grid>
    </Grid>
  )
}
