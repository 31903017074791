import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useUploadOrgsFromCsv } from "../../../api/organizations"

export const CsvUploadModal: FC<{
  open: any
  onClose: any
}> = ({ open, onClose }) => {
  const { mutate: uploadOrgCsv, data: csvResponseData, reset: resetCsvUpload } = useUploadOrgsFromCsv()
  const [csvFile, setCsvFile] = useState<File | undefined>()

  const handleOnClose = () => {
    setCsvFile(undefined)
    resetCsvUpload()
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth>
      <DialogTitle>Upload Channels via CSV</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Button variant="contained" component="label" size="small">
              Choose CSV
              <input
                onChange={(event: any) => {
                  setCsvFile(event.target.files[0])
                }}
                type="file"
                hidden
              />
            </Button>
            <Grid container padding={1}>
              {csvFile && <Typography>{csvFile?.name}</Typography>}
            </Grid>
            {typeof csvResponseData?.data === "string" && (
              <Typography variant="h6" color="red">
                Invalid CSV Format!!!
              </Typography>
            )}
            {typeof csvResponseData?.data === "object" && (
              <Grid container>
                <Grid container spacing={2} padding={1}>
                  <Typography variant="h6" paddingLeft={1} marginTop={1}>
                    {`New Channels (${csvResponseData.data?.new_orgs?.length} uploaded)`}
                  </Typography>
                  {csvResponseData.data?.new_orgs?.map((row: any) => (
                    <Grid container item key={`${Object.values(row).join("-")}`}>
                      {Object.keys(row).map((key: string) => (
                        <Grid item key={key}>
                          <Typography>{`${key}: ${row[key]}`}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} padding={1}>
                  <Typography variant="h6" paddingLeft={1} marginTop={1}>
                    {`Skipped Rows (${csvResponseData.data?.skipped_rows?.length} total)`}
                  </Typography>
                  {csvResponseData.data?.skipped_rows?.map((row: any) => (
                    <Grid container item key={`${Object.keys(row).join("-")}`}>
                      {Object.keys(row).map((key: string) => (
                        <Grid container item key={key} direction="row">
                          <Typography>{`${key}: ${row[key]}`}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Link
              component="button"
              onClick={() => {
                const link = document.createElement("a")
                link.download = "download.txt"
                link.href =
                  "https://storage.googleapis.com/cloverleaf-ai-public-assets/youtube-channel-upload-template.csv"
                link.click()
              }}
            >
              Download CSV Template
            </Link>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={csvFile && (() => uploadOrgCsv({ file: csvFile }))}
          disabled={csvFile === undefined}
          size="medium"
        >
          UPLOAD
        </Button>
        <Button onClick={handleOnClose} size="medium">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
