import { Grid, TextField } from "@mui/material"
import { FC, useState, useEffect } from "react"
import { DatePicker } from "../../Home/DatePicker"
import { defaultDateRange } from "../../shared/config"
import moment from "moment"
import { Configuration } from "../../../interfaces/dashboard"
import { DayRangePicker } from "../../shared/DayRangePicker"
import { useToast } from "@src/components/shared/ToastHook"
import { setMaxDateRange } from "@src/functions/common"

export const HoursAnalyzed: FC<{
  setConfiguration: (config: Configuration) => void
  defaultConfig?: Configuration
}> = ({ setConfiguration, defaultConfig }) => {
  const [name, setName] = useState(defaultConfig?.name || "")

  let dateRange: [Date, Date]
  if (defaultConfig?.date_range) {
    const startDate = moment(defaultConfig.date_range.start).toDate()
    const endDate = moment(defaultConfig.date_range.end).toDate()
    dateRange = [startDate, endDate]
  } else {
    dateRange = defaultDateRange
  }
  const [searchDateRange, setSearchDateRange] = useState(dateRange)
  const [dayRange, setDayRange] = useState(defaultConfig?.day_range || 7)

  const setToast = useToast()

  useEffect(() => {
    setConfiguration({
      name: name,
      date_range: setMaxDateRange({
        dateRange: dateRange,
        maxRange: undefined,
        handleChange: () =>
          setToast("Invalid Date Range selected. Range must be less than 1 year. ", 5000, undefined, "error"),
      }),
      day_range: dayRange,
    })
  }, [name, searchDateRange, dayRange])

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={10}>
        <TextField
          value={name}
          onChange={(event: any) => setName(event.target.value)}
          label="Widget Name"
          fullWidth
          error={!(name && name.length > 0)}
          margin="dense"
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <DayRangePicker searchDayRange={dayRange} setSearchDayRange={setDayRange} showCustom />
      </Grid>
      <Grid item md={5} xs={12} sx={dayRange !== "custom" ? { display: "none" } : null}>
        <DatePicker searchDateRange={searchDateRange} setSearchDateRange={setSearchDateRange} />
      </Grid>
    </Grid>
  )
}
