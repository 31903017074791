import { FC } from "react"
import { List, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { CampaignListItem } from "./CampaignListItem"
import { useFetchUserOrganizationCampaigns } from "../../../../api/admin/user_organizations"
import { CampaignEditButton } from "./CampaignEditButton"
import { AugmentedStatus } from "../../../shared/LoadingEllipsis"

export const CampaignList: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data, status } = useFetchUserOrganizationCampaigns(userOrganizationId)

  return (
    <>
      <CampaignEditButton variant="contained">Create Campaign</CampaignEditButton>
      {data ? (
        <List>
          {data.map((campaign) => {
            return <CampaignListItem key={campaign.id} campaign={campaign} />
          })}
        </List>
      ) : (
        <Typography>
          <AugmentedStatus status={status} />
        </Typography>
      )}
    </>
  )
}
