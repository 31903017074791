import { Column } from "../../shared/DataTable"

export const columns: readonly Column[] = [
  {
    id: "title",
    label: "Title",
  },
  {
    id: "publishedAt",
    label: "Published",
  },
  {
    id: "channelName",
    label: "Channel Name",
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "geography",
    label: "Geography",
  },
  {
    id: "totalMatches",
    label: "Total Matches",
    headerCellProps: { sx: { maxWidth: 90, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "kwHits",
    label: "Keyword Hits",
    headerCellProps: { sx: { minWidth: 100, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "actions",
    label: "Save",
    headerCellProps: { sx: { minWidth: 50, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "viewed",
    label: "Already Viewed",
    headerCellProps: { sx: { minWidth: 50, backgroundColor: "neutral.main", zIndex: 1 } },
  },
]

// Channel Name and Geography loaded seperately
