import { FC, forwardRef, ForwardedRef, useContext } from "react"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableContainerProps,
  Checkbox,
  TableSortLabel,
  Typography,
  Stack,
  Button,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { TableContext } from "./"
import { unpackSetters } from "../unpackSetters"

export const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.secondary.main}`,
}))

export const TableWithHeader: FC<TableContainerProps> = forwardRef(function Component(
  props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { children } = props
  const propsCopy = { ...props }
  delete propsCopy.children
  const {
    columns,
    toggleAllSelected,
    addRef,
    checked,
    indeterminate,
    orderObject,
    setOrderObject,
    size,
    stickyHeader,
    selected,
    actions,
    actionsBarEnabled,
    headerSortInitialOrder,
  } = useContext(TableContext)
  const { order, sortColumn } = orderObject
  const { setOrder, setSortColumn } = unpackSetters(orderObject, setOrderObject)

  const makeClickHandler = (makeColumn: string | number | symbol | string[]) => () => {
    if (makeColumn !== sortColumn) {
      setSortColumn(makeColumn)
      setOrder(headerSortInitialOrder || "asc")
    } else {
      setOrder(order === "asc" ? "desc" : "asc")
    }
  }

  return (
    <TableContainer {...propsCopy} ref={ref}>
      <Table stickyHeader={stickyHeader} size={size}>
        <TableHead>
          <TableRow>
            {toggleAllSelected && (
              <TableHeaderCell
                padding="checkbox"
                sx={{ position: stickyHeader ? "sticky" : "relative", backgroundColor: "neutral.main" }}
              >
                <Stack direction="row" spacing={2} alignItems="center" position="absolute" top={0} bottom={0}>
                  <Checkbox checked={checked} indeterminate={indeterminate} onClick={toggleAllSelected} />
                  {(checked || indeterminate) && (
                    <>
                      <Typography noWrap>{`${selected?.length} Selected`}</Typography>
                      {actions?.map(({ action, text, icon }) => (
                        <Button
                          key={text}
                          onClick={action}
                          startIcon={icon}
                          variant="contained"
                          sx={{ textTransform: "capitalize", whiteSpace: "nowrap" }}
                        >
                          {text}
                        </Button>
                      ))}
                    </>
                  )}
                </Stack>
              </TableHeaderCell>
            )}
            {columns.map((column, index) => {
              if (typeof column === "object") {
                let headerCellContent: JSX.Element
                if (column["sortingEnabled"] === false) {
                  headerCellContent = (
                    <>
                      <Typography variant="h4">{column.label}</Typography>
                    </>
                  )
                } else {
                  headerCellContent = (
                    <TableSortLabel
                      active={sortColumn === column.id}
                      direction={order}
                      onClick={column.label ? makeClickHandler(column.sortProp || column.id) : undefined}
                      sx={
                        actionsBarEnabled && (checked || indeterminate)
                          ? { opacity: "0", pointerEvents: "none" }
                          : undefined
                      }
                    >
                      <Typography variant="h4">{column.label}</Typography>
                    </TableSortLabel>
                  )
                }

                return (
                  <TableHeaderCell
                    key={column.key ? index : column.id}
                    ref={addRef && ((element) => addRef(element))}
                    {...column.headerCellProps}
                  >
                    {headerCellContent}
                  </TableHeaderCell>
                )
              } else {
                return (
                  <TableHeaderCell
                    key={column.toString()}
                    ref={addRef && ((element) => addRef(element))}
                    sx={{ backgroundColor: "neutral.main" }}
                  >
                    <TableSortLabel
                      active={sortColumn === column}
                      direction={order}
                      onClick={column ? makeClickHandler(column) : undefined}
                      sx={
                        actionsBarEnabled && (checked || indeterminate)
                          ? { opacity: "0", pointerEvents: "none" }
                          : undefined
                      }
                    >
                      <Typography variant="h4">{column}</Typography>
                    </TableSortLabel>
                  </TableHeaderCell>
                )
              }
            })}
          </TableRow>
        </TableHead>
        {children}
      </Table>
    </TableContainer>
  )
})
