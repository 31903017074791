import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { FC, ReactNode } from "react"
import { useModalKey, useCloseModalKey } from "../OpenModalHook"

export const ConfirmationModal: FC<{
  modalKey: string
  onConfirm: () => void
  onCancel?: () => void
  title?: ReactNode
  body?: ReactNode
  disabled?: boolean
}> = ({ modalKey, onCancel, onConfirm, title, body, disabled }) => {
  const isOpen = useModalKey(modalKey)
  const close = useCloseModalKey(modalKey)

  return (
    <Dialog open={isOpen} onClose={close}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {body && <DialogContent>{body}</DialogContent>}
      <DialogActions>
        <Button
          onClick={() => {
            onCancel?.()
            close()
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm()
            close()
          }}
          disabled={disabled}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
