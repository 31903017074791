import { FC, useEffect, useMemo } from "react"
import { usePreviewWidget } from "../../../api/widgets"
import { Grid, Typography, CardHeader, Chip, Skeleton } from "@mui/material"
import moment from "moment"
import { DisplayWidgetActions } from "./DisplayWidgetActions"
import { Configuration } from "../../../interfaces/dashboard"
import { dateRangeLabel } from "../../../functions/dashboard"

export const HoursAnalyzed: FC<{
  configuration: Configuration
  openAction?: (actionType: string) => void
}> = ({ configuration, openAction }) => {
  const { data: hoursAnalyzed, isLoading } = usePreviewWidget({ type: "hours", configuration: configuration })
  const displayDateRange = useMemo(() => dateRangeLabel(configuration), [configuration.date_range])

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <CardHeader title={isLoading ? <Skeleton /> : configuration.name} subheader="Videos Analyzed" />
        </Grid>
        <Grid item>
          <Chip
            color="primary"
            label={isLoading ? <Skeleton width="20ch" sx={{ backgroundColor: "#bbb" }} /> : displayDateRange}
            sx={{ position: "relative" }}
          />
          {openAction && <DisplayWidgetActions openAction={openAction} />}
        </Grid>
      </Grid>
      <Grid container direction="row" paddingTop={5}>
        <Grid container item xs={6} direction="column" alignItems="center">
          <Typography variant="large-number">
            {isLoading ? <Skeleton width="2ch" /> : hoursAnalyzed?.widget.total_time}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Hours
          </Typography>
        </Grid>
        <Grid container item xs={6} direction="column" alignItems="center">
          <Typography variant="large-number">
            {isLoading ? <Skeleton width="2ch" /> : hoursAnalyzed?.widget.meeting_count}
          </Typography>
          <Typography variant="h6">Meetings</Typography>
        </Grid>
      </Grid>
    </>
  )
}
