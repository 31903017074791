import { useQuery } from "react-query"
import { useAuthAxios } from "@src/api/axiosInstance"
import { OpportunityCampaign } from "@src/interfaces/opportunityCampaign"

const prefix = (userOrganizationId: unknown) => `admin/user_organizations/${userOrganizationId}/opportunity_campaigns`

export const useAdminFetchOpportunityCampaigns = (userOrganizationId: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["opportunityCampaigns", { user_organization_id: `${userOrganizationId}` }], async () => {
    const response = await (await axiosInstance).get<OpportunityCampaign[]>(prefix(userOrganizationId))

    return response.data
  })
}

export const useAdminFetchOpportunityCampaignsMap = (userOrganizationId: unknown) => {
  const fetchData = useAdminFetchOpportunityCampaigns(userOrganizationId)
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((opportunityCampaign) => [opportunityCampaign.id, opportunityCampaign])),
    }
  }
  return fetchData
}
