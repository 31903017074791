import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { FC } from "react"

export const DayRangePicker: FC<{
  searchDayRange: number | string
  setSearchDayRange: (value: number | string) => void
  showCustom?: boolean
}> = ({ searchDayRange, setSearchDayRange, showCustom = false }) => (
  <FormControl fullWidth margin="dense">
    <InputLabel id="day-range-select-label">Date Range</InputLabel>
    <Select
      labelId="day-range-select-label"
      id="day-range-id"
      value={searchDayRange}
      label="Date Range"
      onChange={(e) => setSearchDayRange(e.target.value)}
    >
      <MenuItem value={7}>Last 7 Days</MenuItem>
      <MenuItem value={30}>Last 30 Days</MenuItem>
      <MenuItem value={90}>Last 90 Days</MenuItem>
      <MenuItem value={"custom"} sx={!showCustom ? { display: "none" } : null}>
        Custom date range
      </MenuItem>
    </Select>
  </FormControl>
)
