import { FetchedMonitor } from "../../../api/admin/signals_monitors"
import { Organization } from "../../../interfaces/organization"
import { Column, formatDate } from "../../shared/DataTable"

const unpackOrgName = (organization: Organization) => {
  return organization?.name
}

const unpackAutoUpdate = (organization: Organization) => {
  return organization?.automatic_update ? "Yes" : "No"
}

const getKey = (data: any, index: number) => {
  return `${data.id}_${index}`
}

const getLink = (data: Organization) => {
  return `/admin/organizations/${data.id}`
}

const getUserOrgLink = (data: FetchedMonitor) => {
  return `/admin/user_organizations/${data.organization_id}`
}

const trimMessage = (message: string) => {
  return message?.length > 100 ? `${message.substring(0, 100)}...` : message
}

const formatSignalsProjection = (projection: boolean | null) => {
  if (projection === null) {
    return "Projection unknown"
  }
  return projection ? "Yes" : "No"
}

export const signalsColumns: Column[] = [
  { id: "email", label: "Email", headerCellProps: { sx: { minWidth: 210 } } },
  { id: "organization", link: getUserOrgLink, label: "User Organization", headerCellProps: { sx: { minWidth: 210 } } },
  {
    id: "signals_projection",
    formatter: formatSignalsProjection,
    label: "Projected?",
    headerCellProps: { sx: { minWidth: 50 } },
  },
  { id: "delivery_status", label: "Delivered?", headerCellProps: { sx: { minWidth: 50 } } },
  { id: "signals_status", label: "Process Status", headerCellProps: { sx: { minWidth: 50 } } },
  { id: "message", formatter: trimMessage, label: "Error Message", headerCellProps: { sx: { minWidth: 50 } } },
  { id: "opens_count", label: "Opens Count", headerCellProps: { sx: { minWidth: 20 } } },
  { id: "clicks_count", label: "Clicks Count", headerCellProps: { sx: { minWidth: 20 } } },
  { id: "updated_at", label: "Updated At", headerCellProps: { sx: { minWidth: 30 } }, formatter: formatDate },
]

export const organizationsColumns: Column[] = [
  { id: "organization_id", label: "Id", link: getLink },
  { id: "organization", sortProp: ["organization", "name"], formatter: unpackOrgName, key: getKey, label: "Name" },
  {
    id: "organization",
    sortProp: ["organization", "automatic_update"],
    formatter: unpackAutoUpdate,
    key: getKey,
    label: "Auto Update",
  },
  { id: "status", label: "Status" },
  { id: "down_date", label: "Down Date", formatter: formatDate },
  { id: "updated_at", label: "Updated At", formatter: formatDate },
  { id: "message", label: "Message", formatter: trimMessage },
]
