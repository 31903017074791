import { FC, useState, useEffect } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import moment from "moment"
import { defaultDateRange } from "../../shared/config"
import { DatePicker } from "../../Home/DatePicker"
import { TagsSearchInput } from "../../shared/TagsSearchInput"
import { Configuration } from "../../../interfaces/dashboard"
import { DayRangePicker } from "../../shared/DayRangePicker"
import { MyCampaignsAutoComplete } from "../../shared/MyCampaignsAutocomplete"
import { UserCampaign } from "../../../interfaces/campaign"
import { useToast } from "@src/components/shared/ToastHook"
import { setMaxDateRange } from "@src/functions/common"

export const GeographiesOverTime: FC<{
  setConfiguration: (config: Configuration) => void
  defaultConfig?: Configuration
}> = ({ setConfiguration, defaultConfig }) => {
  const [name, setName] = useState(defaultConfig?.name || "")
  const [searchTerms, setSearchTerms] = useState(defaultConfig?.search_terms || [])

  const [dateRange, setDateRange] = useState<[Date, Date]>(
    defaultConfig?.date_range
      ? [moment(defaultConfig.date_range.start).toDate(), moment(defaultConfig.date_range.end).toDate()]
      : defaultDateRange,
  )
  const [xAxisUnit, setXAxisUnit] = useState(defaultConfig?.x_axis_unit || "day")
  const [tagsRef, setTagsRef] = useState(null)
  const [dayRange, setDayRange] = useState(defaultConfig?.day_range || 7)
  const [geographyType, setGeographyType] = useState(defaultConfig?.geography_type || "state")

  const campaignChangeHandler = (campaign: UserCampaign | null) => {
    setSearchTerms([])
    if (campaign?.data?.searchTerms) setSearchTerms(campaign.data.searchTerms)
  }

  const setToast = useToast()

  useEffect(() => {
    setConfiguration({
      name: name,
      search_terms: searchTerms,
      x_axis_unit: xAxisUnit,
      day_range: dayRange,
      date_range: setMaxDateRange({
        dateRange: dateRange,
        maxRange: undefined,
        handleChange: () =>
          setToast("Invalid Date Range selected. Range must be less than 1 year. ", 5000, undefined, "error"),
      }),
      geography_type: geographyType,
    })
  }, [name, geographyType, dateRange, dayRange, searchTerms, xAxisUnit])

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} alignItems="center" spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Widget Name"
            fullWidth
            error={!(name && name.length > 0)}
            sx={{ marginTop: 1 }}
          />
        </Grid>
        <Grid item md={2} xs={12} marginTop={1}>
          <DayRangePicker searchDayRange={dayRange} setSearchDayRange={setDayRange} showCustom />
        </Grid>
        <Grid item md={3} xs={12} marginTop={1} sx={dayRange !== "custom" ? { display: "none" } : null}>
          <DatePicker searchDateRange={dateRange} setSearchDateRange={setDateRange} size="lg" />
        </Grid>
      </Grid>
      <Grid item md={2} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="x-axis-select-label">X-Axis Unit</InputLabel>
          <Select
            labelId="x-axis-select-label"
            id="x-axis-select"
            value={xAxisUnit}
            onChange={(event: any) => setXAxisUnit(event.target.value)}
            label="X-Axis Unit"
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="geography-type-select-label">Geography Type</InputLabel>
          <Select
            labelId="geography-type-select-label"
            id="geography-type-select"
            value={geographyType}
            onChange={(e) => setGeographyType(e.target.value)}
            label="Geography Type"
          >
            <MenuItem value="state">State</MenuItem>
            <MenuItem value="county">County</MenuItem>
            <MenuItem value="city">City</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={5} xs={12}>
        <MyCampaignsAutoComplete onChangeHandler={campaignChangeHandler} />
      </Grid>
      <Grid item xs={12}>
        <TagsSearchInput
          tags={searchTerms}
          setTags={setSearchTerms}
          setTagsRef={setTagsRef}
          showSubmit
          numberKeywordsToValidate={15}
        />
      </Grid>
    </Grid>
  )
}
