import { useAuth0 } from "@auth0/auth0-react"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Stack,
  DialogTitle,
  Link,
  DialogActions,
  Button,
} from "@mui/material"
import { FC } from "react"
import { useFetchCurrentUser } from "../api/users"

export const FreeUserPage: FC = () => {
  const { user, logout } = useAuth0()
  useFetchCurrentUser() // creates user if doesn't already exist

  const supportLink = <Link href="mailto:sales@cloverleaf.ai">sales@cloverleaf.ai</Link>

  return (
    <Dialog open sx={{ ".MuiDialog-paper": { minHeight: "40vh" } }}>
      <DialogTitle variant="h1">Content Unavailable</DialogTitle>
      <DialogContent>
        <Stack spacing={2} direction="column" alignItems="flex-start">
          <DialogContentText variant="h6">
            <b>{user?.email ? user.email : "Your Account "}</b>
            &nbsp;may only view shared content.
          </DialogContentText>
          <DialogContentText variant="h6">
            If you would like full access, please reach out to your customer success representative or&nbsp;
            {supportLink}.
          </DialogContentText>
          <DialogContentText variant="h6">Thanks!</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => logout()}>Logout</Button>
      </DialogActions>
    </Dialog>
  )
}
