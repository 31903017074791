import { Grid, Typography } from "@mui/material"
import { FC, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useUnsubscribeTrialUser, useUnsubscribeUser } from "../api/promotions"

type UserParam = {
  id: string
}

export const UnsubscribePage: FC = () => {
  const searchParams = useLocation().search
  const queryParams = new URLSearchParams(searchParams)
  const emailType = queryParams.get("emailType")
  const emailTypeFormatted = emailType ? (emailType === "signals" ? "Signals" : "Account Summary") : ""

  const { id } = useParams<UserParam>()
  const { mutate: unsubscribe, isSuccess: successStatus } = useUnsubscribeTrialUser()
  const { mutate: unsubUser, isSuccess: unsubUserSuccess } = useUnsubscribeUser()

  let message
  if (successStatus) {
    message = "You have successfully unsubscribed from Cloverleaf Trial Emails."
  }
  if (unsubUserSuccess) {
    message = `You have successfully unsubscribed from Cloverleaf ${emailTypeFormatted} Emails.`
  } else {
    message = "Something went wrong. Please contact support@cloverleaf.ai."
  }

  useEffect(() => {
    if (emailType) {
      unsubUser({ id, emailType })
    } else {
      unsubscribe(id)
    }
  }, [])

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Typography
          sx={{ marginTop: "auto", marginBottom: "auto", marginLeft: "auto", marginRight: "auto" }}
          variant="h3"
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  )
}
