import { createContext, FC } from "react"
import { Box } from "@mui/material"
import { useLocation } from "react-router-dom"
import { Layout } from "../../components/shared/Layout"
import { BriefingQueryObject, urlParamsToBriefingQueryObject } from "../../api/briefings"
import useSetUrlParamState from "../../components/shared/UseSetUrlParamState"
import { BriefingsDataFiltersContainer } from "./BriefingsDataFiltersContainer"
import { BriefingsDataGrid } from "@components/Briefings/DataGrid/BriefingsDataGrid"

export interface BriefingsContainerProps {
  params: URLSearchParams
  urlParamState: BriefingQueryObject
  setUrlParamState: (newFilterParams: Record<string, unknown>) => void
}

export const BriefingsContainerContext = createContext<BriefingsContainerProps>({
  params: new URLSearchParams(""),
  urlParamState: {
    states: [],
    cities: [],
    counties: [],
    organizations: [],
    channel_types: [],
    meetings: [],
    city_population: [0, 0],
    county_population: [0, 0],
    city_income: [0, 0],
    county_income: [0, 0],
    types: [],
    users: [],
    text: "",
    sort: "",
    order: "",
    page: 0,
    count: 25,
    fine_tuning: "",
    generated_by_model: "",
  },
  setUrlParamState: () => {
    /* Something here for linter */
  },
})

export const BriefingsContainer: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const urlParamState = urlParamsToBriefingQueryObject(params)
  const setUrlParamState = useSetUrlParamState()

  return (
    <Layout title="Briefings">
      <BriefingsContainerContext.Provider value={{ params, urlParamState, setUrlParamState }}>
        <BriefingsDataFiltersContainer />
        <Box marginTop={2}>
          <BriefingsDataGrid />
        </Box>
      </BriefingsContainerContext.Provider>
    </Layout>
  )
}
