import { FC, useMemo, useState } from "react"
import { TextField, Typography, Stack, Autocomplete } from "@mui/material"
import { useFetchUsers } from "../../../api/users"
import { unpackSetters } from "../../shared/unpackSetters"
import { ClipAttributes } from "./interface"

const TitleClipStartEditor: FC<{
  clipAttributes: ClipAttributes
  setClipAttributes: any
  startTime: string
}> = ({ startTime, clipAttributes, setClipAttributes }) => {
  const { data: userData } = useFetchUsers()
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false)
  const { clipTitle, selectedUsers, description } = clipAttributes
  const { setClipTitle, setSelectedUsers, setDescription } = unpackSetters(clipAttributes, setClipAttributes)

  const sortedUsers = useMemo(() => {
    if (userData) {
      const sortableArray = [...userData]
      return sortableArray.sort((userOne, userTwo) => {
        if (userOne.email < userTwo.email) {
          return -1
        }
        if (userOne.email > userTwo.email) {
          return 1
        }

        return 0
      })
    } else {
      return []
    }
  }, [userData])

  const updateTitle = (title: string) => {
    if (title.replace(/\s/g, "").length) {
      setClipTitle(title)
    }
  }

  return (
    <>
      <Stack paddingTop={1} spacing={3}>
        <TextField
          value={clipTitle}
          onChange={(event) => updateTitle(event.target.value)}
          size="small"
          variant="outlined"
          label="Title"
          color="primary"
          fullWidth
          InputLabelProps={{ style: { color: "#364656", fontWeight: 500 } }}
        />
        <Autocomplete
          multiple
          size="small"
          disableCloseOnSelect
          noOptionsText="Nothing to display"
          value={selectedUsers}
          color="primary"
          id="editor-users-autocomplete"
          onOpen={() => setAutoCompleteOpen(true)}
          onClose={() => setAutoCompleteOpen(false)}
          onClick={() => setAutoCompleteOpen(true)}
          open={autoCompleteOpen}
          options={sortedUsers}
          // the github linter needs "as User[]" below and I have no idea why
          onChange={(_event, value) => setSelectedUsers(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tagged Users"
              InputLabelProps={{ shrink: true, style: { color: "#364656", fontWeight: 500 } }}
            />
          )}
          getOptionLabel={(option) => option.email}
          limitTags={1}
          renderTags={() => (
            <Typography paddingLeft={2} noWrap sx={{ maxWidth: "calc(100% - 40px)" }}>
              {selectedUsers?.map((user) => user.email).join(", ")}
            </Typography>
          )}
        />
        <TextField
          value={description}
          onChange={(event: any) => setDescription(event.target.value)}
          size="small"
          variant="outlined"
          label="Notes"
          color="primary"
          fullWidth
          InputProps={{ sx: { borderRadius: "0px !important" } }}
          InputLabelProps={{ shrink: true, style: { color: "#364656", fontWeight: 500 } }}
          multiline
          maxRows={12}
          minRows={2}
        />
        <Typography sx={{ marginLeft: 2 }}>
          Start Time:&nbsp;
          {startTime}
        </Typography>
      </Stack>
    </>
  )
}

export default TitleClipStartEditor
