import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Flag } from "@interfaces/flag"

export interface FlagParams {
  name: string
  flag_type: string
  value: string
  public: boolean
}

const prefix = "/admin/feature_flags"

export const useGetAllFeatureFlags = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["flags"], async () => {
    const response = await (await axiosInstance).get<Flag[]>(`${prefix}`)

    return response.data
  })
}

interface FlagTypes {
  [typeString: string]: number
}
export const useGetAllFeatureFlagTypes = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["flag_types"], async () => {
    const response = await (await axiosInstance).get<FlagTypes>(`${prefix}?type=flag_types`)

    return response.data
  })
}

export const useCreateFlag = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: FlagParams) => {
      return (await axiosInstance).post(prefix, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["flags"])
      },
    },
  )
}

export const useUpdateFlag = (id: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: FlagParams) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["flags"])
      },
    },
  )
}

export const useDeleteFlags = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: string[]) => {
      return (await axiosInstance).delete(`${prefix}/${id.join(",")}`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["flags"])
      },
    },
  )
}
