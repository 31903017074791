import { FC, useState, useContext, useEffect } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  FormLabel,
  FormGroup,
  FormControlLabel,
  MenuItem,
} from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { useFetchUsers, useUpdateUsersRoles } from "../../api/users"
import { useFetchRoles } from "../../api/roles"
import { UserContext } from "../../pages/UsersPage"
import { useToast } from "../shared/ToastHook"
import { useModalKey, useOpenModalKey, useCloseModalKey } from "../shared/OpenModalHook"

export const userRolesModalKey = "UPDATE_USER_ROLES"

export const UpdateRoleMenuItem: FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const contextData = useContext(UserContext)
  const { ids } = contextData
  const { data: rolesData } = useFetchRoles()
  const openModalKey = useOpenModalKey(userRolesModalKey)
  return (
    <MenuItem
      onClick={() => {
        openModalKey()
        closeMenu()
      }}
      disabled={!ids.length || !rolesData || !rolesData.length}
    >
      Update Role(s)
    </MenuItem>
  )
}

export const UpdateRoleDialog: FC = () => {
  const contextData = useContext(UserContext)
  const { ids, reset } = contextData
  const open = useModalKey(userRolesModalKey)
  const closeModalKey = useCloseModalKey(userRolesModalKey)
  const setToast = useToast()
  const { data: users } = useFetchUsers()
  const { data: rolesData } = useFetchRoles()
  const selectedUsers = users?.filter((user) => ids.includes(user.id))
  const userEmails = selectedUsers?.map((user) => user.email)

  const [checked, setChecked] = useState<Record<string, number>>({})
  useEffect(() => {
    if (rolesData && selectedUsers) {
      const newCheckedState: Record<string, number> = {}
      rolesData.forEach((role) => (newCheckedState[role] = -1))
      let currentIndex = 0
      // 0 is false, 1 is true, 2 is indeterminate
      while (!Object.values(newCheckedState).every((state) => state === 2) && currentIndex < ids.length) {
        rolesData.forEach((role) => {
          if (newCheckedState[role] !== 2) {
            const wantedValue = selectedUsers[currentIndex].roles.some((userRole) => userRole.name === role) ? 1 : 0
            if (newCheckedState[role] === -1) {
              newCheckedState[role] = wantedValue
            } else if (newCheckedState[role] !== wantedValue) {
              newCheckedState[role] = 2
            }
          }
        })
        currentIndex += 1
      }
      setChecked(newCheckedState)
    }
  }, [rolesData, users, ids])

  const handleToggle = (role: string) => () => {
    const newChecked = { ...checked }
    if (newChecked[role] > 0) {
      newChecked[role] = 0
    } else {
      newChecked[role] = 1
    }
    setChecked(newChecked)
  }

  const close = () => {
    closeModalKey()
    reset()
  }

  const { mutate: updateUsersRoles, isLoading } = useUpdateUsersRoles(() => {
    setToast("Successfully updated user roles", 3000, <TaskAltIcon />)
    close()
  })

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle>{`Update User Role${ids.length > 1 ? "s" : ""}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>This will affect the following users:</DialogContentText>
        <List>
          {userEmails?.map((email) => (
            <ListItem key={email}>
              <ListItemText>{email}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogContent sx={{ flexShrink: "0" }}>
        <FormLabel>Assigned Roles:</FormLabel>
        <FormGroup sx={{ marginLeft: 2 }}>
          {rolesData?.map((role) => (
            <FormControlLabel
              key={role}
              control={
                <Checkbox
                  checked={Boolean(checked[role])}
                  indeterminate={checked[role] === 2}
                  onClick={handleToggle(role)}
                />
              }
              label={role}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const roles: Record<string, boolean> = {}
            Object.entries(checked).forEach(([role, value]) => {
              if (value !== 2) {
                roles[role] = Boolean(value)
              }
            })
            updateUsersRoles({
              ids,
              roles,
            })
          }}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Confirm"}
        </Button>
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
