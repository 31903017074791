import { useMutation, useQuery } from "react-query"
import { useAuthAxios } from "../axiosInstance"

const prefix = "/admin/uat"

export const useGetScraperUat = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["scraper_uat"], async () => {
    const response = await (await axiosInstance).get<string[]>(`${prefix}/scraper_uat`)

    return response.data
  })
}

export const useTriggerSignals = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: { days_back_to_process: number; users_to_process_type: string }) => {
    return (await axiosInstance).post(`${prefix}/signals_uat`, params)
  })
}

export const useFetchSignalsProgress = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return await (
      await axiosInstance
    ).get<{ data: string; date_time: string; event_type: string }[]>(`${prefix}/signals_uat_progress`)
  })
}
