import { FC, useState, createContext, Dispatch, SetStateAction, useMemo } from "react"
import { Stack, Box } from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import NotificationsIcon from "@mui/icons-material/Notifications"
import EmailIcon from "@mui/icons-material/Email"
import PersonRemoveIcon from "@mui/icons-material/PersonRemove"
import { Layout } from "../components/shared/Layout"
import { DataTable } from "../components/shared/DataTable"
import { useOpenModalKey } from "../components/shared/OpenModalHook"
import { useInviteUser } from "../api/userOrganizations"
import { useFetchUsers, useFetchUsersCreditUsages, useIsAdmin } from "../api/users"
import { ActionsMenu } from "../components/Users/ActionsMenu"
import { CreateUserDialog, createUserModalKey } from "../components/Users/CreateUserDialog"
import { columns } from "../tableColumns/userColumns"
import { SearchTextField } from "../components/shared/SearchTextField"
import { userRolesModalKey } from "../components/Users/UpdateRole"
import { updateNotificationsModalKey } from "../components/Users/UpdateNotifications"
import { deleteUsersModalKey } from "../components/Users/DeleteUsers"
import { updateCreditLimitModalKey } from "../components/Users/UpdateCreditLimit"

interface ContextData {
  ids: number[]
  reset: () => void
  openToast: string
  setOpenToast: Dispatch<SetStateAction<string>>
  inviteUserSuccess: boolean
  inviteUserReset: () => void
}
const contextData: ContextData = {
  ids: [],
  reset: () => {
    /* placeholder for typescript */
  },
  openToast: "",
  setOpenToast: () => {
    /* placeholder for typescript */
  },
  inviteUserSuccess: false,
  inviteUserReset: () => {
    /* placeholder for typescript */
  },
}
export const UserContext = createContext(contextData)

export const UsersPage: FC = () => {
  const { data: isAdmin } = useIsAdmin()
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [search, setSearch] = useState("")
  const [openToast, setOpenToast] = useState("")
  const { mutate: inviteUsers, isSuccess: inviteUserSuccess, reset: inviteUserReset } = useInviteUser()

  const openCreateUser = useOpenModalKey(createUserModalKey)
  const openUserRoles = useOpenModalKey(userRolesModalKey)
  const openUpdateNotifications = useOpenModalKey(updateNotificationsModalKey)
  const deleteUsers = useOpenModalKey(deleteUsersModalKey)
  const openUpdateCreditLimit = useOpenModalKey(updateCreditLimitModalKey)

  const { data: users } = useFetchUsers()
  const { data: usersCreditUsage } = useFetchUsersCreditUsages()
  const usersMap = new Map(users?.map((user) => [user.id, user]))

  const tableData = useMemo(() => {
    if (users) {
      return users
        .filter((user) => user.email.includes(search))
        .map((user) => {
          const userCreditUsage = usersCreditUsage?.find((creditUsage) => creditUsage.user_id === user.id)

          return {
            ...user,
            roles: user.roles.length ? user.roles.map((role) => role.name).join(", ") : "No Assigned Roles",
            creditsUsed: userCreditUsage?.credits_used,
            creditLimit: userCreditUsage?.credit_limit,
          }
        })
    }
    return []
  }, [users, search, usersCreditUsage])

  const tableActions = isAdmin
    ? [
        {
          action: openUserRoles,
          text: "Update Role(s)",
          icon: <ManageAccountsIcon />,
        },
        {
          action: openUpdateNotifications,
          text: "Update Notifications",
          icon: <NotificationsIcon />,
        },
        {
          action: () => {
            if (selectedUsers.length) {
              inviteUsers({ emails: selectedUsers.map((userId) => usersMap.get(userId)?.email) })
              setSelectedUsers([])
              setOpenToast("Emailed")
            }
          },
          text: "Resend Welcome Emails(s)",
          icon: <EmailIcon />,
        },
        {
          action: deleteUsers,
          text: "Delete User(s)",
          icon: <PersonRemoveIcon />,
        },
        {
          action: openUpdateCreditLimit,
          text: "Manage Credit Limit",
          icon: <CreditCardIcon />,
        },
      ]
    : undefined

  return (
    <UserContext.Provider
      value={{
        ids: selectedUsers,
        reset: () => setSelectedUsers([]),
        openToast,
        setOpenToast,
        inviteUserSuccess,
        inviteUserReset,
      }}
    >
      <Layout
        title="Users"
        actions={isAdmin ? [{ action: openCreateUser, text: "create user", icon: <PersonAddIcon /> }] : undefined}
      >
        <Stack direction="row" marginY={2} justifyContent="space-between">
          <SearchTextField
            label="Search Users"
            size="small"
            sx={{ width: "33.333%" }}
            setSearch={setSearch}
            search={search}
          />
          {isAdmin && (
            <Stack direction="row" spacing={1}>
              <ActionsMenu />
            </Stack>
          )}
        </Stack>
        <Box flex="1">
          <DataTable
            actions={tableActions}
            data={tableData}
            selected={isAdmin ? selectedUsers : undefined}
            setSelected={isAdmin ? setSelectedUsers : undefined}
            columns={columns(isAdmin || false)}
            columnKey={"id"}
          />
        </Box>
      </Layout>
      <CreateUserDialog />
    </UserContext.Provider>
  )
}
