import React, { FC, SetStateAction, createContext, useContext, useMemo, useState } from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { useFetchSubscribers } from "../../../../../api/admin/campaigns"
import { CampaignListItemContext } from "../CampaignListItem"
import { LoadingEllipsis } from "../../../../shared/LoadingEllipsis"
import { useUpdateUserCampaignNotification } from "../../../../../api/user_campaign_notifications"
import { useToast } from "../../../../shared/ToastHook"
import { useQueryClient } from "react-query"
import { SubscribersListItem } from "./SubscribersListItem"

export const SubscribersDialogContext = createContext<{
  emailCadences: { [key: number]: string }
  setEmailCadences: React.Dispatch<SetStateAction<{ [key: number]: string }>>
  generalBriefings: { [key: number]: boolean }
  setGeneralBriefings: React.Dispatch<SetStateAction<{ [key: number]: boolean }>>
  briefings: { [key: number]: number[] }
  setBriefings: React.Dispatch<SetStateAction<{ [key: number]: number[] }>>
  updateUserCampaignNotification: any
}>({
  emailCadences: {
    /* placeholder for typescript */
  },
  setEmailCadences: () => {
    /* placeholder for typescript */
  },
  generalBriefings: {
    /* placeholder for typescript */
  },
  setGeneralBriefings: () => {
    /* placeholder for typescript */
  },
  briefings: {
    /* placeholder for typescript */
  },
  setBriefings: () => {
    /* placeholder for typescript */
  },
  updateUserCampaignNotification: () => {
    /* placeholder for typescript */
  },
})

export const SubscribersDialog: FC = () => {
  const context = useContext(CampaignListItemContext)
  const queryClient = useQueryClient()
  const setToast = useToast()

  const { data: subscribedUsers, isLoading } = useFetchSubscribers(context.campaign.id)
  const { mutate: updateUserCampaignNotification, isSuccess } = useUpdateUserCampaignNotification()

  const [emailCadences, setEmailCadences] = useState<{ [key: number]: string }>({})
  const [generalBriefings, setGeneralBriefings] = useState<{ [key: number]: boolean }>({})
  const [briefings, setBriefings] = useState<{ [key: number]: number[] }>({})

  const dialogClose = () => {
    context.setSubscribersDialogOpen(false)
    queryClient.invalidateQueries("admin_subscribed_users")
  }

  const doneSettingState = useMemo(() => {
    if (subscribedUsers) {
      const cadences: { [key: number]: string } = {}
      const generalBriefings: { [key: number]: boolean } = {}
      const briefings: { [key: number]: number[] } = {}
      subscribedUsers.forEach((user) => {
        cadences[user.user_id] = user.email_cadence
        generalBriefings[user.user_id] = user.automated_general_briefings
        briefings[user.user_id] = user.briefings
      })
      setEmailCadences(cadences)
      setGeneralBriefings(generalBriefings)
      setBriefings(briefings)
      return true
    }
    return false
  }, [subscribedUsers])

  const contextParams = {
    emailCadences,
    setEmailCadences,
    generalBriefings,
    briefings,
    setGeneralBriefings,
    setBriefings,
    updateUserCampaignNotification,
  }

  const loadingRender = () => (
    <Stack spacing={2} margin={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <CircularProgress />
        <Typography>
          Loading
          <LoadingEllipsis />
        </Typography>
      </Stack>
      <Skeleton variant="rectangular" height={100} />
    </Stack>
  )

  return (
    <SubscribersDialogContext.Provider value={contextParams}>
      <Dialog open={context.subscribersDialogOpen} onClose={dialogClose} maxWidth="md" fullWidth>
        <DialogTitle>{`Edit Subscriber Settings for Campaign: ${context.campaign.name}`}</DialogTitle>
        {isLoading ? (
          loadingRender()
        ) : (
          <DialogContent>
            <Typography variant="h6">Subscribed Users</Typography>
            <List sx={{ maxHeight: 260, overflowY: "scroll" }}>
              {doneSettingState &&
                subscribedUsers?.map((user) => <SubscribersListItem key={user.user_id} user={user} />)}
            </List>
            {isSuccess && setToast("Successfully updated user campaign settings", 3000)}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={dialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </SubscribersDialogContext.Provider>
  )
}
