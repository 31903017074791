import { FC, useState } from "react"
import { TextField, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material"
import { useFetchIndustries, useCreateIndustry } from "@api/admin/industries"
import { SearchTextField } from "../../shared/SearchTextField"
import { CreateFolderModal } from "../../Bookmarks/CreateFolderModal"
import { IndustryDataGrid } from "./IndustryDataGrid"
import { useLocation } from "react-router-dom"
import { NaicsCodesModal } from "./NaicsCodesModal"
import { AssociatedUserModal } from "./AssociatedUserModal"
import { RecommendedCampaignsModal } from "./RecommendedCampaignsModal"
import { IndustryBookmarkFolderModal } from "./IndustryBookmarkFolderModal"

export const Industries: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const { data: industries, isLoading: indexLoading } = useFetchIndustries(params)
  const [search, setSearch] = useState("")
  const [name, setName] = useState("")
  const [open, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
    setName("")
  }

  const { mutate: createIndustry, isLoading: createIsLoading } = useCreateIndustry(close)

  return (
    <>
      <Dialog open={open} onClose={close} fullWidth>
        <DialogTitle>Create Industry</DialogTitle>
        <DialogContent sx={{ overflowY: "unset" }}>
          <TextField label="Industry Name" fullWidth value={name} onChange={(event) => setName(event.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <Button
            onClick={() => {
              createIndustry({ name })
              setName("")
            }}
            disabled={!name}
          >
            {createIsLoading ? "Loading" : "Create Industry"}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={1} marginTop={1}>
        <Button variant="outlined" onClick={() => setOpen(true)} sx={{ alignSelf: "flex-start" }}>
          Create Industry
        </Button>
        <SearchTextField
          label="Search Industries"
          size="small"
          sx={{ width: "33.333%" }}
          search={search}
          setSearch={setSearch}
        />
      </Stack>
      <IndustryDataGrid
        industries={industries?.industries || []}
        isLoading={indexLoading}
        totalCount={industries?.total}
      />
      <NaicsCodesModal />
      <RecommendedCampaignsModal />
      <IndustryBookmarkFolderModal />
      <AssociatedUserModal />
      <CreateFolderModal />
    </>
  )
}
