import { Dialog, DialogTitle, DialogContent, Typography, Box, Stack } from "@mui/material"
import { FC } from "react"

export const TrialOverModal: FC<{ open: boolean }> = ({ open }) => {
  return (
    <Dialog sx={{ ".MuiPaper-root": { height: 600, alignItems: "center" } }} open={open}>
      <DialogTitle>
        <div>
          <Typography variant="h1" sx={{ fontSize: 40 }}>
            {" "}
            Free Trial Complete{" "}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={15} sx={{ alignItems: "center" }}>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            <br></br>
            Your trial has ended.
            <br />
            <br />
            <a href="https://meetings.hubspot.com/jeremy-becker/jeremy-30-minutes-only"> Click here </a>
            to schedule a call with our sales team or email us at
            <a href="mailto: sales@cloverleaf.ai"> sales@cloverleaf.ai </a>
            to continue using the platform. Thanks!
          </Typography>
          <Box
            component="img"
            sx={{ width: 150 }}
            src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cl.square.clover.png"
          />
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
