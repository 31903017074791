import { Box } from "@mui/material"
import { FC, useState, createContext } from "react"
import { SearchTextField } from "../../../components/shared/SearchTextField"
import { useAdminQuery } from "../../../api/api"
import { TrialUser } from "../../../interfaces/trialUser"
import { DataTable } from "../../shared/DataTable"
import { columns } from "../../../tableColumns/trialUserColumns"

interface ContextData {
  ids: number[]
  reset: () => void
}

const contextData: ContextData = {
  ids: [],
  reset: () => {
    /* placeholder for typescript */
  },
}

export const TrialUserContext = createContext(contextData)

export const TrialContainer: FC = () => {
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [search, setSearch] = useState("")
  const { data: trialUsers } = useAdminQuery<TrialUser[]>(["trial_users"])

  return (
    <TrialUserContext.Provider value={{ ids: selectedUsers, reset: () => setSelectedUsers([]) }}>
      <SearchTextField
        label="Search Users"
        size="small"
        sx={{ width: "33.333%", marginTop: 2 }}
        setSearch={setSearch}
        search={search}
      />
      <Box flex="1">
        <DataTable
          data={trialUsers
            ?.filter((user) => user.email.includes(search))
            .map((user) => ({
              ...user,
              searchTerms: user.data.searchTerms.toString(),
            }))}
          selected={selectedUsers}
          setSelected={setSelectedUsers}
          columns={columns}
          columnKey="id"
        />
      </Box>
    </TrialUserContext.Provider>
  )
}
