import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { ProxyRegion } from "../../interfaces/proxyRegions"

export interface ProxyRegionParams {
  region: string
  abbreviation: string
  youtube_blocked: boolean
  granicus_blocked: boolean
  swagit_blocked: boolean
  active: boolean
}

const prefix = "/admin/proxy_regions"

export const useGetProxyRegions = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["proxies"], async () => {
    const response = await (await axiosInstance).get<ProxyRegion[]>(prefix)

    return response.data
  })
}

export const useCreateProxyRegion = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: ProxyRegionParams) => {
      return (await axiosInstance).post(prefix, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["proxies"])
      },
    },
  )
}

export const useUpdateProxyRegion = (id: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: ProxyRegionParams) => {
      return (await axiosInstance).put(`${prefix}/${id}`, params)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["proxies"])
      },
    },
  )
}

export const useDeleteProxyRegion = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: string[]) => {
      return (await axiosInstance).delete(`${prefix}/${id.join(",")}`)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["proxies"])
      },
    },
  )
}
