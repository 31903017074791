import { FC, useState, useContext, useEffect } from "react"
import { TextField, Stack, Button, Typography, Checkbox, FormControlLabel, Tooltip } from "@mui/material"
import { OrganizationWizardContext } from "./Context"
import { unpackSetters } from "../../../shared/unpackSetters"
import { IdentifierActionSelection } from "./IdentifierActionSelection"
import Loading from "../../../shared/Loading"
import { GeneralScraperDetailsContext } from "../OrganizationForm/Context"
import { GeneralScraperDetails } from "../../../../api/interfaces"
import { useGatherMeetingCandidates } from "../../../../api/admin/wizard"
import { gatherMeetingsParams } from "./searchParams"

export const MeetingIdentifier: FC = () => {
  const { value, setValue } = useContext(OrganizationWizardContext)
  const {
    separatedMeetingHtml,
    meetingIdentifier,
    meetingIdentifierIframe,
    url,
    navigationMethod,
    setStep,
    meetingBrowserWaitCode,
  } = value
  const { value: generalDetailsValue } = useContext(GeneralScraperDetailsContext)
  const { identifier_actions } = generalDetailsValue as GeneralScraperDetails
  const { setSeparatedMeetingHtml, setMeetingIdentifier, setMeetingIdentifierIframe, setMeetingBrowserWaitCode } =
    unpackSetters(value, setValue)
  const [searchOpts, setSearchOpts] = useState<string | undefined>(undefined)
  const { isLoading, isFetching, refetch } = useGatherMeetingCandidates(searchOpts, setSeparatedMeetingHtml)
  const [useSearch, setUseSearch] = useState(false)

  const search = () => {
    setSearchOpts(gatherMeetingsParams(value, identifier_actions))
    setUseSearch(!useSearch)
  }

  useEffect(() => {
    if (searchOpts === undefined) return
    refetch()
  }, [useSearch])

  return (
    <Stack spacing={1} marginX="20%">
      <Typography>{`URL: ${url}`}</Typography>
      {navigationMethod.includes("selenium") && (
        <Typography paddingTop={2} paddingBottom={1} variant="h3">
          Actions
        </Typography>
      )}
      {navigationMethod.includes("selenium") &&
        identifier_actions?.map((_identifierAction, index) => <IdentifierActionSelection index={index} key={index} />)}
      {navigationMethod.includes("selenium") && (
        <>
          <Stack direction="row" spacing={2}>
            <Tooltip title="This will be the class name of the div" placement="left" arrow>
              <TextField
                label="Meeting Browser Wait Code"
                size="small"
                helperText="@browser.div(class: 'media').wait_until(timeout: 30, &:exists?)"
                value={meetingBrowserWaitCode}
                onChange={(event) => setMeetingBrowserWaitCode(event.target.value)}
                sx={{ flex: 1 }}
              />
            </Tooltip>
          </Stack>
        </>
      )}
      <Stack direction="row" spacing={1}>
        <TextField
          label="Meeting Identifier"
          size="small"
          value={meetingIdentifier}
          onChange={(event) => setMeetingIdentifier(event.target.value)}
          sx={{ flex: 1 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={meetingIdentifierIframe}
              onChange={(event) => setMeetingIdentifierIframe(event.target.checked)}
            />
          }
          label="Iframe"
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <Button onClick={() => search()}>Try Meeting Identifier</Button>
          {(isLoading || isFetching) && <Loading />}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button onClick={() => setStep(0)}>Back</Button>
          <Button onClick={() => setStep(2)} disabled={navigationMethod !== "json" && !meetingIdentifier}>
            Next
          </Button>
        </Stack>
      </Stack>
      {separatedMeetingHtml.map((html) => (
        <Typography key={html}>{html}</Typography>
      ))}
    </Stack>
  )
}
