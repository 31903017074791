import { FC, useContext, useEffect, useState } from "react"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import { IconButton, Tooltip } from "@mui/material"
import { FetchedMeeting } from "../../../api/meetings"
import { BOOKMARK_EDITOR_MODAL_KEY } from "../../MeetingDetail/Bookmark/BookmarkEditor"
import { Meeting } from "../../../interfaces/meeting"
import { useOpenModalKey, useSetData } from "../../shared/OpenModalHook"
import { BookmarkContext } from "../../MeetingDetail/Shared/Context"

export const Bookmark: FC<{
  meeting?: FetchedMeeting | Meeting
  timestamp: string
  transcriptId: number | string
  keyword: string
}> = ({ meeting, timestamp, transcriptId, keyword }) => {
  const { transcriptIdsToBookmarkIds, setTranscriptIdsToBookmarkIds } = useContext(BookmarkContext)
  const openBookmarkModal = useOpenModalKey(BOOKMARK_EDITOR_MODAL_KEY)
  const [saved, setSaved] = useState(transcriptIdsToBookmarkIds[transcriptId]?.length > 0)
  const setDataOnModal = useSetData()

  const updateBookmarks = (newBookmarks: number[]) => {
    // Compare the new and old bookmarks and update the state
    setTranscriptIdsToBookmarkIds((prev) => {
      return {
        ...prev,
        [transcriptId]: newBookmarks,
      }
    })
  }

  const setBookmarkModalData = () => {
    setDataOnModal({
      meeting,
      timestamp,
      transcriptId,
      keyword,
      activeBookmarks: transcriptIdsToBookmarkIds[transcriptId] || [],
      updateBookmarks,
    })
  }

  useEffect(() => {
    setSaved(transcriptIdsToBookmarkIds[transcriptId]?.length > 0)
  }, [transcriptIdsToBookmarkIds[transcriptId], transcriptId])

  const icon = saved ? (
    <IconButton
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        setBookmarkModalData()
        openBookmarkModal()
      }}
      className="table-view-saved"
      color="primary"
      disabled={!meeting}
    >
      <StarIcon />
    </IconButton>
  ) : (
    <IconButton
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        setBookmarkModalData()
        openBookmarkModal()
      }}
      disabled={!meeting}
      className="table-view-add-to-save"
    >
      <StarBorderIcon />
    </IconButton>
  )
  return (
    <Tooltip title={"Bookmark"} placement="top">
      <span>{icon}</span>
    </Tooltip>
  )
}
