import { Typography, Select, MenuItem, Stack } from "@mui/material"
import { FC, useContext } from "react"
import { TimeDuration } from "../TimeDuration"
import { SearchFormTerms, SearchFormInput, SearchFormButton } from "./SearchFormComponents"
import { MeetingSearchFormContext } from "."
import { unpackSetters } from "../unpackSetters"
import { searchInputStyle } from "./searchTerms"

export const AdvancedSearch: FC = () => {
  const { searchFormData, setSearchFormData, addAdvancedTerm } = useContext(MeetingSearchFormContext)
  const { advancedInput, advancedTerms, proximity, exclude } = searchFormData
  const { setAdvancedInput, setAdvancedTerms, setProximity, setExclude } = unpackSetters(
    searchFormData,
    setSearchFormData,
  )
  return (
    <Stack className="advanced-search-inputs" direction={{ xs: "column", sm: "row" }} spacing={{ xs: 1, sm: 2 }}>
      <Stack width={{ xs: "100%", md: "80%" }}>
        <Stack direction="column">
          <Typography alignSelf="flex-start" marginLeft={{ xs: 1, sm: 0 }}>
            Anchor Term:
          </Typography>
          <Stack
            // flex="1"
            sx={{
              ...searchInputStyle,
              paddingY: "7px",
              paddingX: "15px",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <SearchFormTerms
                terms={advancedTerms}
                setTerms={setAdvancedTerms}
                sx={{ display: { xs: "none", sm: "flex" } }}
              />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                flexGrow="1"
                flexShrink="0"
                height={{ sm: "40px" }}
              >
                <SearchFormInput
                  input={advancedInput}
                  inputs={advancedTerms}
                  setInput={setAdvancedInput}
                  addTerm={addAdvancedTerm}
                  placeholder={advancedTerms.size ? "" : "Enter a search term/phrase"}
                  isAdvancedSearch
                  toolTipTitle={advancedTerms.size ? "One term per search" : undefined}
                />
                {advancedInput.trim() && <SearchFormButton addTerm={addAdvancedTerm} />}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={{ xs: 1, sm: 2 }}>
        <Stack>
          <Typography marginLeft={{ xs: 1, sm: 0 }}>Time Range:</Typography>
          <TimeDuration proximity={proximity} setProximity={setProximity} />
        </Stack>
        <Stack width="144px">
          <Typography>&nbsp;</Typography>
          <Select
            className="advanced-search-exclude-include"
            value={exclude ? "exclude" : "include"}
            onChange={(event) => setExclude(event.target.value === "exclude")}
          >
            <MenuItem value="include">Must Include</MenuItem>
            <MenuItem value="exclude">Must Exclude</MenuItem>
          </Select>
        </Stack>
      </Stack>
      <SearchFormTerms
        terms={advancedTerms}
        setTerms={setAdvancedTerms}
        sx={{
          display: { xs: "flex", sm: "none" },
          marginTop: "4px !important",
        }}
      />
    </Stack>
  )
}
