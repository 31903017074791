import { FC, useMemo } from "react"
import { createFilterOptions } from "@mui/material"
import { GeographySelector } from "../shared/Selectors/Single/Geographies"
import { useFetchGeographiesAdmin } from "@api/admin/geographies"
import { County, City, State } from "@interfaces/geography"
import { BasicGeoType } from "../shared/Selectors/interface"

export const LocationInput: FC<{
  title: string
  type: "cities" | "states" | "counties"
  organization: any
  setLocationParams?: any
  locationParams?: any
}> = ({ title, type, locationParams, setLocationParams }) => {
  const { data: geographiesData } = useFetchGeographiesAdmin()

  const geographies = useMemo(() => {
    const geographies = {
      states: [] as BasicGeoType[],
      counties: [] as BasicGeoType[],
      cities: [] as BasicGeoType[],
      organizations: [] as BasicGeoType[],
    }

    geographiesData?.states?.forEach((state: State) => geographies.states.push(state))
    geographiesData?.cities?.forEach((city: City) => geographies.cities.push(city))
    geographiesData?.counties?.forEach((county: County) => geographies.counties.push(county))
    return geographies
  }, [geographiesData])

  const options = useMemo(() => {
    const geographyOptions = { ...geographies }
    if (type === "cities" && geographyOptions["cities"]) {
      if (locationParams.states) {
        geographyOptions[type] = geographyOptions["cities"].filter((geog) => geog.state_id === locationParams.states)
      }
      if (locationParams.counties) {
        geographyOptions[type] = geographyOptions["cities"].filter((geog) => geog.county_id === locationParams.counties)
      }
    } else if (type === "counties" && geographyOptions["counties"]) {
      if (locationParams.states) {
        geographyOptions[type] = geographyOptions["counties"].filter((geog) => geog.state_id === locationParams.states)
      }
      if (locationParams.cities) {
        const county_ids = [] as number[]
        geographyOptions["cities"].forEach((city) => {
          if (city.id === locationParams.cities && city.county_id) {
            county_ids.push(city.county_id)
          }
        })
        geographyOptions[type] = geographyOptions["counties"].filter((geog) => county_ids.includes(Number(geog.id)))
      }
    }
    return geographyOptions
  }, [locationParams, geographies])

  if (geographiesData === undefined) {
    return <></>
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  })

  return (
    <GeographySelector
      options={options}
      label={title}
      type={type}
      filterParams={locationParams}
      setFilterParams={setLocationParams}
      filterOptions={filterOptions}
    />
  )
}
