import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"

export interface Contact {
  id: number
  name: string
  role: string
  title: string
  email: string | null
  phone: string | null
  removed_at: Date | null
}

export const useFetchContacts = (organizationId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [`contacts_${organizationId}`],
    async () => {
      const response = await (await axiosInstance).get<Contact[]>(`/organizations/${organizationId}/contacts`)

      return response.data
    },
    {
      enabled: typeof organizationId === "number",
    },
  )
}
