import { Dispatch, FC, SetStateAction, useMemo, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material"
import { useCreateChannelType, useFetchChannelTypes, useUpdateChannelType } from "../../../api/channel_types"
import { useCloseModalKey, useModalKey, useOpenModalKey } from "../../shared/OpenModalHook"
import { unpackSetters } from "../../shared/unpackSetters"

const modalKey = "ADMIN_CREATE_UPDATE_CHANNEL_TYPE"

export const UpdateChannelTypeMenuItem: FC<{ selectedTypes: number[]; formType: string }> = ({
  selectedTypes,
  formType,
}) => {
  const openModalKey = useOpenModalKey(modalKey)
  let text = "Update Channel Type"
  let disabled = selectedTypes.length !== 1

  if (formType.toUpperCase() === "CREATE") {
    text = "New Channel Type"
    disabled = selectedTypes.length !== 0
  }

  return (
    <MenuItem
      onClick={() => {
        openModalKey()
      }}
      disabled={disabled}
    >
      {text}
    </MenuItem>
  )
}

export const EditChannelTypeDialog: FC<{
  channelTypeState: { selectedTypes: number[] }
  setChannelTypeState: Dispatch<SetStateAction<{ selectedTypes: number[] }>>
}> = ({ channelTypeState, setChannelTypeState }) => {
  const { selectedTypes } = channelTypeState
  const { setSelectedTypes } = unpackSetters(channelTypeState, setChannelTypeState)
  const [nameInput, setNameInput] = useState("")
  const { data: channelTypesData } = useFetchChannelTypes()

  const { mutate: createChannelType } = useCreateChannelType()
  const { mutate: updateChannelType } = useUpdateChannelType()

  const formType = selectedTypes.length === 1 ? "Update" : "New"
  const open = useModalKey(modalKey)
  const closeModalKey = useCloseModalKey(modalKey)

  const close = () => {
    closeModalKey()
  }

  const selectedType = useMemo(() => {
    if (selectedTypes.length === 1 && channelTypesData) {
      const findType = channelTypesData.find((r) => r.id === selectedTypes[0])
      if (findType) {
        setNameInput(findType.name)
        return findType
      }
    }
    setNameInput("")
    return undefined
  }, [selectedTypes, channelTypesData])

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle sx={{ overflowY: "unset" }}>
        {formType}
        &nbsp;Channel Type
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={10} marginTop={1} padding={1}>
            <TextField
              value={nameInput}
              onChange={(event: any) => setNameInput(event.target.value)}
              id="channel-type-name-input"
              label="Name"
              fullWidth
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              formType === "New"
                ? createChannelType({ name: nameInput })
                : updateChannelType({
                    id: selectedType!.id,
                    name: nameInput,
                  })
              setNameInput("")
              setSelectedTypes([])
              close()
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              close()
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
