import { FC, useState, useEffect, useMemo } from "react"
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Stack,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
} from "@mui/material"
import { FixedSizeList as List } from "react-window"
import { useFetchAdminRoles } from "../../../../../api/roles"
import { useFetchUsers } from "../../../../../api/admin/users"
import { useAddUsersToUserOrganization } from "../../../../../api/admin/user_organizations"
import { CsvUser } from "./parseRowFunctions"

export const VerifyCsvData: FC<{
  userOrganizationId: number | string
  users: CsvUser[]
  onSuccess: (data?: { id: number; email: string }[]) => void
}> = ({ userOrganizationId, users, onSuccess }) => {
  const [csvUsers, setCsvUsers] = useState<CsvUser[]>(users)
  useEffect(() => setCsvUsers(users), [users])
  const setUser = (user: CsvUser, index: number) => {
    const csvUsersCopy = [...csvUsers]
    csvUsersCopy.splice(index, 1, user)
    setCsvUsers(csvUsersCopy)
  }

  const { data: adminRoles } = useFetchAdminRoles()

  const { data: userList } = useFetchUsers()
  const usersInOrg = useMemo(() => {
    const set = new Set()
    if (userList) {
      userList.forEach(({ email, user_organization_id }: { email: string; user_organization_id: number }) => {
        if (user_organization_id === userOrganizationId) {
          set.add(email)
        }
      })
    }
    return set
  }, [userList])

  const { mutate: addUsersToUserOrganization, isLoading: addUsersIsLoading } = useAddUsersToUserOrganization(
    userOrganizationId,
    onSuccess,
  )

  const handleSubmit = () => {
    // Convert the csvUsers to the correct format of role_ids
    const usersToAdd = csvUsers.map(({ email, role_id, requires_signals_notifications }) => ({
      email,
      role_ids: role_id ? [role_id] : [],
      requires_signals_notifications,
    }))
    addUsersToUserOrganization(usersToAdd)
  }

  return (
    <>
      <DialogContent>
        <List height={400} itemCount={csvUsers.length} itemSize={100} width={550}>
          {({ index, style }) => {
            const userData = csvUsers[index]
            return (
              <Box sx={style}>
                <Card sx={{ marginLeft: "2px", marginRight: "2px" }}>
                  <CardContent sx={{ paddingBottom: "16px !important" }}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        value={userData.email}
                        size="small"
                        label="Email"
                        onChange={(event) => setUser({ ...csvUsers[index], email: event.target.value }, index)}
                        error={usersInOrg.has(userData.email.toLowerCase())}
                        helperText={usersInOrg.has(userData.email.toLowerCase()) ? "Email already in Org" : ""}
                        sx={{ flex: "1" }}
                      />
                      <FormControl>
                        <InputLabel>Role</InputLabel>
                        <Select
                          value={userData.role_id}
                          size="small"
                          label="Role"
                          onChange={(event) =>
                            setUser({ ...csvUsers[index], role_id: event.target.value as number }, index)
                          }
                        >
                          {adminRoles?.roles.map(({ id, name }: { id: number; name: string }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        {userData.roleHelperText && <FormHelperText>{userData.roleHelperText}</FormHelperText>}
                      </FormControl>
                      <FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={userData.requires_signals_notifications}
                              onChange={(event) =>
                                setUser(
                                  { ...csvUsers[index], requires_signals_notifications: event.target.checked },
                                  index,
                                )
                              }
                            />
                          }
                          label="Notifications"
                        />
                        {userData.notificationHelperText && (
                          <FormHelperText>{userData.notificationHelperText}</FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            )
          }}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>{addUsersIsLoading ? "Loading..." : "Add"}</Button>
      </DialogActions>
    </>
  )
}
