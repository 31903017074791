import { useQuery } from "react-query"
import { useAuthAxios } from "@api/axiosInstance"
import { NaicsCode } from "@src/interfaces/naicsCode"

const prefix = "admin/naics_codes"

export const useFetchNaicsCodes = () => {
  const axiosInstance = useAuthAxios()
  return useQuery(["naicsCodes"], async () => {
    const response = await (await axiosInstance).get<NaicsCode[]>(prefix)

    return response.data
  })
}

export const useFetchNaicsCodesMap = () => {
  const fetchData = useFetchNaicsCodes()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((naicsCode: NaicsCode) => [naicsCode.id, naicsCode])),
    }
  }
  return fetchData
}
