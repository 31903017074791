import { FC, useState } from "react"
import { Box, Stack, Button, MenuItem, Select, Typography, Paper, FormControl, useMediaQuery } from "@mui/material"
import { ChartUp, Message, Gear } from "@assets/find_a_lead/icons"
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates"
import { FetchIndustryForSearch, useFetchIndustriesForSearch } from "@api/promotions/industries"
import { theme } from "@src/theme"
import { useHistory } from "react-router-dom"
import { useToast } from "@components/shared/ToastHook"
import { TestimonialComponent } from "@src/components/Promo/testimonial"

export const FindALeadPromotionPage: FC = () => {
  const { data, isLoading } = useFetchIndustriesForSearch()
  const [chosenIndustry, setChosenIndustry] = useState<FetchIndustryForSearch>()
  const history = useHistory()
  const setToast = useToast()

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const handleChosenIndustry = (value: string) => {
    // Find the industry in the data array
    const industry = data?.find((industry) => industry.id === parseInt(value))
    // Set the chosen industry to the industry name
    setChosenIndustry(industry)
  }

  const handleSubmit = () => {
    // Redirect to the industry page with the chosen industry id
    if (!chosenIndustry) {
      setToast("Please choose an industry.", undefined, undefined, "error")
      return
    }
    history.push(`/find-a-lead/${chosenIndustry.id}`)
  }

  return (
    <Stack
      width={"100%"}
      sx={{ [theme.breakpoints.down("md")]: { paddingTop: 7 }, [theme.breakpoints.up("sm")]: { paddingTop: 8 } }}
      justifyContent={"center"}
      display={"flex"}
    >
      <Box bgcolor={"#4cb2dc"} alignItems={"center"} paddingBottom={5} paddingX={2}>
        <Stack
          width={"100%"}
          direction={{ sm: "column", md: "row" }}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          marginTop={6}
          spacing={4}
        >
          {/* Choose Your Industry Section */}
          <Paper
            elevation={3}
            sx={{
              paddingX: 1,
              paddingY: 6,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: 4,
              backgroundColor: "rgb(205, 225, 240)",
              maxWidth: "300px",
              width: isMediumScreen ? "80%" : "40%",
              height: "100%",
              marginBottom: isMediumScreen ? 4 : 0,
            }}
          >
            <Stack direction={"column"} alignItems={"center"} spacing={2}>
              <Typography variant="h4" color="black">
                Choose Your Industry
              </Typography>
              <Box width="80%">
                <Typography
                  marginX="auto"
                  variant="body1"
                  color={(theme) => theme.palette.grey[800]}
                  textAlign={"center"}
                >
                  See Leads from Past 14 Days
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    value={chosenIndustry ? chosenIndustry.id.toString() : ""}
                    size="small"
                    displayEmpty
                    sx={{ bgcolor: "white", borderRadius: 1, mt: 2, mb: 1 }}
                    onChange={(e) => handleChosenIndustry(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Choose an Industry
                    </MenuItem>
                    {isLoading && (
                      <MenuItem value="" disabled>
                        Loading...
                      </MenuItem>
                    )}
                    {data &&
                      data.length > 0 &&
                      data.map((industry) => (
                        <MenuItem key={industry.id} value={industry.id.toString()}>
                          {industry.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Button variant="contained" sx={{ bgcolor: "black", color: "white" }} onClick={handleSubmit}>
                <TipsAndUpdatesIcon fontSize="small" />
                &nbsp; See Your Leads
              </Button>
            </Stack>
          </Paper>

          {/* Get Ahead of the RFP Section */}
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: 2,
              backgroundColor: "inherit",
              height: "100%",
              width: "fit-content",
              paddingLeft: 4,
              marginX: "auto",
            }}
          >
            <Typography
              variant="h1"
              color="white"
              fontSize={isSmallScreen ? 34 : 38}
              marginBottom={1}
              width={"fit-content"}
            >
              Be the First to Discover {!isSmallScreen && <br />}Government Contracts
            </Typography>
            <Box width={"90%"}>
              <Typography variant="body1" width={"fit-content"}>
                Unlock Your first 3 pre-RFP opportunities for free with Cloverleaf AI.
              </Typography>
              <Typography variant="body2" marginBottom={3} width={"fit-content"}>
                No credit card or personal info needed.
              </Typography>
              <Box sx={{ maxWidth: "400px", width: "100%" }}>
                <TestimonialComponent
                  firstSection="Sharon Noyce"
                  secondSection="Business Intelligence Group Manager, Kiewit"
                  thirdSection="Cloverleaf AI allows our people on the ground to work with the best and most timely intel. This is crucial for our winning multi-million dollar projects."
                />
              </Box>
            </Box>
          </Paper>
        </Stack>
      </Box>

      <Box bgcolor={"white"} display={"flex"} flexDirection={"column"} padding={4}>
        <Box width="100%" display="flex" justifyContent="center">
          <Typography variant="h1" fontWeight={900}>
            How It Works: Know, Act, Win
          </Typography>
        </Box>
        <Stack
          direction={{ sm: "column", md: "row" }}
          justifyContent={"space-evenly"}
          marginTop={2}
          alignItems={"center"}
        >
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={{ sm: "80%", md: "20%" }}>
            <Message fontSize="large" style={{ width: "40%", height: "40%" }} />
            <Typography variant="h4" color="rgb(0, 179, 224)">
              Know
            </Typography>
            <Typography variant="body1" textAlign={"center"}>
              Cloverleaf AI delivers government meeting clips of officials discussing their projects, budgets, and why
              these projects are vital to their region.
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={{ sm: "80%", md: "20%" }}>
            <Gear fontSize="large" style={{ width: "40%", height: "40%" }} />
            <Typography variant="h4" color="rgb(76, 110, 136)">
              Act
            </Typography>
            <Typography variant="body1" textAlign={"center"}>
              Our platform provides contact information so you can get in touch with government officials quickly.
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={{ sm: "80%", md: "20%" }}>
            <ChartUp fontSize="large" style={{ width: "40%", height: "40%" }} />
            <Typography variant="h4" color="rgb(205, 225, 240)">
              Win
            </Typography>
            <Typography variant="body1" textAlign={"center"}>
              Our real-time data puts you in the pole position. Simply put, you'll win more when you are there first.
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box flexGrow={1} bgcolor={"#4cb2dc"} width={"100%"} minHeight={"40px"} />
    </Stack>
  )
}
