import { Button } from "@mui/material"
import { fetchVideoJobs } from "@src/api/admin/content-acquisition/videoJobs"
import { useAuthAxios } from "@src/api/axiosInstance"
import { FC } from "react"

export const ViewCloudRunJobLogs: FC<{ videoId: number }> = ({ videoId }) => {
  const axiosInstance = useAuthAxios()

  const goToLogs = async () => {
    const jobs = await fetchVideoJobs(axiosInstance, videoId)
    if (!jobs || jobs.length === 0) return
    const mostRecentJob = jobs[jobs.length - 1]
    const a = document.createElement("a")
    a.target = "_blank"
    a.href = `https://console.cloud.google.com/logs/query;query=labels.%22run.googleapis.com%2Fexecution_name%22%20%3D%20%22${mostRecentJob.execution_id}%22?project=cloverleaf-326417`
    a.click()
  }

  return <Button onClick={() => goToLogs()}>View Job Log</Button>
}
