import { FC, useMemo, SetStateAction, Dispatch } from "react"
import { Autocomplete, TextField, Box, SxProps } from "@mui/material"
import { useFetchUsers } from "../../../api/admin/users"

export const UserSelector: FC<{
  userId: number | null
  setUserId: Dispatch<SetStateAction<number | null>>
  sx?: SxProps
}> = ({ userId, setUserId, sx }) => {
  const { data: users } = useFetchUsers()
  const usersMap = useMemo(() => {
    const map = new Map(users?.map((user) => [user.id, user]))
    return map
  }, [users])

  return (
    <Autocomplete
      size="small"
      value={userId}
      onChange={(_, newValue) => setUserId(newValue)}
      options={Array.from(usersMap.keys())}
      sx={sx}
      getOptionLabel={(option) => usersMap.get(option)?.email || ""}
      renderInput={(params) => <TextField {...params} label="User" />}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option}>
          {usersMap.get(option)?.email}
        </Box>
      )}
    />
  )
}
