import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"
import { useMutation } from "react-query"

const prefix = "promotions"

interface ErrorResponse {
  response: Response
}

interface Response {
  status: number
}

export const useCreateFreeUser = (onResponse: (msg: string) => void) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useMutation(
    async (email: string) => {
      return (await axiosInstance).post(`/${prefix}/users`, {
        email: email,
      })
    },
    {
      onSuccess: () => {
        const msg = "You have successfully signed up for a free account!"
        onResponse(msg)
      },
      onError: (error: ErrorResponse) => {
        let msg: string
        if (error.response.status === 422) {
          msg = "This email is already in use. Please try another email."
        } else {
          msg = "An error occurred. Please try again later."
        }
        onResponse(msg)
      },
    },
  )
}
