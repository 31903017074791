import { useQuery } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { Proxy } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"

const resourcePath = "/content-acquisition/proxies"

export const useFetchProxies = (params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(paramBuilder.toQueryKey("proxy-provider-proxies"), async () => {
    const response = await (await axiosInstance).get<Proxy[]>(uri)

    return response.data
  })
}
