import { Typography } from "@mui/material"
import { FC } from "react"
import { useGetScraperUat } from "../../../api/admin/uat"

export const ScraperUat: FC = () => {
  const { data: scraperUatData } = useGetScraperUat()
  return (
    <>
      <Typography variant="h6">Scraper UAT - Run 1 Org for the following Org Ids</Typography>
      {scraperUatData?.map((test, index) => <Typography key={index}>{test}</Typography>)}
    </>
  )
}
