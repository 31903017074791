import { FC, useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Button, Divider, Typography, Tab, Tabs } from "@mui/material"
import { NewPermissionContainer } from "./NewPermissionContainer"
import { PermissionsList } from "./PermissionsList"
import { CampaignList } from "./Campaigns/CampaignList"
import { OpportunityCampaigns } from "./OpportunityCampaigns"
import { MetricsContainer } from "../../Home/Metrics/MetricsContainer"
import { Users } from "./UsersSection"
import { useFetchUserOrganization } from "../../../api/admin/user_organizations"
import { AugmentedStatus } from "../../shared/LoadingEllipsis"
import { UserOrganizationPageHeader } from "./UserOrganizationPageHeader"
import { Page as SearchTerms } from "./SearchTerms/Page"
import { CreditUsages } from "./Credits/CreditUsages"

export const UserOrganizationPage: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: userOrganization, status } = useFetchUserOrganization(userOrganizationId)
  const [openPermissions, setOpenPermissions] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!userOrganization) {
    return (
      <Typography>
        <AugmentedStatus status={status} />
      </Typography>
    )
  }

  return (
    <>
      <UserOrganizationPageHeader />
      <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)}>
        <Tab label="Users" />
        <Tab label="Campaigns" />
        <Tab label="Opportunity Campaigns" />
        <Tab label="Search Terms" />
        <Tab label="Metrics" />
        <Tab label="Permissions" />
        <Tab label="Credits" />
      </Tabs>
      <Divider sx={{ marginBottom: 1 }} />
      {tabIndex === 0 && <Users />}
      {tabIndex === 1 && <CampaignList />}
      {tabIndex === 2 && <OpportunityCampaigns />}
      {tabIndex === 3 && <SearchTerms userOrgId={userOrganization.id} />}
      {tabIndex === 4 && <MetricsContainer userOrgId={userOrganization.id} containerMarginTop={5} />}
      {tabIndex === 5 && (
        <>
          <Button onClick={() => setOpenPermissions(true)} size="small" variant="contained">
            Add Organization Permission
          </Button>
          <PermissionsList />
        </>
      )}
      {tabIndex === 6 && (
        <>
          <CreditUsages />
        </>
      )}
      <NewPermissionContainer
        open={openPermissions}
        onClose={() => setOpenPermissions(false)}
        userOrganization={userOrganization}
      />
    </>
  )
}
