import { Avatar, IconButton, Skeleton, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { ClipShareBookmarkView } from "../../../interfaces/clipShare"
import { shareModalKey } from "."
import { useCloseModalKey } from "../OpenModalHook"
import { useToast } from "../ToastHook"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useNavigatorShareFormatBrowser } from "../../../config/clipShareConfig"

export const SlackButton: FC<{ clipShare: ClipShareBookmarkView | undefined }> = ({ clipShare }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const navigatorShareFormatBrowser = useNavigatorShareFormatBrowser(meetingId)
  const closeModal = useCloseModalKey(shareModalKey)
  const setToast = useToast()

  const openSlack = () => {
    if (clipShare) {
      const slackUrl = "slack://open"
      const cb = navigator.clipboard

      const text = navigatorShareFormatBrowser({
        shareId: clipShare.id,
        channelName: clipShare.organization_name,
        meetingTitle: clipShare.meeting.title,
        clipText: clipShare.raw_text,
      })

      cb.writeText(text).then(() => {
        window.open(slackUrl)
        closeModal()
        setToast("Clip Copied", 1750)
      })
    }
  }

  if (!clipShare) return <Skeleton variant="circular" width="60px" height="60px" />

  return (
    <IconButton
      sx={{
        borderRadius: 1,
        backgroundColor: "#F9F9F9",
        "&:active": {
          backgroundColor: "neutral.main",
        },
      }}
      size="large"
      disableRipple
      onClick={openSlack}
    >
      <Stack direction="column" alignItems="center">
        <Avatar
          src="https://storage.googleapis.com/cloverleaf-ai-public-assets/slack.webp"
          alt="twitter"
          aria-label="email"
          variant="circular"
          sx={{
            backgroundColor: "white",
            height: "60px",
            width: "60px",
          }}
        />
        <Typography variant="h5" sx={{ color: "black" }}>
          Slack
        </Typography>
      </Stack>
    </IconButton>
  )
}
