import { Chip, Stack, SxProps, Theme, Typography } from "@mui/material"
import { FC } from "react"
import { Campaign } from "../../../interfaces/campaign"
import { Campaign as HomeCampaign } from "../../Home/interface"
import { ChannelType } from "../../../interfaces/channelType"
import { City, County, State } from "../../../interfaces/geography"
import { Organization } from "../../../interfaces/organization"

interface GeographiesMap {
  cities: Map<number, City>
  counties: Map<number, County>
  states: Map<number, State>
}

export const CampaignTileBase: FC<{
  campaign: Campaign | HomeCampaign
  limit?: number
  organizationsMap?: Map<number, Organization> // these are expensive to calculate and
  geographiesMap?: GeographiesMap // access in every component
  channelTypesMap?: Map<number, ChannelType>
  sx?: SxProps<Theme>
}> = ({ campaign, limit, organizationsMap, geographiesMap, channelTypesMap, sx }) => {
  let filterString = ""
  const terms = [...(campaign.data.searchTerms || []), ...(campaign.data.mustIncludeTerms || [])].slice(0, limit)
  if (campaign.data.filterParams.organizations.length && organizationsMap) {
    filterString += campaign.data.filterParams.organizations
      .slice(0, limit)
      .map((id) => organizationsMap.get(id)?.name)
      .join(", ")
  }
  if (campaign.data.filterParams.channel_types?.length && channelTypesMap) {
    if (filterString) {
      filterString += " in "
    }
    filterString += campaign.data.filterParams.channel_types
      .slice(0, limit)
      .map((id) => channelTypesMap.get(id)?.name)
      .join(", ")
  }
  if (geographiesMap) {
    if (campaign.data.filterParams.cities?.length) {
      if (filterString) {
        filterString += " in "
      }
      filterString += campaign.data.filterParams.cities
        .slice(0, limit)
        .map((id) => geographiesMap.cities.get(id)?.name)
        .join(", ")
    }
    if (campaign.data.filterParams.counties?.length) {
      if (filterString) {
        filterString += " in "
      }
      filterString += campaign.data.filterParams.counties
        .slice(0, limit)
        .map((id) => geographiesMap.counties.get(id)?.name)
        .join(", ")
    }
    if (campaign.data.filterParams.states?.length) {
      if (filterString) {
        filterString += " in "
      }
      filterString += campaign.data.filterParams.states
        .slice(0, limit)
        .map((id) => geographiesMap.states.get(id)?.code)
        .join(", ")
    }
  }
  return (
    <Stack
      spacing={0.5}
      position="relative"
      sx={{
        maskImage: "linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255, 255) 36px)",
        ...sx,
      }}
    >
      <Typography overflow="hidden" whiteSpace="nowrap" variant="h5" marginBottom={1}>
        {campaign.name}
      </Typography>
      <Stack direction="row" spacing={0.5} width="100%" overflow="hidden">
        {terms.map((term, index) => (
          <Chip label={term} key={term + campaign.id + index} />
        ))}
      </Stack>
      <Typography
        variant="body2"
        overflow="hidden"
        whiteSpace="nowrap"
        sx={
          filterString
            ? undefined
            : {
                opacity: "0",
                pointerEvents: "none",
              }
        }
      >
        {filterString || "spacer"}
      </Typography>
    </Stack>
  )
}
