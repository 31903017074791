import { FC, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { EditLocationForm } from "./EditLocationForm"
import { getSingular } from "../../../config/geographyAdminConfig"

export const EditLocationModal: FC<{
  open: boolean
  onClose: any
  type: string
  updateGeography?: any
  createGeography?: any
  selectedGeography: any
  onActionComplete: any
  formType: any
}> = ({ open, onClose, updateGeography, createGeography, type, selectedGeography, onActionComplete, formType }) => {
  const [nameInput, setNameInput] = useState(selectedGeography?.name)
  const [filterParams, setFilterParams] = useState({
    cities: [],
    counties: selectedGeography?.county_id,
    states: selectedGeography?.state_id,
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {formType}
        {selectedGeography?.name || getSingular(type)}
      </DialogTitle>
      <DialogContent>
        <EditLocationForm
          type={type}
          nameInput={nameInput}
          setNameInput={setNameInput}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        <DialogActions>
          <Button
            onClick={() => {
              formType === "New"
                ? createGeography({
                    name: nameInput,
                    state_id: filterParams["states"],
                    county_id: filterParams["counties"],
                    type,
                  })
                : updateGeography({
                    name: nameInput,
                    state_id: filterParams["states"],
                    county_id: filterParams["counties"],
                    geography_id: selectedGeography?.id,
                    type,
                  })
              onClose()
              onActionComplete()
            }}
          >
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
