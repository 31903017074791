import { FetchedCampaign } from "../api/admin/user_organizations"
import { Campaign } from "../interfaces/campaign"

export const tranformFetchedToCampaign = (campaign: FetchedCampaign) => {
  return {
    assignees: campaign.assignees,
    data: campaign.data,
    email_cadence: "Unsubscribed",
    id: campaign.id,
    name: campaign.name,
    user: campaign.user,
    user_id: campaign.user_id,
  } as Campaign
}
