import { Dispatch, SetStateAction, createContext } from "react"
import { ScraperAction } from "./ActionCard"
import { IdentifierAction } from "../../../../interfaces/organization"

export const generateSimpleActions = (titleIdentifier: string, titleIndex: number): ScraperAction[] => [
  {
    type: "EXTRACT_DATE_MDY",
    identifier: "",
    index: 0,
    regex: false,
  },
  {
    type: "EXTRACT_TITLE",
    identifier: titleIdentifier,
    index: titleIndex,
    regex: false,
  },
  {
    type: "EXTRACT_VIDEO_URL",
    identifier: "",
    index: 0,
    regex: false,
  },
]

export interface NewOrganization {
  name: string
  url: string
  additionalUrls: string[]
  navigationMethod: "uri" | "selenium" | "zyte" | "json"
  head: boolean
  identifierActions: IdentifierAction[]
  meetingIdentifier: string
  meetingIdentifierRegex: boolean
  meetingIdentifierIframe: boolean
  meetingIdentiferValue?: boolean
  separatedMeetingHtml: string[]
  titleIdentifier: string
  titleIndex: number
  scraperActions: ScraperAction[]
  setStep: Dispatch<SetStateAction<number>>
  meetingBrowserWaitCode: string | undefined
  jsonReplaceCode: string
}

export const OrganizationWizardContext = createContext<{
  value: NewOrganization
  setValue: Dispatch<SetStateAction<NewOrganization>>
}>({
  value: {
    name: "",
    url: "",
    additionalUrls: [],
    navigationMethod: "uri",
    head: false,
    identifierActions: [
      {
        identifierAction: "",
        identifierActionSelection: "",
      },
    ],
    meetingIdentifier: "",
    meetingIdentifierRegex: false,
    meetingIdentifierIframe: false,
    meetingIdentiferValue: false,
    separatedMeetingHtml: [],
    titleIdentifier: "",
    titleIndex: 0,
    scraperActions: generateSimpleActions("", 0),
    setStep: () => {
      /* placeholder for Typescript */
    },
    meetingBrowserWaitCode: undefined,
    jsonReplaceCode: "",
  },
  setValue: () => {
    /* placeholder for Typescript */
  },
})
