import { Box, Typography } from "@mui/material"
import { FC } from "react"

export interface TestimonialProps {
  firstSection: string
  secondSection: string
  thirdSection: string
}

export const TestimonialComponent: FC<TestimonialProps> = ({ firstSection, secondSection, thirdSection }) => {
  return (
    <Box sx={{ padding: 2, paddingTop: 1, bgcolor: "rgba(255, 255, 255, 0.2)", borderRadius: 4 }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          fontSize: "70px",
          lineHeight: "1",
          margin: 0,
          padding: 0,
          color: "#cde1f0",
          height: "40px",
        }}
      >
        &ldquo;
      </Typography>

      <Typography variant="h6" color="white">
        {firstSection}
      </Typography>
      <Typography variant="body2" color="#cde1f0">
        {secondSection}
      </Typography>
      <Typography variant="body2" color="white">
        {thirdSection}
      </Typography>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: "black",
            height: "3px",
          }}
          flexGrow={1}
        ></Box>
        <Typography
          variant="h4"
          sx={{
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: "50px",
            lineHeight: "1",
            margin: 0,
            padding: 0,
            color: "#cde1f0",
            height: "30px",
          }}
        >
          &rdquo;
        </Typography>
      </Box>
    </Box>
  )
}
