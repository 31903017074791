import { FC } from "react"
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Box } from "@mui/material"
import { useFetchUserSubscriptions } from "../../../api/subscriptions"
import Loading from "../../shared/Loading"

export const SubscriptionsModal: FC<{
  open: boolean
  onClose: any
  user: any
}> = ({ open, onClose, user }) => {
  const { data: userSubscriptions } = useFetchUserSubscriptions(user.id)

  if (userSubscriptions === undefined) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <Loading />
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{`${user.email}'s Subscriptions`}</DialogTitle>
      <DialogContent>
        <Grid container marginTop={2} spacing={2} direction="column">
          <Grid item xs={12}>
            <Typography variant="h6">Daily</Typography>
            {userSubscriptions.subscriptions
              .filter((sub: any) => sub.email_cadence === "Daily")
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((sub: any) => (
                <Box key={sub.id} display="flex" justifyContent="space-between">
                  <Typography>{sub.name}</Typography>
                  <Typography variant="body2">{`(${sub.creator?.email})`}</Typography>
                </Box>
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Weekly</Typography>
            {userSubscriptions.subscriptions
              .filter((sub: any) => sub.email_cadence === "Weekly")
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((sub: any) => (
                <Box key={sub.id} display="flex" justifyContent="space-between">
                  <Typography>{sub.name}</Typography>
                  <Typography variant="body2">{`(${sub.creator?.email})`}</Typography>
                </Box>
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Unsubscribed</Typography>
            {userSubscriptions.subscriptions
              .filter((sub: any) => sub.email_cadence === "Unsubscribed")
              .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((sub: any) => (
                <Box key={sub.id} display="flex" justifyContent="space-between">
                  <Typography>{sub.name}</Typography>
                  <Typography variant="body2">{`(${sub.creator?.email})`}</Typography>
                </Box>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
