import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material"
import { ServerEvent } from "@src/api/interfaces"
import { SignalsContainerContext } from "@src/containers/SignalsUatContainer"
import { FC, useContext, useEffect, useState } from "react"

export const SignalsOutputs: FC = () => {
  const { signalsProgressData, isProcessLoading } = useContext(SignalsContainerContext)
  const [allSignalsProgressData, setAllSignalsProgressData] = useState<ServerEvent[]>([])

  useEffect(() => {
    if (signalsProgressData) {
      if (signalsProgressData.length === 0) {
        setAllSignalsProgressData([])
      } else {
        setAllSignalsProgressData([...allSignalsProgressData, ...signalsProgressData])
      }
    }
  }, [signalsProgressData])

  return (
    <Paper
      elevation={2}
      sx={{
        padding: 1,
        margin: 1,
        height: 400,
        overflow: "auto",
      }}
    >
      <Stack spacing={1}>
        <Typography variant="h4">Outputs</Typography>
        {allSignalsProgressData.map((serverEvent, index) => (
          <Stack key={`stack_${serverEvent.eventName}_${index}`} spacing={2} direction="row" alignItems="flex-start">
            <Box width={90}>
              <Typography>{serverEvent.time}</Typography>
            </Box>
            <Box width="80vw">
              <Typography>{serverEvent.data}</Typography>
            </Box>
          </Stack>
        ))}
        {isProcessLoading && <CircularProgress />}
      </Stack>
    </Paper>
  )
}
