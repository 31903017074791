import { FC, useContext } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material"
import { BriefingsUsersContext } from "./context"
import { useToast } from "../../../shared/ToastHook"
import { useDestroyBriefingsUser } from "../../../../api/admin/organizations"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "../../../../api/interfaces"

const DeleteButton: FC<{
  organizationId: string
  id: number
  onSuccess: () => void
}> = ({ organizationId, id, onSuccess }) => {
  const { mutate: destroyBriefingsUser } = useDestroyBriefingsUser(organizationId, id.toString(), onSuccess)

  return (
    <Button variant="contained" onClick={() => destroyBriefingsUser()}>
      Delete
    </Button>
  )
}

export const DeleteBriefingsUsersModal: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { openModal, close, id, email } = useContext(BriefingsUsersContext)

  const toast = useToast()
  const onSuccess = () => {
    toast(`Briefings for ${email} deleted`)
    close()
  }

  return (
    <Dialog open={openModal === "delete"} onClose={close} fullWidth>
      <DialogTitle>{`Delete briefings for ${email}`}</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack spacing={1}></Stack>
        <DialogActions>
          {id !== null && <DeleteButton organizationId={organizationId} id={id} onSuccess={onSuccess} />}
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
