import { useQuery } from "react-query"
import { SignalsMonitor } from "../../interfaces/signalsMonitor"
import { useAuthAxios } from "../axiosInstance"

export interface FetchedMonitor extends SignalsMonitor {
  email: string
  organization: string
  organization_id: number
}
export const useFetchSignalsMonitors = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["signals_monitors"], async () => {
    const response = await (await axiosInstance).get<{ monitors: FetchedMonitor[] }>("/admin/signals_monitors")

    return response.data
  })
}
