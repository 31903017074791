import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { User } from "../../interfaces/user"
import {
  FetchUserOrganizationPermissions,
  UserOrganizationPermissionsByState,
} from "@src/interfaces/userOrganizationPermission"

export const useFetchUserOrganizationUsers = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "users"], async () => {
    const response = await (await axiosInstance).get<{ users: User[] }>("/user_organizations/users")

    return response.data
  })
}

export const useFetchUserOrganizations = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations"], async () => {
    const response = await (await axiosInstance).get("/admin/user_organizations")

    return response.data
  })
}

export const useFetchAdminUserOrganizationUsers = (userOrganizationId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", userOrganizationId, "users"], async () => {
    const response = await (
      await axiosInstance
    ).get<{ users: User[] }>(`/admin/user_organizations/${userOrganizationId}/users`)

    return response.data
  })
}

export const useFetchUserOrganizationPermissions = (orgId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["permissions", orgId], async () => {
    const response = await (
      await axiosInstance
    ).get<FetchUserOrganizationPermissions>(`/admin/user_organizations/${orgId}/permissions`)

    return response.data
  })
}

export const useFetchUserOrganizationPermissionsByState = (userOrgId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["state_permissions", userOrgId], async () => {
    const response = await (
      await axiosInstance
    ).get<UserOrganizationPermissionsByState>(`/admin/user_organizations/${userOrgId}/geography_permissions`)

    return response.data
  })
}

export const useFetchUserOrganizationCampaigns = (orgId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", orgId, "campaigns"], async () => {
    const response = await (await axiosInstance).get(`/admin/user_organizations/${orgId}/campaigns`)

    return response.data
  })
}

export const useCreateUserOrganization = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("/admin/user_organizations/", params)
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (_response: any) => {
        queryClient.invalidateQueries("user_organizations")
      },
    },
  )
}

export const useUpdateUserOrganization = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`/admin/user_organizations/${params.id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
      },
    },
  )
}

export const useAddUserToUserOrganization = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { email: string; id: number; requires_signals_notifications: boolean; role_id: number }) => {
      return (await axiosInstance).post(`/admin/user_organizations/${params.id}/add_user`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("users")
      },
    },
  )
}

export const useInviteUser = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("/admin/user_organizations/invite_user", params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("users")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useCreateUserOrganizationPermission = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { userOrganizationId: number; organizationIds: number[] }) => {
      return (await axiosInstance).post(`/admin/user_organizations/${params.userOrganizationId}/permissions`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("permissions")
      },
    },
  )
}

export const useSetUserOrganizationPermissionByState = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { userOrganizationId: number; stateIds: number[] }) => {
      return (await axiosInstance).put(`/admin/user_organizations/${params.userOrganizationId}/geography_permissions`, {
        state_ids: params.stateIds,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("permissions")
        queryClient.invalidateQueries("state_permissions")
      },
    },
  )
}

export const useDeleteUserOrganizationPermission = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(
        `/admin/user_organizations/${params.userOrganizationId}/permissions/${params.ids.join(",")}`,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_organizations")
        queryClient.invalidateQueries("permissions")
      },
    },
  )
}

export const useCreateCampaign = (userOrgId: number, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(`/admin/user_organizations/${userOrgId}/create_campaign`, {
        name: params.name,
        filterParams: params.filterParams,
        searchTerms: params.searchTerms,
        user_id: params.user_id,
        email_cadence: params.emailCadence,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user_organizations", userOrgId, "campaigns"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateCampaign = (userOrgId: any) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`/admin/user_organizations/${userOrgId}/update_campaign`, {
        name: params.name,
        filterParams: params.filterParams,
        searchTerms: params.searchTerms,
        user_id: params.user_id,
        campaign_id: params.campaign_id,
        email_cadence: params.emailCadence,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user_org_campaigns")
      },
    },
  )
}

export const useSendDailyDigestForUserOrg = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (params: any) => {
    return (await axiosInstance).post(`/admin/user_organizations/${params.id}/send_daily_digest`)
  })
}

export const getLogoUrl = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "logo_url"], async () => {
    const response = await (await axiosInstance).get<string | null>("/user_organizations/logo_url")

    return response.data
  })
}
