import { FC, useMemo } from "react"
import { DataTable } from "../DataTable"
import { useFetchContacts } from "../../../api/contacts"
import { Skeleton, Typography } from "@mui/material"
import { formatLink } from "@components/shared/DataTable/formatters"

export const ContactsTable: FC<{
  organizationId: number
}> = ({ organizationId }) => {
  const { data, isLoading, isSuccess } = useFetchContacts(organizationId)

  const filteredData = useMemo(() => {
    if (data) {
      return data.filter((contact) => contact.removed_at === null)?.slice(0, 100)
    }
    return []
  }, [data])

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" height={350} />}
      {filteredData && filteredData.length > 0 && (
        <DataTable
          columns={[
            { id: "name", label: "Name" },
            { id: "title", label: "Title" },
            { id: "role", label: "Role" },
            { id: "email", label: "Email", formatter: formatLink },
            { id: "phone", label: "Phone" },
          ]}
          data={filteredData}
          stickyHeader
        />
      )}
      {isSuccess && filteredData.length === 0 && (
        <Typography padding={1} variant="h4">
          No contacts available
        </Typography>
      )}
    </>
  )
}
