import { FC, useMemo } from "react"
import { CardContent, Grid, CardHeader, Chip, Typography, Skeleton } from "@mui/material"
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useHitsPerGeoOverTime } from "../../../api/search"
import { useFetchGeographiesMap } from "../../../api/geographies"
import { DisplayWidgetActions } from "./DisplayWidgetActions"
import { Configuration } from "../../../interfaces/dashboard"
import { dateRangeLabel } from "../../../functions/dashboard"
import { defaultLocationFilterParams } from "../../shared/config"
import { SearchQueryParams } from "../../../interfaces/search"

const millisecondsPerDay = 1000 * 60 * 60 * 24

export const GeographiesOverTime: FC<{
  configuration: Configuration
  openAction?: (actionType: string) => void
}> = ({ configuration, openAction }) => {
  const { data: geographiesMap, isLoading: geographiesIsLoading } = useFetchGeographiesMap()
  const queryParams = new URLSearchParams()
  configuration.search_terms?.forEach((term) => {
    queryParams.append(SearchQueryParams.SearchTerms, term)
  })
  let timeStartInt = 0
  let timeEndInt = 0

  if (configuration.day_range === "custom") {
    if (configuration.date_range) {
      const { start, end } = configuration.date_range
      if (typeof start === "object") {
        timeStartInt = start.valueOf()
        queryParams.append(SearchQueryParams.DateRange, `${start.valueOf()}`)
      } else if (typeof start === "number") {
        timeStartInt = start
        queryParams.append(SearchQueryParams.DateRange, `${start}`)
      } else if (typeof start === "string") {
        timeStartInt = new Date(start).valueOf()
        queryParams.append(SearchQueryParams.DateRange, `${new Date(start).valueOf()}`)
      }

      if (typeof end === "object") {
        timeEndInt = end.valueOf()
        queryParams.append(SearchQueryParams.DateRange, `${end.valueOf()}`)
      } else if (typeof end === "number") {
        timeEndInt = end
        queryParams.append(SearchQueryParams.DateRange, `${end}`)
      } else if (typeof end === "string") {
        timeEndInt = new Date(end).valueOf()
        queryParams.append(SearchQueryParams.DateRange, `${new Date(end).valueOf()}`)
      }
    }
  } else if (typeof configuration.day_range === "number") {
    timeStartInt = (Math.ceil(Date.now() / millisecondsPerDay) - configuration.day_range) * millisecondsPerDay
    queryParams.append(SearchQueryParams.DateRange, timeStartInt.toString())
    timeEndInt = Math.ceil(Date.now() / millisecondsPerDay) * millisecondsPerDay
    queryParams.append(SearchQueryParams.DateRange, timeEndInt.toString())
  }
  queryParams.set(
    SearchQueryParams.FilterParams,
    btoa(JSON.stringify(configuration.filter_params || defaultLocationFilterParams)),
  )

  const { data, isLoading } = useHitsPerGeoOverTime(
    queryParams,
    configuration.x_axis_unit as "day" | "week" | "month",
    configuration.geography_type as "city" | "county" | "state",
  )
  const dataMap = new Map(data?.map(({ time, hits }) => [time, hits]))

  const chartData: Record<string, string | number>[] = []
  const dayStart = Math.ceil(timeStartInt / millisecondsPerDay)
  for (let i = dayStart; i < Math.ceil(timeEndInt / millisecondsPerDay); i += 1) {
    const date = new Date(i * millisecondsPerDay)
    const newData: Record<string, string | number> = {
      date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
    }
    const dateData = dataMap.get(i * millisecondsPerDay)
    if (dateData && geographiesMap) {
      dateData.forEach(({ id, count }) => {
        if (configuration.geography_type === "city") {
          const city = geographiesMap.cities.get(id)
          if (city) {
            newData[city.name] = count
          }
        } else if (configuration.geography_type === "county") {
          const county = geographiesMap.counties.get(id)
          if (county) {
            newData[county.name] = count
          }
        } else if (configuration.geography_type === "state") {
          const state = geographiesMap.states.get(id)
          if (state) {
            newData[state.name] = count
          }
        }
      })
    }
    chartData.push(newData)
  }
  const lines: Set<string> = new Set()
  let i = chartData.length
  while (i > 0 && lines.size < 5) {
    i -= 1
    if (Object.keys(chartData[i]).length > 1) {
      const nameEntries: [string, number][] = []
      Object.entries(chartData[i]).forEach(([key, value]) => {
        if (typeof value === "number") {
          nameEntries.push([key, value])
        }
      })
      nameEntries.sort(([, countA], [, countB]) => countB - countA)
      let j = 0
      while (lines.size < 5 && j < nameEntries.length) {
        lines.add(nameEntries[j][0])
        j += 1
      }
    }
  }

  chartData.forEach((dateDatum) => {
    lines.forEach((name) => {
      if (!(name in dateDatum)) {
        dateDatum[name] = 0
      }
    })
  })

  const displayDateRange = useMemo(() => dateRangeLabel(configuration), [configuration])
  const colors = ["#3A47BB", "#027D60", "#BC0706", "#1B242D", "#BA0EB3"]

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <CardHeader title={isLoading ? <Skeleton /> : configuration.name} subheader="Geographies Over Time" />
        </Grid>
        <Grid item>
          <Chip
            color="primary"
            label={isLoading ? <Skeleton width="20ch" sx={{ backgroundColor: "#bbb" }} /> : displayDateRange}
            sx={{ position: "relative" }}
          />
          {openAction && <DisplayWidgetActions openAction={openAction} />}
        </Grid>
      </Grid>
      <CardContent>
        <Grid container direction="row" justifyContent="center">
          {isLoading || geographiesIsLoading ? (
            <Skeleton width="100%" height={200} variant="rectangular" />
          ) : lines.size !== 0 ? (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {Array.from(lines).map((name, index) => (
                  <Line key={name} type="monotone" dataKey={name} stroke={colors[index]} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="h6">No Data Found</Typography>
          )}
        </Grid>
      </CardContent>
    </>
  )
}
