import { useState, FC, useMemo, useEffect } from "react"
import { useGetAllFeatureFlagTypes } from "../../../api/admin/flags"
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Dialog,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"
import { FlagParams, useCreateFlag, useUpdateFlag } from "../../../api/admin/flags"
import { Flag } from "../../../interfaces/flag"

export const createUpdateKey = "CREATE_UPDATE_FLAG"

export const CreateUpdateModal: FC<{ featureFlag?: Flag; onSuccess?: () => void }> = ({ featureFlag, onSuccess }) => {
  const open = useModalKey(createUpdateKey)
  const closeModalKey = useCloseModalKey(createUpdateKey)
  const { data: flagTypesData } = useGetAllFeatureFlagTypes()

  const flagTypes = flagTypesData ? Object.keys(flagTypesData) : []

  const [formData, setFormData] = useState<FlagParams>({
    name: featureFlag?.name || "",
    value: featureFlag?.value || "",
    flag_type: featureFlag?.flag_type || "number",
    public: featureFlag?.public || false,
  })
  const afterUpdate = () => {
    setFormData({
      name: "",
      value: "",
      flag_type: "number",
      public: false,
    })
    onSuccess && onSuccess()
  }
  const { mutate: createFeatureFlag } = useCreateFlag(() =>
    setFormData({
      name: "",
      value: "",
      flag_type: "number",
      public: false,
    }),
  )
  const { mutate: updateFeatureFlag } = useUpdateFlag(featureFlag?.id || 0, afterUpdate)

  useEffect(() => {
    setFormData({
      name: featureFlag?.name || "",
      value: featureFlag?.value || "",
      flag_type: featureFlag?.flag_type || "number",
      public: featureFlag?.public || false,
    })
  }, [featureFlag])

  const handleSubmit = () => {
    if (featureFlag) {
      updateFeatureFlag(formData)
    } else {
      createFeatureFlag(formData)
    }
    closeModalKey()
  }

  return (
    <Dialog fullWidth open={open} onClose={closeModalKey}>
      <DialogTitle>
        {featureFlag ? "Update" : "Create"}
        &nbsp;Feature Flag
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Stack spacing={2}>
          <TextField
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            label="Name"
          />
          <TextField
            value={formData.value}
            onChange={(e) => setFormData({ ...formData, value: e.target.value })}
            label="Value"
          />
          <FormControl>
            <InputLabel id="type-id">Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={formData.flag_type}
              label="Type"
              onChange={(e) => setFormData({ ...formData, flag_type: e.target.value })}
            >
              {flagTypes.map((type) => (
                <MenuItem value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="public-id">Public</InputLabel>
            <Select
              labelId="public-select-label"
              id="public-select"
              value={formData.public.toString()}
              label="Public"
              onChange={(e) => setFormData({ ...formData, public: e.target.value === "true" })}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={formData.value === "" && formData.name === ""} onClick={handleSubmit}>
          {featureFlag ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
