import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { OpportunityCampaignsUsers } from "@src/interfaces/opportunityCampaignsUsers"
import { useIsOpportunitiesEnabled } from "./users"

const prefix = "/opportunity_campaigns_users"

export const useFetchOpportunityCampaignsUsers = () => {
  const axiosInstance = useAuthAxios()
  const { data: isOpportunitiesEnabled } = useIsOpportunitiesEnabled()

  return useQuery(
    ["opportunityCampaignsUsers"],
    async () => {
      const response = await (await axiosInstance).get<OpportunityCampaignsUsers[]>(prefix)

      return response.data
    },
    {
      enabled: Boolean(isOpportunitiesEnabled),
    },
  )
}

export const useFetchOpportunityCampaignsUsersMap = () => {
  const fetchData = useFetchOpportunityCampaignsUsers()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(
        fetchData.data.map((opportunityCampaignsUser) => [
          opportunityCampaignsUser.opportunity_campaign_id,
          opportunityCampaignsUser,
        ]),
      ),
    }
  }
  return fetchData
}
