import { Info } from "@mui/icons-material"
import { Skeleton, Tabs as MuiTabs, Tab, Tooltip } from "@mui/material"
import { FC, useContext, useMemo } from "react"
import { useFetchContacts } from "../../../api/contacts"
import { StatisticsDetailsCardContext } from "."
import { render } from "@testing-library/react"

const keywordToolTip = "Click on a term to add it to your search or click the X next to the term if it's irrelevant."
const mostUsedWordsToolTip = "Click on a word to add it to your search!"

export const Tabs: FC = () => {
  const { meeting, tabValue, setTabValue } = useContext(StatisticsDetailsCardContext)
  const {
    data: contactsData,
    isLoading: isContactsLoading,
    isError: isContactsError,
  } = useFetchContacts(meeting.organization_id)
  const renderTabs = useMemo(() => {
    const tabs = []

    if (contactsData && contactsData.length > 0) {
      tabs.push(<Tab key="contacts" label="Contacts" value="contacts" iconPosition="end" />)
    }
    tabs.push(<Tab key="details" label="Channel Details" value="details" iconPosition="end" />)
    tabs.push(
      <Tab
        key="keyTerms"
        label="Key Terms in This Meeting"
        value="keyTerms"
        icon={
          tabValue === "keyTerms" ? (
            <Tooltip title={keywordToolTip}>
              <Info />
            </Tooltip>
          ) : undefined
        }
        iconPosition="end"
      />,
    )
    tabs.push(
      <Tab
        key="mostUsedWords"
        label="Most Used Words"
        value="mostUsedWords"
        icon={
          tabValue === "mostUsedWords" ? (
            <Tooltip title={mostUsedWordsToolTip}>
              <Info />
            </Tooltip>
          ) : undefined
        }
        iconPosition="end"
      />,
    )

    if (isContactsLoading) {
      return <Skeleton variant="rectangular" height={40} sx={{ marginBottom: 2 }} />
    } else {
      return (
        <MuiTabs
          value={tabValue}
          onChange={(_event, value: string) => setTabValue(value)}
          sx={{ maxHeight: 60 }}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {tabs}
        </MuiTabs>
      )
    }
  }, [contactsData, isContactsLoading, isContactsError, tabValue])

  return renderTabs
}
