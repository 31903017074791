import { FC, useContext } from "react"
import { Alert, Snackbar } from "@mui/material"
import { UserContext } from "../../pages/UsersPage"
import { UpdateNotifications } from "./UpdateNotifications"
import { UpdateRoleDialog } from "./UpdateRole"
import { DeleteUsersDialog } from "./DeleteUsers"
import { UpdateCreditLimit } from "./UpdateCreditLimit"

export const ActionsMenu: FC = () => {
  const { openToast, setOpenToast, inviteUserSuccess, inviteUserReset } = useContext(UserContext)

  return (
    <>
      <Snackbar
        open={inviteUserSuccess && openToast !== ""}
        autoHideDuration={3000}
        onClose={() => {
          openToast === "Email" && inviteUserReset()
          setOpenToast("")
        }}
      >
        <Alert
          severity={openToast === "Deleted" ? "error" : "success"}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            "& .MuiAlert-icon": { color: "white" },
          }}
        >
          {`User(s) Successfully ${openToast}`}
        </Alert>
      </Snackbar>
      <UpdateRoleDialog />
      <UpdateNotifications />
      <DeleteUsersDialog />
      <UpdateCreditLimit />
    </>
  )
}
