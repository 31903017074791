import { FC } from "react"
import { Grid, Typography } from "@mui/material"

export const NoResultsContainer: FC = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "50vh",
        backgroundColor: "primary.dark",
        borderRadius: 3,
        marginTop: 1,
      }}
    >
      <Grid container direction="column" item xs={4} alignItems="center" padding={1}>
        <Typography color="neutral.light" variant="h1">
          No Results
        </Typography>
        <Typography color="neutral.main" variant="h4" textAlign="center">
          Change your search terms and/or filters and try again!
        </Typography>
      </Grid>
    </Grid>
  )
}
