import { Chip, Stack } from "@mui/material"
import { FC, Dispatch, SetStateAction } from "react"

export const SearchFormTerms: FC<{
  terms: Set<string>
  setTerms: Dispatch<SetStateAction<Set<string>>>
}> = ({ terms, setTerms }) => {
  if (terms.size !== 0) {
    return (
      <Stack direction="row" flexWrap="wrap" gap={0.5} marginTop={0.5}>
        {Array.from(terms).map((term) => (
          <Chip
            key={term}
            label={term}
            onDelete={() =>
              setTerms((prevTerms) => {
                prevTerms.delete(term)
                return new Set(prevTerms)
              })
            }
          />
        ))}
      </Stack>
    )
  }
  return null
}
