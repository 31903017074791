import { FC } from "react"
import { getSuccessMessage } from "../../config/campaignConfig"
import { Alert, Snackbar } from "@mui/material"

export const CampaignSnackBar: FC<{
  isOpen: any
  operation: string
  onComplete: any
}> = ({ isOpen, operation, onComplete }) => {
  const { message } = getSuccessMessage(operation)

  return (
    <Snackbar open={isOpen && operation?.length > 0} autoHideDuration={2000} onClose={onComplete}>
      <Alert
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          "& .MuiAlert-icon": { color: "white" },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
