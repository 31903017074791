import { FC } from "react"
import { Link, Box, Grid, Typography } from "@mui/material"
import CopyrightIcon from "@mui/icons-material/Copyright"

export const Footer: FC = () => {
  // Grab the current year for copyright
  const year = new Date().getFullYear()

  return (
    <Box sx={{ width: "100%", display: "flex", paddingLeft: "20px", backgroundColor: "neutral.main" }}>
      <Grid container spacing={1}>
        <Grid item>
          <CopyrightIcon fontSize="small" sx={{ backgroundColor: "neutral.main" }} />
        </Grid>
        <Grid item>
          <Typography sx={{ fontWeight: 400 }} variant="body1">
            {year} Engaged Citizens
          </Typography>
        </Grid>
        <Grid item>
          <p style={{ padding: 0, margin: 0, fontSize: "11px", marginTop: "4px", color: "#364656" }}>|</p>
        </Grid>
        <Grid item>
          <Link
            variant="body1"
            underline="none"
            style={{ fontWeight: 400 }}
            target="_blank"
            href="https://app.termly.io/document/privacy-policy/93a03f05-8756-446d-8687-117cf0d7f814"
          >
            Privacy
          </Link>
        </Grid>
        <Grid item>
          <p style={{ padding: 0, margin: 0, fontSize: "11px", marginTop: "4px", color: "#364656" }}>|</p>
        </Grid>
        <Grid item>
          <Link
            variant="body1"
            underline="none"
            style={{ fontWeight: 400 }}
            target="_blank"
            href="https://app.termly.io/document/terms-of-use-for-saas/580de6e9-ecaf-435f-a749-e6ab245e797a"
          >
            Terms of Service
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}
