import { FC, useState } from "react"
import { Button, Dialog, DialogTitle } from "@mui/material"
import { useParams } from "react-router-dom"
import { useToast } from "../../../shared/ToastHook"
import { useFetchUserOrganizationUsers } from "../../../../api/admin/user_organizations"
import { SendInvitesModal } from "../SendInvitesModal"

export const UninvitedUsersButton: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: users } = useFetchUserOrganizationUsers(userOrganizationId)
  const [open, setOpen] = useState(false)
  const setToast = useToast()

  return (
    <>
      <Button size="small" variant="text" onClick={() => setOpen(true)}>
        Invite uninvited users
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Import from CSV</DialogTitle>
        {users && (
          <SendInvitesModal
            users={users.filter(({ been_invited }) => !been_invited).map(({ id, email }) => ({ id, email }))}
            onSuccess={() => {
              setOpen(false)
              setToast("User(s) successfully invited")
            }}
          />
        )}
      </Dialog>
    </>
  )
}
