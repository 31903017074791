import { IMeetingDetail } from "../api/interfaces"
import { formatBool, formatDate, formatDatetime, formatPercent } from "../components/shared/config"

export interface MeetingColumn {
  id:
    | "id"
    | "created_at"
    | "published_at"
    | "title"
    | "is_spam"
    | "spam_certainty"
    | "channel_name"
    | "archive_url"
    | "state"
    | "county"
    | "city"
    | "source_video_url"
  type: "string" | "number" | "datetime" | "boolean" | "float"
  label: string
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  link?: any
}

export const meetingColumns: readonly MeetingColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "title",
    label: "Title",
    minWidth: 280,
    type: "string",
  },
  {
    id: "channel_name",
    label: "Channel",
    minWidth: 175,
    type: "string",
  },
  {
    id: "is_spam",
    label: "Spam?",
    type: "boolean",
    format: formatBool,
  },
  {
    id: "spam_certainty",
    label: "Spam Certainty",
    type: "float",
    format: formatPercent,
  },
  {
    id: "published_at",
    label: "Published At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "state",
    label: "State",
    minWidth: 100,
    type: "string",
  },
  {
    id: "county",
    label: "County",
    minWidth: 100,
    type: "string",
  },
  {
    id: "city",
    label: "City",
    minWidth: 120,
    type: "string",
  },
  {
    id: "source_video_url",
    label: "Source Video Url",
    minWidth: 280,
    type: "string",
  },
  {
    id: "archive_url",
    label: "Archive Url",
    minWidth: 150,
    type: "float",
    format: formatPercent,
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 110,
    format: formatDate,
    type: "datetime",
  },
]

export const createMeetingRow: Record<any, any> = (meeting: IMeetingDetail) => {
  return {
    id: meeting.id,
    title: meeting.title,
    created_at: meeting.created_at,
    published_at: meeting.published_at,
    is_spam: meeting.is_spam,
    spam_certainty: meeting.spam_certainty,
    source_video_url: meeting.source_video_url,
    channel_name: meeting.organization.name,
    city: meeting?.city?.name,
    county: meeting?.county?.name,
    state: meeting?.state?.name,
    archive_url:
      meeting.organization.video_archive_type === "youtube"
        ? `https://youtube.com/channel/${meeting.organization.video_archive_id}`
        : meeting.organization.video_archive_id,
  }
}
