import { useFetchOrganizations as useFetchAdminOrganizations } from "@src/api/admin/organizations"
import { useFetchOrganizations as useFetchNonAdminOrganizations } from "@src/api/organizations"
import { useFetchOrganizations as useFetchOrganizationsPromotions } from "@src/api/promotions/organizations"
import { Organization } from "@src/interfaces/organization"
import { useLocation } from "react-router-dom"

const fetchDataHandler = (authenticated: boolean, org_ids?: number[]) => {
  const location = useLocation()

  if (authenticated) {
    if (location.pathname.includes("/admin/")) {
      return useFetchAdminOrganizations()
    } else {
      return useFetchNonAdminOrganizations()
    }
  }
  if (!org_ids || org_ids.length > 10) {
    console.error("useFetchOrganizations: must provide org_ids if not authenticated and less than 10")
    return { data: { organizations: [] } }
  }
  return useFetchOrganizationsPromotions(org_ids)
}

export const useFetchOrganizationsMap = (authenticated: boolean = true, org_ids?: number[]) => {
  // Function to get a map of orgs. IF the user is not authenticated (find a lead) then the org_ids must be provided
  if (!authenticated && !org_ids) {
    console.error("useFetchOrganizationsMap: must provide org_ids if not authenticated")
    return { data: new Map() }
  }
  const fetchData = fetchDataHandler(authenticated, org_ids)
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.organizations.map((organization: Organization) => [organization.id, organization])),
    }
  }
  return fetchData
}

export const organizationsFetchContext = (user_org_id?: string) => {
  const location = useLocation()

  if (location.pathname.includes("/admin/")) {
    return useFetchAdminOrganizations({ userOrganizationId: user_org_id })
  } else {
    return useFetchNonAdminOrganizations()
  }
}
