import { AppBar, Box, Grid, Link, Toolbar, MenuItem, Menu, IconButton, useTheme, useMediaQuery } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { FC, useState } from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { MobilePromoLinks } from "./MobilePromoLinks"
import { PromoLinks } from "./PromoLinks"

export const PromoNav: FC<{ isFindALead: boolean }> = ({ isFindALead }) => {
  const history = useHistory()
  const theme = useTheme()
  const { isAuthenticated, logout, user } = useAuth0()
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100%",
        background: "linear-gradient(40deg, rgba(4,4,24,1) 87%, rgba(4,26,55,.88))",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Grid container item flexDirection="row" justifyContent="space-between" alignItems="center" spacing={0}>
          <Grid container item xs={10} sm={6} md={7} justifyContent="flex-start" alignItems="center">
            <Box
              component="img"
              onClick={() => history.push("/redirect")}
              sx={{
                margin: { xs: 1, sm: 0 },
                width: { xs: 230 },
                height: { xs: "100%", md: "35%", lg: "35%", xl: "35%" },
                cursor: "pointer",
                paddingLeft: 1,
              }}
              src="https://storage.googleapis.com/cloverleaf-ai-public-assets/cl.ai.text.and.logo.png"
            />
          </Grid>
          {isMobile ? <MobilePromoLinks isFindALead={isFindALead} /> : <PromoLinks isFindALead={isFindALead} />}
          {isAuthenticated ? (
            <Grid container item justifyContent="flex-end" xs={1}>
              <IconButton
                size="large"
                sx={{ color: "neutral.light" }}
                onClick={(event) => setUserMenuAnchorEl(event.currentTarget)}
              >
                <AccountCircleIcon sx={{ height: "30px" }} />
              </IconButton>

              <Menu
                id="user-icon-menu"
                anchorEl={userMenuAnchorEl}
                open={userMenuAnchorEl !== null}
                onClose={() => setUserMenuAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "user-icon-button",
                }}
              >
                <MenuItem disabled>{user?.name}</MenuItem>
                <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Log Out</MenuItem>
              </Menu>
            </Grid>
          ) : (
            <Grid container item justifyContent="flex-end" xs={1}>
              {!isAuthenticated && (
                <Link
                  variant="h6"
                  color="white"
                  href="/"
                  underline="none"
                  sx={{
                    fontSize: { xs: 15, sm: 15 },
                    "&:hover": {
                      color: "#FFF",
                    },
                  }}
                >
                  Login
                </Link>
              )}
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
