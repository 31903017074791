export const getTableFormattedHits = (
  terms?: {
    term: string
    count: number
  }[],
) => {
  if (!terms) {
    return []
  }
  const nonZeroTerms = terms.filter((term) => term.count > 0)
  nonZeroTerms.sort((a, b) => b.count - a.count)
  const topTerms = terms.slice(0, 3)
  return topTerms
}

export const getTooltipFormattedHits = (
  terms?: {
    term: string
    count: number
  }[],
) => {
  if (!terms) {
    return ""
  }
  terms.sort((a, b) => b.count - a.count)
  return formatHitsForDisplay(terms)
}

const formatHitsForDisplay = (
  terms: {
    term: string
    count: number
  }[],
) => {
  const valid = terms.reduce((result, term) => {
    if (term.count > 0) {
      result.push(`${term.term}: ${term.count}`)
      return result
    } else {
      return result
    }
  }, [] as string[])
  return valid.join("\n")
}
