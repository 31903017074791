import { FC, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material"
import { marked } from "marked"
import { sanitize } from "dompurify"
import SendIcon from "@mui/icons-material/Send"
import { getPlaygroundResult } from "../../../api/admin/prompts"
import { LoadingEllipsis } from "../../shared/LoadingEllipsis"
import { useModalKey, useCloseModalKey } from "../../shared/OpenModalHook"
import { UserSelector } from "../../shared/Selectors/User"

export const PLAYGROUND_MODAL_KEY = "PLAYGROUND"

export const PlaygroundModal: FC = () => {
  const isOpen = useModalKey(PLAYGROUND_MODAL_KEY)
  const close = useCloseModalKey(PLAYGROUND_MODAL_KEY)
  const [meetingId, setMeetingId] = useState<number | null>(null)
  const [userId, setUserId] = useState<number | null>(null)
  const [text, setText] = useState("")
  const [time, setTime] = useState(0)
  const { data, isLoading } = getPlaygroundResult(time, meetingId, text, userId)

  let promptResult = <div style={{ width: "50%" }} />
  if (data) {
    if (data[0].promptFeedback?.blockReason) {
      promptResult = (
        <Typography>{`Error, prompt blocked for reason: ${data[0].promptFeedback?.blockReason}`}</Typography>
      )
    } else if (data[0].candidates && !data[0].candidates[0].content.parts) {
      const reason = data[0].candidates[0].safetyRatings.find(({ blocked }) => blocked)
      promptResult = <Typography>{`Error, prompt blocked for reason: ${reason?.category}`}</Typography>
    } else {
      promptResult = (
        <div
          style={{ width: "50%" }}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              marked.parse(
                data.map(({ candidates }) => (candidates ? candidates[0].content.parts?.[0].text : "")).join(),
              ) as string,
            ),
          }}
        />
      )
    }
  }

  return (
    <Dialog open={isOpen} onClose={close} PaperProps={{ sx: { width: "80%", maxWidth: "unset" } }}>
      <DialogTitle>LLM Playground</DialogTitle>
      <DialogContent sx={{ overflow: "visible" }}>
        <Stack spacing={1} direction="row">
          <Stack spacing={1} width="50%">
            <TextField
              required
              size="small"
              label="Meeting Id"
              type="number"
              value={meetingId || ""}
              onChange={(event) => {
                const newValue = parseInt(event.target.value, 10)
                if (typeof newValue === "number" && !isNaN(newValue)) {
                  setMeetingId(newValue)
                } else if (event.target.value === "") {
                  setMeetingId(0)
                }
              }}
            />
            <UserSelector userId={userId} setUserId={setUserId} />
            <TextField
              size="small"
              value={text}
              onChange={(event) => setText(event.target.value)}
              label="Prompt"
              fullWidth
              multiline
            />
            <Typography variant="h6">Substitutions:</Typography>
            <Typography>{"{transcript}, {industry}, {job_title}, {goals}"}</Typography>
          </Stack>
          {promptResult}
        </Stack>
        <DialogActions>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={() => setTime(Date.now())}
            disabled={meetingId === null || text === ""}
          >
            {isLoading ? (
              <>
                Loading
                <LoadingEllipsis />
              </>
            ) : (
              "Send"
            )}
          </Button>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
