import { FC } from "react"
import { Layout } from "../components/shared/Layout"
import { Redirect } from "react-router-dom"
import { useFetchPerson } from "../api/persons"
import { Typography } from "@mui/material"
import { MeetingItemBase } from "../components/shared/MeetingItemBase"
import { Meeting } from "../interfaces/meeting"
import { useIsSpeakerIdentificationEnabled } from "../api/users"

export const PersonPage: FC<{ match: { params: { personId: string } } }> = ({
  match: {
    params: { personId },
  },
}) => {
  const { data: person } = useFetchPerson(parseInt(personId))
  const { data: isSpeakerIdentificationEnabled, isFetched } = useIsSpeakerIdentificationEnabled()

  return (
    <Layout title={person?.name}>
      {person?.organization && (
        <Typography variant="h6">{`${person.organization}${person.title ? ` ${person.title}` : ""}`}</Typography>
      )}
      <Typography>This person appears in these meetings:</Typography>
      {person?.meetings.map((meeting: Meeting) => (
        <MeetingItemBase key={meeting.id} meeting={meeting} link={`/meetings/${meeting.id}`} />
      ))}
      {isFetched && !isSpeakerIdentificationEnabled && <Redirect to="/" />}
    </Layout>
  )
}
