import { FC, useState } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { useDeleteUserOrganizationPermission } from "../../../api/userOrganizations"

export const PermissionsListItemDetails: FC<{
  permission: any
  userOrgId: any
}> = ({ permission, userOrgId }) => {
  const { mutate: deletePermission } = useDeleteUserOrganizationPermission()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  return (
    <Grid container marginY={1} key={permission.id}>
      <Grid item xs={6}>
        <Typography>{permission.organization}</Typography>
      </Grid>
      <Grid item xs={6}>
        {showConfirmDelete ? (
          <>
            <Button
              onClick={() => {
                deletePermission({
                  ids: [permission.id],
                  userOrganizationId: userOrgId,
                })
                setShowConfirmDelete(false)
              }}
              variant="contained"
              size="small"
              color="error"
            >
              Confirm
            </Button>
            <Button onClick={() => setShowConfirmDelete(false)} variant="outlined" size="small">
              Cancel
            </Button>
          </>
        ) : (
          <Button onClick={() => setShowConfirmDelete(true)} variant="outlined" color="error" size="small">
            Delete Permission
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
