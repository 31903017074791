import { formatYesNo } from "../components/shared/DataTable"
import { ProxyRegion } from "../interfaces/proxyRegions"

export interface ProxyRegionColumn {
  id: "id" | "region" | "abbreviation" | "youtube_blocked" | "granicus_blocked" | "swagit_blocked" | "active"
  label: string
  minWidth?: number
  type: "string" | "number" | "datetime" | "boolean"
  width?: number
  format?: (value: any) => string
}

export const proxyRegionColumns: readonly ProxyRegionColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "abbreviation",
    label: "Abbreviation",
    minWidth: 110,
    type: "string",
  },
  {
    id: "region",
    label: "Region",
    minWidth: 110,
    type: "string",
  },
  {
    id: "active",
    label: "Active",
    minWidth: 30,
    format: formatYesNo,
    type: "boolean",
  },
  {
    id: "youtube_blocked",
    label: "Youtube Block",
    minWidth: 30,
    format: formatYesNo,
    type: "boolean",
  },
  {
    id: "granicus_blocked",
    label: "Granicus Block",
    minWidth: 30,
    format: formatYesNo,
    type: "boolean",
  },
  {
    id: "swagit_blocked",
    label: "Swagit Block",
    minWidth: 30,
    format: formatYesNo,
    type: "boolean",
  },
]

export const createProxyRoleRow: Record<any, any> = (region: ProxyRegion) => {
  return {
    id: region.id,
    abbreviation: region.abbreviation,
    region: region.region,
    active: region.active,
    youtube_blocked: region.youtube_blocked,
    granicus_blocked: region.granicus_blocked,
    swagit_blocked: region.swagit_blocked,
    created_at: region.created_at,
    updated_at: region.updated_at,
  }
}
