import { useContext, FC } from "react"
import { Typography, Stack, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material"
import { unpackSetters } from "../unpackSetters"
import { CampaignWizardContext } from "./Context"

export const NameCampaign: FC = () => {
  const { value, setValue } = useContext(CampaignWizardContext)
  const { name, emailFrequency } = value
  const { setName, setEmailFrequency } = unpackSetters(value, setValue)
  return (
    <>
      <Stack spacing={1} marginBottom={2}>
        <Typography variant="body2">Step One</Typography>
        <Typography variant="h1">Name Your Campaign</Typography>
        <Typography variant="body1">
          To get started, name your campaign and select the frequency with which you’d like to be notified about your
          campaign.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={3}>
        <TextField label="Campaign Name" value={name} onChange={(event) => setName(event.target.value)} fullWidth />
        <FormControl sx={{ minWidth: "32ch" }}>
          <InputLabel>Email Notification Frequency</InputLabel>
          <Select
            value={emailFrequency}
            label="Email Notification Frequency"
            onChange={(event) => setEmailFrequency(event.target.value as "" | "Daily" | "Weekly" | "Unsubscribed")}
          >
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}
