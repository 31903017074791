import HomeIcon from "@mui/icons-material/Home"
import DashboardIcon from "@mui/icons-material/Dashboard"
import DvrIcon from "@mui/icons-material/Dvr"
import CampaignIcon from "@mui/icons-material/Campaign"
import PersonIcon from "@mui/icons-material/Person"
import { useLocation, matchPath } from "react-router-dom"
import { useFetchCurrentUser, useIsContractor, useIsSuperAdmin } from "../../api/users"
import StarIcon from "@mui/icons-material/Star"

export const navConfig = [
  { label: "Home", to: "/", icon: HomeIcon },
  { label: "Dashboards", to: "/dashboards", icon: DashboardIcon },
  { label: "Campaigns", to: "/campaigns", icon: CampaignIcon },
  { label: "Channels", to: "/channels", icon: DvrIcon },
  { label: "Bookmarks", to: "/bookmarks", icon: StarIcon },
  { label: "Users", to: "/users", icon: PersonIcon },
]

export const checkMatchingPath = (to: string): boolean => {
  const match = matchPath(useLocation().pathname, { path: to, strict: true, exact: to === "/" })
  return match !== null
}

export const useCanSeeAdmin = () => {
  const { data: isSuperAdmin, isFetched: superAdminIsFetched } = useIsSuperAdmin()
  const { data: isContractor, isFetched: contractorIsFetched } = useIsContractor()
  const { data: currentUser, isFetched: currentUserIsFetched } = useFetchCurrentUser()

  return {
    data: Boolean(
      isSuperAdmin || isContractor || currentUser?.available_routes?.some((route) => route.slice(0, 5) === "admin"),
    ),
    isFetched: superAdminIsFetched && contractorIsFetched && currentUserIsFetched,
  }
}
