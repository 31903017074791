import { useFetchUsers } from "@api/users"
import { Autocomplete, Skeleton, TextField } from "@mui/material"
import { BriefingsContainerContext } from "@src/containers/BriefingContainers/BriefingsContainer"
import { FC, useContext } from "react"

export const BriefingUsersAutocomplete: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(BriefingsContainerContext)
  const { data: users, isFetched: usersIsFetched } = useFetchUsers()
  const usersMap = new Map(users?.map(({ id, email }) => [id, email]))

  return (
    <>
      {!usersIsFetched || !users ? (
        <Skeleton height={56} variant="rounded" sx={{ flex: 1 }} />
      ) : (
        <Autocomplete
          multiple
          options={Array.from(users.map(({ id }) => id))}
          renderInput={(params) => <TextField {...params} label="User" />}
          sx={{ flex: 1 }}
          getOptionLabel={(option) => usersMap.get(option) || ""}
          value={urlParamState.users}
          onChange={(_event, value) => setUrlParamState({ ...urlParamState, users: value || [], page: 0 })}
        />
      )}
    </>
  )
}
