import { Autocomplete, TextField } from "@mui/material"
import { getPrompts } from "../../../api/admin/prompts"
import { Dispatch, SetStateAction } from "react"

export interface SelectedPrompt {
  id: number
  name: string
}

type SelectedPromptType = SelectedPrompt | SelectedPrompt[] | null

export interface SelectedPrompts<Type extends SelectedPromptType> {
  prompts: Type
  setPrompts: Dispatch<SetStateAction<Type>>
}

// This component is for super admin's only because it reveals what the prompt
// machine learning text is if they were to inspect the return,
// which we don't want normal users having access to
export const PromptsAutocomplete = <Type extends SelectedPromptType>({
  selectedPrompts,
  enableMultiple = false,
}: {
  selectedPrompts: SelectedPrompts<Type>
  enableMultiple: boolean
}) => {
  const { prompts, setPrompts } = selectedPrompts
  const { data: prompsResponseData } = getPrompts()

  return (
    <Autocomplete
      value={prompts}
      onChange={(event, newValue) => {
        if (Array.isArray(newValue)) {
          setPrompts(newValue as Type)
        } else {
          setPrompts(newValue as Type)
        }
      }}
      options={prompsResponseData?.map((prompt) => ({ id: prompt.id, name: prompt.name })) || []}
      getOptionLabel={(option) => {
        if (option.name) {
          return option.name
        }
        return ""
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      fullWidth
      multiple={enableMultiple}
      renderInput={(params) => <TextField {...params} label="Prompt" />}
      size="small"
    />
  )
}
