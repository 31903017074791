import { FC, MouseEventHandler, ReactNode } from "react"
import { FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { getPrompts } from "../../../api/prompts"

export const PromptSelect: FC<{
  id: number
  availablePrompts: { id: number; name: string }[]
  handleChange: (event: SelectChangeEvent<number>, child: ReactNode) => void
  handleDelete: MouseEventHandler
  handleUp?: MouseEventHandler
  handleDown?: MouseEventHandler
}> = ({ id, availablePrompts, handleChange, handleDelete, handleUp, handleDown }) => {
  const { data } = getPrompts("personal")
  const selectableChoices = [...availablePrompts]
  const currentChoice = data?.find(({ id: promptId }) => id === promptId)
  if (currentChoice) {
    selectableChoices.unshift(currentChoice)
  }
  return (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <InputLabel>Prompt</InputLabel>
        <Select value={id} label="Prompt" onChange={handleChange} sx={{ width: "20ch" }}>
          {selectableChoices.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton
        color="primary"
        onClick={handleUp}
        sx={{
          opacity: handleUp ? 1 : 0,
        }}
        disabled={!handleUp}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <IconButton
        color="primary"
        onClick={handleDown}
        sx={{
          opacity: handleDown ? 1 : 0,
        }}
        disabled={!handleDown}
      >
        <ArrowDownwardIcon />
      </IconButton>
      <IconButton color="primary" onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </Stack>
  )
}
