import { Button, TableCell, TextField } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useFetchPersonsMap, useUpdatePerson } from "@api/admin/persons"
import { useUpdateVoicePersonId } from "@api/admin/voices"

export const PersonCells: FC<{
  // if voice_id is not null, update the voice with the person_id
  voiceId: number | null
  personId: number | null
}> = ({ voiceId, personId }) => {
  const { data: personsMap } = useFetchPersonsMap(new URLSearchParams({ count: "1000000" }))
  const [name, setName] = useState("")
  const [title, setTitle] = useState("")
  const [organization, setOrganization] = useState("")

  const { mutate: updateVoicePersonId, isLoading: voiceIsLoading } = useUpdateVoicePersonId()
  const { mutate: updatePerson, isLoading: personIsLoading } = useUpdatePerson()

  let canUpdate = Boolean(voiceId)
  if (!voiceId && personId && personsMap) {
    const person = personsMap.get(personId)
    if (person) {
      canUpdate = name !== person.name || title !== person.title || organization !== person.organization
    }
  }

  useEffect(() => {
    if (personId && personsMap) {
      const person = personsMap.get(personId)
      if (person) {
        setName(person.name)
        setTitle(person.title)
        setOrganization(person.organization)
      }
    }
  }, [personId, Boolean(personsMap)])

  return (
    <>
      <TableCell>
        <TextField value={name} onChange={(event) => setName(event.target.value)} size="small" />
      </TableCell>
      <TableCell>
        <TextField value={title} onChange={(event) => setTitle(event.target.value)} size="small" />
      </TableCell>
      <TableCell>
        <TextField value={organization} onChange={(event) => setOrganization(event.target.value)} size="small" />
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          disabled={!canUpdate || voiceIsLoading || personIsLoading}
          onClick={() => {
            if (voiceId !== null && personId !== null) {
              updateVoicePersonId({
                voice_id: voiceId,
                person_id: personId,
              })
            }
            if (personId !== null) {
              updatePerson({ id: personId, name, title, organization })
            }
          }}
        >
          Update
        </Button>
      </TableCell>
    </>
  )
}
