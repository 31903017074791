import { FC, useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player/lazy"
import { Stack, Card, CardMedia, CardContent, Grid, Skeleton, Typography, StackProps } from "@mui/material"
import Loading from "@components/shared/Loading"
import { TranscriptMeetingHits } from "@interfaces/transcript"
import { TranscriptListContainer } from "./TranscriptListContainer"
import { FetchedMeeting } from "@api/meetings"
import { locationText, publishedAtTextUtc } from "@components/shared/MeetingBasicInfo"
import { Organization } from "@interfaces/organization"
import { ClipShareLeadView } from "@src/interfaces/clipShare"
import { FindALeadContainer } from "./FindALeadContainer"

export interface TranscriptContainerProps {
  meeting: FetchedMeeting
  transcripts: TranscriptMeetingHits
  searchTerms: string[]
  setVideoStartTime: (startTime: { start: number; change: boolean }) => void
  videoCardHeight?: string
}

export const TranscriptContainer: FC<TranscriptContainerProps> = ({
  meeting,
  transcripts,
  searchTerms,
  setVideoStartTime,
  videoCardHeight,
}) => {
  return (
    <Stack direction="column" sx={{ height: videoCardHeight, width: { xs: undefined, lg: "50%" }, flex: 1 }}>
      <TranscriptListContainer
        setVideoStartTime={setVideoStartTime}
        meeting={meeting}
        transcriptHits={transcripts}
        terms={searchTerms}
      />
    </Stack>
  )
}

export const ShareDisplay: FC<{
  isError: boolean
  isLoading: boolean
  meeting: FetchedMeeting | undefined
  organization?: Organization
  transcripts?: TranscriptMeetingHits
  transcriptsLoading?: boolean
  startTime?: number
  searchTerms?: string[]
  stackProps?: StackProps // Allows for custom styling of the parent Stack component
  isLeadView?: boolean
  clipData?: ClipShareLeadView | undefined // If this is a FindALead view, the clip data will be passed in
  clipDataIsLoading?: boolean
}> = ({
  isError,
  isLoading,
  meeting,
  organization,
  transcripts,
  transcriptsLoading,
  startTime,
  searchTerms,
  stackProps,
  isLeadView = false,
  clipData,
  clipDataIsLoading,
}) => {
  const videoPlayerRef = useRef<ReactPlayer>(null)
  const [playingVideo, setPlayingVideo] = useState(true)
  const [isReady, setIsReady] = useState(false)
  const [videoStartTime, setVideoStartTime] = useState<{ start: number; change: boolean }>({ start: 0, change: false })
  const [videoCardHeight, setVideoCardHeight] = useState<string>()
  const videoCardRef = useRef<HTMLDivElement>(null)

  const VideoMetaDataComponent = () => {
    if (!meeting) return null
    const displayedName = organization ? organization.name : meeting.organization_name
    const displayedLocationText = organization
      ? locationText({
          city: organization.city?.name,
          county: organization.county?.name,
          state: organization.state?.name,
        })
      : locationText(meeting as unknown as { city: string; county: string; state: string })
    return (
      <>
        {publishedAtTextUtc(meeting.published_at)}
        <span style={{ fontWeight: "bold" }}>{` ${displayedName} `}</span>
        <>
          {" in "}
          <strong>{displayedLocationText}</strong>
        </>
      </>
    )
  }

  const TranscriptContainerHandler = () => {
    if (setVideoStartTime === undefined || meeting === undefined) return null
    if (isLeadView && clipData) {
      return (
        <FindALeadContainer
          setVideoStartTime={setVideoStartTime}
          clipShare={clipData}
          videoCardHeight={videoCardHeight}
        />
      )
    }
    if (transcriptsLoading || clipDataIsLoading) {
      return (
        <Skeleton
          variant="rectangular"
          sx={{ flex: 1, maxWidth: { md: undefined, lg: "50%", height: videoCardHeight } }}
        />
      )
    }
    if (searchTerms && transcripts) {
      return (
        <TranscriptContainer
          meeting={meeting}
          transcripts={transcripts as TranscriptMeetingHits}
          searchTerms={searchTerms}
          setVideoStartTime={setVideoStartTime}
          videoCardHeight={videoCardHeight}
        />
      )
    }
    return null
  }

  useEffect(() => {
    if (startTime) {
      setVideoStartTime({ start: startTime, change: true })
    }
  }, [startTime])

  useEffect(() => {
    if (isReady && videoStartTime.change) {
      videoPlayerRef.current?.seekTo(videoStartTime.start)
    }
  }, [videoStartTime, isReady])

  useEffect(() => {
    if (videoCardRef.current) {
      setVideoCardHeight(`${videoCardRef.current.clientHeight}px`)
    }
  }, [isLoading, transcripts, meeting, videoCardRef])

  // Handle resizing of the video card
  useEffect(() => {
    const handleResize = () => {
      if (videoCardRef.current) {
        setVideoCardHeight(`${videoCardRef.current.clientHeight}px`)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [videoCardRef])

  return (
    <Stack
      direction={stackProps?.direction ?? { xs: "column", lg: "row" }}
      padding={stackProps?.padding ?? { xs: 1, lg: 4, xl: 8 }}
      spacing={stackProps?.spacing ?? { xs: 4, lg: 4, xl: 8 }}
      margin={stackProps?.margin ?? { xs: 1, md: 8, xl: 8 }}
      marginTop={stackProps?.marginTop ?? { xs: 8, md: 8, xl: 8 }}
      width={stackProps?.width ?? "100%"}
      {...stackProps}
    >
      {isError || !meeting ? (
        <Grid item xs={3}>
          {isLoading ? (
            <Loading />
          ) : (
            <Typography
              sx={{ marginTop: "auto", marginBottom: "auto", marginLeft: "auto", marginRight: "auto" }}
              variant="h3"
            >
              Could Not Display Shared Video
            </Typography>
          )}
        </Grid>
      ) : (
        <>
          <Stack direction="column" width={{ xs: "100%", lg: "45%" }} height={"fit-content"} ref={videoCardRef}>
            <Card
              sx={{
                backgroundColor: "neutral.light",
                boxShadow: 4,
                color: "primary.dark",
              }}
            >
              <CardMedia>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={350} />
                ) : (
                  <ReactPlayer
                    className="share-page-react-player"
                    ref={videoPlayerRef}
                    url={meeting?.source_video_url}
                    playing={playingVideo}
                    onPause={() => setPlayingVideo(false)}
                    onReady={() => setIsReady(true)}
                    muted={true}
                    controls
                    width="100%"
                    style={{
                      backgroundImage: isReady
                        ? "url(https://storage.googleapis.com/cloverleaf-ai-public-assets/audio_recording_thumbnail.png)"
                        : "none",
                      backgroundSize: "cover",
                    }}
                  />
                )}
              </CardMedia>
              <CardContent>
                <Typography variant="h6">{isLoading ? <Skeleton /> : `${meeting.title}`}</Typography>
                <Typography variant="body2">
                  {isLoading ? <Skeleton width={350} /> : <VideoMetaDataComponent />}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
          <TranscriptContainerHandler />
        </>
      )}
    </Stack>
  )
}
