import { FC } from "react"
import { Stack, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import { useLocation, Link, useRouteMatch, Switch, Route } from "react-router-dom"
import { SalesforceButton } from "./SalesforceButton"
import { UserOrganizationAboutForm } from "../../shared/UserAboutForm"
import { getAbout, useSetAbout, useIsSalesforceEnabled, useIsBriefingsEnabled } from "../../../api/users"
import { Credits } from "./Credits"

const About: FC = () => {
  const { data } = getAbout()
  const { mutate } = useSetAbout()

  if (!data) {
    return null
  }

  return <UserOrganizationAboutForm aboutData={data} setAbout={mutate} />
}

const Salesforce: FC = () => <SalesforceButton />

export const SettingsTab: FC = () => {
  const { url } = useRouteMatch()
  const { data, isLoading } = getAbout()
  const locationArray = useLocation().pathname.split("/")
  const location = locationArray[locationArray.length - 1]

  const { data: isSalesforceEnabled } = useIsSalesforceEnabled()
  const { data: isBriefingsEnabled } = useIsBriefingsEnabled()

  return (
    <Stack direction="row" spacing={1} paddingTop={2}>
      <List disablePadding>
        {!isLoading && data !== null && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ paddingRight: 8, borderRadius: 6 }}
              component={Link}
              to={url}
              selected={locationArray.length === 2}
            >
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
        )}
        {isSalesforceEnabled && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ paddingRight: 8, borderRadius: 6 }}
              component={Link}
              to={`${url}/salesforce`}
              selected={location === "salesforce"}
            >
              <ListItemText primary="Salesforce" />
            </ListItemButton>
          </ListItem>
        )}
        {isBriefingsEnabled && (
          <ListItem disablePadding>
            <Credits />
          </ListItem>
        )}
      </List>
      <Switch>
        {isSalesforceEnabled && <Route path={`${url}/salesforce`} component={Salesforce} />}
        <Route path={url} component={About} />
      </Switch>
    </Stack>
  )
}
