import { InputBase, Skeleton } from "@mui/material"
import { FC, useContext } from "react"
import { ClipShare, ClipShareBookmarkView } from "../../../interfaces/clipShare"
import { MeetingContext } from "../../MeetingDetail/Shared/Context"
import { getShareLink } from "./generateUrls"

export const CopyFormInput: FC<{
  clipData: ClipShare | ClipShareBookmarkView | undefined
}> = ({ clipData }) => {
  const { meeting } = useContext(MeetingContext)
  const shareLink = getShareLink(clipData, meeting?.id)

  if (!shareLink) {
    return <Skeleton width="100%" sx={{ fontSize: "3rem" }} />
  }

  return (
    <>
      <InputBase
        value={shareLink}
        color="primary"
        sx={{
          ".MuiInputBase-input": {
            color: "black !important",
            display: "block",
            overflow: "auto",
          },
          display: "block",
          flex: 1,
          width: { xs: "30ch", sm: "35ch", md: "45ch" },
          marginTop: 1,
        }}
        inputProps={{
          "aria-label": "copy",
          sx: {
            "&::placeholder": {
              opacity: "1",
            },
          },
        }}
      />
    </>
  )
}
