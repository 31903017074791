import { Checkbox, FormControlLabel, TextField, Typography, Stack } from "@mui/material"
import { FC, useContext } from "react"
import { unpackSetters } from "@components/shared/unpackSetters"
import { OrganizationFormContext } from "./Context"

export const ProxyOptions: FC = () => {
  const { value: orgValue, setValue: setOrgValue } = useContext(OrganizationFormContext)

  const { proxyDownload, customConfig, shuffleRegion } = orgValue

  const { setProxyDownload, setCustomConfig, setShuffleRegion } = unpackSetters(orgValue, setOrgValue)

  return (
    <Stack spacing={2}>
      <Typography variant="h3">Proxy Options</Typography>
      <Stack direction="row" spacing={2}>
        <FormControlLabel
          control={<Checkbox onClick={() => setProxyDownload(!proxyDownload)} checked={proxyDownload} />}
          label="Proxy Download"
        />
        <FormControlLabel
          control={<Checkbox onClick={() => setShuffleRegion(!shuffleRegion)} checked={shuffleRegion} />}
          label="Shuffle Region"
        />
      </Stack>
      <TextField
        size="small"
        label="Custom Configuration"
        value={customConfig}
        onChange={(event) => setCustomConfig(event.target.value)}
        fullWidth
      />
    </Stack>
  )
}
