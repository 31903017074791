import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  styled,
  Switch,
  Stack,
  Typography,
} from "@mui/material"
import { useFetchNaicsCodes } from "@src/api/admin/naicsCodes"
import { useCloseModalKey, useData, useModalKey } from "@src/components/shared/OpenModalHook"
import { FetchedIndustry } from "@src/interfaces/industry"
import { NaicsCode, NaicsCodeNode } from "@src/interfaces/naicsCode"
import { Dispatch, FC, forwardRef, SetStateAction, useEffect, useMemo, useState } from "react"
import { isEqual } from "lodash"
import { useUpdateIndustry } from "@src/api/admin/industries"
import { useToast } from "@src/components/shared/ToastHook"
import Loading from "@src/components/shared/Loading"
import { buildNaicsTree } from "@src/functions/naicsCode"

import { TreeItem2Props, TreeItem2, TreeItem2Content } from "@mui/x-tree-view/TreeItem2"
import { RichTreeView } from "@mui/x-tree-view/RichTreeView"
import { TreeViewBaseItem } from "@mui/x-tree-view/models"
import { useTreeItem2 } from "@mui/x-tree-view"

export const NAICS_CODES_MODAL_KEY = "NAICS_CODES_MODAL_KEY"

function findNodeById(itemId: string, nodes: NaicsCodeNode[]): NaicsCodeNode | null {
  for (const node of nodes) {
    if (node.code.toString() === itemId) {
      return node
    }
    if (node.children && node.children.length > 0) {
      const result = findNodeById(itemId, node.children)
      if (result) {
        return result
      }
    }
  }
  return null
}

function getAllDescendants(node: NaicsCodeNode): NaicsCodeNode[] {
  let descendants: NaicsCodeNode[] = []
  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      descendants.push(child)
      descendants = descendants.concat(getAllDescendants(child))
    }
  }
  return descendants
}

function getAllAncestors(node: NaicsCodeNode): NaicsCodeNode[] {
  const ancestors: NaicsCodeNode[] = []
  let current = node.parent
  while (current) {
    ancestors.push(current)
    current = current.parent
  }
  return ancestors
}

interface CustomLabelProps {
  children: string
  className: string
  secondaryLabel: string
}

function CustomLabel({ children, className, secondaryLabel }: CustomLabelProps) {
  return (
    <div className={className}>
      <Typography>{children}</Typography>
      {secondaryLabel && (
        <Typography variant="caption" color="secondary" fontWeight={700}>
          {secondaryLabel}
        </Typography>
      )}
    </div>
  )
}

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(0.5, 1),
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}))

const CustomTreeItem = forwardRef(function CustomTreeItem(props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) {
  const { id, itemId, label, disabled, children, ...other } = props
  const treeItemHooks = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const publicApi: any = treeItemHooks.publicAPI // TS can't find getItem but it's there
  const item = publicApi.getItem(props.itemId)

  return (
    <TreeItem2
      {...props}
      ref={ref}
      slots={{
        label: CustomLabel,
        content: CustomTreeItemContent,
      }}
      slotProps={{
        label: { secondaryLabel: item?.secondaryLabel || "" } as CustomLabelProps,
      }}
    />
  )
})

interface NaicsTreeComponentProps {
  naicsCodes: NaicsCode[]
  selectedNaicsCodes: NaicsCode[]
  setSelectedNaicsCodes: Dispatch<SetStateAction<NaicsCode[]>>
  showIncludedIndustries: boolean
}

export type TreeItemWithSecondaryLabel = TreeViewBaseItem & { secondaryLabel?: string }

const NaicsTreeComponent: FC<NaicsTreeComponentProps> = ({
  naicsCodes,
  selectedNaicsCodes,
  setSelectedNaicsCodes,
  showIncludedIndustries,
}) => {
  const naicsCodesMap = new Map(naicsCodes.map((node) => [node.code.toString(), node]))
  const naicsCodesTree = buildNaicsTree(naicsCodes)

  const getNodesToExpandOnInit = () => {
    const expandedSet = new Set<string>()

    selectedNaicsCodes.forEach((node) => {
      let current = findNodeById(node.code.toString(), naicsCodesTree)?.parent
      while (current) {
        expandedSet.add(current.code.toString())
        current = current.parent
      }
    })
    return Array.from(expandedSet)
  }

  const [expandedItems, setExpandedItems] = useState<string[]>(getNodesToExpandOnInit())

  const handleSelectedItemChange = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    const node = findNodeById(itemId, naicsCodesTree)
    if (!node) return // Node not found

    if (isSelected) {
      // Add node and all descendants to selectedNaicsCodes
      const descendants = getAllDescendants(node)
      const nodesToAdd = [node, ...descendants]

      setSelectedNaicsCodes((prevSelected) => {
        const selectedSet = new Map(prevSelected.map((n) => [n.code.toString(), n]))
        nodesToAdd.forEach((n) => selectedSet.set(n.code.toString(), n))
        return Array.from(selectedSet.values())
      })
    } else {
      // Deselect node, its descendants, and its ancestors
      const descendants = getAllDescendants(node)
      const ancestors = getAllAncestors(node)
      const nodesToRemove = [node, ...descendants, ...ancestors]
      const nodesToRemoveSet = new Set(nodesToRemove.map((n) => n.code.toString()))
      setSelectedNaicsCodes((prevSelected) => {
        return prevSelected.filter((selectedNode) => !nodesToRemoveSet.has(selectedNode.code.toString()))
      })
    }
  }

  const getSecondaryLabel = (node: NaicsCodeNode) => {
    let newLabel = `${node.code} - ${node.title}`
    if (showIncludedIndustries) {
      const included_industries = naicsCodesMap.get(node.code.toString())?.industries
      if (included_industries && included_industries.length > 0) {
        newLabel = included_industries.map((industry) => industry.name).join(", ")
      } else {
        newLabel = "Unassigned"
      }
      return newLabel
    }
    return undefined
  }

  const generateTreeItems = (nodes: NaicsCodeNode[]) =>
    nodes.map((node) => {
      const children = node.children ? generateTreeItems(node.children) : []
      const result: TreeItemWithSecondaryLabel = {
        id: node.code.toString(),
        label: `${node.code} - ${node.title}`,
        secondaryLabel: getSecondaryLabel(node),
        children,
      }
      return result
    })

  const treeItems: TreeItemWithSecondaryLabel[] = useMemo(() => {
    return generateTreeItems(naicsCodesTree)
  }, [naicsCodesTree, showIncludedIndustries])

  return (
    <RichTreeView
      items={treeItems}
      selectedItems={selectedNaicsCodes.map((node) => node.code.toString())}
      checkboxSelection
      expandedItems={expandedItems}
      onExpandedItemsChange={(_, expanded) => setExpandedItems(expanded)}
      onItemSelectionToggle={handleSelectedItemChange}
      slots={{ item: CustomTreeItem }}
      multiSelect
    ></RichTreeView>
  )
}

interface NaicsCodesModalProps {
  industry: FetchedIndustry | undefined
}

export const NaicsCodesModal: FC = () => {
  const open = useModalKey(NAICS_CODES_MODAL_KEY)
  const closeModal = useCloseModalKey(NAICS_CODES_MODAL_KEY)
  const modalData: NaicsCodesModalProps | undefined = useData()
  const setToast = useToast()
  const { data: naicsCodes, isLoading: naicsCodesIsLoading } = useFetchNaicsCodes()
  const { mutate: updateIndustry, isLoading: updateIsLoading } = useUpdateIndustry(() =>
    setToast("Industry updated successfully"),
  )

  const [showIncludedIndustries, setShowIncludedIndustries] = useState(false)
  const [selectedNaicsCodes, setSelectedNaicsCodes] = useState<NaicsCode[]>(modalData?.industry?.naics_codes || [])
  const [pendingNaicsCodes, setPendingNaicsCodes] = useState<NaicsCode[]>(modalData?.industry?.naics_codes || [])
  const [naicsCodesChanged, setNaicsCodesChanged] = useState(false)

  useEffect(() => {
    if (modalData?.industry) {
      setSelectedNaicsCodes(modalData.industry.naics_codes || [])
      setPendingNaicsCodes(modalData.industry.naics_codes || [])
    }
  }, [modalData?.industry?.id])

  useEffect(() => {
    setNaicsCodesChanged(!isEqual(selectedNaicsCodes, pendingNaicsCodes))
  }, [selectedNaicsCodes, pendingNaicsCodes])

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="md">
      <DialogTitle>
        <Stack direction="row" spacing={2} justifyContent={"space-between"} alignItems="center">
          <Typography> NAICS Codes : {modalData?.industry?.name}</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showIncludedIndustries}
                  onChange={(event, checked) => setShowIncludedIndustries(checked)}
                />
              }
              label={showIncludedIndustries ? "Hide Included Industries" : "Show Included Industries"}
            />
          </FormGroup>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {naicsCodesIsLoading || updateIsLoading ? (
          <Loading useCloverleafIcon />
        ) : (
          <NaicsTreeComponent
            naicsCodes={naicsCodes || []}
            selectedNaicsCodes={pendingNaicsCodes}
            setSelectedNaicsCodes={setPendingNaicsCodes}
            showIncludedIndustries={showIncludedIndustries}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <Button
          onClick={() => {
            if (modalData?.industry) {
              const naicsCodeIdsToUpdate = new Set(pendingNaicsCodes.map((code) => code.id))
              updateIndustry({
                id: modalData.industry.id,
                naics_code_ids: Array.from(naicsCodeIdsToUpdate),
              })
            }
          }}
          disabled={!naicsCodesChanged || updateIsLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
