import { Typography, Box, Stack } from "@mui/material"
import Highlighter from "react-highlight-words"
import { FC, useMemo, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Transcript } from "../../../interfaces/transcript"
import { centerTermInText } from "../../shared/textTransformers"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useFetchMeetingTranscripts } from "../../../api/meetings"
import { generateParamsString } from "../../../functions/generateURL"
import { getHighlighterRegexArray } from "../../../functions/highlighter"

export const TEXT_PADDING = 64
const ADJACENT_TRANSCRIPTS = 2

export const TranscriptListItemDescription: FC<{
  transcript: Transcript
  searchTerm: string
  isSelected?: boolean
}> = ({ transcript, searchTerm, isSelected }) => {
  const [isExpanded] = useState(false)
  const history = useHistory()
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useFetchMeetingTranscripts(meetingId)
  const searchString = useLocation().search

  const [prevTranscripts, nextTranscripts] = useMemo(() => {
    const prev: Transcript[] = []
    const next: Transcript[] = []
    const mainTranscriptIndex = data?.transcripts.findIndex((otherTranscript) => otherTranscript.id === transcript.id)
    if (mainTranscriptIndex !== undefined) {
      data?.transcripts.forEach((otherTranscript, index) => {
        if (index >= mainTranscriptIndex - ADJACENT_TRANSCRIPTS && index < mainTranscriptIndex) {
          prev.push(otherTranscript)
        } else if (index > mainTranscriptIndex && index <= mainTranscriptIndex + ADJACENT_TRANSCRIPTS) {
          next.push(otherTranscript)
        }
      })
    }
    return [prev, next]
  }, [transcript.id, data])

  const { snippet } = centerTermInText(transcript.raw_text, TEXT_PADDING, [searchTerm])
  let text
  if (isSelected) {
    text = (
      <Typography key={transcript.id}>
        <Highlighter textToHighlight={`...${snippet}...`} searchWords={getHighlighterRegexArray([searchTerm])} />
      </Typography>
    )
  } else {
    text = isExpanded ? (
      <Stack spacing={2}>
        {isExpanded &&
          prevTranscripts.map((transcript) => (
            <Typography key={transcript.id}>
              <Highlighter textToHighlight={transcript.raw_text} searchWords={getHighlighterRegexArray([searchTerm])} />
            </Typography>
          ))}
        <Typography key={transcript.id}>
          <Highlighter textToHighlight={transcript.raw_text} searchWords={getHighlighterRegexArray([searchTerm])} />
        </Typography>
        {isExpanded &&
          nextTranscripts.map((transcript) => (
            <Typography key={transcript.id}>
              <Highlighter
                key={transcript.id}
                textToHighlight={transcript.raw_text}
                searchWords={getHighlighterRegexArray([searchTerm])}
              />
            </Typography>
          ))}
      </Stack>
    ) : (
      <Typography key={transcript.id}>
        <Highlighter textToHighlight={`...${snippet}...`} searchWords={getHighlighterRegexArray([searchTerm])} />
      </Typography>
    )
  }

  return (
    <Box marginY={1}>
      <Typography
        variant="body2"
        width="100%"
        sx={{ cursor: "pointer", display: { xs: "none", sm: "inherit" }, whiteSpace: "pre-line" }}
        onClick={() => {
          history.push({
            search: generateParamsString({
              transcriptId: transcript.id,
              activeTerm: searchTerm,
              prevParams: searchString,
            }),
          })
          document.getElementById("meeting-video-player-anchor-element")?.scrollIntoView()
        }}
      >
        {text}
      </Typography>
      <Typography
        variant="body2"
        width="100%"
        padding={1}
        sx={{ display: { xs: "inherit", sm: "none", whiteSpace: "pre-line" } }}
      >
        {text}
      </Typography>
    </Box>
  )
}
