import { FC, useState } from "react"
import { Button, Grid, Menu, MenuItem } from "@mui/material"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "@api/interfaces"
import { useCalculateMetadata, useRunTranscriptionJobs } from "@api/meetings"
import { ActionsDialogs } from "./ActionsDialogs"
import { useIsContractor, useIsSuperAdmin } from "@api/users"
import { useOpenModalKey } from "@components/shared/OpenModalHook"
import { EDIT_TRANSCRIPTION_JOB_MODAL_KEY } from "@components/shared/TranscriptionJob/EditModal"
import { EditModal } from "@components/shared/TranscriptionJob/EditModal"
import { SPAM_CHECKER_MODAL_KEY, SpamCheckerModal } from "@src/components/shared/SpamCheckerModal"
import { useAnalyzeOpportunities } from "@src/api/admin/opportunities"
import { useToast } from "@src/components/shared/ToastHook"

export const ActionsMenu: FC<{ meetingIds: number[] }> = ({ meetingIds }) => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const disableActions = meetingIds.length === 0
  const openTjEditModal = useOpenModalKey(EDIT_TRANSCRIPTION_JOB_MODAL_KEY)
  const openSpamCheckModal = useOpenModalKey(SPAM_CHECKER_MODAL_KEY)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openDialog, setOpenDialog] = useState("")
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openDeleteTranscriptsConfirm, setOpenDeleteTranscriptsConfirm] = useState(false)
  const [openGenerateGeneralBriefingsConfirm, setOpenGenerateGeneralBriefingsConfirm] = useState(false)
  const [openOverrideConfirm, setOpenOverrideConfirm] = useState(false)
  const [openUpdateSpam, setOpenUpdateSpam] = useState("")
  const [openSpamCheck, setOpenSpamCheck] = useState(false)
  const { data: isSuperAdmin } = useIsSuperAdmin()
  const { data: isContractor } = useIsContractor()
  const toast = useToast()

  const { mutate: analyzeOpportunities } = useAnalyzeOpportunities()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSpamCheckOpen = () => {
    setOpenSpamCheck(true)
    openSpamCheckModal()
  }

  const { mutate: runTranscriptionJobs } = useRunTranscriptionJobs(organizationId)
  const { mutate: calculateMetadata } = useCalculateMetadata(organizationId)

  const menuItemConfigs = [
    {
      key: "new",
      label: "New Meeting",
      disabled: false,
      onClick: () => setOpenDialog("new"),
      requiredRole: isSuperAdmin,
    },
    {
      key: "edit",
      label: "Edit Meeting",
      disabled: meetingIds.length !== 1,
      onClick: () => setOpenDialog("edit"),
      requiredRole: isSuperAdmin,
    },
    {
      key: "runTranscriptionJobs",
      label: "Run Job(s)",
      disabled: disableActions,
      onClick: () => runTranscriptionJobs({ meetingIds }),
      requiredRole: isSuperAdmin,
    },
    {
      key: "calculateMetadata",
      label: "Calculate Metadata",
      disabled: disableActions,
      onClick: () => calculateMetadata({ meetingIds }),
      requiredRole: isSuperAdmin,
    },
    {
      key: "deleteMeeting",
      label: "Delete Meeting(s)",
      disabled: disableActions,
      onClick: () => setOpenConfirm(true),
      requiredRole: isSuperAdmin || isContractor,
    },
    {
      key: "deleteTranscripts",
      label: "Delete Transcripts for Meeting(s)",
      disabled: disableActions,
      onClick: () => setOpenDeleteTranscriptsConfirm(true),
      requiredRole: isSuperAdmin,
    },
    {
      key: "forceRun",
      label: "Force Run Job(s)",
      disabled: disableActions,
      onClick: () => setOpenOverrideConfirm(true),
      requiredRole: isSuperAdmin,
    },
    {
      key: "spam",
      label: "Label Meeting as Spam",
      disabled: disableActions,
      onClick: () => setOpenUpdateSpam("spam"),
      requiredRole: isSuperAdmin,
    },
    {
      key: "notSpam",
      label: "Remove Spam Label from Meeting",
      disabled: disableActions,
      onClick: () => setOpenUpdateSpam("*not* spam"),
      requiredRole: isSuperAdmin,
    },
    {
      key: "generateGeneralBriefings",
      label: "Generate general briefing(s)",
      disabled: disableActions,
      onClick: () => setOpenGenerateGeneralBriefingsConfirm(true),
      requiredRole: isSuperAdmin,
    },
    {
      key: "editTranscriptionJob",
      label: "Edit Transcription Job",
      disabled: meetingIds.length !== 1,
      requiredRole: isSuperAdmin,
      onClick: () => openTjEditModal(),
    },
    {
      key: "runSpamCheck",
      label: "Run Spam Check",
      disabled: disableActions,
      requiredRole: isSuperAdmin,
      onClick: () => handleSpamCheckOpen(),
    },
    {
      key: "analyzeOpportunities",
      label: "Analyze Opportunities",
      disabled: disableActions,
      requiredRole: isSuperAdmin,
      onClick: () => {
        analyzeOpportunities({ id: meetingIds })
        toast(`Analyzing opportunities in meeting(s) ${meetingIds.join(", ")}`)
        handleClose()
      },
    },
  ]

  return (
    <Grid>
      <>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="large"
          variant="contained"
          sx={{ fontSize: 20 }}
        >
          ACTIONS
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          {menuItemConfigs.map((config) => (
            <MenuItem key={config.key} onClick={config.onClick} disabled={config.disabled || !config.requiredRole}>
              {config.label}
            </MenuItem>
          ))}
        </Menu>
        <ActionsDialogs
          meetingIds={meetingIds}
          handleClose={handleClose}
          openNewMeetingDialog={openDialog}
          setOpenNewMeetingDialog={setOpenDialog}
          openDeleteMeetingConfirm={openConfirm}
          setOpenDeleteMeetingConfirm={setOpenConfirm}
          openDeleteTranscriptsConfirm={openDeleteTranscriptsConfirm}
          setOpenDeleteTranscriptsConfirm={setOpenDeleteTranscriptsConfirm}
          openGenerateGeneralBriefingsConfirm={openGenerateGeneralBriefingsConfirm}
          setOpenGenerateGeneralBriefingsConfirm={setOpenGenerateGeneralBriefingsConfirm}
          openOverrideConfirm={openOverrideConfirm}
          setOpenOverrideConfirm={setOpenOverrideConfirm}
          openUpdateSpam={openUpdateSpam}
          setOpenUpdateSpam={setOpenUpdateSpam}
        />

        <EditModal meetingId={meetingIds[0]} />
        {/* Setting open via state so we don't render the component in the background and fire off unnecessary api calls */}
        {openSpamCheck && <SpamCheckerModal meetingIds={meetingIds} handleClose={() => setOpenSpamCheck(false)} />}
      </>
    </Grid>
  )
}
