import { FC, useEffect } from "react"
import { Box, Button, Stack, StackProps, Typography } from "@mui/material"
import { useFetchMeetingNoAuth } from "@api/promotions/meetings"
import { ShareDisplay } from "@components/Promo/share/ShareDisplay"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useFetchIndustry } from "@src/api/promotions/industries"
import { theme } from "@src/theme"
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material"
import Loading from "@src/components/shared/Loading"
import { useFetchClipShare } from "@src/api/promotions/clip_shares"
import { ClipType } from "@src/interfaces/clipShare"
import { CreateSearchLogParams, useCreateSearchLogs } from "@src/api/promotions/searchlogs"
import { PageOfOrigin } from "@src/interfaces/searchLogs"

export const MeetingShare: FC = () => {
  const { leadId: industryId, meetingId, clipId } = useParams<{ leadId: string; meetingId: string; clipId: string }>()
  const { data, isLoading: meetingIsLoading, isError } = useFetchMeetingNoAuth(meetingId)
  const { data: industryData, isLoading: industryDataIsLoading } = useFetchIndustry(industryId)
  const { data: clipShareData, isLoading: clipShareDataIsLoading } = useFetchClipShare(
    parseInt(clipId),
    true,
    ClipType.Industry,
  )
  const { mutate: createSearchLog } = useCreateSearchLogs()
  const allParams = new URLSearchParams(useLocation().search)
  const videoStartTime = allParams.get("start_time")
  const totalHits = allParams.get("totalHits")

  const history = useHistory()

  const handleGoBack = () => {
    history.push(`/find-a-lead/${industryId}`)
  }

  const handleShowMore = () => {
    history.push(`/find-a-lead/${industryId}/form`)
  }

  useEffect(() => {
    if (industryData?.id && clipShareData) {
      const searchLogData: CreateSearchLogParams = {
        data: {
          industry_id: industryData.id,
          search_terms: clipShareData.keyword ? [clipShareData.keyword] : undefined,
        },
        meeting_id: parseInt(meetingId),
        page_of_origin: PageOfOrigin.FindALeadDetail,
      }
      createSearchLog(searchLogData)
    }
  }, [industryData?.id, clipShareData?.keyword])

  const stackProps: StackProps = {
    padding: { xs: 1, lg: 0, xl: 0 },
    marginX: { xs: 1, md: 1, lg: 3, xl: 8 },
    marginTop: { xs: 8, md: 8, xl: 8 },
    paddingX: { xs: 4, sm: 8, lg: 5, xl: 5 },
    paddingBottom: 0,
    marginBottom: 4,
    spacing: { xs: 4, lg: 4, xl: 8 },
  }

  const childStackProps: StackProps = {
    padding: undefined,
    margin: undefined,
    marginTop: undefined,
    paddingBottom: undefined,
    marginBottom: undefined,
    spacing: { xs: 4, lg: 6, xl: 8 },
    alignItems: { xs: undefined, lg: "stretch" },
  }

  return meetingIsLoading || industryDataIsLoading ? (
    <Loading useCloverleafIcon />
  ) : (
    <Stack width="100%" alignItems="center" {...stackProps}>
      {industryData && (
        <Stack paddingTop={4} display={"flex"} justifyContent={"start"} width={"100%"}>
          <Typography variant="h1" color="primary.dark" fontSize={32}>
            Here's a recent pre-RFP meeting in <span style={{ color: "#4cb2dc" }}>{industryData.name}</span>
          </Typography>
        </Stack>
      )}
      <Box bgcolor={theme.palette.grey[100]} padding={4} width={"100%"} boxSizing={"border-box"}>
        <ShareDisplay
          isError={isError}
          isLoading={meetingIsLoading || industryDataIsLoading}
          meeting={data?.meeting}
          startTime={videoStartTime ? parseInt(videoStartTime) : undefined}
          stackProps={childStackProps}
          isLeadView={true}
          clipData={clipShareData}
          clipDataIsLoading={clipShareDataIsLoading}
        />
      </Box>
      <Box width={"100%"} height={"20%"}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.grey[300], color: "black", fontWeight: 600 }}
            onClick={handleGoBack}
          >
            <ArrowCircleLeft sx={{ color: "rgba(4,4,24,1)" }} /> &nbsp; Go Back
          </Button>
          <Button
            variant="contained"
            style={{ background: "linear-gradient(40deg, rgba(4,4,24,1) 87%, rgba(4,26,55,.88))", color: "white" }}
            onClick={handleShowMore}
          >
            View {totalHits ?? "More"} Opportunities <ArrowCircleRight sx={{ ml: 1, color: "#4cb2dc" }} />
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}
