import { IconButton, Menu, MenuItem } from "@mui/material"
import { FC, useState } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"

export const DisplayWidgetActions: FC<{ openAction: (actionType: string) => void }> = ({ openAction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton aria-label="more" size="small" onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            openAction("edit")
          }}
        >
          Edit Widget
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            openAction("clone")
          }}
        >
          Clone Widget
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null)
            openAction("delete")
          }}
        >
          Delete Widget
        </MenuItem>
      </Menu>
    </>
  )
}
