import { FC, useState, useContext } from "react"
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material"
import { AdminUserContext } from "./context"
import { useSendBriefingFromUser } from "../../../api/admin/users"
import { useToast } from "../../shared/ToastHook"

export const SendBriefingModal: FC<{
  handleClose: () => void
  open: boolean
}> = ({ handleClose, open }) => {
  const contextData = useContext(AdminUserContext)
  const { ids } = contextData
  const [meetingId, setMeetingId] = useState("")
  const toast = useToast()
  const { mutate: sendBriefing } = useSendBriefingFromUser(ids[0], () => toast("Briefing request received"))

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>Send Briefing</DialogTitle>
      <DialogContent>
        <TextField value={meetingId} onChange={(event) => setMeetingId(event.target.value)} label="Meeting Id" />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => sendBriefing({ meeting_id: meetingId })}>
          Send Briefing
        </Button>
      </DialogActions>
    </Dialog>
  )
}
