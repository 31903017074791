import { useQuery } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { Job } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { AxiosInstance } from "axios"

const resourcePath = "/content-acquisition/videos"

export const useFetchVideoJobs = (id?: number, params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}/${id}/jobs${queryParams}`

  return useQuery(paramBuilder.toQueryKey("content-acquisition-video-jobs"), async () => {
    const response = await (await axiosInstance).get<Job[]>(uri)

    return response.data
  })
}

export const fetchVideoJobs = async (axiosInstance: Promise<AxiosInstance>, id: number, params?: CommonUrlParams) => {
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}/${id}/jobs${queryParams}`
  const response = await (await axiosInstance).get<Job[]>(uri)
  return response.data
}
