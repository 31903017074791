import { createContext, FC, useMemo, useContext } from "react"
import axios, { AxiosHeaders, AxiosInstance } from "axios"
import { getBaseUrl } from "./axiosInstance"

const NoAuthAxiosInstanceContext = createContext<Promise<AxiosInstance>>(new Promise(() => axios.create()))

export const useAuthAxiosNoAuth = () => {
  return useContext(NoAuthAxiosInstanceContext)
}

export const AxiosProviderNoAuth: FC = ({ children }) => {
  const axiosInstance = useMemo(async () => {
    const newAxiosInstance = axios.create({
      baseURL: getBaseUrl(),
    })

    newAxiosInstance.interceptors.request.use((config) => {
      const headers = new AxiosHeaders({
        "content-type": "application/json",
        ...config.headers,
      })
      const resultConfig = {
        ...config,
        headers,
      }
      return resultConfig
    })

    newAxiosInstance.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        return Promise.reject(error)
      },
    )

    return newAxiosInstance
  }, [])

  return <NoAuthAxiosInstanceContext.Provider value={axiosInstance}>{children}</NoAuthAxiosInstanceContext.Provider>
}
