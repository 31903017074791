import { FC } from "react"
import { Chip } from "@mui/material"

export const SearchTermChips: FC<{
  termHits: { [index: string]: number }
  onDelete?: (term: string) => void
}> = ({ termHits, onDelete }) => {
  const sortedTermHits = Object.entries(termHits).sort((a, b) => b[1] - a[1])

  return (
    <>
      {sortedTermHits.map(([term, count]) => (
        <Chip
          key={`chip-${term}-${count}`}
          label={term}
          onDelete={() => {
            if (onDelete) {
              onDelete(term)
            }
          }}
          icon={<Chip size="small" label={count} />}
        />
      ))}
    </>
  )
}
