import { useQuery, useQueries, useMutation } from "react-query"
import { flattenDeep } from "lodash"

import { useAuthAxios } from "./axiosInstance"
import { useAuthAxiosNoAuth } from "./axiosInstanceNoAuth"
import { Meeting } from "../interfaces/meeting"
import { FilterParams } from "../components/Home/interface"
import { Transcript } from "../interfaces/transcript"
import { AxiosInstance } from "axios"

let prefix = "/search"
if (!process.env.REACT_APP_GOOGLE_ENV) {
  const searchHostUrl = process.env.REACT_APP_SEARCH_API_URL || "http://localhost:8000"
  prefix = `${searchHostUrl}${prefix}`
}

export interface ElasticSearchAggResult {
  id: number
  hits: number
  time: number
  already_viewed: boolean
  terms: {
    term: string
    count: number
  }[]
  transcripts: {
    id: number
    text: string
    start_time: number
  }[]
}

export interface FetchedSearch {
  meetings: Meeting[]
  meeting_hits: ElasticSearchAggResult[]
  total_meeting_hits: number
  total_hits: number
  already_viewed: { [key: number]: boolean }
}

export const useSearch = (searchParams: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const queryKey = ["search", flattenDeep([...searchParams.entries()]).join(",")]

  // JSON.stringify will make sure the query matches only itself
  return useQuery(
    queryKey,
    async () => {
      const response = await (await axiosInstance).get<FetchedSearch>(`${prefix}?${searchParams.toString()}`)

      return response.data
    },
    {
      enabled:
        (searchParams.has("search-terms") || searchParams.has("must-include-terms")) &&
        searchParams.getAll("date-range").length >= 2,
    },
  )
}

export const useMeetingSearch = (
  searchParams: URLSearchParams | string[][],
  meetingId: string | undefined,
  noAuth = false,
) => {
  const axiosInstance = useAuthAxios()
  const noAuthAxiosInstance = useAuthAxiosNoAuth()
  let axios: Promise<AxiosInstance>
  let route = ""
  if (noAuth) {
    axios = noAuthAxiosInstance
    route = "promotion"
  } else {
    axios = axiosInstance
    route = "meeting"
  }
  const meetingSearchParams = new URLSearchParams(searchParams)
  const terms = meetingSearchParams.getAll("terms")
  meetingSearchParams.delete("transcript_id")
  meetingSearchParams.delete("active-term")
  meetingSearchParams.delete("terms")
  terms.forEach((term) => meetingSearchParams.append("search-terms", term))

  const queryKey = [`meeting-search-${meetingId}`, flattenDeep([...meetingSearchParams.entries()]).join(",")]

  return useQuery(
    queryKey,
    async () => {
      const response = await (
        await axios
      ).get<{
        total_hits: number
        terms: [
          {
            term: string
            hits: number
            transcripts: Transcript[]
          },
        ]
        transcripts: Record<number, Transcript>
      }>(`${prefix}/${route}/${meetingId}?${meetingSearchParams.toString()}`)

      return response.data
    },
    {
      enabled:
        ((Array.isArray(searchParams) && searchParams.length > 0) ||
          (searchParams instanceof URLSearchParams && searchParams.has("terms"))) &&
        meetingId !== undefined,
    },
  )
}

export const useHits = (term: string, filterParams: FilterParams, dateRange: [Date, Date]) => {
  const axiosInstance = useAuthAxios()
  const searchParams = new URLSearchParams()
  searchParams.set("term", term)
  searchParams.set("filter-params", btoa(JSON.stringify(filterParams)))
  searchParams.append("date-range", dateRange[0].valueOf().toString())
  searchParams.append("date-range", dateRange[1].valueOf().toString())

  const queryKey = ["search", "hits", flattenDeep([...searchParams.entries()]).join(",")]

  return useQuery(
    queryKey,
    async () => {
      const response = await (await axiosInstance).get<number>(`${prefix}/hits?${searchParams.toString()}`)

      return response.data
    },
    {
      enabled: Boolean(term) && dateRange.length >= 2,
    },
  )
}

export const useHitsOverTime = (
  searchTerms: string[],
  searchParams: URLSearchParams,
  timeUnit: "day" | "week" | "month",
) => {
  const axiosInstance = useAuthAxios()
  searchParams.set("time-unit", timeUnit)

  const queryKeyBase = ["search", "hitsOverTime", flattenDeep([...searchParams.entries()]).join(",")]

  return useQueries(
    searchTerms.map((term) => {
      const searchParamsCopy = new URLSearchParams(searchParams)
      searchParamsCopy.set("search-terms", term)

      return {
        queryKey: [...queryKeyBase, term],
        queryFn: async () => {
          const response = await (
            await axiosInstance
          ).get<{ time: number; hits: number }[]>(`${prefix}/hits-over-time?${searchParamsCopy.toString()}`)

          return response.data
        },
      }
    }),
  )
}

export const useHitsPerGeoOverTime = (
  searchParams: URLSearchParams,
  timeUnit: "day" | "week" | "month",
  geographyType: "city" | "county" | "state",
) => {
  const axiosInstance = useAuthAxios()
  searchParams.set("time-unit", timeUnit)
  searchParams.set("geography-type", geographyType)

  const queryKey = ["search", "hitsPerGeoOverTime", flattenDeep([...searchParams.entries()]).join(",")]

  return useQuery(
    queryKey,
    async () => {
      const response = await (
        await axiosInstance
      ).get<{ time: number; hits: { id: number; count: number }[] }[]>(
        `${prefix}/hits-over-time?${searchParams.toString()}`,
      )

      return response.data
    },
    {
      enabled: Boolean(searchParams.has("search-terms") && timeUnit && geographyType),
    },
  )
}

export const useLogTranscriptClick = (transcriptId: number | string, terms: string[]) => {
  const axiosInstance = useAuthAxios()

  return useMutation(async () => {
    return (await axiosInstance).post(`${prefix}/log-transcript-click`, {
      transcript_id: transcriptId,
      terms,
    })
  })
}
