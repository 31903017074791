import { Box, Button, Grid, Stack, TextField, Tooltip } from "@mui/material"
import { GeneralStepProps } from "@src/interfaces/newUserOrgForm"
import { CreateUserOrganization } from "@src/interfaces/userOrganization"
import { FC, useEffect, useRef, useState } from "react"
import { DialogActionsContainer } from "../NewUserOrganizationWizardGeneralContent"
import { UserOrganizationFormStep3User, CreateUserData } from "@src/interfaces/user"
import { RolesMap } from "@src/interfaces/roles"
import {
  AddRemoveFormComponentForObject,
  EnumMappings,
  FieldAttributes,
} from "@src/components/shared/AddRemoveFormComponent"
import { useFetchAdminRoles } from "@src/api/roles"
import Loading from "@src/components/shared/Loading"
import {
  UserOrgWizardCsvUser,
  UserOrgWizardUserCsvRow,
  handleUserOrgWizardCsvRow,
} from "../../UsersSection/csvManagement/parseRowFunctions"
import Info from "@mui/icons-material/Info"
import Papa from "papaparse"

export const UserOrgFormFields: FC<GeneralStepProps> = ({
  newUserOrganization,
  setNewUserOrganization,
  setStep,
  handleOnClose,
}) => {
  const [localNewUserOrganization, setLocalNewUserOrganization] =
    useState<Partial<CreateUserOrganization>>(newUserOrganization)

  const handleClickNext = () => {
    setNewUserOrganization(localNewUserOrganization)
    setStep((prevVal) => prevVal + 1)
  }

  const [userList, setUserList] = useState<UserOrganizationFormStep3User[]>([])
  const fileInputRef = useRef<null | HTMLInputElement>(null)

  useEffect(() => {
    if (localNewUserOrganization.user_list) {
      const user_list: UserOrganizationFormStep3User[] = localNewUserOrganization.user_list.map((user) => {
        return {
          email: user.email,
          requires_signals_notifications: user.requires_signals_notifications,
          requires_account_summary_notifications: user.requires_account_summary_notifications,
          role_ids: user.role_ids,
          credit_limit: user.credit_limit,
          jobTitle: user.about?.jobTitle || "",
        }
      })
      setUserList(user_list)
    }
  }, [localNewUserOrganization.user_list])

  const defaultUserCreateObject: UserOrganizationFormStep3User = {
    email: "",
    requires_signals_notifications: false,
    requires_account_summary_notifications: false,
    role_ids: [RolesMap.Basic], // Defaults to Basic User
    credit_limit: newUserOrganization.briefings_enabled ? 0 : undefined,
    jobTitle: "",
  }

  const handleClickBack = () => {
    setStep((prevVal) => prevVal - 1)
  }

  const { data: rolesAllowed, isLoading } = useFetchAdminRoles()

  const [RolesEnumMapping, setRolesEnumMapping] = useState<EnumMappings>()
  const [rolesMap, setRolesMap] = useState(new Map<string, number>())

  useEffect(() => {
    if (rolesAllowed) {
      const rolesEnumMapping: EnumMappings = rolesAllowed.roles.map((role) => ({
        label: role.name,
        value: role.id,
      }))
      const rolesEnumMap = new Map<string, number>()
      rolesAllowed.roles.forEach((role) => rolesEnumMap.set(role.name.toLowerCase(), role.id))
      setRolesEnumMapping(rolesEnumMapping)
      setRolesMap(rolesEnumMap)
    }
  }, [rolesAllowed])

  const handleCsvUsers = (csvUsers: UserOrgWizardCsvUser[]) => {
    const new_user_list: CreateUserData[] = csvUsers.map((user) => {
      const temp_user: CreateUserData = {
        email: user.email,
        requires_signals_notifications: user.requires_signals_notifications,
        requires_account_summary_notifications: user.requires_account_summary_notifications,
        role_ids: user.role_id ? [user.role_id] : [],
        credit_limit: localNewUserOrganization.briefings_enabled ? user.credit_limit ?? 0 : undefined,
        about: {
          jobTitle: user.job_title,
          competitors: localNewUserOrganization.default_user_configurations?.competitors,
          goals: localNewUserOrganization.default_user_configurations?.goals,
          industry: localNewUserOrganization.industry ?? undefined,
        },
      }
      return temp_user
    })
    setLocalNewUserOrganization((prevVal) => ({
      ...prevVal,
      user_list: [...(prevVal.user_list ?? []), ...new_user_list],
    }))
  }

  const UserFieldAttributes: FieldAttributes = {
    email: { label: "Email", sx: { flexGrow: 1, maxWidth: "25%" } },
    requires_signals_notifications: { label: "Signals Notifications" },
    requires_account_summary_notifications: { label: "Account Summary Notifications" },
    role_ids: { label: "Role", enumMappings: RolesEnumMapping },
    credit_limit: { label: "Credit Limit", disabled: Boolean(!newUserOrganization.briefings_enabled) },
    jobTitle: { label: "Job Title" },
  }

  const handleChange = (user_list: UserOrganizationFormStep3User[]) => {
    const new_user_list: CreateUserData[] = user_list.map((user) => {
      const temp_user: CreateUserData = {
        email: user.email,
        requires_signals_notifications: user.requires_signals_notifications,
        requires_account_summary_notifications: user.requires_account_summary_notifications,
        role_ids: user.role_ids,
        credit_limit: user.credit_limit,
        about: {
          jobTitle: user.jobTitle,
          competitors: localNewUserOrganization.default_user_configurations?.competitors,
          goals: localNewUserOrganization.default_user_configurations?.goals,
          industry: localNewUserOrganization.industry ?? undefined,
        },
      }
      return temp_user
    })
    setLocalNewUserOrganization({ ...localNewUserOrganization, user_list: new_user_list })
  }

  const handleCsvUpload = async () => {
    if (!fileInputRef.current?.files?.[0]) {
      return
    }
    Papa.parse<UserOrgWizardUserCsvRow>(fileInputRef.current?.files[0], {
      header: true,
      transformHeader: (header) => header.replaceAll(" ", "_"), // remove spaces from headers
      complete: (results) => {
        const csvUsersResults = results.data.map((row) => handleUserOrgWizardCsvRow(row, rolesMap))
        handleCsvUsers(csvUsersResults)
      },
    })
  }

  return (
    // SETTING USERS FOR THE ORG HERE
    <>
      <Grid item>
        {isLoading ? (
          <Loading useCloverleafIcon />
        ) : (
          <Box position="relative">
            <Box>
              <AddRemoveFormComponentForObject
                objectName="Users"
                fieldNameAttributes={UserFieldAttributes}
                data={userList}
                handleChange={handleChange}
                giveEmptyObject={() => defaultUserCreateObject}
              />
            </Box>
            <Stack
              spacing={2}
              direction="row"
              sx={{ position: "absolute", top: 0, right: 0 }}
              alignItems={"center"}
              alignContent={"baseline"}
            >
              <Button size="small" variant="contained" onClick={() => fileInputRef.current?.click()}>
                Import CSV
              </Button>
              <TextField
                type="file"
                inputProps={{ ref: fileInputRef }}
                sx={{ display: "none" }}
                onChange={async () => {
                  handleCsvUpload()
                }}
              />
              <Button
                variant="text"
                download="user-org-template.csv"
                href={window.URL.createObjectURL(
                  new Blob(
                    [
                      "email,role,requires signals notifications,requires account summary notifications,credit limit,job title\n[email],[basic/admin/super admin],[true/false],[true/false],[0-100+],[job title]",
                    ],
                    {
                      type: "text/csv",
                    },
                  ),
                )}
              >
                Download CSV Template
              </Button>
              <Tooltip title="Import a CSV file with the format specified in template">
                <Info />
              </Tooltip>
            </Stack>
          </Box>
        )}
      </Grid>
      <DialogActionsContainer
        handleOnClose={handleOnClose}
        handleOnNext={handleClickNext}
        handleOnBack={handleClickBack}
      />
    </>
  )
}
