import { useFetchCurrentUser, useIsFreeUser } from "../api/users"
import { pendoInit } from "../config/pendo"
import { FC, useEffect } from "react"

export const Init: FC = () => {
  const { data: currentUserData } = useFetchCurrentUser()
  const { data: isFreeUser } = useIsFreeUser()
  const pathname = window.location.pathname
  useEffect(() => {
    if (currentUserData) {
      if (isFreeUser && !pathname.includes("/share")) {
        window.location.assign("/free-account")
      }
      pendoInit(currentUserData)
    } else if (pathname.includes("/find-a-lead")) {
      pendoInit(undefined, true)
    }
  }, [isFreeUser])

  return null
}
