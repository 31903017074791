import { FC, useContext } from "react"
import { NativeSelect, Dialog, DialogTitle, DialogContent } from "@mui/material"
import { MeetingSearchFormContext } from "."
import { useCloseModalKey, useModalKey } from "../OpenModalHook"
import { unpackSetters } from "../unpackSetters"

export const dateRangeModalKey = "DATE_RANGE_MODAL"

const daysMillisecond = 1000 * 60 * 60 * 24
function toDayStart(milliseconds: number) {
  return Math.floor(milliseconds / daysMillisecond) * daysMillisecond
}

export const DateRangeModal: FC = () => {
  const { searchFormData, setSearchFormData } = useContext(MeetingSearchFormContext)
  const { searchDateRange } = searchFormData
  const { setSearchDateRange } = unpackSetters(searchFormData, setSearchFormData)
  const closeModalKey = useCloseModalKey(dateRangeModalKey)
  const open = useModalKey(dateRangeModalKey)

  return (
    <Dialog open={open} onClose={closeModalKey} fullWidth>
      <DialogTitle>Search Date Range</DialogTitle>
      <DialogContent>
        <NativeSelect
          value={searchDateRange[0].valueOf()}
          onChange={(event) => {
            setSearchDateRange((prevValue) => [new Date(parseInt(event.target.value, 10)), prevValue[1]])
            closeModalKey()
          }}
          inputProps={{
            name: "days previous",
            id: "uncontrolled-native",
          }}
          sx={{ display: { xs: "flex", sm: "none" } }}
        >
          <option value={new Date(toDayStart(Date.now() - 7 * daysMillisecond)).valueOf()}>Last 7 Days</option>
          <option value={new Date(toDayStart(Date.now() - 30 * daysMillisecond)).valueOf()}>Last 30 Days</option>
          <option value={new Date(toDayStart(Date.now() - 90 * daysMillisecond)).valueOf()}>Last 90 Days</option>
        </NativeSelect>
      </DialogContent>
    </Dialog>
  )
}
