import { FC, useState, ReactNode } from "react"
import { Button, Popover } from "@mui/material"

export const PopoverButton: FC<{
  title: string
  contents: ReactNode | undefined | null
  variant: "text" | "outlined" | "contained"
}> = ({ title, contents, variant }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? `${title}-popover` : undefined

  return (
    <>
      <Button
        aria-describedby={`${title}-popover`}
        color="primary"
        variant={variant || "outlined"}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {contents}
      </Popover>
    </>
  )
}
