import { FC, useContext, useState } from "react"
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { AdditionalDetails } from "../../Home/interface"
import { useCreateCampaign } from "../../../api/campaigns"
import { useCloseModalKey, useModalKey } from "../OpenModalHook"
import { useHistory, useLocation } from "react-router-dom"
import { MeetingSearchFormContext } from "."
import { SearchQueryParams } from "../../../interfaces/search"
import { EmailCadence } from "@src/interfaces/campaign"

export const campaignSaveModalKey = "SAVE_CAMPAIGN_MODAL"

export const CampaignSaveContainer: FC<{ pathname?: string }> = ({ pathname }) => {
  const meetingContext = useContext(MeetingSearchFormContext)
  const { filterParams } = meetingContext
  const { terms, advancedTerms, searchDateRange, proximity, exclude } = meetingContext.searchFormData
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const history = useHistory()
  const open = useModalKey(campaignSaveModalKey)
  const closeMenu = useCloseModalKey(campaignSaveModalKey)

  const [campaignName, setCampaignName] = useState("")
  const [emailCadence, setEmailCadence] = useState<EmailCadence>("Daily")
  const { mutate: createNewCampaign } = useCreateCampaign(({ data }) => {
    queryParams.set(SearchQueryParams.CampaignId, data.newFilter.id.toString())
    queryParams.delete(SearchQueryParams.DateRange)
    queryParams.append(SearchQueryParams.DateRange, `${searchDateRange[0].valueOf()}`)
    queryParams.append(SearchQueryParams.DateRange, `${searchDateRange[1].valueOf()}`)
    history.push({ pathname: pathname, search: queryParams.toString() })
  })
  const submitNewCampaign = ({ name, emailCadence }: AdditionalDetails) => {
    createNewCampaign({
      name,
      filterParams: filterParams,
      searchTerms: Array.from(terms),
      mustIncludeInput: Array.from(advancedTerms),
      emailCadence,
      proximity,
      exclude,
    })
  }

  return (
    <Dialog open={open} onClose={closeMenu} fullWidth maxWidth="md">
      <DialogTitle>Save New Campaign</DialogTitle>
      <DialogContent>
        <TextField
          value={campaignName}
          onChange={(event) => setCampaignName(event.target.value)}
          label="Campaign Name"
          fullWidth
          required
          sx={{ marginTop: 1, marginBottom: 2 }}
        />
        <FormControl fullWidth>
          <InputLabel id="notification-cadence">Notify By Email</InputLabel>
          <Select
            labelId="notification-cadence"
            id="notification-cadence"
            value={emailCadence}
            label="Notify By Email"
            onChange={(e) => setEmailCadence(e.target.value as EmailCadence)}
          >
            <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ margin: 1, paddingRight: 2 }}>
        <Button
          onClick={() => {
            submitNewCampaign({
              name: campaignName,
              emailCadence: emailCadence,
            })
            closeMenu()
          }}
          disabled={campaignName.trim().length === 0}
          variant="contained"
        >
          Submit
        </Button>
        <Button onClick={closeMenu}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
