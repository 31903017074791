import { useQuery } from "react-query"
import { useAuthAxios } from "@src/api/axiosInstance"
import { OpportunityCampaignsUsers } from "@src/interfaces/opportunityCampaignsUsers"

const prefix = (userOrganizationId: unknown) =>
  `admin/user_organizations/${userOrganizationId}/opportunity_campaigns_users`

export const useAdminFetchOpportunityCampaignsUsers = (userOrganizationId: unknown) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["opportunityCampaignsUsers", { user_organization: `${userOrganizationId}` }], async () => {
    const response = await (await axiosInstance).get<OpportunityCampaignsUsers[]>(prefix(userOrganizationId))

    return response.data
  })
}

export const useAdminFetchOpportunityCampaignsUsersMap = (userOrganizationId: unknown) => {
  const fetchData = useAdminFetchOpportunityCampaignsUsers(userOrganizationId)
  if (fetchData.data) {
    const opportunityCampaignsUsersMap = new Map<number, OpportunityCampaignsUsers[]>()
    fetchData.data.forEach((opportunityCampaignsUser) => {
      const opportunityCampaignsUsers = opportunityCampaignsUsersMap.get(
        opportunityCampaignsUser.opportunity_campaign_id,
      )
      if (!opportunityCampaignsUsers) {
        opportunityCampaignsUsersMap.set(opportunityCampaignsUser.opportunity_campaign_id, [opportunityCampaignsUser])
      } else {
        opportunityCampaignsUsers.push(opportunityCampaignsUser)
      }
    })

    return {
      ...fetchData,
      data: opportunityCampaignsUsersMap,
    }
  }
  return fetchData
}
