import { useMutation, useQuery, useQueryClient } from "react-query"
import { ClipShare, ClipShareBookmarkView } from "../interfaces/clipShare"
import { useAuthAxios } from "./axiosInstance"
import { ChannelType } from "../interfaces/channelType"
import { FetchedClipShare } from "./bookmarks"

const prefix = "/clip_shares"

export interface ClipShareCreateParams {
  meetingId: number
  transcriptId: number | string
  keyword: string
  title?: string
  description?: string
  channelType?: ChannelType
}

export const useFetchClipShare = (clipShareId: number | undefined, isPromo = false) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["clip_share", clipShareId],
    async () => {
      const response = await (await axiosInstance).get<ClipShareBookmarkView>(`${prefix}/${clipShareId}`)

      return response.data
    },
    { enabled: clipShareId !== undefined && !isPromo },
  )
}

// {meeting_id: {transcript_id: [bookmark_id, bookmark_id, bookmark_id]}}
export type FetchMyClipSharesResponseObject = Record<string, Record<string, number[]>>

export const useFetchMyClipSharesInBookmarks = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["my_clips_in_bookmarks"], async () => {
    const response = await (await axiosInstance).get<FetchMyClipSharesResponseObject>(`${prefix}/my_clips_in_bookmarks`)

    return response.data
  })
}

export const useCreateClipShare = (onSuccess?: (data: ClipShare) => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (clipShare: ClipShareCreateParams) => {
      const response = await (
        await axiosInstance
      ).post<ClipShare>(prefix, {
        keyword: clipShare.keyword,
        meeting_id: clipShare.meetingId,
        transcript_id: clipShare.transcriptId,
      })
      return response.data
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess(response)
        }
      },
    },
  )
}

export const useUpdateCreatedClipShare = () => {
  const axiosInstance = useAuthAxios()

  return useMutation(async (clipShare: ClipShare) => {
    return (await axiosInstance).put(`${prefix}/${clipShare.id}/create_update/`)
  })
}

export const useUpdateClip = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (clipShare: FetchedClipShare) => {
      return (await axiosInstance).put(`${prefix}/${clipShare.id}`, {
        title: clipShare.title,
        description: clipShare.description,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bookmarks")
        queryClient.invalidateQueries("shared_bookmarks")
        queryClient.invalidateQueries("industry_bookmarks")
      },
    },
  )
}

export const useSalesforceShare = (clipShare: ClipShare, onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()

  return useMutation(
    async (params: { baseUrl: string; sobjectId: string }) => {
      return (await axiosInstance).post(`${prefix}/${clipShare.id}/salesforce`, params)
    },
    { onSuccess },
  )
}
