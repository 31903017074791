import {
  Dispatch,
  SetStateAction,
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
  ReactElement,
  useMemo,
} from "react"
import { Snackbar, Alert } from "@mui/material"

interface ToastMessage {
  message: string
  duration: number
  icon: ReactElement | false
  severity: "info" | "error"
}

const ToastContext = createContext<Dispatch<SetStateAction<ToastMessage[]>>>(() => {
  /* placeholder for typescript */
})

export const useToast = () => {
  const setToastSequence = useContext(ToastContext)
  return (message: string, duration = 2000, icon?: ReactElement, severity?: "info" | "error") =>
    setToastSequence((prevSequence) => [
      ...prevSequence,
      { message, duration, icon: icon || false, severity: severity || "info" },
    ])
}

export const ToastProvider: FC = ({ children }) => {
  const [toastSequence, setToastSequence] = useState<ToastMessage[]>([])
  const [open, setOpen] = useState(false)
  const [currentToast, setCurrentToast] = useState<ToastMessage>()

  const handleClose = () => setOpen(false)
  useEffect(() => {
    if (toastSequence.length && !currentToast) {
      setCurrentToast(toastSequence[0])
      setToastSequence((prevSequence) => prevSequence.slice(1))
      setOpen(true)
    } else if (toastSequence.length && currentToast && open) {
      setOpen(false)
    }
  }, [toastSequence, currentToast, open])

  const alert = useMemo(() => {
    if (currentToast?.severity === "error") {
      return <Alert severity="error">{currentToast?.message}</Alert>
    }
    const style = {
      backgroundColor: "primary.main",
      color: "white",
      "& .MuiAlert-icon": { color: "white" },
    }
    return (
      <Alert sx={style} icon={currentToast?.icon || false}>
        {currentToast?.message}
      </Alert>
    )
  }, [currentToast?.severity])

  return (
    <ToastContext.Provider value={setToastSequence}>
      {children}
      <Snackbar
        key={currentToast?.message || "empty"}
        open={open}
        autoHideDuration={currentToast?.duration || 0}
        onClose={handleClose}
        TransitionProps={{ onExited: () => setCurrentToast(undefined) }}
      >
        {alert}
      </Snackbar>
    </ToastContext.Provider>
  )
}
