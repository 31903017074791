import { FC, useState } from "react"
import { Button, Dialog, DialogContent } from "@mui/material"
import { Opportunities } from "@src/components/MeetingDetail/OpportunitiesTab"

export const ShowOpportunitiesCell: FC<{ id: unknown }> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Button variant="contained" onClick={() => setIsOpen(true)}>
        Show
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent>
          <Opportunities id={id} />
        </DialogContent>
      </Dialog>
    </>
  )
}
