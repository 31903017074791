import { SearchQueryParams } from "@src/interfaces/search"
import React, { createContext, FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { CampaignSelect } from "."

export interface CampaignSelectContextProps {
  campaignId: number | undefined
  setCampaignId: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const CampaignSelectContext = createContext<CampaignSelectContextProps>({
  campaignId: undefined,
  setCampaignId: () => {
    /* placeholder for typescript */
  },
})

export const CampaignSelectContainer: FC = () => {
  const locationSearchParams = useLocation().search
  const campaignIdString = new URLSearchParams(locationSearchParams).get(SearchQueryParams.CampaignId)
  const [campaignId, setCampaignId] = useState(campaignIdString ? parseInt(campaignIdString) : undefined)

  useEffect(() => {
    if (campaignIdString) {
      setCampaignId(parseInt(campaignIdString))
    }
  }, [campaignIdString])

  return (
    <CampaignSelectContext.Provider value={{ campaignId, setCampaignId }}>
      {campaignId && <CampaignSelect />}
    </CampaignSelectContext.Provider>
  )
}
