import { FC, useMemo } from "react"
import { Grid, Typography, Skeleton } from "@mui/material"
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled"
import CampaignIcon from "@mui/icons-material/Campaign"
import ShareIcon from "@mui/icons-material/Share"
import CorporateFareIcon from "@mui/icons-material/CorporateFare"
import AbcIcon from "@mui/icons-material/Abc"
import Divider from "@mui/material/Divider"
import { useFetchDashboardMetrics } from "../../../api/metrics"
import MetricsWrapper from "./MetricsWrapper"
import {
  VideoAnalyzedTotalDisplay,
  TopCampaignsDisplay,
  TopKeywordsDisplay,
  TopChannelDisplays,
  NothingToDisplay,
  NoSharesToDisplay,
} from "./MetricsContent"
import { MeetingsSharedDisplay } from "../MeetingsSharedDisplay"

export const MetricsContainer: FC<{
  userOrgId?: number
  containerMarginTop?: number
}> = ({ userOrgId }) => {
  const iconStyles = {
    boxSizing: "content-box",
    color: "white",
    padding: "14px",
    backgroundColor: "primary.main",
    borderRadius: 3,
    position: "absolute",
    top: "-50%",
  }

  const { data: dashboardMetrics, isLoading } = useFetchDashboardMetrics(userOrgId)

  const hottestOrgs = dashboardMetrics?.hottest_orgs?.slice(0, 5)
  const newVideosAnalyzed = dashboardMetrics?.new_videos_analyized_in_hours
  const topCampaigns = dashboardMetrics?.top_campaigns
  const topTerms = dashboardMetrics?.top_terms
  const mostShared = dashboardMetrics?.most_shared

  const topCampaignsMemo = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <Typography variant="h2">
            <Skeleton />
          </Typography>
          <Typography variant="h2">
            <Skeleton />
          </Typography>
          <Typography variant="h2">
            <Skeleton />
          </Typography>
        </>
      )
    } else if (topCampaigns?.length) {
      return <TopCampaignsDisplay campaignNames={topCampaigns} />
    } else {
      return <NothingToDisplay />
    }
  }, [isLoading, topCampaigns])

  const hoursAnalyzedMemo = useMemo(() => {
    if (isLoading) {
      return (
        <Typography variant="large-number">
          <Skeleton />
        </Typography>
      )
    } else if (Number.isInteger(newVideosAnalyzed)) {
      return <VideoAnalyzedTotalDisplay total={newVideosAnalyzed} />
    } else {
      return <NothingToDisplay />
    }
  }, [isLoading, newVideosAnalyzed])

  const meetingsSharedMemo = useMemo(() => {
    if (isLoading) {
      return <Skeleton variant="rectangular" height={240} />
    } else if (mostShared?.meetings?.length) {
      return <MeetingsSharedDisplay meetings={mostShared} />
    } else {
      return <NoSharesToDisplay />
    }
  }, [isLoading, mostShared])

  const topKeywordsMemo = useMemo(() => {
    if (isLoading) {
      return <Skeleton variant="rectangular" height={400} />
    } else if (topTerms?.length) {
      return <TopKeywordsDisplay data={topTerms} />
    } else {
      return <NothingToDisplay />
    }
  }, [isLoading, topTerms])

  const topChannelsMemo = useMemo(() => {
    if (isLoading) {
      return <Skeleton variant="rectangular" height={400} />
    } else if (hottestOrgs?.length) {
      return <TopChannelDisplays data={hottestOrgs} />
    } else {
      return <NothingToDisplay />
    }
  }, [isLoading, hottestOrgs])

  return (
    <>
      <Divider component="div" variant="middle" sx={{ marginBottom: 4 }} textAlign="center">
        <Typography alignSelf="center" variant="h2" fontSize={35} gutterBottom>
          7 Day Summary
        </Typography>
      </Divider>
      <Grid container rowSpacing={6} spacing={2} width="90%" margin="auto" marginBottom={4}>
        <Grid item marginBottom={{ xs: 5, md: 0 }} xs={12} md={4}>
          <MetricsWrapper
            title="Top Campaigns"
            icon={<CampaignIcon sx={iconStyles} fontSize="large" />}
            content={topCampaignsMemo}
            infoMessage="The campaigns in your account that have generated the most results."
          />
        </Grid>
        <Grid item marginBottom={{ xs: 5, md: 0 }} xs={12} md={4}>
          <MetricsWrapper
            title="Hours Analyzed"
            icon={<AccessTimeFilledIcon sx={iconStyles} fontSize="large" />}
            content={hoursAnalyzedMemo}
            infoMessage="The total hours of video analyzed for you in the last 7 days."
          />
        </Grid>
        <Grid item marginBottom={{ xs: 5, md: 0 }} xs={12} md={4}>
          <MetricsWrapper
            title="Meetings Shared"
            icon={<ShareIcon sx={iconStyles} fontSize="large" />}
            content={meetingsSharedMemo}
            infoMessage="These are the meetings your team has shared the most in the last 7 days."
          />
        </Grid>
        <Grid item marginBottom={{ xs: 5, md: 0 }} xs={12} md={6}>
          <MetricsWrapper
            title="Top Keywords"
            icon={<AbcIcon sx={iconStyles} fontSize="large" />}
            content={topKeywordsMemo}
            infoMessage="The keywords that have generated the most results across all your campaigns."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MetricsWrapper
            title="Top Channels"
            icon={<CorporateFareIcon sx={iconStyles} fontSize="large" />}
            content={topChannelsMemo}
            infoMessage="The channels that have generated the most results across all your campaigns."
          />
        </Grid>
      </Grid>
    </>
  )
}
