import { FC } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DialogActions,
  Button,
  Link,
} from "@mui/material"
import { useModalKey, useCloseModalKey } from "../OpenModalHook"
import { useFetchCurrentUserCreditUsages } from "../../../api/users"
import CloseIcon from "@mui/icons-material/Close"

const noBottom = {
  borderBottom: 0,
  paddingBottom: 0,
}

const noUnderline = {
  borderBottom: 0,
}

export const roundCredits = (credits: number) => (Math.round(credits * 100) / 100).toFixed(2)

export const CreditSpentConfirmation: FC<{
  modalKey: string
  cost?: number
  onConfirm?: () => void
}> = ({ modalKey, cost, onConfirm }) => {
  const open = useModalKey(modalKey)
  const closeModal = useCloseModalKey(modalKey)
  const { data: userCreditUsage } = useFetchCurrentUserCreditUsages()

  if (!userCreditUsage) {
    return null
  }
  const roundedCost = roundCredits(cost || 0)
  const remainingCredits = userCreditUsage.credit_limit - userCreditUsage.credits_used - (cost || 0)

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#F9F9F9",
          minWidth: "360px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h2">Spend Credits</Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {"You are about to spend "}
          <Typography fontWeight="bold" component="span">
            {roundedCost}
          </Typography>
          {" credits."}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={noBottom}>Available Credits</TableCell>
                <TableCell align="right" sx={noBottom}>
                  {roundCredits(userCreditUsage.credit_limit - userCreditUsage.credits_used)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Credit cost</TableCell>
                <TableCell align="right">{`-${roundedCost}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={noUnderline}>Remaining credits</TableCell>
                <TableCell align="right" sx={noUnderline}>
                  {roundCredits(remainingCredits)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {remainingCredits < 0 && (
          <Typography>
            You are out of briefing credits. Please reach out to your customer success representative or&nbsp;
            <Link href="mailto:sales@cloverleaf.ai">sales@cloverleaf.ai</Link>
            &nbsp;to purchase more. Thanks!
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={() => {
            if (onConfirm) {
              onConfirm()
            }
            closeModal()
          }}
          variant="contained"
          disabled={remainingCredits < 0}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
