import { FC, useMemo, useState } from "react"
import { Divider, Grid, Tab, Tabs, Typography, Box, TextField } from "@mui/material"
import { useDeleteGeography, useNewCreateGeography, useUpdateGeography } from "../../../api/geographies"
import { useFetchGeographiesAdmin } from "../../../api/admin/geographies"
import { DataTable, DataTableProps } from "../../shared/DataTable"
import { ActionsMenu } from "./ActionsMenu"
import { stateColumns, countyColumns, cityColumns, mapDataToColumns } from "../../../tableColumns/adminGeographyColumns"
import { ValidateGeographies } from "./ValidateGeographies"

export const GeographyAdminContainer: FC = () => {
  const [tabIndex, setTabIndex] = useState<"cities" | "counties" | "states" | "validate">("cities")
  const [selectedCities, setSelectedCities] = useState<number[]>([])
  const [selectedCounties, setSelectedCounties] = useState<number[]>([])
  const [selectedStates, setSelectedStates] = useState<number[]>([])
  const [nameFilter, setNameFilter] = useState("")
  const [populationLimit, setPopulationLimit] = useState(10000000)

  const { data: geographiesData, status } = useFetchGeographiesAdmin()
  const { mutate: createGeography } = useNewCreateGeography()
  const { mutate: updateGeography } = useUpdateGeography()
  const { mutate: deleteGeography } = useDeleteGeography()

  const onActionComplete = () => {
    setSelectedCities([])
    setSelectedCounties([])
    setSelectedStates([])
  }

  const geoIds = tabIndex === "cities" ? selectedCities : tabIndex === "counties" ? selectedCounties : selectedStates

  const filteredGeoData = useMemo(() => {
    if (geographiesData && geographiesData[tabIndex]) {
      const filteredByPop =
        tabIndex === "states"
          ? geographiesData[tabIndex]
          : geographiesData[tabIndex].filter((dataRow: any) => dataRow.population < populationLimit)

      return filteredByPop.filter(
        (dataRow: any) => dataRow.name?.toLowerCase().indexOf(nameFilter.toLowerCase()) !== -1,
      )
    }
    return []
  }, [geographiesData, nameFilter, tabIndex, populationLimit])

  const dataTableProps: DataTableProps<Record<string, any>, number> = {
    data: [],
    columns: [],
    size: "small",
    columnKey: "id",
  }
  if (filteredGeoData) {
    switch (tabIndex) {
      case "states":
        dataTableProps.selected = selectedStates
        dataTableProps.setSelected = setSelectedStates
        dataTableProps.columns = stateColumns
        dataTableProps.data = filteredGeoData.map(mapDataToColumns)
        break
      case "counties":
        dataTableProps.selected = selectedCounties
        dataTableProps.setSelected = setSelectedCounties
        dataTableProps.columns = countyColumns
        dataTableProps.data = filteredGeoData.map(mapDataToColumns)
        break
      case "cities":
        dataTableProps.selected = selectedCities
        dataTableProps.setSelected = setSelectedCities
        dataTableProps.columns = cityColumns
        dataTableProps.data = filteredGeoData.map(mapDataToColumns)
        break
      default:
        break
    }
  }

  return (
    <>
      <Grid container marginTop={2} justifyContent="space-between">
        <Grid item xs={6}>
          <Tabs
            value={tabIndex}
            onChange={(_event: React.SyntheticEvent, newValue: string) => {
              if (
                newValue === "cities" ||
                newValue === "counties" ||
                newValue === "states" ||
                newValue === "validate"
              ) {
                setNameFilter("")
                setTabIndex(newValue)
                onActionComplete()
              }
            }}
            aria-label="settings tabs"
          >
            <Tab label="Cities" value="cities" />
            <Tab label="Counties" value="counties" />
            <Tab label="States" value="states" />
            <Tab label="Validate" value="validate" />
          </Tabs>
        </Grid>
        <Grid item xs={2} alignSelf="center">
          <TextField
            label="Filter By Name"
            value={nameFilter}
            onChange={(event: any) => setNameFilter(event.target.value)}
          />
        </Grid>
        {tabIndex !== "states" && (
          <Grid item xs={2} alignSelf="center">
            <TextField
              label="Filter By Population"
              value={populationLimit}
              onChange={(event: any) => setPopulationLimit(event.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={2} alignSelf="center">
          <ActionsMenu
            geoIds={geoIds}
            onActionComplete={onActionComplete}
            geoType={tabIndex}
            createGeography={createGeography}
            updateGeography={updateGeography}
            deleteGeography={deleteGeography}
          />
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2 }} />
      {tabIndex !== "validate" && (
        <Box flex="1">{geographiesData ? <DataTable {...dataTableProps} /> : <Typography>{status}</Typography>}</Box>
      )}
      {tabIndex === "validate" && <ValidateGeographies />}
    </>
  )
}
