import { getWordTime } from "../../../../functions/transcript-words-array"
import { TranscriptWithPerson } from "../../../../interfaces/transcript"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import Share from "@mui/icons-material/Share"
import { ClipShare } from "../../../../interfaces/clipShare"
import { FetchedMeeting } from "../../../../api/meetings"
import { useOpenModalKey, useSetData } from "../../../shared/OpenModalHook"
import { useCreateClipShare } from "../../../../api/clip_shares"
import { useContext } from "react"
import { Bookmark } from "../../../Home/MeetingList/bookmark"
import { PromoContext } from "../../../../App"
import { useCreateClipShare as useCreatePromotionalShare } from "../../../../api/admin/clip_shares"
import { shareTypes, shareModalKey, ShareModalData } from "../../../shared/ShareModal"

interface TranscriptActionProps {
  searchTerm: string
  actionTranscript: TranscriptWithPerson
  meeting: FetchedMeeting
  handleCopyTextFromParagraph: () => void
  handleOnClick?: () => void
}
export const TranscriptItemActionComponent: React.FC<TranscriptActionProps> = ({
  searchTerm,
  actionTranscript,
  meeting,
  handleCopyTextFromParagraph,
  handleOnClick,
}) => {
  const wordTime = getWordTime(searchTerm, actionTranscript)
  const timestamp = new Date(wordTime * 1000).toISOString().slice(11, 19)

  const { mutate: createClip } = useCreateClipShare(async (data) => setModalData(data, wordTime))
  const { mutate: createPromoClip } = useCreatePromotionalShare(async (data) => setModalData(data, wordTime))

  const handleShareTypeSelected = (shareType: shareTypes) => {
    if (shareType === "share") {
      createClip({
        meetingId: meeting.id,
        transcriptId: actionTranscript.id,
        keyword: searchTerm,
      })
    } else if (shareType === "promotional") {
      createPromoClip({
        meetingId: meeting.id,
        transcriptId: actionTranscript.id,
        keyword: searchTerm,
      })
    } else {
      console.error("Invalid share type selected")
    }
  }

  const setDataOnModal = useSetData()
  const openShareModal = useOpenModalKey(shareModalKey)
  const isPromo = useContext(PromoContext)

  const buttonStyles = {
    background: "linear-gradient(280deg, rgba(4,4,24,1) 27%, rgba(4,26,55,.80))",
    color: "neutral.light",
    marginY: 1,
  }

  const setModalData = (data: ClipShare, wordTime: number) => {
    const shareModalData: ShareModalData = {
      id: data.id,
      keyword: searchTerm,
      start_time_in_seconds: `${wordTime} - 5`,
      title: meeting?.title || "",
      transcript_id: actionTranscript.id,
      meeting_id: meeting?.id,
      meeting: meeting,
      raw_text: actionTranscript.raw_text,
      transcript: actionTranscript,
      organization_id: meeting.id,
      organization_name: meeting.organization_name,
      state_name: typeof meeting?.state === "string" ? meeting.state : "",
      county_name: typeof meeting?.county === "string" ? meeting.county : "",
      city_name: typeof meeting?.city === "string" ? meeting.city : "",
      created_at: data.created_at,
      updated_at: data.updated_at,
      discarded_at: null,
      clip_type: data.clip_type,
      promoAllowed: true,
      onShareTypeSelected: handleShareTypeSelected,
    }
    setDataOnModal(shareModalData)
  }

  if (isPromo) {
    return null
  }

  return (
    <Box sx={{ justifyContent: "space-between", display: "flex" }}>
      <Box>
        <Tooltip title={`Seek to ${timestamp}`} placement="top-start">
          <Button
            size="small"
            color="primary"
            variant="contained"
            sx={buttonStyles}
            onClick={() => {
              if (handleOnClick) handleOnClick()
            }}
          >
            {timestamp}
          </Button>
        </Tooltip>
      </Box>

      <Box>
        <Tooltip title="Share" placement="top">
          <IconButton
            className="table-view-add-to-save"
            sx={{ textTransform: "uppercase" }}
            onClick={async () => {
              openShareModal()
              setDataOnModal({
                promoAllowed: true,
                onShareTypeSelected: handleShareTypeSelected,
              })
            }}
          >
            <Share />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy" placement="top">
          <IconButton color="primary" onClick={handleCopyTextFromParagraph}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        <Bookmark meeting={meeting} timestamp={timestamp} keyword={searchTerm} transcriptId={actionTranscript.id} />
      </Box>
    </Box>
  )
}
