import { SvgIcon, SvgIconProps } from "@mui/material"

export const ChartUp: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg id="uuid-fd55ce6d-bcaa-4a30-b55e-8efcfaef6588" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 300">
        <rect
          x="22.54"
          y="97.16"
          width="105.51"
          height="144.63"
          rx="19.57"
          ry="19.57"
          style={{ fill: "#4c6e88", opacity: 0.25, strokeWidth: "0px" }}
        />
        <path
          d="m279.46,150c0-71.72-58.14-129.85-129.85-129.85"
          style={{
            fill: "none",
            opacity: 0.25,
            stroke: "#4c6e88",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6px",
          }}
        />
        <path
          d="m149.61,38.62c-61.68,0-111.68,50-111.68,111.68s50,111.68,111.68,111.68,111.68-50,111.68-111.68-50-111.68-111.68-111.68Z"
          style={{ fill: "#4c6e88", strokeWidth: "0px" }}
        />
        <path
          d="m116.49,183.83h12.07v-32.72l-12.07,6.37v26.36Zm16.56-35.09v35.09h12.07v-34.5l-4.46-4.6-7.61,4.01Zm16.56,35.09h12.07v-37.43l-12.07,7.14v30.29Zm16.56,0h12.07v-47.22l-12.07,7.14v40.08Zm-52.43-29.78h0s0,0,0,0h0Zm53.06-43.27l3.83,6.28.96,1.57.2.33.31.25-.12.06.9,1.47-6.7,3.96-4.48,2.65-9.56,5.65-2.52-2.59-4.48-4.62-1.25-1.29-10.83,5.71-4.48,2.36-12.07,6.37-8.39,4.43,5.63,10.68h0l2.76-1.46,12.07-6.37,4.48-2.36,8.46-4.46,3.62,3.73,4.48,4.62.47.49,11.6-6.86,4.48-2.65,12.07-7.14.91-.54,2.25,3.68,3.8,6.23,10.12-26.71-28.52-3.47Z"
          style={{ fill: "#fff", strokeWidth: "0px" }}
        />
      </svg>
    </SvgIcon>
  )
}

export const Message: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        id="uuid-5e63e644-37c8-47fc-8877-36fafedddc32"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 302 300"
        width="100%"
        height="100%"
      >
        <path
          d="m214.89,25.14c-33.89,0-61.36,27.47-61.36,61.36s27.47,61.36,61.36,61.36,61.36-27.47,61.36-61.36-27.47-61.36-61.36-61.36Z"
          style={{ fill: "#00b3e0", opacity: 0.25, strokeWidth: "0px" }}
        />
        <path
          d="m157.7,38.62c-61.68,0-111.68,50-111.68,111.68s50,111.68,111.68,111.68,111.68-50,111.68-111.68-50-111.68-111.68-111.68Z"
          style={{ fill: "#00b3e0", strokeWidth: "0px" }}
        />
        <path
          d="m28.15,150c0,71.72,58.14,129.85,129.85,129.85"
          style={{
            fill: "none",
            opacity: 0.25,
            stroke: "#00b3e0",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "6px",
          }}
        />
        <path
          d="m120.48,167.42c1.89,0,3.5,1.33,3.82,3.15,1.04,5.86,4.07,8.56,5.88,9.68,0-.06.01-.12.02-.19.19-2.03.48-5.09,1.87-7.69,1.76-3.28,4.74-4.95,8.88-4.95h21.39c2.34-8.69,10.08-15.12,19.15-15.7v-29.42c0-3.26-2.56-5.83-5.82-5.83h-58.14c-3.26,0-5.92,2.57-5.92,5.83v39.22c0,3.26,2.65,5.9,5.92,5.9h2.96Zm30.22-9.65h-4.14c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35h4.14c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35Zm-20.69-9.07c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35h18.23c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-18.23Zm36.02,9.07h-11.09c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35h11.09c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35Zm7.81-9.07h-21.21c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35h21.21c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35Zm-21.16-10.42c0-.75.6-1.35,1.35-1.35h10.73c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-10.73c-.75,0-1.35-.6-1.35-1.35Zm17.23-12.16h3.93c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-3.93c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Zm-.93,10.81h4.86c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-4.86c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Zm-40.91-10.81h37.52c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-37.52c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Zm-8.74,0h4.46c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-4.46c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Zm0,10.81h30.42c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-30.42c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Zm0,9.07h6.41c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-6.41c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Zm0,9.07h22.77c.75,0,1.35.6,1.35,1.35s-.6,1.35-1.35,1.35h-22.77c-.75,0-1.35-.6-1.35-1.35s.6-1.35,1.35-1.35Z"
          style={{ fill: "#fff", strokeWidth: "0px" }}
        />
        <path
          d="m182.92,154.38c-10.27,0-18.63,8.36-18.63,18.63s8.36,18.63,18.63,18.63,18.63-8.36,18.63-18.63-8.36-18.63-18.63-18.63Zm11.57,11.97l-15.23,15.23c-.26.26-.61.4-.96.4s-.69-.13-.96-.4l-6-6c-.53-.53-.53-1.38,0-1.91.53-.53,1.38-.53,1.91,0l5.04,5.04,14.28-14.28c.53-.53,1.38-.53,1.91,0,.53.53.53,1.38,0,1.91Z"
          style={{ fill: "#fff", strokeWidth: "0px" }}
        />
      </svg>
    </SvgIcon>
  )
}

export const Gear: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 300" width="100%" height="100%">
        <path
          d="M78.53 92.7c-33.89 0-61.36 27.47-61.36 61.36s27.47 61.36 61.36 61.36 61.36-27.47 61.36-61.36S112.42 92.7 78.53 92.7Z"
          style={{
            fill: "#cde1f0",
            opacity: 0.25,
            strokeWidth: 0,
          }}
        />
        <path
          d="M157.05 279.85c71.72 0 129.85-58.14 129.85-129.85"
          style={{
            fill: "none",
            opacity: 0.25,
            stroke: "#cde1f0",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 6,
          }}
        />
        <path
          d="M157.05 38.62c-61.68 0-111.68 50-111.68 111.68s50 111.68 111.68 111.68 111.68-50 111.68-111.68-50-111.68-111.68-111.68Z"
          style={{
            fill: "#cde1f0",
            strokeWidth: 0,
          }}
        />
        <path
          d="m195.6 159.49-4.54-2.62c.35-1.93.52-3.86.52-5.76s-.18-3.83-.52-5.76l4.54-2.62c1.93-1.12 2.6-3.59 1.48-5.52l-7.27-12.59c-1.12-1.94-3.6-2.6-5.52-1.48l-4.54 2.62a33.14 33.14 0 0 0-9.98-5.75v-5.25c0-2.23-1.81-4.04-4.04-4.04h-14.54c-2.23 0-4.04 1.81-4.04 4.04v5.25a33.14 33.14 0 0 0-9.98 5.75l-4.54-2.62a4.033 4.033 0 0 0-5.52 1.48l-7.27 12.59c-1.12 1.93-.45 4.4 1.48 5.52l4.54 2.62c-.35 1.93-.52 3.86-.52 5.76s.18 3.83.52 5.76l-4.54 2.62c-1.93 1.12-2.6 3.59-1.48 5.52l7.27 12.59a4.042 4.042 0 0 0 5.52 1.48l4.54-2.62a33.14 33.14 0 0 0 9.98 5.75v5.25c0 2.23 1.81 4.04 4.04 4.04h14.54c2.23 0 4.04-1.81 4.04-4.04v-5.25a33.14 33.14 0 0 0 9.98-5.75l4.54 2.62c1.92 1.11 4.4.46 5.52-1.48l7.27-12.59c1.12-1.93.45-4.4-1.48-5.52Zm-37.14 10.2c-10.24 0-18.58-8.33-18.58-18.58s8.33-18.58 18.58-18.58 18.58 8.33 18.58 18.58-8.33 18.58-18.58 18.58Z"
          style={{
            fill: "#fff",
            strokeWidth: 0,
          }}
        />
        <path
          d="M21.82 75.12h31.61M67.27 34.12v31.61M34.28 43.38l22.35 22.35"
          style={{
            fill: "none",
            opacity: 0.25,
            stroke: "#cde1f0",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 6,
          }}
        />
      </svg>
    </SvgIcon>
  )
}

export const Lock1: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 300" width="100%" height="100%">
        <rect
          width={173.6}
          height={146.86}
          x={47.25}
          y={127.05}
          rx={21.87}
          ry={21.87}
          style={{
            fill: "#4cb2dc",
            strokeWidth: 0,
          }}
        />
        <path
          d="M178.06 105h10.62a7.18 7.18 0 0 0 7.18-7.18V87.1c0-17.19-6.95-32.71-18.23-43.95-11.27-11.31-26.86-18.27-44.12-18.23-34.12.09-62 29.88-62 64v38.12h24.97V88.41c0-19.8 14.91-37.11 34.66-38.43 21.66-1.45 39.75 15.76 39.75 37.12v10.72a7.18 7.18 0 0 0 7.18 7.18ZM143.77 197.21l4.22 32.69h-28.68l4.28-32.75c-3.04-2.7-4.94-6.67-4.94-11.08 0-8.29 6.77-14.99 15.06-14.99s14.99 6.74 14.99 15.03c0 4.41-1.89 8.35-4.94 11.11Z"
          style={{
            fill: "#fff",
            strokeWidth: 0,
          }}
        />
        <path
          d="M70 235.21c-2.15 0-3.89-1.74-3.89-3.89v-58.05c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89v58.05c0 2.15-1.74 3.89-3.89 3.89ZM70 163.16c-2.15 0-3.89-1.74-3.89-3.89v-4.73c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89v4.73c0 2.15-1.74 3.89-3.89 3.89Z"
          style={{
            fill: "#d2e2ef",
            strokeWidth: 0,
          }}
        />
        <path
          d="M204.16 127.79c.47 1.78.74 3.63.74 5.55 0 69.03-55.96 124.99-124.99 124.99H53.17c-1.92 0-3.78-.27-5.55-.74 2.46 9.38 10.97 16.31 21.13 16.31h129.86c12.08 0 21.87-9.79 21.87-21.87V148.91c0-10.15-6.93-18.67-16.31-21.13Z"
          style={{
            fill: "#3b9ab7",
            strokeWidth: 0,
          }}
        />
        <path
          d="M71.5 115.38h24.97v11.67H71.5zM238.01 110.56c-1.43 0-2.81-.79-3.49-2.16a3.878 3.878 0 0 1 1.76-5.21l14.52-7.21c1.92-.96 4.25-.17 5.21 1.76.96 1.92.17 4.26-1.76 5.21l-14.52 7.21c-.55.28-1.14.41-1.72.41ZM223.87 89.99c-.52 0-1.06-.11-1.57-.33a3.892 3.892 0 0 1-1.98-5.13l6.11-13.78a3.889 3.889 0 0 1 7.11 3.15l-6.11 13.78a3.89 3.89 0 0 1-3.56 2.31ZM260.01 137.98c-.3 0-.6-.03-.9-.1l-13.88-3.27c-2.09-.49-3.38-2.59-2.89-4.68.49-2.09 2.59-3.37 4.68-2.89l13.88 3.27c2.09.49 3.38 2.59 2.89 4.68-.42 1.79-2.02 3-3.78 3Z"
          style={{
            fill: "#d2e2ef",
            strokeWidth: 0,
          }}
        />
      </svg>
    </SvgIcon>
  )
}

export const Lock2: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302 300" width="100%" height="100%">
        <rect
          width={173.6}
          height={146.86}
          x={53.36}
          y={127.05}
          rx={21.87}
          ry={21.87}
          style={{
            fill: "#1b242d",
            strokeWidth: 0,
          }}
        />
        <path
          d="M184.17 105h10.62a7.18 7.18 0 0 0 7.18-7.18V87.1c0-17.19-6.95-32.71-18.23-43.95-11.27-11.31-26.86-18.27-44.12-18.23-34.12.09-62 29.88-62 64v38.12h24.97V88.41c0-19.8 14.91-37.11 34.66-38.43C158.91 48.53 177 65.74 177 87.1v10.72a7.18 7.18 0 0 0 7.18 7.18ZM149.88 197.21l4.22 32.69h-28.68l4.28-32.75c-3.04-2.7-4.94-6.67-4.94-11.08 0-8.29 6.77-14.99 15.06-14.99s14.99 6.74 14.99 15.03c0 4.41-1.89 8.35-4.94 11.11Z"
          style={{
            fill: "#fff",
            strokeWidth: 0,
          }}
        />
        <path
          d="M76.11 235.21c-2.15 0-3.89-1.74-3.89-3.89v-58.05c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89v58.05c0 2.15-1.74 3.89-3.89 3.89ZM76.11 163.16c-2.15 0-3.89-1.74-3.89-3.89v-4.73c0-2.15 1.74-3.89 3.89-3.89s3.89 1.74 3.89 3.89v4.73c0 2.15-1.74 3.89-3.89 3.89Z"
          style={{
            fill: "#4cb2dc",
            strokeWidth: 0,
          }}
        />
        <path
          d="M210.27 127.79c.47 1.78.74 3.63.74 5.55 0 69.03-55.96 124.99-124.99 124.99H59.28c-1.92 0-3.78-.27-5.55-.74 2.46 9.38 10.97 16.31 21.13 16.31h129.86c12.08 0 21.87-9.79 21.87-21.87V148.91c0-10.15-6.93-18.67-16.31-21.13Z"
          style={{
            fill: "#0d1216",
            strokeWidth: 0,
          }}
        />
        <path
          d="M77.61 115.38h24.97v11.67H77.61zM244.13 110.56c-1.43 0-2.81-.79-3.49-2.16a3.878 3.878 0 0 1 1.76-5.21l14.52-7.21c1.92-.96 4.25-.17 5.21 1.76.96 1.92.17 4.26-1.76 5.21l-14.52 7.21c-.55.28-1.14.41-1.72.41ZM229.98 89.99c-.52 0-1.06-.11-1.57-.33a3.892 3.892 0 0 1-1.98-5.13l6.11-13.78a3.889 3.889 0 0 1 7.11 3.15l-6.11 13.78a3.89 3.89 0 0 1-3.56 2.31ZM266.12 137.98c-.3 0-.6-.03-.9-.1l-13.88-3.27c-2.09-.49-3.38-2.59-2.89-4.68.49-2.09 2.59-3.37 4.68-2.89l13.88 3.27c2.09.49 3.38 2.59 2.89 4.68-.42 1.79-2.02 3-3.78 3Z"
          style={{
            fill: "#d2e2ef",
            strokeWidth: 0,
          }}
        />
      </svg>
    </SvgIcon>
  )
}

export const Quote1: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 683.29 300" width="100%" height="100%">
        <rect
          width={655.24}
          height={287.69}
          x={14.02}
          y={6.16}
          rx={45.31}
          ry={45.31}
          style={{
            fill: "#fff",
            opacity: 0.1,
            strokeWidth: 0,
          }}
        />
        <path
          d="M85.77 36.34h198.7M284.47 260.59H75.5c-15.47 0-28.14-12.66-28.14-28.14V78.29"
          style={{
            fill: "none",
            stroke: "#cddff2",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 8,
          }}
        />
        <text
          style={{
            fill: "#fff",
            fontSize: "21.7px",
          }}
          transform="translate(85.01 150.48)"
        >
          <tspan
            style={{
              fontFamily: "Gotham-Book,Gotham",
            }}
          >
            <tspan
              x={0}
              y={0}
              style={{
                letterSpacing: 0,
              }}
            >
              {"L"}
            </tspan>
            <tspan x={13.22} y={0}>
              {"o"}
            </tspan>
            <tspan
              x={27.24}
              y={0}
              style={{
                letterSpacing: "-.02em",
              }}
            >
              {"r"}
            </tspan>
            <tspan x={35.55} y={0}>
              {"em ipsum dolor sit amet, "}
            </tspan>
            <tspan
              x={308.91}
              y={0}
              style={{
                letterSpacing: "-.01em",
              }}
            >
              {"c"}
            </tspan>
            <tspan x={320.98} y={0}>
              {"onsec"}
            </tspan>
            <tspan
              x={384.42}
              y={0}
              style={{
                letterSpacing: "-.02em",
              }}
            >
              {"t"}
            </tspan>
            <tspan x={392.86} y={0}>
              {"etuer "}
            </tspan>
          </tspan>
          <tspan
            x={0}
            y={26.05}
            style={{
              fontFamily: "Gotham-Book,Gotham",
            }}
          >
            {"adipiscing elit, "}
          </tspan>
          <tspan
            x={160.87}
            y={26.05}
            style={{
              fontFamily: "Gotham-MediumItalic,Gotham",
              fontStyle: "italic",
              fontWeight: 500,
            }}
          >
            {"sed diam nonum"}
          </tspan>
          <tspan
            x={341.14}
            y={26.05}
            style={{
              fontFamily: "Gotham-MediumItalic,Gotham",
              fontStyle: "italic",
              fontWeight: 500,
              letterSpacing: "-.02em",
            }}
          >
            {"m"}
          </tspan>
          <tspan
            x={361.24}
            y={26.05}
            style={{
              fontFamily: "Gotham-MediumItalic,Gotham",
              fontStyle: "italic",
              fontWeight: 500,
            }}
          >
            {"y"}
          </tspan>
          <tspan
            xmlSpace="preserve"
            x={373.94}
            y={26.05}
            style={{
              fontFamily: "Gotham-Book,Gotham",
            }}
          >
            {" nibh euismod "}
          </tspan>
        </text>
        <text
          style={{
            fill: "#fff",
            fontFamily: "Gotham-Bold,Gotham",
            fontSize: "18.33px",
            fontWeight: 700,
          }}
          transform="translate(84.99 215.68)"
        >
          <tspan x={0} y={0}>
            {"PERSON NAME HERE"}
          </tspan>
        </text>
        <path
          d="M50.62 50.42h3.81v10.3H44.5v-8.73c0-4.18.97-7.22 2.91-9.11 1.94-1.89 4.8-2.99 8.59-3.28l.97 3.88c-4.58.85-6.69 3.16-6.35 6.94Zm17.25 0h3.88v10.3h-9.93v-8.73c0-4.18.97-7.22 2.91-9.11 1.94-1.89 4.8-2.99 8.59-3.28l.97 3.88c-4.58.85-6.72 3.16-6.42 6.94ZM320.7 260.33h-3.81v-10.3h9.93v8.73c0 4.18-.97 7.22-2.91 9.11-1.94 1.89-4.8 2.99-8.59 3.28l-.97-3.88c4.58-.85 6.69-3.16 6.35-6.94Zm-17.25 0h-3.88v-10.3h9.93v8.73c0 4.18-.97 7.22-2.91 9.11-1.94 1.89-4.8 2.99-8.59 3.28l-.97-3.88c4.58-.85 6.72-3.16 6.42-6.94Z"
          style={{
            fill: "#cddff2",
            strokeWidth: 0,
          }}
        />
        <rect
          width={248.64}
          height={28.15}
          x={78.19}
          y={84.26}
          rx={14.07}
          ry={14.07}
          style={{
            fill: "#cddff2",
            opacity: 0.83,
            strokeWidth: 0,
          }}
        />
        <text
          style={{
            fill: "#00b3e0",
            fontFamily: "Gotham-Bold,Gotham",
            fontSize: "14.81px",
            fontWeight: 700,
          }}
          transform="translate(91.95 103.2)"
        >
          <tspan x={0} y={0}>
            {"SECONDARY INFORMATION"}
          </tspan>
        </text>
      </svg>
    </SvgIcon>
  )
}

export const Quote2: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 683.29 300" width="100%" height="100%">
        <rect
          style={{
            fill: "#fff",
            opacity: 0.1,
            strokeWidth: 0,
          }}
        />
        <path
          d="M88.43 36.34h198.7M287.13 260.59H78.17c-15.47 0-28.14-12.66-28.14-28.14V78.29"
          style={{
            fill: "none",
            stroke: "#cddff2",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 8,
          }}
        />
        <text
          style={{
            fill: "#fff",
            fontSize: "21.7px",
          }}
          transform="translate(87.67 150.48)"
        >
          <tspan
            style={{
              fontFamily: "Gotham-Book,Gotham",
            }}
          >
            <tspan
              x={0}
              y={0}
              style={{
                letterSpacing: 0,
              }}
            >
              {"L"}
            </tspan>
            <tspan x={13.22} y={0}>
              {"o"}
            </tspan>
            <tspan
              x={27.24}
              y={0}
              style={{
                letterSpacing: "-.02em",
              }}
            >
              {"r"}
            </tspan>
            <tspan x={35.55} y={0}>
              {"em ipsum dolor sit amet, "}
            </tspan>
            <tspan
              x={308.91}
              y={0}
              style={{
                letterSpacing: "-.01em",
              }}
            >
              {"c"}
            </tspan>
            <tspan x={320.98} y={0}>
              {"onsec"}
            </tspan>
            <tspan
              x={384.42}
              y={0}
              style={{
                letterSpacing: "-.02em",
              }}
            >
              {"t"}
            </tspan>
            <tspan x={392.86} y={0}>
              {"etuer "}
            </tspan>
          </tspan>
          <tspan
            x={0}
            y={26.05}
            style={{
              fontFamily: "Gotham-Book,Gotham",
            }}
          >
            {"adipiscing elit, "}
          </tspan>
          <tspan
            x={160.87}
            y={26.05}
            style={{
              fontFamily: "Gotham-MediumItalic,Gotham",
              fontStyle: "italic",
              fontWeight: 500,
            }}
          >
            {"sed diam nonum"}
          </tspan>
          <tspan
            x={341.14}
            y={26.05}
            style={{
              fontFamily: "Gotham-MediumItalic,Gotham",
              fontStyle: "italic",
              fontWeight: 500,
              letterSpacing: "-.02em",
            }}
          >
            {"m"}
          </tspan>
          <tspan
            x={361.24}
            y={26.05}
            style={{
              fontFamily: "Gotham-MediumItalic,Gotham",
              fontStyle: "italic",
              fontWeight: 500,
            }}
          >
            {"y"}
          </tspan>
          <tspan
            xmlSpace="preserve"
            x={373.94}
            y={26.05}
            style={{
              fontFamily: "Gotham-Book,Gotham",
            }}
          >
            {" nibh euismod "}
          </tspan>
        </text>
        <text
          style={{
            fill: "#fff",
            fontFamily: "Gotham-Bold,Gotham",
            fontSize: "18.33px",
            fontWeight: 700,
          }}
          transform="translate(87.65 215.68)"
        >
          <tspan x={0} y={0}>
            {"PERSON NAME HERE"}
          </tspan>
        </text>
        <path
          d="M53.28 50.42h3.81v10.3h-9.93v-8.73c0-4.18.97-7.22 2.91-9.11 1.94-1.89 4.8-2.99 8.59-3.28l.97 3.88c-4.58.85-6.69 3.16-6.35 6.94Zm17.25 0h3.88v10.3h-9.93v-8.73c0-4.18.97-7.22 2.91-9.11 1.94-1.89 4.8-2.99 8.59-3.28l.97 3.88c-4.58.85-6.72 3.16-6.42 6.94ZM323.37 260.33h-3.81v-10.3h9.93v8.73c0 4.18-.97 7.22-2.91 9.11-1.94 1.89-4.8 2.99-8.59 3.28l-.97-3.88c4.58-.85 6.69-3.16 6.35-6.94Zm-17.25 0h-3.88v-10.3h9.93v8.73c0 4.18-.97 7.22-2.91 9.11-1.94 1.89-4.8 2.99-8.59 3.28l-.97-3.88c4.58-.85 6.72-3.16 6.42-6.94Z"
          style={{
            fill: "#cddff2",
            strokeWidth: 0,
          }}
        />
        <rect
          style={{
            fill: "#cddff2",
            opacity: 0.83,
            strokeWidth: 0,
          }}
        />
        <text
          style={{
            fill: "#00b3e0",
            fontFamily: "Gotham-Bold,Gotham",
            fontSize: "14.81px",
            fontWeight: 700,
          }}
          transform="translate(94.61 103.2)"
        >
          <tspan x={0} y={0}>
            {"SECONDARY INFORMATION"}
          </tspan>
        </text>
      </svg>
    </SvgIcon>
  )
}
