import { FC, useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { useNewUser, useUpdateUser } from "@api/admin/users"
import { useFetchAdminRoles } from "@api/roles"
import ErrorIcon from "@mui/icons-material/Error"
import Loading from "../../shared/Loading"
import { useFetchUserOrganizations } from "@api/admin/user_organizations"
import { useToast } from "../../shared/ToastHook"
import { SimpleUser } from "@src/interfaces/user"

export const EditUserModal: FC<{
  open: boolean
  onClose: () => void
  selectedUser: SimpleUser | undefined
  onActionComplete?: () => void
  formType: "New" | "Update"
}> = ({ open, onClose, selectedUser, onActionComplete, formType }) => {
  const roleId = selectedUser?.roles[0]?.id
  const [emailInput, setEmailInput] = useState(selectedUser?.email)
  const [roleOption, setRoleOption] = useState(roleId)
  const [userOrgId, setUserOrgId] = useState(selectedUser?.user_organization_id)
  const [signalsNotifications, setSignalsNotifications] = useState(
    formType === "New" ? true : selectedUser?.requires_signals_notifications,
  )
  const [accountSummaryNotifications, setAccountSummaryNotifications] = useState(
    formType === "New" ? true : selectedUser?.requires_account_summary_notifications,
  )

  const confirmDisabled = userOrgId === undefined || emailInput === undefined || roleOption === undefined
  const setToast = useToast()

  const { mutate: createUser } = useNewUser(
    () => {
      setToast("User created successfully.", 3000)
      onClose()
      if (onActionComplete) {
        onActionComplete()
      }
    },
    (errorMessage: string) => setToast(errorMessage, 5000, <ErrorIcon />, "error"),
  )
  const { mutate: updateUser } = useUpdateUser(() => setToast("User updated successfully.", 3000))
  const { data: rolesData } = useFetchAdminRoles()
  const { data: userOrgData } = useFetchUserOrganizations()

  useEffect(() => {
    if (rolesData && formType === "New") {
      const basicOption = rolesData.roles.find(({ name }: { name: string }) => name === "Basic")
      if (basicOption) {
        setRoleOption(basicOption.id)
      }
    }
  }, [rolesData, formType])

  if (rolesData === undefined || userOrgData === undefined) {
    return <Loading />
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{`${formType} User`}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          {formType === "New" && (
            <Grid item xs={10} padding={1}>
              <TextField
                value={emailInput}
                onChange={(event) => setEmailInput(event.target.value)}
                id="user-email-input"
                label="Email address"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="user-org-select-label">User Organization</InputLabel>
              <Select
                value={userOrgId}
                onChange={(event) => setUserOrgId(event.target.value as number)}
                labelId="user-org-select-label"
                label="User Organization"
              >
                {userOrgData.map((userOrg) => (
                  <MenuItem key={userOrg.id} value={userOrg.id}>
                    {userOrg.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Deprecated? */}
          {/* <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              {(roleOption !== undefined || formType === "Update") && (
                <Select
                  value={roleOption}
                  onChange={(event: any) => setRoleOption(event.target.value)}
                  labelId="role-select-label"
                  label="Role"
                >
                  {rolesData.roles.map((role: any) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid> */}
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={signalsNotifications}
                  onChange={(event) => setSignalsNotifications(event.target.checked)}
                />
              }
              label="Requires Signals Notifications?"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accountSummaryNotifications}
                  onChange={(event) => setAccountSummaryNotifications(event.target.checked)}
                />
              }
              label="Requires Account Summary Notifications?"
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            disabled={confirmDisabled}
            onClick={() => {
              selectedUser === undefined || formType === "New"
                ? createUser({
                    email: emailInput,
                    role_id: roleOption,
                    requires_signals_notifications: signalsNotifications,
                    requires_account_summary_notifications: accountSummaryNotifications,
                    user_organization_id: userOrgId,
                  })
                : updateUser({
                    ids: [selectedUser.id],
                    email: emailInput,
                    requires_signals_notifications: signalsNotifications,
                    requires_account_summary_notifications: accountSummaryNotifications,
                    role_id: roleOption,
                    user_organization_id: userOrgId,
                  })
            }}
          >
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
