import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"

export const useFetchSuggestedTerms: any = (meetingId: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["suggested_terms", meetingId], async () => {
    const response = await (await axiosInstance).get(`/suggested_terms?meeting_id=${meetingId}`)

    return response.data
  })
}

export const useCreateSuggestedTerm: any = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("/suggested_terms", params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("suggested_terms")
      },
    },
  )
}
