import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { AdminEditableIndustryFields, AdminFetchedIndustry, Industry } from "@interfaces/industry"

const prefix = "/admin/industries"

export interface IndustryIndexResponse {
  industries: AdminFetchedIndustry[]
  total: number
}

export const useFetchIndustries = (filterParams?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const apiParams = new URLSearchParams({
    name: filterParams?.get("name") || "",
    page: filterParams?.get("page") || "0",
    count: filterParams?.get("count") || "25",
    sort_by: filterParams?.get("sort_by") || "id",
    sort_order: filterParams?.get("sort_order") || "asc",
  })

  return useQuery(["industries", apiParams?.toString()], async () => {
    const response = await (await axiosInstance).get<IndustryIndexResponse>(`${prefix}?${apiParams?.toString()}`)

    return response.data
  })
}

export const useCreateIndustry = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { name: string }) => {
      return (await axiosInstance).post<Industry>(prefix, params)
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries("industries")
      },
    },
  )
}

export const useRestoreIndustry = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      return (await axiosInstance).patch<Industry>(`${prefix}/${id}/restore_industry`, { undiscard: true })
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries("industries")
      },
    },
  )
}

export const useUpdateIndustry = (onSuccess?: (msg: string) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { id: number } & Partial<AdminEditableIndustryFields>) => {
      const { id, ...data } = params
      return (await axiosInstance).put<Industry>(`${prefix}/${id}`, data)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess("Industry Updated Successfully")
          queryClient.invalidateQueries("industries")
          queryClient.invalidateQueries("naicsCodes")
        }
      },
    },
  )
}

export const useDeleteIndustry = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (id: number) => {
      return (await axiosInstance).delete(`${prefix}/${id}`)
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries("industries")
      },
    },
  )
}

export const useAddRecommendedCampaign = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post(`/admin/industries/${params.industry_id}/add_recommended_campaign`, {
        campaign_id: params.campaign_id,
        use_case: params.use_case,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("industries")
      },
    },
  )
}

export const useRemoveRecommendedCampaign = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(
        `/admin/industries/${params.industryId}/remove_recommended_campaign?recommended_campaign_id=${params.recommendedCampaignId}`,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("industries")
      },
    },
  )
}
