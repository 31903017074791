import { FC, useState, useContext, Dispatch, SetStateAction, useEffect } from "react"
import {
  TextField,
  Stack,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { unpackSetters } from "../../../shared/unpackSetters"
import { OrganizationWizardContext } from "./Context"
import { Divider } from "./Divider"
import Loading from "../../../shared/Loading"
import { JsonSelectors } from "./JsonSelectors"
import { useContactSourceUrl } from "../../../../api/admin/wizard"
import { contactSourceUrlParams } from "./searchParams"

export const AdditionalUrl: FC<{
  url: string
  index: number
  setAdditionalUrls: Dispatch<SetStateAction<string[]>>
}> = ({ url, index, setAdditionalUrls }) => (
  <>
    <Stack direction="row" spacing={1}>
      <TextField
        label="Additional URL"
        size="small"
        value={url}
        onChange={(event) =>
          setAdditionalUrls((prevValue) => {
            const newValue = [...prevValue]
            newValue[index] = event.target.value
            return newValue
          })
        }
        fullWidth
      />
      <IconButton
        size="small"
        onClick={() =>
          setAdditionalUrls((prevValue) => {
            const newValue = [...prevValue]
            newValue.splice(index, 1)
            return newValue
          })
        }
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
    <Divider
      onClick={() =>
        setAdditionalUrls((prevValue) => {
          const newValue = [...prevValue]
          newValue.splice(index + 1, 0, "")
          return newValue
        })
      }
    />
  </>
)

export const VerifyUrl: FC = () => {
  const { value, setValue } = useContext(OrganizationWizardContext)
  const { name, url, navigationMethod, additionalUrls, head, jsonReplaceCode, setStep } = value
  const { setName, setUrl, setNavigationMethod, setAdditionalUrls, setHead } = unpackSetters(value, setValue)
  const [searchOpts, setSearchOpts] = useState<string | undefined>(undefined)
  const { data, isLoading, refetch, isRefetching } = useContactSourceUrl(searchOpts)
  const [useSearch, setUseSearch] = useState(false)

  const getHtmlFromUrl = () => {
    setSearchOpts(contactSourceUrlParams(url, navigationMethod, head, jsonReplaceCode))
    setUseSearch(!useSearch)
  }

  useEffect(() => {
    if (searchOpts === undefined) return
    refetch()
  }, [useSearch])

  return (
    <Stack marginX="20%">
      <TextField
        label="Channel Name"
        size="small"
        value={name}
        onChange={(event) => setName(event.target.value)}
        sx={{ marginBottom: 1 }}
      />
      <TextField label="Channel URL" size="small" value={url} onChange={(event) => setUrl(event.target.value)} />
      <Divider onClick={() => setAdditionalUrls((prevValue) => ["", ...prevValue])} />
      {additionalUrls.map((url, index) => (
        <AdditionalUrl key={index} url={url} index={index} setAdditionalUrls={setAdditionalUrls} />
      ))}
      <Stack direction="row" spacing={2}>
        <FormControl sx={{ alignSelf: "flex-start", minWidth: "20ch" }}>
          <InputLabel id="demo-simple-select-label">Navigation Method</InputLabel>
          <Select
            value={navigationMethod}
            label="Navigation Method"
            onChange={(event) => setNavigationMethod(event.target.value as "uri" | "selenium" | "zyte" | "json")}
            size="small"
          >
            <MenuItem value="uri">URI</MenuItem>
            <MenuItem value="selenium">Selenium</MenuItem>
            <MenuItem value="zyte-selenium">Zyte Selenium</MenuItem>
            <MenuItem value="zyte">Zyte</MenuItem>
            <MenuItem value="json">JSON</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={head} onChange={(event) => setHead(event.target.checked)} />}
          label="Use Head"
        />
        {navigationMethod === "json" && <JsonSelectors />}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        {(isLoading || isRefetching) && <Loading />}
        <Button onClick={getHtmlFromUrl}>Verify URL</Button>
        <Button onClick={() => setStep(1)}>Next</Button>
      </Stack>
      <Typography whiteSpace="pre-wrap">{JSON.stringify(data?.response, null, 4)}</Typography>
    </Stack>
  )
}
