import { Grid, Typography, Card, CardContent } from "@mui/material"
import { FC, ReactElement, useMemo, useState } from "react"
import { useFetchDashboardWidgets } from "../../../api/dashboards"
import { HoursAnalyzed as HoursAnalyzedDisplay } from "./HoursAnalyzed"
import { KeywordsOverTime as KeywordsOverTimeDisplay } from "./KeywordsOverTime"
import { GeographiesOverTime as GeographiesOverTimeDisplay } from "./GeographiesOverTime"
import { TopChannels as TopChannelsDisplay } from "./TopChannels"
import { useUpdateWidget, useDeleteWidget } from "../../../api/widgets"
import { ActionWidgetDialog } from "../ActionWidgetDialog"
import { EditableWidget } from "./EditableWidget"
import { useAddWidgetToDashboard } from "../../../api/dashboards"
import { Confirm as DeleteConfirm } from "../../shared/Confirm"
import { Widget, WidgetConfig, Configuration } from "../../../interfaces/dashboard"

export const WidgetContainer: FC<{ dashboardId: string }> = ({ dashboardId }) => {
  const { data: widgetsData, isLoading } = useFetchDashboardWidgets(dashboardId)
  const { mutate: updateWidget } = useUpdateWidget()
  const { mutate: cloneWidget } = useAddWidgetToDashboard()
  const { mutate: deleteWidget } = useDeleteWidget()

  const getDisplayConfig: (widget: Widget, openAction?: (actionType: string) => void) => ReactElement = (
    widget,
    openAction,
  ) => {
    const displayConfig: WidgetConfig = {
      hours: <HoursAnalyzedDisplay configuration={widget?.configuration} openAction={openAction} />,
      top_channels: <TopChannelsDisplay configuration={widget?.configuration} openAction={openAction} />,
      keywords_over_time: <KeywordsOverTimeDisplay configuration={widget?.configuration} openAction={openAction} />,
      geographies_over_time: (
        <GeographiesOverTimeDisplay configuration={widget?.configuration} openAction={openAction} />
      ),
    }
    return displayConfig[widget?.widget_type]
  }

  const [dialogOpen, setDialogOpen] = useState("")
  const [actionWidget, setActionWidget] = useState<Widget | undefined>(undefined)

  const setActionWidgetConfig = (newConfig: Configuration) => {
    if (actionWidget) {
      const updatedWidget: Widget = { ...actionWidget }
      updatedWidget.configuration = newConfig
      setActionWidget({ ...updatedWidget })
    }
  }

  const sortedWidgets = useMemo(() => {
    return widgetsData?.widgets.sort((currentWidget: Widget, nextWidget: Widget) => {
      return currentWidget.created_at < nextWidget.created_at ? 1 : -1
    })
  }, [widgetsData?.widgets])

  if (isLoading) {
    return null
  }

  if (!widgetsData || widgetsData?.widgets?.length === 0) {
    return (
      <Grid container justifyContent="center" spacing={1} paddingY={3}>
        <Typography variant="h5">
          You currently have no widgets. Explore the widget library to add a widget to your dashboard.
        </Typography>
      </Grid>
    )
  }

  const disableSubmitName = !(actionWidget?.configuration.name && actionWidget?.configuration?.name.length > 0)
  const disableSubmitSearchTerms = !(
    actionWidget?.configuration?.search_terms && actionWidget?.configuration?.search_terms.length > 0
  )
  let submitDisabled
  if (actionWidget?.widget_type === "keywords_over_time" || actionWidget?.widget_type === "geographies_over_time") {
    submitDisabled = disableSubmitName || disableSubmitSearchTerms
  } else {
    submitDisabled = disableSubmitName
  }

  return (
    <>
      <Grid container spacing={1} paddingY={2} justifyContent="space-between">
        {sortedWidgets?.map((widget: Widget) => {
          const openAction = (actionType: string) => {
            setDialogOpen(actionType)
            const newWidget = JSON.parse(JSON.stringify(widget))
            if (actionType === "clone") {
              newWidget.configuration.name = `Copy of ${widget.configuration.name}`
            }

            setActionWidget({ ...newWidget })
          }
          return (
            <Grid item xs={12} md={6} key={widget.id} height={350}>
              <Card sx={{ height: "100%" }}>
                <CardContent>{getDisplayConfig(widget, openAction)}</CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <ActionWidgetDialog
        isOpen={dialogOpen === "edit"}
        title="Edit Widget"
        dialogContent={
          actionWidget && <EditableWidget actionWidget={actionWidget} setActionWidgetConfig={setActionWidgetConfig} />
        }
        onClick={() => {
          if (actionWidget) {
            updateWidget({ id: actionWidget.id, configuration: actionWidget.configuration })
            setDialogOpen("")
            setActionWidget(undefined)
          }
        }}
        onClose={() => setDialogOpen("")}
        submitIsDisabled={submitDisabled}
      />
      <ActionWidgetDialog
        isOpen={dialogOpen === "clone"}
        title="Clone Widget"
        dialogContent={
          actionWidget && (
            <>
              <EditableWidget actionWidget={actionWidget} setActionWidgetConfig={setActionWidgetConfig} />
              <br />
              <Card>
                <CardContent>{getDisplayConfig(actionWidget)}</CardContent>
              </Card>
            </>
          )
        }
        onClick={() => {
          if (actionWidget) {
            cloneWidget({
              dashboardId: dashboardId,
              configuration: actionWidget.configuration,
              type: actionWidget.widget_type,
            })
            setDialogOpen("")
            setActionWidget(undefined)
          }
        }}
        onClose={() => setDialogOpen("")}
        submitIsDisabled={submitDisabled}
      />
      <DeleteConfirm
        open={dialogOpen === "delete"}
        onClose={() => {
          setDialogOpen("")
          setActionWidget(undefined)
        }}
        onSucceed={() => {
          if (actionWidget) {
            deleteWidget(actionWidget.id)
            setDialogOpen("")
            setActionWidget(undefined)
          }
        }}
        title="Delete Widget"
        body={`Are you sure you want to delete ${actionWidget?.configuration?.name}`}
      />
    </>
  )
}
