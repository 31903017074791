import { Dispatch, FC, SetStateAction, useState, useEffect } from "react"
import { Stack, Autocomplete, TextField, Box, Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import { urlParamsToOpportunityQueryObject, OpportunityQueryObjectWithCampaign } from "@api/opportunities"
import { ChannelSelectorMultiple } from "../shared/Selectors/Multiple/Channel"
import { GeographySelectorMultiple } from "../shared/Selectors/Multiple/Geographies"
import { useFetchOrganizations } from "@api/organizations"
import { useFetchGeographies } from "@api/geographies"
import { FilterParams } from "../Home/interface"
import useSetUrlParamState from "../shared/UseSetUrlParamState"
import { ChipField } from "@src/components/shared/ChipField"
import { CampaignFields } from "./CampaignFields"
import { useFetchIndustryIdsMap } from "@src/api/industries"
import { useFetchNaicsCodesMapByCode } from "@src/api/naicsCodes"

interface OpportunityTableSearchFormProps {
  showIndustry: boolean
}

export const OpportunityTableSearchForm: FC<OpportunityTableSearchFormProps> = ({ showIndustry }) => {
  const paramsString = useLocation().search
  const params = new URLSearchParams(paramsString)

  const campaignIdString = params.get("campaign")
  const campaignId = campaignIdString ? parseInt(campaignIdString, 10) : undefined

  const urlParamState = urlParamsToOpportunityQueryObject(params)
  const [terms, setTerms] = useState(new Set<string>(urlParamState.terms))
  useEffect(() => {
    setTerms(new Set<string>(urlParamState.terms))
  }, [paramsString])

  const { data: organizationsData, isLoading: organizationsLoading } = useFetchOrganizations()
  const { data: geographiesData, isLoading: geographiesLoading } = useFetchGeographies()
  const { data: industriesDataMap, isLoading: industriesLoading } = useFetchIndustryIdsMap(false, true)
  const { data: naicsCodesMap } = useFetchNaicsCodesMapByCode()
  const defaultGeographies = {
    cities: [],
    states: [],
    counties: [],
  }
  const setUrlParamState = useSetUrlParamState<OpportunityQueryObjectWithCampaign>()

  const setUrlParamStateWithPage0 = ((newState: typeof urlParamState) =>
    setUrlParamState({ ...newState, campaign: campaignId ? `${campaignId}` : undefined, page: 0 })) as Dispatch<
    SetStateAction<FilterParams>
  >

  return (
    <Stack gap={1}>
      <ChipField terms={terms} setTerms={setTerms} placeholder="What opportunities would you like to find?" />
      {showIndustry ? (
        <Autocomplete
          multiple
          value={urlParamState.industry}
          onChange={(_, newValue) =>
            setUrlParamState({
              ...urlParamState,
              industry: newValue.map((value) => value.toString()),
              page: 0,
              campaign: campaignId ? `${campaignId}` : undefined,
            })
          }
          getOptionLabel={(option) => {
            const industry = industriesDataMap?.get(option)
            return industry?.name || ""
          }}
          loading={industriesLoading}
          options={industriesDataMap ? Array.from(industriesDataMap.keys()) : []}
          renderInput={(params) => <TextField {...params} label="Industry" />}
          renderOption={(props, option) => {
            const industry = industriesDataMap?.get(option)
            return (
              <Box component="li" {...props} key={option}>
                {industry?.name}
              </Box>
            )
          }}
        />
      ) : (
        <Autocomplete
          multiple
          value={urlParamState.naics_code}
          onChange={(_, newValue) =>
            setUrlParamState({
              ...urlParamState,
              naics_code: newValue.map((value) => value.toString()),
              page: 0,
              campaign: campaignId ? `${campaignId}` : undefined,
            })
          }
          getOptionLabel={(option) => {
            const naicsCode = naicsCodesMap?.get(option)
            return `${naicsCode?.code}: ${naicsCode?.title}`
          }}
          options={naicsCodesMap ? Array.from(naicsCodesMap.keys()) : []}
          renderInput={(params) => <TextField {...params} label="NAICS Code" />}
          renderOption={(props, option) => {
            const naicsCode = naicsCodesMap?.get(option)
            return (
              <Box component="li" {...props} key={option}>
                {`${naicsCode?.code}: ${naicsCode?.title}`}
              </Box>
            )
          }}
        />
      )}
      <Stack direction="row" gap={1} flexWrap="wrap">
        <>
          <GeographySelectorMultiple
            options={geographiesData || defaultGeographies}
            label="State"
            type="states"
            loading={geographiesLoading}
            filterParams={urlParamState as FilterParams}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "20ch 1 0" }}
          />
          <GeographySelectorMultiple
            options={geographiesData || defaultGeographies}
            label="County"
            type="counties"
            loading={geographiesLoading}
            filterParams={urlParamState as FilterParams}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "20ch 1 0" }}
          />
          <GeographySelectorMultiple
            options={geographiesData || defaultGeographies}
            label="City"
            type="cities"
            filterParams={urlParamState as FilterParams}
            loading={geographiesLoading}
            setFilterParams={setUrlParamStateWithPage0}
            sx={{ flex: "30ch 1 0" }}
          />
        </>
        <ChannelSelectorMultiple
          options={organizationsData ? organizationsData.organizations : []}
          filterParams={urlParamState as FilterParams}
          loading={organizationsLoading}
          setFilterParams={setUrlParamStateWithPage0}
          sx={{ flex: "30ch 1 0" }}
        />
      </Stack>
      <Stack direction="row" gap={1}>
        <CampaignFields />
        <Button
          variant="contained"
          size="large"
          onClick={() =>
            setUrlParamState({
              ...urlParamState,
              terms: Array.from(terms),
              campaign: campaignId ? `${campaignId}` : undefined,
              page: 0,
            })
          }
        >
          Search
        </Button>
      </Stack>
    </Stack>
  )
}
