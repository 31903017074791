import { getUtcDateforNow } from "@src/interfaces/content-acquisition/timeUtilities"
import { CommonUrlParams } from "../requestParams"
import { subDays } from "date-fns"

export const fetchYesterdaysJobsParams: CommonUrlParams = {
  dateFilters: [
    {
      attribute: "started_at",
      dateRange: [subDays(getUtcDateforNow(), 1), undefined],
      filterBy: "after_or_on",
    },
  ],
  fields: ["success", "proxy_id"],
}

export const fetchJobsByDaysBackParams: (days_back: number) => CommonUrlParams = (days_back) => ({
  dateFilters: [
    {
      attribute: "started_at",
      dateRange: [subDays(getUtcDateforNow(), days_back + 1), subDays(getUtcDateforNow(), days_back)],
      filterBy: "in_range",
    },
  ],
  fields: ["success", "proxy_id"],
})
