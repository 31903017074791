import { Button, Typography, Stack } from "@mui/material"
import { FC, useContext } from "react"
import SwapVertIcon from "@mui/icons-material/SwapVert"
import { HomePageContext } from "../../../pages/HomePage"
import { useHistory, useLocation } from "react-router-dom"
import { unpackSetters } from "../../shared/unpackSetters"
import { enabledButtonStyle, disabledButtonStyle } from "./styles"

export const Sort: FC = () => {
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const { homePageParams, setHomePageParams } = useContext(HomePageContext)
  const { sortBy } = homePageParams
  const { setSortBy } = unpackSetters(homePageParams, setHomePageParams)

  const switchSort = (selection?: string) => {
    let type: "hits" | "date"
    if (selection) {
      if (selection === "hits") {
        type = "hits"
      } else {
        type = "date"
      }
    } else {
      if (sortBy === "date") {
        type = "hits"
      } else {
        type = "date"
      }
    }
    setSortBy(type)
    queryParams.set("sort-by", type)
    queryParams.set("page", "1")
    history.push({ pathname: window.location.pathname, search: queryParams.toString() })
  }

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} marginTop={1} justifyContent="flex-start" alignItems="center">
      <Typography fontWeight={600} variant="body2" sx={{ display: { xs: "none", md: "block" } }}>
        Sort By
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => switchSort("hits")}
        sx={sortBy === "hits" ? enabledButtonStyle : disabledButtonStyle}
      >
        Most Hits
      </Button>
      <Button
        variant="outlined"
        onClick={() => switchSort("date")}
        sx={sortBy === "date" ? enabledButtonStyle : disabledButtonStyle}
      >
        Most Recent
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => switchSort()}
        startIcon={<SwapVertIcon />}
        sx={{ display: { xs: "flex", md: "none" }, textTransform: "capitalize" }}
      >
        {sortBy}
      </Button>
    </Stack>
  )
}
