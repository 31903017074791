import { createTheme, TextFieldProps, PaletteColorOptions } from "@mui/material"
import { darken, lighten } from "@mui/material/styles"

declare module "@mui/material/styles" {
  interface PaletteOptions {
    neutral: PaletteColorOptions
    salesforce: PaletteColorOptions
  }

  interface TypographyVariants {
    "large-number": React.CSSProperties
  }

  interface TypographyVariantsOptions {
    "large-number": React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    "large-number": true
  }
}

const defaultTheme = createTheme()
const { pxToRem } = defaultTheme.typography

const salesforceBase = "#0d9dda"

export const theme = createTheme({
  palette: {
    primary: {
      dark: "#1B242D",
      main: "#566F88",
      light: "#374758",
    },
    secondary: {
      main: "#7FA4CA",
      light: "#D1E1F0",
      contrastText: "#FFFFFF",
    },
    neutral: {
      dark: "#D7D7D7",
      main: "#F9F9F9",
      light: "#FFFFFF",
    },
    salesforce: {
      main: salesforceBase,
      light: lighten(salesforceBase, 0.1),
      dark: darken(salesforceBase, 0.1),
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#8b0000",
    },
  },
  typography: {
    fontFamily: "Barlow",
    h1: {
      fontWeight: "700",
      fontSize: pxToRem(26),
    },
    h2: {
      fontWeight: "500",
      fontSize: pxToRem(26),
    },
    h3: {
      fontFamily: "Barlow",
      fontWeight: "700",
      fontSize: pxToRem(22),
    },
    h4: {
      fontWeight: "700",
      fontSize: pxToRem(18),
    },
    h5: {
      fontWeight: "600",
      fontSize: pxToRem(18),
    },
    h6: {
      fontWeight: "500",
      fontSize: pxToRem(18),
    },
    subtitle1: {
      fontSize: pxToRem(12),
    },
    subtitle2: {
      fontSize: pxToRem(10),
    },
    body1: {
      fontSize: pxToRem(16),
    },
    body2: {
      fontSize: pxToRem(14),
    },
    "large-number": {
      fontWeight: "800",
      fontSize: pxToRem(95),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          textTransform: "none",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialog-paper": {
            borderRadius: "14px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "unset",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "1.8em !important",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: { backgroundColor: "white" },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: { backgroundColor: "white" },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          "&.MuiButton-outlined": {
            backgroundColor: "white",
          },
        },
      },
    },
  },
})

export const selectorTextFieldProps: TextFieldProps = {
  variant: "standard",
  size: "small",
  sx: {
    backgroundColor: "white",
    borderWidth: "1px 1px 0 1px",
    borderStyle: "solid",
    borderColor: "#ddd",
    borderRadius: "8px 8px 0 0",
  },
  InputLabelProps: {
    sx: {
      paddingLeft: 0.5,
    },
  },
}
