import { useMutation, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { Person } from "../../interfaces/person"

const prefix = "/admin/voices"

export const useUpdateVoicePersonId = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { voice_id: number; person_id: number }) => {
      return (await axiosInstance).put<Person>(`${prefix}/${params.voice_id}`, { person_id: params.person_id })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["voices"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useVerifyVoice = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { id: number }) => {
      return (await axiosInstance).put<Person>(`${prefix}/${params.id}/verify`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["voices"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUnverifyVoice = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { id: number }) => {
      return (await axiosInstance).put<Person>(`${prefix}/${params.id}/unverify`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["voices"])
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
