import { FC, useState, useMemo } from "react"
import { Autocomplete, Chip, Grid, TextField } from "@mui/material"
import { useFetchGeographies } from "../../api/geographies"
import { useFetchOrganizations } from "../../api/organizations"
import { FilterKeys, FilterParams, FilterTransform } from "../Home/interface"

export const FilterAutocomplete: FC<{
  filterParams: FilterParams
  setFilterParams: (filteParams: FilterParams) => void
  geographyType: FilterKeys
}> = ({ filterParams, setFilterParams, geographyType }) => {
  const { data: geographyData } = useFetchGeographies()
  const { data: organizationsData } = useFetchOrganizations()
  const [searchValue, setSearchValue] = useState("")

  const recordsFromFilterParams = useMemo(() => {
    let data: FilterTransform[]
    const newData: Record<number, { label: string; state: string }> = {}
    if (geographyType === "channels") {
      data = (organizationsData ? organizationsData["organizations"] : []) as unknown as FilterTransform[]
    } else {
      //TODO: fix this any
      data = geographyData ? (geographyData[geographyType] as any) : []
    }
    data.forEach((item) => (newData[item.id] = { label: item.name, state: item.state_name || item?.state?.name }))
    return newData
  }, [geographyType, organizationsData, geographyData, filterParams])

  const autocompleteOptions = useMemo(() => {
    let data: FilterTransform[]
    if (geographyType === "channels") {
      data = (organizationsData ? organizationsData["organizations"] : []) as unknown as FilterTransform[]
      data.sort((a, b) => (a.state?.name > b.state?.name ? 1 : -1))
    } else {
      //TODO: fix this any
      data = geographyData ? (geographyData[geographyType] as any) : []
      data.sort((a, b) => (a.state_name > b.state_name ? 1 : -1))
    }

    return data.map((item) => item.id)
  }, [geographyData, organizationsData, geographyType])

  const autocompleteFilterValue = useMemo(() => {
    if (geographyType === "channels") {
      return filterParams["organizations"]
    } else {
      return filterParams[geographyType]
    }
  }, [filterParams, geographyType])

  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12}>
        <Autocomplete
          multiple
          autoComplete
          fullWidth
          id="filter-autocomplete-tags"
          value={autocompleteFilterValue}
          onChange={(_event, newValue) => {
            const temp = { ...filterParams }
            if (geographyType === "channels") {
              temp["organizations"] = newValue
            } else {
              temp[geographyType] = newValue
            }
            setFilterParams(temp)
          }}
          renderTags={(tags, getTagProps) =>
            tags.map((tag, index) => (
              <Chip variant="outlined" label={recordsFromFilterParams[tag]?.label} {...getTagProps({ index })} />
            ))
          }
          inputValue={searchValue}
          onInputChange={(_event, newValue) => setSearchValue(newValue)}
          options={autocompleteOptions}
          groupBy={(option) => recordsFromFilterParams[option]?.state}
          getOptionLabel={(option) => recordsFromFilterParams[option]?.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Select ${geographyType.replace(geographyType[0], geographyType[0].toUpperCase())}`}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
