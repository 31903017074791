import { IDashboard } from "../api/interfaces"
import { formatDatetime } from "../components/shared/config"

export interface DashboardColumn {
  id: "id" | "updated_at" | "name" | "description" | "created_by"
  type: "string" | "number" | "datetime" | "boolean"
  label: string
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  link?: any
}

export const dashboardsColumns: readonly DashboardColumn[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 110,
    type: "string",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 110,
    type: "string",
  },
  {
    id: "created_by",
    label: "Creator",
    minWidth: 110,
    type: "string",
  },
  {
    id: "updated_at",
    label: "Last Updated",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
]

export const createDashboardRow: Record<any, any> = (dashboard: IDashboard) => {
  return {
    id: dashboard.id,
    name: dashboard.name,
    description: dashboard.description,
    created_by: dashboard.email,
    updated_at: dashboard.updated_at,
  }
}

export const widgetSelectableDisplayConfig: any = {
  hoursAnalyzed: {
    title: "Hours of Video Analyzed",
    description: "Specify a period of time and see the total amount of hours Cloverleaf compiled for you.",
    image: "https://storage.googleapis.com/cloverleaf-ai-public-assets/hours_analyzed.png", //resized in preview to 320 x 320
    enum: "hours",
  },
  topChannels: {
    title: "Top Channels",
    description:
      "View a list of the top channels that have generated the most keyword results in the geographies you are monitoring.",
    image: "https://storage.googleapis.com/cloverleaf-ai-public-assets/top_channels.png",
    enum: "top_channels",
  },
  keywordsOverTime: {
    title: "Top Keywords Over Time",
    description:
      "Specify a period of time and location to view how keywords have been used in meetings in meetings over time.",
    image: "https://storage.googleapis.com/cloverleaf-ai-public-assets/keywords_over_time.png",
    enum: "keywords_over_time",
  },
  geographiesOverTime: {
    title: "Top Geographies Over Time",
    description:
      "Specify a set of keywords to view the top five geographies those words have been used in during a given date range.",
    image: "https://storage.googleapis.com/cloverleaf-ai-public-assets/geographies_over_time.png",
    enum: "geographies_over_time",
  },
}
