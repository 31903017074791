import { useQuery } from "react-query"
import { State } from "../../interfaces/geography"
import { useAuthAxios } from "../axiosInstance"

export const useFetchGeographiesAdmin: any = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["geographies"], async () => {
    const response = await (await axiosInstance).get<{ states: State[] }>("/admin/geographies")

    return response.data
  })
}

export const useFetchStatesAdmin: any = (daysBack: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["states", daysBack], async () => {
    const response = await (await axiosInstance).get(`/admin/geographies/states?days_back=${daysBack}`)

    return response.data
  })
}

export const useFetchGeographiesMeetingsAdmin: any = (startDate: Date, endDate: Date) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["geographies", startDate, endDate],
    async () => {
      const response = await (
        await axiosInstance
      ).get(`/admin/geographies/meetings?start_date=${startDate}&end_date=${endDate}`)
      return response.data
    },
    {
      refetchInterval: false,
    },
  )
}
