import { FC } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"

export const UseCaseStep: FC<{
  useCase: string
  setUseCase: any
  otherInput: string
  setOtherInput: any
}> = ({ useCase, setUseCase, otherInput, setOtherInput }) => {
  return (
    <Grid container direction="column" justifyContent="center" marginTop={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="use-case-label">Use Case</InputLabel>
          <Select
            fullWidth
            value={useCase}
            label="Use Case"
            labelId="use-case-label"
            onChange={(event: any) => setUseCase(event.target.value)}
          >
            <MenuItem value="deep_research">Deep Research</MenuItem>
            <MenuItem value="opp_id">Opportunity Identification</MenuItem>
            <MenuItem value="rep_mgmt">Reputation Management</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid marginTop={2} item xs={6} hidden={useCase !== "other"}>
        <TextField
          value={otherInput}
          placeholder="What's your use case?"
          onChange={(event: any) => setOtherInput(event.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}
