import { FC, Dispatch, SetStateAction, useEffect } from "react"
import { Box, Slider, Stack, TextField, Typography } from "@mui/material"
import { FilterParams } from "../../../../Home/interface"

export const RangeSlider: FC<{
  filterParams: FilterParams
  setFilterParams: Dispatch<SetStateAction<FilterParams>>
  filterType: "city_population" | "county_population" | "city_income" | "county_income"
  label: string
}> = ({ filterParams, setFilterParams, label, filterType }) => {
  useEffect(() => {
    // old implementation made it possible for value to be ""
    if (filterParams[filterType]?.some((value) => typeof value === "string")) {
      const newFilterParams = { ...filterParams }
      const newFilterRange: [number, number] = [-1, -1]
      if (typeof filterParams[filterType][0] !== "string") {
        newFilterRange[0] = filterParams[filterType][0]
      }
      if (typeof filterParams[filterType][1] !== "string") {
        newFilterRange[1] = filterParams[filterType][1]
      }
      newFilterParams[filterType] = newFilterRange
      setFilterParams(newFilterParams)
    }
  }, [filterParams])
  let max: number
  if (filterType === "city_population") {
    max = 10000000
  } else if (filterType === "county_population") {
    max = 20000000
  } else {
    max = 300000
  }
  const isPopulation = filterType === "city_population" || filterType === "county_population"
  const transformFactor = isPopulation ? 1 : max / 100
  const valueLabelFormat = (value: number) => {
    if (isPopulation && value !== 0 && !value.toString().match(/^\d0+$/)) {
      // because the scaling on the population slider isn't a continuous formula,
      // the label gets weird if you use the TextField
      const lowerValue = filterParams[filterType][0]
      const higherValue = filterParams[filterType][1]
      // need to assign these values to constants to make typescript happy
      if (lowerValue !== -1) {
        return higherValue.toLocaleString()
      } else if (higherValue !== -1) {
        return lowerValue.toLocaleString()
      } else {
        if (Math.abs(value - lowerValue) < Math.abs(value - higherValue)) {
          return lowerValue.toLocaleString()
        } else {
          return higherValue.toLocaleString()
        }
      }
    } else {
      return value.toLocaleString()
    }
  }
  const valueToFilterValue = (value: number) => {
    if (!isPopulation) {
      return value * transformFactor
    } else if (value === 0) {
      return 0
    } else {
      const leadingNumber = ((value - 1) % 9) + 1
      return leadingNumber * 100 * 10 ** Math.ceil(value / 9) // minimum 1000
    }
  }
  const filterValueToValue = (fallbackValue: number, value?: number) => {
    if (value === undefined || value === null || value === -1) {
      return fallbackValue
    } else if (!isPopulation) {
      return (value * 100) / max
    } else if (value === 0) {
      return 0
    } else {
      // this is the inverse of valueToFilterValue
      const leadingNumber = parseInt(value.toString()[0])
      const ceil = (Math.log10(value / leadingNumber) - 2) * 9
      return ceil - 9 + leadingNumber
    }
  }
  const validate = () => {
    if (
      filterParams[filterType][0] >= 0 &&
      filterParams[filterType][1] >= 0 &&
      filterParams[filterType][0] > filterParams[filterType][1]
    ) {
      setFilterParams({
        ...filterParams,
        [filterType]: [filterParams[filterType][1], filterParams[filterType][0]],
      })
    }
  }

  const toPrettyTextField = (value: number) => {
    let valueString = `${value}`
    for (let i = valueString.length - 3; i > 0; i -= 3) {
      valueString = `${valueString.slice(0, i)},${valueString.slice(i)}`
    }
    return valueString
  }

  const fromPrettyTextField = (value: string) => {
    let numberValue = ""
    for (let i = 0; i < value.length; i += 1) {
      if (!isNaN(parseInt(value.charAt(i)))) {
        numberValue += value.charAt(i)
      }
    }
    if (numberValue === "") {
      return ""
    }
    return parseInt(numberValue)
  }

  return (
    <Box className={`range-slider ${filterType}`} width={280} minWidth={160} marginX={1.5}>
      <Typography paddingTop={1} paddingLeft={1} variant="body2" fontWeight={500}>
        {label}
      </Typography>
      <Slider
        value={
          filterParams[filterType]
            ? [filterValueToValue(0, filterParams[filterType][0]), filterValueToValue(100, filterParams[filterType][1])]
            : [0, 100]
        }
        valueLabelDisplay="auto"
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        scale={valueToFilterValue}
        max={isPopulation ? filterValueToValue(100, max) : undefined}
        onChange={(_event, value, activeThumb) =>
          setFilterParams((prevParams) => {
            if (Array.isArray(value)) {
              if (activeThumb === 0) {
                return {
                  ...prevParams,
                  [filterType]: [valueToFilterValue(value[0]), prevParams[filterType] ? prevParams[filterType][1] : ""],
                }
              } else {
                return {
                  ...prevParams,
                  [filterType]: [prevParams[filterType] ? prevParams[filterType][0] : "", valueToFilterValue(value[1])],
                }
              }
            } else {
              return prevParams // value should always be an array
            }
          })
        }
      />
      <Stack direction="row" spacing={1}>
        <TextField
          id="min-number"
          // type="number"
          size="small"
          placeholder="0"
          value={
            filterParams[filterType] && filterParams[filterType][0] !== -1
              ? toPrettyTextField(filterParams[filterType][0])
              : ""
          }
          onChange={(event) =>
            setFilterParams({
              ...filterParams,
              [filterType]: [fromPrettyTextField(event.target.value), filterParams[filterType][1]],
            })
          }
          onBlur={validate}
        />
        <TextField
          id="max-number"
          // type="number"
          size="small"
          placeholder={toPrettyTextField(max)}
          value={
            filterParams[filterType] && filterParams[filterType][1] !== -1
              ? toPrettyTextField(filterParams[filterType][1])
              : ""
          }
          onChange={(event) =>
            setFilterParams({
              ...filterParams,
              [filterType]: [filterParams[filterType][0], fromPrettyTextField(event.target.value)],
            })
          }
          onBlur={validate}
        />
      </Stack>
    </Box>
  )
}
