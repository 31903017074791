import React, { ForwardedRef, forwardRef } from "react"
import { OutlinedTextFieldProps, TextField, Typography, Box, Skeleton } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Variant } from "@mui/material/styles/createTypography"

interface NameTextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  textVariant?: Variant
  editing?: boolean
  skeletonWidth?: false | number | string
}

export const NameTextField = forwardRef(function Component(props: NameTextFieldProps, ref: ForwardedRef<HTMLElement>) {
  const { typography } = useTheme()
  const { textVariant, inputProps, sx, editing, value, multiline, skeletonWidth } = props

  const filteredProps = { ...props }
  // we delete textVariant, editing, and skeleton because the browser throws a warning because it doesn't recognize them
  delete filteredProps.textVariant
  delete filteredProps.editing
  delete filteredProps.skeletonWidth

  const filteredInputProps = { ...inputProps }
  delete filteredInputProps.sx

  let inputStyle
  if (textVariant) {
    inputStyle = typography[textVariant]
  }

  return (
    <Box sx={{ ...sx, position: "relative" }}>
      <Typography
        variant={textVariant}
        ref={ref}
        sx={{
          overflowX: multiline ? "unset" : "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
          position: "absolute",
          paddingLeft: "8px",
          paddingY: multiline ? "4px" : "5px",
          whiteSpace: multiline ? "unset" : "nowrap",
          ...inputProps?.sx,
        }}
      >
        {skeletonWidth ? <Skeleton width={skeletonWidth} /> : typeof value === "string" && value}
      </Typography>
      <TextField
        fullWidth
        {...filteredProps}
        sx={{
          pointerEvents: editing ? "auto" : "none",
          opacity: editing ? "1" : "0",
          transition: "opacity 0.2s",
          "& > .MuiInputBase-root": {
            padding: "0",
          },
        }}
        inputProps={{
          spellCheck: editing,
          sx: {
            ...inputStyle,
            paddingX: 1,
            paddingY: 0.5,
            ...inputProps?.sx,
          },
          ...filteredInputProps,
        }}
      />
    </Box>
  )
})
