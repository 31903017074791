import { FC, useState } from "react"
import {
  Table as MTable,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TableSortLabel,
  TableFooter,
  TablePagination,
  Tooltip,
  Stack,
  Skeleton,
} from "@mui/material"
import Loading from "./Loading"
import { TableCellContent } from "./TableCellContent"
import { Info } from "@mui/icons-material"

type Order = "asc" | "desc"

const stableSort = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: any) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (a[orderBy] === b[orderBy]) {
    return 0
  } else if (a[orderBy] === undefined || a[orderBy] === null) {
    return -1
  } else if (b[orderBy] === undefined || b[orderBy] === null) {
    return 1
  } else {
    return a[orderBy] < b[orderBy] ? 1 : -1
  }
}

export const TableRowsLoader = () => {
  return (
    <TableCell>
      <Skeleton animation="wave" variant="text" />
    </TableCell>
  )
}

const getComparator = (order: Order, orderBy: any) => {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

export const Table: FC<{
  data: any[]
  selected: number[]
  setSelected: any
  createRow: any
  columns: any
  sortKey?: string
  sortOrder?: Order
  showCheckbox?: boolean
  showFooter?: boolean
}> = ({
  data,
  selected,
  setSelected,
  createRow,
  columns,
  sortKey = "id",
  sortOrder,
  showCheckbox = true,
  showFooter = false,
}) => {
  const [order, setOrder] = useState<Order>(sortOrder || "desc")
  const [orderBy, setOrderBy] = useState(sortKey)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(-1)

  if (data === undefined) {
    return <Loading />
  }

  const rows = data?.map((rowData: any) => createRow(rowData))

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property)
  }

  const handleCheckClicked = (id: number) => {
    const selectedCopy = [...selected]
    const selectedIndex = selectedCopy.indexOf(id)
    if (selectedIndex === -1) {
      selectedCopy.push(id)
      setSelected(selectedCopy)
    } else {
      selectedCopy.splice(selectedIndex, 1)
      setSelected(selectedCopy)
    }
  }

  const handleCheckAllClicked = () => {
    if (selected.length === rows.length) {
      setSelected([])
    } else {
      const allIds = rows.map((row: any) => row.id)
      setSelected(allIds)
    }
  }

  const sliceVars = rowsPerPage === -1 ? [0, rows.length] : [page * rowsPerPage, page * rowsPerPage + rowsPerPage]

  return (
    <TableContainer>
      <MTable size="small">
        <TableHead>
          <TableRow>
            {showCheckbox && (
              <TableCell>
                <Checkbox
                  color="primary"
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleCheckAllClicked}
                  inputProps={{
                    "aria-label": "select all rows",
                  }}
                />
              </TableCell>
            )}
            {columns.map((column: any) => {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, width: column.width }}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                    {"tooltip" in column && column.tooltip && (
                      <Tooltip title={column.tooltip}>
                        <Info />
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice(...sliceVars)
            .map((row: any) => {
              return (
                <TableRow hover key={row.id}>
                  {showCheckbox && (
                    <TableCell key={`checkbox-${row.id}`}>
                      <Checkbox checked={selected.indexOf(row.id) !== -1} onChange={() => handleCheckClicked(row.id)} />
                    </TableCell>
                  )}
                  {columns.map((column: any) => {
                    const value = row[column.id]
                    return <TableCellContent key={column.id} row={row} column={column} value={value} />
                  })}
                </TableRow>
              )
            })}
        </TableBody>
        {showFooter && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100, { label: "All", value: -1 }]}
                count={rows.length}
                colSpan={4}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={(_event, number) => setPage(number)}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              />
            </TableRow>
          </TableFooter>
        )}
      </MTable>
    </TableContainer>
  )
}
