import { Chip, Box } from "@mui/material"
import { FC, useContext } from "react"
import DateRangeIcon from "@mui/icons-material/DateRange"
import moment from "moment"
import { HomePageContext } from "../../../pages/HomePage"
import { FetchedSearch } from "../../../api/search"

export const SearchOverview: FC<{ meetingsSearchData: FetchedSearch | undefined }> = ({ meetingsSearchData }) => {
  const homePageContext = useContext(HomePageContext)
  const { displayDateRange } = homePageContext.homePageParams
  return (
    <>
      <Chip
        color="primary"
        label={
          meetingsSearchData
            ? `Meetings Found: ${
                meetingsSearchData?.total_meeting_hits
              }${meetingsSearchData?.total_meeting_hits === 1000 ? "+" : ""}`
            : "Meetings Loading..."
        }
      />
      <Chip
        color="primary"
        label={
          meetingsSearchData
            ? `Matches: ${meetingsSearchData?.total_hits}${meetingsSearchData?.total_hits === 10000 ? "+" : ""}`
            : "Matches Loading..."
        }
      />
      <Box flexGrow={1}>
        {displayDateRange && (
          <Chip
            icon={<DateRangeIcon />}
            label={`${moment(displayDateRange[0]).format("MM/DD/YY")} - ${moment(displayDateRange[1]).format("MM/DD/YY")}`}
          />
        )}
      </Box>
    </>
  )
}
