import { FC, useEffect, useState, useMemo } from "react"
import { Stack, Autocomplete, Typography, IconButton, TextField, Box } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import CloseIcon from "@mui/icons-material/Close"
import { getSalesforceSObjects } from "../../../api/account"
import { getMainSalesforceObject, useSetMainSalesforceObject } from "../../../api/userOrganizations/salesforce"
import { SObject } from "../../../interfaces/salesforce"

export const SelectMainSalesforceObject: FC = () => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const { data: salesforceObjects } = getSalesforceSObjects()
  const { data: mainSalesforceObject, isLoading } = getMainSalesforceObject()
  const { mutate: setMainSalesforceObject } = useSetMainSalesforceObject(() => setEditing(false))

  const sobjectsMap = useMemo(() => {
    const map = new Map<string, SObject>()
    if (salesforceObjects) {
      salesforceObjects.sobjects
        // TODO: remove below after getting response from salesforce about required fields
        .filter((sobject) => sobject.name === "Account")
        .forEach((sobject) => {
          map.set(sobject.name, sobject)
        })
    }
    return map
  }, [salesforceObjects])

  useEffect(() => {
    if (mainSalesforceObject) {
      setValue(mainSalesforceObject.name)
    }
  }, [mainSalesforceObject])

  const showEditButtons = editing || !mainSalesforceObject
  let content = null
  if (isLoading) {
    return null
    // content = <Skeleton width="100px" />; // there might be logic where this is preferable down the line
  } else {
    if (sobjectsMap.size === 0) {
      return null
    }
    if (showEditButtons) {
      if (salesforceObjects) {
        content = (
          <Autocomplete
            size="small"
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            options={Array.from(sobjectsMap.keys())}
            sx={{ width: 300 }}
            getOptionLabel={(option) => sobjectsMap.get(option)?.label || ""}
            renderInput={(params) => <TextField {...params} label="Salesforce Object" />}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option}>
                {sobjectsMap.get(option)?.label}
              </Box>
            )}
          />
        )
      }
    } else if (mainSalesforceObject) {
      content = <Typography>{mainSalesforceObject.label}</Typography>
    }
  }

  return (
    <Stack direction="row" alignItems="center" marginTop={1} spacing={1}>
      <Typography>Main Salesforce Object:</Typography>
      {content}
      {showEditButtons ? (
        <>
          <IconButton
            size="small"
            onClick={() => {
              if (value) {
                setMainSalesforceObject({ sobject: sobjectsMap.get(value) })
              }
            }}
            disabled={!value}
          >
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => setEditing(false)} size="small">
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={() => setEditing(true)} size="small">
          <EditIcon />
        </IconButton>
      )}
    </Stack>
  )
}
