import { FC, useEffect, useState, useMemo } from "react"
import { Stack, Autocomplete, Typography, IconButton, Skeleton, TextField, Box } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import CloseIcon from "@mui/icons-material/Close"
import { getSalesforceSObjects } from "../../../api/account"
import {
  getMainSalesforceObjectAttachment,
  getMainSalesforceObjectChildren,
  useSetMainSalesforceObjectAttachment,
} from "../../../api/userOrganizations/salesforce"
import { SObject, SObjectChild } from "../../../interfaces/salesforce"

const childSObjectToKey = (sobjectChild: SObjectChild) => `${sobjectChild.childSObject}${sobjectChild.relationshipName}`

export const SelectMainSalesforceObjectAttachment: FC = () => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const { data: salesforceObjects } = getSalesforceSObjects()
  const { data: mainSalesforceObjectChildren } = getMainSalesforceObjectChildren()
  const { data: mainSalesforceObjectAttachment, isLoading } = getMainSalesforceObjectAttachment()
  const { mutate: setMainSalesforceObjectAttachment } = useSetMainSalesforceObjectAttachment(() => setEditing(false))

  const sobjectsMap = useMemo(() => {
    const map = new Map<string, SObject>()
    if (salesforceObjects) {
      salesforceObjects.sobjects.forEach((sobject) => {
        map.set(sobject.name, sobject)
      })
    }
    return map
  }, [salesforceObjects])

  const sobjectsChildrenMap = useMemo(() => {
    const map = new Map<string, SObjectChild>()
    if (mainSalesforceObjectChildren) {
      mainSalesforceObjectChildren
        // TODO: remove below after getting response from salesforce about required fields
        .filter((sobjectChild) => childSObjectToKey(sobjectChild) === "EventEvents")
        .forEach((sobjectChild) => {
          map.set(childSObjectToKey(sobjectChild), sobjectChild)
        })
    }
    return map
  }, [mainSalesforceObjectChildren])

  useEffect(() => {
    if (mainSalesforceObjectAttachment) {
      setValue(childSObjectToKey(mainSalesforceObjectAttachment))
    }
  }, [mainSalesforceObjectAttachment])

  if (!mainSalesforceObjectChildren?.length) {
    return null
  }

  const showEditButtons = editing || !mainSalesforceObjectAttachment
  let content = null
  if (isLoading) {
    content = <Skeleton width="100px" />
  } else {
    if (showEditButtons) {
      if (salesforceObjects) {
        content = (
          <Autocomplete
            size="small"
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            options={Array.from(sobjectsChildrenMap.keys())}
            sx={{ width: 400 }}
            getOptionLabel={(option) => {
              const sobjectChild = sobjectsChildrenMap.get(option)
              if (sobjectChild) {
                return sobjectsMap.get(sobjectChild.childSObject)?.label || ""
              }
              return ""
            }}
            renderInput={(params) => <TextField {...params} label="Salesforce Object Attachment" />}
            renderOption={(props, option) => {
              const sobjectChild = sobjectsChildrenMap.get(option)
              if (sobjectChild) {
                const sobject = sobjectsMap.get(sobjectChild.childSObject)
                const details = [sobjectChild.field]
                if (sobjectChild.relationshipName) {
                  details.push(sobjectChild.relationshipName)
                }
                return (
                  <Box component="li" {...props} key={option}>
                    {`${sobject?.label} (${details.join(", ")})`}
                  </Box>
                )
              } else {
                return null
              }
            }}
          />
        )
      }
    } else if (mainSalesforceObjectAttachment) {
      content = <Typography>{mainSalesforceObjectAttachment.label}</Typography>
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography>Main Salesforce Object Attachment:</Typography>
      {content}
      {showEditButtons ? (
        <>
          <IconButton
            size="small"
            onClick={() => {
              if (value) {
                const sobjectChild = sobjectsChildrenMap.get(value)
                if (sobjectChild) {
                  setMainSalesforceObjectAttachment({
                    sobject: {
                      ...sobjectChild,
                      ...sobjectsMap.get(sobjectChild.childSObject),
                    },
                  })
                }
              }
            }}
            disabled={!value}
          >
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => setEditing(false)} size="small">
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={() => setEditing(true)} size="small">
          <EditIcon />
        </IconButton>
      )}
    </Stack>
  )
}
