import { IOrganization } from "../api/interfaces"
import { formatBool, formatDate, formatDatetime, formatUrl } from "../components/shared/config"
import { Column } from "../components/shared/DataTable"
import { TableCellProps, TypographyProps } from "@mui/material"

export interface OrganizationColumn extends Column {
  id:
    | "id"
    | "created_at"
    | "updated_at"
    | "name"
    | "title"
    | "state_name"
    | "city_name"
    | "county_name"
    | "automatic_update"
    | "video_archive_id"
    | "video_archive_type"
    | "last_scraped_at"
    | "most_recent_published_at"
    | "total_meetings"
    | "spam_enabled"
    | "channel_type"
    | "home_page_url"
    | "contact_page_url"
    | "shuffle_region"
    | "opp_analysis_enabled"
}

const orgTableTypographyProps: TypographyProps = { variant: "body2" }
const orgTableHeadersStyling: TableCellProps = { style: { fontSize: "1em", padding: "10px" } }

const linkGenerator = (dataKey: string, prefix?: string) => {
  return (data: Record<string, any>): string => {
    const urlPrefix = prefix ? `${prefix}/` : ""
    return `${urlPrefix}${data[dataKey]}`
  }
}

export const organizationColumns: readonly OrganizationColumn[] = [
  {
    id: "id",
    label: "Id",
    headerCellProps: { sx: { minWidth: 30 }, ...orgTableHeadersStyling },
    typographyProps: orgTableTypographyProps,
  },
  {
    id: "name",
    label: "Channel",
    headerCellProps: { sx: { minWidth: 170 }, ...orgTableHeadersStyling },
    link: linkGenerator("id", "/admin/organizations"),
    typographyProps: orgTableTypographyProps,
  },
  {
    id: "automatic_update",
    label: "Auto Update",
    headerCellProps: { sx: { minWidth: 30 }, ...orgTableHeadersStyling },
    formatter: formatBool,
  },
  {
    id: "shuffle_region",
    label: "Proxy Shuffle",
    headerCellProps: { sx: { minWidth: 30 }, ...orgTableHeadersStyling },
    formatter: formatBool,
  },
  {
    id: "channel_type",
    label: "Channel Type",
    headerCellProps: { sx: { minWidth: 150 }, ...orgTableHeadersStyling },
  },
  {
    id: "state_name",
    label: "State",
    headerCellProps: { sx: { minWidth: 80 }, ...orgTableHeadersStyling },
  },
  {
    id: "county_name",
    label: "County",
    headerCellProps: { sx: { minWidth: 100 }, ...orgTableHeadersStyling },
  },
  {
    id: "city_name",
    label: "City",
    headerCellProps: { sx: { minWidth: 100 }, ...orgTableHeadersStyling },
  },
  {
    id: "video_archive_type",
    label: "Video Archive Type",
    headerCellProps: { sx: { minWidth: 80 }, ...orgTableHeadersStyling },
  },
  {
    id: "video_archive_id",
    label: "Video Archive Id",
    headerCellProps: { sx: { minWidth: 110 }, ...orgTableHeadersStyling },
  },
  {
    id: "home_page_url",
    label: "Home Page URL",
    headerCellProps: { sx: { minWidth: 100 }, ...orgTableHeadersStyling },
    link: linkGenerator("home_page_url"),
    formatter: formatUrl,
  },
  {
    id: "contact_page_url",
    label: "Contact Page URL",
    headerCellProps: { sx: { minWidth: 100 }, ...orgTableHeadersStyling },
    link: linkGenerator("contact_page_url"),
    formatter: formatUrl,
  },
  {
    id: "last_scraped_at",
    label: "Last Finished",
    headerCellProps: { sx: { minWidth: 170 }, ...orgTableHeadersStyling },
    formatter: formatDatetime,
  },
  {
    id: "most_recent_published_at",
    label: "Most Recently Published",
    headerCellProps: { sx: { minWidth: 170 }, ...orgTableHeadersStyling },
    formatter: formatDatetime,
    sortingEnabled: false,
  },
  {
    id: "total_meetings",
    label: "Total Meetings",
    headerCellProps: { sx: { minWidth: 80 }, ...orgTableHeadersStyling },
    sortingEnabled: false,
  },
  {
    id: "spam_enabled",
    label: "Spam Enabled",
    headerCellProps: { sx: { minWidth: 80 }, ...orgTableHeadersStyling },
    formatter: formatBool,
  },
  {
    id: "opp_analysis_enabled",
    label: "Opportunity Analysis Enabled",
    headerCellProps: { sx: { minWidth: 80 }, ...orgTableHeadersStyling },
    formatter: formatBool,
  },
  {
    id: "created_at",
    label: "Created At",
    headerCellProps: { sx: { minWidth: 170 }, ...orgTableHeadersStyling },
    formatter: formatDate,
  },
  {
    id: "updated_at",
    label: "Updated At",
    headerCellProps: { sx: { minWidth: 150 }, ...orgTableHeadersStyling },
    formatter: formatDatetime,
  },
]

export const createOrganizationRow = (organization: IOrganization) => {
  return {
    id: organization.id,
    name: organization.name,
    channel_type: organization?.type?.name,
    state_name: organization?.state?.name,
    county_name: organization?.county?.name,
    city_name: organization?.city?.name,
    automatic_update: organization?.automatic_update,
    created_at: organization.created_at,
    updated_at: organization.updated_at,
    video_archive_id: organization.video_archive_id,
    video_archive_type: organization.video_archive_type,
    last_scraped_at: organization.last_scraped_at,
    most_recent_published_at: organization.most_recent_published_at,
    total_meetings: organization.total_meetings,
    spam_enabled: organization.spam_enabled,
    home_page_url: organization.home_page_url,
    contact_page_url: organization.contact_page_url,
    shuffle_region: organization.shuffle_region,
    opp_analysis_enabled: organization.opp_analysis_enabled,
  }
}
