import { IconButton, List, ListItem, Paper, Stack, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { SignalsContainerContext } from "@src/containers/SignalsUatContainer"
import { FC, useContext, useEffect, useState } from "react"
import { AddAllowedUsers } from "./AddAllowedUsers"
import { SignalsUatAllowedUsers, useDeleteSignalsUatAllowedUser } from "@src/api/admin/users"
import { useToast } from "@src/components/shared/ToastHook"
import TaskAltIcon from "@mui/icons-material/TaskAlt"

export const AllowedUsers: FC = () => {
  const { allowedUsers } = useContext(SignalsContainerContext)
  const { mutate: deleteSignalsUatAllowedUser, isSuccess: deleteSignalsUatAllowedUserSuccess } =
    useDeleteSignalsUatAllowedUser()
  const [userDeleted, setUserDeleted] = useState<SignalsUatAllowedUsers | null>(null)
  const setToast = useToast()

  const handleDelete = (id: number) => {
    deleteSignalsUatAllowedUser({ id })
  }

  const handleDeleteButtonOnClick = (user: SignalsUatAllowedUsers) => {
    handleDelete(user.id)
    setUserDeleted(user)
  }

  useEffect(() => {
    if (deleteSignalsUatAllowedUserSuccess && userDeleted) {
      setToast(`${userDeleted.email} removed successfully`, 3000, <TaskAltIcon />)
    }
  }, [deleteSignalsUatAllowedUserSuccess, userDeleted])

  return (
    <Paper
      elevation={2}
      sx={{
        width: "50vw",
        height: 250,
        overflow: "auto",
      }}
    >
      <Stack padding={1} spacing={1}>
        <Typography variant="h4">Allowed Users</Typography>
        <Typography>
          These users will receive emails for testing. Please do not add anyone who should not receive emails. Thank
          you!
        </Typography>
        <Typography>
          Reminder: users on this list who do not have daily campaigns enabled, or do not have hits within the number
          specified on the form will not receive an email.
        </Typography>
        <AddAllowedUsers />
        <List dense sx={{ margin: "0px !important", padding: "0px", lineHeight: "1px" }}>
          {allowedUsers
            .sort((a, b) => a.email?.localeCompare(b.email))
            .map((user) => (
              <ListItem key={user.id}>
                <Stack direction="row" alignItems="center">
                  {user.email}
                  <IconButton onClick={() => handleDeleteButtonOnClick(user)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </ListItem>
            ))}
        </List>
      </Stack>
    </Paper>
  )
}
