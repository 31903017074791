import { createContext } from "react"

interface ContextData {
  ids: number[]
  reset: () => void
}
const contextData: ContextData = {
  ids: [],
  reset: () => {
    /* placeholder for typescript */
  },
}
export const AdminUserContext = createContext(contextData)
