import { FC } from "react"
import { Stack } from "@mui/material"
import { BriefingTextSearchInput } from "@src/components/Briefings/BriefingFilters/BriefingTextSearchInput"
import { BriefingTypeAutocomplete } from "@src/components/Briefings/BriefingFilters/BriefingTypeAutocomplete"
import { BriefingUsersAutocomplete } from "@src/components/Briefings/BriefingFilters/BriefingUsersAutocomplete"
import { BriefingGeographiesAutocompletes } from "@src/components/Briefings/BriefingFilters/BriefingGeographiesAutocompletes"
import { BriefingChannelAutocomplete } from "@src/components/Briefings/BriefingFilters/BriefingChannelAutocomplete"

export const BriefingsDataFiltersContainer: FC = () => {
  return (
    <>
      <Stack direction="row" marginTop={2} gap={1} flexWrap="wrap">
        <BriefingTextSearchInput />
        <Stack gap={1} direction="row" flex="70ch 1 0">
          <BriefingTypeAutocomplete />
          <BriefingUsersAutocomplete />
        </Stack>
      </Stack>
      <Stack direction="row" gap={1} marginTop={1}>
        <BriefingGeographiesAutocompletes />
        <BriefingChannelAutocomplete />
      </Stack>
    </>
  )
}
