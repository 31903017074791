import { TranscriptWord } from "../../interfaces/transcript"

export const normalize = (text: string) => text.replaceAll(/[^\w\d\s]/g, " ").toLowerCase()

export const termToRegex = (term: string | string[], flags = "") => {
  if (typeof term === "string") {
    return new RegExp(`^(${term})\\W|\\W(${term})\\W|\\W(${term})$|^(${term})$`, flags)
  } else {
    return new RegExp(
      term
        .map((singleTerm) => `^(${singleTerm})\\W|\\W(${singleTerm})\\W|\\W(${singleTerm})$|^(${singleTerm})$`)
        .join("|"),
      flags,
    )
  }
}

export const centerTermInText = (text: string, spacing: number, searchWords: string[], words?: TranscriptWord[]) => {
  const searchRegex = termToRegex(
    searchWords.map((term) => normalize(term)),
    "id",
  )
  const regexResult = searchRegex.exec(normalize(text))
  let transcriptText = text
  let centerTerm: string | undefined
  let transcriptWords = words
  if (regexResult) {
    if (regexResult.index > spacing) {
      transcriptText = text.slice(Math.max(regexResult.index - spacing, 0))
      transcriptText = transcriptText.slice(transcriptText.indexOf(" "))
      // trim begging of words array to match snippet
      if (transcriptWords) {
        const textArray = text.slice(0, Math.max(regexResult.index - spacing, 0)).split(" ")
        transcriptWords = transcriptWords.slice(textArray.length - 1)
      }
    }

    if (transcriptText.length > spacing * 2) {
      const textArray = transcriptText.slice(0, spacing * 2).split(" ")
      transcriptText = transcriptText.slice(0, spacing * 2)
      transcriptText = transcriptText.slice(0, transcriptText.lastIndexOf(" "))
      // trim end of words array to match snippet
      if (transcriptWords) {
        transcriptWords = transcriptWords.slice(0, textArray.length - 2)
      }
    }

    // use transcriptText to trim words
    // writing it like this because the linter doesn't recognize this as valid. Outdated?
    const { indices } = regexResult as unknown as { indices: ([number, number] | undefined)[] }
    if (indices?.length > 1) {
      const match = indices.slice(1).find((indexValues) => indexValues)
      if (match) {
        centerTerm = text.slice(match[0], match[1])
      }
    }
  }
  return {
    snippet: transcriptText,
    centerTerm,
    transcriptWords: transcriptWords,
  }
}

export const centerWordsInText = (text: string, spacing: number, searchWords: string[]) => {
  const searchRegex = termToRegex(
    searchWords.map((term) => normalize(term)),
    "id",
  )
  const regexResult = searchRegex.exec(normalize(text))
  let transcriptText = text
  let centerTerm: string | undefined
  if (regexResult) {
    if (regexResult.index > spacing) {
      transcriptText = text.slice(Math.max(regexResult.index - spacing, 0))
      transcriptText = transcriptText.slice(transcriptText.indexOf(" "))
    }
    if (transcriptText.length > spacing * 2) {
      transcriptText = transcriptText.slice(0, spacing * 2)
      transcriptText = transcriptText.slice(0, transcriptText.lastIndexOf(" "))
    }

    // writing it like this because the linter doesn't recognize this as valid. Outdated?
    const { indices } = regexResult as unknown as { indices: ([number, number] | undefined)[] }
    if (indices?.length > 1) {
      const match = indices.slice(1).find((indexValues) => indexValues)
      if (match) {
        centerTerm = text.slice(match[0], match[1])
      }
    }
  }
  return {
    snippet: transcriptText,
    centerTerm,
  }
}
