import { FC, useState } from "react"
import { Box, Fab, Stack, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap"
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap"
import { Footer } from "../../Nav/Footer"
import { VideoPlayer } from "./VideoPlayer"

export const MobileVideo: FC = () => {
  const [mobilePlayerExpanded, setMobilePlayerExpanded] = useState(false)
  const theme = useTheme()
  const showVideo = useMediaQuery(theme.breakpoints.down("md"))

  if (showVideo) {
    return (
      <Box display={{ xs: "block", md: "none" }} position="fixed" right="0" bottom="0" sx={{ pointerEvents: "none" }}>
        <Stack alignItems="flex-end">
          <Fab
            onClick={() => setMobilePlayerExpanded((prevValue) => !prevValue)}
            size="small"
            sx={{ pointerEvents: "auto", marginRight: 2, marginBottom: 1 }}
          >
            {mobilePlayerExpanded ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
          </Fab>
          <Box width={mobilePlayerExpanded ? `${window.innerWidth}px` : "320px"} sx={{ transition: "width 0.4s" }}>
            <Box
              position="relative"
              right={mobilePlayerExpanded ? "0" : "16px"}
              paddingTop="56.25%"
              sx={{
                opacity: mobilePlayerExpanded ? "1" : "0.7",
                transition: "opacity 0.4s, right 0.4s",
                pointerEvents: "auto",
              }}
            >
              <VideoPlayer width="100%" height="100%" style={{ position: "absolute", top: 0, left: 0 }} />
            </Box>
          </Box>
        </Stack>
        <Box padding={2} sx={{ opacity: "0", pointerEvents: "none" }}>
          <Footer />
        </Box>
      </Box>
    )
  }
  return null
}
