import { useMemo } from "react"
import { Campaign } from "@interfaces/campaign"

export const getUsersToUnassign = (sortedCampaigns: Campaign[]) => {
  return useMemo(() => {
    if (!sortedCampaigns || sortedCampaigns.length === 0) {
      return []
    }
    const validCampaigns = sortedCampaigns.filter((campaign) => campaign !== undefined)
    const allUsers = validCampaigns.map((campaign) => campaign.assignees).flat()
    const assignedUsers = Array.from(new Map(allUsers.map((assignee) => [assignee.email, assignee])).values())
    return assignedUsers
  }, [sortedCampaigns])
}

export const displayColumnHeaders = (selected: number[]) => {
  if (selected.length === 0) {
    return {}
  } else {
    return { display: "none" }
  }
}

export const displayCampaignActions = (selected: number[]) => {
  if (selected.length > 0) {
    return {}
  } else {
    return { display: "none" }
  }
}
