import { FC } from "react"
import { marked } from "marked"
import { sanitize } from "dompurify"

export const BriefingContent: FC<{ promptResult: string }> = ({ promptResult }) => {
  if (promptResult) {
    const briefingElement = (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(marked.parse(promptResult) as string),
        }}
      />
    )
    return <>{briefingElement}</>
  }
  return null
}
