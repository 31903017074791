import { FC, useState } from "react"
import { Button, Grid, Chip } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"
import { useFetchCurrentUser, useIsAdmin } from "../../api/users"
import { CampaignDialog } from "../shared/CampaignDialog"
import { useDeleteCampaign, useCloneCampaigns, useUnassignCampaign } from "../../api/campaigns"

export const CampaignListItemActions: FC<{
  isEditing: boolean
  setIsEditing: any
  setDialogOpen: any
  filterTermsInput: any
  campaign: any
  closeModals: any
  mustIncludeInput: any
}> = ({ isEditing, setIsEditing, setDialogOpen, filterTermsInput, campaign, closeModals, mustIncludeInput }) => {
  const { mutate: deleteCampaign, isSuccess: deleteSuccess } = useDeleteCampaign()
  const { mutate: cloneCampaigns, isSuccess: cloneSuccess } = useCloneCampaigns()
  const { mutate: unassignCampaigns, isSuccess: unassignSuccess } = useUnassignCampaign()
  const { data: currentUser } = useFetchCurrentUser()
  const { data: isAdmin } = useIsAdmin()
  const [openCloneDialog, setOpenCloneDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openUnassignDialog, setOpenUnassignDialog] = useState(false)

  const userCanDelete = campaign?.assignees?.length === 0 || isAdmin
  const campaignHasAssignees = campaign?.assigned
  let deleteOrRemoveButton: any
  if (campaignHasAssignees) {
    deleteOrRemoveButton = (
      <Button onClick={() => setOpenUnassignDialog(true)} color="error" variant="outlined">
        Unassign
      </Button>
    )
  } else if (userCanDelete) {
    deleteOrRemoveButton = (
      <Button onClick={() => setOpenDeleteDialog(true)} color="error" variant="outlined">
        Delete
      </Button>
    )
  }

  if (!currentUser) {
    return null
  }

  if (isEditing) {
    return (
      <>
        <Grid item>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => setDialogOpen("update")}
            disabled={filterTermsInput.length === 0 && mustIncludeInput.length === 0}
          >
            Save
          </Button>
        </Grid>
      </>
    )
  } else {
    return (
      <>
        <Grid item>
          <Button
            onClick={() => {
              if (campaign.id) {
                const urlSearchParams = new URLSearchParams()
                urlSearchParams.set("campaign-id", campaign.id.toString())
                window.open(`/?${urlSearchParams}`)
              }
            }}
            color="primary"
            variant="outlined"
          >
            View
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => setIsEditing(true)} color="primary" variant="contained">
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => setOpenCloneDialog(true)} color="primary" variant="outlined">
            Clone
          </Button>
        </Grid>

        {deleteOrRemoveButton && <Grid item>{deleteOrRemoveButton}</Grid>}
        {campaign.assigned && (
          <Grid item marginLeft="auto" paddingRight={4}>
            <Chip color="primary" icon={<ShareIcon />} label="Assigned" />
          </Grid>
        )}
        <CampaignDialog
          campaign={campaign}
          dialogOpen={openCloneDialog}
          setDialogOpen={setOpenCloneDialog}
          requestStatus={cloneSuccess}
          onComplete={() => {
            closeModals()
            setOpenCloneDialog(false)
          }}
          dialogAction="clone"
          onSubmit={() => cloneCampaigns({ id: campaign.id })}
        />
        <CampaignDialog
          campaign={campaign}
          dialogOpen={openDeleteDialog}
          setDialogOpen={setOpenDeleteDialog}
          requestStatus={deleteSuccess}
          onComplete={() => {
            closeModals()
            setOpenDeleteDialog(false)
          }}
          dialogAction="delete"
          onSubmit={() => deleteCampaign({ id: campaign.id })}
        />
        <CampaignDialog
          campaign={campaign}
          dialogOpen={openUnassignDialog}
          setDialogOpen={setOpenUnassignDialog}
          requestStatus={unassignSuccess}
          onComplete={() => {
            closeModals()
            setOpenUnassignDialog(false)
          }}
          dialogAction="unassign"
          onSubmit={() => unassignCampaigns({ userIds: [currentUser.id.toString()], campaignIds: [campaign?.id] })}
        />
      </>
    )
  }
}
