import { Link, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Button, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useUpdateOrgsFromCsv } from "../../../api/organizations"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"
import { useToast } from "../../shared/ToastHook"

export const BatchUpdateDialog: FC<{
  modalKey: string
  handleClose?: () => void
}> = ({ modalKey, handleClose }) => {
  const isOpen = useModalKey(modalKey)
  const closeDialog = useCloseModalKey(modalKey)
  const {
    mutate: updateOrgs,
    error: error,
    isError: isError,
    reset: resetCsvUpload,
  } = useUpdateOrgsFromCsv(() => onSuccess())
  const [csvFile, setCsvFile] = useState<File | undefined>()

  const onSuccess = () => {
    onClose()
    setToast("Success", 5000)
  }

  const onClose = () => {
    if (handleClose) {
      handleClose()
    }
    setCsvFile(undefined)
    resetCsvUpload()
    closeDialog()
  }

  const setToast = useToast()
  const errorToString = (error: any) => {
    return error?.response?.data?.message
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Update Channels via CSV</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Button variant="contained" component="label" size="small">
            Choose CSV
            <input
              onChange={(event: any) => {
                setCsvFile(event.target.files[0])
              }}
              type="file"
              hidden
            />
          </Button>
          {isError && (
            <Typography variant="h6" color="red" sx={{ alignSelf: "center" }}>
              {errorToString(error)}
            </Typography>
          )}
          {csvFile && <Typography sx={{ alignSelf: "center" }}>{csvFile?.name}</Typography>}
          <Link
            component="button"
            onClick={() => {
              const link = document.createElement("a")
              link.download = "download.txt"
              link.href = "https://storage.googleapis.com/cloverleaf-ai-public-assets/channel-update-template.csv"
              link.click()
            }}
          >
            Download CSV Template
          </Link>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={csvFile === undefined} onClick={csvFile && (() => updateOrgs({ file: csvFile }))}>
          Confirm
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
