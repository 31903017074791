import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { SObject, SObjectChild, SObjectField, SObjectInstance } from "../../interfaces/salesforce"

const prefix = "/user_organizations"

export const getMainSalesforceObject = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "mainSalesforceObject"], async () => {
    return (await (await axiosInstance).get<SObject>(`${prefix}/main_salesforce_object`)).data
  })
}

export const useSetMainSalesforceObject = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { sobject: unknown }) => {
      return (await (await axiosInstance).post(`${prefix}/main_salesforce_object`, params)).data
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["user_organizations", "mainSalesforceObject"])
      },
    },
  )
}

// this is the list of main sObject instances (e.g. Account instances)
export const getMainSalesforceObjectList = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "mainSalesforceObject", "list"], async () => {
    return (await (await axiosInstance).get<SObjectInstance[]>(`${prefix}/main_salesforce_object/list`)).data
  })
}

// this is the main sObject's possible children (e.g. Event)
export const getMainSalesforceObjectChildren = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "mainSalesforceObject", "children"], async () => {
    return (await (await axiosInstance).get<SObjectChild[]>(`${prefix}/main_salesforce_object/children`)).data
  })
}

// this gets what has been set as the attachment (e.g. Event)
export const getMainSalesforceObjectAttachment = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "mainSalesforceObject", "attachment"], async () => {
    return (await (await axiosInstance).get<SObject & SObjectChild>(`${prefix}/main_salesforce_object/attachment`)).data
  })
}

export const useSetMainSalesforceObjectAttachment = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { sobject: unknown }) => {
      return (await (await axiosInstance).post(`${prefix}/main_salesforce_object/attachment`, params)).data
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["user_organizations", "mainSalesforceObject", "attachment"])
      },
    },
  )
}

export const getMainSalesforceObjectAttachmentFields = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "mainSalesforceObject", "attachment", "fields"], async () => {
    return (await (await axiosInstance).get<SObjectField[]>(`${prefix}/main_salesforce_object/attachment_fields`)).data
  })
}

export const getMainSalesforceObjectAttachmentField = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_organizations", "mainSalesforceObject", "attachment", "field"], async () => {
    return (await (await axiosInstance).get<string>(`${prefix}/main_salesforce_object/attachment_field`)).data
  })
}

export const useSetMainSalesforceObjectAttachmentField = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { field: string }) => {
      return (await (await axiosInstance).post(`${prefix}/main_salesforce_object/attachment_field`, params)).data
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
        queryClient.invalidateQueries(["user_organizations", "mainSalesforceObject", "attachment", "field"])
      },
    },
  )
}
