import { UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "../axiosInstance"
import { FetchedBookmarksItem } from "../bookmarks"
import { useIsSuperAdmin } from "../users"

const prefix = "/admin"

export const useCreateIndustryBookmarkFolder = (
  onSuccess?: (msg: string) => void,
  onError?: (msg: string) => void,
  onSettled?: () => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: { industryId: number; title: string; visible: boolean }) => {
      return (await axiosInstance).post(`${prefix}/industries/${params.industryId}/bookmarks`, {
        title: params.title,
        visible: params.visible,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("industries")
        if (onSuccess) {
          onSuccess("Folder Created Successfully")
        }
      },
      onError: () => {
        if (onError) {
          onError("Error Creating Folder")
        }
      },
      onSettled: () => {
        if (onSettled) {
          onSettled()
        }
      },
    },
  )
}

export const useFetchIndustryBookmarks = () => {
  const axiosInstance = useAuthAxios()

  const { data: isSuperAdmin, isLoading: isSuperAdminLoading } = useIsSuperAdmin()

  const queryResult: UseQueryResult<FetchedBookmarksItem[], unknown> = useQuery(
    ["industry_bookmarks"],
    async () => {
      const response = await (
        await axiosInstance
      ).get<FetchedBookmarksItem[]>(`${prefix}/bookmarks?bookmark_type=industry`)
      return response.data
    },
    {
      enabled: !isSuperAdminLoading && Boolean(isSuperAdmin),
      notifyOnChangeProps: "tracked",
    },
  )
  // If the user is not a Super Admin, return a default value
  if (!isSuperAdminLoading && !isSuperAdmin) {
    return {
      isLoading: false,
      data: [] as FetchedBookmarksItem[],
      error: null,
      isError: false,
      isSuccess: true,
      status: "success",
    }
  }
  return queryResult
}

export const useUpdateIndustryBookmarkFolder = (
  onSuccess?: (msg: string) => void,
  onError?: (msg: string) => void,
  onSettled?: () => void,
) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: {
      industryId?: number
      bookmarkFolderId: number
      title?: string
      visible?: boolean
      bookmark_type?: string
    }) => {
      const payload: Record<string, unknown> = {}
      if (params.bookmark_type !== undefined) {
        payload.bookmark_type = params.bookmark_type
        payload.industry_id = params.industryId
      } else {
        if (params.title !== undefined) {
          payload.title = params.title
        }
        if (params.visible !== undefined) {
          payload.visible = params.visible
        }
      }

      return (await axiosInstance).put(`${prefix}/bookmarks/${[params.bookmarkFolderId]}`, payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("industries")
        queryClient.invalidateQueries("shared_bookmarks")
        queryClient.invalidateQueries("industry_bookmarks")
        queryClient.invalidateQueries("bookmarks")
        if (onSuccess) {
          onSuccess("Folder Updated Successfully")
        }
      },
      onError: () => {
        if (onError) {
          onError("Error Updating Folder")
        }
      },
      onSettled: () => {
        if (onSettled) {
          onSettled()
        }
      },
    },
  )
}
