import { FC, useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material"
import { useUpdateCreditLimit, useUpdateUsersNotifications } from "@api/users"
import { SimpleUser } from "@interfaces/user"
import { useToast } from "../ToastHook"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import { useUpdateUser } from "@src/api/admin/users"

export const UsersSetttingsDialog: FC<{
  user: SimpleUser | undefined
  open: boolean
  onClose: () => void
  showCreditLimitUpdate?: boolean
  adminView?: boolean
}> = ({ user, open, onClose, showCreditLimitUpdate = false, adminView = false }) => {
  const setToast = useToast()
  const { mutate: updateUser } = useUpdateUsersNotifications()
  const { mutate: updateCreditLimit, isSuccess, isError } = useUpdateCreditLimit()
  const { mutate: adminUpdateUser, isSuccess: adminIsSuccess, isError: adminIsError } = useUpdateUser()
  const [signalsChecked, setSignalsChecked] = useState(user ? user.requires_signals_notifications : false)
  const [accountSummaryChecked, setAccountSummaryChecked] = useState(
    user ? user.requires_account_summary_notifications : false,
  )
  const [creditLimit, setCreditLimit] = useState(user ? user.credit_limit : 0)

  const signalsChanged = (checked: boolean) => {
    setSignalsChecked(checked)
    if (user) {
      updateUser({
        ids: [user.id],
        requires_signals_notifications: checked,
      })
    }
  }

  const accountSummaryChanged = (checked: boolean) => {
    setAccountSummaryChecked(checked)
    if (user) {
      updateUser({
        ids: [user.id],
        requires_account_summary_notifications: checked,
      })
    }
  }

  const onCreditLimitChange = (creditLimit: number) => {
    setCreditLimit(creditLimit)
    if (user) {
      if (adminView) {
        adminUpdateUser({
          ids: [user.id],
          credit_limit: creditLimit,
        })
      } else {
        updateCreditLimit({
          ids: [user.id],
          credit_limit: creditLimit,
        })
      }
    }
  }

  useEffect(() => {
    if (isSuccess || adminIsSuccess) {
      setToast("Credit Limit Updated Successfully", 3000, <CheckCircleIcon />)
    }
    if (isError || adminIsError) {
      setToast("Failed to update credit limit", 5000, <ErrorIcon />, "error")
    }
  }, [isSuccess, isError, adminIsSuccess, adminIsError])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Change Your Notifications"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container direction="row" rowSpacing={1} alignItems="center">
            <Grid item xs={8}>
              Signals Emails
            </Grid>
            <Grid item xs={2}>
              <Switch checked={signalsChecked} onChange={() => signalsChanged(!signalsChecked)} />
            </Grid>
            <Grid item xs={8}>
              Account Summary Emails
            </Grid>
            <Grid item xs={2}>
              <Switch checked={accountSummaryChecked} onChange={() => accountSummaryChanged(!accountSummaryChecked)} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      {showCreditLimitUpdate && (
        <>
          <DialogTitle>{"Update Credit Limit"}</DialogTitle>
          <DialogContent>
            <Stack direction="row" gap={2} marginTop={1}>
              <TextField
                label="Credit Limit"
                type="number"
                inputProps={{ min: 0 }}
                value={creditLimit}
                onChange={(event) => setCreditLimit(parseFloat(event.target.value))}
              />
              <Button onClick={() => onCreditLimitChange(creditLimit)} variant="contained">
                Update
              </Button>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
