import { FC, useMemo } from "react"
import { CardContent, Grid, CardHeader, Chip, Typography, Skeleton, Stack, Paper } from "@mui/material"
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { DisplayWidgetActions } from "./DisplayWidgetActions"
import { Configuration } from "../../../interfaces/dashboard"
import { dateRangeLabel } from "../../../functions/dashboard"
import { TooltipProps } from "recharts/types/component/Tooltip"
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent"
import { defaultLocationFilterParams } from "../../shared/config"
import { useHitsOverTime } from "../../../api/search"
import { SearchQueryParams } from "../../../interfaces/search"

const millisecondsPerDay = 1000 * 60 * 60 * 24

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  if (active) {
    return (
      <Paper sx={{ padding: 2 }} elevation={4}>
        <Typography variant="body2">{label}</Typography>
        <Stack>
          {payload?.map((data) => (
            <Typography variant="body2" key={data?.dataKey} sx={{ color: data?.stroke }}>
              {`${data?.dataKey}: ${data?.value}`}
            </Typography>
          ))}
        </Stack>
        <Typography variant="body2">Click Dot to View Results</Typography>
      </Paper>
    )
  }

  return null
}

export const KeywordsOverTime: FC<{
  configuration: Configuration
  openAction?: (actionType: string) => void
}> = ({ configuration, openAction }) => {
  const queryParams = new URLSearchParams()
  let timeStartInt = 0
  let timeEndInt = 0

  if (configuration.day_range === "custom") {
    if (configuration.date_range) {
      const { start, end } = configuration.date_range
      if (typeof start === "object") {
        timeStartInt = start.valueOf()
        queryParams.append("date-range", `${start.valueOf()}`)
      } else if (typeof start === "number") {
        timeStartInt = start
        queryParams.append("date-range", `${start}`)
      } else if (typeof start === "string") {
        timeStartInt = new Date(start).valueOf()
        queryParams.append("date-range", `${new Date(start).valueOf()}`)
      }

      if (typeof end === "object") {
        timeEndInt = end.valueOf()
        queryParams.append(SearchQueryParams.DateRange, `${end.valueOf()}`)
      } else if (typeof end === "number") {
        timeEndInt = end
        queryParams.append(SearchQueryParams.DateRange, `${end}`)
      } else if (typeof end === "string") {
        timeEndInt = new Date(end).valueOf()
        queryParams.append(SearchQueryParams.DateRange, `${new Date(end).valueOf()}`)
      }
    }
  } else if (typeof configuration.day_range === "number") {
    timeStartInt = (Math.ceil(Date.now() / millisecondsPerDay) - configuration.day_range) * millisecondsPerDay
    queryParams.append(SearchQueryParams.DateRange, timeStartInt.toString())
    timeEndInt = Math.ceil(Date.now() / millisecondsPerDay) * millisecondsPerDay
    queryParams.append(SearchQueryParams.DateRange, timeEndInt.toString())
  }
  queryParams.set(
    SearchQueryParams.FilterParams,
    btoa(JSON.stringify(configuration.filter_params || defaultLocationFilterParams)),
  )

  const { search_terms } = configuration
  const queryResults = useHitsOverTime(
    search_terms || [],
    queryParams,
    configuration.x_axis_unit as "day" | "week" | "month",
  )
  const isLoading = queryResults.some(({ isLoading }) => isLoading)
  const dataMap = new Map<number, Map<string, number>>()

  const chartData: Record<string, string | number>[] = []
  if (search_terms) {
    queryResults.forEach(({ data }, index) => {
      if (data) {
        data.forEach(({ time, hits }) => {
          if (!dataMap.has(time)) {
            dataMap.set(time, new Map<string, number>())
          }
          dataMap.get(time)?.set(search_terms[index], hits)
        })
      }
    })

    const dayStart = Math.ceil(timeStartInt / millisecondsPerDay)
    for (let i = dayStart; i < Math.ceil(timeEndInt / millisecondsPerDay); i += 1) {
      const date = new Date(i * millisecondsPerDay)
      const newData: Record<string, string | number> = {
        date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      }
      search_terms.forEach((term) => (newData[term] = 0))
      const hitData = dataMap.get(i * millisecondsPerDay)
      if (hitData) {
        Array.from(hitData).forEach(([term, hits]) => {
          newData[term] = hits
        })
      }
      chartData.push(newData)
    }
  }

  const displayDateRange = useMemo(() => dateRangeLabel(configuration), [configuration])
  const colors = ["#3A47BB", "#027D60", "#BC0706", "#1B242D", "#BA0EB3"]

  const goToSearch = (dateString: string, term: string) => {
    const date = new Date(dateString)
    const urlSearchParams = new URLSearchParams()
    urlSearchParams.set(SearchQueryParams.FilterParams, btoa(JSON.stringify(configuration.filter_params)))
    urlSearchParams.set(SearchQueryParams.SearchTerms, term)
    urlSearchParams.delete(SearchQueryParams.DateRange)
    urlSearchParams.append(SearchQueryParams.DateRange, date.valueOf().toString())
    urlSearchParams.append(SearchQueryParams.DateRange, (date.valueOf() + millisecondsPerDay).toString())
    window.open(`/?${urlSearchParams}`)
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <CardHeader title={configuration.name} subheader="Keywords Over Time" />
        </Grid>
        <Grid item>
          <Chip color="primary" label={displayDateRange} sx={{ position: "relative" }} />
          {openAction && <DisplayWidgetActions openAction={openAction} />}
        </Grid>
      </Grid>
      <CardContent>
        <Grid container direction="row" justifyContent="center">
          {isLoading ? (
            <Skeleton width="100%" height={200} variant="rectangular" />
          ) : chartData.length ? (
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                {configuration?.search_terms &&
                  configuration?.search_terms.map((term, index) => (
                    <Line
                      key={term}
                      activeDot={{
                        r: 6,
                        onClick: (_event, dot: any) => goToSearch(dot.payload.date, term),
                      }}
                      type="monotone"
                      dataKey={term}
                      stroke={colors[index]}
                    />
                  ))}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="h6">No Data Found</Typography>
          )}
        </Grid>
      </CardContent>
    </>
  )
}
