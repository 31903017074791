import { FC, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useCreateRole, useUpdateRole } from "../../../api/roles"

export const EditRoleModal: FC<{
  open: boolean
  onClose: any
  selectedRole: any
  onActionComplete: any
  formType: any
}> = ({ open, onClose, selectedRole, onActionComplete, formType }) => {
  const [nameInput, setNameInput] = useState(selectedRole?.name)
  const { mutate: createRole } = useCreateRole()
  const { mutate: updateRole } = useUpdateRole()

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{formType} Role</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={10} padding={1}>
            <TextField
              value={nameInput}
              onChange={(event: any) => setNameInput(event.target.value)}
              id="role-name-input"
              label="Name"
              fullWidth
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              formType === "New"
                ? createRole({ name: nameInput })
                : updateRole({
                    id: selectedRole.id,
                    name: nameInput,
                  })
              onClose()
              onActionComplete()
            }}
          >
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
