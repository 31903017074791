import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { NaicsCode } from "@interfaces/naicsCode"
import { buildNaicsTree } from "@src/functions/naicsCode"

const prefix = "/naics_codes"

export const useFetchNaicsCodes = () => {
  const axiosInstance = useAuthAxios()
  return useQuery(["naicsCodes"], async () => {
    const response = await (await axiosInstance).get<NaicsCode[]>(prefix)

    return response.data
  })
}

export const useFetchNaicsCodesMap = () => {
  const fetchData = useFetchNaicsCodes()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((naicsCode: NaicsCode) => [naicsCode.id, naicsCode])),
    }
  }
  return fetchData
}

export const useFetchNaicsCodesTree = () => {
  const fetchData = useFetchNaicsCodes()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: buildNaicsTree(fetchData.data),
    }
  }
  return fetchData
}

export const useFetchNaicsCodesMapByCode = () => {
  const fetchData = useFetchNaicsCodes()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((naicsCode: NaicsCode) => [naicsCode.code, naicsCode])),
    }
  }
  return fetchData
}
