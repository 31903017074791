import { useMutation } from "react-query"
import { useAuthAxios } from "./axiosInstance"

export const useCreateWordFromCsv: any = () => {
  const axiosInstance = useAuthAxios()
  return useMutation(async (params: any) => {
    const formData = new FormData()

    // Update the formData object
    formData.append("words_file", params.file)

    return (await axiosInstance).post("/admin/most_used_words/create_from_csv", formData)
  })
}
