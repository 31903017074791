import { Stack, Chip, Dialog, DialogTitle, DialogContent, Typography, Grid } from "@mui/material"
import { FC } from "react"
import { useIsAdmin } from "../../api/users"
import { Campaign } from "../../interfaces/campaign"

export const DetailsModal: FC<{
  campaign?: Campaign
  open: boolean
  handleClose: () => void
}> = ({ campaign, open, handleClose }) => {
  const { data: isAdmin } = useIsAdmin()

  const getTerms = (terms: string[]) => (
    <Stack direction="row" sx={{ flexWrap: "wrap" }}>
      {terms?.map((term) => (
        <Chip
          key={`${term}-${Date.now()}`}
          label={term}
          color="primary"
          sx={{ marginBottom: 1, marginRight: 1 }}
          variant="outlined"
        />
      ))}
    </Stack>
  )

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {campaign && (
        <>
          <DialogTitle sx={{ textAlign: "center" }}>{campaign.name}</DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" paddingBottom={1} fontWeight={200}>
                  Search Terms
                </Typography>
                {getTerms(campaign?.data?.searchTerms)}
              </Grid>
              {campaign?.data?.mustIncludeTerms && (
                <Grid item xs={12}>
                  <Typography variant="body1" paddingBottom={1} fontWeight={200}>
                    Advanced Search
                  </Typography>
                  {getTerms(campaign?.data?.mustIncludeTerms)}
                </Grid>
              )}
              {isAdmin && (
                <Grid item xs={12}>
                  <Typography variant="body1" paddingBottom={1} fontWeight={200}>
                    Assignees
                  </Typography>
                  {campaign.assignees.length > 0 ? (
                    campaign.assignees.map((user) => (
                      <Typography key={user.email} variant="body2" sx={{ paddingLeft: 1 }}>
                        {user.email}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2" sx={{ paddingLeft: 1 }}>
                      {" "}
                      None{" "}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
