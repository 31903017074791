import { FetchedTranscriptionJob, Progress } from "../../../../../api/admin/organizations"
import { Column } from "../../../../shared/DataTable"
import { formatDate, formatBool, formatPercent, formatDatetime } from "../../../../shared/config"

export const formatSeconds = (seconds?: number) => {
  if (seconds === undefined) {
    return null
  }
  return new Date(seconds * 1000).toISOString().substr(11, 8)
}

const formatIsBool = (transcriptionJob?: FetchedTranscriptionJob) => {
  return transcriptionJob?.in_progress
    ? "Yes"
    : !transcriptionJob?.scraped_at && transcriptionJob?.started_at
      ? "Failed"
      : "No"
}
const getTranscriptionJobKey = (transcriptionJob: FetchedTranscriptionJob, index: number) =>
  `${transcriptionJob?.id}${index}`
const formatStartedAt = (transcriptionJob?: FetchedTranscriptionJob) => formatStage(transcriptionJob?.started_at)
const formatScrapedAt = (transcriptionJob?: FetchedTranscriptionJob) =>
  formatStage(transcriptionJob?.scraped_at, "download", transcriptionJob?.progress)
const formatTranscodedAt = (transcriptionJob?: FetchedTranscriptionJob) => formatStage(transcriptionJob?.transcoded_at)
const formatDiarizedAt = (transcriptionJob?: FetchedTranscriptionJob) =>
  formatStage(transcriptionJob?.diarized_at, "betty", transcriptionJob?.progress)
const formatTranscribedAt = (transcriptionJob?: FetchedTranscriptionJob) =>
  formatStage(transcriptionJob?.transcribed_at, "veronika", transcriptionJob?.progress)
const formatIndexedAt = (transcriptionJob?: FetchedTranscriptionJob) => formatStage(transcriptionJob?.indexed_at)
const formatFinishedAt = (transcriptionJob?: FetchedTranscriptionJob) => formatStage(transcriptionJob?.finished_at)
const formatTranscriptionJobId = (transcriptionJob?: FetchedTranscriptionJob) => transcriptionJob?.id

export const formatStage = (finishedDate?: string, stage?: string, progress?: Progress) => {
  if (finishedDate) {
    return formatDatetime(finishedDate).toString()
  }
  if (progress && stage && progress.service === stage) {
    if (progress.service === "betty") {
      const part = progress.stage === "embeddings" ? "2" : "1"
      return `Part ${part} of 2 - ${progress.progress}%`
    }
    return `${progress.progress}%`
  }
  return ""
}

export const meetingViewColumns: readonly Column[] = [
  {
    id: "id",
    label: "Id",
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "transcription_job",
    label: "TJ Id",
    formatter: formatTranscriptionJobId,
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "title",
    label: "Title",
    headerCellProps: { sx: { minWidth: "300px", backgroundColor: "neutral.main", zIndex: 1 } },
    link: (data) => `/meetings/${data?.id}`,
  },
  {
    id: "source_video_url",
    label: "Download Link",
    headerCellProps: { sx: { minWidth: "300px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "duration_seconds",
    label: "Duration",
    formatter: formatSeconds,
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "published_at",
    label: "Published",
    formatter: formatDate,
    headerCellProps: { sx: { maxWidth: 135, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "is_spam",
    label: "Spam?",
    formatter: formatBool,
    headerCellProps: { sx: { minWidth: 100, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "spam_certainty",
    label: "Spam Certainty",
    formatter: formatPercent,
    headerCellProps: { sx: { minWidth: 50, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "spam_reasoning",
    label: "Spam Reasoning",
    headerCellProps: { sx: { minWidth: 200, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "opportunities",
    label: "Opportunities",
    headerCellProps: { sx: { minWidth: 50, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "created_at",
    label: "Created At",
    formatter: formatDatetime,
  },
  {
    id: "updated_at",
    label: "Updated At",
    formatter: formatDatetime,
  },
]

export const transcriptionJobViewColumns: readonly Column[] = [
  {
    id: "id",
    label: "Id",
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "transcription_job",
    label: "TJ Id",
    formatter: formatTranscriptionJobId,
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "title",
    label: "Title",
    headerCellProps: { sx: { minWidth: "300px", backgroundColor: "neutral.main", zIndex: 1 } },
    link: (data) => `/meetings/${data?.id}`,
  },
  {
    id: "source_video_url",
    label: "Download Link",
    headerCellProps: { sx: { minWidth: "300px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "duration_seconds",
    label: "Duration",
    formatter: formatSeconds,
    headerCellProps: { sx: { minWidth: "100px", backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "published_at",
    label: "Published",
    formatter: formatDate,
    headerCellProps: { sx: { maxWidth: 135, backgroundColor: "neutral.main", zIndex: 1 } },
  },
  {
    id: "created_at",
    label: "Created At",
    formatter: formatDatetime,
  },
  {
    id: "transcription_job",
    label: "Download In Progress?",
    formatter: formatIsBool,
    headerCellProps: { sx: { minWidth: 50, backgroundColor: "neutral.main", zIndex: 1 } },
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "in_progress"],
  },
  {
    id: "transcription_job",
    label: "Started At",
    formatter: formatStartedAt,
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "started_at"],
  },
  {
    id: "transcription_job",
    label: "Scraped At",
    formatter: formatScrapedAt,
    headerCellProps: { sx: { minWidth: 90, backgroundColor: "neutral.main", zIndex: 1 } },
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "scraped_at"],
  },
  {
    id: "transcription_job",
    label: "Transcoded At",
    formatter: formatTranscodedAt,
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "transcoded_at"],
  },
  {
    id: "transcription_job",
    label: "Diarized At",
    formatter: formatDiarizedAt,
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "diarized_at"],
  },
  {
    id: "transcription_job",
    label: "Transcribed At",
    formatter: formatTranscribedAt,
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "transcribed_at"],
  },
  {
    id: "transcription_job",
    label: "Indexed At",
    formatter: formatIndexedAt,
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "indexed_at"],
  },
  {
    id: "transcription_job",
    label: "Finished At",
    formatter: formatFinishedAt,
    key: getTranscriptionJobKey,
    sortProp: ["transcription_job", "finished_at"],
  },
]
