import { FC } from "react"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { BriefingPDF } from "./BriefingPDF"

export const BriefingContent: FC<{ promptResult: string }> = ({ promptResult }) => {
  if (promptResult) {
    const briefingElement = (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(marked.parse(promptResult) as string),
        }}
      />
    )
    return (
      <>
        <BriefingPDF briefingElement={briefingElement} />
        {briefingElement}
      </>
    )
  }
  return null
}
