import { FC } from "react"
import { Link, useParams } from "react-router-dom"
import { Card, CardContent, Box, Skeleton, Typography, Chip, Stack, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { TranscriptListSearch } from "../TranscriptsTab/TranscriptList/TranscriptListSearch"
import { publishedAtTextUtc, locationText } from "../../shared/MeetingBasicInfo"
import { VideoPlayer } from "./VideoPlayer"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { useFetchMeeting } from "../../../api/meetings"

export const VideoCard: FC = () => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data, isLoading } = useFetchMeeting(meetingId)

  const theme = useTheme()
  const showVideo = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Card>
      <CardContent>
        <TranscriptListSearch sx={{ marginBottom: 2 }} />
        {showVideo && <VideoPlayer width="100%" />}
        <Stack direction="row" justifyContent="space-between">
          <Box width="100%">
            <Typography variant="h6">{isLoading ? <Skeleton /> : `${data?.meeting?.title}`}</Typography>
            <Typography variant="body2">
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  {data && publishedAtTextUtc(data.meeting.published_at)}
                  <Link
                    to={`/channel-details/${data?.meeting?.organization_id}`}
                    style={{
                      fontWeight: 700,
                      color: "#566F88",
                      textDecorationColor: "#566F88",
                      textDecoration: "underline",
                    }}
                  >
                    {data?.meeting?.organization_name}
                  </Link>
                  {data && (
                    <>
                      {" in "}
                      <strong>
                        {locationText(data.meeting as unknown as { city: string; county: string; state: string })}
                      </strong>
                    </>
                  )}
                </>
              )}
            </Typography>
          </Box>
          {data?.meeting?.has_viewed && (
            <Chip
              color="primary"
              label="VIEWED"
              size="medium"
              variant="outlined"
              sx={{ backgroundColor: "white", marginTop: "3px !important", fontWeight: 600 }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
