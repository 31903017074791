import { FC, ReactElement } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"

export const ActionWidgetDialog: FC<{
  isOpen: boolean
  dialogContent: ReactElement | undefined
  onClick: () => void
  onClose: () => void
  submitIsDisabled: boolean
  title: string
}> = ({ isOpen, dialogContent, onClick, onClose, submitIsDisabled, title }) => {
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="xl">
      <DialogTitle sx={{ backgroundColor: "neutral.light" }}> {title} </DialogTitle>
      <DialogContent sx={{ backgroundColor: "neutral.light" }}>{dialogContent}</DialogContent>
      <DialogActions sx={{ backgroundColor: "neutral.light" }}>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" disabled={submitIsDisabled} onClick={onClick}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
