import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"

export const setSubscriptionOverride: any = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`user_campaign_notifications/${params.ids.join(",")}`, {
        email_cadence: params.emailCadence,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my_subscriptions")
        queryClient.invalidateQueries("my_campaigns")
      },
    },
  )
}

export const useFetchUserSubscriptions: any = (userId: any) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["user_subscriptions", userId], async () => {
    const response = await (await axiosInstance).get(`/admin/user_campaign_notifications?user_id=${userId}`)

    return response.data
  })
}
