import { useMutation, useQuery, useQueryClient } from "react-query"
import { ChannelType } from "../interfaces/channelType"
import { useSmartUpdate } from "./api"
import { useAuthAxios } from "./axiosInstance"

export const useFetchChannelTypes = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["channel_types"], async () => {
    const response = await (await axiosInstance).get<ChannelType[]>("/channel_types")

    return response.data.sort((a, b) => (a.name > b.name ? 1 : -1))
  })
}

export const useFetchChannelTypesMap = () => {
  const fetchData = useFetchChannelTypes()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: new Map(fetchData.data.map((channel_type) => [channel_type.id, channel_type])),
    }
  }
  return fetchData
}

export const useDeleteChannelTypes = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const smartUpdate = useSmartUpdate(queryClient, true)

  return useMutation(
    async (ids: number[]) => {
      return (await axiosInstance).delete(`/admin/channel_types/${ids.join(",")}`)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        queryClient.refetchQueries("organizations")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useUpdateChannelType = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const smartUpdate = useSmartUpdate(queryClient)

  return useMutation(
    async (params: { id: number; name: string }) => {
      return (await axiosInstance).put<ChannelType>(`/admin/channel_types/${params.id}`, params)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        queryClient.refetchQueries("organizations")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}

export const useCreateChannelType = (onSuccess?: () => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()
  const smartUpdate = useSmartUpdate(queryClient)

  return useMutation(
    async (params: { name: string }) => {
      return (await axiosInstance).post<ChannelType>("/admin/channel_types/", params)
    },
    {
      onSuccess: (response) => {
        smartUpdate(response)
        queryClient.refetchQueries("organizations")
        if (onSuccess) {
          onSuccess()
        }
      },
    },
  )
}
