import { FC, useState } from "react"
import { Typography, Link } from "@mui/material"
import { Layout } from "../components/shared/Layout"
import { NewDashboard } from "../components/Dashboards/NewDashboard"
import { RemoveDashboardDialog } from "../components/Dashboards/RemoveDashboardDialog"
import { useFetchDashboards } from "../api/dashboards"
import { DataTable } from "../components/shared/DataTable"
import { dashboardsColumns } from "../config/dashboardConfig"
import { Link as RouterLink } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

export const DashboardsPage: FC = () => {
  const [showNewDashboardForm, setShowNewDashboardForm] = useState(false)
  const [showRemoveDashboard, setShowRemoveDashboard] = useState(false)
  const [selectedDashboards, setSelectedDashboards] = useState<number[]>([])
  const { data: dashboardsData } = useFetchDashboards()

  const onSubmit = (dashboardId: string) => {
    setShowNewDashboardForm(false)
    window.location.href = `dashboards/${dashboardId}`
  }

  return (
    <Layout
      title="Dashboards"
      actions={[{ action: () => setShowNewDashboardForm(true), text: "new dashboard", icon: <AddIcon /> }]}
    >
      {dashboardsData?.dashboards.length === 0 ? (
        <Typography>
          All of your dashboards will be displayed here. Click the button above to create your first dashboard.
        </Typography>
      ) : (
        <DataTable
          columns={dashboardsColumns}
          data={dashboardsData?.dashboards.map((dashboard) => ({
            ...dashboard,
            name: (
              <Link component={RouterLink} to={`/dashboards/${dashboard.id}`}>
                {dashboard.name}
              </Link>
            ),
            created_by: dashboard.email,
          }))}
          selected={selectedDashboards}
          setSelected={setSelectedDashboards}
          actions={[
            {
              action: () => setShowRemoveDashboard(true),
              text: "remove",
              icon: <RemoveIcon />,
            },
          ]}
        />
      )}
      <NewDashboard open={showNewDashboardForm} onSubmit={onSubmit} onClose={() => setShowNewDashboardForm(false)} />
      <RemoveDashboardDialog
        open={showRemoveDashboard}
        setOpen={setShowRemoveDashboard}
        selectedDashboards={selectedDashboards}
        onComplete={() => setSelectedDashboards([])}
      />
    </Layout>
  )
}
