import { Dispatch, SetStateAction, createContext, FC, useState, useContext } from "react"

const OpenModalContext = createContext<{
  openModalKeys: Set<string>
  setOpenModalKeys: Dispatch<SetStateAction<Set<string>>>
  data?: any
  setData?: (data: any) => void
}>({
  openModalKeys: new Set(),
  setOpenModalKeys: () => {
    /* placeholder for typescript */
  },
  data: {},
  setData: () => {
    /* placeholder for typescript */
  },
})

export const useModalKey = (key: string) => {
  const { openModalKeys } = useContext(OpenModalContext)
  return openModalKeys.has(key)
}

export const useOpenModalKey = (key: string) => {
  const { setOpenModalKeys } = useContext(OpenModalContext)
  return () => {
    setOpenModalKeys((prevSet) => {
      const newSet = new Set(prevSet)
      newSet.add(key)
      return newSet
    })
  }
}

export const useCloseModalKey = (key: string) => {
  const { setOpenModalKeys, data, setData } = useContext(OpenModalContext)
  return () => {
    if (data && setData) {
      setData(undefined)
    }
    setOpenModalKeys((prevSet) => {
      const newSet = new Set(prevSet)
      newSet.delete(key)
      return newSet
    })
  }
}

export const useData = () => {
  const { data } = useContext(OpenModalContext)

  return data
}

export const useSetData = () => {
  const { setData } = useContext(OpenModalContext)
  return (newData: any) => {
    if (setData) {
      setData(newData)
    }
  }
}

export const OpenModalProvider: FC = ({ children }) => {
  const [openModalKeys, setOpenModalKeys] = useState<Set<string>>(new Set())
  const [data, setData] = useState()

  return (
    <OpenModalContext.Provider value={{ openModalKeys, setOpenModalKeys, data, setData }}>
      {children}
    </OpenModalContext.Provider>
  )
}
