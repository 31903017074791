import { Layout } from "@src/components/shared/Layout"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import { urlParamsToBriefingQueryObject } from "@src/api/briefings"
import useSetUrlParamState from "@src/components/shared/UseSetUrlParamState"
import { Box } from "@mui/material"
import { BriefingsAdminDataFilterContainer } from "./BriefingsAdminDataFiltersContainer"
import { BriefingsAdminDataGrid } from "@components/Admin/Briefings/DataGrid/BriefingsAdminDataGrid"
import { BriefingsContainerContext } from "./BriefingsContainer"

export const BriefingsAdminContainer: FC = () => {
  const params = new URLSearchParams(useLocation().search)
  const urlParamState = urlParamsToBriefingQueryObject(params)
  const setUrlParamState = useSetUrlParamState()

  return (
    <Layout title="All Briefings (admin)">
      <BriefingsContainerContext.Provider value={{ params, urlParamState, setUrlParamState }}>
        <BriefingsAdminDataFilterContainer />
        <Box marginTop={2}>
          <BriefingsAdminDataGrid />
        </Box>
      </BriefingsContainerContext.Provider>
    </Layout>
  )
}
