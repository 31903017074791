import { Dispatch, SetStateAction } from "react"

export function unpackSetters<ObjectType extends Record<string, any>>(
  object: ObjectType,
  setObject: Dispatch<SetStateAction<ObjectType>>,
) {
  const setterObject: Record<string, (newValue: unknown) => void> = {}

  Object.keys(object).forEach((key: string) => {
    setterObject[`set${key.charAt(0).toUpperCase() + key.slice(1)}`] = (newValue) => {
      if (typeof newValue === "function") {
        setObject((prevValue) => ({ ...prevValue, [key]: newValue(prevValue[key]) }))
      } else {
        setObject((prevValue) => ({ ...prevValue, [key]: newValue }))
      }
    }
  })

  return setterObject as {
    [Property in keyof ObjectType as `set${Capitalize<string & Property>}`]: Dispatch<
      SetStateAction<ObjectType[Property]>
    >
  }
}
