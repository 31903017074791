import { useAuthAxios } from "./axiosInstance"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Configuration } from "../interfaces/dashboard"

interface PreviewWidgetParams {
  configuration: Configuration
  type: string
}

export const usePreviewWidget = (params: PreviewWidgetParams) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    [
      "reports",
      params.configuration.day_range,
      params.configuration.search_terms,
      params.configuration.filter_params,
      params.configuration.date_range,
      params.configuration.x_axis_unit,
      params.configuration.geography_type,
      params.type,
    ],
    async () => {
      const response = await (
        await axiosInstance
      ).post("/widgets/display_widget", {
        type: params.type,
        configuration: params.configuration,
      })

      return response.data
    },
  )
}

export const useUpdateWidget = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`/widgets/${params.id}`, { configuration: params.configuration })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["widgets"])
      },
    },
  )
}

export const useDeleteWidget = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (widgetId: number) => {
      return (await axiosInstance).delete(`/widgets/${widgetId}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["widgets"])
      },
    },
  )
}
