import { FC, useContext, useState, useEffect } from "react"
import { Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useUpdateOpportunityCampaignsOwnRelation } from "@src/api/opportunityCampaigns"
import { useToast } from "@src/components/shared/ToastHook"
import { OpportunityCampaignCardContext } from "./index"
import { EmailCadence } from "@src/interfaces/campaign"

export const OwnEmailCadence: FC<{
  opportunityCampaignId: unknown
  isEditing: boolean
}> = ({ opportunityCampaignId, isEditing }) => {
  const { ownEmailCadence, setOwnEmailCadenceAction } = useContext(OpportunityCampaignCardContext)

  const [newEmailCadence, setNewEmailCadence] = useState(ownEmailCadence || "Daily")
  const toast = useToast()
  const { mutate: updateOpportunityCampaignsOwnRelation } = useUpdateOpportunityCampaignsOwnRelation(
    opportunityCampaignId,
    {
      onSuccess: () => {
        toast("Campaign Permissions successfully updated")
      },
    },
  )

  useEffect(() => {
    if (ownEmailCadence) {
      setNewEmailCadence(ownEmailCadence)
    }
  }, [ownEmailCadence])

  useEffect(() => {
    if (ownEmailCadence && newEmailCadence !== ownEmailCadence) {
      setOwnEmailCadenceAction(() => () => updateOpportunityCampaignsOwnRelation({ email_cadence: newEmailCadence }))
    } else {
      setOwnEmailCadenceAction(() => () => {})
    }
  }, [ownEmailCadence, newEmailCadence])

  useEffect(() => {
    if (!isEditing) {
      setNewEmailCadence(ownEmailCadence || "Daily")
    }
  }, [isEditing])

  if (!ownEmailCadence) {
    return null
  }

  return (
    <>
      {isEditing ? (
        <FormControl>
          <InputLabel>Email Cadence</InputLabel>
          <Select
            sx={{ minWidth: "20ch" }}
            value={newEmailCadence}
            label="Email Cadence"
            onChange={(event) => {
              setNewEmailCadence(event.target.value as EmailCadence)
            }}
          >
            <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Typography width="12ch">{ownEmailCadence}</Typography>
      )}
    </>
  )
}
