import { FC, useState } from "react"
import { Button, Grid, Menu, MenuItem } from "@mui/material"
import { Table } from "../../shared/Table"
import Loading from "../../shared/Loading"
import { useDeleteChannelTypes, useFetchChannelTypes } from "../../../api/channel_types"
import { createRoleRow, roleColumns } from "../../../config/roleAdminConfig"
import { Confirm } from "../../shared/Confirm"
import { EditChannelTypeDialog, UpdateChannelTypeMenuItem } from "./EditChannelTypeModal"
import { unpackSetters } from "../../shared/unpackSetters"

export const ChannelTypesContainer: FC = () => {
  const channelTypesProps = { selectedTypes: [] as number[] }
  const [channelTypesState, setChannelTypesState] = useState(channelTypesProps)
  const { setSelectedTypes } = unpackSetters(channelTypesState, setChannelTypesState)

  const { data: channelTypesData } = useFetchChannelTypes()

  const { mutate: deleteChannelTypes } = useDeleteChannelTypes()

  const onActionComplete = () => {
    setSelectedTypes([])
  }

  const disableActions = channelTypesState.selectedTypes.length === 0

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openConfirm, setOpenConfirm] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenConfirm(false)
  }

  const onComplete = () => {
    onActionComplete()
    handleClose()
  }

  if (channelTypesData === undefined) {
    return <Loading />
  }

  return (
    <Grid container>
      <Grid item xs={2} alignSelf="flex-end" marginY={2}>
        <Button
          id="actions-menu-button"
          aria-controls="actions-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          size="large"
          variant="outlined"
        >
          Actions
        </Button>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "actions-menu-button" }}
        >
          <UpdateChannelTypeMenuItem selectedTypes={channelTypesState.selectedTypes} formType="CREATE" />
          <UpdateChannelTypeMenuItem selectedTypes={channelTypesState.selectedTypes} formType="UPDATE" />
          <MenuItem onClick={() => setOpenConfirm(true)} disabled={disableActions}>
            Delete Channel Types
          </MenuItem>
        </Menu>
        <Confirm
          onSucceed={() => {
            deleteChannelTypes(channelTypesState.selectedTypes)
            onComplete()
          }}
          onClose={() => setOpenConfirm(false)}
          open={openConfirm}
          title={"Delete Channel Types"}
          body={"Are you sure you want to delete these Channel Types? This action is irreversible."}
        />
        <EditChannelTypeDialog channelTypeState={channelTypesState} setChannelTypeState={setChannelTypesState} />
      </Grid>
      <Grid item xs={12}>
        <Table
          data={channelTypesData}
          selected={channelTypesState.selectedTypes}
          setSelected={setSelectedTypes}
          createRow={createRoleRow}
          columns={roleColumns}
        />
      </Grid>
    </Grid>
  )
}
