import { useState, FC } from "react"
import {
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Dialog,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"
import { ProxyRegionParams, useUpdateProxyRegion } from "../../../api/admin/proxies"
import { ProxyRegion } from "../../../interfaces/proxyRegions"

const updateKey = "UPDATE_PROXY_REGION"

export const EditModal: FC<{ proxyRegion: ProxyRegion; onSuccess: () => void }> = ({ proxyRegion, onSuccess }) => {
  const { mutate: updateProxyRegion } = useUpdateProxyRegion(proxyRegion.id, onSuccess)
  const open = useModalKey(updateKey)
  const closeModalKey = useCloseModalKey(updateKey)
  const [formData, setFormData] = useState<ProxyRegionParams>({
    region: proxyRegion.region,
    abbreviation: proxyRegion.abbreviation,
    youtube_blocked: proxyRegion.youtube_blocked,
    granicus_blocked: proxyRegion.granicus_blocked,
    swagit_blocked: proxyRegion.swagit_blocked,
    active: proxyRegion.active,
  })

  const handleSubmit = () => {
    updateProxyRegion(formData)
    closeModalKey()
  }

  return (
    <Dialog fullWidth open={open} onClose={closeModalKey}>
      <DialogTitle>Update Proxy Region</DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Stack spacing={2}>
          <TextField
            value={formData.abbreviation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, abbreviation: e.target.value })
            }
            label="Abbreviation"
          />
          <TextField
            value={formData.region}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...formData, region: e.target.value })}
            label="Region"
          />
          <FormControl>
            <InputLabel id="active-id">Active</InputLabel>
            <Select
              labelId="active-select-label"
              id="active-select"
              value={formData.active}
              label="Active"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, active: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="youtube-block-id">Youtube Block</InputLabel>
            <Select
              labelId="youtube-block-select-label"
              id="youtube-block-select"
              value={formData.youtube_blocked}
              label="Youtube Block"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, youtube_blocked: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="granicus-block-id">Granicus Block</InputLabel>
            <Select
              labelId="granicus-block-select-label"
              id="granicus-block-select"
              value={formData.granicus_blocked}
              label="Granicus Block"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, granicus_blocked: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="swagit-block-id">Swagit Block</InputLabel>
            <Select
              labelId="swagit-block-select-label"
              id="swagit-block-select"
              value={formData.swagit_blocked}
              label="Swagit Block"
              onChange={(e: SelectChangeEvent<boolean>) =>
                setFormData({ ...formData, swagit_blocked: e.target.value === "true" })
              }
            >
              <MenuItem value={"true"}>True</MenuItem>
              <MenuItem value={"false"}>False</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={formData.abbreviation === "" || formData.region === ""} onClick={handleSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
