import { useQuery } from "react-query"
import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"

const prefix = "/promotions/industries"

export interface FetchIndustryForSearch {
  id: number
  name: string
}

export const useFetchIndustry = (industryId: string) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery(
    ["promo_industry", industryId],
    async () => {
      const response = await (await axiosInstance).get<FetchIndustryForSearch>(`${prefix}/${industryId}`)
      return response.data
    },
    {
      enabled: Boolean(industryId),
    },
  )
}

export const useFetchIndustriesForSearch = () => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery("promo_industries", async () => {
    const response = await (await axiosInstance).get<FetchIndustryForSearch[]>(`${prefix}`)

    return response.data
  })
}
