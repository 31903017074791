import { FC } from "react"
import { useAdminQuery } from "../../../../api/api"
import { SearchLog } from "../../../../interfaces/searchLogs"
import { DataTable } from "../../../shared/DataTable"
import { columns } from "../../../../tableColumns/searchLogColumns"

export const Page: FC<{ userOrgId: number }> = ({ userOrgId }) => {
  const { data: searchLogs } = useAdminQuery<SearchLog[]>([`user_organizations/${userOrgId}/search_logs`])
  return <DataTable data={searchLogs} columns={columns} columnKey="id" height={1000} defaultSort="created_at" />
}
