import { useQuery } from "react-query"
import { SanitizedSearchLog } from "../interfaces/searchLogs"
import { useAuthAxios } from "./axiosInstance"

const prefix = "/search_logs"

export const useFetchSearchLogs = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["search_logs"],
    async () => {
      const response = await (await axiosInstance).get<SanitizedSearchLog[]>(prefix)

      return response.data
    },
    {
      refetchOnMount: "always",
    },
  )
}
