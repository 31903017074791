import { createContext, FC, useMemo, useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import axios, { AxiosInstance } from "axios"

export const getBaseUrl = () => {
  return process.env.REACT_APP_SEARCH_API_URL || "http://localhost:3000"
}

const AxiosInstanceContext = createContext<Promise<AxiosInstance>>(new Promise(() => axios.create()))

export const useAuthAxios = () => {
  return useContext(AxiosInstanceContext)
}

export const AxiosProvider: FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const { getIdTokenClaims } = useAuth0()
  const axiosInstance = useMemo(async () => {
    const token = await getAccessTokenSilently()
    const idToken = await getIdTokenClaims()
    const newAxiosInstance = axios.create({
      baseURL: getBaseUrl(),
    })

    newAxiosInstance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`
      config.headers["X-Request-Id"] = `${self?.crypto?.randomUUID()}--${idToken?.email || "unknown"}`
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json"
      }
      return { ...config }
    })

    newAxiosInstance.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        if (error.response.status === 403) {
          window.location.assign("/")
        } else if (error.response.status === 401) {
          if (window.location.pathname !== "/") {
            window.location.assign("/")
          }
        }
        return Promise.reject(error)
      },
    )

    return newAxiosInstance
  }, [getAccessTokenSilently, getIdTokenClaims])

  return <AxiosInstanceContext.Provider value={axiosInstance}>{children}</AxiosInstanceContext.Provider>
}
