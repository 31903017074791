import { FormControl, InputLabel, Select, MenuItem, TextField, Stack, IconButton } from "@mui/material"
import { Dispatch, FC, SetStateAction, useContext } from "react"
import { unpackSetters } from "../../../shared/unpackSetters"
import { GeneralScraperDetailsContext } from "../OrganizationForm/Context"
import { GeneralScraperDetails } from "../../../../api/interfaces"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"

export const IdentifierActionSelection: FC<{ index: number }> = ({ index }) => {
  const { value, setValue } = useContext(GeneralScraperDetailsContext)
  const { identifier_actions } = value as GeneralScraperDetails
  const { setIdentifier_actions } = unpackSetters(
    value as GeneralScraperDetails,
    setValue as Dispatch<SetStateAction<GeneralScraperDetails>>,
  )

  const updateActions = (actionType: string) => {
    const newValue = [...identifier_actions]
    newValue[index].identifier_action = actionType
    if (newValue[index].identifier_action === "") {
      newValue[index].identifier_action_selection = ""
      newValue[index].identifier_action_value = ""
    }
    setIdentifier_actions(newValue)
  }

  const updateSelection = (selection: string) => {
    const newValue = [...identifier_actions]
    newValue[index].identifier_action_selection = selection
    setIdentifier_actions(newValue)
  }

  const updateValue = (value: string) => {
    const newValue = [...identifier_actions]
    newValue[index].identifier_action_value = value
    setIdentifier_actions(newValue)
  }

  const addAction = () => {
    const newValue = [...identifier_actions]
    newValue.push({
      identifier_action: "",
      identifier_action_selection: "",
      identifier_action_value: "",
    })
    setIdentifier_actions(newValue)
  }

  const action = identifier_actions[index] ? identifier_actions[index].identifier_action : ""
  const selection = identifier_actions[index] ? identifier_actions[index].identifier_action_selection : ""
  // for select
  const newValue = identifier_actions[index] ? identifier_actions[index].identifier_action_value : ""
  return (
    <Stack direction="row" alignItems="baseline" margin={1} spacing={1}>
      <FormControl size="small">
        <InputLabel>Action Type</InputLabel>
        <Select
          value={action}
          label="Action Type"
          sx={{ minWidth: 150 }}
          onChange={(event) => updateActions(event.target.value)}
        >
          <MenuItem value="" key="none">
            None
          </MenuItem>
          <MenuItem value="CLICK_ON" key="click-on">
            Click On
          </MenuItem>
          <MenuItem value="SELECT" key="select">
            Select
          </MenuItem>
        </Select>
      </FormControl>
      {action === "CLICK_ON" && (
        <TextField
          label="Menu Xpath Selector"
          helperText={<>{'//*[@id="meetings-list-tabs"]/li[2]/a'}</>}
          size="small"
          value={selection}
          onChange={(event) => updateSelection(event.target.value)}
          sx={{ flex: 1, width: "20ch !important", ".MuiInputBase-root": { width: "45ch !important" } }}
        />
      )}
      {action === "SELECT" && (
        <Stack direction="row" justifyContent="flex-start" spacing={1}>
          <TextField
            label="Select Element Id"
            size="small"
            value={selection}
            onChange={(event) => updateSelection(event.target.value)}
          />
          <TextField
            label="New Select Value"
            size="small"
            value={newValue}
            onChange={(event) => updateValue(event.target.value)}
          />
        </Stack>
      )}
      <Stack direction="row">
        {index === identifier_actions.length - 1 && (
          <IconButton onClick={addAction} size="small">
            <AddIcon />
          </IconButton>
        )}
        {index > 0 && (
          <IconButton
            onClick={() => setIdentifier_actions((prevValue) => prevValue.filter((_, i) => i !== index))}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  )
}
