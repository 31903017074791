import { FC, useState, useMemo, Dispatch, SetStateAction } from "react"
import {
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  Chip,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
  Autocomplete,
  FormControl,
  Typography,
} from "@mui/material"
import { useFetchCurrentUser, useFetchUsers } from "../../api/users"
import { EmailCadence } from "@src/interfaces/campaign"
import { SimpleUser } from "@src/interfaces/user"

export const AssignDialogMenu: FC<{
  handleClose: any
  availableUsers?: any
  isOpen: boolean
  selectedUsers: any
  setSelectedUsers: any
  emailCadence: EmailCadence
  setEmailCadence: Dispatch<SetStateAction<EmailCadence>>
  onSave: any
  assignees: any
}> = ({
  handleClose,
  availableUsers,
  isOpen,
  selectedUsers,
  setSelectedUsers,
  emailCadence,
  setEmailCadence,
  onSave,
  assignees,
}) => {
  const { data: myUsers } = useFetchUsers()
  const { data: me } = useFetchCurrentUser()
  const [searchValue, setSearchValue] = useState("")
  let userOptions: SimpleUser[] = []
  if (!me?.roles.some((role) => role.name === "Admin") && !me?.roles.some((role) => role.name === "Super Admin")) {
    userOptions = me ? [me] : []
  } else if (availableUsers !== undefined) {
    userOptions = availableUsers
  } else {
    userOptions = myUsers ? myUsers : []
  }
  const userAlreadyAssigned = useMemo(
    () => assignees.some((assignee: any) => selectedUsers?.some((u: any) => u.id === assignee.id)),
    [selectedUsers],
  )

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => {
        setSearchValue("")
        setSelectedUsers([])
        setEmailCadence("Unsubscribed")
        handleClose()
      }}
    >
      <DialogTitle>Assign to Users</DialogTitle>
      <DialogContent>
        <FormControl sx={{ marginTop: 1, paddingBottom: 4 }} fullWidth>
          <Autocomplete
            multiple
            autoComplete
            fullWidth
            id="multiple-users-tags"
            value={selectedUsers}
            onChange={(event: any, newValue: any) => setSelectedUsers(newValue)}
            renderTags={(users: SimpleUser[]) => {
              return users.map((user) => <Chip variant="outlined" label={user.email} key={user.email} />)
            }}
            inputValue={searchValue}
            onInputChange={(event: any, newValue: any) => setSearchValue(newValue)}
            options={userOptions ? userOptions : []}
            getOptionLabel={(user: any) => user.email}
            renderInput={(params) => <TextField {...params} label="Users" />}
          />
        </FormControl>
        <FormControl fullWidth sx={{ paddingBottom: 4 }}>
          <InputLabel id="notification-cadence">Notify By Email</InputLabel>
          <Select
            labelId="notification-cadence"
            id="notification-cadence"
            value={emailCadence}
            label="Notify By Email"
            onChange={(e) => setEmailCadence(e.target.value as EmailCadence)}
          >
            <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
        {userAlreadyAssigned && (
          <Typography sx={{ color: "error.main" }}>
            One or more of the users you selected is already assigned. Click assign below to assign this campaign to the
            rest of the users.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSave}
          size="medium"
          variant="contained"
          color="primary"
          disabled={selectedUsers.length === 0}
        >
          Assign
        </Button>
        <Button onClick={handleClose} size="medium" variant="text">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
