import { FC, useMemo } from "react"
import { Button } from "@mui/material"
import { useParams } from "react-router-dom"
import { useFetchUserOrganization, useFetchUserOrganizationUsers } from "../../../../../api/admin/user_organizations"

export const UserOrganizationExportCsv: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: userOrganization } = useFetchUserOrganization(userOrganizationId)
  const { data: users } = useFetchUserOrganizationUsers(userOrganizationId)
  const name = userOrganization?.name
  const roleUrl = useMemo(() => {
    if (users) {
      let roleCsv = "email,roles,requires notifications"
      users.forEach((user) => {
        roleCsv += `\n${user.email},"${user.roles.map((role) => role.name).join()}",${user.requires_signals_notifications}`
      })
      return window.URL.createObjectURL(new Blob([roleCsv], { type: "text/csv" }))
    }
  }, [users])
  const now = new Date(Date.now())
  const fileName = `${name}-${
    now.getMonth() + 1
  }-${now.getDate()}-${now.getFullYear()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`

  return (
    <Button component="a" download={fileName} href={roleUrl} variant="contained">
      Export CSV
    </Button>
  )
}
