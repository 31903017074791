import { useQuery } from "react-query"
import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"
import { Organization } from "@src/interfaces/organization"

const prefix = "/promotions"

export const useFetchOrganizations = (org_ids: number[]) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery(["organizations", org_ids], async () => {
    const response = await (
      await axiosInstance
    ).get<{ organizations: Organization[] }>(`${prefix}/organizations`, {
      params: { org_ids: org_ids },
    })
    return response.data
  })
}
