import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import { FC, useContext, useState } from "react"
import { BriefingsContainerContext } from "@src/containers/BriefingContainers/BriefingsContainer"

const TEXT_SEARCH_LABEL = "Briefing Text Search"

export const BriefingTextSearchInput: FC = () => {
  const { urlParamState, setUrlParamState } = useContext(BriefingsContainerContext)
  const [searchText, setSearchText] = useState(urlParamState.text)
  const setSearchTextUrlParamState = () => setUrlParamState({ ...urlParamState, text: searchText, page: 0 })

  return (
    <FormControl sx={{ flex: "40ch 1 0" }} variant="outlined">
      <InputLabel>{TEXT_SEARCH_LABEL}</InputLabel>
      <OutlinedInput
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            setSearchTextUrlParamState()
          }
        }}
        label={TEXT_SEARCH_LABEL}
        endAdornment={
          <InputAdornment position="end">
            {searchText && (
              <IconButton
                onClick={() => {
                  setSearchText("")
                  setUrlParamState({ ...urlParamState, text: "", page: 0 })
                }}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            )}
            <IconButton onClick={setSearchTextUrlParamState} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}
