import { FC, useContext } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  MenuItem,
} from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { useFetchUsers, useDeleteUsers } from "../../api/users"
import { UserContext } from "../../pages/UsersPage"
import { useToast } from "../shared/ToastHook"
import { useModalKey, useOpenModalKey, useCloseModalKey } from "../shared/OpenModalHook"

export const deleteUsersModalKey = "DELETE_USERS"

export const DeleteUsersMenuItem: FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const contextData = useContext(UserContext)
  const { ids } = contextData
  const openModalKey = useOpenModalKey(deleteUsersModalKey)
  return (
    <MenuItem
      onClick={() => {
        openModalKey()
        closeMenu()
      }}
      disabled={!ids.length}
    >
      Delete User(s)
    </MenuItem>
  )
}

export const DeleteUsersDialog: FC = () => {
  const contextData = useContext(UserContext)
  const { ids, reset } = contextData
  const open = useModalKey(deleteUsersModalKey)
  const closeModalKey = useCloseModalKey(deleteUsersModalKey)
  const setToast = useToast()
  const { data: users } = useFetchUsers()
  const selectedUsers = users?.filter((user) => ids.includes(user.id))
  const userEmails = selectedUsers?.map((user) => user.email)

  const close = () => {
    closeModalKey()
    reset()
  }

  const { mutate: deleteUsers, isLoading } = useDeleteUsers(() => {
    setToast("Successfully deleted user(s)", 3000, <TaskAltIcon />)
    close()
  })

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle>{`Delete User${ids.length > 1 ? "s" : ""}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to delete ${ids.length > 1 ? "these users" : "this user"}? This action is `}
          <strong>irreversible</strong>.
        </DialogContentText>
        <List>
          {userEmails?.map((email) => (
            <ListItem key={email}>
              <ListItemText>{email}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => deleteUsers(ids)} disabled={isLoading}>
          {isLoading ? "Loading..." : "Confirm"}
        </Button>
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
