import React, { FC } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { Box, Link, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { jsPDF } from "jspdf"
import { publishedAtText, locationText } from "../../shared/MeetingBasicInfo"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { FetchedMeeting, useFetchMeeting } from "../../../api/meetings"
import { getLogoUrl } from "../../../api/userOrganizations"
import font from "../../../Barlow-normal"

export const useCreatePDFAndDownload = (briefingElement: JSX.Element, meeting?: FetchedMeeting) => {
  const { data: logoUrl } = getLogoUrl()
  return () => {
    if (meeting) {
      const doc = new jsPDF({ format: "letter", unit: "px", hotfixes: ["px_scaling"] })
      doc.addFileToVFS("Barlow-normal.ttf", font)
      doc.addFont("Barlow-normal.ttf", "Barlow", "normal")
      doc.setFont("Barlow")
      doc.html(
        renderToStaticMarkup(
          <>
            {logoUrl && (
              <Box display="flex" justifyContent="center">
                <Box component="img" src={logoUrl} maxHeight="100px" />
              </Box>
            )}
            <Typography variant="h6" fontFamily="Barlow">
              {meeting.title}
            </Typography>
            <Typography variant="body2" fontFamily="Barlow">
              {`${publishedAtText(meeting.published_at)}${meeting.organization_name} in ${locationText(meeting as unknown as { city: string; county: string; state: string })}`}
            </Typography>
            <Typography fontFamily="Barlow">{briefingElement}</Typography>
          </>,
        ),
        {
          margin: [90, 100, 90, 100],
          autoPaging: "text",
          windowWidth: 650, // letter paper has width of 850
          width: 650,
          callback: function (doc) {
            doc.save(`${meeting.title} ${meeting.id} briefing.pdf`)
          },
        },
      )
    }
  }
}

export const BriefingPDF: FC<{ briefingElement: JSX.Element }> = ({ briefingElement }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useFetchMeeting(meetingId)
  const createPDFAndDownload = useCreatePDFAndDownload(briefingElement, data?.meeting)
  if (data) {
    return (
      <Link marginBottom={1} sx={{ cursor: "pointer" }} onClick={createPDFAndDownload}>
        Briefing PDF
      </Link>
    )
  } else {
    return null
  }
}
