import { FC } from "react"
import { Link as LinkComponent } from "@mui/material"
import { Link } from "react-router-dom"
import { Person } from "../../../../interfaces/person"

export const PersonText: FC<{ person: Person }> = ({ person }) => {
  const nameList = [person.name]
  if (person.title) {
    nameList.push(person.title)
  }
  if (person.organization) {
    nameList.push(person.organization)
  }
  return (
    <>
      <LinkComponent component={Link} to={`/persons/${person.id}`}>
        {`${nameList.join(", ")}`}
      </LinkComponent>
      <br />
    </>
  )
}
