import { Column, formatDateTime } from "../components/shared/DataTable"
import { formatNumber } from "../components/shared/DataTable/formatters"

export const stateColumns: Column[] = [
  { id: "id", label: "Id", headerCellProps: { sx: { minWidth: "calc(4ch + 32px)" } } },
  {
    id: "name",
    label: "Name",
    headerCellProps: { sx: { minWidth: "calc(18ch + 32px)" } },
    typographyProps: { variant: "body2" },
  },
  {
    id: "created_at",
    label: "Created At",
    formatter: formatDateTime,
    headerCellProps: { sx: { minWidth: "calc(24ch + 32px)" } },
    typographyProps: { variant: "body2" },
  },
  {
    id: "updated_at",
    label: "Updated At",
    formatter: formatDateTime,
    headerCellProps: { sx: { minWidth: "calc(24ch + 32px)" } },
    typographyProps: { variant: "body2" },
  },
]

export const countyColumns: Column[] = [
  ...stateColumns.slice(0, 2),
  {
    id: "state_name",
    label: "State",
    headerCellProps: { sx: { minWidth: "calc(10ch + 32px)" } },
    typographyProps: { variant: "body2" },
  },
  { id: "population", label: "Population", formatter: formatNumber },
  { id: "income_household_median", label: "Household Income", formatter: formatNumber },
  ...stateColumns.slice(2),
]

export const cityColumns: Column[] = [
  ...countyColumns.slice(0, 2),
  {
    id: "county_name",
    label: "County",
    headerCellProps: { sx: { minWidth: "calc(18ch + 32px)" } },
    typographyProps: { variant: "body2" },
  },
  ...countyColumns.slice(2),
]

export const mapDataToColumns = (data: {
  channels: string | any[]
  total_meetings: number | null
  total_minutes: number | null
}) => ({
  ...data,
})
