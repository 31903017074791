import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useDeleteOrphanedMeetingsConfirm, useDeleteOrphanedMeetingsInit } from "../../../api/settings"
import { Confirm } from "../../shared/Confirm"

export const DeleteOrphanedMeetings: FC = () => {
  const [open, setOpen] = useState(false)

  const { mutate: confirmDelete } = useDeleteOrphanedMeetingsConfirm()
  const { data } = useDeleteOrphanedMeetingsInit()

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          onClick={() => {
            setOpen(true)
          }}
        >
          DELETE
        </Button>
      </Grid>
      {data && (
        <Confirm
          open={open}
          onClose={() => setOpen(false)}
          onSucceed={confirmDelete}
          title="Are you sure you want to delete these meetings?"
          renderBody={() =>
            data?.orphaned_meetings?.map((meeting: any) => (
              <Typography variant="body1" sx={{ marginY: 1 }} key={meeting.id}>
                {meeting?.organization?.name}
                {meeting?.id} - {meeting?.title}
              </Typography>
            ))
          }
        />
      )}
    </Grid>
  )
}
