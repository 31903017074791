import { FC, createContext, useState, useContext, useMemo } from "react"
import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material"
import { useFetchFilterList, useFetchMyCampaigns } from "@api/campaigns"
import { CampaignTileBase } from "../shared/CampaignTileBase"
import { useFetchGeographiesMap } from "@api/geographies"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { useFetchChannelTypesMap } from "@api/channel_types"
import { Campaign } from "@interfaces/campaign"
import { Campaign as HomeCampaign } from "./interface"
import { Organization } from "@interfaces/organization"
import { City, County, State } from "@interfaces/geography"
import { ChannelType } from "@interfaces/channelType"
import { Link, useLocation } from "react-router-dom"
import { campaignWizardModalKey } from "../shared/CampaignWizard"
import { useOpenModalKey } from "../shared/OpenModalHook"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import { useFetchRecommendedCampaigns } from "@api/users"

export const FilterContext = createContext<string>("")
export const CampaignMapContext = createContext<{
  organizationsMap?: Map<number, Organization>
  geographiesMap?: {
    cities: Map<number, City>
    counties: Map<number, County>
    states: Map<number, State>
  }
  channelTypesMap?: Map<number, ChannelType>
}>({
  organizationsMap: new Map(),
  geographiesMap: {
    cities: new Map(),
    counties: new Map(),
    states: new Map(),
  },
  channelTypesMap: new Map(),
})

const CampaignCard: FC<{ campaign: Campaign | HomeCampaign }> = ({ campaign }) => {
  const locationSearchParams = useLocation().search
  const { organizationsMap, geographiesMap, channelTypesMap } = useContext(CampaignMapContext)
  const filter = useContext(FilterContext)
  const show = useMemo(
    () =>
      campaign.name.toLowerCase().includes(filter.toLowerCase()) ||
      campaign.data?.searchTerms?.find((term) => term.toLowerCase().includes(filter.toLowerCase())) ||
      (geographiesMap &&
        (campaign.data?.filterParams?.states?.find((stateId) =>
          geographiesMap.states.get(stateId)?.name.toLowerCase().includes(filter.toLowerCase()),
        ) ||
          campaign.data?.filterParams?.counties?.find((countyId) =>
            geographiesMap.counties.get(countyId)?.name.toLowerCase().includes(filter.toLowerCase()),
          ) ||
          campaign.data?.filterParams?.cities?.find((cityId) =>
            geographiesMap.cities.get(cityId)?.name.toLowerCase().includes(filter.toLowerCase()),
          ))),
    [filter],
  )

  const urlSearchParams = new URLSearchParams(locationSearchParams)
  urlSearchParams.delete("search-terms")
  urlSearchParams.delete("must-include-terms")
  urlSearchParams.set("campaign-id", campaign.id.toString())
  if (show) {
    return (
      <CardActionArea
        key={campaign.id}
        sx={{
          marginY: 1,
          "&:hover": {
            textDecoration: "none",
            color: "inherit",
          },
        }}
        component={Link}
        to={`?${urlSearchParams.toString()}`}
      >
        <Card variant="outlined" sx={{ borderRadius: 2 }}>
          <CardContent>
            <CampaignTileBase
              campaign={campaign}
              organizationsMap={organizationsMap}
              geographiesMap={geographiesMap}
              channelTypesMap={channelTypesMap}
            />
          </CardContent>
        </Card>
      </CardActionArea>
    )
  }
  return null
}

const CampaignTileColumn: FC<{
  title: string
  create?: boolean
}> = ({ title, create, children }) => {
  const openCampaignWizard = useOpenModalKey(campaignWizardModalKey)
  const [search, setSearch] = useState("")
  return (
    <Stack width={{ xs: "100%", sm: "45%" }} marginX={{ xs: 0, sm: "2.5%" }} alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h3" marginY={1}>
          {title}
        </Typography>
        {create && (
          <Button variant="contained" onClick={openCampaignWizard} size="small">
            Create
          </Button>
        )}
      </Stack>
      <TextField
        size="small"
        sx={{ width: "100%" }}
        placeholder="Search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {search ? (
                <IconButton onClick={() => setSearch("")} edge="end">
                  <CloseIcon />
                </IconButton>
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <Box width="100%" maxHeight={{ xs: "auto", sm: "600px" }} overflow="auto">
        <FilterContext.Provider value={search}>{children}</FilterContext.Provider>
      </Box>
    </Stack>
  )
}

export const CampaignTilesContainer: FC = () => {
  const { data: myCampaignData } = useFetchMyCampaigns()
  const { data: campaignsData } = useFetchFilterList()
  const { data: recommendedCampaigns } = useFetchRecommendedCampaigns()
  const { data: organizationsMap } = useFetchOrganizationsMap()
  const { data: geographiesMap } = useFetchGeographiesMap()
  const { data: channelTypesMap } = useFetchChannelTypesMap()

  return (
    <CampaignMapContext.Provider
      value={{
        organizationsMap: organizationsMap,
        geographiesMap: geographiesMap,
        channelTypesMap: channelTypesMap,
      }}
    >
      <Stack direction={{ xs: "column", sm: "row" }} marginTop={4}>
        <CampaignTileColumn
          title="Campaigns"
          // create // not 100% on if we want to remove this yet. Commented so restoring is easy
        >
          {myCampaignData?.campaigns.map((campaign) => <CampaignCard campaign={campaign} key={campaign.id} />)}
          <Typography variant="h4" textAlign="center">
            Organization Campaigns
          </Typography>
          {campaignsData?.campaigns.map((campaign: Campaign) => <CampaignCard campaign={campaign} key={campaign.id} />)}
        </CampaignTileColumn>
        <CampaignTileColumn title="Recommended Campaigns">
          {recommendedCampaigns?.map((campaign) => <CampaignCard campaign={campaign} key={campaign.id} />)}
        </CampaignTileColumn>
      </Stack>
    </CampaignMapContext.Provider>
  )
}
