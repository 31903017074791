import { FC, useMemo } from "react"
import { CardContent, Grid, CardHeader, Chip, Typography, Skeleton } from "@mui/material"
import { usePreviewWidget } from "../../../api/widgets"
import { HorizontalChart } from "../HorizontalChart"
import { DisplayWidgetActions } from "./DisplayWidgetActions"
import { Configuration } from "../../../interfaces/dashboard"
import { dateRangeLabel } from "../../../functions/dashboard"

export const TopChannels: FC<{
  configuration: Configuration
  openAction?: (actionType: string) => void
}> = ({ configuration, openAction }) => {
  const { data: allTopChannels, isLoading } = usePreviewWidget({ type: "top_channels", configuration: configuration })

  const topChannels: any = useMemo(
    () => allTopChannels?.widget?.top_channels?.slice(0, configuration.channels_display_count),
    [configuration.channels_display_count, allTopChannels?.widget?.top_channels],
  )

  const showGraph = useMemo(
    () => allTopChannels?.widget?.top_channels && allTopChannels?.widget?.top_channels?.length > 0,
    [allTopChannels?.widget?.top_channels],
  )

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <CardHeader title={isLoading ? <Skeleton /> : configuration?.name} subheader="Top Channels" />
        </Grid>
        <Grid item>
          <Chip
            color="primary"
            label={
              isLoading ? (
                <Skeleton width="15ch" sx={{ backgroundColor: "#bbb" }} />
              ) : (
                `Last ${configuration?.day_range} Days`
              )
            }
            sx={{ position: "relative" }}
          />
          {openAction && <DisplayWidgetActions openAction={openAction} />}
        </Grid>
      </Grid>
      <CardContent>
        <Grid container direction="row" justifyContent="center">
          {isLoading ? (
            <Skeleton height={200} width="100%" />
          ) : showGraph ? (
            <HorizontalChart data={topChannels} yAxisName="name" />
          ) : (
            <Typography variant="h6">No Channels Found</Typography>
          )}
        </Grid>
      </CardContent>
    </>
  )
}
