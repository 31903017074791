import { FC } from "react"
import {
  Typography,
  List,
  Box,
  Divider,
  Grid,
  Button,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material"
import { PermissionsListItemDetails } from "./PermissionsListItemDetails"
import InfoIcon from "@mui/icons-material/Info"
import { useDeleteUserOrganizationPermission } from "@api/userOrganizations"
import { ExpandMoreOutlined } from "@mui/icons-material"
import { IHashedPermissions } from "./PermissionsList"

export const PermissionListItem: FC<{
  stateName: string
  hashedPermissions: IHashedPermissions
  userOrgId: number
  totalPermissions: number
}> = ({ stateName, hashedPermissions, userOrgId, totalPermissions }) => {
  const { mutate: deletePermissions } = useDeleteUserOrganizationPermission()

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls={stateName + "-panel-content"}
        id={stateName + "-panel-header"}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6">{stateName}</Typography>
          <Typography>
            {`${hashedPermissions?.permissionAutoUpdateByState?.[stateName]?.autoUpdateOnCount || 0}
            /
            ${totalPermissions || 0}`}
          </Typography>
          <Tooltip title="# of automatic update owned / total permissions with automatic update">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <Button
              onClick={() =>
                deletePermissions({
                  userOrganizationId: userOrgId,
                  ids: hashedPermissions?.permissionByState?.[stateName]?.map((state) => state.id),
                })
              }
              variant="contained"
              size="small"
              color="error"
            >
              Delete All
            </Button>
          </Grid>
        </Grid>
        <List>
          {hashedPermissions?.permissionByState?.[stateName]?.map((permission) => (
            <PermissionsListItemDetails permission={permission} userOrgId={userOrgId} key={permission.id} />
          ))}
          <Box sx={{ textAlign: "right" }}>
            <Divider />
          </Box>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
