import { Column, formatDate } from "../components/shared/DataTable"

export const columns: Column[] = [
  { id: "searchTerms", label: "Search Terms", headerCellProps: { sx: { maxWidth: 210 } } },
  { id: "page_of_origin", label: "Origin", headerCellProps: { sx: { minWidth: 90 } } },
  { id: "created_at", label: "Created At", headerCellProps: { sx: { minWidth: 40 } }, formatter: formatDate },
  { id: "discarded_at", label: "Unsubscribed", headerCellProps: { sx: { minWidth: 40 } }, formatter: formatDate },
  { id: "email", label: "User", headerCellProps: { sx: { minWidth: 40 } } },
  { id: "campaign_id", label: "Campaign Id", headerCellProps: { sx: { minWidth: 40 } } },
  { id: "meeting_id", label: "Meeting Id", headerCellProps: { sx: { minWidth: 40 } } },
]
