import { Dispatch, FC, SetStateAction, useMemo, useState } from "react"
import { Typography, Autocomplete, TextField, SxProps, Theme, TextFieldProps } from "@mui/material"
import { useFetchOrganizations } from "../../../../../api/organizations"
import { FilterParamsController } from "../../../../../config/homeConfig"
import { BasicGeoType } from "../../interface"
import { FilterParams, FilterParamsWithoutDates } from "../../../../Home/interface"
import { styledGroupTitles } from "../../styles"
import { sortGeographyByState } from "../../helpers"

export const GeographySelectorMultiple: FC<{
  options: {
    states: BasicGeoType[]
    cities: BasicGeoType[]
    counties: BasicGeoType[]
  }
  type: "cities" | "states" | "counties"
  label: string
  filterParams: FilterParams
  setFilterParams: Dispatch<SetStateAction<FilterParams | FilterParamsWithoutDates>>
  sx?: SxProps<Theme>
  textFieldProps?: TextFieldProps
  size?: "small" | "medium"
  loading?: boolean
}> = ({ options, type, label, filterParams, setFilterParams, sx, textFieldProps, size, loading }) => {
  const { data: organizationsData } = useFetchOrganizations()
  const [open, setIsOpen] = useState(false)

  const sortedOptions = useMemo(() => {
    if (options && options[type]) {
      return sortGeographyByState(options[type], type, options?.states)
    } else {
      return []
    }
  }, [options])

  const geographiesHash: { [id: number]: BasicGeoType } = useMemo(() => {
    if (!options || !options[type]) {
      return {}
    }
    const hash: { [id: number]: BasicGeoType } = {}
    options[type].forEach((geo: BasicGeoType) => (hash[geo.id] = geo))

    return hash
  }, [options])

  const filteredValues = useMemo(() => {
    if (!options || !options[type]) {
      return []
    }
    return filterParams[type].map((geoId: number) => geographiesHash[geoId])
  }, [filterParams, options])

  const filteredOptions: BasicGeoType[] = useMemo(() => {
    if (type !== "states") {
      const newOpts = sortedOptions.filter((geo) => {
        if (!filterParams.states.includes(geo?.state_id || 0) && filterParams["states"]?.length !== 0) {
          return false
        }
        return true
      })
      return newOpts
    }
    return options ? options[type] : []
  }, [filterParams, sortedOptions])

  if (
    !options ||
    !options[type] === undefined ||
    ((type === "cities" || type === "counties") && filteredOptions === undefined)
  ) {
    return <></>
  }

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      sx={{
        ".MuiOutlinedInput-root": {
          height: 60,
        },
        ...sx,
      }}
      noOptionsText="Nothing to display"
      id={`${type}-select`}
      open={open}
      ListboxComponent={styledGroupTitles}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onClick={() => setIsOpen(true)}
      loading={loading}
      options={filteredOptions?.length > 0 ? [...filteredOptions] : []}
      value={filteredValues || []}
      renderTags={() => (
        <Typography onClick={() => setIsOpen(!open)} sx={{ maxWidth: "calc(100% - 40px)" }} noWrap>
          {filteredValues
            .map((item) => item?.code || item?.name)
            .join(", ")
            .replace(/,\s*$/, "")}
        </Typography>
      )}
      onChange={(_event, newValue) => {
        let temp = { ...filterParams }
        const filterParamsController: FilterParamsController = new FilterParamsController(
          type,
          temp,
          newValue.map((geo) => geo?.id),
          options,
          organizationsData,
          filteredOptions,
        )
        temp = filterParamsController.handleGeographySelection()

        setFilterParams(temp)
      }}
      groupBy={(option) => (option?.state_name && option.state_name !== "none" ? option.state_name : "")}
      renderInput={(params) => <TextField {...params} label={label} {...textFieldProps} />}
      getOptionLabel={(option: BasicGeoType | null) => option?.name || "None"}
      renderOption={(props, option) => (
        <li {...props} key={option?.id}>
          <Typography variant="body1">{option?.name}</Typography>
        </li>
      )}
      size={size}
    />
  )
}
