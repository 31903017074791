import { Typography, Paper } from "@mui/material"
import { FC } from "react"
import { User } from "../../interfaces/user"

export const AssigneesPopoverList: FC<{ list: User[] }> = ({ list }) => (
  <Paper sx={{ padding: 2 }}>
    {list.map((user) => (
      <Typography key={user.id} gutterBottom>
        {user.email}
      </Typography>
    ))}
  </Paper>
)
