import { FC, useEffect, useState, useMemo } from "react"
import { Stack, Autocomplete, Typography, IconButton, Skeleton, TextField, Box } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import SaveIcon from "@mui/icons-material/Save"
import CloseIcon from "@mui/icons-material/Close"
import {
  getMainSalesforceObjectAttachmentField,
  getMainSalesforceObjectAttachmentFields,
  useSetMainSalesforceObjectAttachmentField,
} from "../../../api/userOrganizations/salesforce"
import { SObject } from "../../../interfaces/salesforce"

export const SelectMainSalesforceObjectAttachmentFields: FC = () => {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const { data: mainSalesforceObjectAttachmentFields } = getMainSalesforceObjectAttachmentFields()
  const { data: mainSalesforceObjectAttachmentField, isLoading } = getMainSalesforceObjectAttachmentField()
  const { mutate: setMainSalesforceObjectAttachmentField } = useSetMainSalesforceObjectAttachmentField(() =>
    setEditing(false),
  )

  const fieldsMap = useMemo(() => {
    const map = new Map<string, SObject>()
    if (mainSalesforceObjectAttachmentFields) {
      mainSalesforceObjectAttachmentFields
        // TODO: remove below after getting response from salesforce about required fields
        .filter((sobjectField) => sobjectField.name === "Description")
        .forEach((sobjectField) => {
          if (sobjectField.type === "textarea") {
            map.set(sobjectField.name, sobjectField)
          }
        })
    }
    return map
  }, [mainSalesforceObjectAttachmentFields])

  useEffect(() => {
    if (mainSalesforceObjectAttachmentField) {
      setValue(mainSalesforceObjectAttachmentField)
    }
  }, [mainSalesforceObjectAttachmentField])

  if (!mainSalesforceObjectAttachmentFields?.length) {
    return null
  }

  const showEditButtons = editing || !mainSalesforceObjectAttachmentField
  let content = null
  if (isLoading) {
    content = <Skeleton width="100px" />
  } else {
    if (showEditButtons) {
      if (mainSalesforceObjectAttachmentFields) {
        content = (
          <Autocomplete
            size="small"
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            options={Array.from(fieldsMap.keys())}
            sx={{ width: 300 }}
            getOptionLabel={(option) => fieldsMap.get(option)?.label || ""}
            renderInput={(params) => <TextField {...params} label="Salesforce Object Attachment Field" />}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option}>
                {fieldsMap.get(option)?.label}
              </Box>
            )}
          />
        )
      }
    } else if (mainSalesforceObjectAttachmentField) {
      content = <Typography>{mainSalesforceObjectAttachmentField}</Typography>
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography>Main Salesforce Object Attachment Field:</Typography>
      {content}
      {showEditButtons ? (
        <>
          <IconButton
            size="small"
            onClick={() => {
              if (value) {
                setMainSalesforceObjectAttachmentField({ field: value })
              }
            }}
            disabled={!value}
          >
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => setEditing(false)} size="small">
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={() => setEditing(true)} size="small">
          <EditIcon />
        </IconButton>
      )}
    </Stack>
  )
}
