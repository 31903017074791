import { FC, useState, useEffect } from "react"
import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import { getMeetingBriefingCost, useCreatePersonalBriefing } from "../../../api/meetings"
import { useParams } from "react-router-dom"
import { IMeetingRouteParams } from "../../../api/interfaces"
import { getPrompts } from "../../../api/prompts"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreditSpentConfirmation, roundCredits } from "../../shared/CreditSpentConfirmation"
import { useToast } from "../../shared/ToastHook"
import { PersonalBriefingPromptSelector } from "../../shared/PersonalBriefingPromptSelector"
import PostAddIcon from "@mui/icons-material/PostAdd"

const personalBriefingModalKey = "PERSONAL_BRIEFING_COST_MODAL"

export const PersonalBriefingCreation: FC<{
  blockedPrompts?: string[]
  onSuccess?: () => void
  isLoading?: boolean
}> = ({ blockedPrompts, onSuccess, isLoading }) => {
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data: prompts, isFetched } = getPrompts("personal")
  const promptsMap = new Map(prompts?.map((prompt) => [prompt.id, prompt]))
  const [selectedPrompts, setSelectedPrompts] = useState<Set<number>>(new Set())
  const toast = useToast()
  const openModal = useOpenModalKey(personalBriefingModalKey)
  const { data: cost } = getMeetingBriefingCost(meetingId)
  const { mutate: createPersonalBriefing } = useCreatePersonalBriefing(meetingId)

  const availablePrompts =
    prompts?.filter(({ id, name }) => {
      if (selectedPrompts.has(id)) {
        return false
      } else if (blockedPrompts && blockedPrompts.includes(name)) {
        return false
      } else {
        return true
      }
    }) || []

  useEffect(() => {
    if (prompts && isFetched && selectedPrompts.size === 0) {
      if (availablePrompts.length === 0) {
        setSelectedPrompts(new Set())
      } else {
        setSelectedPrompts(new Set([availablePrompts[0].id]))
      }
    }
  }, [isFetched, availablePrompts.length])

  useEffect(() => {
    if (blockedPrompts?.length && prompts) {
      setSelectedPrompts(
        (prevValue) =>
          new Set(
            Array.from(prevValue).filter((selectedPrompt) => {
              const prompt = promptsMap.get(selectedPrompt)
              return Boolean(prompt && !blockedPrompts.includes(prompt.name))
            }),
          ),
      )
    }
  }, [blockedPrompts?.length])

  return (
    <>
      <Stack spacing={1} alignItems="flex-start">
        <Typography>Add your personalized briefing prompts.</Typography>
        <PersonalBriefingPromptSelector
          selectedPrompts={selectedPrompts}
          setSelectedPrompts={setSelectedPrompts}
          blockedPrompts={blockedPrompts}
        />
        <Stack spacing={2} direction="row" alignItems="center">
          <Button
            variant="contained"
            startIcon={<PostAddIcon />}
            onClick={openModal}
            disabled={selectedPrompts.size === 0 || isLoading}
          >
            Create Personal Briefing
          </Button>
          {cost ? <Typography>{`Credit Cost: ${roundCredits(cost * selectedPrompts.size)}`}</Typography> : null}
          {isLoading && (
            <>
              <Typography>Briefing Processing</Typography>
              <CircularProgress />
            </>
          )}
        </Stack>
      </Stack>
      {cost ? (
        <CreditSpentConfirmation
          modalKey={personalBriefingModalKey}
          onConfirm={() => {
            createPersonalBriefing({ promptIds: Array.from(selectedPrompts) })
            toast("A Personal Briefing is being created. We will email you when it's finished")
            if (onSuccess) {
              onSuccess()
            }
          }}
          cost={cost * selectedPrompts.size}
        />
      ) : null}
    </>
  )
}
