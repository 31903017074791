import { FC, useState, useMemo } from "react"
import { List, Tabs, Tab, Skeleton, Box } from "@mui/material"
import { Layout } from "../components/shared/Layout"
import { CampaignListItem } from "../components/Campaigns/CampaignListItem"
import { a11yProps } from "../components/shared/config"
import { useFetchMyCampaigns } from "../api/campaigns"
import { CampaignTableView } from "../components/Campaigns/CampaignTableView"
import { campaignWizardModalKey } from "../components/shared/CampaignWizard"
import { useOpenModalKey } from "../components/shared/OpenModalHook"
import { CampaignWizard } from "../components/shared/CampaignWizard"
import { useHistory, useLocation } from "react-router-dom"
import { useIsOpportunitiesEnabled } from "@src/api/users"
import { OpportunityCampaigns } from "@src/components/Campaigns/OpportunityCampaigns"
import moment from "moment"
import AddIcon from "@mui/icons-material/Add"
import React from "react"

export const CampaignsPage: FC = () => {
  const searchParams = useLocation().search
  const history = useHistory()
  const urlSearchParams = new URLSearchParams(searchParams)
  const openModal = useOpenModalKey(campaignWizardModalKey)
  const [tabIndex, setTabIndex] = useState(parseInt(urlSearchParams.get("tabIndex") || "0"))
  const { data: myCampaignsData, isLoading: campaignsLoading } = useFetchMyCampaigns()
  const { data: opportunitiesEnabled } = useIsOpportunitiesEnabled()

  const sortedCampaigns = useMemo(() => {
    const campaigns = [...(myCampaignsData?.campaigns || [])]
    campaigns.sort((c, c_next) => {
      return moment(c_next.updated_at).valueOf() - moment(c.updated_at).valueOf()
    })
    return campaigns
  }, [myCampaignsData])

  return (
    <Layout title="Campaigns" actions={[{ action: openModal, text: "new campaign", icon: <AddIcon /> }]}>
      <Tabs
        value={tabIndex}
        onChange={(_event: React.SyntheticEvent, newValue: number) => {
          setTabIndex(newValue)
          urlSearchParams.set("tabIndex", newValue?.toString())
          history.push({ search: urlSearchParams.toString() })
        }}
        aria-label="settings tabs"
      >
        <Tab label="My Campaigns" {...a11yProps(0)} />
        <Tab label="All Campaigns" {...a11yProps(0)} />
        {opportunitiesEnabled && <Tab label="Opportunity Campaigns" {...a11yProps(0)} />}
      </Tabs>
      {tabIndex === 0 && (
        <List>
          {campaignsLoading
            ? new Array(3)
                .fill(null)
                .map((_, index) => <Skeleton key={index} height={160} variant="rectangular" sx={{ marginBottom: 5 }} />)
            : sortedCampaigns.map((campaign) => (
                <CampaignListItem key={`${campaign.id}-${campaign.assigned}`} campaign={campaign} />
              ))}
        </List>
      )}
      {tabIndex === 1 && (
        <Box height="90%" overflow="hidden">
          <CampaignTableView />
        </Box>
      )}
      {tabIndex === 2 && <OpportunityCampaigns />}
      <CampaignWizard />
    </Layout>
  )
}
