export const CustomToolTip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-recharts-tooltip">
        <p>{label}</p>
        <p className="custom-recharts-metric">{`total_hits: ${payload[0].value}`}</p>
      </div>
    )
  }
  return null
}
