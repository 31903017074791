import { FC, useEffect, useMemo, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from "@mui/material"
import {
  useCreateUserOrganizationPermission,
  useFetchUserOrganizationPermissionsByState,
} from "../../../api/userOrganizations"
import { NewPermissionChannel } from "./NewPermissionChannel"
import { NewPermissionState } from "./NewPermissionState"
import { useSetUserOrganizationPermissionByState } from "../../../api/userOrganizations"
import { DetailedUserOrganization } from "@src/interfaces/userOrganization"

export const NewPermissionContainer: FC<{
  open: boolean
  onClose: () => void
  userOrganization: DetailedUserOrganization
}> = ({ open, onClose, userOrganization }) => {
  const [tabValue, setTabValue] = useState("state")
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedOrganizations(userOrgPermOrgIds)
    setTabValue(newValue)
  }

  const userOrgPermOrgIds = useMemo(() => {
    return userOrganization?.user_organization_permissions?.map((userOrgPerm) => userOrgPerm.organization_id)
  }, [userOrganization])
  const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>(userOrgPermOrgIds)
  const [orgFilter, setOrgFilter] = useState("")
  const { mutate: createPermission } = useCreateUserOrganizationPermission()
  const { data: userOrgPremissionsByState } = useFetchUserOrganizationPermissionsByState(userOrganization.id)
  const [selectedStates, setSelectedStates] = useState<number[]>([])
  const { mutate: createUserOrgPermissionByState } = useSetUserOrganizationPermissionByState()

  useEffect(() => {
    setSelectedStates(userOrgPremissionsByState?.state_permissions.map((state) => state.id) || [])
  }, [userOrgPremissionsByState])

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Create Permissions</DialogTitle>
      <DialogContent>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="secondary"
          sx={{ paddingBottom: 2 }}
        >
          <Tab value="channel" label="Channel" />
          <Tab value="state" label="State" />
        </Tabs>
        {tabValue === "channel" ? (
          <NewPermissionChannel
            userOrganization={userOrganization}
            orgFilter={orgFilter}
            setOrgFilter={setOrgFilter}
            selectedOrganizations={selectedOrganizations}
            setSelectedOrganizations={setSelectedOrganizations}
          />
        ) : (
          <NewPermissionState
            userOrganization={userOrganization}
            selectedStates={selectedStates}
            handleSelectionChange={(selectedStates) => setSelectedStates(selectedStates)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (tabValue === "state") {
              createUserOrgPermissionByState({
                stateIds: selectedStates,
                userOrganizationId: userOrganization.id,
              })
            } else {
              createPermission({
                organizationIds: selectedOrganizations,
                userOrganizationId: userOrganization.id,
              })
            }
            onClose()
          }}
        >
          Confirm
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
