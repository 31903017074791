import { Column } from "../components/shared/DataTable"
import { formatDate, formatNumber } from "../components/shared/DataTable/formatters"

export const columns: Column[] = [
  { id: "name", label: "Channel Name", link: "link", headerCellProps: { sx: { minWidth: 80 } } },
  { id: "state", label: "State", headerCellProps: { sx: { minWidth: 80 } } },
  { id: "county", label: "County", headerCellProps: { sx: { minWidth: 80 } } },
  { id: "channelType", label: "Channel Type", headerCellProps: { sx: { minWidth: 80 } } },
  { id: "meetingsLength", label: "Total Meetings", formatter: formatNumber, headerCellProps: { sx: { minWidth: 80 } } },
  {
    id: "lastMeeting",
    label: "Last Meeting Finished",
    formatter: formatDate,
    headerCellProps: { sx: { minWidth: 80 } },
  },
]
