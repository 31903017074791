import { FC, createContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "../api/interfaces"
import { useFetchOrganization } from "../api/organizations"
import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material"
import { ChannelInfoCard } from "../components/ChannelDetails/ChannelInfoCard"
import { LinksContactsCard } from "../components/ChannelDetails/LinksContactsCard"
import { MeetingArchiveCard } from "../components/ChannelDetails/MeetingArchiveCard"

export interface ChannelDetailContextTypes {
  organization: any
}

export const ChannelDetailContext = createContext<ChannelDetailContextTypes>({
  organization: {},
})

export const ChannelDetailContainer: FC = () => {
  const orgId = useParams<IOrganizationRouteParams>().organizationId
  const { data: organization, isLoading } = useFetchOrganization(orgId)

  const filterParams = useMemo(() => {
    const params = {
      cities: [] as number[],
      counties: [] as number[],
      states: [] as number[],
      organizations: [] as number[],
      channel_types: [] as string[],
    }
    if (organization) {
      params.organizations.push(organization.id)
    }
    return params
  }, [organization])

  return (
    <ChannelDetailContext.Provider value={{ organization }}>
      <Grid container justifyContent="center" gap={2} padding={2}>
        <Grid container xs={12} direction="row" justifyContent="center">
          <Grid item xs={6}>
            <Typography variant="h1">{organization?.name || ""}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" href={`/?filter-params=${btoa(JSON.stringify(filterParams))}`}>
                Search this channel
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container xs={12} direction="row" gap={2} justifyContent="space-between">
          <Grid item xs={12} md={4}>
            <ChannelInfoCard />
          </Grid>
          <Grid item xs={12} md={7.5}>
            {organization && <LinksContactsCard />}
            {isLoading && <Skeleton variant="rectangular" height={200} />}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <MeetingArchiveCard />
          </Grid>
        </Grid>
      </Grid>
    </ChannelDetailContext.Provider>
  )
}
