import { FC, useMemo, useState } from "react"
import { Button, Stack } from "@mui/material"
import { BriefingsUsersContext } from "./context"
import { EditBriefingsUsersModal } from "./EditModal"
import { DeleteBriefingsUsersModal } from "./DeleteModal"
import { getBriefingsUsers } from "../../../../api/admin/organizations"
import { DataTable } from "../../../shared/DataTable"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "../../../../api/interfaces"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"

import { SelectedPrompt } from "../../../shared/PromptsAutocomplete"
import { getPrompts } from "../../../../api/admin/prompts"
import { BriefingsUsersColumns } from "./columns"
import { GenerateBriefingCheckbox } from "./GenerateBriefingCheckbox"

export const BriefingsUsersTable: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const { data: promptsData } = getPrompts()
  const { data } = getBriefingsUsers(organizationId)

  const [openModal, setOpenModal] = useState("")
  const [id, setId] = useState<number | null>(null)
  const [userId, setUserId] = useState<number | null>(null)
  const [email, setEmail] = useState("")
  const [promptInput, setPromptInput] = useState<SelectedPrompt[]>([])

  const briefingsUsersData = useMemo(() => {
    if (data && promptsData) {
      return data.map((briefingsUser) => ({
        ...briefingsUser,
        prompts: briefingsUser.prompts.map((promptId) => {
          const prompt = promptsData.find((prompt) => prompt.id === promptId)
          return { id: promptId, name: prompt?.name || "" }
        }),
      }))
    }
  }, [data, promptsData])

  const openEdit = () => setOpenModal("edit")

  return (
    <BriefingsUsersContext.Provider
      value={{
        openModal,
        id,
        userId,
        email,
        promptsInput: promptInput,
        setId,
        setUserId,
        setPromptsInput: setPromptInput,
        close: () => {
          setOpenModal("")
          setId(null)
          setUserId(null)
          setEmail("")
          setPromptInput([])
        },
      }}
    >
      <GenerateBriefingCheckbox />
      <Button variant="contained" sx={{ alignSelf: "flex-start", marginTop: 1 }} onClick={openEdit}>
        Create
      </Button>
      <DataTable
        data={briefingsUsersData?.map((briefingsUser) => ({
          ...briefingsUser,
          actions: (
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                endIcon={<EditIcon />}
                onClick={() => {
                  setId(briefingsUser.id)
                  setUserId(briefingsUser.user_id)
                  setPromptInput(briefingsUser.prompts)
                  openEdit()
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                endIcon={<DeleteIcon />}
                onClick={() => {
                  setId(briefingsUser.id)
                  setEmail(briefingsUser.email)
                  setOpenModal("delete")
                }}
              >
                Delete
              </Button>
            </Stack>
          ),
        }))}
        columns={BriefingsUsersColumns}
      />
      <EditBriefingsUsersModal />
      <DeleteBriefingsUsersModal />
    </BriefingsUsersContext.Provider>
  )
}
