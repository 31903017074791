import { Box, Button, Card, CardContent, Divider, Stack, Tooltip, Typography } from "@mui/material"
import { ClipShareLeadView } from "@src/interfaces/clipShare"
import { FC } from "react"
import { buttonStylesTimeStamp } from "./TranscriptListItem"
import Highlighter from "react-highlight-words"
import { getHighlighterRegexArray } from "@src/functions/highlighter"
import { theme } from "@src/theme"

export interface FindALeadContainerProps {
  setVideoStartTime: (startTime: { start: number; change: boolean }) => void
  clipShare: ClipShareLeadView
  videoCardHeight?: string
}

export const FindALeadContainer: FC<FindALeadContainerProps> = ({ setVideoStartTime, clipShare, videoCardHeight }) => {
  const timestamp = new Date(parseInt(clipShare.start_time_in_seconds) * 1000).toISOString().slice(11, 19)
  return (
    <Stack direction="column" sx={{ height: videoCardHeight, width: { xs: undefined, lg: "50%" }, flex: 1 }}>
      {clipShare.description?.trim().length !== 0 && (
        <>
          <Typography variant="h5" fontWeight="bold" marginTop={2}>
            Sales Insights
          </Typography>
          <Box fontWeight={40} maxHeight={"50%"} height={"auto"} overflow={"auto"}>
            <Box border={0.5} borderRadius={1} borderColor={theme.palette.primary.light} padding={2} marginTop={1}>
              <Typography marginTop={1} fontWeight={500} variant="body2" whiteSpace="pre-wrap" color="black">
                {clipShare.description}
              </Typography>
            </Box>
          </Box>
        </>
      )}
      <Card
        sx={{
          border: 1,
          borderColor: "primary.dark",
          marginTop: 3,
          position: "relative",
          overflow: "auto",
          maxHeight: clipShare.description?.trim().length !== 0 ? "30%" : "100%",
        }}
        variant="outlined"
      >
        <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
          <Tooltip title={`Seek to ${timestamp}`}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              sx={buttonStylesTimeStamp}
              onClick={() => {
                setVideoStartTime({ start: parseInt(clipShare.start_time_in_seconds) - 5, change: true })
                document.getElementById("meeting-video-player-anchor-element")?.scrollIntoView()
              }}
            >
              {timestamp}
            </Button>
          </Tooltip>
          <Typography
            variant="body2"
            width="100%"
            sx={{ display: { xs: "none", sm: "inherit" }, whiteSpace: "pre-line" }}
          >
            <Typography>
              <Highlighter
                textToHighlight={clipShare.raw_text}
                searchWords={getHighlighterRegexArray([clipShare.keyword])}
              />
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  )
}
