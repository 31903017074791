import { getBriefings } from "../api/briefings"
import { PublicBriefing } from "../interfaces/briefing"
import { Organization } from "../interfaces/organization"
import { BRIEFING_PRIORITY } from "./briefingConfig"

interface ShareObject {
  shareId: number
  channelName?: string
  meetingTitle?: string
  clipText?: string
}

function getBriefingWithSummary(briefings: PublicBriefing[]) {
  let chosenBriefing
  let i = 0
  // first we pick according to the priority as long as it has a summary too
  while (i < BRIEFING_PRIORITY.length && !chosenBriefing) {
    chosenBriefing = briefings.find((briefing) => briefing.name === BRIEFING_PRIORITY[i] && briefing.summary)
    i++
  }

  // if we can't find any, just pick a briefing with a summary
  if (!chosenBriefing) {
    chosenBriefing = briefings.find((briefing) => briefing.summary)
  }

  // if we still can't find any, just pick the first one
  if (!chosenBriefing) {
    chosenBriefing = briefings[0]
  }

  if (chosenBriefing.summary) {
    return chosenBriefing.summary
  } else if (chosenBriefing.result) {
    return `${chosenBriefing.result.split(" ").slice(0, 60).join(" ")}...`
  }

  return undefined
}

export const useNavigatorShareFormatBrowser = (meetingId: unknown, email?: boolean) => {
  const { data: briefingsQuery } = getBriefings(new URLSearchParams({ meetings: `${meetingId}`, count: "100" }))
  const meetingSummary = briefingsQuery?.briefings.length ? getBriefingWithSummary(briefingsQuery.briefings) : undefined

  return (shareDetails: ShareObject) => {
    const textCollection = [
      meetingSummary || "<<Your Text Here>>",
      "Add some context on this clip for your recipient.",
      `Check out this Cloverleaf AI clip. https://${window.location.hostname}/share/${shareDetails.shareId}?type=clip`,
    ]

    if (shareDetails?.channelName) {
      textCollection.push(`Government Agency: ${shareDetails.channelName}`)
    }

    if (shareDetails?.meetingTitle) {
      textCollection.push(`Meeting Name: ${shareDetails.meetingTitle}`)
    }

    if (shareDetails?.clipText) {
      textCollection.push(`Clip Text: "${shareDetails.clipText}"`)
    }

    if (email) {
      textCollection.push("Best Regards,")
    }
    return textCollection.join(email ? "%0D%0D" : "\n\n")
  }
}

export const formatChannelDetails = (organization: Organization) => {
  let text = `${organization.name} in `
  if (organization.city) {
    text += `in ${organization.city.name}, `
  } else if (organization.county) {
    text += `in ${organization.county.name}, `
  }

  if (organization?.state?.name) {
    text += `${organization.state.name}`
  }

  return text
}
