import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useFetchOrganizationMetadata } from "@src/api/organizations"
import { FC, useContext } from "react"
import { OrganizationFormContext } from "./Context"
import { unpackSetters } from "@src/components/shared/unpackSetters"

export const DownloadServiceOptions: FC = () => {
  const { data: orgMetadataData } = useFetchOrganizationMetadata()
  const { value: orgValue, setValue: setOrgValue } = useContext(OrganizationFormContext)
  const { downloadServiceType } = orgValue
  const { setDownloadServiceType } = unpackSetters(orgValue, setOrgValue)

  return (
    <FormControl fullWidth>
      <InputLabel id="download-service-input-label">Download Service</InputLabel>
      <Select
        value={downloadServiceType}
        onChange={(event) => setDownloadServiceType(event.target.value as string)}
        labelId="download-service-select-label"
        label="Download Service"
      >
        {orgMetadataData?.download_service.map((service) => (
          <MenuItem key={service.id} value={service.value}>
            {service.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
