import { FormControlLabel, Checkbox, Stack } from "@mui/material"
import { ViewOptsConfigInterface } from "../interface"

export class TableSettings {
  constructor(private optionsConfig: ViewOptsConfigInterface[]) {
    this.optionsConfig = optionsConfig
  }

  viewCheckboxComponent = (config: ViewOptsConfigInterface) => (
    <FormControlLabel
      key={config.id}
      label={config.label}
      control={<Checkbox checked={config.value} color="primary" onChange={() => config.setValue()} />}
    />
  )

  getCheckBoxesByType = (type: string) => {
    return (
      <Stack direction="row">
        {this.optionsConfig
          .filter((config) => config.type === type)
          .map((config) => this.viewCheckboxComponent(config))}
      </Stack>
    )
  }
}
