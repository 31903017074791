// src/auth/protected-route.js

import { FC, useMemo } from "react"
import { Route } from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from "../components/shared/Loading"

const ProtectedRoute: FC<{
  component: any
  path?: string
  exact?: boolean
  signUp?: boolean
}> = ({ component, path, exact, signUp }) => {
  const AuthenticatedComponent = useMemo(
    () =>
      withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
        loginOptions: {
          screen_hint: signUp ? "signup" : "login",
        },
      }),
    [component],
  )
  return <Route component={AuthenticatedComponent} path={path} exact={exact} />
}

export default ProtectedRoute
