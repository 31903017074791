import { Dialog, DialogTitle, Typography, DialogContent, TextField, DialogActions, Button, Stack } from "@mui/material"
import { FC, useState } from "react"
import { useUpdateClip } from "../../api/clip_shares"
import { useCloseModalKey, useModalKey } from "../shared/OpenModalHook"
import { FetchedClipShare } from "../../api/bookmarks"

export const EditClipModal: FC<{
  clip?: FetchedClipShare
  bookmarkId: number
}> = ({ clip, bookmarkId }) => {
  const editModalKey = `EDIT_CLIP_SHARE_KEY_${bookmarkId}`
  const open = useModalKey(editModalKey)
  const closeModalKey = useCloseModalKey(editModalKey)
  const [title, setTitle] = useState(clip?.title || "")
  const [description, setDescription] = useState(clip?.description || "")

  const { mutate: update } = useUpdateClip()

  if (!clip) return null

  return (
    <Dialog
      open={open}
      onClose={closeModalKey}
      fullWidth
      PaperProps={{ sx: { maxWidth: "80%", paddingX: 6, paddingTop: 6, paddingBottom: 6 } }}
    >
      <DialogTitle>
        <Typography variant="h3" component="div">
          Edit Clip
        </Typography>
      </DialogTitle>
      <DialogContent>
        Edit your clip, then click save.
        <Stack direction="column" sx={{ paddingTop: 2 }} spacing={2}>
          <TextField
            label="Title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{ maxLength: 100 }}
          />
          <TextField
            label="Notes"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            InputProps={{ sx: { borderRadius: "0px !important" } }}
            multiline
            maxRows={20}
            minRows={2}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            clip.title = title
            clip.description = description
            update(clip)
            closeModalKey()
          }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button onClick={closeModalKey}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
