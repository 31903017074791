import { FC, useState } from "react"
import {
  Grid,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  Slider,
} from "@mui/material"
import { Link, useHistory, useLocation } from "react-router-dom"
import Loading from "@components/shared/Loading"
import { useFetchSpamMeetings } from "@api/meetings"
import { useFetchMeetings } from "@api/admin/meetings"
import { meetingColumns, createMeetingRow } from "@src/config/meetingAdminConfig"
import { PaginatedTable } from "@components/shared/PaginatedTable"
import { ActionsMenu } from "./ActionsMenu"
import { EngineGraph } from "./EngineGraph"
import { generateURL } from "@src/functions/generateURL"

export const MeetingsAdminContainer: FC = () => {
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const urlSearchParams = new URLSearchParams(locationSearchParams)

  const [selectedMeetings, setSelectedMeetings] = useState<number[]>([])
  const [tabIndex, setTabIndex] = useState(parseInt(urlSearchParams.get("tabIndex") || "0"))
  const [page, setPage] = useState(0)
  const [daysBack, setDaysBack] = useState(1)
  const { data: meetingsData } = useFetchSpamMeetings(page, daysBack)
  const { data: veronikaJobs } = useFetchMeetings({ veronika: true })

  const handleTabClick = (newTabIndex: number) => {
    setTabIndex(newTabIndex)
    urlSearchParams.set("tabIndex", newTabIndex.toString())
    history.push({ search: urlSearchParams.toString() })
  }

  if (meetingsData === undefined) {
    return <Loading />
  }

  return (
    <Grid container>
      <Grid container item xs={12} marginTop={2} justifyContent="space-between">
        <Grid item xs={8}>
          <Tabs
            value={tabIndex}
            onChange={(_event: React.SyntheticEvent, newValue: number) => handleTabClick(newValue)}
            aria-label="settings tabs"
          >
            <Tab label="Transcription Engine" value={0} />
            <Tab label="Spam Meetings" value={1} />
            <Tab label="Veronika" value={2} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item hidden={tabIndex !== 0} marginTop={2}>
        <EngineGraph />
      </Grid>
      <Grid item hidden={tabIndex !== 1}>
        <Grid item xs={2} alignSelf="flex-end" marginY={2}>
          <ActionsMenu meetingIds={selectedMeetings} />
          <Slider
            aria-label="Days Back"
            value={daysBack}
            onChange={(_event, value) => setDaysBack(value as number)}
            getAriaValueText={() => daysBack.toString()}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={10}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            data={meetingsData.spam_meetings}
            selected={selectedMeetings}
            setSelected={setSelectedMeetings}
            createRow={createMeetingRow}
            columns={meetingColumns}
            page={page}
            setPage={setPage}
            totalRecords={meetingsData.total}
            sortKey="spam_certainty"
            sortDirection="asc"
          />
        </Grid>
      </Grid>
      <Grid item hidden={tabIndex !== 2}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Started At</TableCell>
                <TableCell>Transcribed At</TableCell>
                <TableCell>Failed At</TableCell>
                <TableCell>Retries</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {veronikaJobs?.map((meeting) => (
                <TableRow key={meeting.id}>
                  <TableCell>{meeting.id}</TableCell>
                  <TableCell>
                    <Link to={generateURL(meeting.id)}>{meeting.title}</Link>
                  </TableCell>
                  <TableCell>{meeting.started_at}</TableCell>
                  <TableCell>{meeting.transcribed_at}</TableCell>
                  <TableCell>{meeting.failed_at}</TableCell>
                  <TableCell>{meeting.retries}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
