import { FC } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

export const Confirm: FC<{
  open: boolean
  onClose: () => any
  onSucceed: any
  title: string
  body?: string
  renderBody?: any
}> = ({ open, onClose, onSucceed, title, body, renderBody = () => {} }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
          {renderBody()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSucceed}>Confirm</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
