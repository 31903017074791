import { Box, Button, Card, CardContent, Pagination, Skeleton, Stack, Typography } from "@mui/material"
import { FC, useEffect, useMemo, useState } from "react"
import { SearchTextField } from "../components/shared/SearchTextField"
import { useFetchGeographies } from "../api/geographies"
import { GeographySelectorMultiple } from "../components/shared/Selectors/Multiple/Geographies"
import { TypeSelectorMultiple } from "../components/shared/Selectors/Multiple/Types"
import { columns } from "../tableColumns/channelDirectoryColumns"
import { useFetchChannelDirectory } from "../api/organizations"
import { DataTable } from "../components/shared/DataTable"
import { FilterParams } from "../components/Home/interface"
import { Sort } from "../interfaces/default"
import { Layout } from "../components/shared/Layout"

export const ChannelDirectoryPage: FC = () => {
  const [channelName, setChannelName] = useState<string>("")
  const [submitChannelName, setSubmitChannelName] = useState<string>("")
  const [page, setPage] = useState<number>(1)
  const [sortBy, setSortBy] = useState<Sort>({ name: "state", method: "asc" })
  const [filterParams, setFilterParams] = useState<FilterParams>({
    states: [],
    counties: [],
    cities: [],
    organizations: [],
    channel_types: [],
    city_population: [-1, -1],
    county_population: [-1, -1],
    city_income: [-1, -1],
    county_income: [-1, -1],
  })
  const { data: geographies, isLoading: geogsLoading } = useFetchGeographies()
  const { data: channelData, isLoading: dataLoading } = useFetchChannelDirectory(
    page,
    filterParams,
    submitChannelName,
    sortBy,
  )

  // When filter params or table sort is changed we need to set page back to 1
  useEffect(() => {
    if (
      filterParams.states.length > 0 ||
      filterParams.counties.length > 0 ||
      filterParams.channel_types.length > 0 ||
      sortBy.name !== ""
    ) {
      setPage(1)
    }
  }, [filterParams, sortBy])

  const tableData = useMemo(() => {
    if (channelData) {
      return channelData.organizations.map((channel: any) => {
        return {
          name: channel.name,
          state: channel.state?.name,
          county: channel.county?.name,
          channelType: channel.type?.name,
          meetingsLength: channel.meetings_length,
          lastMeeting: channel.last_meeting,
          link: `/channel-details/${channel.id}`,
        }
      })
    }
    return undefined
  }, [channelData])

  useEffect(() => {
    if (channelName === "" || channelName === null) {
      setSubmitChannelName("")
    }
  }, [channelName])

  const totalPages = channelData ? Math.ceil(channelData.total_orgs / 25) : 1

  // Card content view that shows message to user if no data else returns data table
  const getCardContentView = () => {
    if (tableData?.length === 0 && !dataLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
          <Typography variant="h2">No Channels Found</Typography>
        </Box>
      )
    }
    return (
      <DataTable
        data={tableData}
        columns={columns}
        columnKey="id"
        tableStyle={{ height: { xs: 400, md: 400, xl: 600 } }}
        stickyHeader
        headerSortFunction={(newSort: Sort) => {
          const sort = { ...newSort }
          setSortBy(sort)
        }}
      />
    )
  }

  const handleSearchClicked = () => {
    setSubmitChannelName(channelName)
    if (channelName.length > 0) {
      setPage(1)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key.toLocaleLowerCase() === "enter") {
      handleSearchClicked()
    }
  }

  return (
    <Layout title="Channel Directory">
      <Stack mt={3}>
        <Stack spacing={2} direction="row">
          <SearchTextField
            search={channelName}
            setSearch={setChannelName}
            placeholder="Enter Channel Name"
            onKeyPress={(event) => handleKeyPress(event)}
            sx={{ width: "90%" }}
          />
          <Button variant="contained" onClick={() => handleSearchClicked()} sx={{ width: "10%" }}>
            Search
          </Button>
        </Stack>
        {geogsLoading ? (
          <Skeleton height={80} />
        ) : (
          <Stack mt={2} spacing={2} direction={{ xs: "column", md: "row" }}>
            <GeographySelectorMultiple
              options={geographies ? geographies : { states: [], counties: [], cities: [] }}
              type="states"
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              label="State"
              sx={{ width: "100%" }}
            />
            <GeographySelectorMultiple
              options={geographies ? geographies : { states: [], counties: [], cities: [] }}
              type="counties"
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              label="County"
              sx={{ width: "100%" }}
            />
            <TypeSelectorMultiple
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              sx={{ width: "100%" }}
            />
          </Stack>
        )}
        <Stack mt={5}>
          <Card>
            <CardContent sx={{ margin: 1 }}>{getCardContentView()}</CardContent>
          </Card>
          <Box display="flex" justifyContent="flex-end">
            <Pagination page={page} onChange={(_, pageNumber) => setPage(pageNumber)} count={totalPages} />
          </Box>
        </Stack>
      </Stack>
    </Layout>
  )
}
