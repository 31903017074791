import { Button, Grid, Typography } from "@mui/material"
import { FC, useState, CSSProperties } from "react"
import { useDeleteUser } from "@api/admin/users"
import { useInviteUser } from "@api/userOrganizations"
import { Confirm } from "@components/shared/Confirm"
import { UsersSetttingsDialog } from "@components/shared/UserSettingsDialog"
import { SendDailyDigestModal } from "../SendDailyDigestModal"
import { SubscriptionsModal } from "../SubscriptionsModal"
import { SearchesModal } from "./SearchesModal"

export const UserListItem: FC<{ user: any; sx?: CSSProperties }> = ({ user, sx }) => {
  const [showDeleteUser, setShowDeleteUser] = useState<any | null>(null)
  const [showSubscriptionsModal, setShowSubcriptionsModal] = useState(false)
  const [showSearchesModal, setShowSearchesModal] = useState(false)
  const [openDailyDigestUser, setOpenDailyDigestUser] = useState<any | null>(null)
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const { mutate: inviteUser } = useInviteUser()
  const { mutate: deleteUser } = useDeleteUser()

  return (
    <>
      <Grid container sx={sx}>
        <Grid item xs={1} sm={2} md={4}>
          <Typography variant="body1">{user.email}</Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => inviteUser({ emails: [user.email] })}
            variant={user.been_invited ? "contained" : "outlined"}
            size="small"
            sx={{ marginLeft: 2 }}
          >
            Invite
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => setOpenDailyDigestUser(user)} variant="contained" size="small" sx={{ marginLeft: 2 }}>
            Daily Digest
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setShowDeleteUser(user)}
            variant="outlined"
            color="error"
            size="small"
            sx={{ marginLeft: 2 }}
          >
            Delete User
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setNotificationsOpen(!notificationsOpen)}
            variant="contained"
            size="small"
            sx={{ marginLeft: 2 }}
          >
            Settings
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setShowSubcriptionsModal(true)}
            variant="contained"
            size="small"
            sx={{ marginLeft: 2 }}
          >
            Subscriptions
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => setShowSearchesModal(true)} variant="contained" size="small" sx={{ marginLeft: 2 }}>
            Searches
          </Button>
        </Grid>
        <Confirm
          open={showDeleteUser !== null}
          onClose={() => setShowDeleteUser(null)}
          onSucceed={() => {
            deleteUser({ ids: [showDeleteUser.id] })
            setShowDeleteUser(null)
          }}
          title="Delete User?"
          body={`Are you sure you want to delete ${showDeleteUser?.email}? This action is permanent`}
        />
        {showSubscriptionsModal && (
          <SubscriptionsModal
            open={showSubscriptionsModal}
            onClose={() => setShowSubcriptionsModal(false)}
            user={user}
          />
        )}
        {showSearchesModal && (
          <SearchesModal open={showSearchesModal} onClose={() => setShowSearchesModal(false)} user={user} />
        )}
        {openDailyDigestUser && (
          <SendDailyDigestModal
            open={openDailyDigestUser !== null}
            onClose={() => setOpenDailyDigestUser(false)}
            user={openDailyDigestUser}
          />
        )}
      </Grid>
      {notificationsOpen && (
        <UsersSetttingsDialog
          user={user}
          open={notificationsOpen}
          onClose={() => setNotificationsOpen(false)}
          showCreditLimitUpdate={true}
          adminView={true}
        />
      )}
    </>
  )
}
