import { FC, useState, useEffect } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import moment from "moment"
import { defaultDateRange, defaultLocationFilterParams } from "../../shared/config"
import { DatePicker } from "../../Home/DatePicker"
import { TagsSearchInput } from "../../shared/TagsSearchInput"
import { FilterAutocomplete } from "../../shared/FilterAutocomplete"
import { FilterParams, FilterKeys } from "../../Home/interface"
import { Configuration } from "../../../interfaces/dashboard"
import { DayRangePicker } from "../../shared/DayRangePicker"
import { useToast } from "@src/components/shared/ToastHook"
import { setMaxDateRange } from "@src/functions/common"

export const KeywordsOverTime: FC<{
  setConfiguration: (config: Configuration) => void
  defaultConfig?: Configuration
}> = ({ setConfiguration, defaultConfig }) => {
  let defaultGeoKey
  const [filterParams, setFilterParams] = useState<FilterParams>(
    defaultConfig?.filter_params || defaultLocationFilterParams,
  )
  const [name, setName] = useState(defaultConfig?.name || "")
  const [searchTerms, setSearchTerms] = useState(defaultConfig?.search_terms || [])
  const filterParamsKeys: ("states" | "counties" | "cities" | "organizations")[] = [
    "states",
    "counties",
    "cities",
    "organizations",
  ]
  filterParamsKeys.forEach((key) => {
    if (filterParams[key].length > 0) {
      if (key === "organizations") {
        defaultGeoKey = "channels"
      } else {
        defaultGeoKey = key
      }
    }
  })
  const [geographyType, setGeographyType] = useState<FilterKeys>(defaultGeoKey || "states")
  const [geographies, setGeographies] = useState<number[]>(filterParams[defaultGeoKey || "states"])
  const [dateRange, setDateRange] = useState<[Date, Date]>(
    defaultConfig?.date_range
      ? [moment(defaultConfig.date_range.start).toDate(), moment(defaultConfig.date_range.end).toDate()]
      : defaultDateRange,
  )
  const [xAxisUnit, setXAxisUnit] = useState(defaultConfig?.x_axis_unit || "day")
  const [tagsRef, setTagsRef] = useState(null)
  const [dayRange, setDayRange] = useState(defaultConfig?.day_range || 7)

  const setToast = useToast()

  useEffect(() => {
    const params = { ...filterParams }

    setConfiguration({
      name: name,
      search_terms: searchTerms,
      x_axis_unit: xAxisUnit,
      filter_params: params,
      day_range: dayRange,
      date_range: setMaxDateRange({
        dateRange: dateRange,
        maxRange: undefined,
        handleChange: () =>
          setToast("Invalid Date Range selected. Range must be less than 1 year. ", 5000, undefined, "error"),
      }),
    })
  }, [name, geographyType, dateRange, dayRange, geographies, searchTerms, filterParams, xAxisUnit])

  const handleGeographyTypeChange = (event: SelectChangeEvent) => {
    setGeographyType(event.target.value as FilterKeys)
    setFilterParams(defaultLocationFilterParams)
    setGeographies([])
  }

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} alignItems="center" spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Widget Name"
            fullWidth
            error={!(name && name.length > 0)}
            sx={{ marginTop: 1 }}
          />
        </Grid>
        <Grid item md={2} xs={12} marginTop={1}>
          <DayRangePicker searchDayRange={dayRange} setSearchDayRange={setDayRange} showCustom />
        </Grid>
        <Grid item md={3} xs={12} marginTop={1} sx={dayRange !== "custom" ? { display: "none" } : null}>
          <DatePicker searchDateRange={dateRange} setSearchDateRange={setDateRange} size="lg" />
        </Grid>
      </Grid>
      <Grid item md={2} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="x-axis-select-label">X-Axis Unit</InputLabel>
          <Select
            labelId="x-axis-select-label"
            id="x-axis-select"
            value={xAxisUnit}
            onChange={(event) => setXAxisUnit(event.target.value)}
            label="X-Axis Unit"
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12}>
        <FormControl fullWidth>
          <InputLabel id="geography-type-select-label">Geography Type</InputLabel>
          <Select
            labelId="geography-type-select-label"
            id="geography-type-select"
            value={geographyType}
            onChange={handleGeographyTypeChange}
            label="Geography Type"
          >
            <MenuItem value="states">State</MenuItem>
            <MenuItem value="counties">County</MenuItem>
            <MenuItem value="cities">City</MenuItem>
            <MenuItem value="channels">Channel</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={8} xs={12}>
        <FilterAutocomplete
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          geographyType={geographyType}
        />
      </Grid>
      <Grid item xs={12}>
        <TagsSearchInput
          tags={searchTerms}
          setTags={setSearchTerms}
          setTagsRef={setTagsRef}
          showSubmit
          numberKeywordsToValidate={5}
        />
      </Grid>
    </Grid>
  )
}
