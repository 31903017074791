import moment from "moment"
import { Organization } from "../../../interfaces/organization"

export const publishedAtText = (dateText: string) =>
  `Published on ${new Date(dateText).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  })} by `

export const publishedAtTextUtc = (dateText: string) =>
  `Published on ${new Date(dateText).toLocaleDateString(undefined, {
    timeZone: "UTC",
    year: "numeric",
    month: "short",
    day: "numeric",
  })} by `

export const formatDatetime = (datetime: string, hour = false) => {
  const momentDate = moment(datetime)
  if (momentDate.isValid() === false) {
    return ""
  }
  const format = hour ? "MMMM Do YYYY, h:mm:ss a" : "MMMM Do YYYY"

  return moment(datetime, "YYYY-MM-DDTh:mm:ss").format(format)
}

export const unpackGeo = (organization: Organization) => {
  let stateName = ""
  let countyName = ""
  let cityName = ""
  if (organization.state) {
    stateName = organization.state.name
    if (organization.city) {
      cityName = organization.city.name
    } else if (organization.county) {
      countyName = organization.county.name
    }
  }
  return { stateName, countyName, cityName }
}

export const countyBuilder = (county: string, state: string) => {
  if (state === "Louisiana") {
    return `${county} Parish`
  } else if (state === "Alaska") {
    return `${county} Borough`
  } else {
    return `${county} County`
  }
}

export const locationText = (meeting: { city?: string; county?: string; state?: string }) => {
  let location = ""
  if (meeting.city && meeting.state) {
    location = `${meeting.city}, ${meeting.state}`
  } else if (meeting.county && meeting.state) {
    location = `${countyBuilder(meeting.county, meeting.state)}, ${meeting.state}`
  } else if (meeting.state) {
    location = `${meeting.state}`
  }
  if (location) {
    return location
  } else {
    return null
  }
}
