import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material"
import { PublicBriefing } from "@src/interfaces/briefing"
import { FC } from "react"
import { BriefingContent } from "../BriefingContent"
import { useCloseModalKey, useModalKey, useOpenModalKey } from "@components/shared/OpenModalHook"

export const BriefingActionsDataGridRow: FC<{ briefing: PublicBriefing }> = ({ briefing }) => {
  const briefingModalKey = `${briefing.id} briefing modal`
  const summaryModalKey = `${briefing.id} summary modal`
  const briefingOpened = useModalKey(briefingModalKey)
  const summaryOpened = useModalKey(summaryModalKey)
  const openBriefing = useOpenModalKey(briefingModalKey)
  const openSummary = useOpenModalKey(summaryModalKey)
  const closeBriefing = useCloseModalKey(briefingModalKey)
  const closeSummary = useCloseModalKey(summaryModalKey)

  return (
    <>
      <Stack spacing={1}>
        <Button variant="contained" sx={{ textWrap: "nowrap", width: 110 }} onClick={openBriefing}>
          Show Briefing
        </Button>
        {briefing.summary && (
          <Button variant="contained" sx={{ textWrap: "nowrap", width: 110 }} onClick={openSummary}>
            Show Summary
          </Button>
        )}
        <Dialog open={briefingOpened} onClose={closeBriefing} fullWidth>
          <DialogTitle>{`${briefing.meeting.title} ${briefing.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <BriefingContent promptResult={briefing.result} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeBriefing}>Close</Button>
          </DialogActions>
        </Dialog>
      </Stack>
      {briefing.summary && (
        <Dialog open={summaryOpened} onClose={closeSummary} fullWidth>
          <DialogTitle>{`${briefing.meeting.title} ${briefing.name}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <BriefingContent promptResult={briefing.summary} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSummary}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
