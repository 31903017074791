import { FC, useState, useRef, useMemo, ReactNode } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material"
import { useParams } from "react-router-dom"
import { useFetchAdminRoles } from "../../../../../api/roles"
import { VerifyCsvData } from "./VerifyCsvData"
import { SendInvitesModal } from "../../SendInvitesModal"
import Papa from "papaparse"
import { CsvRow, handleCsvRow, CsvUser } from "./parseRowFunctions"

export const UserOrganizationImportCsv: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const fileInputRef = useRef<null | HTMLInputElement>(null)

  const reset = () => {
    setOpen(false)
    if (fileInputRef.current) {
      fileInputRef.current.value = ""
    }
    setTimeout(() => setActiveStep(0), 200)
  }

  const [csvUsers, setCsvUsers] = useState<CsvUser[]>([])

  const { data: adminRoles } = useFetchAdminRoles()
  const roleMap = useMemo(() => {
    const map = new Map<string, number>()
    adminRoles?.roles.map(({ id, name }: { id: number; name: string }) => map.set(name.toLowerCase(), id))
    return map
  }, [adminRoles])

  const [returnedUsers, setReturnedUsers] = useState<{ id: number; email: string }[]>([])

  let modalContent: ReactNode
  switch (activeStep) {
    case 0:
      modalContent = (
        <VerifyCsvData
          userOrganizationId={userOrganizationId}
          users={csvUsers}
          onSuccess={(data) => {
            setActiveStep(1)
            if (data) {
              setReturnedUsers(data)
            }
          }}
        />
      )
      break
    case 1:
      modalContent = <SendInvitesModal users={returnedUsers} onSuccess={() => setActiveStep(2)} />
      break
    case 2:
      modalContent = (
        <>
          <DialogContent>
            <Typography>Invite(s) sent successfully</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={reset}>Finish</Button>
          </DialogActions>
        </>
      )
      break
    default:
      break
  }

  const handleCsvUpload = async () => {
    if (!fileInputRef.current?.files?.[0]) {
      return
    }
    Papa.parse<CsvRow>(fileInputRef.current?.files[0], {
      header: true,
      transformHeader: (header) => header.replaceAll(" ", "_"), // remove spaces from headers
      complete: (results) => {
        setOpen(true)
        const csvUsersResults = results.data.map((row) => handleCsvRow(row, roleMap))
        setCsvUsers(csvUsersResults)
      },
    })
  }

  return (
    <>
      <Button size="small" variant="contained" onClick={() => fileInputRef.current?.click()}>
        Import CSV
      </Button>
      <TextField
        type="file"
        inputProps={{ ref: fileInputRef }}
        sx={{ display: "none" }}
        onChange={async () => {
          handleCsvUpload()
        }}
      />
      <Dialog open={open} onClose={reset}>
        <DialogTitle>Import from CSV</DialogTitle>
        <DialogContent sx={{ flexShrink: "0" }}>
          <Stepper activeStep={activeStep}>
            <Step completed={activeStep > 0}>
              <StepLabel>Verify CSV Data</StepLabel>
            </Step>
            <Step>
              <StepLabel>Send Invite Emails</StepLabel>
            </Step>
          </Stepper>
        </DialogContent>
        {modalContent}
      </Dialog>
    </>
  )
}
