import { Dispatch, SetStateAction, useState, useEffect } from "react"
import { TablePagination, Container } from "@mui/material"

interface TablePaginationPropsRow<Row extends Record<string, any>> {
  tableData: Row[]
  setPaginatedData: Dispatch<SetStateAction<Row[]>>
}

export const ClientSidePagination = <Row extends Record<string, any>>({
  tableData,
  setPaginatedData,
}: TablePaginationPropsRow<Row>) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const updateDisplay = (page: number, rowsPerPage: number) => {
    const start = page * rowsPerPage
    const end = start + rowsPerPage
    setPaginatedData(tableData.slice(start, end > tableData?.length ? tableData?.length : end))
  }

  useEffect(() => updateDisplay(page, rowsPerPage), [tableData])

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
    updateDisplay(newPage, rowsPerPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    updateDisplay(page, parseInt(event.target.value))
  }

  return (
    <Container maxWidth={false} sx={{ fontSize: "12px" }}>
      <TablePagination
        component="div"
        count={tableData.length || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100, 500]}
        sx={{
          fontSize: "1.25em",
          ".MuiTablePagination-selectLabel": {
            fontSize: "1.25em",
          },
          ".MuiTablePagination-displayedRows": {
            fontSize: "1.25em",
          },
          ".MuiSelect-select": {
            fontSize: "1.25em",
            paddingTop: "10px",
          },
        }}
      />
    </Container>
  )
}
