import { IRole } from "../api/interfaces"
import { formatDate, formatDatetime } from "../components/shared/config"

export interface RoleColumn {
  id: "id" | "created_at" | "updated_at" | "name"
  type: "string" | "number" | "datetime" | "boolean"
  label: string
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  link?: any
}

export const roleColumns: readonly RoleColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "name",
    label: "Name",
    minWidth: 110,
    type: "string",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 170,
    format: formatDate,
    type: "datetime",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
]

export const createRoleRow: Record<any, any> = (role: IRole) => {
  return {
    id: role.id,
    name: role.name,
    created_at: role.created_at,
    updated_at: role.updated_at,
  }
}
