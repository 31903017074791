import { Dispatch, SetStateAction, createContext } from "react"
import { FilterParamsWithoutDates } from "../../Home/interface"

export interface NewCampaign {
  name: string
  emailFrequency: "" | "Daily" | "Weekly" | "Unsubscribed"
  searchTerms: Set<string>
  advancedSearch: Set<string>
  filterParams: FilterParamsWithoutDates
  proximity: number | null
  exclude: boolean
}

export const CampaignWizardContext = createContext<{
  value: NewCampaign
  setValue: Dispatch<SetStateAction<NewCampaign>>
}>({
  value: {
    name: "",
    emailFrequency: "",
    searchTerms: new Set(),
    advancedSearch: new Set(),
    filterParams: {
      cities: [],
      states: [],
      counties: [],
      organizations: [],
      channel_types: [],
      city_population: [-1, -1],
      county_population: [-1, -1],
      city_income: [-1, -1],
      county_income: [-1, -1],
    },
    proximity: null,
    exclude: false,
  },
  setValue: () => {
    /* placeholder for Typescript */
  },
})
