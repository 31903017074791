import { FC } from "react"
import { Grid, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemButton } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip as RechartTooltip } from "recharts"

const goToCampaign = (campaign: any) => {
  const urlSearchParams = new URLSearchParams()
  urlSearchParams.set("filter-params", btoa(JSON.stringify(campaign?.data?.filterParams)))
  urlSearchParams.set("search-terms", encodeURIComponent(campaign?.data?.searchTerms?.toString()))
  urlSearchParams.set("campaign-id", campaign.id || "")
  window.location.href = `?${urlSearchParams}`
}

export const VideoAnalyzedTotalDisplay: FC<{ total: number }> = ({ total }) => (
  <Typography variant="large-number" textAlign="center" component="div">
    {total}
  </Typography>
)

export const TopCampaignsDisplay: FC<{ campaignNames: any[] }> = ({ campaignNames }) => (
  <List>
    {campaignNames.map((value, index) => (
      <ListItem key={value.campaign.id} disablePadding>
        <Tooltip title={`${window.location.href}campaigns/${value?.campaign?.id}`} placement="top">
          <ListItemButton onClick={() => goToCampaign(value.campaign)}>
            <ListItemIcon>
              <Typography
                bgcolor="primary.dark"
                color="common.white"
                width="3ch"
                height="3ch"
                borderRadius="50%"
                fontWeight="600"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {index + 1}
              </Typography>
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h2" color="primary.light">
                {value.campaign.name}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </Tooltip>
      </ListItem>
    ))}
  </List>
)

export const TopKeywordsDisplay: FC<{ data: any[] }> = ({ data }) => (
  <Grid item sx={{ width: "-webkit-fill-available", overflow: "hidden !important" }}>
    <ResponsiveContainer width="100%" height={400}>
      <BarChart layout="vertical" data={data.slice(0, 5)} margin={{ top: 0, right: 0, left: 40, bottom: 0 }}>
        <XAxis type="number" />
        <YAxis type="category" dataKey="search_term" interval={0} />
        <RechartTooltip />
        <Bar dataKey="total_hits" fill="rgba(86, 111, 136, .5)" />
      </BarChart>
    </ResponsiveContainer>
  </Grid>
)

export const TopChannelDisplays: FC<{ data: any[] }> = ({ data }) => (
  <Grid item sx={{ width: "-webkit-fill-available", overflow: "hidden !important" }}>
    <ResponsiveContainer width="100%" height={400}>
      <BarChart layout="vertical" data={data} margin={{ top: 0, right: 0, left: 40, bottom: 0 }}>
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <RechartTooltip />
        <Bar type="number" dataKey="total_hits" fill="rgba(86, 111, 136, .5)" />
      </BarChart>
    </ResponsiveContainer>
  </Grid>
)

export const NothingToDisplay: FC = () => <p>Nothing to Display</p>

export const NoSharesToDisplay: FC = () => (
  <Grid item>
    <Typography variant="body1" gutterBottom>
      You haven&apos;t shared any videos.
    </Typography>
    <br />
    <Typography variant="body2" gutterBottom>
      Spread the word by clicking the &quot;copy link url&quot; button on the next clip that you want to share with your
      team.
    </Typography>
  </Grid>
)
