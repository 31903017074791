import { useQuery } from "react-query"
import { useAuthAxios } from "../../axiosInstance"
import { Job } from "@src/interfaces/content-acquisition/responseObjectInterfaces"
import { CommonUrlParams } from "@src/interfaces/content-acquisition/requestParams"
import { QueryParamsBuilder } from "@src/interfaces/content-acquisition/queryParamsBuilder"

const resourcePath = "/content-acquisition/jobs"

export const useFetchContentAcquisitionJobs = (params?: CommonUrlParams) => {
  const axiosInstance = useAuthAxios()
  const paramBuilder = new QueryParamsBuilder()
  const queryParams = paramBuilder.build(params)
  const uri = `${resourcePath}${queryParams}`

  return useQuery(paramBuilder.toQueryKey("content-acquisition-jobs"), async () => {
    const response = await (await axiosInstance).get<Job[]>(uri)

    return response.data
  })
}
