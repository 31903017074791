import { SearchTextField } from "../../shared/SearchTextField"
import { FormControlLabel, Stack, Checkbox } from "@mui/material"
import { FC, useState, useMemo } from "react"
import { FetchedMonitor, useFetchSignalsMonitors } from "../../../api/admin/signals_monitors"
import { DataTable } from "../../shared/DataTable"
import { signalsColumns as columns } from "./monitorColumns"

const isError = (monitor: FetchedMonitor) => {
  return (
    monitor.signals_projection === null ||
    monitor.signals_status === "internal_server_error" ||
    monitor.signals_status === "campaign_failed" ||
    monitor.delivery_status === "blocked" ||
    (monitor.signals_projection === true && monitor.delivery_status === "not_sent")
  )
}

export const SignalsMonitor: FC = () => {
  const { data: monitorsData } = useFetchSignalsMonitors()
  const [search, setSearch] = useState("")
  const [checked, setChecked] = useState(true)

  const monitors = useMemo(() => {
    return monitorsData?.monitors.filter(
      (monitor) =>
        (monitor.email.includes(search) || monitor.organization.includes(search)) &&
        (!checked || (checked && isError(monitor))),
    )
  }, [monitorsData?.monitors, search, checked])

  return (
    <>
      <Stack direction="row" marginY={2} spacing={3}>
        <SearchTextField
          label="Search Email or Organizations"
          size="small"
          sx={{ width: "33.333%" }}
          setSearch={setSearch}
          search={search}
        />
        <FormControlLabel
          label="Errors Only"
          control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
        />
      </Stack>
      <DataTable virtualize={false} data={monitors} columnKey="id" columns={columns} defaultSort="signals_status" />
    </>
  )
}
