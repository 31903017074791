import { Column, formatYesNo } from "../components/shared/DataTable"

export const columns = (isAdmin: boolean) => {
  const columns: Column[] = [
    { id: "email", label: "Email", headerCellProps: { sx: { minWidth: 110 } } },
    { id: "roles", label: "Role", headerCellProps: { sx: { minWidth: 90 } } },
    {
      id: "requires_signals_notifications",
      label: "Signals Notifications",
      headerCellProps: { sx: { minWidth: 40 } },
      formatter: formatYesNo,
    },
    {
      id: "requires_account_summary_notifications",
      label: "Account Summary Notifications",
      headerCellProps: { sx: { minWidth: 40 } },
      formatter: formatYesNo,
    },
  ]

  if (isAdmin) {
    columns.push({
      id: "creditsUsed",
      label: "Credits Used",
      headerCellProps: { sx: { minWidth: 40 } },
      formatter: (value: number) => value?.toFixed(2),
    })
    columns.push({
      id: "creditLimit",
      label: "Credit Limit",
      headerCellProps: { sx: { minWidth: 40 } },
      formatter: (value: number) => value?.toFixed(2),
    })
  }
  return columns
}
