import { Column, formatDate, formatDateTime, formatYesNo } from "../components/shared/DataTable"

export const columns: Column[] = [
  { id: "id", label: "Id", headerCellProps: { width: 30 } },
  { id: "email", label: "Email", headerCellProps: { sx: { minWidth: 300 } }, typographyProps: { variant: "body2" } },
  {
    id: "user_organization_name",
    label: "User Organization",
    headerCellProps: { sx: { minWidth: 110 } },
    typographyProps: { variant: "body2" },
  },
  { id: "roles", label: "Role", headerCellProps: { sx: { width: 140 } } },
  {
    id: "use_case",
    label: "Use Case",
    headerCellProps: { sx: { minWidth: 80 } },
    typographyProps: { variant: "body2" },
  },
  {
    id: "industry_name",
    label: "Industry",
    headerCellProps: { sx: { minWidth: 80 } },
    typographyProps: { variant: "body2" },
  },
  {
    id: "requires_signals_notifications",
    label: "Signals Notifications?",
    headerCellProps: { sx: { minWidth: 40 } },
    formatter: formatYesNo,
  },
  {
    id: "requires_account_summary_notifications",
    label: "Account Summary Notifications?",
    headerCellProps: { sx: { minWidth: 40 } },
    formatter: formatYesNo,
  },
  { id: "created_at", label: "Created At", headerCellProps: { sx: { minWidth: 170 } }, formatter: formatDate },
  { id: "updated_at", label: "Updated At", headerCellProps: { sx: { minWidth: 170 } }, formatter: formatDateTime },
]
