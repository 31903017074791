import { Stack, Typography } from "@mui/material"
import { FC } from "react"
import { useFetchCurrentUserCreditUsages } from "../../../api/users"

export const Credits: FC = () => {
  const { data: credit_usages } = useFetchCurrentUserCreditUsages()

  return (
    <Stack spacing={2} padding={1}>
      <Typography>{`Credits Used: ${credit_usages?.credits_used.toFixed(2)}`}</Typography>
      <Typography>{`Credit Limit: ${credit_usages?.credit_limit.toFixed(2)}`}</Typography>
    </Stack>
  )
}
