import { FC, useState } from "react"
import { Button, Grid, Menu, MenuItem } from "@mui/material"
import { Confirm } from "../../shared/Confirm"
import { capitalizeWord } from "../../shared/config"
import { EditUserModal } from "./EditUserModal"
import { UpdateRoleMenuItem, UpdateRoleDialog } from "./UpdateRole"
import { UpdateAboutModal } from "./UpdateAboutModal"
import { SendBriefingModal } from "./SendBriefingModal"

export const ActionsMenu: FC<{
  ids: number[]
  onActionComplete: any
  newRecord: any
  updateRecord: any
  deleteRecord: any
  recordName: string
  records: any
}> = ({ ids, onActionComplete, deleteRecord, recordName, records }) => {
  const disableActions = ids.length === 0

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openNewEditModal, setOpenNewEditModal] = useState("")

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenConfirm(false)
    setOpenNewEditModal("")
  }

  const onComplete = () => {
    onActionComplete()
    handleClose()
  }

  const selectedRecord = ids.length === 1 ? records.find((r: any) => r.id === ids[0]) : undefined

  return (
    <Grid>
      <Button
        id="actions-menu-button"
        aria-controls="actions-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="medium"
        variant="outlined"
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "actions-menu-button" }}
      >
        <MenuItem onClick={() => setOpenNewEditModal("new")} disabled={!disableActions}>
          New {capitalizeWord(recordName)}
        </MenuItem>
        <MenuItem onClick={() => setOpenNewEditModal("update")} disabled={ids.length !== 1}>
          Update {capitalizeWord(recordName)}
        </MenuItem>
        <UpdateRoleMenuItem closeMenu={handleClose} />
        <MenuItem onClick={() => setOpenNewEditModal("updateAbout")} disabled={ids.length !== 1}>
          Update About
        </MenuItem>
        <MenuItem onClick={() => setOpenConfirm(true)} disabled={disableActions}>
          Delete {capitalizeWord(recordName)}
          (s)
        </MenuItem>
        <MenuItem onClick={() => setOpenNewEditModal("sendBriefing")} disabled={ids.length !== 1}>
          Send Briefing
        </MenuItem>
      </Menu>
      <Confirm
        onSucceed={() => {
          deleteRecord({ ids })
          onComplete()
        }}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
        title={`Delete ${recordName}(s)`}
        body={`Are you sure you want to delete these ${recordName}(s)? This action is irreversible.`}
      />
      {ids.length === 0 && (
        <EditUserModal
          open={openNewEditModal === "new"}
          onClose={handleClose}
          selectedUser={selectedRecord}
          onActionComplete={onComplete}
          formType="New"
        />
      )}
      {ids.length === 1 && (
        <EditUserModal
          open={openNewEditModal === "update"}
          onClose={handleClose}
          selectedUser={selectedRecord}
          onActionComplete={onComplete}
          formType="Update"
        />
      )}
      <UpdateRoleDialog />
      <UpdateAboutModal handleClose={handleClose} open={openNewEditModal === "updateAbout"} />
      <SendBriefingModal handleClose={handleClose} open={openNewEditModal === "sendBriefing"} />
    </Grid>
  )
}
