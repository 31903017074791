const controlConfig: any = {
  states: [
    ["counties", "state_id"],
    ["cities", "state_id"],
    ["organizations", "state"],
    ["channel_types", "state"],
  ],
  counties: [["organizations", "county"]],
  cities: [["organizations", "city"]],
}

export class FilterParamsController {
  chosenType
  newFilterParams
  oldFilterParams
  newValue
  geographiesData
  organizationsData
  sortedOptions

  constructor(
    chosenType: string,
    oldFilterParams: any,
    newValue: any,
    geographiesData: any,
    organizationsData: any,
    sortedOptions: any,
  ) {
    this.chosenType = chosenType
    this.oldFilterParams = oldFilterParams
    this.newFilterParams = { ...oldFilterParams }
    this.newValue = newValue
    this.geographiesData = geographiesData
    this.organizationsData = organizationsData
    this.sortedOptions = sortedOptions
  }

  handleGeographySelection = () => {
    this.newFilterParams[this.chosenType] = this.newValue
    if (this.newValue[this.newValue.length - 1] === -2) {
      this.newFilterParams[this.chosenType] = []
      this.selectAll()
    } else if (this.newValue.length === 0) {
      // user cleared geography filter
      controlConfig[this.chosenType].forEach((config: string[]) => (this.newFilterParams[config[0]] = []))
    } else {
      this.selectOrDeselectOne()
    }
    return this.newFilterParams
  }

  handleTypeSelection = () => {
    this.newFilterParams[this.chosenType] = this.newValue
    const permissibleOrgs: number[] = []

    this.organizationsData["organizations"].forEach((org: any) => {
      if (
        this.newFilterParams["organizations"].includes(org.id) &&
        this.newFilterParams[this.chosenType].includes(org?.type?.id)
      ) {
        permissibleOrgs.push(org.id)
      }
    })
    this.newFilterParams["organizations"] = permissibleOrgs

    return this.newFilterParams
  }

  selectAll = () => {
    if (this.chosenType === "organizations") {
      this.organizationsData[this.chosenType].forEach((geo: any) => {
        this.newFilterParams[this.chosenType].push(geo.id)
        this.handleContainedSelections(true, geo.id)
      })
    } else {
      // flat because sortedOptions = {state: [{id: 1, county_id: 2, etc.}, .etc], etc.}
      const options: any = this.sortedOptions ? Object.values(this.sortedOptions).flat(1) : []
      this.geographiesData[this.chosenType].forEach((geo: any) => {
        if (this.chosenType === "states" || options.some((opt: any) => opt.id === geo.id)) {
          this.newFilterParams[this.chosenType].push(geo.id)
        }
        this.handleContainedSelections(true, geo.id)
      })
    }
  }

  selectOrDeselectOne = () => {
    if (this.newValue.length > this.oldFilterParams[this.chosenType].length) {
      // added one
      const addedGeo: number[] = this.newValue.filter(
        (geoId: number) => !this.oldFilterParams[this.chosenType].includes(geoId),
      )
      this.handleContainedSelections(true, addedGeo[0])
    } else {
      //subtracted one
      const addedGeo: number[] = this.oldFilterParams[this.chosenType].filter(
        (geoId: number) => !this.newValue.includes(geoId),
      )
      this.handleContainedSelections(false, addedGeo[0])
    }
  }

  handleContainedSelections: any = (isAddOperation: boolean, changedGeographyId: any) => {
    const selectedTypeSet: Set<any> = new Set(this.newFilterParams[this.chosenType])
    if (isAddOperation) {
      controlConfig[this.chosenType].forEach((childType: any) => {
        this.removeAllNotBelongingToGeo(childType[0], childType[1], selectedTypeSet)
      })
    } else {
      // subtract by state
      controlConfig[this.chosenType].forEach((childType: any) => {
        this.removeAllBelongingToGeo(childType[0], childType[1], changedGeographyId, selectedTypeSet)
      })
    }
  }

  removeAllBelongingToGeo = (
    childType: string,
    parentIdAttribute: string,
    changedGeographyId: number,
    selectedParentData: any,
  ) => {
    let unSelectedIds: Set<string>
    const selectedIds: number[] = []
    if (childType === "channel_types") {
      const permissibleOrgs = this.organizationsData["organizations"].filter((org: any) => {
        if (!org[parentIdAttribute]) {
          return false
        }

        return selectedParentData.has(org[parentIdAttribute].id)
      })
      const permissibleTypeIds = permissibleOrgs.map((org: any) => org?.type?.id)
      this.oldFilterParams["channel_types"].forEach((channelId: number) => {
        if (permissibleTypeIds.includes(channelId)) {
          selectedIds.push(channelId)
        }
      })
    } else {
      if (childType === "organizations") {
        unSelectedIds = new Set(
          this.organizationsData[childType]
            .filter((org: any) => org[parentIdAttribute]?.id === changedGeographyId)
            .map((org: any) => org.id),
        )
      } else {
        unSelectedIds = new Set(
          this.geographiesData[childType]
            .filter((geo: any) => geo[parentIdAttribute] === changedGeographyId)
            .map((geo: any) => geo.id),
        )
      }

      this.newFilterParams[childType].forEach((id: any) => {
        if (!unSelectedIds.has(id)) {
          selectedIds.push(id)
        }
      })
    }

    this.newFilterParams[childType] = selectedIds
  }

  removeAllNotBelongingToGeo = (childType: string, parentIdAttribute: string, selectedParentData: any) => {
    const newFilter: any = [] //build new selections from nothing thereby removing any duplicates
    this.newFilterParams[childType]?.forEach((c: any) => {
      // remove if not belonging to parent
      let child

      if (childType === "organizations") {
        child = this.organizationsData[childType].find((geo: any) => geo.id === c)
      } else if (childType === "channel_types") {
        const permissibleOrgs = this.organizationsData["organizations"].filter((org: any) => {
          if (!org[parentIdAttribute]) {
            return false
          }

          return selectedParentData.has(org[parentIdAttribute].id)
        })
        const permissibleTypeIds = permissibleOrgs.map((org: any) => org?.type?.id)
        if (permissibleTypeIds.includes(c)) {
          newFilter.push(c)
        }
      } else {
        child = this.geographiesData[childType].find((geo: any) => geo.id === c)
      }
      if (
        child &&
        (childType === "organizations"
          ? selectedParentData.has(child[parentIdAttribute]?.id)
          : selectedParentData.has(child[parentIdAttribute]))
      ) {
        newFilter.push(child?.id)
      }
    })

    this.newFilterParams[childType] = newFilter
  }
}
