import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import { FC } from "react"
import { widgetSelectableDisplayConfig } from "../../../config/dashboardConfig"

export const SelectableWidgetWrapper: FC<{
  type: any
  setWidgetType: any
}> = ({ type, setWidgetType }) => {
  const config: any = widgetSelectableDisplayConfig[type]

  return (
    <Card sx={{ width: 300 }} raised>
      <CardActionArea
        onClick={() => setWidgetType(type)}
        sx={{
          height: "100%",
          ".MuiCardActionArea-focusHighlight": {
            backgroundColor: "white",
          },
        }}
      >
        <CardMedia
          component="img"
          image={config.image}
          height={160}
          sx={{ width: "100%", borderBottom: 1, borderStyle: "solid", borderColor: "neutral.dark" }}
        />
        <CardContent>
          <Typography variant="h6">{config.title}</Typography>
          <Typography variant="body2">{config.description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
