import { FC, useState } from "react"
import {
  Link as MuiLink,
  Table as MTable,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  TableSortLabel,
  TableFooter,
  TablePagination,
} from "@mui/material"
import { Link } from "react-router-dom"
import Loading from "./Loading"
import { PaginatedTableActions } from "./PaginatedTableActions"

type Order = "asc" | "desc"

const stableSort = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: any) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

const descendingComparator = (a: any, b: any, orderBy: any) => {
  if (a[orderBy] === b[orderBy]) {
    return 0
  } else if (a[orderBy] === undefined || a[orderBy] === null) {
    return -1
  } else if (b[orderBy] === undefined || b[orderBy] === null) {
    return 1
  } else {
    return a[orderBy] < b[orderBy] ? 1 : -1
  }
}

const getComparator = (order: Order, orderBy: any) => {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

export const PaginatedTable: FC<{
  data: any[]
  selected: number[]
  setSelected: any
  createRow: any
  columns: any
  sortKey?: string
  sortDirection?: Order
  showCheckbox?: boolean
  page: number
  setPage: any
  totalRecords: number
  perPage?: number
}> = ({
  data,
  selected,
  setSelected,
  createRow,
  columns,
  totalRecords,
  sortKey = "id",
  sortDirection = "desc",
  showCheckbox = true,
  perPage = 25,
  page,
  setPage,
}) => {
  const [order, setOrder] = useState<Order>(sortDirection)
  const [orderBy, setOrderBy] = useState(sortKey)
  const [rowsPerPage, setRowsPerPage] = useState(perPage)

  if (data === undefined) {
    return <Loading />
  }

  const rows = data?.map((rowData: any) => createRow(rowData))

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property)
  }

  const handleCheckClicked = (id: number) => {
    const selectedCopy = [...selected]
    const selectedIndex = selectedCopy.indexOf(id)
    if (selectedIndex === -1) {
      selectedCopy.push(id)
      setSelected(selectedCopy)
    } else {
      selectedCopy.splice(selectedIndex, 1)
      setSelected(selectedCopy)
    }
  }

  const handleCheckAllClicked = () => {
    if (selected.length === rows.length) {
      setSelected([])
    } else {
      const allIds = rows.map((row: any) => row.id)
      setSelected(allIds)
    }
  }

  return (
    <TableContainer>
      <MTable size="small">
        <TableHead>
          <TableRow>
            {showCheckbox && (
              <TableCell>
                <Checkbox
                  color="primary"
                  checked={rows.length > 0 && selected.length === rows.length}
                  onChange={handleCheckAllClicked}
                  inputProps={{
                    "aria-label": "select all rows",
                  }}
                />
              </TableCell>
            )}
            {columns.map((column: any) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                sortDirection={orderBy === column.id ? order : false}
              >
                <TableSortLabel active={orderBy === column.id} direction={order} onClick={createSortHandler(column.id)}>
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .slice([page * perPage, page * perPage + perPage])
            .map((row: any) => {
              return (
                <TableRow hover key={row.id}>
                  {showCheckbox && (
                    <TableCell key={`checkbox-${row.id}`}>
                      <Checkbox checked={selected.indexOf(row.id) !== -1} onChange={() => handleCheckClicked(row.id)} />
                    </TableCell>
                  )}
                  {columns.map((column: any) => {
                    const value = row[column.id]
                    return column.action ? (
                      <TableCell key={column.id} align={column.align}>
                        <Button onClick={column.action}>{column.label}</Button>
                      </TableCell>
                    ) : column.link !== undefined ? (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {column.link === "externalUrl" ? (
                          <MuiLink key={column.id} href={value} target="_blank">
                            {column.format ? column.format(value) : value}
                          </MuiLink>
                        ) : (
                          <Link key={column.id} to={`${column.link}/${row.id}`} style={{ textDecoration: "none" }}>
                            {column.format ? column.format(value) : value}
                          </Link>
                        )}
                      </TableCell>
                    ) : (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[perPage]}
              count={totalRecords}
              colSpan={4}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{ inputProps: { "aria-label": "rows per page" }, native: true }}
              onPageChange={(_event, number) => setPage(number)}
              onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              ActionsComponent={PaginatedTableActions}
            />
          </TableRow>
        </TableFooter>
      </MTable>
    </TableContainer>
  )
}
