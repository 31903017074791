import { FC } from "react"
import { TableRow, TableCell, Link as MuiLink } from "@mui/material"
import { Link } from "react-router-dom"
import { meetingLocationString } from "../shared/config"
import { OpportunityIndexResult } from "@src/api/opportunities"
import { FetchedMeeting } from "@src/api/meetings"

interface OpportunityTableRowProps {
  showIndustry: boolean
  opportunity: OpportunityIndexResult
}

export const OpportunityTableRow: FC<OpportunityTableRowProps> = ({ showIndustry, opportunity }) => {
  let lastSpaceIndex = 49
  for (let i = 0; i < 50; i += 1) {
    if (opportunity.description[i] === " ") {
      lastSpaceIndex = i
    }
  }
  const truncatedDescription = `${opportunity.description.slice(0, lastSpaceIndex)}...`

  return (
    <TableRow key={opportunity.id}>
      <TableCell>
        <MuiLink component={Link} to={`/meetings/${opportunity.meeting_id}`}>
          {opportunity.name}
        </MuiLink>
      </TableCell>
      <TableCell sx={{ display: { md: "none" } }}>{truncatedDescription}</TableCell>
      <TableCell sx={{ display: { xs: "none", md: "table-cell" } }}>{opportunity.description}</TableCell>
      <TableCell>{opportunity.actionability}</TableCell>
      {showIndustry ? (
        <TableCell>{opportunity.naics_code.industries?.map((industry) => industry.name).join(", ")}</TableCell>
      ) : (
        <TableCell>{`${opportunity.naics_code.code}: ${opportunity.naics_code.title}`}</TableCell>
      )}
      <TableCell>{opportunity.actionable_date}</TableCell>
      <TableCell>{opportunity.key_decision_maker}</TableCell>
      <TableCell>{new Date(opportunity.published_at).toLocaleDateString()}</TableCell>
      <TableCell>
        <MuiLink component={Link} to={`/channel-details/${opportunity.channel_id}`}>
          {opportunity.channel_name}
        </MuiLink>
      </TableCell>
      <TableCell>
        {meetingLocationString({
          state: opportunity.state_name,
          county: opportunity.county_name,
          city: opportunity.city_name,
        } as FetchedMeeting)}
      </TableCell>
      <TableCell>{new Date(opportunity.created_at).toLocaleDateString()}</TableCell>
    </TableRow>
  )
}
