import { FC, useState } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { useFetchOrganizationsCampaigns } from "@api/campaigns"
import { useAddRecommendedCampaign, useRemoveRecommendedCampaign } from "@api/admin/industries"
import { AdminFetchedIndustry, FetchedIndustryRecommendedCampaign } from "@interfaces/industry"
import { useCloseModalKey, useData, useModalKey } from "@src/components/shared/OpenModalHook"

export const RecommendedCampaignsModalKey = "RecommendedCampaignsModal"

export const RecommendedCampaignsModal: FC = () => {
  const open = useModalKey(RecommendedCampaignsModalKey)
  const modalData: AdminFetchedIndustry | undefined = useData()
  const closeModal = useCloseModalKey(RecommendedCampaignsModalKey)
  const [openForm, setOpenForm] = useState(false)
  const [useCase, setUseCase] = useState("")
  const [campaignId, setCampaignId] = useState(0)
  const { data: campaigns } = useFetchOrganizationsCampaigns()
  const { mutate: addRecommendedCampaign } = useAddRecommendedCampaign()
  const { mutate: removeRecommendedCampaign } = useRemoveRecommendedCampaign()

  const handleCloseModal = () => {
    setOpenForm(false)
    closeModal()
  }

  return (
    <Dialog open={open} onClose={handleCloseModal} fullWidth>
      <DialogTitle>Recommended Campaigns</DialogTitle>
      <DialogContent>
        <Grid container marginBottom={1} spacing={2} direction="column">
          {modalData?.recommended_campaigns?.map((recommendedCampaign: FetchedIndustryRecommendedCampaign) => (
            <Grid item container key={recommendedCampaign.id} alignItems="center">
              <Typography variant="h6">
                {modalData?.campaigns?.find((campaign) => campaign.id === recommendedCampaign.campaign.id)?.name}
              </Typography>
              <Typography variant="body1">
                &nbsp;
                {`(${recommendedCampaign.use_case})`}
              </Typography>
              <Button
                onClick={() => {
                  removeRecommendedCampaign({
                    recommendedCampaignId: recommendedCampaign.id,
                    industryId: modalData.id,
                  })
                }}
              >
                Remove
              </Button>
            </Grid>
          ))}
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button onClick={() => setOpenForm(!openForm)}>Add Recommended Campaign</Button>
          </Grid>
          <Grid hidden={openForm === false} item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="campaign-id-label">Select the Campaign</InputLabel>
                  <Select
                    value={campaignId.toString()}
                    onChange={(event: SelectChangeEvent) => setCampaignId(parseInt(event.target.value))}
                    labelId="campaign-id-label"
                    label="Select The Campaign"
                  >
                    {campaigns?.campaigns
                      ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((channel_type) => (
                        <MenuItem key={channel_type.id} value={channel_type.id}>
                          {channel_type.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="use-case-label">Use Case</InputLabel>
                  <Select
                    value={useCase}
                    onChange={(event: SelectChangeEvent) => setUseCase(event.target.value)}
                    labelId="use-case-label"
                    label="Use Case"
                  >
                    {modalData?.use_cases?.map((use_case: string) => (
                      <MenuItem key={use_case} value={use_case}>
                        {use_case}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  onClick={() =>
                    addRecommendedCampaign({
                      industry_id: modalData?.id,
                      campaign_id: campaignId,
                      use_case: useCase,
                    })
                  }
                  disabled={campaignId === 0 || useCase === ""}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
