import { FC, useState, useContext, useEffect } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  FormLabel,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { useFetchUsers, useUpdateUsersNotifications } from "../../api/users"
import { useFetchRoles } from "../../api/roles"
import { UserContext } from "../../pages/UsersPage"
import { useToast } from "../shared/ToastHook"
import { useModalKey, useOpenModalKey, useCloseModalKey } from "../shared/OpenModalHook"

export const updateNotificationsModalKey = "UPDATE_NOTIFICATIONS"

export const UpdateNotificationsMenuItem: FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const contextData = useContext(UserContext)
  const { ids } = contextData
  const { data: rolesData } = useFetchRoles()
  const openModalKey = useOpenModalKey(updateNotificationsModalKey)
  return (
    <MenuItem
      onClick={() => {
        openModalKey()
        closeMenu()
      }}
      disabled={!ids.length || !rolesData || !rolesData.length}
    >
      Update Notifications
    </MenuItem>
  )
}

export const UpdateNotifications: FC = () => {
  const contextData = useContext(UserContext)
  const { ids, reset } = contextData
  const open = useModalKey(updateNotificationsModalKey)
  const closeModalKey = useCloseModalKey(updateNotificationsModalKey)
  const setToast = useToast()
  const { data: users } = useFetchUsers()
  const selectedUsers = users?.filter((user) => ids.includes(user.id))
  const userEmails = selectedUsers?.map((user) => user.email)

  const [signalsChecked, setSignalsChecked] = useState(false)
  const [accountSummaryChecked, setAccountSummaryChecked] = useState(false)

  useEffect(() => {
    if (selectedUsers) {
      if (selectedUsers.filter((user) => user.requires_signals_notifications).length > 0) {
        setSignalsChecked(true)
      }
      if (selectedUsers.filter((user) => user.requires_account_summary_notifications).length > 0) {
        setAccountSummaryChecked(true)
      }
    }
  }, [ids])

  const close = () => {
    closeModalKey()
    reset()
  }

  const { mutate: updateNotifications, isLoading } = useUpdateUsersNotifications(() => {
    setToast("Successfully updated user notifications", 3000, <TaskAltIcon />)
    close()
  })

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle>{"Update Notifications"}</DialogTitle>
      <DialogContent>
        <DialogContentText>This will affect the following users:</DialogContentText>
        <List>
          {userEmails?.map((email) => (
            <ListItem key={email}>
              <ListItemText>{email}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogContent sx={{ flexShrink: "0" }}>
        <FormLabel>Assigned Roles:</FormLabel>
        <FormGroup sx={{ marginLeft: 2 }}>
          <FormControlLabel
            key="signals"
            control={<Switch checked={signalsChecked} onChange={() => setSignalsChecked(!signalsChecked)} />}
            label="Signals Emails"
            labelPlacement="start"
            style={{ justifyContent: "flex-end", marginLeft: 0 }}
          />
          <FormControlLabel
            key="accountSummary"
            control={
              <Switch
                checked={accountSummaryChecked}
                onChange={() => setAccountSummaryChecked(!accountSummaryChecked)}
              />
            }
            label="Account Summary Emails"
            labelPlacement="start"
            style={{ justifyContent: "flex-end", marginLeft: 0 }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            updateNotifications({
              ids: ids,
              requires_signals_notifications: signalsChecked,
              requires_account_summary_notifications: accountSummaryChecked,
            })
          }}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Confirm"}
        </Button>
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
