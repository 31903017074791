import { FC } from "react"
import { Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { useFetchUserSearches } from "../../../../api/users"
import Loading from "../../../shared/Loading"
import { SearchLog } from "../../../../api/interfaces"

export const SearchesModal: FC<{
  open: boolean
  onClose: any
  user: any
}> = ({ open, onClose, user }) => {
  const { data: userSearches } = useFetchUserSearches(user.id)

  if (userSearches === undefined) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <Loading />
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{`${user.email}'s Searches`}</DialogTitle>
      <DialogContent>
        <Grid container marginTop={2} spacing={2} direction="column">
          <Typography paddingLeft={2} variant="h6">
            {`Last ${userSearches.searches.length} Searches`}
          </Typography>
          {userSearches.searches.map((search: SearchLog) => (
            <Grid item key={search.id}>
              <Typography variant="body2">{search.data.searchTerms.join(", ")}</Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
