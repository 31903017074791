import { FC } from "react"
import { Box, Grid, Typography } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, EffectCoverflow } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/effect-coverflow"

export const MeetingsSharedDisplay: FC<{ meetings: any }> = ({ meetings }) => {
  return (
    <Grid
      container
      item
      sx={{ display: "inline !important", width: "95%", height: 240 }}
      alignSelf="center"
      direction="column"
    >
      <Swiper
        effect="coverflow"
        spaceBetween={20}
        slidesPerView={2}
        navigation
        centeredSlides
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[Navigation, EffectCoverflow]}
      >
        {meetings?.meetings.map((meeting: any) => {
          return (
            <Grid item key={meeting?.id}>
              <SwiperSlide onClick={() => (window.location.href = `meetings/${meeting?.id}`)}>
                <Box
                  component="img"
                  sx={{ width: "100%", height: 130 }}
                  src={meeting?.thumbnail_url || "/no-video.png"}
                />
                <Typography
                  sx={{ overflow: "hidden !important" }}
                  maxHeight={50}
                  padding={1}
                  variant="body2"
                  color="text.secondary"
                >
                  {meeting?.title}
                </Typography>
              </SwiperSlide>
            </Grid>
          )
        })}
      </Swiper>
    </Grid>
  )
}
