import { Chip, Skeleton } from "@mui/material"
import { FC } from "react"
import { useHits } from "../../../api/search"
import { FilterParams } from "../../Home/interface"

export const TermChipBase: FC<{
  term: string
  filterParams: FilterParams
  searchDateRange: [Date, Date]
  onDelete?: () => void
}> = ({ term, filterParams, searchDateRange, onDelete }) => {
  const { data } = useHits(term, filterParams, searchDateRange)
  return (
    <Chip
      key={term}
      label={term}
      onDelete={onDelete}
      icon={<Chip label={data ?? <Skeleton width="1ch" />} size="small" />}
    />
  )
}
