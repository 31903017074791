import { FC, useState, useEffect } from "react"
import { Button, Box, Typography } from "@mui/material"
import { useLocation, useHistory } from "react-router-dom"
import { useIsSalesforceConnected, useConnectSalesforce } from "../../../api/account"

function dec2hex(dec: number) {
  return ("0" + dec.toString(16)).slice(-2)
}

function generateRandomString() {
  const array = new Uint32Array(56 / 2)
  window.crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join("")
}

function sha256(plain: string) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest("SHA-256", data)
}

// base64url is different from base64 and needs to be implemented manually
function base64urlencode(a: ArrayBuffer) {
  let str = ""
  const bytes = new Uint8Array(a)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i])
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")
}

async function challenge_from_verifier(v: string) {
  const hashed = await sha256(v)
  const base64encoded = base64urlencode(hashed)
  return base64encoded
}

export const SalesforceButton: FC = () => {
  const history = useHistory()
  const { data: salesforceConnected, isLoading } = useIsSalesforceConnected()
  const { mutate } = useConnectSalesforce(() => {
    window.localStorage.removeItem("verifier")
    history.replace({ search: "" })
  })
  const currentParams = new URLSearchParams(useLocation().search)
  const [challenge, setChallenge] = useState("")

  useEffect(() => {
    const code = currentParams.get("code")
    const storedVerifier = window.localStorage.getItem("verifier")
    if (code && storedVerifier) {
      // for the backend to get an auth token, we need to send it the auth code from salesforce
      // and the verifier we used to generate the challenge we sent
      mutate({ code, verifier: storedVerifier })
    } else {
      const setChallengeFunction = async () => {
        const verifierString = generateRandomString()
        // I need to set the verifier in local storage because we need to navigate to salesforce
        // and leaving the app causes the state to be lost but local storage is safe
        window.localStorage.setItem("verifier", verifierString)
        setChallenge(await challenge_from_verifier(verifierString))
      }
      setChallengeFunction()
    }
  }, [])

  const salesforceParams = new URLSearchParams()
  // normally it might be better to programmatically determine current url and simply return there
  // but salesforce only accepts predefined redirect_uri paths so we must hard code it below
  salesforceParams.set("redirect_uri", `${window.location.origin}/account/salesforce`)
  salesforceParams.set("client_id", process.env.REACT_APP_SALESFORCE_CLIENT_ID || "")
  salesforceParams.set("response_type", "code")
  salesforceParams.set("code_challenge", challenge)

  if (isLoading) {
    return null
  }

  if (salesforceConnected) {
    return <Typography>{`Connected to Salesforce instance at ${salesforceConnected}`}</Typography>
  }

  return (
    <Box>
      <Button
        variant="contained"
        component="a"
        sx={{ "&:hover": { color: "#ffffff" } }}
        href={`https://login.salesforce.com/services/oauth2/authorize?${salesforceParams.toString()}`}
        // need to do this so typescript isn't upset
        color={"salesforce" as "primary"}
        startIcon={
          <Box position="relative" width={20}>
            <Box
              sx={{
                backgroundColor: "white",
                width: 40,
                height: 40,
                borderRadius: "50%",
                position: "absolute",
                top: -20,
                left: -16,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                width="90%"
                src="https://storage.googleapis.com/cloverleaf-ai-public-assets/Salesforce_Corporate_Logo_RGB.png"
                position="relative"
                top="4%"
              />
            </Box>
          </Box>
        }
        size="large"
      >
        Connect to Salesforce
      </Button>
    </Box>
  )
}
