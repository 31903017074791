import { Dispatch, FC, SetStateAction } from "react"
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { countyStateSelectorConfig } from "@components/shared/config"
import { GeographySelector } from "../../shared/Selectors/Single/Geographies"
import { useFetchGeographiesAdmin } from "@api/admin/geographies"
import { IOrganizationState } from "@interfaces/organization"
import { unpackSetters } from "../../shared/unpackSetters"
import { useFetchChannelTypes } from "@api/channel_types"
import { useFetchOrganizationMetadata } from "@api/organizations"

export const TableFilters: FC<{
  organizationState: IOrganizationState
  setOrganizationState: Dispatch<SetStateAction<IOrganizationState>>
}> = ({ organizationState, setOrganizationState }) => {
  const {
    setNameFilter,
    setFilterParams,
    setAutoUpdateFilter,
    setSpamEnabledFilter,
    setArchiveTypeFilter,
    setChannelTypeFilter,
    setArchiveStatusFilter,
    setVideoArchiveIdFilter,
    setProxyShuffleFilter,
    setOppAnalysisEnabledFilter,
  } = unpackSetters(organizationState, setOrganizationState)

  const { data: geographiesData } = useFetchGeographiesAdmin()
  const { data: channelTypes } = useFetchChannelTypes()
  const { data: orgMetadataData } = useFetchOrganizationMetadata()

  const onNameFilterChange = (event: any) => setNameFilter(event.target.value)
  const onVideoArchiveIdFilterChange = (event: any) => setVideoArchiveIdFilter(event.target.value)

  return (
    <Grid container spacing={1} paddingRight={2}>
      <Grid item xs={12}>
        <TextField
          id="name-filter-search"
          value={organizationState.nameFilter}
          onChange={onNameFilterChange}
          label="Filter By Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="domain-filter-search"
          value={organizationState.videoArchiveIdFilter}
          onChange={onVideoArchiveIdFilterChange}
          label="Filter by Domain"
          fullWidth
        />
      </Grid>
      <Grid item container xs={12} spacing={1}>
        {countyStateSelectorConfig.map((geographySelector: any) => (
          <Grid item xs={12} sm={6} key={geographySelector.type}>
            <GeographySelector
              options={geographiesData}
              label={geographySelector.label}
              type={geographySelector.type}
              filterParams={organizationState.filterParams}
              setFilterParams={setFilterParams}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="auto-update-select-label">Auto Update</InputLabel>
            <Select
              id="auto-update-select"
              value={organizationState.autoUpdateFilter}
              onChange={(event: any) => setAutoUpdateFilter(event.target.value)}
              label="Auto Update"
              labelId="auto-update-select-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              <MenuItem key="true" value="true">
                On
              </MenuItem>
              <MenuItem key="false" value="false">
                Off
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="archive-status-select-label">Archive Status</InputLabel>
            <Select
              id="archive-status-select"
              value={organizationState.archiveStatusFilter}
              onChange={(event: any) => setArchiveStatusFilter(event.target.value)}
              label="Archive Status"
              labelId="archive-status-select-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              <MenuItem key="active" value="active">
                Active
              </MenuItem>
              <MenuItem key="inactive" value="inactive">
                Inactive
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="spam-enabled-select-label">Spam Enabled</InputLabel>
            <Select
              id="spam-enabled-select"
              value={organizationState.spamEnabledFilter}
              onChange={(event: any) => setSpamEnabledFilter(event.target.value)}
              label="Spam Enabled"
              labelId="spam-enabled-select-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              <MenuItem key="true" value="true">
                On
              </MenuItem>
              <MenuItem key="false" value="false">
                Off
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="video-archive-type-select-label">Video Archive Type</InputLabel>
            <Select
              id="video-archive-type-select"
              value={organizationState.archiveTypeFilter}
              onChange={(event: any) => setArchiveTypeFilter(event.target.value)}
              label="Video Archive Type"
              labelId="video-archive-type-select-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              {orgMetadataData?.video_archive_type?.map((archive_type) => (
                <MenuItem key={archive_type.id} value={archive_type.value}>
                  {archive_type.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="channel-type-label">Channel Type</InputLabel>
            <Select
              id="channel-type-select"
              value={organizationState.channelTypeFilter}
              onChange={(event: any) => setChannelTypeFilter(event.target.value)}
              label="Channel Type"
              labelId="channel-type-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              {channelTypes?.map((channelType: any) => (
                <MenuItem key={channelType.id} value={channelType.id}>
                  {channelType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel id="spam-enabled-select-label">Proxy Shuffle</InputLabel>
            <Select
              id="proxy-shufle-select"
              value={organizationState.proxyShuffleFilter}
              onChange={(event) => setProxyShuffleFilter(event.target.value)}
              label="Proxy Shuffle"
              labelId="proxy-shufle-select-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              <MenuItem key="true" value="true">
                On
              </MenuItem>
              <MenuItem key="false" value="false">
                Off
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Opportunity Analysis</InputLabel>
            <Select
              id="opp-analysis-enabled-select"
              value={organizationState.oppAnalysisEnabledFilter}
              onChange={(event) => setOppAnalysisEnabledFilter(event.target.value)}
              label="Opportunity Analysis"
              labelId="opp-analysis-enabled-select-label"
            >
              <MenuItem key="none" value="">
                None
              </MenuItem>
              <MenuItem key="true" value="true">
                Enabled
              </MenuItem>
              <MenuItem key="false" value="false">
                Disabled
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
