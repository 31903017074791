import { ClipShare, ClipType } from "../../../interfaces/clipShare"
import { SearchOptions } from "../../../interfaces/search"

export const getShareLink = (clipData?: ClipShare, meetingId?: number) => {
  let input
  const searchParams = new URLSearchParams(window.location.search)
  if (clipData?.transcript_id) {
    searchParams.set("type", "clip")
    searchParams.set("transcript_id", clipData.transcript_id.toString())
    input = `${window.location.origin}/share/${clipData.id}?${searchParams.toString()}`
    if (clipData.clip_type === ClipType.Promotion) {
      searchParams.set("search-option", SearchOptions.Standard)
      input = `${window.location.origin}/free/${clipData.id}?${searchParams.toString()}`
    }
  } else if (meetingId) {
    input = `${window.location.origin}/share/${meetingId}`
  }
  return input
}
