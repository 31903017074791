import { FC, useMemo, useState, useEffect } from "react"
import { Box, Grid, Pagination } from "@mui/material"
import { defaultCountyStateFilterParamsSingle, defaultCountyStateFilterParams } from "@components/shared/config"
import { useFetchOrganizations } from "@src/api/admin/organizations"
import { ActionsMenu } from "./ActionsMenu"
import Loading from "@components/shared/Loading"
import { createOrganizationRow, organizationColumns } from "@config/organizationAdminConfig"
import { TableFilters } from "./TableFilters"
import { FilterParamsSingle } from "../../Home/interface"
import { unpackSetters } from "@components/shared/unpackSetters"
import { DataTable, TableSort } from "@components/shared/DataTable"
import { IOrganization } from "@api/interfaces"

interface OrganizationState {
  selectedOrgs: number[]
  filterParams: FilterParamsSingle
  nameFilter: string
  nameFilterQueryKey: string
  autoUpdateFilter: string
  archiveTypeFilter: string
  spamEnabledFilter: string
  tablePage: number
  channelTypeFilter: string
  archiveStatusFilter: string
  proxyShuffleFilter: string
  videoArchiveIdQueryKey: string
  videoArchiveIdFilter: string
  oppAnalysisEnabledFilter: string
  sortBy: TableSort
}

export const OrganizationAdminContainer: FC = () => {
  const organizationProps: OrganizationState = {
    selectedOrgs: [] as number[],
    filterParams: defaultCountyStateFilterParamsSingle,
    nameFilter: "",
    nameFilterQueryKey: "",
    autoUpdateFilter: "",
    archiveTypeFilter: "",
    spamEnabledFilter: "",
    tablePage: 1,
    channelTypeFilter: "",
    archiveStatusFilter: "",
    proxyShuffleFilter: "",
    videoArchiveIdQueryKey: "",
    videoArchiveIdFilter: "",
    oppAnalysisEnabledFilter: "",
    sortBy: { name: "id", method: "desc" },
  }

  const [organizationState, setOrganizationState] = useState(organizationProps)
  const { setSelectedOrgs, setNameFilterQueryKey, setVideoArchiveIdQueryKey, setTablePage, setSortBy } = unpackSetters(
    organizationState,
    setOrganizationState,
  )

  const formatFilterParams = (filterParams: FilterParamsSingle) => {
    const newFilterParams = { ...defaultCountyStateFilterParams }
    if (filterParams?.states) {
      newFilterParams.states = [filterParams.states]
    }

    if (filterParams?.counties) {
      newFilterParams.counties = [filterParams.counties]
    }

    return newFilterParams
  }

  const { data: organizationsData } = useFetchOrganizations({
    page: organizationState.tablePage,
    filterParams: formatFilterParams(organizationState.filterParams),
    archiveType: organizationState.archiveTypeFilter,
    nameFilter: organizationState.nameFilterQueryKey,
    spamEnabledFilter: organizationState.spamEnabledFilter,
    autoUpdate: organizationState.autoUpdateFilter,
    channelTypeFilter: organizationState.channelTypeFilter,
    archiveStatusFilter: organizationState.archiveStatusFilter,
    proxyShuffleFilter: organizationState.proxyShuffleFilter,
    videoArchiveIdFilter: organizationState.videoArchiveIdQueryKey,
    oppAnalysisEnabledFilter: organizationState.oppAnalysisEnabledFilter,
    sortBy: organizationState.sortBy,
    scope: "search",
    extend: "detailed",
  })

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setNameFilterQueryKey(organizationState.nameFilter)
      setVideoArchiveIdQueryKey(organizationState.videoArchiveIdFilter)
    }, 700)
    return () => clearTimeout(timeOutId)
  }, [organizationState.nameFilter, organizationState.videoArchiveIdFilter])

  useEffect(() => {
    setTablePage(1)
  }, [organizationState.sortBy])

  const filteredOrgs = useMemo(() => {
    if (organizationsData?.organizations === undefined) {
      return []
    }
    return organizationsData?.organizations
  }, [
    organizationState.filterParams,
    organizationsData?.organizations,
    organizationState.autoUpdateFilter,
    organizationState.tablePage,
    organizationState.spamEnabledFilter,
    organizationState.channelTypeFilter,
    organizationState.archiveStatusFilter,
    organizationState.oppAnalysisEnabledFilter,
  ])

  const filteredOrgsTableData = filteredOrgs.map((orgData: IOrganization) => {
    return createOrganizationRow(orgData)
  })

  if (organizationsData === undefined) {
    return <Loading />
  }

  const handleColumnSort = (newSort: TableSort) => {
    const sort = { ...newSort }
    setSortBy(sort)
  }

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" marginY={2} spacing={1}>
        <Grid item xs={1} alignSelf="flex-end">
          <ActionsMenu orgIds={organizationState.selectedOrgs} onActionComplete={() => setSelectedOrgs([])} />
        </Grid>
        <Grid item xs={11}>
          <TableFilters organizationState={organizationState} setOrganizationState={setOrganizationState} />
        </Grid>
      </Grid>
      <Grid container>
        <DataTable
          data={filteredOrgsTableData}
          columns={organizationColumns}
          columnKey="id"
          defaultSort={organizationState.sortBy["name"]}
          defaultSortOrder={organizationState.sortBy["method"]}
          stickyHeader
          size="small"
          selected={organizationState.selectedOrgs}
          setSelected={setSelectedOrgs}
          actionsBarEnabled={false}
          headerSortFunction={handleColumnSort}
        />
        <Box display="flex" justifyContent="flex-end">
          <Pagination
            page={organizationState.tablePage}
            onChange={(_, pageNumber) => setTablePage(pageNumber)}
            count={organizationsData?.total_pages}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
