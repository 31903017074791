import { FC } from "react"
import { Grid, Button } from "@mui/material"
import { useOpenModalKey } from "../shared/OpenModalHook"

export const ClipListItemActions: FC<{
  selected: number[]
  bookmarkId: number
}> = ({ selected, bookmarkId }) => {
  const editModalKey = `EDIT_CLIP_SHARE_KEY_${bookmarkId}`
  const deleteModalKey = `DELETE_CLIP_SHARE_KEY_${bookmarkId}`
  const openEditModalKey = useOpenModalKey(editModalKey)
  const openDeleteModalKey = useOpenModalKey(deleteModalKey)

  return (
    <Grid container direction="row" justifyContent="end" sx={{ marginRight: 4 }}>
      <Grid item>
        <Button onClick={openEditModalKey} color="primary" disabled={selected.length !== 1} variant="text">
          Edit
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={openDeleteModalKey} color="primary" variant="text" disabled={selected.length === 0}>
          Delete
        </Button>
      </Grid>
    </Grid>
  )
}

export default ClipListItemActions
