import { FC, useState, useEffect } from "react"
import { Grid, TextField } from "@mui/material"
import { DayRangePicker } from "../../shared/DayRangePicker"
import { getLimit } from "../../shared/config"
import { Configuration } from "../../../interfaces/dashboard"

export const TopChannels: FC<{
  setConfiguration: (config: Configuration) => void
  defaultConfig?: Configuration
}> = ({ setConfiguration, defaultConfig }) => {
  const [name, setName] = useState(defaultConfig?.name || "")
  const [channelsCount, setChannelsCount] = useState(defaultConfig?.channels_display_count || 10)
  const [dayRange, setDayRange] = useState(defaultConfig?.day_range || 7)

  const calculateChannelsCount = (value: any) => {
    setChannelsCount(getLimit(1, 10, value))
  }

  useEffect(() => {
    setConfiguration({
      name: name,
      day_range: dayRange,
      channels_display_count: channelsCount,
    })
  }, [name, dayRange, channelsCount])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item md={6} xs={12}>
        <TextField
          value={name}
          onChange={(event: any) => setName(event.target.value)}
          label="Widget Name"
          fullWidth
          error={!(name && name.length > 0)}
          margin="dense"
        />
      </Grid>
      <Grid item md={3} xs={12} width={350}>
        <DayRangePicker searchDayRange={dayRange} setSearchDayRange={setDayRange} />
      </Grid>
      <Grid item md={2} xs={12}>
        <TextField
          margin="dense"
          value={channelsCount}
          onChange={(event: any) => calculateChannelsCount(event.target.value)}
          label="Top Channels"
          type="number"
          fullWidth
          inputProps={{
            onKeyDown: (event) => {
              event.preventDefault()
            },
          }}
        />
      </Grid>
    </Grid>
  )
}
