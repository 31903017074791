import { FC, useState } from "react"
import { Button, Popover } from "@mui/material"
import { AssigneesPopoverList } from "../Campaigns/AssigneesPopoverList"
import { DetailsModal } from "../Campaigns/DetailsModal"
import { Campaign } from "../../interfaces/campaign"
import { User } from "../../interfaces/user"

export const TableButton: FC<{
  campaign: Campaign
  userList: User[]
  popoverTitle: string
}> = ({ campaign, userList, popoverTitle }) => {
  const [popOverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const open = Boolean(popOverAnchorEl)

  if (popoverTitle === "Assignees") {
    if (userList?.length > 0) {
      return (
        <>
          <Button
            aria-describedby={`${popoverTitle}-popover`}
            color="primary"
            variant="contained"
            onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
          >
            {popoverTitle}
          </Button>
          <Popover
            id={`${popoverTitle}-popover`}
            open={open}
            anchorEl={popOverAnchorEl}
            onClose={() => setPopoverAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <AssigneesPopoverList list={userList} />
          </Popover>
        </>
      )
    } else {
      return null
    }
  } else {
    return (
      <>
        <Button
          aria-describedby={`${popoverTitle}-popover`}
          color="primary"
          variant="contained"
          onClick={() => setModalIsOpen(true)}
        >
          {popoverTitle}
        </Button>
        <DetailsModal campaign={campaign} open={modalIsOpen} handleClose={() => setModalIsOpen(false)} />
      </>
    )
  }
}
