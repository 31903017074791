import { FC } from "react"
import { Grid, Divider, TextField } from "@mui/material"
import { Table } from "@components/shared/Table"
import { createOrganizationRow, organizationColumns } from "@config/organizationAdminConfig"
import { useFetchOrganizations } from "@src/api/admin/organizations"

export const NewPermissionChannel: FC<{
  userOrganization: any
  orgFilter: any
  setOrgFilter: any
  selectedOrganizations: any
  setSelectedOrganizations: any
}> = ({ userOrganization, orgFilter, setOrgFilter, selectedOrganizations, setSelectedOrganizations }) => {
  const { data: organizations } = useFetchOrganizations({ scope: "detailed" })
  const filteredOrgs = organizations?.organizations?.filter(
    (org: any) => org?.name?.toLowerCase()?.indexOf(orgFilter?.toLowerCase()) !== -1,
  )

  return (
    <Grid container item spacing={1} direction="column">
      <Grid item>
        Give&nbsp;
        <strong>{userOrganization.name}</strong>
        &nbsp;access to the following Meetings/Channels
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <TextField value={orgFilter} onChange={(event: any) => setOrgFilter(event.target.value)} label="Channel Filter" />
      <Grid item>
        <Table
          data={filteredOrgs}
          selected={selectedOrganizations}
          setSelected={setSelectedOrganizations}
          createRow={createOrganizationRow}
          columns={organizationColumns}
        />
      </Grid>
    </Grid>
  )
}
