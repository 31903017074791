import { FC, ReactElement, useState } from "react"
import { CircularProgress, Card, CardContent, CardActions, Typography, Button, IconButton, Stack } from "@mui/material"
import { PublicBriefing } from "../../../interfaces/briefing"
import { useRetryBriefing } from "@src/api/briefings"
import { marked } from "marked"
import { sanitize } from "dompurify"
import { useToast } from "../ToastHook"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ReplayIcon from "@mui/icons-material/Replay"

export const LoadingText: FC = () => (
  <Stack direction="row" spacing={2} alignItems="center">
    <CircularProgress />
    <Typography>Generating briefing...</Typography>
  </Stack>
)

export const BriefingCard: FC<{
  briefing: PublicBriefing
}> = ({ briefing: { id, name, meeting, result, summary, updated_at } }) => {
  const { mutate: retryBriefing, isLoading } = useRetryBriefing(id, meeting.id)
  const [isFull, setIsFull] = useState(false)
  const toast = useToast()

  let error = false

  let briefingContent: ReactElement
  if (result || summary) {
    briefingContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(marked.parse(isFull ? result : summary) as string),
        }}
      />
    )
  } else if (isLoading) {
    briefingContent = <LoadingText />
  } else {
    const createDate = new Date(updated_at)
    if (Date.now() - createDate.valueOf() > 120000) {
      // 2 * 60 * 1000 = 2 minutes
      error = true
      briefingContent = (
        <Typography>The generation of this briefing has errored. Please contact Cloverleaf AI.</Typography>
      )
    } else {
      briefingContent = <LoadingText />
    }
  }

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" flex="1">
            {name}
          </Typography>
          {error ? (
            <Button startIcon={<ReplayIcon />} variant="contained" onClick={() => retryBriefing()}>
              Retry
            </Button>
          ) : (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(isFull ? result : summary)
                toast("Copied to clipboard")
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          )}
        </Stack>
        {briefingContent}
      </CardContent>
      {(result || summary) && (
        <CardActions>
          <Button
            onClick={() => setIsFull((prevValue) => !prevValue)}
            startIcon={isFull ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            variant="outlined"
          >
            {isFull ? "Show short briefing section" : "Show full briefing section"}
          </Button>
        </CardActions>
      )}
    </Card>
  )
}
