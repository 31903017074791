import { NewOrganization } from "./Context"
import { generateSimpleActions } from "./Context"
import { IdentifierAction } from "../../../../api/interfaces"

export const contactSourceUrlParams = (
  url: string,
  navigationMethod: string,
  head: boolean,
  jsonReplaceCode: string,
) => {
  const params = new URLSearchParams()
  params.set("url", url)
  params.set("navigation_method", `${navigationMethod}`)
  params.set("head", head.toString())
  params.set("json_code", jsonReplaceCode)
  return params.toString()
}

export const gatherMeetingsParams = (value: NewOrganization, identifier_actions: IdentifierAction[]) => {
  const params = new URLSearchParams()
  params.set("url", value.url)
  params.set("navigation_method", `${value.navigationMethod}`)
  params.set("separator", value.meetingIdentifier)
  params.set("separator_regex", `${value.meetingIdentifierRegex}`)
  params.set("iframe", `${value.meetingIdentifierIframe}`)
  params.set("head", value.head.toString())
  params.set("json_code", value.jsonReplaceCode)
  if (identifier_actions[0].identifier_action && identifier_actions[0].identifier_action_selection) {
    params.set("identifier_actions", JSON.stringify(identifier_actions))
  }
  params.set("meeting_browser_wait_code", `${value.meetingBrowserWaitCode || ""}`)
  return params.toString()
}

export const inspectMeetingsParams = (
  value: NewOrganization,
  identifier_actions: IdentifierAction[],
  advanced: boolean,
) => {
  const params = new URLSearchParams()
  params.set("url", value.url)
  params.set("navigation_method", `${value.navigationMethod}`)
  params.set("separator", value.meetingIdentifier)
  params.set("json_code", value.jsonReplaceCode)
  params.set("iframe", `${value.meetingIdentifierIframe}`)
  params.set("separator_regex", `${value.meetingIdentifierRegex}`)
  params.set("head", value.head.toString())
  if (identifier_actions[0].identifier_action && identifier_actions[0].identifier_action_selection) {
    params.set("identifier_actions", JSON.stringify(identifier_actions))
  }
  params.set("meeting_browser_wait_code", `${value.meetingBrowserWaitCode || ""}`)
  if (advanced) {
    params.set("actions", JSON.stringify(value.scraperActions))
  } else {
    params.set("actions", JSON.stringify(generateSimpleActions(value.titleIdentifier, value.titleIndex)))
  }
  return params.toString()
}
