import { ICity, ICounty, IState, IStatePermissions } from "../api/interfaces"
import { formatDatetime } from "../components/shared/config"

export interface CityColumn {
  id:
    | "id"
    | "created_at"
    | "updated_at"
    | "name"
    | "state_name"
    | "income_household_median"
    | "county_name"
    | "total_meetings"
    | "total_minutes"
    | "channels"
  type: "string" | "number" | "datetime" | "boolean" | "array"
  label: string
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  link?: any
  component?: any
  title?: string
  listKey?: string
}

export interface CountyColumn {
  id:
    | "id"
    | "created_at"
    | "updated_at"
    | "name"
    | "state_name"
    | "total_meetings"
    | "total_minutes"
    | "channels"
    | "income_household_median"
  type: "string" | "number" | "datetime" | "boolean" | "array"
  label: string
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  component?: any
  link?: any
  title?: string
  listKey?: string
}

export interface StateColumn {
  id:
    | "id"
    | "created_at"
    | "updated_at"
    | "name"
    | "totalChannels"
    | "autoUpdateCount"
    | "nonAutoUpdateCount"
    | "channels"
  type: "string" | "number" | "datetime" | "boolean" | "array"
  label: string
  minWidth?: number
  width?: number
  align?: "right" | "left" | "center"
  format?: (value: any) => string
  action?: any
  component?: any
  link?: any
  title?: string
  listKey?: string
}

export const cityColumns: readonly CityColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "name",
    label: "Name",
    minWidth: 130,
    type: "string",
  },
  {
    id: "county_name",
    label: "County",
    minWidth: 100,
    type: "string",
  },
  {
    id: "state_name",
    label: "State",
    minWidth: 80,
    type: "string",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "channels",
    label: "",
    width: 200,
    type: "array",
    component: "popover",
    title: "Channels",
    listKey: "url",
  },
]

export const countyColumns: readonly CountyColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "name",
    label: "Name",
    minWidth: 100,
    type: "string",
  },
  {
    id: "state_name",
    label: "State",
    minWidth: 80,
    type: "string",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "channels",
    label: "",
    width: 200,
    type: "array",
    component: "popover",
    title: "Channels",
    listKey: "url",
  },
]

export const stateColumns: readonly StateColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "name",
    label: "Name",
    minWidth: 100,
    type: "string",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 170,
    format: formatDatetime,
    type: "datetime",
  },
  {
    id: "channels",
    label: "",
    width: 200,
    type: "array",
    component: "popover",
    title: "Channels",
    listKey: "url",
  },
]

export const statePermissionsColumns: readonly StateColumn[] = [
  { id: "id", label: "Id", width: 30, type: "number" },
  {
    id: "name",
    label: "Name",
    minWidth: 100,
    type: "string",
  },
  {
    id: "totalChannels",
    label: "Total Channels",
    type: "string",
  },
  {
    id: "autoUpdateCount",
    label: "Auto Update On (user permissions / # auto update on)",
    type: "string",
  },
  {
    id: "nonAutoUpdateCount",
    label: "Auto Update Off (user permissions / # auto update off)",
    type: "string",
  },
]

export const createCityRow: Record<any, any> = (city: ICity) => {
  return {
    id: city.id,
    name: city.name,
    county_name: city.county_name,
    state_name: city.state_name,
    total_channels: city.channels.length,
    channels: city.channels,
    created_at: city.created_at,
    updated_at: city.updated_at,
  }
}

export const createCountyRow: Record<any, any> = (county: ICounty) => {
  return {
    id: county.id,
    name: county.name,
    state_name: county.state_name,
    channels: county.channels,
    total_channels: county.channels.length,
    created_at: county.created_at,
    updated_at: county.updated_at,
  }
}

export const createStateRow: Record<any, any> = (state: IState) => {
  return {
    id: state.id,
    name: state.name,
    channels: state.channels,
    total_channels: state.channels.length,
    created_at: state.created_at,
    updated_at: state.updated_at,
  }
}

export const createStatePermissionRow: Record<any, any> = (state: IStatePermissions) => {
  return {
    id: state.id,
    name: state.name,
    totalChannels: state.totalChannels || "NA",
    autoUpdateCount: state.autoUpdateCount || "NA",
    nonAutoUpdateCount: state.nonAutoUpdateCount || "NA",
  }
}

export const getSingular: any = (type: string) => {
  if (type === "cities") {
    return "city"
  } else if (type === "counties") {
    return "county"
  } else {
    return "state"
  }
}

export const getCapitalSingular: any = (type: string) => {
  return type[0]?.toUpperCase() + getSingular(type)?.slice(1)
}
