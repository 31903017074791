import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useTrainSpamModel } from "../../../api/settings"

export const TrainSpam: FC = () => {
  const { mutate: trainSpamModel } = useTrainSpamModel()
  const [trainCsvFile, setTrainCsvFile] = useState<File | undefined>()
  const [testCsvFile, setTestCsvFile] = useState<File | undefined>()

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button variant="contained" component="label" size="small">
          Choose Test CSV
          <input
            onChange={(event: any) => {
              setTestCsvFile(event.target.files[0])
            }}
            type="file"
            hidden
          />
        </Button>
        <Grid container padding={1}>
          {testCsvFile && <Typography>{testCsvFile?.name}</Typography>}
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="contained" component="label" size="small">
          Choose Training CSV
          <input
            onChange={(event: any) => {
              setTrainCsvFile(event.target.files[0])
            }}
            type="file"
            hidden
          />
        </Button>
        <Grid container padding={1}>
          {trainCsvFile && <Typography>{trainCsvFile?.name}</Typography>}
        </Grid>
      </Grid>
      <Grid item>
        <Button onClick={() => trainSpamModel({ train_file: trainCsvFile, test_file: testCsvFile })}>UPLOAD</Button>
      </Grid>
    </Grid>
  )
}
