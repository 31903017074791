import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridValidRowModel } from "@mui/x-data-grid"
import { MergePersonsParams, useMergePeople } from "@src/api/admin/persons"
import Loading from "@src/components/shared/Loading"
import { useCloseModalKey, useData, useModalKey } from "@src/components/shared/OpenModalHook"
import { useToast } from "@src/components/shared/ToastHook"
import { FC, useEffect, useState } from "react"

export const MergePersonsModalKey = "MERGE_PERSONS_MODAL"

export interface MergePersonsModalData {
  rows: GridValidRowModel[]
}

export const MergePeopleModal: FC = () => {
  const isOpen = useModalKey(MergePersonsModalKey)
  const close = useCloseModalKey(MergePersonsModalKey)
  const modalData: MergePersonsModalData | undefined = useData()

  const [primaryRowForMerge, setPrimaryRowForMerge] = useState<GridValidRowModel>()
  const [rowsToMerge, setRowsToMerge] = useState<GridValidRowModel[]>()

  useEffect(() => {
    if (!modalData?.rows) {
      return
    }
    setPrimaryRowForMerge(modalData.rows[0])
    setRowsToMerge(modalData?.rows.slice(1))
  }, [modalData?.rows])

  useEffect(() => {
    if (!primaryRowForMerge) {
      return
    }
    setRowsToMerge(modalData?.rows.filter((row) => row.id !== primaryRowForMerge.id) || [])
  }, [primaryRowForMerge])

  const toast = useToast()
  const { mutate: mergePeople, isLoading } = useMergePeople(() => {
    toast("People successfully merged", undefined, undefined, "info")
    close()
  })

  const handleMergePeopleRequest = () => {
    if (!primaryRowForMerge || !rowsToMerge) {
      toast("Error finding primaryRow and rows to merge", undefined, undefined, "error")
      return
    }
    const payload: MergePersonsParams = {
      primary_person_id: primaryRowForMerge.id as number,
      people_to_merge_ids: rowsToMerge.map((row) => row.id as number),
    }
    mergePeople(payload)
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 3 },
    { field: "title", headerName: "Title", flex: 3 },
    {
      field: "organization",
      headerName: "Organization",
      flex: 3,
    },
    { field: "created_at", headerName: "Created At", flex: 2 },
    { field: "updated_at", headerName: "Updated At", flex: 2 },
  ]

  const setAsPrimaryRowColumnDef: GridColDef = {
    field: "set_as_primary",
    headerName: "Set as Primary",
    flex: 2,
    renderCell: (params) => {
      return (
        <Button onClick={() => setPrimaryRowForMerge(params.row)} disabled={params.row.id === primaryRowForMerge?.id}>
          Set as Primary
        </Button>
      )
    },
  }

  const blankColumn: GridColDef = {
    field: "blank",
    headerName: "",
    flex: 2,
  }

  if (!modalData || !modalData.rows) {
    return null
  }

  return (
    <Dialog open={isOpen} onClose={close} fullWidth maxWidth="lg">
      <DialogTitle>Merge Persons</DialogTitle>
      {isLoading ? (
        <Loading useCloverleafIcon />
      ) : (
        <DialogContent>
          <DataGrid
            rows={modalData?.rows}
            columns={columns.concat(setAsPrimaryRowColumnDef)}
            hideFooter
            hideFooterPagination
            hideFooterSelectedRowCount
          />
          <Typography variant="body1" fontSize={"large"} fontWeight={600} marginY={2}>
            Will be merged into:
          </Typography>
          {primaryRowForMerge && (
            <DataGrid
              rows={[primaryRowForMerge]}
              columns={columns.concat(blankColumn)}
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={close} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleMergePeopleRequest} disabled={isLoading}>
          Merge Persons
        </Button>
      </DialogActions>
    </Dialog>
  )
}
