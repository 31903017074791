import { FC, useState, useEffect, createContext } from "react"
import { Collapse, Divider, Grid, ListItem, TextField } from "@mui/material"
import { defaultLocationFilterParams } from "../shared/config"
import { CampaignEditForm, CampaignTypes } from "../shared/CampaignEditForm"
import { CampaignListItemActions } from "./CampaignListItemActions"
import { CampaignDialog } from "../shared/CampaignDialog"
import { useUpdateCampaign } from "../../api/campaigns"
import { UserCampaign, EmailCadence, Campaign } from "../../interfaces/campaign"

export const CampaignContext = createContext<{
  campaign: UserCampaign | Campaign
}>({
  campaign: {} as UserCampaign | Campaign,
})

export const CampaignListItem: FC<{
  campaign: UserCampaign
}> = ({ campaign }) => {
  const { mutate: updateCampaign, isSuccess: updateSuccess } = useUpdateCampaign(campaign.id)
  const [filterParams, setFilterParams] = useState({
    ...defaultLocationFilterParams,
    ...campaign.data.filterParams,
  })
  const [emailCadence, setEmailCadence] = useState<EmailCadence>(campaign.email_cadence || "Unsubscribed")
  const [filterNameInput, setFilterNameInput] = useState(campaign.name || "")
  const [filterTermsInput, setFilterTermsInput] = useState(campaign.data.searchTerms || [])
  const [mustIncludeInput, setMustIncludeInput] = useState(campaign.data.mustIncludeTerms || [])
  useEffect(() => {
    setEmailCadence(campaign.email_cadence || "Unsubscribed")
    setFilterNameInput(campaign.name || "")
    setFilterTermsInput(campaign.data.searchTerms || [])
    setMustIncludeInput(campaign.data.mustIncludeTerms || [])
  }, [campaign])
  const [isEditing, setIsEditing] = useState(false)
  const [campaignDialogOpen, setCampaignDialogOpen] = useState(false)
  const [proximity, setProximity] = useState(campaign.data.proximity)
  const [exclude, setExclude] = useState(campaign.data.exclude === true)
  const [generalBriefing, setGeneralBriefing] = useState(campaign.automated_general_briefings === true)
  const [briefings, setBriefings] = useState<Set<number>>(new Set(campaign.briefings))

  const closeModals = () => {
    setCampaignDialogOpen(false)
    setIsEditing(false)
  }

  const campaignData: CampaignTypes = {
    filterParams,
    searchTerms: filterTermsInput,
    mustIncludeTerms: mustIncludeInput,
    emailCadence: emailCadence,
    proximity,
    exclude,
    generalBriefing,
    briefings,
    objectSetters: {
      setFilterParams,
      setSearchTerms: setFilterTermsInput,
      setMustIncludeTerms: setMustIncludeInput,
      setEmailCadence: setEmailCadence,
      setFilterNameInput: () => {
        /* placeholder for typescript */
      },
      setUserId: () => {
        /* placeholder for typescript */
      },
      setProximity,
      setExclude,
      setGeneralBriefing,
      setBriefings,
    },
  }

  return (
    <CampaignContext.Provider value={{ campaign: campaign }}>
      <ListItem
        sx={{
          border: 1,
          borderColor: "primary.main",
          borderRadius: 1,
          marginBottom: 2,
        }}
      >
        <Grid container direction="column" marginTop={1}>
          <Grid container>
            <Grid item xs={12} marginY={2}>
              <TextField
                value={filterNameInput}
                onChange={(event) => setFilterNameInput(event.target.value)}
                disabled={!isEditing}
                label="Campaign Name"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Collapse in={isEditing}>
                <CampaignEditForm campaignData={campaignData} isEditing={isEditing} />
              </Collapse>
            </Grid>
          </Grid>
          <Grid container padding={1}>
            <Grid item xs={12} marginY={1} paddingX={4}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container marginLeft={1} spacing={1}>
            <CampaignListItemActions
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setDialogOpen={setCampaignDialogOpen}
              filterTermsInput={filterTermsInput}
              campaign={campaign}
              closeModals={closeModals}
              mustIncludeInput={mustIncludeInput}
            />
          </Grid>
        </Grid>
      </ListItem>
      <CampaignDialog
        campaign={campaign}
        dialogOpen={campaignDialogOpen}
        setDialogOpen={setCampaignDialogOpen}
        requestStatus={updateSuccess}
        onComplete={() => {
          closeModals()
          setCampaignDialogOpen(false)
        }}
        dialogAction="update"
        onSubmit={() =>
          updateCampaign({
            filterParams: filterParams,
            searchTerms: filterTermsInput,
            name: filterNameInput,
            emailCadence: emailCadence,
            mustIncludeInput: mustIncludeInput,
            proximity,
            exclude,
            generalBriefing,
            briefings: Array.from(briefings),
          })
        }
      />
    </CampaignContext.Provider>
  )
}
