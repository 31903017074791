import { Button, Grid, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useCreateUpdateGeographies } from "../../../api/geographies"
import {
  useReindexAllMeetings,
  useGeneratePdfs,
  useTrefoliumMeetingInformation,
  useDetectSpamForAllMeetings,
  useCalculateMinhashAllMeetings,
  useCalculateMissingMeetingMetadata,
  useCalculateMostUsedWords,
  useDeleteSpamTranscripts,
  useUpdateCityCountyData,
} from "../../../api/settings"
import { CostCalculator } from "./CostCalculator"
import { DeleteOrphanedMeetings } from "./DeleteOrphanedMeetings"
import { TrainSimilarWords } from "./TrainSimilarWords"
import { TrainSpam } from "./TrainSpam"
import { WordFilters } from "./WordFilters"
import { YoutubeOverride } from "./YoutubeOverride"

const gridItemStyles = {
  border: 1,
  borderColor: "neutral.main",
  borderRadius: 2,
  marginY: 2,
  padding: 2,
}

export const SupportContainer: FC = () => {
  const { mutate: reindexAllMeetings } = useReindexAllMeetings()
  const { mutate: generatePdfs } = useGeneratePdfs()
  const { mutate: trefoliumMeetingInfo } = useTrefoliumMeetingInformation()
  const { mutate: detectSpamForAllMeetings } = useDetectSpamForAllMeetings()
  const { mutate: calculateMinhash } = useCalculateMinhashAllMeetings()
  const { mutate: calculateMissingMetadata } = useCalculateMissingMeetingMetadata()
  const { mutate: calculateMostUsedWords, isLoading: isLoadingWords } = useCalculateMostUsedWords()
  const { mutate: createUpdateGeogs } = useCreateUpdateGeographies()
  const { mutate: deleteSpamTranscripts } = useDeleteSpamTranscripts()
  const { mutate: updateCityCountyData } = useUpdateCityCountyData()

  const [openCostCalculator, setOpenCostCalculator] = useState(false)

  return (
    <Grid container spacing={2} direction="column">
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Import csv for filtered words</Typography>
        <WordFilters />
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Recalculate Most Used Words</Typography>
        <Button onClick={() => calculateMostUsedWords()} variant="contained" disabled={isLoadingWords}>
          Calculate
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Reindex Transcripts</Typography>
        <Button onClick={() => reindexAllMeetings()} variant="contained">
          RUN
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Generate PDFs</Typography>
        <Button onClick={() => generatePdfs()} variant="contained">
          Generate
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Trefolium Analytics</Typography>
        <Button onClick={() => trefoliumMeetingInfo()} variant="contained">
          Refresh
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Train Spam Model</Typography>
        <TrainSpam />
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Train Similar Words</Typography>
        <TrainSimilarWords />
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Detect Spam for All Meetings</Typography>
        <Button onClick={() => detectSpamForAllMeetings()} variant="contained">
          RUN
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Calculate MinHash for all Meetings</Typography>
        <Button onClick={() => calculateMinhash()} variant="contained">
          Calculate
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Calculate Missing Meeting Metadata</Typography>
        <Button onClick={() => calculateMissingMetadata()} variant="contained">
          Calculate
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Update City County Data</Typography>
        <Button onClick={() => updateCityCountyData()} variant="contained">
          Update
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Delete Orphaned Meetings</Typography>
        <DeleteOrphanedMeetings />
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Delete Spam Transcripts</Typography>
        <Button onClick={() => deleteSpamTranscripts()} variant="contained">
          Delete
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Create Update Geographies Using Simplemaps</Typography>
        <Button onClick={() => createUpdateGeogs()} variant="contained">
          Create/ Update
        </Button>
      </Grid>
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Cost Calculator</Typography>
        <Button onClick={() => setOpenCostCalculator(true)} variant="contained">
          Open
        </Button>
      </Grid>
      {openCostCalculator && <CostCalculator open={openCostCalculator} onClose={() => setOpenCostCalculator(false)} />}
      <Grid item sx={gridItemStyles}>
        <Typography variant="h6">Youtube Override</Typography>
        <YoutubeOverride />
      </Grid>
    </Grid>
  )
}
