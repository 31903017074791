import { useQuery } from "react-query"
import { ClipShareBookmarkView, ClipType } from "../../interfaces/clipShare"
import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"

const prefix = "/promotions/clip_shares"

export const useFetchClipShare = (
  clipShareId: number | undefined,
  isPromo = false,
  clip_type: ClipType = ClipType.Promotion,
) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery(
    ["clip_share", clipShareId],
    async () => {
      const response = await (
        await axiosInstance
      ).get<ClipShareBookmarkView>(`${prefix}/${clipShareId}?clip_type=${clip_type}`)

      return response.data
    },
    { enabled: clipShareId !== undefined && isPromo },
  )
}
