import { useMutation, useQueryClient } from "react-query"
import { ClipShare } from "../../interfaces/clipShare"
import { useAuthAxios } from "../axiosInstance"
import { ClipShareCreateParams } from "../clip_shares"

const prefix = "admin/clip_shares"

export const useCreateClipShare = (onSuccess?: (data: ClipShare) => void) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (clipShare: ClipShareCreateParams) => {
      const response = await (
        await axiosInstance
      ).post<ClipShare>(prefix, {
        keyword: clipShare.keyword,
        meeting_id: clipShare.meetingId,
        transcript_id: clipShare.transcriptId,
      })
      return response.data
    },
    {
      onSuccess: (response) => {
        if (onSuccess) {
          queryClient.invalidateQueries(["bookmarks"])
          onSuccess(response)
        }
      },
    },
  )
}
