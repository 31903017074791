// src/auth/protected-route.js

import { FC } from "react"
import { Route, Redirect } from "react-router-dom"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from "../components/shared/Loading"
import { useCanSeeAdmin } from "../components/Nav/config"

const ProtectedAdminRoute: FC<{
  component: any
  path?: string
  exact?: boolean
}> = ({ component, path, exact }) => {
  const { data: canSeeAdmin, isFetched } = useCanSeeAdmin()
  if (!isFetched) {
    return null
  }

  if (!canSeeAdmin) {
    return <Redirect to="/" />
  }
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
      })}
      path={path}
      exact={exact}
    />
  )
}

export default ProtectedAdminRoute
