import { FC, useState } from "react"
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import { useCreateUserOrganization } from "@api/admin/user_organizations"
import { CreateUserOrganization } from "@src/interfaces/userOrganization"
import { GeneralUserOrgFormFields } from "./steps/GeneralUserOrgFormFields"
import { StatePermissionsOrgFormFields } from "./steps/StatePermissionsOrgFormFields"
import { UserOrgFormFields } from "./steps/UserOrgFormFields"
import { ReviewUserOrgFormFields } from "./steps/ReviewUserFormFields"
import { useToast } from "@src/components/shared/ToastHook"
import { Error, Info } from "@mui/icons-material"
import Loading from "@src/components/shared/Loading"

enum Steps {
  Step1 = 0,
  Step2 = 1,
  Step3 = 2,
  Step4 = 3,
}

export const NewUserOrganization: FC<{
  open: boolean
  onClose: (value: React.SetStateAction<boolean>) => void
}> = ({ open, onClose }) => {
  const setToast = useToast()

  const handleSuccess = () => {
    setToast("User Organization Created", 5000, <Info />, "info")
    onClose(false)
  }

  const handleError = () => {
    setToast("Error creating User Organization", 5000, <Error />, "error")
  }

  const { mutate: createUserOrganization, isLoading } = useCreateUserOrganization(handleSuccess, handleError)
  const [step, setStep] = useState<Steps>(Steps.Step1)

  let errorMsgs: string[] = []

  const [newUserOrganization, setNewUserOrganization] = useState<Partial<CreateUserOrganization>>({})

  const StepHandler = () => {
    switch (step) {
      case Steps.Step1:
        return (
          <GeneralUserOrgFormFields
            newUserOrganization={newUserOrganization}
            setNewUserOrganization={setNewUserOrganization}
            setStep={setStep}
            handleOnClose={handleOnClose}
          />
        )
      case Steps.Step2:
        return (
          <StatePermissionsOrgFormFields
            newUserOrganization={newUserOrganization}
            setNewUserOrganization={setNewUserOrganization}
            setStep={setStep}
            handleOnClose={handleOnClose}
          />
        )
      case Steps.Step3:
        return (
          <UserOrgFormFields
            newUserOrganization={newUserOrganization}
            setNewUserOrganization={setNewUserOrganization}
            setStep={setStep}
            handleOnClose={handleOnClose}
          />
        )
      case Steps.Step4:
        return (
          <ReviewUserOrgFormFields
            newUserOrganization={newUserOrganization}
            setStep={setStep}
            handleOnClose={handleOnClose}
            handleSubmit={handleSubmit}
          />
        )
    }
  }

  const validateUsers = () => {
    const users = newUserOrganization.user_list || []
    users.forEach((user, index) => {
      if (!user.email || user.email.trim() === "") {
        errorMsgs.push(`User ${index + 1} email is required`)
      }
      if (!user.role_ids || user.role_ids.length === 0) {
        errorMsgs.push(`User ${index + 1} role is required`)
      }
      if (user.credit_limit && user.credit_limit < 0) {
        errorMsgs.push(`User ${index + 1} credit limit must be greater than 0`)
      }
    })
  }

  const validateGeneralInfo = () => {
    if (!newUserOrganization.name || newUserOrganization.name.trim() === "") {
      errorMsgs.push("Organization name is required")
    }
    if (newUserOrganization.briefings_enabled && newUserOrganization.total_credits === undefined) {
      errorMsgs.push("Total credits is required when briefings are enabled")
    }
    if (newUserOrganization.default_user_configurations) {
      newUserOrganization.default_user_configurations.goals?.forEach((goal, index) => {
        if (!goal || goal.trim() === "") {
          errorMsgs.push(`Goal ${index + 1} is blank. Set or remove`)
        }
      })
      newUserOrganization.default_user_configurations.competitors?.forEach((competitor, index) => {
        if (!competitor || competitor.trim() === "") {
          errorMsgs.push(`Competitor ${index + 1} is blank. Set or remove`)
        }
      })
    }
  }

  const handleSubmit = () => {
    errorMsgs = []
    validateGeneralInfo()
    validateUsers()
    if (errorMsgs.length > 0) {
      setToast("Errors: " + errorMsgs.join(", "), 10000, <Error />, "error")
      return
    }
    createUserOrganization(newUserOrganization)
  }

  const handleOnClose = () => {
    onClose(false)
    setStep(Steps.Step1)
    setNewUserOrganization({})
  }

  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth="lg">
      <DialogTitle>New User Organization</DialogTitle>
      <DialogContent sx={{ minHeight: "20vw" }}>
        <Grid container item marginTop={0} spacing={2} direction="column">
          {isLoading ? <Loading useCloverleafIcon /> : <StepHandler />}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
