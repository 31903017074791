import { FC, useState } from "react"
import { Button, Grid, Menu, MenuItem } from "@mui/material"
import { Confirm } from "../../shared/Confirm"
import { useUpdateSpam } from "../../../api/meetings"

export const ActionsMenu: FC<{ meetingIds: number[] }> = ({ meetingIds }) => {
  const disableActions = meetingIds.length === 0
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [openUpdateSpam, setOpenUpdateSpam] = useState("")

  const { mutate: updateSpam } = useUpdateSpam()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid>
      <Button
        id="actions-menu-button"
        aria-controls="actions-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="medium"
        variant="outlined"
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "actions-menu-button" }}
      >
        <MenuItem
          onClick={() => {
            setOpenUpdateSpam("spam")
          }}
          disabled={disableActions}
        >
          Should Be Spam
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenUpdateSpam("*not* spam")
          }}
          disabled={disableActions}
        >
          NOT Spam
        </MenuItem>
      </Menu>
      <Confirm
        onSucceed={() => {
          handleClose()
          setOpenUpdateSpam("")
          updateSpam({ ids: meetingIds.join(","), is_spam: openUpdateSpam === "spam" })
        }}
        onClose={() => setOpenUpdateSpam("")}
        open={openUpdateSpam !== ""}
        title="Update Spam Model"
        body={`Are you sure you want to mark these as ${openUpdateSpam}.`}
      />
    </Grid>
  )
}
