import { FC, useState, useContext, useEffect } from "react"
import { Checkbox, Divider, FormControlLabel, Grid, Stack, Typography, Button } from "@mui/material"
import { LocationInput } from "../../LocationInput"
import { geographySelectorConfig } from "@components/shared/config"
import { useFetchOrganizationMetadata } from "@api/organizations"
import { useUpdateOrganization } from "@src/api/admin/organizations"
import Loading from "@components/shared/Loading"
import { unpackSetters } from "@components/shared/unpackSetters"
import { UpdateModal } from "../UpdateModal"
import { GeneralScraperDetailsContext, OrganizationFormContext } from "./Context"
import { ProxyOptions } from "./ProxyOptions"
import { OrganizationDetails, updateModalKey } from "./OrganizationDetails"
import { DownloadServiceOptions } from "./DownloadServiceOptions"
import { BaseOrganization } from "@src/interfaces/organization"
import { useToast } from "@src/components/shared/ToastHook"
import { useOpenModalKey } from "@src/components/shared/OpenModalHook"

export const OrganizationForm: FC<{
  organization: BaseOrganization
}> = ({ organization }) => {
  const openUpdateModalKey = useOpenModalKey(updateModalKey)
  const { mutate: updateOrganization, isSuccess } = useUpdateOrganization()
  const toast = useToast()
  useEffect(() => {
    if (isSuccess) {
      toast("Successfully Updated")
    }
  }, [isSuccess])
  const { data: orgMetadataData } = useFetchOrganizationMetadata()
  const { value: videoArchiveDetails } = useContext(GeneralScraperDetailsContext)
  const { value: orgValue, setValue: setOrgValue } = useContext(OrganizationFormContext)

  const {
    id,
    name,
    videoArchiveId,
    homePageUrl,
    contactPageUrl,
    automaticUpdate,
    spamEnabled,
    proxyDownload,
    customConfig,
    shuffleRegion,
    videoArchiveType,
    videoArchiveDetails: vidArchiveDetailsString,
    immediateRetrieval,
    infiniteLoopback,
    useM4a,
    downloadServiceType,
    channelTypeId,
  } = orgValue

  const { setAutomaticUpdate, setSpamEnabled, setImmediateRetrieval, setInfiniteLoopback, setUseM4a } = unpackSetters(
    orgValue,
    setOrgValue,
  )

  const transcriptionEngineConfigs = [
    {
      label: "Automatic Update",
      value: automaticUpdate,
      setter: () => setAutomaticUpdate(!automaticUpdate),
    },
    {
      label: "Spam Enabled",
      value: spamEnabled,
      setter: () => setSpamEnabled(!spamEnabled),
    },
    {
      label: "Immediate Retrieval",
      value: immediateRetrieval,
      setter: () => setImmediateRetrieval(!immediateRetrieval),
    },
    {
      label: "Infinite Loopback",
      value: infiniteLoopback,
      setter: () => setInfiniteLoopback(!infiniteLoopback),
    },
    {
      label: "Transcode .m4a File",
      value: useM4a,
      setter: () => setUseM4a(!useM4a),
    },
  ]

  const [locationParams, setLocationParams] = useState({
    cities: organization?.city_id,
    counties: organization?.county_id,
    states: organization?.state_id,
  })

  const updateOrg = () => {
    updateOrganization({
      id: id,
      name: name,
      video_archive_id: videoArchiveId,
      video_archive_type: videoArchiveType,
      video_archive_details: videoArchiveType === "general" ? videoArchiveDetails : vidArchiveDetailsString,
      automatic_update: automaticUpdate,
      spam_enabled: spamEnabled,
      city_id: locationParams.cities,
      county_id: locationParams.counties,
      state_id: locationParams.states,
      channel_type_id: channelTypeId,
      home_page_url: homePageUrl,
      contact_page_url: contactPageUrl,
      proxy_download: proxyDownload,
      custom_config: customConfig,
      shuffle_region: shuffleRegion,
      immediate_retrieval: immediateRetrieval,
      infinite_loopback: infiniteLoopback,
      use_m4a: useM4a,
      download_service: downloadServiceType,
    } as BaseOrganization)
  }

  if (orgMetadataData === undefined) {
    return <Loading />
  }

  return (
    <Stack spacing={3} marginTop={2}>
      <OrganizationDetails />
      <Typography variant="h3">Location</Typography>
      {geographySelectorConfig.map((geographySelector) => (
        <Grid item xs={geographySelector.xs} key={geographySelector.type}>
          <LocationInput
            title={geographySelector.label}
            type={geographySelector.type}
            organization={organization}
            setLocationParams={setLocationParams}
            locationParams={locationParams}
          />
        </Grid>
      ))}
      <Divider />
      <Typography variant="h3">Transcription Engine Configs</Typography>
      <Stack direction="row" spacing={2}>
        {transcriptionEngineConfigs.map((config) => (
          <FormControlLabel
            control={<Checkbox onClick={config.setter} checked={config.value} />}
            label={config.label}
          />
        ))}
      </Stack>
      <ProxyOptions />
      <DownloadServiceOptions />
      <Button sx={{ width: 15 }} onClick={openUpdateModalKey} variant="contained">
        Update
      </Button>
      <UpdateModal updateOrg={updateOrg} />
    </Stack>
  )
}
