import { ScraperAction, SelectionTypes } from "./ActionCard"

export const needsRegex = (scraperAction: ScraperAction) => {
  return scraperAction.type !== "CLICK_ON"
}

export const needsIframe = (scraperAction: ScraperAction) => {
  return scraperAction.type !== "CLICK_ON"
}

export const needsValue = (scraperAction: ScraperAction) => {
  return scraperAction.type !== "CLICK_ON"
}

export const needsNetworkExtraction = (scraperAction: ScraperAction) => {
  return scraperAction.type === "EXTRACT_VIDEO_URL"
}

export const needsWaitCode = (scraperAction: ScraperAction) => {
  return (
    scraperAction.type === "BROWSER_NAVIGATE" ||
    scraperAction.type === "CLICK_ON" ||
    (scraperAction.type === "EXTRACT_VIDEO_URL" && scraperAction.network_request)
  )
}

export const regexOnly = (selectionType?: SelectionTypes, scraperAction?: ScraperAction) => {
  if (selectionType === "SUBSTITUTION") {
    return true
  }

  if (scraperAction?.network_request) {
    return true
  }

  return false
}
