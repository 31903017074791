import { FC, useMemo, useState } from "react"
import { Button, Grid, InputAdornment, Stack, TextField } from "@mui/material"
import { DetailsModal } from "./DetailsModal"
import { organizationColumns } from "../../config/campaignConfig"
import { DataTable } from "../shared/DataTable"
import { useFetchOrganizationsCampaigns } from "../../api/campaigns"
import { useIsAdmin } from "../../api/users"
import { Close, Search } from "@mui/icons-material"
import { IconButton } from "rsuite"
import { Campaign } from "../../interfaces/campaign"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import NotificationsIcon from "@mui/icons-material/Notifications"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import PersonRemoveIcon from "@mui/icons-material/PersonRemove"
import { ManageCampaignItems } from "./ManageCampaignItems"
import { unpackSetters } from "../shared/unpackSetters"
import { PopoverButton } from "../shared/PopoverButton"
import { AssigneesPopoverList } from "../Campaigns/AssigneesPopoverList"
import { ManageCampaignDialog } from "./ManageCampaignDialog"

export const CampaignTableView: FC = () => {
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<number[]>([])
  const { data: orgCampaignData } = useFetchOrganizationsCampaigns()
  const [activeCampaignDetails, setActiveCampaignDetails] = useState<Campaign | undefined>(undefined)
  const [searchInput, setSearchInput] = useState("")
  const { data: isAdmin } = useIsAdmin()

  const campaigns = orgCampaignData?.campaigns.filter((row) =>
    row.name.trim().toLowerCase().includes(searchInput.trim().toLowerCase()),
  )

  const campaignItemProps = {
    notifyDialogOpen: false,
    cloneDialogOpen: false,
    deleteDialogOpen: false,
    assignDialogOpen: false,
    unassignDialogOpen: false,
    isEditing: false,
    dialogAction: "",
    selectedUnassignUsers: [] as any[],
  }
  const [campaignState, setCampaignState] = useState(campaignItemProps)
  const {
    setIsEditing,
    setDialogAction,
    setCloneDialogOpen,
    setDeleteDialogOpen,
    setNotifyDialogOpen,
    setAssignDialogOpen,
    setUnassignDialogOpen,
  } = unpackSetters(campaignState, setCampaignState)

  const sortedSelectedCampaigns = useMemo(() => {
    if (orgCampaignData !== undefined) {
      const selectedCampaigns = selectedCampaignIds
        .map((id) => orgCampaignData.campaigns.find((campaign) => campaign.id === id))
        .filter((campaign): campaign is Campaign => campaign !== undefined)
      return selectedCampaigns.sort()
    }
    return []
  }, [selectedCampaignIds, orgCampaignData])

  const closeAndResetPage = () => {
    setIsEditing(false)
    setDialogAction("")
    setSelectedCampaignIds([])
  }

  const tableActions = [
    {
      action: () => {
        setDialogAction("cloneMany")
        setCloneDialogOpen(true)
      },
      text: "Clone",
      icon: <ContentCopyIcon />,
    },
    {
      action: () => {
        setDialogAction("notify")
        setNotifyDialogOpen(true)
      },
      text: "Notifications",
      icon: <NotificationsIcon />,
    },
  ]

  if (isAdmin) {
    tableActions.splice(
      1,
      0,
      {
        action: () => {
          setDialogAction("deleteMany")
          setDeleteDialogOpen(true)
        },
        text: "Delete",
        icon: <DeleteIcon />,
      },
      {
        action: () => setIsEditing(true),
        text: "Edit",
        icon: <EditIcon />,
      },
    )
    tableActions.push(
      {
        action: () => {
          setDialogAction("assign")
          setAssignDialogOpen(true)
        },
        text: "Assign",
        icon: <PersonAddIcon />,
      },
      {
        action: () => {
          setDialogAction("unassign")
          setUnassignDialogOpen(true)
        },
        text: "Unassign",
        icon: <PersonRemoveIcon />,
      },
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={4} marginTop={3} marginBottom={2} marginLeft={1}>
          <TextField
            variant="outlined"
            size="small"
            label="Search Campaigns"
            fullWidth
            onChange={(event) => setSearchInput(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setSearchInput("")}
                    style={{ padding: 0, backgroundColor: "transparent", display: "flex", alignItems: "center" }}
                    size="xs"
                  >
                    {searchInput === "" ? <Search /> : <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searchInput}
          />
        </Grid>
      </Grid>
      <DataTable
        stickyHeader
        virtualize
        actions={tableActions}
        data={campaigns?.map((campaign) => ({
          ...campaign,
          actions: (
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  if (campaign.id) {
                    const urlSearchParams = new URLSearchParams()
                    urlSearchParams.set("campaign-id", campaign.id.toString())
                    window.open(`/?${urlSearchParams}`)
                  }
                }}
              >
                View
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  setActiveCampaignDetails(campaign)
                }}
              >
                Details
              </Button>
              {Boolean(isAdmin && campaign.assignees.length) && (
                <PopoverButton
                  title="Assignees"
                  variant="text"
                  contents={<AssigneesPopoverList list={campaign.assignees} />}
                />
              )}
            </Stack>
          ),
        }))}
        selected={selectedCampaignIds}
        setSelected={setSelectedCampaignIds}
        columns={organizationColumns}
        defaultSort="created_at"
      />
      <DetailsModal
        campaign={activeCampaignDetails}
        open={Boolean(activeCampaignDetails)}
        handleClose={() => setActiveCampaignDetails(undefined)}
      />
      <ManageCampaignItems
        campaignState={campaignState}
        setCampaignState={setCampaignState}
        handleClose={closeAndResetPage}
        sortedCampaigns={sortedSelectedCampaigns}
      />
      <ManageCampaignDialog
        campaignState={campaignState}
        setCampaignState={setCampaignState}
        campaign={sortedSelectedCampaigns[0]}
        onCancel={closeAndResetPage}
      />
    </>
  )
}
