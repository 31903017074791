import {
  Dialog,
  DialogTitle,
  Button,
  Stack,
  Switch,
  Typography,
  DialogContent,
  TextField,
  FormControlLabel,
  DialogActions,
} from "@mui/material"
import { FC, useState } from "react"
import { useCreateBookmark } from "../../api/bookmarks"
import { useCloseModalKey, useData, useModalKey } from "../shared/OpenModalHook"
import { useToast } from "../shared/ToastHook"
import { visibleMessage, hiddenMessage, industryVisibleMessage, industryHiddenMessage } from "./config"
import { Industry } from "../../interfaces/industry"

export const createFolderKey = "CREATE_FOLDER_KEY"

export interface CreateFolderModalData {
  industry: Industry
}

export const CreateFolderModal: FC = () => {
  const open = useModalKey(createFolderKey)
  const closeModal = useCloseModalKey(createFolderKey)
  const data: CreateFolderModalData | undefined = useData()
  const setToast = useToast()
  const [title, setTitle] = useState(data?.industry?.name ? data.industry?.name : "")
  const [visible, setVisibility] = useState(false)
  const { mutate: createBookmark } = useCreateBookmark()

  const clearData = () => {
    setTitle("")
    setVisibility(false)
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      fullWidth
      PaperProps={{ sx: { maxWidth: "80%", paddingX: 6, paddingTop: 6, paddingBottom: 6 } }}
    >
      <DialogTitle>
        <Typography variant="h3" component="div">
          Create Folder
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Set your title, then click save.</Typography>
        <Stack direction="column" sx={{ paddingTop: 2 }} spacing={2}>
          <TextField
            label="Title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{ maxLength: 100 }}
          />
          <FormControlLabel
            control={<Switch checked={visible} onChange={() => setVisibility(!visible)} />}
            label={visible ? "Public" : "Private"}
            componentsProps={{ typography: { variant: "body1" } }}
            sx={{
              "& .MuiFormControlLabel-label": {
                fontWeight: 500,
              },
            }}
          />
          {data?.industry ? (
            <Typography>{visible ? industryVisibleMessage : industryHiddenMessage}</Typography>
          ) : (
            <Typography>{visible ? visibleMessage : hiddenMessage}</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            createBookmark({ title: title, visible: visible })
            closeModal()
            clearData()
            setToast("Folder Created", 1750)
          }}
          variant="contained"
          color="primary"
          disabled={title.length === 0}
        >
          Save
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
