import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { Role } from "../interfaces/roles"

export const useFetchAdminRoles = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["admin", "roles"], async () => {
    const response = await (await axiosInstance).get<{ roles: Role[] }>("/admin/roles")

    return response.data
  })
}

export const useFetchRoles = () => {
  const axiosInstance = useAuthAxios()

  return useQuery(["roles"], async () => {
    const response = await (await axiosInstance).get<string[]>("/roles")

    return response.data
  })
}

export const useDeleteRole = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(`/admin/roles/${params.ids.join(",")}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("roles")
      },
    },
  )
}

export const useUpdateRole = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`/admin/roles/${params.id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("roles")
      },
    },
  )
}

export const useCreateRole = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("/admin/roles/", params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("roles")
      },
    },
  )
}
