import { Box, Card, CardContent, Grid, Link as MuiLink, Typography, useMediaQuery, useTheme } from "@mui/material"
import { FC, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { FixedSizeList as List, ListChildComponentProps } from "react-window"
import { SearchTextField } from "../shared/SearchTextField"
import { useFetchMeetingsByTitle } from "../../api/organizations"
import { ChannelDetailContext } from "../../containers/ChannelDetailContainer"
import moment from "moment"

const renderRow = (props: ListChildComponentProps, meetings: any) => {
  const { index, style } = props
  const meeting = meetings[index]

  return (
    <Card sx={style} key={index}>
      <CardContent>
        <Grid container direction="row">
          <Grid item xs={3}>
            <Box component="img" height={150} width={200} src={meeting.thumbnail_url || "/no-video.png"} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MuiLink component={Link} to={`/meetings/${meeting.id}`}>
              <Typography variant="h6" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {meeting.title}
              </Typography>
              <Typography>
                Published On&nbsp;
                <strong>{moment(meeting.published_at).format("MMM Do YYYY")}</strong>
              </Typography>
            </MuiLink>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export const MeetingArchiveCard: FC = () => {
  const { organization } = useContext(ChannelDetailContext)
  const [meetingSearch, setMeetingSearch] = useState("")
  const { data: meetings } = useFetchMeetingsByTitle(organization?.id, meetingSearch)

  const theme = useTheme()
  const screenBelowLarge = useMediaQuery(theme.breakpoints.down("lg"))

  const callRenderRow = (props: ListChildComponentProps) => renderRow(props, meetings)

  return (
    <Card>
      <CardContent sx={{ padding: 3 }}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} md={2}>
            <Typography variant="h3">Meeting Archive</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <SearchTextField
              search={meetingSearch}
              setSearch={setMeetingSearch}
              label="Enter Meeting Title"
              fullWidth
            />
          </Grid>
          {meetings && (
            <Grid item xs={12} margin={5}>
              <List height={400} itemCount={meetings.length} itemSize={screenBelowLarge ? 250 : 180} width="100%">
                {callRenderRow}
              </List>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
