import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAuthAxios } from "./axiosInstance"
import { PublicBriefing } from "../interfaces/briefing"
import { useIsBriefingsEnabled } from "./users"

const prefix = "/briefings"

export interface BriefingQueryObject {
  states: number[]
  cities: number[]
  counties: number[]
  organizations: number[]
  channel_types: number[]
  meetings: number[]
  city_population: [number, number]
  county_population: [number, number]
  city_income: [number, number]
  county_income: [number, number]
  types: string[]
  users: number[]
  text: string
  sort: string
  order: string
  page: number
  count: number
  fine_tuning: string
  generated_by_model: string
}

export const urlParamsToBriefingQueryObject = (params: URLSearchParams) =>
  ({
    states: params.getAll("states").map((state) => parseInt(state)),
    cities: params.getAll("cities").map((city) => parseInt(city)),
    counties: params.getAll("counties").map((county) => parseInt(county)),
    organizations: params.getAll("organizations").map((organization) => parseInt(organization)),
    channel_types: params.getAll("channel_types").map((channelType) => parseInt(channelType)),
    meetings: params.getAll("meetings").map((meeting) => parseInt(meeting)),
    city_population: params.getAll("city_population").map((cityPopulation) => parseInt(cityPopulation)) as [
      number,
      number,
    ],
    county_population: params.getAll("county_population").map((countyPopulation) => parseInt(countyPopulation)) as [
      number,
      number,
    ],
    city_income: params.getAll("city_income").map((cityIncome) => parseInt(cityIncome)) as [number, number],
    county_income: params.getAll("county_income").map((countyIncome) => parseInt(countyIncome)) as [number, number],
    types: params.getAll("types"),
    users: params.getAll("users").map((user) => parseInt(user)),
    text: params.get("text") || "",
    sort: params.get("sort") || "",
    order: params.get("order") || "",
    page: parseInt(params.get("page") ?? "0"),
    count: parseInt(params.get("count") ?? "25"),
    fine_tuning: params.get("fine_tuning") ?? "",
    generated_by_model: params.get("generated_by_model") || "",
  }) as BriefingQueryObject

export class BriefingUrlBuilder {
  #url: string
  #collectionParams = ["states", "cities", "counties", "organizations", "meetings", "types", "users"]
  #stringParams = ["text", "sort", "order", "generated_by_model"]
  #numberParams = [
    { name: "page", defaultValue: 0 },
    { name: "count", defaultValue: 25 },
  ]

  constructor(useAdmin: boolean = false) {
    this.#url = useAdmin ? "admin/briefings" : "briefings"
  }

  build(params?: URLSearchParams) {
    let url = this.#url
    if (params) {
      const rubyParams = this.buildParamCollection(params)
      url += `?${new URLSearchParams(rubyParams).toString()}`
    }

    return url
  }

  buildParamCollection(params: URLSearchParams) {
    const rubyParamsCollection: string[][] = []

    this.#collectionParams.forEach((param) => {
      params.getAll(param).forEach((value) => rubyParamsCollection.push([`${param}[]`, value]))
    })

    this.#stringParams.forEach((param) => {
      const value = params.get(param)
      if (value) {
        rubyParamsCollection.push([param, value])
      }
    })

    this.#numberParams.forEach(({ name, defaultValue }) => {
      const value = parseInt(params.get(name) ?? defaultValue.toString())
      rubyParamsCollection.push([name, value.toString()])
    })

    const fineTuning = params.get("fine_tuning")
    if (fineTuning) {
      rubyParamsCollection.push(["fine_tuning", (fineTuning === "On").toString()])
    }

    return rubyParamsCollection
  }
}

export const getBriefings = (params?: URLSearchParams) => {
  const axiosInstance = useAuthAxios()
  const { data: isBriefingsEnabled } = useIsBriefingsEnabled()

  return useQuery(
    ["briefings", params && urlParamsToBriefingQueryObject(params)],
    async () => {
      const url = new BriefingUrlBuilder(false).build(params)
      const response = await (await axiosInstance).get<{ total: number; briefings: PublicBriefing[] }>(url)

      return response.data
    },
    {
      enabled: Boolean(isBriefingsEnabled),
    },
  )
}

export const useRetryBriefing = (id: unknown, meetingId: unknown) => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).post<PublicBriefing>(`${prefix}/${id}/retry`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("briefings")
        queryClient.invalidateQueries(["meetings", `${meetingId}`, "briefing"])
        queryClient.invalidateQueries(["meetings", `${meetingId}`, "personalBriefing"])
      },
    },
  )
}
