import { Button, Stack, Typography } from "@mui/material"
import { FC, useContext } from "react"
import { enabledButtonStyle, disabledButtonStyle } from "./styles"
import { HomePageContext } from "../../../pages/HomePage"
import { unpackSetters } from "../../shared/unpackSetters"
import { useHistory, useLocation } from "react-router-dom"

export const View: FC = () => {
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)

  const homePageContext = useContext(HomePageContext)
  const { homePageParams, setHomePageParams } = homePageContext
  const { view } = homePageParams
  const { setView } = unpackSetters(homePageParams, setHomePageParams)

  const switchView = (selection: string) => {
    const type: "list" | "table" = selection === "list" ? "list" : "table"
    setView(type)
    queryParams.set("view", type)
    history.push({ pathname: window.location.pathname, search: queryParams.toString() })
  }

  return (
    <Stack direction="row" flexWrap="wrap" gap={1} marginTop={1} justifyContent="flex-start" alignItems="center">
      <Typography fontWeight={600} variant="body2" sx={{ display: { xs: "none", md: "block" } }}>
        View As
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => switchView("list")}
        sx={view === "list" ? enabledButtonStyle : disabledButtonStyle}
      >
        List
      </Button>
      <Button
        variant="outlined"
        onClick={() => switchView("table")}
        sx={view === "table" ? enabledButtonStyle : disabledButtonStyle}
      >
        Table
      </Button>
    </Stack>
  )
}
