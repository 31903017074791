import { AxiosInstance, AxiosResponse } from "axios"
import { QueryClient, QueryKey, useQuery as originalUseQuery, useQueryClient, UseQueryOptions } from "react-query"
import { urlResources } from "../config/urlResourcesConfig"

export type ApiErrorResponse = {
  errorMessage: string
}

// ****************************************
// ********** react-query *****************
// ****************************************

const ttl = 1000 * 60 * 10
export const queryClient = (axiosInstance: Promise<AxiosInstance>) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: async ({ queryKey, meta }) => {
          let url = `/${queryKey[0]}`
          if (queryKey[1] && typeof queryKey[1] === "object") {
            const params = queryKey[1] as Record<string, unknown>
            if ("id" in params) {
              url += `/${params.id}`
            } else {
              let urlPrefix = ""
              Object.entries(params).forEach(([key, value]) => {
                if (key in urlResources) {
                  urlPrefix += `/${urlResources[key]}/${value}`
                }
              })
              url = urlPrefix + url
            }
          }
          if (meta?.admin) {
            url = `/admin${url}`
          }
          const response = await (await axiosInstance).get(url)
          return response.data
        },
        refetchInterval: ttl,
        refetchOnWindowFocus: false,
        staleTime: ttl,
      },
    },
  })

export const useQuery = <Type>(queryKey: QueryKey, options?: UseQueryOptions<Type>) => {
  const queryClient = useQueryClient()
  return originalUseQuery<Type>(queryKey, {
    onSuccess: (data) => {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          if (item && typeof item === "object" && "id" in item) {
            const { id } = item as { id: string | number }
            queryClient.setQueryDefaults([queryKey[0], { id: id.toString() }], {
              placeholderData: item,
            })
          }
        })
      }
    },
    ...options,
  })
}

export const useAdminQuery = <Type>(queryKey: QueryKey, options?: UseQueryOptions<Type>) =>
  useQuery<Type>(queryKey, {
    meta: {
      admin: true,
    },
    ...options,
  })

const queryFilterMatches = (queryFilter: Record<string | number, any>, data: any) => {
  let shouldUpdate = true
  Object.entries(queryFilter).forEach(([key, value]) => {
    if (!(String(value) === data[key]?.toString() || (typeof value === "boolean" && value === Boolean(data[key])))) {
      shouldUpdate = false
    }
  })
  return shouldUpdate
}

const handleDeleteRequest = (queryClient: QueryClient, type: string, id: string, forceDelete = true) => {
  queryClient.setQueriesData([type], (oldData: unknown) => {
    if (Array.isArray(oldData)) {
      const dataIndex = oldData.findIndex((item) => item?.id.toString() === id)
      if (dataIndex !== -1) {
        const newData = [...oldData]
        if (oldData[dataIndex].discarded_at || forceDelete) {
          newData.splice(dataIndex, 1)
        } else {
          newData[dataIndex] = {
            ...newData[dataIndex],
            discarded_at: new Date(Date.now()).toISOString(),
          }
        }
        return newData
      }
    } else if (oldData && typeof oldData === "object" && !(oldData as { discarded_at?: string }).discarded_at) {
      return {
        ...oldData,
        discarded_at: new Date(Date.now()).toISOString(),
      }
    }
    return oldData
  })

  queryClient.setQueriesData(
    {
      predicate: ({ queryKey }) =>
        Array.isArray(queryKey) && queryKey[0] === type && queryKey.length === 2 && queryKey[1]?.discarded_at === false,
    },
    (oldData) => {
      if (Array.isArray(oldData)) {
        return oldData.filter((item) => !item?.discarded_at)
      }
      return oldData
    },
  )
}

const handleRequestItem = (queryClient: QueryClient, type: string, data: Record<string, unknown>) => {
  queryClient.setQueriesData(
    {
      predicate: ({ queryKey }) => {
        if (Array.isArray(queryKey) && queryKey[0] === type) {
          if (queryKey.length === 1) {
            return true
          } else if (queryKey.length === 2 && typeof queryKey[1] === "object") {
            return queryFilterMatches(queryKey[1], data)
          }
        }
        return false
      },
    },
    (oldData: unknown) => {
      if (Array.isArray(oldData) && data && "id" in data) {
        const dataIndex = oldData.findIndex((item) => item?.id.toString() === (data.id as string | number).toString())
        if (dataIndex !== -1) {
          const newData = [...oldData]
          newData.splice(dataIndex, 1, data)
          return newData
        }
        return [...oldData, data]
      } else if (typeof oldData === "object") {
        return data
      }
      return oldData
    },
  )

  queryClient.setQueriesData(
    {
      predicate: ({ queryKey }) => {
        if (
          Array.isArray(queryKey) &&
          queryKey[0] === type &&
          queryKey.length === 2 &&
          typeof queryKey[1] === "object"
        ) {
          return !queryFilterMatches(queryKey[1], data)
        }
        return false
      },
    },
    (oldData) => {
      if (Array.isArray(oldData) && data && "id" in data) {
        return oldData.filter((item) => item?.id !== data.id)
      }
      return oldData
    },
  )
}

export const useSmartUpdate =
  (queryClient: QueryClient, forceDelete = false) =>
  (response: AxiosResponse<unknown>, overrideType?: string) => {
    const { url } = response.config
    const validUrlResources = Object.values(urlResources)
    let type = overrideType
    if (!type && url) {
      const urlItems = url?.split("/")
      type = urlItems.reverse().find((urlItem) => validUrlResources.includes(urlItem))
    }
    if (type) {
      if (response.config.method === "delete") {
        if (url) {
          const id = url.split("/").pop()
          if (id) {
            // check for multiple items to delete
            if (id.includes(",")) {
              id.split(",").forEach((value: string) => {
                type && handleDeleteRequest(queryClient, type, value, forceDelete)
              })
            } else {
              handleDeleteRequest(queryClient, type, id, forceDelete)
            }
          }
        }
      } else {
        const { data } = response
        if (Array.isArray(data)) {
          data.forEach((item: unknown) => {
            if (item && typeof item === "object") {
              handleRequestItem(queryClient, type as string, item as Record<string, unknown>)
            }
          })
        } else if (data && typeof data === "object") {
          handleRequestItem(queryClient, type, data as Record<string, unknown>)
        }
      }
    }
  }
