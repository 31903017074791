import { BaseClipShare } from "@src/interfaces/clipShare"
import { BaseMeeting, Meeting } from "@src/interfaces/meeting"
import { useAuthAxiosNoAuth } from "../axiosInstanceNoAuth"
import { useMutation, useQuery } from "react-query"

const prefix = "/promotions/leads"

export interface LeadMeeting extends Omit<BaseMeeting, "metadata"> {
  // Same as BaseMeeting, but without metadata
}

export interface Lead {
  meeting: LeadMeeting
  clip_share: BaseClipShare
}

export interface FetchedLeadsForIndustry {
  leads: Lead[]
  count: number
}

export const useFetchLeadsForIndustry = (industryId: string) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery(
    ["promo_leads", industryId],
    async () => {
      const response = await (await axiosInstance).get<FetchedLeadsForIndustry>(`${prefix}/${industryId}`)
      return response.data
    },
    {
      enabled: Boolean(industryId),
    },
  )
}

export const useFetchMeetingForLead = (meetingId: string) => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useQuery(
    ["meetings", meetingId],
    async () => {
      const response = await (
        await axiosInstance
      ).get<{
        meeting: Meeting
      }>(`${prefix}/meeting?meeting_id=${meetingId}`)

      return response.data
    },
    { retry: 0 },
  )
}

export interface SubmitInterestFormData {
  name: string
  email: string
  phone: string
  industry_id: string
}

export const useSubmitInterestForm = () => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useMutation(async (formData: SubmitInterestFormData) => {
    const response = await (await axiosInstance).post(`${prefix}/form_submission`, formData)
    return response.data
  })
}
