import { FC, useMemo, useState } from "react"
import { useFetchScheduledVideos } from "@src/api/admin/content-acquisition/videos"
import { useFetchProxies } from "@src/api/admin/content-acquisition/proxies"
import { useFetchContentAcquisitionJobs } from "@src/api/admin/content-acquisition/jobs"
import { Stack, Box, Tabs, Tab, Typography } from "@mui/material"
import { ProxyTable } from "./ProxyTable"
import { VideoTable } from "./VideoTable"
import { theme } from "../../../../theme"
import { fetchJobsByDaysBackParams, fetchYesterdaysJobsParams } from "@src/interfaces/content-acquisition/query/jobs"
import { Job } from "@src/interfaces/content-acquisition/responseObjectInterfaces"

export const Container: FC = () => {
  const { data: jobsforLessOneDay } = useFetchContentAcquisitionJobs(fetchYesterdaysJobsParams)
  const { data: jobsforLessTwoDay } = useFetchContentAcquisitionJobs(fetchJobsByDaysBackParams(1))
  const { data: jobsforLessThreeDay } = useFetchContentAcquisitionJobs(fetchJobsByDaysBackParams(2))

  useFetchScheduledVideos()
  useFetchProxies()
  const [tab, setTab] = useState(0)

  const getSuccessRates = (jobs: Job[] | undefined) => {
    if (!jobs) return "0%"
    let total = 0
    let success = 0
    jobs.forEach((job) => {
      total++
      if (job.success) success++
    })
    if (total === 0) return "0%"
    return `${Math.round((success / total) * 100)}%`
  }

  const dailySuccessRates = useMemo(
    () => [
      {
        label: "1 Day",
        stat: getSuccessRates(jobsforLessOneDay),
        key: 1,
      },
      {
        label: "2 Day",
        stat: getSuccessRates(jobsforLessTwoDay),
        key: 2,
      },
      {
        label: "3 Day",
        stat: getSuccessRates(jobsforLessThreeDay),
        key: 3,
      },
    ],
    [jobsforLessOneDay, jobsforLessTwoDay, jobsforLessThreeDay],
  )

  const tables = [<ProxyTable />, <VideoTable />]

  return (
    <Stack fontSize="16px" spacing={2} height="fit-content">
      <Stack
        spacing={1.5}
        alignItems="center"
        sx={{
          display: "inline-flex",
          bgcolor: "primary.dark",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        }}
        paddingBottom={1.25}
      >
        <Typography fontSize="1.953em" color="white" component="h2" variant="h2" sx={{ paddingLeft: 2, paddingTop: 1 }}>
          Job Success Rate
        </Typography>
        <Stack spacing={5} direction="row">
          {dailySuccessRates.map((rate) => (
            <Typography fontSize="2.441em" variant="h1" component="h1" color="white" key={rate.key}>
              {rate.label}: {rate.stat}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <Stack
        spacing={1.5}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontSize: "1.25rem",
          },
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
        }}
        bgcolor="white"
        padding={3}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(_e, value) => setTab(value)}
            aria-label="proxy provider tabs"
            textColor={"primary"}
            indicatorColor="primary"
          >
            <Tab value={0} label="Proxies" component="h2" sx={{ fontSize: "1.953em !important" }} />
            <Tab value={1} label="Videos" component="h2" sx={{ fontSize: "1.953em !important" }} />
          </Tabs>
        </Box>
        {tables[tab]}
      </Stack>
    </Stack>
  )
}
