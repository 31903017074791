import { FC, useState, useContext } from "react"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { SideBar } from "./SideBar"
import { MobileSideBar } from "./MobileSideBar"
import { TopBar } from "./TopBar"
import { PromoContext } from "../../App"

export const Nav: FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = useState(false)
  const isPromo = useContext(PromoContext)

  return (
    <>
      <TopBar toggleNav={isMobile ? () => setOpen((prevValue) => !prevValue) : undefined} />
      {isMobile ? <MobileSideBar open={open} closeNav={() => setOpen(false)} /> : isPromo ? <></> : <SideBar />}
    </>
  )
}
