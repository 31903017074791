import { FC, useContext, useMemo } from "react"
import { useSearch } from "../../../api/search"
import { DataTable } from "../../shared/DataTable"
import { useFetchOrganizationsMap } from "@functions/organizations"
import { Meeting } from "../../../interfaces/meeting"
import { locationText, unpackGeo, formatDatetime } from "../../shared/MeetingBasicInfo"
import { columns } from "./columns"
import { useLocation } from "react-router-dom"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Bookmark } from "./bookmark"
import { generateURL } from "@functions/generateURL"
import { HomePageContext } from "@pages/HomePage"
import { getTableFormattedHits, getTooltipFormattedHits } from "./functions"

export const TableView: FC = () => {
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const { data: meetingsSearchData, isLoading } = useSearch(queryParams)
  const { data: organizationData } = useFetchOrganizationsMap()
  const homePageContext = useContext(HomePageContext)
  const { searchTerms, mustIncludeTerms, proximity, exclude } = homePageContext.homePageParams
  const timeStamp = (startTime: number) => new Date(startTime * 1000).toISOString().slice(11, 19)

  const meetingsMap = useMemo(
    () => new Map<number, Meeting>(meetingsSearchData?.meetings.map((meeting) => [meeting.id, meeting])),
    [meetingsSearchData],
  )

  const data = useMemo(() => {
    const tabledata = meetingsSearchData?.meeting_hits.map((meetingHit) => {
      const meeting = meetingsMap.get(meetingHit.id)
      let orgName: string | undefined = undefined
      let location: string | undefined | null = undefined
      if (meeting) {
        const organization = organizationData?.get(meeting?.organization_id)
        if (organization) {
          orgName = organization.name
          const { stateName, countyName, cityName } = unpackGeo(organization)
          location = locationText({ city: cityName, county: countyName, state: stateName })
        }
      }
      const toolTipHits = getTooltipFormattedHits(meetingHit?.terms)
      const needsTooltip = toolTipHits.split("\n").length > 3
      return {
        id: meeting?.id,
        title: meeting?.title,
        publishedAt: meeting?.published_at ? formatDatetime(meeting?.published_at) : undefined,
        channelName: orgName,
        geography: location,
        totalMatches: meetingHit?.hits,
        kwHits: (
          <Tooltip
            title={
              <Typography sx={{ whiteSpace: "pre-line" }}>{getTooltipFormattedHits(meetingHit?.terms)}</Typography>
            }
            disableHoverListener={!needsTooltip}
          >
            <Box>
              {getTableFormattedHits(meetingHit?.terms).map((term, index) => (
                <Typography key={index} variant="body1" color="text.primary" style={{ whiteSpace: "pre-line" }}>
                  {term.term}: {term.count}
                  {needsTooltip && index === 2 ? "\n..." : ""}
                </Typography>
              ))}
            </Box>
          </Tooltip>
        ),
        actions: (
          <Bookmark
            meeting={meeting}
            timestamp={timeStamp(meetingHit.transcripts[0].start_time)}
            transcriptId={meetingHit.transcripts[0].id}
            keyword={meetingHit.terms[0].term}
          />
        ),
        viewed: meetingHit.already_viewed ? (
          <IconButton sx={{ top: "1px" }}>
            <VisibilityIcon color="primary" />
          </IconButton>
        ) : null,
        rowClick: () => {
          const url = generateURL(meetingHit.id, {
            terms: searchTerms,
            proximity,
            mustHaveTerms: mustIncludeTerms,
            exclude,
          })
          window.location.href = url
        },
      }
    })
    return tabledata === undefined ? [] : tabledata
  }, [meetingsMap, organizationData])

  const dataTable = <DataTable data={data} columns={columns} showSkeleton={isLoading} skeletonRows={5} />

  return dataTable
}
