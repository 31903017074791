import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { FC } from "react"
import { useCloseModalKey, useModalKey } from "../../shared/OpenModalHook"

const updateModalKey = "UPDATE_ORG_KEY"

export const UpdateModal: FC<{ updateOrg: any }> = ({ updateOrg }) => {
  const open = useModalKey(updateModalKey)
  const closeModal = useCloseModalKey(updateModalKey)

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Update Organzation Info</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to update?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button
          onClick={() => {
            updateOrg()
            closeModal()
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
