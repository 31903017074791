import { FC, useState } from "react"
import { Box, Button, IconButton, InputAdornment, Stack, TextField, BoxProps } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { addSearchTermString, removeSearchTermString } from "../../../../functions/generateURL"
import { useMeetingSearch } from "../../../../api/search"
import { IMeetingRouteParams } from "../../../../api/interfaces"
import { SearchTermChips } from "../SearchTermChips"

export const TranscriptListSearch: FC<BoxProps> = (props) => {
  const [searchInput, setSearchInput] = useState("")
  const history = useHistory()
  const locationSearchParams = useLocation().search
  const params = new URLSearchParams(locationSearchParams)

  const terms = params.getAll("terms")
  const { meetingId } = useParams<IMeetingRouteParams>()
  const { data } = useMeetingSearch(params, meetingId)
  const termHits: { [index: string]: number } = Object.fromEntries(terms.map((term) => [term, 0]))

  data?.terms.forEach((term_hit) => {
    const transcriptHits = term_hit.transcripts
    if (transcriptHits.length) {
      termHits[term_hit.term] = term_hit.hits
    }
  })

  const addTerm = () => {
    history.push({ search: addSearchTermString(locationSearchParams, searchInput) })
    setSearchInput("")
  }

  const removeTerm = (searchTerm: string) => {
    history.push({ search: removeSearchTermString(locationSearchParams, searchTerm) })
  }

  return (
    <Box {...props}>
      <Stack direction="row" spacing={1}>
        <TextField
          fullWidth
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          size="small"
          placeholder="Enter a search term/phrase"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              addTerm()
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ display: { md: "none" } }}>
                <IconButton onClick={addTerm} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" onClick={addTerm} sx={{ display: { xs: "none", md: "block" }, flexShrink: 0 }}>
          Search
        </Button>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap={0.5} marginTop={0.5}>
        <SearchTermChips termHits={termHits} onDelete={removeTerm} />
      </Stack>
    </Box>
  )
}
