import { FC } from "react"
import { TableContainer, Table, TableHead, TableRow, TableBody, Typography, TableCell } from "@mui/material"
import { useFetchMeetingOpportunities } from "../../../api/meetings"
import { TableHeaderCell } from "@src/components/shared/DataTable/TableWithHeader"

export const Opportunities: FC<{ id: unknown }> = ({ id }) => {
  const { data: opportunities } = useFetchMeetingOpportunities(id)

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              <Typography variant="h4">Title</Typography>
            </TableHeaderCell>
            <TableHeaderCell>
              <Typography variant="h4">Description</Typography>
            </TableHeaderCell>
            <TableHeaderCell>
              <Typography variant="h4">Actionability</Typography>
            </TableHeaderCell>
            <TableHeaderCell>
              <Typography variant="h4">NAICS Code</Typography>
            </TableHeaderCell>
            <TableHeaderCell>
              <Typography variant="h4">Actionable Date</Typography>
            </TableHeaderCell>
            <TableHeaderCell>
              <Typography variant="h4">Key Speaker</Typography>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {opportunities?.map(
            ({ id, name, description, actionability, naics_code, actionable_date, key_decision_maker }) => (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>{description}</TableCell>
                <TableCell>{actionability}</TableCell>
                <TableCell>{`${naics_code?.code}: ${naics_code?.title}`}</TableCell>
                <TableCell>{actionable_date}</TableCell>
                <TableCell>{key_decision_maker}</TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
