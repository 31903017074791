import { FC } from "react"
import { Link } from "react-router-dom"
import { Stack, Toolbar, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Box } from "@mui/material"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import ArticleIcon from "@mui/icons-material/Article"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { useIsBriefingsEnabled, useIsOpportunitiesEnabled } from "../../api/users"
import { navConfig, checkMatchingPath, useCanSeeAdmin } from "./config"

const ListLink: FC<{ label: string; Icon: OverridableComponent<any>; to: string }> = ({ label, Icon, to }) => {
  return (
    <ListItem disablePadding sx={{ width: 56, display: "block" }}>
      <ListItemButton
        component={Link}
        to={to}
        selected={checkMatchingPath(to)}
        sx={{
          width: 56,
          overflow: "hidden",
          paddingRight: "0",
          borderRadius: "0 8px 8px 0",
          "& .MuiTouchRipple-child": {
            backgroundColor: "primary.main",
          },
          "&.Mui-selected": {
            backgroundColor: "primary.dark",
          },
          "&:focus": {
            textDecoration: "none",
          },
          "&:hover, &.Mui-selected:hover": {
            textDecoration: "none",
            backgroundColor: "primary.dark",
            width: 196,
          },
          "&:hover .MuiListItemIcon-root": { color: "common.white" },
          "&.Mui-selected .MuiListItemIcon-root": { color: "common.white" },
          "&:active": {
            textDecoration: "none",
            backgroundColor: "primary.main",
          },
          transition: "background-color 0.2s, width 0.2s",
        }}
      >
        <ListItemIcon
          sx={{
            float: "left",
            minWidth: "0",
            paddingRight: 2,
            color: "primary.dark",
            transition: "color 0.2s",
            zIndex: "1",
          }}
        >
          <Icon />
        </ListItemIcon>
        <ListItemText sx={{ float: "left", color: "common.white", zIndex: "1" }}>
          <Typography variant="h3">{label}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

export const SideBar: FC = () => {
  const { data: canSeeAdmin } = useCanSeeAdmin()
  const { data: briefingsEnabled } = useIsBriefingsEnabled()
  const { data: opportunitiesEnabled } = useIsOpportunitiesEnabled()

  return (
    <Box width={56}>
      <Stack sx={{ zIndex: (theme) => theme.zIndex.drawer, position: "fixed" }} spacing={1}>
        <Toolbar />
        {navConfig.map(({ label, to, icon }) => (
          <ListLink key={to} label={label} to={to} Icon={icon} />
        ))}
        {briefingsEnabled && <ListLink label="Briefings" to="/briefings" Icon={ArticleIcon} />}
        {opportunitiesEnabled && <ListLink label="Opportunities" to="/opportunities" Icon={RequestQuoteIcon} />}
        {canSeeAdmin && <ListLink label="Admin" to="/admin" Icon={AdminPanelSettingsIcon} />}
      </Stack>
    </Box>
  )
}
