import { FC, useState, useContext, useEffect } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  FormLabel,
  FormGroup,
  FormControlLabel,
  MenuItem,
} from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import { useFetchUsers, useUpdateUsersRoles } from "../../../api/admin/users"
import { AdminUserContext } from "./context"
import { useToast } from "../../shared/ToastHook"
import { useModalKey, useOpenModalKey, useCloseModalKey } from "../../shared/OpenModalHook"
import { useFetchAdminRoles } from "../../../api/roles"

const modalKey = "ADMIN_UPDATE_USER_ROLES"

export const UpdateRoleMenuItem: FC<{ closeMenu: () => void }> = ({ closeMenu }) => {
  const contextData = useContext(AdminUserContext)
  const { ids } = contextData
  const openModalKey = useOpenModalKey(modalKey)
  return (
    <MenuItem
      onClick={() => {
        openModalKey()
        closeMenu()
      }}
      disabled={!ids.length}
    >
      Update Role(s)
    </MenuItem>
  )
}

enum CheckboxState {
  Unset = -1,
  False,
  True,
  Indeterminate,
}

export const UpdateRoleDialog: FC = () => {
  const contextData = useContext(AdminUserContext)
  const { ids, reset } = contextData
  const open = useModalKey(modalKey)
  const closeModalKey = useCloseModalKey(modalKey)
  const setToast = useToast()
  const { data: users } = useFetchUsers()
  const selectedUsers = users?.filter((user) => ids.includes(user.id))
  const userEmails = selectedUsers?.map((user) => user.email)
  const { data: adminRoleData } = useFetchAdminRoles()

  const [checked, setChecked] = useState<Map<string, CheckboxState>>(new Map())
  useEffect(() => {
    if (selectedUsers && adminRoleData) {
      const newCheckedState: Map<string, CheckboxState> = new Map(
        adminRoleData.roles.map((role) => [role.name, CheckboxState.Unset]),
      )
      let currentIndex = 0
      while (
        currentIndex < selectedUsers.length &&
        !Array.from(newCheckedState.values()).every((state) => state === CheckboxState.Indeterminate) // early exit
      ) {
        newCheckedState.forEach((state, role) => {
          // if it's already indeterminate, don't check because nothing can change indeterminate
          if (state !== CheckboxState.Indeterminate) {
            const wantedValue = selectedUsers[currentIndex].roles.some((userRole) => userRole.name === role)
              ? CheckboxState.True
              : CheckboxState.False
            if (state === CheckboxState.Unset) {
              // if no registered state, assign it to wanted value
              newCheckedState.set(role, wantedValue)
            } else if (state !== wantedValue) {
              // if the new value doesn't match, set to indeterminate
              newCheckedState.set(role, CheckboxState.Indeterminate)
            }
          }
        })
        currentIndex += 1
      }
      setChecked(newCheckedState)
    }
  }, [users, ids, adminRoleData?.roles.length])

  const handleToggle = (role: string) => () => {
    setChecked((prevValue) => {
      const newValue = new Map(prevValue)
      if (prevValue.get(role) !== CheckboxState.False) {
        newValue.set(role, CheckboxState.False)
      } else {
        newValue.set(role, CheckboxState.True)
      }
      return newValue
    })
  }

  const close = () => {
    closeModalKey()
    reset()
  }

  const { mutate: updateUsersRoles, isLoading } = useUpdateUsersRoles(() => {
    setToast("Successfully updated user roles", 3000, <TaskAltIcon />)
    close()
  })

  return (
    <Dialog open={open} onClose={close} fullWidth>
      <DialogTitle>{`Update User Role${ids.length > 1 ? "s" : ""}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>This will affect the following users:</DialogContentText>
        <List>
          {userEmails?.map((email) => (
            <ListItem key={email}>
              <ListItemText>{email}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogContent sx={{ flexShrink: "0" }}>
        <FormLabel>Assigned Roles:</FormLabel>
        <FormGroup sx={{ marginLeft: 2 }}>
          {adminRoleData?.roles.map(({ name }) => {
            const checkboxState = checked.get(name)
            if (checkboxState !== undefined) {
              return (
                <FormControlLabel
                  key={name}
                  control={
                    <Checkbox
                      checked={checkboxState === CheckboxState.True}
                      indeterminate={checkboxState === CheckboxState.Indeterminate}
                      onClick={handleToggle(name)}
                    />
                  }
                  label={name}
                />
              )
            } else {
              return null
            }
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            const roles: Record<string, boolean> = {}
            checked.forEach((checkboxState, role) => {
              if (checkboxState === CheckboxState.True) {
                roles[role] = true
              } else if (checkboxState === CheckboxState.False) {
                roles[role] = false
              }
            })
            updateUsersRoles({
              ids,
              roles,
            })
          }}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Confirm"}
        </Button>
        <Button onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
