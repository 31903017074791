import { FC } from "react"
import { IconButton, Divider as MuiDivider, Box } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"

export const Divider: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Box>
    <MuiDivider sx={{ marginY: "2px" }}>
      <IconButton onClick={onClick} size="small">
        <AddIcon />
      </IconButton>
    </MuiDivider>
  </Box>
)
