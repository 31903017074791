import { FC, useMemo, useState } from "react"
import { Typography, Autocomplete, TextField } from "@mui/material"
import { BasicFilterParams, BasicGeoType } from "../../interface"
import { FilterParamsSingle } from "../../../../Home/interface"
import { styledGroupTitles } from "../../styles"
import { sortGeographyByState } from "../../helpers"

export const GeographySelector: FC<{
  options: BasicFilterParams
  filterParams: FilterParamsSingle
  setFilterParams: (filterParams: FilterParamsSingle) => void
  filterOptions?: any
  label: string
  type: "cities" | "states" | "counties"
  adminGeographiesData?: BasicGeoType[]
}> = ({ options, filterParams, setFilterParams, filterOptions = undefined, label, type }) => {
  const [open, setIsOpen] = useState(false)

  const geographiesHash: { [id: number]: BasicGeoType } = useMemo(() => {
    if (!options || !options[type]) {
      return {}
    }
    const hash: { [id: number]: BasicGeoType } = {}
    options[type].forEach((geo: BasicGeoType) => (hash[geo.id] = geo))

    return hash
  }, [options])

  const filteredValue = useMemo(() => {
    const hashIndex = filterParams[type]
    if (!hashIndex) {
      return null
    } else {
      return geographiesHash[hashIndex]
    }
  }, [filterParams, options])

  let sortedOptions: BasicGeoType[]
  if (options) {
    sortedOptions = sortGeographyByState(options[type], type, options?.states)
  } else {
    sortedOptions = []
  }

  return (
    <Autocomplete
      noOptionsText="Nothing to display"
      id="organizations-single-select"
      ListboxComponent={styledGroupTitles}
      options={sortedOptions}
      value={filteredValue || null}
      open={open}
      filterOptions={filterOptions}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      onClick={() => setIsOpen(true)}
      renderTags={() => (
        <Typography onClick={() => setIsOpen(!open)} sx={{ maxWidth: "90%" }} noWrap>
          {filteredValue?.name}
        </Typography>
      )}
      onChange={(_event, newValue) => {
        const temp = { ...filterParams }
        temp[type] = newValue?.id || null
        setFilterParams(temp)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      groupBy={(option) => (option?.state_name && option.state_name !== "none" ? option.state_name : "")}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(option: BasicGeoType | null) => option?.name || "None"}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Typography variant="body1">{option?.name}</Typography>
        </li>
      )}
    />
  )
}
