import { FC, useState, useMemo, useEffect } from "react"
import { useDeleteFlags, useGetAllFeatureFlags, useGetAllFeatureFlagTypes } from "@api/admin/flags"
import { Button, Menu, MenuItem, Stack } from "@mui/material"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreateUpdateModal, createUpdateKey } from "./CreateUpdateModal"
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid"

export const FlagsContainer: FC = () => {
  const { data: flagData, isLoading } = useGetAllFeatureFlags()
  // preload flag types
  useGetAllFeatureFlagTypes()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const { mutate: deleteFlags } = useDeleteFlags(() => setRowSelectionModel([]))
  const openCreate = useOpenModalKey(createUpdateKey)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (open: () => void) => {
    handleClose()
    open()
  }

  const selectedFlag = useMemo(
    () => flagData?.find((flag) => flag.id === rowSelectionModel[0]),
    [flagData, rowSelectionModel],
  )

  const [rows, setRows] = useState<GridRowsProp>([])

  useEffect(() => {
    if (flagData) {
      setRows(flagData)
    }
  }, [JSON.stringify(flagData)])

  const columns: GridColDef[] = [
    { field: "id", headerName: "Flag Id", flex: 1 },
    { field: "name", headerName: "Name", flex: 4 },
    { field: "value", headerName: "Value", flex: 6 },
    { field: "flag_type", headerName: "Flag Type", flex: 3 },
    { field: "public", headerName: "Public", flex: 3 },
  ]

  return (
    <Stack>
      <Button size="large" variant="contained" onClick={handleClick} sx={{ width: 150, margin: 1 }}>
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem disabled={rowSelectionModel.length > 0} onClick={() => handleOpen(openCreate)}>
          Create
        </MenuItem>
        <MenuItem
          disabled={rowSelectionModel.length < 1}
          onClick={() => handleOpen(() => deleteFlags(rowSelectionModel.map((val) => val.toString())))}
        >
          Delete
        </MenuItem>
        <MenuItem disabled={rowSelectionModel.length !== 1} onClick={() => handleOpen(openCreate)}>
          Update
        </MenuItem>
      </Menu>
      <DataGrid
        rows={rows}
        loading={isLoading}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel)
        }}
        rowSelectionModel={rowSelectionModel}
      />
      <CreateUpdateModal featureFlag={selectedFlag} onSuccess={() => setRowSelectionModel([])} />
    </Stack>
  )
}
