import { Button, Grid, Link, useMediaQuery, useTheme } from "@mui/material"
import { FC } from "react"

export const demoLink = "https://meetings.hubspot.com/jeremy-becker/jeremy-30-minutes-only"
export const trialLink =
  "https://www.cloverleaf.ai/get-started?utm_source=cloverleaf%20ai&utm_medium=application&utm_campaign=&utm_content=meeting_share_page"

export const PromoLinks: FC<{ isFindALead?: boolean }> = ({ isFindALead }) => {
  const theme = useTheme()
  const pageIsMedium = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <Grid
      container
      item
      xs={10}
      sm={5}
      md={4}
      sx={{ flexDirection: "row", paddingTop: 2 }}
      spacing={2}
      justifyContent="flex-end"
    >
      <Button
        sx={{
          marginRight: isFindALead ? 0 : 2,
          fontSize: { xs: 15, sm: 15 },
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "#FFF",
          },
        }}
        variant={pageIsMedium ? "text" : "outlined"}
        type="button"
        size="large"
        component={Link}
        onClick={() => window.open(demoLink, "_blank")}
      >
        Schedule Demo
      </Button>
      {!isFindALead && (
        <Button
          sx={{
            color: "neutral.light",
            "&:hover": {
              color: "neutral.light",
            },
          }}
          color="secondary"
          variant={pageIsMedium ? "text" : "contained"}
          component="a"
          size="large"
          onClick={() => window.open(trialLink, "_blank")}
        >
          {!pageIsMedium && "Start Your "}
          Free Trial
        </Button>
      )}
    </Grid>
  )
}
