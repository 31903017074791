import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Stack, Typography } from "@mui/material"
import { FC, useContext, useMemo } from "react"
import { useFetchEstimateGeneralBriefingCost } from "../../../api/campaigns"
import { LoadingEllipsis } from "../LoadingEllipsis"
import { useFetchCurrentUser, useFetchCurrentUserCreditUsages } from "../../../api/users"
import { useModalKey, useCloseModalKey } from "../OpenModalHook"
import { CampaignContext } from "../../Campaigns/CampaignListItem"

export const WarningDialog: FC<{
  modalKey: string
  onCancel: () => void
  onSubmit: () => void
  personalBriefingWarning?: boolean
}> = ({ modalKey, onCancel, onSubmit, personalBriefingWarning }) => {
  const campaignContext = useContext(CampaignContext)
  const { data: currentUser } = useFetchCurrentUser()
  const { data: estimate, isLoading } = useFetchEstimateGeneralBriefingCost(campaignContext.campaign.id)
  const { data: myCreditUsages } = useFetchCurrentUserCreditUsages()

  const isOpen = useModalKey(modalKey)
  const close = useCloseModalKey(modalKey)

  const cancel = () => {
    onCancel()
    close()
  }

  const submit = () => {
    onSubmit()
    close()
  }

  const handleClose = () => {
    close()
  }

  const estimatedCost = useMemo(() => {
    return estimate?.cost.toFixed(2)
  }, [estimate])

  const myCreditsRemaining = useMemo(() => {
    if (myCreditUsages) {
      return (myCreditUsages.credit_limit - myCreditUsages.credits_used)?.toFixed(2)
    }
    return 0
  }, [myCreditUsages, currentUser])

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Please Note</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Stack spacing={2}>
            <Typography>
              Currently estimating the cost, may take a couple minutes
              <LoadingEllipsis />
            </Typography>
            <Skeleton variant="rectangular" width={500} height={100} />
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Typography>
              Based on the previous 7 days of usage, enabling briefings on this campaign will cost an estimated
              <strong>
                &nbsp;
                {estimatedCost}
                &nbsp;
              </strong>
              credits per week {personalBriefingWarning ? "per briefing " : ""}to process.
            </Typography>
            <Typography>{`You have ${myCreditsRemaining} credits remaining.`}</Typography>
            <Typography>
              Are you sure you want to enable {personalBriefingWarning ? "personal" : "general"} briefings?
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>No</Button>
        <Button onClick={submit}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}
