import { useMemo } from "react"
import { CampaignList, FilterParams, FilterParamsWithoutDates } from "./interface"
import { UserCampaign } from "../../interfaces/campaign"
import { User } from "../../interfaces/user"

/** Updates the url search params for filterParams, searchTerms, campaignId, dateRange,
 * and adds this new url to history.
 */
export const updateSearchParams = (
  filterParams: any,
  searchInput: any,
  selectedCampaignId: string,
  locationSearchParams: string,
  tagsRef: string | null,
  history: any,
  searchDateRange: [Date, Date],
  mustIncludeInput: any = [],
) => {
  const extraTag: string[] = tagsRef ? [tagsRef] : []
  const urlSearchParams = new URLSearchParams(locationSearchParams)
  urlSearchParams.set("filter-params", btoa(JSON.stringify(filterParams)))
  urlSearchParams.set("search-terms", encodeURIComponent(searchInput.concat(extraTag).toString()))
  urlSearchParams.set("campaign-id", selectedCampaignId || "")
  urlSearchParams.set("date-range", searchDateRange.toString())
  urlSearchParams.set("must-include-terms", encodeURIComponent(mustIncludeInput))
  history.push({ search: urlSearchParams.toString() })
}

export const goToSearch = (
  filterParams: any,
  searchInput: any,
  selectedCampaignId: string,
  locationSearchParams: string,
  searchDateRange: [Date, Date],
  mustIncludeInput: any = [],
) => {
  const urlSearchParams = new URLSearchParams(locationSearchParams)
  urlSearchParams.set("filter-params", btoa(JSON.stringify(filterParams)))
  urlSearchParams.set("search-terms", encodeURIComponent(searchInput.toString()))
  urlSearchParams.set("campaign-id", selectedCampaignId || "")
  urlSearchParams.set("date-range", searchDateRange.toString())
  urlSearchParams.set("must-include-terms", encodeURIComponent(mustIncludeInput))
  window.open(`/?${urlSearchParams}`)
}

/** Determines if there is a valid campaign selected and if any of the filters changed
 */
export const campaignAndFiltersChanged = (
  campaignsData: CampaignList,
  filterParams: FilterParams,
  searchInput: string[],
  selectedCampaignId: string,
  mustIncludeInput: string[],
  tagsRef: string | null,
) => {
  const filterChanged = filterParamsChanged(campaignsData, filterParams, selectedCampaignId)
  const searchChanged = searchInputChanged(campaignsData, searchInput, selectedCampaignId)
  const mustChanged = mustIncludeInputChanged(campaignsData, mustIncludeInput, selectedCampaignId)
  return (
    (selectedCampaignId !== "" && filterChanged) ||
    (selectedCampaignId !== "" && searchChanged) ||
    (selectedCampaignId !== "" && mustChanged) ||
    tagsRef !== null
  )
}

/** Memoizes the filterParamsChanged and searchInputChanged functions as params,
 * and returns them as dictionary
 */
export const filtersChanged = (
  campaignsData: CampaignList,
  filterParams: FilterParams,
  searchInput: string[],
  selectedCampaignId: string,
  mustIncludeInput: string[],
) => {
  const filterChanged = filterParamsChanged(campaignsData, filterParams, selectedCampaignId)
  const searchChanged = searchInputChanged(campaignsData, searchInput, selectedCampaignId)
  const mustChanged = mustIncludeInputChanged(campaignsData, mustIncludeInput, selectedCampaignId)
  return filterChanged || searchChanged || mustChanged
}

/** Memoizes filterParams to determine if filterParams have been changed from the selected campaign
 * or have been changed without a campaign selected.
 */
const filterParamsChanged = (campaignsData: CampaignList, filterParams: FilterParams, selectedCampaignId: string) =>
  useMemo(() => {
    const currentCampaign = campaignsData?.campaigns?.find((campaign) => campaign.id === Number(selectedCampaignId))

    if (currentCampaign !== undefined) {
      const copyFilterParams = { ...filterParams }
      const copyFilterParamsKeys = Object.keys(copyFilterParams) as (keyof FilterParamsWithoutDates)[]
      copyFilterParamsKeys.forEach((key) => {
        if (Array.isArray(copyFilterParams[key])) {
          copyFilterParams[key] = copyFilterParams[key].sort() as [number, number]
        }
      })

      const copyCurrentCampaign = { ...currentCampaign.data.filterParams }
      const copyCurrentCampaignKeys = Object.keys(copyCurrentCampaign) as (keyof FilterParamsWithoutDates)[]
      copyCurrentCampaignKeys.forEach((key) => {
        if (Array.isArray(copyCurrentCampaign[key])) {
          copyCurrentCampaign[key] = copyCurrentCampaign[key]?.sort() as [number, number]
        }
      })

      return JSON.stringify(copyCurrentCampaign) !== JSON.stringify(copyFilterParams)
    }

    const currentKeys = Object.keys(filterParams) as (keyof FilterParamsWithoutDates)[]
    if (currentKeys.some((key) => filterParams[key].length > 0)) {
      return true
    }
    return false
  }, [campaignsData, filterParams, selectedCampaignId])

/** Memoizes the searchInput to determine if it has been changed from the selected campaign
 * or has been changed without a campaign selected.
 */
const searchInputChanged = (campaignsData: CampaignList, searchInput: string[], selectedCampaignId: string) =>
  useMemo(() => {
    const cleanSearchInput = cleanInputs(searchInput)
    const currentCampaign = campaignsData?.campaigns?.find((campaign) => campaign.id === Number(selectedCampaignId))

    if (currentCampaign !== undefined) {
      const copyCurrentCampaignSearch = [...currentCampaign.data.searchTerms]
      const cleanCurrentTerms = cleanInputs(copyCurrentCampaignSearch)
      return JSON.stringify(cleanCurrentTerms.sort()) !== JSON.stringify(cleanSearchInput.sort())
    }

    return cleanSearchInput[0] !== "" && cleanSearchInput.length > 0
  }, [campaignsData, searchInput, selectedCampaignId])

/** Memoizes the mustIncludeInput to determine if it has been changed from the selected campaign
 * or has been changed without a campaign selected.
 */
const mustIncludeInputChanged = (campaignsData: CampaignList, mustIncludeInput: string[], selectedCampaignId: string) =>
  useMemo(() => {
    const cleanMustInclude = cleanInputs(mustIncludeInput)
    const currentCampaign = campaignsData?.campaigns?.find((campaign) => campaign.id === Number(selectedCampaignId))

    if (currentCampaign !== undefined && currentCampaign.data.mustIncludeTerms !== undefined) {
      const copyCurrentCampaignSearch = [...currentCampaign.data.mustIncludeTerms]
      const cleanCurrentTerms = cleanInputs(copyCurrentCampaignSearch)
      return JSON.stringify(cleanCurrentTerms.sort()) !== JSON.stringify(cleanMustInclude.sort())
    }

    return cleanMustInclude[0] !== "" && cleanMustInclude.length > 0
  }, [campaignsData, mustIncludeInput, selectedCampaignId])

export const selectedCampaign = (selectedCampaignId: string, campaignsData: CampaignList) => {
  return useMemo(() => {
    if (selectedCampaignId !== undefined && campaignsData !== undefined) {
      const campaign = campaignsData?.campaigns?.find((hash) => hash.id.toString() === selectedCampaignId)
      return campaign
    }
  }, [selectedCampaignId, campaignsData])
}

export const update = (buttonState: string) => {
  return useMemo(() => buttonState.trim().toLowerCase() === "update", [buttonState])
}

// Determines if current campaign selected is the current users camapaign
export const userCampaignSelected = (selectedCampaignId: number | null, userCampaigns?: UserCampaign[]) => {
  return useMemo(() => {
    if (!selectedCampaignId || !userCampaigns) {
      return false
    }

    const selectedCampaign = userCampaigns.find((campaign) => campaign.id === selectedCampaignId)

    if (selectedCampaign) {
      return true
    }

    return false
  }, [selectedCampaignId, userCampaigns])
}

/** Goes through input and removes any strings that are blank strings */
export const cleanInputs = (stringInput: string[]) => {
  const newInput = [...stringInput]
  if (newInput !== undefined) {
    newInput.forEach((term, index) => {
      if (term === "") newInput.splice(index, 1)
    })
  }
  return newInput
}
