import { useMutation, useQuery } from "react-query"
import { useAuthAxiosNoAuth } from "./axiosInstanceNoAuth"
import { Meeting } from "../interfaces/meeting"
import { useAuthAxios } from "./axiosInstance"

const prefix = "/promotions"

export const useUnsubscribeTrialUser: any = () => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useMutation({
    mutationFn: async (id: string) => {
      return (await axiosInstance).delete(`${prefix}/unsub_trial_user/?user_id=${id}`)
    },
    retry: 0,
  })
}

export const useUnsubscribeUser: any = () => {
  const axiosInstance = useAuthAxiosNoAuth()

  return useMutation(
    async ({ id, emailType }: { id: string; emailType: string }) => {
      return (await axiosInstance).put(`${prefix}/unsub_user/?user_id=${id}&email_type=${emailType}`)
    },
    { retry: 0 },
  )
}

export const useFetchMeeting = (meetingId: string) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["meetings", meetingId],
    async () => {
      const response = await (
        await axiosInstance
      ).get<{
        meeting: Meeting
      }>(`${prefix}/meeting?meeting_id=${meetingId}`)

      return response.data
    },
    { retry: 0 },
  )
}

export const useFetchSampleMeetings: any = (searchTerms: string[], channelType: string) => {
  const axiosInstance = useAuthAxiosNoAuth()
  const urlParamsPrefix = "terms[]="
  let urlParams = ""

  searchTerms.forEach((term) => {
    urlParams = `${urlParams}${urlParamsPrefix}${term}&`
  })

  urlParams = `${urlParams}channel_type=${channelType}`

  return useQuery(
    ["search_sample", searchTerms, channelType],
    async () => {
      const response = await (await axiosInstance).get(`${prefix}/meetings?${urlParams}`)

      return response.data
    },
    {
      enabled: searchTerms.length > 0,
    },
  )
}
