import { TextField, Stack, Button, Typography, Checkbox, FormControlLabel } from "@mui/material"
import { FC, useState } from "react"
import { useUpdateYtOverride } from "../../../api/settings"
import Loading from "../../shared/Loading"

export const YoutubeOverride: FC = () => {
  const [override, setOveride] = useState("")
  const [proxy, setProxy] = useState(false)
  const [shuffleRegion, setShuffleRegion] = useState(false)

  const { mutate: update, isLoading, isError } = useUpdateYtOverride()

  if (isLoading) {
    return <Loading />
  } else if (isError) {
    return <Typography>Something went wrong</Typography>
  } else {
    return (
      <Stack direction="row" spacing={2}>
        <TextField sx={{ width: 1000 }} size="medium" onChange={(event) => setOveride(event.target.value)}>
          {override}
        </TextField>
        <FormControlLabel control={<Checkbox value={proxy} onChange={() => setProxy(!proxy)} />} label="Proxy" />
        <FormControlLabel
          control={<Checkbox value={proxy} onChange={() => setShuffleRegion(!shuffleRegion)} />}
          label="Shuffle Region"
        />
        <Button
          sx={{ width: 100 }}
          color="primary"
          variant="contained"
          onClick={() => update({ override: override, proxy: proxy, shuffleRegion: shuffleRegion })}
        >
          Submit
        </Button>
      </Stack>
    )
  }
}
