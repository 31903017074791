import { FC, useContext, useEffect, useMemo, useState } from "react"
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { AdditionalDetails } from "../../Home/interface"
import { useFetchFilterList, useFetchMyCampaigns, useUpdateCampaign } from "../../../api/campaigns"
import { useHistory, useLocation } from "react-router-dom"
import { useCloseModalKey, useModalKey } from "../OpenModalHook"
import { Campaign } from "../../../interfaces/campaign"
import { MeetingSearchFormContext } from "."
import { SearchQueryParams } from "../../../interfaces/search"

export const campaignUpdateModalKey = "UPDATE_CAMPAIGN_MODAL"

export const CampaignUpdateContainer: FC<{ pathname?: string }> = ({ pathname }) => {
  const meetingContext = useContext(MeetingSearchFormContext)
  const { filterParams } = meetingContext
  const { terms, advancedTerms, searchDateRange, proximity, exclude } = meetingContext.searchFormData
  const locationSearchParams = useLocation().search
  const queryParams = new URLSearchParams(locationSearchParams)
  const history = useHistory()
  const campaignIdString = new URLSearchParams(locationSearchParams).get(SearchQueryParams.CampaignId)
  const campaignId = parseInt(campaignIdString || "0")

  const { data: myCampaignData } = useFetchMyCampaigns()
  const { data: campaignsData } = useFetchFilterList()
  const campaignNameEmailCadenceMap = useMemo(() => {
    if (myCampaignData && campaignsData) {
      return new Map<number, [string, "Unsubscribed" | "Daily" | "Weekly"]>([
        ...campaignsData.campaigns.map((campaign: Campaign) => [campaign.id, [campaign.name, "Daily"]]),
        ...myCampaignData.campaigns.map((campaign) => [campaign.id, [campaign.name, campaign.email_cadence]]),
      ])
    } else {
      return new Map<number, [string, "Unsubscribed" | "Daily" | "Weekly"]>()
    }
  }, [myCampaignData, campaignsData])

  const open = useModalKey(campaignUpdateModalKey)
  const closeMenu = useCloseModalKey(campaignUpdateModalKey)

  const [campaignName, setCampaignName] = useState("")
  const [emailCadence, setEmailCadence] = useState<"Unsubscribed" | "Daily" | "Weekly">("Daily")
  const { mutate: updateCampaign } = useUpdateCampaign(campaignId, () => {
    queryParams.set(SearchQueryParams.FilterParams, btoa(JSON.stringify(filterParams)))
    queryParams.delete(SearchQueryParams.SearchTerms)
    Array.from(terms).forEach((term) => {
      queryParams.append(SearchQueryParams.SearchTerms, term)
    })
    queryParams.delete(SearchQueryParams.MustIncludeTerms)
    Array.from(advancedTerms).forEach((term) => {
      queryParams.append(SearchQueryParams.MustIncludeTerms, term)
    })
    queryParams.delete(SearchQueryParams.DateRange)
    queryParams.append(SearchQueryParams.DateRange, `${searchDateRange[0].valueOf()}`)
    queryParams.append(SearchQueryParams.DateRange, `${searchDateRange[1].valueOf()}`)
    queryParams.delete(SearchQueryParams.Proximity)
    if (proximity) {
      queryParams.append(SearchQueryParams.Proximity, proximity.toString())
    }
    history.push({ pathname: pathname, search: queryParams.toString() })
  })

  const updateCurrentCampaign = (additionalDetails: AdditionalDetails) => {
    updateCampaign({
      filterParams,
      searchTerms: Array.from(terms),
      mustIncludeInput: Array.from(advancedTerms),
      ...additionalDetails,
      proximity,
      exclude,
    })
  }

  useEffect(() => {
    if (campaignIdString !== null) {
      const [campaignName, emailCadence] = campaignNameEmailCadenceMap.get(campaignId) || ["", "Daily"]
      setCampaignName(campaignName)
      setEmailCadence(emailCadence)
    }
  }, [campaignIdString, campaignNameEmailCadenceMap])

  return (
    <Dialog open={Boolean(open && campaignName)} onClose={closeMenu} fullWidth maxWidth="md">
      <DialogTitle>{`Update ${campaignName}`}</DialogTitle>
      <DialogContent>
        <TextField
          value={campaignName}
          onChange={(event) => setCampaignName(event.target.value)}
          label="Campaign Name"
          fullWidth
          required
          sx={{ marginTop: 1, marginBottom: 2 }}
        />
        <FormControl fullWidth>
          <InputLabel id="notification-cadence">Notify By Email</InputLabel>
          <Select
            labelId="notification-cadence"
            id="notification-cadence"
            value={emailCadence}
            label="Notify By Email"
            onChange={(e) => setEmailCadence(e.target.value as "Unsubscribed" | "Daily" | "Weekly")}
          >
            <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ margin: 1, paddingRight: 2 }}>
        <Button
          onClick={() => {
            updateCurrentCampaign({
              name: campaignName,
              emailCadence: emailCadence,
            })
            closeMenu()
          }}
          disabled={campaignName.trim().length === 0}
          variant="contained"
        >
          Submit
        </Button>
        <Button onClick={closeMenu}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
