import { useState, useEffect, FC } from "react"

export const LoadingEllipsis: FC = () => {
  const [ellipsisCount, setEllipsisCount] = useState(0)
  useEffect(() => {
    const id = setInterval(() => {
      setEllipsisCount((prevCount) => (prevCount = (prevCount + 1) % 4))
    }, 1000)
    return () => {
      clearInterval(id)
    }
  }, [])
  return <span>{".".repeat(ellipsisCount)}</span>
}

export const AugmentedStatus: FC<{ status: string }> = ({ status }) => {
  return (
    <span>
      {status}
      {status === "loading" && <LoadingEllipsis />}
    </span>
  )
}
