import { FC, useMemo, useState } from "react"
import { Button, List, ListItem, Stack, TextField, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { NewUser } from "./NewUser"
import { UserListItem } from "./UserListItem"
import { UserOrganizationImportCsv } from "./csvManagement/ImportCsv"
import { UserOrganizationExportCsv } from "./csvManagement/ExportCsv"
import { UninvitedUsersButton } from "./UninvitedUsersButton"
import { useFetchUserOrganizationUsers } from "../../../../api/admin/user_organizations"
import { AugmentedStatus } from "../../../shared/LoadingEllipsis"

export const Users: FC = () => {
  const { userOrganizationId } = useParams<{ userOrganizationId: string }>()
  const { data: users, status } = useFetchUserOrganizationUsers(userOrganizationId)
  const [openAddUser, setOpenAddUser] = useState(false)
  const [userSearchInput, setUserSearchInput] = useState("")
  const usersData = useMemo(() => {
    return users?.filter((user) => user.email.indexOf(userSearchInput) >= 0)
  }, [users, userSearchInput])

  if (!users) {
    return (
      <Typography>
        <AugmentedStatus status={status} />
      </Typography>
    )
  }

  return (
    <>
      <Stack spacing={1} direction="row" sx={{ marginBottom: 2 }}>
        <Button onClick={() => setOpenAddUser(true)} size="small" variant="contained">
          Add User
        </Button>
        <UserOrganizationImportCsv />
        <UserOrganizationExportCsv />
        <Button
          variant="text"
          download="user-org-template.csv"
          href={window.URL.createObjectURL(
            new Blob(["email,role,requires notifications\n[email],[basic/admin/super admin],[true/false]"], {
              type: "text/csv",
            }),
          )}
        >
          Download CSV Template
        </Button>
        <UninvitedUsersButton />
      </Stack>
      <TextField
        margin="dense"
        id="user-search-input"
        label="Search By Email"
        type="text"
        fullWidth
        value={userSearchInput}
        onChange={(event) => setUserSearchInput(event.target.value)}
      />
      {usersData && (
        <List sx={{ maxHeight: 400, overflowY: "auto" }}>
          {usersData.map((user) => (
            <ListItem sx={{ padding: 0, margin: 0, marginBottom: 0.5 }}>
              <UserListItem user={user} />
            </ListItem>
          ))}
        </List>
      )}
      <NewUser open={openAddUser} onClose={() => setOpenAddUser(false)} />
    </>
  )
}
