import { FC, useState } from "react"
import { Grid } from "@mui/material"
import { Table } from "../../shared/Table"
import Loading from "../../shared/Loading"
import { ActionsMenu } from "./ActionsMenu"
import { useCreateRole, useDeleteRole, useFetchAdminRoles, useUpdateRole } from "../../../api/roles"
import { createRoleRow, roleColumns } from "../../../config/roleAdminConfig"

export const RoleAdminContainer: FC = () => {
  const [selectedRoles, setSelectedRoles] = useState<number[]>([])
  const { data: rolesData } = useFetchAdminRoles()

  const { mutate: createRole } = useCreateRole()
  const { mutate: updateRole } = useUpdateRole()
  const { mutate: deleteRole } = useDeleteRole()

  if (rolesData === undefined) {
    return <Loading />
  }

  const onActionComplete = () => {
    setSelectedRoles([])
  }

  return (
    <Grid container>
      <Grid item xs={2} alignSelf="flex-end" marginY={2}>
        <ActionsMenu
          ids={selectedRoles}
          onActionComplete={onActionComplete}
          newRecord={createRole}
          updateRecord={updateRole}
          deleteRecord={deleteRole}
          recordName="role"
          records={rolesData.roles}
        />
      </Grid>
      <Grid item xs={12}>
        <Table
          data={rolesData.roles}
          selected={selectedRoles}
          setSelected={setSelectedRoles}
          createRow={createRoleRow}
          columns={roleColumns}
        />
      </Grid>
    </Grid>
  )
}
