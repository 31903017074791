import { FC } from "react"
import { Card, CardHeader, Typography, CardContent, Stack } from "@mui/material"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import Tooltip from "@mui/material/Tooltip"

const MetricsWrapper: FC<{
  icon: any
  title: string
  content: any
  infoMessage: any
}> = ({ icon, title, content, infoMessage }) => {
  return (
    <Card
      sx={{
        overflow: "visible !important",
        height: "100%",
      }}
    >
      <CardHeader
        sx={{ position: "relative" }}
        avatar={icon}
        title={
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Typography variant="h5">{title}</Typography>
            <Tooltip title={infoMessage} arrow placement="top">
              <InfoIcon sx={{ color: "black", paddingBottom: "2px" }} />
            </Tooltip>
          </Stack>
        }
      />
      <CardContent>{content}</CardContent>
    </Card>
  )
}

export default MetricsWrapper
