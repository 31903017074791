import { FC } from "react"
import { Typography, Stack, Button, Skeleton } from "@mui/material"
import { Helmet } from "react-helmet"

interface Action {
  action: () => void
  text: string
  icon?: JSX.Element
  disabled?: boolean
}

export const Layout: FC<{ title?: string; actions?: Action[] }> = ({ title, actions, children }) => {
  return (
    <>
      <Helmet>
        <title>{`Cloverleaf.AI | ${title}`}</title>
      </Helmet>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" marginRight={3}>
          {title || <Skeleton width="12ch" />}
        </Typography>
        <Stack direction="row-reverse" alignItems="center" spacing={1}>
          {actions?.map(({ action, text, icon, disabled }, index) => (
            <Button
              variant={index === 0 ? "contained" : "outlined"}
              key={text}
              sx={{ textTransform: "capitalize" }}
              onClick={action}
              startIcon={icon}
              size="small"
              disabled={disabled}
            >
              {text}
            </Button>
          ))}
        </Stack>
      </Stack>
      {children}
    </>
  )
}
