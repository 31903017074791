import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material"
import { Stack, Box, Typography, Paper, Button, InputLabel, TextField, Fab, useMediaQuery } from "@mui/material"
import { Lock1 } from "@src/assets/find_a_lead/icons"
import { TestimonialComponent } from "@src/components/Promo/testimonial"
import { useToast } from "@src/components/shared/ToastHook"
import { theme } from "@src/theme"
import { ChangeEventHandler, FC, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useSubmitInterestForm } from "@src/api/promotions/leads"
import formSuccess from "@src/assets/find_a_lead/formSuccess.gif"

export interface InterestedFormProps {
  name: string | undefined
  email: string | undefined
  phone: string | undefined
}

export enum PageState {
  Form,
  Transition,
  Success,
}

export const InterestedForm: FC = () => {
  const { id: industryId } = useParams<{ id: string }>()
  const [formData, setFormData] = useState<InterestedFormProps>({ name: undefined, email: undefined, phone: undefined })
  const setToast = useToast()
  const [nameError, setNameError] = useState<string>()
  const [emailError, setEmailError] = useState<string>()
  const [phoneError, setPhoneError] = useState<string>()
  const history = useHistory()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const [pageState, setPageState] = useState<PageState>(PageState.Form)
  const { mutate: createFormSubmission } = useSubmitInterestForm()

  useEffect(() => {
    // Whenever we make changes to the form, clear the error messages
    setNameError(undefined)
    setEmailError(undefined)
    setPhoneError(undefined)
  }, [JSON.stringify(formData)])

  const handleSubmit = () => {
    // Verify that the form is filled out
    if (formData.name === undefined || formData.email === undefined || formData.phone === undefined) {
      setToast("Please fill out all fields", undefined, undefined, "error")
      return
    }
    // Verify that the name is not empty
    if (formData.name.trim() === "") {
      setNameError("Please enter a name")
      return
    }
    // Verify that the email is not empty
    if (formData.email === "") {
      setEmailError("Please enter an email")
      return
    }
    // Verify that the email is a valid email
    if (!formData.email.includes("@")) {
      setEmailError("Please enter a valid email")
      return
    }
    // Verify that the email does not belong to (gmail, hotmail, outlook, yahoo, aol, or icloud)
    if (
      formData.email.includes("gmail") ||
      formData.email.includes("hotmail") ||
      formData.email.includes("outlook") ||
      formData.email.includes("yahoo") ||
      formData.email.includes("aol") ||
      formData.email.includes("icloud")
    ) {
      setEmailError("Please enter your company email address")
      return
    }
    // Verify that the phone is not empty
    if (formData.phone === "") {
      setPhoneError("Please enter a phone number")
      return
    }
    // Verify that the phone number matches the format (xxx) xxx-xxxx
    if (!formData.phone.match(/^\(\d{3}\) \d{3}-\d{4}$/)) {
      setPhoneError("Please enter a valid phone number")
      return
    }
    createFormSubmission({
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      industry_id: industryId,
    })

    setPageState(PageState.Success)
  }

  useEffect(() => {
    if (pageState === PageState.Transition) {
      setTimeout(() => {
        setPageState(PageState.Success)
      }, 3000)
    }
  }, [pageState])

  const handleGoBack = () => {
    history.push(`/find-a-lead/${industryId}`)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, name: event.target.value })
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, email: event.target.value.trim() })
  }

  const handlePhoneNumberChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    // As they type the phone number, format it to (xxx) xxx-xxxx
    // If they are deleting, allow them to delete the last character and return
    const phone = event.target.value
    if (phone.length < (formData?.phone?.length ?? 0) || phone.length === 0) {
      setFormData({ ...formData, phone })
      return
    }
    if (phone.match(/^\d{3}$/)) {
      setFormData({ ...formData, phone: `(${phone}) ` })
    } else if (phone.match(/^\(\d{3}\) \d{3}$/)) {
      setFormData({ ...formData, phone: `${phone}-` })
    } else {
      setFormData({ ...formData, phone })
    }
  }

  if (pageState === PageState.Transition) {
    // Not currently in use but leaving incase we want to add a transition animation
    return (
      <Box paddingY={8} justifyContent={"center"} display={"flex"} width={"100%"}>
        <img src={formSuccess} width={isMediumScreen ? "70%" : "40%"} height={"auto"} style={{ margin: "auto" }} />
      </Box>
    )
  }

  if (pageState === PageState.Success) {
    return (
      <Box paddingY={8} justifyContent={"center"} display={"flex"} width={"100%"} flexDirection={"column"}>
        <img
          src={formSuccess}
          width={isMediumScreen ? "50%" : "30%"}
          height={"auto"}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />
        <Typography variant="h1" color="#4cb2dc" fontSize={36} sx={{ fontWeight: "700", textAlign: "center" }}>
          Thank You! Your Information Is In
        </Typography>
        <Typography variant="body1" color="black" sx={{ textAlign: "center", marginTop: 1 }}>
          Expect to be ahead of the RFP game and your competitors within 48 hours.
          <br />
          We're excited to show you the Cloverleaf AI difference.
        </Typography>
      </Box>
    )
  }

  return (
    <Stack
      width={"100%"}
      sx={{ paddingTop: isSmallScreen ? 7 : 8, backgroundColor: theme.palette.primary.dark }}
      justifyContent={"center"}
      display={"flex"}
      paddingX={{ xs: 2, md: 2, lg: 3, xl: 8 }}
      paddingY={8}
    >
      <Stack
        direction={isMediumScreen ? "column" : "row"}
        justifyContent={isMediumScreen ? "center" : "space-evenly"}
        alignItems={isMediumScreen ? "center" : "stretch"}
        spacing={4}
        marginTop={4}
        height={"fit-content"}
      >
        <Box
          width={"fit-content"}
          margin={"auto"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Box
            width={"fit-content"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            textAlign={isSmallScreen ? "center" : undefined}
          >
            <Lock1 style={{ height: "100px", width: "auto", alignSelf: "flex-start" }} />
            <Typography variant="h1" fontSize={isSmallScreen ? 32 : 38} color="white" sx={{ fontWeight: "700" }}>
              <span style={{ color: "#4cb2dc" }}>Unlock the</span> Full List
            </Typography>
            <Typography variant={isSmallScreen ? "body2" : "body1"} color="white">
              Be the First to Discover Government Contracts.
            </Typography>
          </Box>
          <Box sx={{ maxWidth: "400px", width: "100%" }} marginTop={4} marginX={isMediumScreen ? "auto" : "inherit"}>
            <TestimonialComponent
              firstSection="Tom Szold"
              secondSection="Blue Zone Partners"
              thirdSection="Cloverleaf provides slam-dunk sales leads months and years before my competition"
            />
          </Box>
        </Box>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderRadius: 4,
            backgroundColor: "rgb(205, 225, 240)",
            maxWidth: "280px",
            width: "100%",
          }}
        >
          <Stack direction={"column"} alignItems={"center"} spacing={2}>
            <Box width="90%">
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <Box paddingX={2}>
                    <InputLabel>Name</InputLabel>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={formData.name}
                    onChange={handleNameChange}
                  />
                  {nameError && <Typography color="red">{nameError}</Typography>}
                </Stack>
                <Stack spacing={1}>
                  <Box paddingX={2}>
                    <InputLabel>Email</InputLabel>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleEmailChange}
                  />
                  {emailError && <Typography color="red">{emailError}</Typography>}
                </Stack>
                <Stack spacing={1}>
                  <Box paddingX={2}>
                    <InputLabel>Phone</InputLabel>
                  </Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={formData.phone}
                    onChange={handlePhoneNumberChange}
                  />
                  {phoneError && <Typography color="red">{phoneError}</Typography>}
                </Stack>
              </Stack>
              <Box width={"90%"} margin={"auto"} marginTop={2}>
                <Button
                  variant="contained"
                  sx={{ bgcolor: "black", color: "white", paddingLeft: 3, paddingRight: 1 }}
                  onClick={handleSubmit}
                  fullWidth
                >
                  Submit <ArrowCircleRight sx={{ ml: 1, color: "#4cb2dc" }} />
                </Button>
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Stack>
      <Box display={"flex"} justifyContent={"flex-end"} position={"fixed"} bottom={16} left={30}>
        <Fab
          variant="extended"
          onClick={handleGoBack}
          style={{ background: "#374758", color: "white", fontWeight: 600 }}
          size="medium"
        >
          <ArrowCircleLeft sx={{ color: "white" }} /> &nbsp; Go Back
        </Fab>
      </Box>
    </Stack>
  )
}
