export interface CsvUser {
  email: string
  role_id: number | undefined
  roleHelperText?: string
  requires_signals_notifications: boolean
  notificationHelperText?: string
}

export interface CsvRow {
  email: string
  role: string
  requires_notifications: string
}

export interface UserOrgWizardUserCsvRow {
  email: string
  role: string
  requires_signals_notifications: string
  requires_account_summary_notifications: string
  credit_limit: string
  job_title?: string
}

export interface UserOrgWizardCsvUser extends CsvUser {
  requires_account_summary_notifications: boolean
  credit_limit: number | undefined
  job_title?: string
}

const handleRole = (roleRow: string | undefined, roleMap: Map<string, number>): [number, string | undefined] => {
  const result: [number, string | undefined] = [roleMap.get("basic") || 0, undefined]
  if (roleRow === undefined) {
    result[1] = "Undefined in csv"
    return result
  }
  if (roleRow === "") {
    result[1] = "Empty in csv"
  } else {
    let roleNumber = roleMap.get(roleRow.toLowerCase())
    if (roleNumber === undefined) {
      roleNumber = parseInt(roleRow)
    }
    if (!isNaN(roleNumber)) {
      result[0] = roleNumber
    }
  }
  return result
}

const handleRequiresNotifications = (requiresNotifications: string | undefined): [boolean, string | undefined] => {
  const result: [boolean, string | undefined] = [true, undefined]
  if (requiresNotifications === undefined) {
    result[1] = "Undefined in csv"
    return result
  }
  if (requiresNotifications === "") {
    result[1] = "Empty in csv"
  } else {
    if (requiresNotifications.toLowerCase().includes("t") || requiresNotifications === "1") {
      result[0] = true
    } else if (requiresNotifications.toLowerCase().includes("f") || requiresNotifications === "0") {
      result[0] = false
    } else {
      result[1] = "Unknown in csv"
    }
  }
  return result
}

export const handleCsvRow = (row: CsvRow, roleMap: Map<string, number>): CsvUser => {
  const rowObject: CsvUser = {
    email: row.email,
    role_id: roleMap.get("basic") || 0,
    requires_signals_notifications: true,
  }
  const [roleNumber, roleHelperText] = handleRole(row.role, roleMap)
  rowObject.role_id = roleNumber
  if (roleHelperText) {
    rowObject.roleHelperText = roleHelperText
  }
  const [requiresNotifications, notificationHelperText] = handleRequiresNotifications(row.requires_notifications)
  rowObject.requires_signals_notifications = requiresNotifications
  if (notificationHelperText) {
    rowObject.notificationHelperText = notificationHelperText
  }
  return rowObject
}

export const handleUserOrgWizardCsvRow = (
  row: UserOrgWizardUserCsvRow,
  roleMap: Map<string, number>,
): UserOrgWizardCsvUser => {
  const rowObject: UserOrgWizardCsvUser = {
    email: row.email,
    role_id: roleMap.get("basic") || 0,
    requires_signals_notifications: true,
    requires_account_summary_notifications: false,
    credit_limit: undefined,
  }

  const [roleNumber, roleHelperText] = handleRole(row.role, roleMap)
  rowObject.role_id = roleNumber
  if (roleHelperText) {
    rowObject.roleHelperText = roleHelperText
  }

  const [requiresSignalsNotifications, notificationHelperText] = handleRequiresNotifications(
    row.requires_signals_notifications,
  )
  rowObject.requires_signals_notifications = requiresSignalsNotifications
  if (notificationHelperText) {
    rowObject.notificationHelperText = notificationHelperText
  }
  const [requiresAccountSummaryNotifications, accountSummaryNotificationHelperText] = handleRequiresNotifications(
    row.requires_account_summary_notifications,
  )
  rowObject.requires_account_summary_notifications = requiresAccountSummaryNotifications
  if (accountSummaryNotificationHelperText) {
    rowObject.notificationHelperText = accountSummaryNotificationHelperText
  }
  if (row.credit_limit) {
    const creditLimit = parseInt(row.credit_limit)
    if (!isNaN(creditLimit)) {
      rowObject.credit_limit = creditLimit
    }
  }
  if (row.job_title) {
    rowObject.job_title = row.job_title
  }
  return rowObject
}
