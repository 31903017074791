import { Dispatch, FC, SetStateAction, useState } from "react"
import { Typography, Chip, Stack, InputBase, Button, Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"

const searchInputStyle = {
  alignItems: "center",
  borderRadius: "29px",
  backgroundColor: "neutral.light",
  border: "1px solid #666666",
  display: "flex",
  overflow: "hidden",
}

export const ChipField: FC<{
  terms: Set<string>
  setTerms: Dispatch<SetStateAction<Set<string>>>
  placeholder?: string
}> = ({ terms, setTerms, placeholder = "What do you want to search?" }) => {
  const [input, setInput] = useState("")
  const addTerm = () => {
    if (input.trim()) {
      setTerms((prevTerms) => new Set([...Array.from(prevTerms), input]))
      setInput("")
    }
  }

  return (
    <Stack
      direction="row"
      gap={0.5}
      alignItems="flex-start"
      paddingY={1}
      paddingX={1}
      sx={searchInputStyle}
      marginTop={2}
    >
      <Stack className="advanced-search-terms" direction="row" flexWrap="wrap" gap={0.5} paddingY="4px">
        {Array.from(terms).map((term) => (
          <Chip
            key={term}
            label={term}
            onDelete={() =>
              setTerms((prevTerms) => {
                prevTerms.delete(term)
                return new Set(prevTerms)
              })
            }
          />
        ))}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center" flexGrow="1" flexShrink="0" marginLeft="0 !important">
        <InputBase
          value={input}
          sx={{ flex: 1, minWidth: "22ch" }}
          inputProps={{
            "aria-label": `${placeholder}`,
            sx: {
              "&::placeholder": {
                opacity: "0.7",
              },
            },
          }}
          placeholder={placeholder}
          onChange={(event) => setInput(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter" && input.trim()) {
              addTerm()
            }
          }}
        />
        {input.trim() && (
          <>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                color: "white",
                flexShrink: "0",
                display: { xs: "none", sm: "flex" },
              }}
              disableElevation
              onClick={addTerm}
            >
              <Typography>Add Term</Typography>
            </Button>
            <Fab
              size="small"
              color="secondary"
              sx={{
                boxShadow: 0,
                color: "white",
                width: 36,
                height: 36,
                display: { xs: "flex", sm: "none" },
              }}
              onClick={addTerm}
            >
              <AddIcon />
            </Fab>
          </>
        )}
      </Stack>
    </Stack>
  )
}
