import { FC } from "react"
import { Stack, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import { useLocation, Link, useRouteMatch, Switch, Route } from "react-router-dom"
import { Salesforce } from "./salesforce"

export const OrganizationTab: FC = () => {
  const { url } = useRouteMatch()
  const locationArray = useLocation().pathname.split("/")
  const location = locationArray[locationArray.length - 1]

  return (
    <Stack direction="row" spacing={1} paddingTop={2}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ paddingRight: 8, borderRadius: 6 }}
            component={Link}
            to={`${url}/salesforce`}
            selected={location === "salesforce"}
          >
            <ListItemText primary="Salesforce" />
          </ListItemButton>
        </ListItem>
      </List>
      <Switch>
        <Route path={`${url}/salesforce`} component={Salesforce} />
      </Switch>
    </Stack>
  )
}
