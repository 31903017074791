import {
  Stack,
  IconButton,
  Typography,
  List,
  ListItem,
  TextField,
  Switch,
  FormControlLabel,
  InputLabel,
  Box,
  Select,
  MenuItem,
  SxProps,
  TextFieldProps,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

type ObjectFormDataType<T> = {
  [Property in keyof T]: T[Property]
}

export type EnumMappings = {
  label: string
  value: string | number
}[]
interface FieldMappings {
  label: string
  enumMappings?: EnumMappings
  disabled?: boolean
  sx?: SxProps
}

export type FieldAttributes = Record<string, FieldMappings>

export interface AddRemoveFormComponentForObjectProps<T> {
  objectName: string
  fieldNameAttributes?: FieldAttributes //Converts data.keys to human readable strings
  data: T[]
  handleChange: (value: T[]) => void
  giveEmptyObject: () => T
}

enum FormComponentTypes {
  STRING = "string",
  BOOLEAN = "boolean",
  NUMBER = "number",
  ENUM = "enum",
}

export const AddRemoveFormComponentForObject = <T extends ObjectFormDataType<T>>({
  objectName,
  fieldNameAttributes,
  data,
  handleChange,
  giveEmptyObject,
}: AddRemoveFormComponentForObjectProps<T>) => {
  /*
   * A reusable component for adding/removing objects from a list allowing you to set the values on each object in the Array.
   * If you want to have a select component for a field, you have to pass in fieldNameAttributes with an enumMappings object for the given key
   * Give empty object is a function that returns an empty object of the type you are using. Wasn't able to figure out how to do this generically
   * Passing in SX to the fieldAttributes impacts the Boxes that wrap the input components. Helpful for setting widths
   *
   * To correctly set fieldNameAttributes.enumMappings build an object from an enum like this:
   * export const EnumOptions = Object.keys(Enum)
   *    .filter((key) => isNaN(Number(key)))
   *    .map((key) => ({
   *    label: key,
   *    value: Enum[key as keyof typeof Enum],
   * }))
   */

  const localHandleDataChange = (index: number, key: keyof T, value: T[keyof T]) => {
    const newData = [...data]
    newData[index][key] = value
    handleChange(newData)
  }

  const handleRemove = (index: number) => {
    const newData = [...data]
    newData.splice(index, 1)
    handleChange(newData)
  }

  const handleAdd = () => {
    const newObject = giveEmptyObject()
    handleChange([...data, newObject])
  }

  const getFieldAttribute = (key: string, attribute: keyof FieldMappings) => {
    const value = fieldNameAttributes?.[key]?.[attribute] || undefined
    return value
  }

  const componentHandler = (
    key: string,
    value: T[keyof T],
    fieldMapping?: FieldMappings,
  ): [FormComponentTypes, boolean] | undefined => {
    // Decides whether we are dealing with an Enum, String, Boolean, or Number (or if we are dealing with a list of these)
    if (Array.isArray(value)) {
      const result = componentHandler(key, (value as Array<T[keyof T]>)[0], fieldMapping)
      if (result) {
        return [result[0], true]
      }
      undefined
    }
    if (fieldMapping && fieldMapping.enumMappings) {
      return [FormComponentTypes.ENUM, false]
    }
    if (typeof value === "string") {
      return [FormComponentTypes.STRING, false]
    }
    if (typeof value === "boolean") {
      return [FormComponentTypes.BOOLEAN, false]
    }
    if (typeof value === "number") {
      return [FormComponentTypes.NUMBER, false]
    }
    console.error(`Field ${key} is not a string, boolean, or number`)
  }

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{objectName}</Typography>
        <IconButton size="small" onClick={handleAdd}>
          <AddIcon />
        </IconButton>
      </Stack>
      <List>
        {data.map((value, index) => (
          <ListItem key={index}>
            <Stack direction="row" alignItems="center" key={index} spacing={2} width={"100%"}>
              {Object.keys(value).map(
                (key) =>
                  // (fieldNameAttributes && fieldNameAttributes[key] && fieldNameAttributes[key].enumMappings && (
                  (componentHandler(key, value[key as keyof T], fieldNameAttributes?.[key])?.[0] ===
                    FormComponentTypes.ENUM && (
                    <Box key={key} sx={getFieldAttribute(key, "sx") as SxProps}>
                      <InputLabel sx={{ marginLeft: 1 }}>{getFieldAttribute(key, "label") || key}</InputLabel>
                      <Select
                        disabled={Boolean(getFieldAttribute(key, "disabled"))}
                        size="small"
                        fullWidth
                        multiple={componentHandler(key, value[key as keyof T], fieldNameAttributes?.[key])?.[1]}
                        value={value[key as keyof T] as string}
                        onChange={(event) =>
                          localHandleDataChange(index, key as keyof T, event.target.value as unknown as T[keyof T])
                        }
                      >
                        {fieldNameAttributes?.[key].enumMappings?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )) ||
                  (componentHandler(key, value[key as keyof T], fieldNameAttributes?.[key])?.[0] ===
                    FormComponentTypes.STRING && (
                    <Box key={key} sx={getFieldAttribute(key, "sx") as SxProps}>
                      <InputLabel sx={{ marginLeft: 1 }}>{fieldNameAttributes?.[key]?.label || key}</InputLabel>
                      <TextField
                        disabled={Boolean(getFieldAttribute(key, "disabled"))}
                        value={value[key as keyof T] as string}
                        fullWidth
                        onChange={(event) =>
                          localHandleDataChange(index, key as keyof T, event.target.value as unknown as T[keyof T])
                        }
                        size="small"
                      />
                    </Box>
                  )) ||
                  (componentHandler(key, value[key as keyof T], fieldNameAttributes?.[key])?.[0] ===
                    FormComponentTypes.BOOLEAN && (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={Boolean(getFieldAttribute(key, "disabled"))}
                          checked={value[key as keyof T]}
                          onChange={(event) =>
                            localHandleDataChange(index, key as keyof T, event.target.checked as unknown as T[keyof T])
                          }
                          size="medium"
                          sx={getFieldAttribute(key, "sx") as SxProps}
                        />
                      }
                      label={fieldNameAttributes?.[key]?.label || key}
                      labelPlacement="top"
                    />
                  )) ||
                  (componentHandler(key, value[key as keyof T], fieldNameAttributes?.[key])?.[0] ===
                    FormComponentTypes.NUMBER && (
                    <Box key={key} sx={getFieldAttribute(key, "sx") as SxProps}>
                      <InputLabel sx={{ marginLeft: 1 }}>{fieldNameAttributes?.[key]?.label || key}</InputLabel>
                      <TextField
                        disabled={Boolean(getFieldAttribute(key, "disabled"))}
                        value={value[key as keyof T] as number}
                        type="number"
                        onChange={(event) =>
                          localHandleDataChange(
                            index,
                            key as keyof T,
                            parseInt(event.target.value) as unknown as T[keyof T],
                          )
                        }
                        size="small"
                      />
                    </Box>
                  )),
              )}
              <Box paddingTop={2}>
                <IconButton onClick={() => handleRemove(index)}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            </Stack>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export interface AddRemoveFormComponentProps {
  fieldName: string
  data: string[]
  handleChange: (data: string[]) => void
  textFieldProps?: TextFieldProps
}

export const AddRemoveFormComponent = ({
  fieldName,
  data,
  handleChange,
  textFieldProps,
}: AddRemoveFormComponentProps) => {
  const localHandleDataChange = (index: number, value?: string) => {
    if (value) {
      const newData = [...data]
      newData[index] = value
      handleChange(newData)
    } else {
      const newData = [...data]
      newData.splice(index, 1)
      handleChange(newData)
    }
  }
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{fieldName}</Typography>
        <IconButton size="small" onClick={() => handleChange([...data, ""])}>
          <AddIcon />
        </IconButton>
      </Stack>
      <List sx={{ width: "100%" }}>
        {data.map((value, index) => (
          <ListItem key={index}>
            <Stack direction="row" alignItems="center" key={index} width={"100%"}>
              <TextField
                value={value}
                onChange={(event) => localHandleDataChange(index, event.target.value)}
                label={fieldName}
                size="small"
                fullWidth
                {...(textFieldProps || {})}
              />
              <IconButton size="small" onClick={() => localHandleDataChange(index, undefined)}>
                <RemoveIcon />
              </IconButton>
            </Stack>
          </ListItem>
        ))}
      </List>
    </>
  )
}
