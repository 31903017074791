import { FC, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useCreateDashboard } from "../../api/dashboards"

export const NewDashboard: FC<{
  open: boolean
  onClose: any
  onSubmit: any
}> = ({ open, onClose, onSubmit }) => {
  const [nameInput, setNameInput] = useState("")
  const [descriptionInput, setDescriptionInput] = useState("")
  const { mutateAsync: createDashboard } = useCreateDashboard()

  let nameErrorText = ""
  if (nameInput.length > 100) {
    nameErrorText = "Name must be under 100 characters"
  }

  let descriptionErrorText = ""
  if (descriptionInput.length > 500) {
    descriptionErrorText = "Description must be under 500 characters"
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>New Dashboard</DialogTitle>
      <DialogContent>
        <Grid container item marginTop={2} spacing={2} direction="column">
          <Grid item>
            <TextField
              size="small"
              label="Name"
              value={nameInput}
              onChange={(event) => setNameInput(event.target.value)}
              fullWidth
              error={Boolean(nameErrorText)}
              helperText={nameErrorText}
            />
          </Grid>
          <Grid item>
            <TextField
              size="small"
              label="Description"
              value={descriptionInput}
              onChange={(event) => setDescriptionInput(event.target.value)}
              multiline
              fullWidth
              error={Boolean(descriptionErrorText)}
              helperText={descriptionErrorText}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            disabled={nameInput.length === 0 || Boolean(descriptionErrorText) || Boolean(nameErrorText)}
            onClick={async () => {
              const response = await createDashboard({
                name: nameInput,
                description: descriptionInput,
              })
              onSubmit(response.data?.new_dashboard?.id)
            }}
          >
            Confirm
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
