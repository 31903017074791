import { Button, Dialog, DialogActions, DialogTitle, Grid, Step, StepLabel, Stepper } from "@mui/material"
import { FC, useState } from "react"
import { useUpdateUserOnboarding } from "../../../api/users"
import { IndustryStep } from "./IndustryStep"
import { UseCaseStep } from "./UseCaseStep"

export const OnboardingWizard: FC<{
  open: boolean
}> = ({ open }) => {
  const { mutate: updateOnboarding, isLoading: updateInProgress } = useUpdateUserOnboarding()

  const [industry, setIndustry] = useState(0)
  const [industryOtherInput, setIndustryOtherInput] = useState("")
  const [useCase, setUseCase] = useState("")
  const [useCaseOtherInput, setUseCaseOtherInput] = useState("")

  const stepLabels = ["What's your industry?", "What's your use case?"]
  const components = [
    <IndustryStep
      key="industry"
      userIndustry={industry}
      setUserIndustry={setIndustry}
      otherInput={industryOtherInput}
      setOtherInput={setIndustryOtherInput}
    />,
    <UseCaseStep
      key="use-case"
      useCase={useCase}
      setUseCase={setUseCase}
      otherInput={useCaseOtherInput}
      setOtherInput={setUseCaseOtherInput}
    />,
  ]

  const [activeStep, setActiveStep] = useState(1)
  const isLastStep = stepLabels.length === activeStep
  const isFirstStep = 1 === activeStep

  const getStepContent: () => void = () => components[activeStep - 1]

  return (
    <Dialog open={open} onClose={() => 1 + 1} fullWidth maxWidth="md">
      <DialogTitle>Let us get to know you!</DialogTitle>
      <Grid padding={2} paddingTop={4}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {stepLabels.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {getStepContent()}
        <DialogActions sx={{ margin: 1, paddingRight: 2 }}>
          {!isFirstStep && (
            <Button onClick={() => setActiveStep(activeStep + -1)} variant={"outlined"}>
              Previous
            </Button>
          )}
          <Button
            onClick={
              isLastStep
                ? () =>
                    updateOnboarding({
                      industry_id: industry,
                      industry_other: industryOtherInput,
                      use_case: useCase,
                      use_case_other: useCaseOtherInput,
                    })
                : () => setActiveStep(activeStep + 1)
            }
            variant={isLastStep ? "contained" : "outlined"}
            disabled={(activeStep === 1 ? industry === 0 : useCase === "") || updateInProgress}
          >
            {updateInProgress ? "Loading...." : isLastStep ? "Submit" : "Next"}
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  )
}
