import { FC, useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  FormLabel,
} from "@mui/material"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import ErrorIcon from "@mui/icons-material/Error"
import { useToast } from "../shared/ToastHook"
import { useModalKey, useCloseModalKey } from "../shared/OpenModalHook"
import { useFetchRoles } from "../../api/roles"
import { useCreateUser } from "../../api/users"

export const createUserModalKey = "CREATE_USER_MODAL"

export const CreateUserDialog: FC = () => {
  const open = useModalKey(createUserModalKey)
  const closeModal = useCloseModalKey(createUserModalKey)
  const [emailInput, setEmailInput] = useState("")
  const [signalsNotifications, setSignalsNotifications] = useState(true)
  const [accountSummaryNotifications, setAccountSummaryNotifications] = useState(true)
  const setToast = useToast()
  const { data: rolesData } = useFetchRoles()
  const [checked, setChecked] = useState<Record<string, boolean>>({})
  useEffect(() => {
    if (rolesData) {
      const newCheckedState: Record<string, boolean> = {}
      rolesData.forEach((role) => (newCheckedState[role] = false))
      setChecked(newCheckedState)
    }
  }, [rolesData])

  const handleToggle = (role: string) => () => {
    const newChecked = { ...checked }
    newChecked[role] = !newChecked[role]
    setChecked(newChecked)
  }

  const close = () => {
    closeModal()
    setEmailInput("")
    setSignalsNotifications(true)
    setAccountSummaryNotifications(true)
    if (rolesData) {
      const newCheckedState: Record<string, boolean> = {}
      rolesData.forEach((role) => (newCheckedState[role] = false))
      setChecked(newCheckedState)
    }
  }

  const { mutate: createUser, isLoading } = useCreateUser(
    () => {
      setToast("User Successfully Created", 3500, <TaskAltIcon />)
      close()
    },
    (errorMessage: string) => setToast(errorMessage, 5000, <ErrorIcon />, "error"),
  )

  return (
    <Dialog open={open} onClose={close} fullWidth sx={{ overflowY: "unset" }}>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent sx={{ overflowY: "unset" }}>
        <TextField
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
          id="user-email-input"
          label="Email address"
          fullWidth
        />
        <FormLabel>Assigned Roles:</FormLabel>
        <FormGroup sx={{ marginLeft: 2 }}>
          {rolesData?.map((role) => (
            <FormControlLabel
              key={role}
              control={<Checkbox checked={checked[role]} onClick={handleToggle(role)} />}
              label={role}
            />
          ))}
        </FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={signalsNotifications}
              onChange={(event: any) => setSignalsNotifications(event.target.checked)}
            />
          }
          label="Requires Signals Notifications?"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={accountSummaryNotifications}
              onChange={(event: any) => setAccountSummaryNotifications(event.target.checked)}
            />
          }
          label="Requires Account Summary Notifications?"
        />
        <DialogActions>
          <Button
            onClick={() =>
              createUser({
                email: emailInput,
                roles: Object.keys(checked).filter((key) => checked[key]),
                requires_signals_notifications: signalsNotifications,
                requires_account_summary_notifications: accountSummaryNotifications,
              })
            }
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Confirm"}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
