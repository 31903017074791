import {
  Dialog,
  Stepper,
  Step,
  StepLabel,
  DialogActions,
  Button,
  Grid,
  DialogTitle,
  Divider,
  DialogContent,
} from "@mui/material"
import { FC } from "react"

export const Wizard: FC<{
  stepLabels: string[]
  components: any[]
  open: boolean
  onClose: () => void
  activeStep: any
  setActiveStep: any
  disableSubmit: boolean
  onSubmit: any
}> = ({ stepLabels, components, open, onClose, activeStep, setActiveStep, disableSubmit, onSubmit }) => {
  const isFirstStep = 1 === activeStep
  const getStepContent: () => any = () => components[activeStep - 1]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{ ".MuiDialog-paper": { position: "absolute", top: 20 } }}
    >
      <Grid padding={2} paddingTop={4} sx={{ backgroundColor: "neutral.light" }}>
        <DialogTitle sx={{ paddingBottom: 5 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepLabels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Divider sx={{ paddingTop: 3 }} />
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>{getStepContent()}</DialogContent>
        <DialogActions sx={{ margin: 1, paddingRight: 2 }}>
          {!isFirstStep && (
            <>
              <Button onClick={() => setActiveStep(activeStep + -1)} variant="outlined">
                Previous
              </Button>
              <Button onClick={onSubmit} variant="contained" disabled={!disableSubmit}>
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Grid>
    </Dialog>
  )
}
