import { FC, useEffect, useState } from "react"
import { useCloseModalKey, useModalKey } from "./OpenModalHook"
import { useCheckSpam } from "@src/api/meetings"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { getPrompts } from "@src/api/admin/prompts"
import { Prompt } from "@src/interfaces/prompt"
import { useFetchMeetings } from "@src/api/admin/meetings"
import { TableRowsLoader } from "./Table"

export const SPAM_CHECKER_MODAL_KEY = "SPAM_CHECKER_MODAL_KEY"

interface SpamCheckerModalProps {
  meetingIds: number[]
  handleClose: () => void
}

export const SpamCheckerModal: FC<SpamCheckerModalProps> = ({ meetingIds, handleClose }) => {
  const [customPrompt, setCustomPrompt] = useState<string>()
  const [spamPrompt, setSpamPrompt] = useState<Prompt>()
  const open = useModalKey(SPAM_CHECKER_MODAL_KEY)
  const closeModal = useCloseModalKey(SPAM_CHECKER_MODAL_KEY)
  // Refetch here to avoid auto check spam
  const { data: spamCheckerData, refetch, isLoading, isRefetching } = useCheckSpam(meetingIds, customPrompt)
  const { data: promptsData } = getPrompts()
  const { data: meetingsData, isLoading: meetingsLoading } = useFetchMeetings({
    veronika: false,
    meetingIds: meetingIds,
  })
  const closeModalHelper = () => {
    setCustomPrompt(undefined)
    handleClose()
    closeModal()
  }

  const [rows, setRows] = useState<
    { id: number; title: string; is_spam?: boolean; confidence_score?: number; reasoning?: string }[]
  >([])

  useEffect(() => {
    if (promptsData) {
      const spamPrompt = promptsData.find((prompt) => prompt.name === "Spam Prompt")
      setSpamPrompt(spamPrompt)
    }
  }, [promptsData])

  useEffect(() => {
    if (meetingsData) {
      const rows = meetingsData.meetings.map((meeting) => ({
        id: meeting.id,
        title: meeting.title,
        is_spam: undefined,
        confidence_score: undefined,
        reasoning: undefined,
      }))
      setRows(rows)
    }
  }, [meetingsData])

  useEffect(() => {
    if (spamCheckerData) {
      const newRows = rows.map((row) => {
        const spamData = spamCheckerData.find((data) => data.meeting_id === row.id)
        if (spamData) {
          return {
            ...row,
            is_spam: spamData.is_spam,
            confidence_score: spamData.confidence_score,
            reasoning: spamData.reasoning,
          }
        }
        return row
      })
      setRows(newRows)
    }
  }, [spamCheckerData])

  return (
    <Dialog open={open} onClose={closeModalHelper} fullWidth maxWidth="lg">
      <DialogTitle>Spam Checker</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {meetingsLoading ? (
            <Skeleton variant="rectangular" height={50} />
          ) : (
            <TableContainer component={Paper} sx={{ maxHeight: "50vh" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Spam</TableCell>
                    <TableCell>Confidence Score</TableCell>
                    <TableCell>Reasoning</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      {isLoading || isRefetching ? (
                        <TableRowsLoader />
                      ) : (
                        <TableCell>{row.is_spam !== undefined ? (row.is_spam ? "Yes" : "No") : undefined}</TableCell>
                      )}
                      {isLoading || isRefetching ? <TableRowsLoader /> : <TableCell>{row.confidence_score}</TableCell>}
                      {isLoading || isRefetching ? <TableRowsLoader /> : <TableCell>{row.reasoning}</TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {spamPrompt && <Typography variant="h6">Current Spam Prompt: {spamPrompt.text}</Typography>}
          <TextField
            label="Custom Prompt"
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModalHelper} variant="outlined">
          Close
        </Button>
        <Button onClick={() => refetch()} variant="outlined">
          Check Spam
        </Button>
      </DialogActions>
    </Dialog>
  )
}
