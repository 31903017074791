import {
  Dialog,
  DialogTitle,
  Link,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material"
import { FC } from "react"
import { useModalKey, useCloseModalKey } from "../shared/OpenModalHook"
import { theme } from "@src/theme"
export const scheduleKey = "OPEN_SCHEDULE_MODAL"

export const ScheduleDemoModal: FC = () => {
  const open = useModalKey(scheduleKey)
  const closeModalKey = useCloseModalKey(scheduleKey)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog open={open} onClose={closeModalKey} sx={{ ".MuiDialog-paper": { padding: 2 } }} fullWidth>
      <DialogTitle>
        <Typography component="span" variant="h1">
          Want to explore more?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="span" variant="h6">
          Schedule a demo or start your free trial.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={isSmallScreen ? "column" : "row"}
          spacing={2}
          justifyContent={isSmallScreen ? "center" : undefined}
          width={isSmallScreen ? "100%" : "inherit"}
        >
          <Button
            sx={{
              fontSize: { xs: 15, sm: 15 },
              "&:hover": {
                backgroundColor: "#FFF",
              },
            }}
            variant="contained"
            type="button"
            size="large"
            component={Link}
            color="primary"
            href="https://meetings.hubspot.com/jeremy-becker/jeremy-30-minutes-only"
          >
            Schedule Demo
          </Button>
          <Button
            sx={{
              color: "neutral.light",
              fontSize: { xs: 15, sm: 15 },
              "&:hover": {
                backgroundColor: "#FFF",
              },
            }}
            color="secondary"
            variant="contained"
            component="a"
            size="large"
            href="https://www.cloverleaf.ai/get-started?utm_source=cloverleaf&utm_medium=website&utm_campaign=trial_search"
          >
            Start Your Free Trial
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
