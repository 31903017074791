import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material"
import { FC, useMemo, useEffect, useState } from "react"
import { getDialogInfo } from "../../config/campaignConfig"
import { CampaignSnackBar } from "../Campaigns/CampaignSnackBar"

export const CampaignDialog: FC<{
  campaign?: any
  campaigns?: any
  setDialogOpen: (newValue: boolean) => void
  onComplete?: () => void
  requestStatus: boolean
  onSubmit: (value?: any) => void
  dialogOpen: boolean
  dialogAction: string
  defaultValue?: any
}> = ({
  campaign,
  dialogOpen,
  setDialogOpen,
  campaigns,
  onComplete,
  requestStatus,
  onSubmit,
  dialogAction,
  defaultValue,
}) => {
  const [snackOpen, setSnackOpen] = useState(false)
  const [currentOperation, setCurrentOperation] = useState("")
  const [value, setValue] = useState(defaultValue)
  useEffect(() => setValue(defaultValue), [defaultValue])

  useEffect(() => setSnackOpen(requestStatus), [requestStatus])

  const dialogInfo = useMemo(() => {
    let altText: string | React.ReactNode = ""
    let extraInput: React.ReactNode = null
    const { title: title, text: text } = getDialogInfo(campaign, dialogAction)
    switch (dialogAction) {
      case "cloneMany":
        altText = (
          <>
            {campaigns?.map((campaign: any) => (
              <Typography variant="body1" key={campaign.id}>
                {campaign?.name}
              </Typography>
            ))}
          </>
        )
        break
      case "update":
        if (campaign?.assignees?.length > 1) {
          altText =
            "This campaign has been assigned to other users. Editing the campaign settings will also edit the campaign" +
            " for everyone who was assigned the campaign."
        }
        break
      case "delete":
      case "deleteMany":
        if (campaign?.assignees?.length > 0 || campaigns?.some((c: any) => c?.assignees?.length > 0)) {
          altText =
            "This campaign is assigned to other users. " +
            "Deleting the campaign will also remove notifications for all users that the campaign is assigned to."
        }
        break
      case "notify":
        extraInput = (
          <RadioGroup row value={value} onChange={(event) => setValue(event.target.value)}>
            <FormControlLabel value="Daily" control={<Radio />} label="Daily" />
            <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
            <FormControlLabel value="Unsubscribed" control={<Radio />} label="Never" />
          </RadioGroup>
        )
        break
    }
    return { title, text, altText, extraInput }
  }, [dialogOpen, value])

  const onClickHandler = () => {
    onSubmit(value)
    setDialogOpen(false)
    setCurrentOperation(dialogAction)
  }

  return (
    <>
      <Dialog open={dialogOpen} onClose={onComplete}>
        <DialogTitle>{dialogInfo.title}</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>{dialogInfo.text}</DialogContentText>
          <DialogContentText gutterBottom>{dialogInfo.altText}</DialogContentText>
          {dialogInfo.extraInput}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickHandler}>Confirm</Button>
          <Button onClick={onComplete}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <CampaignSnackBar
        isOpen={snackOpen}
        operation={currentOperation}
        onComplete={() => {
          setSnackOpen(false)
          if (onComplete) {
            onComplete()
          }
        }}
      />
    </>
  )
}
