import {
  Grid,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Skeleton,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material"
import { Dispatch, FC, SetStateAction, useContext, useState } from "react"
import { AdvancedSearch, AdvancedSearchTypes } from "./AdvancedSearch"
import { geographySelectorConfig } from "./config"
import { GeographySelectorMultiple } from "./Selectors/Multiple/Geographies"
import { UserSelector } from "./UserSelector"
import { ChannelSelectorMultiple } from "./Selectors/Multiple/Channel"
import { BasicFilterParams } from "./Selectors/interface"
import { useFetchGeographies } from "../../api/geographies"
import { useFetchOrganizations } from "../../api/organizations"
import { TypeSelectorMultiple } from "./Selectors/Multiple/Types"
import { RangeSlider } from "./Selectors/Multiple/Range"
import { FilterParams } from "../Home/interface"
import { EmailCadence } from "../../interfaces/campaign"
import { EnableGeneralBriefing } from "./EnableGeneralBriefing"
import { PersonalBriefingPromptSelector } from "./PersonalBriefingPromptSelector"
import { useIsBriefingsEnabled } from "../../api/users"
import { useFetchEstimateGeneralBriefingCost } from "../../api/campaigns"
import { useOpenModalKey } from "./OpenModalHook"
import { WarningDialog } from "./EnableGeneralBriefing/WarningDialog"
import { CampaignContext } from "../Campaigns/CampaignListItem"

const filterSx = {
  flexGrow: "1",
  minWidth: "0",
  ".MuiOutlinedInput-root": {},
}

export interface CampaignTypes {
  campaign_id?: number
  filterParams: FilterParams
  searchTerms: string[]
  mustIncludeTerms: string[]
  emailCadence: string
  proximity: number | null
  exclude: boolean
  generalBriefing: boolean
  briefings: Set<number>
  userId?: string
  filterNameInput?: string
  objectSetters: {
    setFilterParams: Dispatch<SetStateAction<FilterParams>>
    setSearchTerms: Dispatch<SetStateAction<string[]>>
    setMustIncludeTerms: Dispatch<SetStateAction<string[]>>
    setEmailCadence: Dispatch<SetStateAction<EmailCadence>>
    setUserId: Dispatch<SetStateAction<string>>
    setFilterNameInput: Dispatch<SetStateAction<string>>
    setProximity: Dispatch<SetStateAction<number | null>>
    setExclude: Dispatch<SetStateAction<boolean>>
    setGeneralBriefing: Dispatch<SetStateAction<boolean>>
    setBriefings: Dispatch<SetStateAction<Set<number>>>
  }
}

export const CampaignEditForm: FC<{
  campaignData: CampaignTypes
  isEditing?: boolean
  showUsers?: boolean
  showNameField?: boolean
  isSubscriptionEditForm?: boolean
}> = ({ campaignData, isEditing, showUsers, showNameField = false, isSubscriptionEditForm }) => {
  const campaignContext = useContext(CampaignContext) // need this to get campaign id
  const {
    searchTerms,
    mustIncludeTerms,
    filterParams,
    emailCadence,
    filterNameInput,
    userId,
    proximity,
    exclude,
    briefings,
  } = campaignData
  const {
    setFilterParams,
    setSearchTerms,
    setMustIncludeTerms,
    setEmailCadence,
    setFilterNameInput,
    setUserId,
    setProximity,
    setExclude,
    setBriefings,
  } = campaignData.objectSetters
  const { data: organizationsData } = useFetchOrganizations()
  const { data, isLoading: geographiesIsLoading } = useFetchGeographies()
  const [tagsRef, setTagsRef] = useState(null)
  const [showBriefingSelector, setShowBriefingSelector] = useState(Boolean(briefings.size))
  const { data: briefingsEnabled } = useIsBriefingsEnabled()
  const { data: estimatedCost } = useFetchEstimateGeneralBriefingCost(campaignContext.campaign.id)
  let geographies: { states: any[]; counties: any[]; cities: any[] }
  if (data) {
    geographies = data
  } else {
    geographies = { states: [], counties: [], cities: [] }
  }

  const personalBriefingWarningModalKey = `PERSONAL_BRIEFING_WARNING_MODAL_KEY_${campaignContext.campaign.id}`
  const openWarning = useOpenModalKey(personalBriefingWarningModalKey)

  const advanced_search_data: AdvancedSearchTypes = {
    filterParams,
    searchTerms: searchTerms,
    mustIncludeTerms: mustIncludeTerms,
    searchInputTagsRef: tagsRef,
    isSubscriptionEditForm,
    proximity,
    exclude,
    briefings,
    objectSetters: {
      setFilterParams,
      setSearchTerms: setSearchTerms,
      setMustIncludeTerms: setMustIncludeTerms,
      setSearchInputTagsRef: setTagsRef,
      setProximity,
      setExclude,
      setBriefings,
    },
  }

  return (
    <Grid container spacing={2}>
      {showNameField && (
        <Grid item xs={12} marginTop={1}>
          <TextField
            value={filterNameInput}
            onChange={(event) => (setFilterNameInput ? setFilterNameInput(event.target.value) : null)}
            disabled={!isEditing}
            label="Campaign Name"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <AdvancedSearch data={advanced_search_data} />
      </Grid>
      <Grid container item spacing={1} xs={60} columns={60}>
        {geographySelectorConfig.map((geographySelector) => (
          <Grid item xs={12} md={geographySelector.xs} key={geographySelector.type}>
            {geographiesIsLoading ? (
              <Skeleton height={56} variant="rectangular" />
            ) : (
              <GeographySelectorMultiple
                options={geographies as BasicFilterParams}
                label={geographySelector.label}
                type={geographySelector.type}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                sx={filterSx}
                size="small"
              />
            )}
          </Grid>
        ))}
        <Grid item xs={11}>
          <TypeSelectorMultiple
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            sx={filterSx}
            size="small"
          />
        </Grid>
        <Grid item xs={15}>
          {organizationsData?.organizations ? (
            <ChannelSelectorMultiple
              options={organizationsData?.organizations}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              sx={filterSx}
              size="small"
            />
          ) : (
            <Skeleton height={56} variant="rectangular" />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <RangeSlider
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            filterType="city_population"
            label="City Population"
          />
          <RangeSlider
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            filterType="county_population"
            label="County Population"
          />
          <RangeSlider
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            filterType="city_income"
            label="City Household Income"
          />
          <RangeSlider
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            filterType="county_income"
            label="County Household Income"
          />
        </Stack>
      </Grid>
      <Grid item minWidth="24ch">
        <FormControl fullWidth>
          <InputLabel id="notification-cadence">Notify By Email</InputLabel>
          <Select
            labelId="notification-cadence"
            id="notification-cadence"
            value={emailCadence}
            label="Notify By Email"
            onChange={(e) => setEmailCadence(e.target.value as EmailCadence)}
          >
            <MenuItem value="Unsubscribed">Unsubscribed</MenuItem>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {isEditing && briefingsEnabled && (
        <>
          <Grid item alignContent="center">
            <EnableGeneralBriefing
              generalBriefing={{
                briefing: campaignData.generalBriefing,
                setBriefing: campaignData.objectSetters.setGeneralBriefing,
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showBriefingSelector}
                  onChange={(_event, checked) => {
                    if (checked) {
                      openWarning()
                    } else {
                      setBriefings(new Set())
                      setShowBriefingSelector(false)
                    }
                  }}
                />
              }
              label="Enable Personal Briefings"
            />
          </Grid>
          <Grid item xs={12}>
            <WarningDialog
              modalKey={personalBriefingWarningModalKey}
              onCancel={() => setShowBriefingSelector(false)}
              onSubmit={() => setShowBriefingSelector(true)}
              personalBriefingWarning
            />
            {showBriefingSelector && (
              <>
                <PersonalBriefingPromptSelector selectedPrompts={briefings} setSelectedPrompts={setBriefings} />
                <Stack direction="row" spacing={1}>
                  <Typography>Estimated Cost:</Typography>
                  <Typography>
                    {estimatedCost ? (briefings.size * estimatedCost.cost).toFixed(2) : <Skeleton width="4ch" />}
                  </Typography>
                </Stack>
              </>
            )}
          </Grid>
        </>
      )}
      {showUsers && (
        <Grid item xs={12}>
          <UserSelector setUser={setUserId} userValue={userId} />
        </Grid>
      )}
    </Grid>
  )
}
