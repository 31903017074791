import { FC, useMemo, useState } from "react"
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Paper, Stack } from "@mui/material"
import { useOpenModalKey } from "../../shared/OpenModalHook"
import { CreatePersonModal, CREATE_PERSON_MODAL_KEY } from "./createPersonModal"
import { Layout } from "../../shared/Layout"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import { useLocation } from "react-router-dom"
import useSetUrlParamState from "../../shared/UseSetUrlParamState"
import { Clear } from "@mui/icons-material"
import { VoiceModal } from "@src/components/shared/VoicesModal"
import { PersonsDataGrid } from "./personsDataGrid"
import { useFetchPersons } from "@src/api/admin/persons"
import { MergePeopleModal } from "./mergePersonModal"

export type AdminPersonPageParams = {
  name?: string
  page: number
  count: number
  title?: string
  organization?: string
  sort_by?: string
  sort_order?: string
}

export const Persons: FC = () => {
  const open = useOpenModalKey(CREATE_PERSON_MODAL_KEY)
  const params = new URLSearchParams(useLocation().search)
  const { data, isLoading: searchIsLoading } = useFetchPersons(params, true)

  const filterParams: AdminPersonPageParams = useMemo(
    () => ({
      name: params.get("name") || undefined,
      page: parseInt(params.get("page") || "0"),
      count: parseInt(params.get("count") || "25"),
      title: params.get("title") || undefined,
      organization: params.get("organization") || undefined,
      sort_by: params.get("sort_by") || undefined,
      sort_order: params.get("sort_order") || undefined,
    }),
    [params],
  )

  const [searchName, setSearchName] = useState<string>(filterParams.name || "")
  const [searchTitle, setSearchTitle] = useState<string>(filterParams.title || "")
  const [searchOrganization, setSearchOrganization] = useState<string>(filterParams.organization || "")

  const setFilterParams = useSetUrlParamState<AdminPersonPageParams>()

  return (
    <Layout title="Persons" actions={[{ text: "Create", icon: <AddIcon />, action: open }]}>
      <Stack direction="row" spacing={2} sx={{ marginTop: 2 }} width={"100%"} alignContent={"center"}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Name search</InputLabel>
          <OutlinedInput
            value={searchName}
            onChange={(event) => setSearchName(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterParams({ ...filterParams, name: searchName, page: 0 })
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setFilterParams({ ...filterParams, name: searchName, page: 0 })} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Title search</InputLabel>
          <OutlinedInput
            value={searchTitle}
            onChange={(event) => setSearchTitle(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterParams({ ...filterParams, title: searchTitle, page: 0 })
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setFilterParams({ ...filterParams, title: searchTitle, page: 0 })}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Organization search</InputLabel>
          <OutlinedInput
            value={searchOrganization}
            onChange={(event) => setSearchOrganization(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setFilterParams({ ...filterParams, organization: searchOrganization, page: 0 })
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setFilterParams({ ...filterParams, organization: searchOrganization, page: 0 })}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {/* Clear form */}
        <IconButton
          title="Clear all search filters"
          onClick={() => {
            setSearchName("")
            setSearchTitle("")
            setSearchOrganization("")
            setFilterParams({ ...filterParams, name: undefined, title: undefined, organization: undefined, page: 0 })
          }}
        >
          <Clear />
        </IconButton>
      </Stack>
      <Paper sx={{ marginTop: 2 }}>
        <PersonsDataGrid
          filterParams={filterParams}
          data={data || { total: -1, persons: [] }}
          loading={searchIsLoading}
        />
      </Paper>
      <CreatePersonModal />
      <VoiceModal />
      <MergePeopleModal />
    </Layout>
  )
}
