import { FC } from "react"
import { Grid, TextField } from "@mui/material"
import { GeographySelector } from "../../shared/Selectors/Single/Geographies"
import { useFetchGeographiesAdmin } from "../../../api/admin/geographies"

export const EditLocationForm: FC<{
  type: string
  nameInput: any
  setNameInput: any
  filterParams: any
  setFilterParams: any
}> = ({ filterParams, setFilterParams, nameInput, setNameInput, type }) => {
  const { data: geographiesData } = useFetchGeographiesAdmin()

  return (
    <Grid container item marginTop={2} spacing={2} direction="column">
      {(type === "cities" || type === "counties") && (
        <Grid item>
          <GeographySelector
            options={geographiesData}
            label="State"
            type="states"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Grid>
      )}
      {type === "cities" && (
        <Grid item>
          <GeographySelector
            options={geographiesData}
            label="County"
            type="counties"
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        </Grid>
      )}
      <Grid item>
        <TextField
          size="small"
          label="Name"
          value={nameInput}
          onChange={(event: any) => setNameInput(event.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}
