import { FC, Dispatch, SetStateAction, useState, useEffect } from "react"
import { Chip, Button, TextField, Stack } from "@mui/material"

const scrollbarStyles = {
  "div::-webkit-scrollbar": {
    display: "none",
  },
}

export const TagsSearchInput: FC<{
  tags: string[]
  setTags: Dispatch<SetStateAction<string[]>>
  setTagsRef: any
  showSubmit?: boolean
  altActionButton?: any
  advancedSearchCheckbox?: any
  isSubscriptionEditForm?: boolean
  numberKeywordsToValidate?: number
  placeholder?: string
  isAdvancedSearch?: boolean
}> = ({
  tags,
  setTags,
  setTagsRef,
  altActionButton,
  advancedSearchCheckbox,
  showSubmit = false,
  isSubscriptionEditForm = false,
  numberKeywordsToValidate = null,
  placeholder = "Enter a search term/phrase",
  isAdvancedSearch = false,
}) => {
  const [inputText, setInputText] = useState("")

  const showSubmitButton = showSubmit //must pass tagsRef to show submit button

  useEffect(() => {
    setTags((prevTags) => prevTags)
  }, [])

  const addTag = () => {
    if (inputText) {
      const removeComma = inputText.replace(",", " ").trim()
      if (!tags.includes(removeComma) && removeComma.replace(/\s/g, "").length) {
        setTags((prevTags) => [...prevTags, removeComma])
      }
      setInputText("")
      setTagsRef(null)
    }
  }

  const handleDelete = (tag: string) => {
    const temp = [...tags]
    temp.splice(temp.indexOf(tag), 1)
    setTags(temp)
  }

  const updateTextField = (value: string) => {
    setInputText(value)
    setTagsRef(value)
  }

  return (
    <>
      <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
        <TextField
          fullWidth
          placeholder={placeholder}
          sx={{ borderWidth: "4px" }}
          inputProps={{ style: { borderWidth: "4px" } }}
          onChange={(event) => updateTextField(event.target.value)}
          value={inputText}
          label={numberKeywordsToValidate && `${numberKeywordsToValidate} max terms/phrases`}
          InputLabelProps={{ shrink: true }}
          error={Boolean(numberKeywordsToValidate) && tags.length === 0}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              addTag()
            }
          }}
          disabled={isAdvancedSearch && tags.length > 0}
        />
        {showSubmitButton && (
          <Stack spacing={1} alignItems="center" direction="row" flexWrap="wrap">
            <Button
              onClick={addTag}
              color="primary"
              variant="outlined"
              type="button"
              size="medium"
              sx={{ whiteSpace: "nowrap" }}
              disabled={isAdvancedSearch && tags.length > 0}
            >
              Add Term
            </Button>
            {altActionButton}
            {advancedSearchCheckbox}
          </Stack>
        )}
      </Stack>
      <Stack>
        <Stack sx={scrollbarStyles} direction="row" gap={1} flexWrap="wrap">
          {tags.map((tag) => (
            <Chip key={tag} label={tag} onDelete={() => handleDelete(tag)} />
          ))}
        </Stack>
      </Stack>
    </>
  )
}
