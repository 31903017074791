import { Dispatch, FC, SetStateAction, useState } from "react"
import { Stepper, Step, StepLabel } from "@mui/material"
import { OrganizationWizardContext, NewOrganization, generateSimpleActions } from "./Context"
import { VerifyUrl } from "./VerifyUrl"
import { MeetingIdentifier } from "./MeetingIdentifier"
import { IdentifyData } from "./IdentifyData"
import { GeneralScraperDetails } from "../../../../api/interfaces"
import { GeneralScraperDetailsContext } from "../OrganizationForm/Context"

export const Wizard: FC = () => {
  const [step, setStep] = useState(0)
  const [value, setValue] = useState<NewOrganization>({
    name: "",
    url: "",
    additionalUrls: [],
    navigationMethod: "uri",
    meetingIdentifier: "",
    head: false,
    identifierActions: [],
    meetingIdentifierRegex: false,
    meetingIdentifierIframe: false,
    separatedMeetingHtml: [],
    titleIdentifier: "",
    titleIndex: 0,
    scraperActions: generateSimpleActions("", 0),
    setStep,
    meetingBrowserWaitCode: undefined,
    jsonReplaceCode: "",
  })

  // Only using this for identifier actions
  // TODO: refactor to be used with OrganizationFormWrapper so there is 1
  // context for both Wizard and Org Form
  const [initialActions, setInitialActions] = useState<GeneralScraperDetails>({
    actions: [],
    navigation_method: "uri",
    additional_urls: [],
    meeting_identifier: "",
    meeting_identifier_regex: false,
    iframe: false,
    head: false,
    meeting_browser_wait_code: "",
    json_code: "",
    identifier_actions: [{ identifier_action: "", identifier_action_selection: "" }],
  })

  return (
    <OrganizationWizardContext.Provider value={{ value, setValue }}>
      <GeneralScraperDetailsContext.Provider
        value={{
          value: initialActions,
          setValue: setInitialActions as Dispatch<SetStateAction<GeneralScraperDetails | string>>,
        }}
      >
        <Stepper activeStep={step} sx={{ marginY: 2 }}>
          <Step>
            <StepLabel>Verify Url</StepLabel>
          </Step>
          <Step>
            <StepLabel>Set Meeting Identifier</StepLabel>
          </Step>
          <Step>
            <StepLabel>Identify Data</StepLabel>
          </Step>
        </Stepper>
        {step === 0 && <VerifyUrl />}
        {step === 1 && <MeetingIdentifier />}
        {step === 2 && <IdentifyData />}
      </GeneralScraperDetailsContext.Provider>
    </OrganizationWizardContext.Provider>
  )
}
