import { FC, useState, useEffect } from "react"
import { Button, DialogContent, DialogActions, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { FixedSizeList as List } from "react-window"
import { useInviteUser } from "../../../api/userOrganizations"

interface UsersSendInvites {
  id: number
  email: string
  sendInvite: boolean
}

export const SendInvitesModal: FC<{
  users: { id: number; email: string }[]
  onSuccess?: () => void
}> = ({ users, onSuccess }) => {
  const [userList, setUserList] = useState<UsersSendInvites[]>([])
  useEffect(() => setUserList(users.map((user) => ({ ...user, sendInvite: false }))), [users])
  const setUsersSendInvite = (value: boolean) => {
    setUserList((prevUsers) => {
      return prevUsers.map((userData) => ({ ...userData, sendInvite: value }))
    })
  }

  const { mutate: inviteUser, isLoading: inviteUsersIsLoading } = useInviteUser(onSuccess)

  const sendInviteIsChecked = userList.every((userData) => userData.sendInvite)
  const sendInviteIsIndeterminate = !sendInviteIsChecked && !userList.every((userData) => !userData.sendInvite)

  const selectedEmails = userList.filter((user) => user.sendInvite).map((user) => user.email)
  return (
    <>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={sendInviteIsChecked}
              indeterminate={sendInviteIsIndeterminate}
              onChange={() => {
                if (sendInviteIsChecked || sendInviteIsIndeterminate) {
                  setUsersSendInvite(false)
                } else {
                  setUsersSendInvite(true)
                }
              }}
            />
          }
          label="Email"
        />
        <List height={400} itemCount={userList.length} itemSize={40} width={550} innerElementType={FormGroup}>
          {({ index, style }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={userList[index].sendInvite}
                  onChange={(event) => {
                    setUserList((prevUsers) => {
                      const copy = [...prevUsers]
                      copy.splice(index, 1, {
                        ...userList[index],
                        sendInvite: event.target.checked,
                      })
                      return copy
                    })
                  }}
                />
              }
              label={userList[index].email}
              sx={style}
            />
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button disabled={!selectedEmails.length} onClick={() => inviteUser({ emails: selectedEmails })}>
          {inviteUsersIsLoading ? "Loading..." : "Send Invites"}
        </Button>
      </DialogActions>
    </>
  )
}
