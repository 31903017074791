import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { CampaignEditForm, CampaignTypes } from "../shared/CampaignEditForm"
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material"
import { CampaignDialog } from "../shared/CampaignDialog"
import { useUpdateCampaign } from "../../api/campaigns"
import { EmailCadence, ICampaignState } from "../../interfaces/campaign"
import { unpackSetters } from "../shared/unpackSetters"
import { FilterParams } from "../Home/interface"
import { defaultLocationFilterParams } from "../shared/config"
import { CampaignContext } from "./CampaignListItem"

export const ManageCampaignDialog: FC<{
  campaignState: ICampaignState
  setCampaignState: Dispatch<SetStateAction<ICampaignState>>
  campaign: any
  onCancel: any
}> = ({ campaignState, setCampaignState, campaign, onCancel }) => {
  const { isEditing, dialogAction } = campaignState
  const { setIsEditing, setDialogAction } = unpackSetters(campaignState, setCampaignState)
  const { mutate: updateCampaign, isSuccess: updateSuccess } = useUpdateCampaign(campaign?.id)
  const [emailCadence, setEmailCadence] = useState<EmailCadence>("Unsubscribed")
  const [campaignName, setCampaignName] = useState("")
  const [filterParams, setFilterParams] = useState<FilterParams>(defaultLocationFilterParams)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [filterTermsInput, setFilterTermsInput] = useState<string[]>([])
  const [mustIncludeInput, setMustIncludeInput] = useState<string[]>([])
  const [proximity, setProximity] = useState<number | null>(null)
  const [exclude, setExclude] = useState(false)
  const [generalBriefing, setGeneralBriefing] = useState<boolean>(false)
  const [briefings, setBriefings] = useState<Set<number>>(new Set())

  const campaignData: CampaignTypes = {
    filterParams,
    searchTerms: filterTermsInput,
    mustIncludeTerms: mustIncludeInput,
    emailCadence,
    proximity,
    exclude,
    generalBriefing,
    briefings,
    objectSetters: {
      setFilterParams,
      setSearchTerms: setFilterTermsInput,
      setMustIncludeTerms: setMustIncludeInput,
      setEmailCadence,
      setUserId: () => {
        /* placeholder for typescript */
      },
      setFilterNameInput: () => {
        /* placeholder for typescript */
      },
      setProximity,
      setExclude,
      setGeneralBriefing,
      setBriefings,
    },
  }

  useEffect(() => {
    setEmailCadence(campaign?.email_cadence || "Unsubscribed")
    setCampaignName(campaign?.name)
    setFilterParams(campaign?.data?.filterParams)
    setFilterTermsInput(campaign?.data?.searchTerms)
    if (campaign?.data?.mustIncludeTerms !== undefined) {
      setMustIncludeInput(campaign?.data?.mustIncludeTerms)
    }
    setProximity(campaign?.data?.proximity)
    setGeneralBriefing(campaign?.automated_general_briefings || false)
    setBriefings(new Set(campaign?.briefings))
  }, [campaign])

  const onClose = () => {
    onCancel()
    setEditDialogOpen(false)
  }

  return (
    <CampaignContext.Provider value={{ campaign: campaign }}>
      <Dialog maxWidth="md" fullWidth onClose={onClose} open={isEditing}>
        <DialogTitle>Update Campaign</DialogTitle>
        <DialogContent>
          <TextField
            value={campaignName}
            onChange={(event) => setCampaignName(event.target.value)}
            disabled={!isEditing}
            label="Campaign Name"
            fullWidth
            sx={{ marginY: 2 }}
          />
          {isEditing && <CampaignEditForm campaignData={campaignData} isEditing={isEditing} isSubscriptionEditForm />}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setIsEditing(false)
              setDialogAction("update")
              setEditDialogOpen(true)
            }}
          >
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <CampaignDialog
        requestStatus={updateSuccess}
        dialogOpen={editDialogOpen}
        dialogAction={dialogAction}
        setDialogOpen={setEditDialogOpen}
        onComplete={onClose}
        campaign={campaign}
        onSubmit={() => {
          updateCampaign({
            filterParams: filterParams,
            searchTerms: filterTermsInput,
            name: campaignName,
            emailCadence: emailCadence,
            mustIncludeInput: mustIncludeInput,
            proximity,
            exclude,
            generalBriefing,
            briefings: Array.from(briefings),
          })
        }}
      />
    </CampaignContext.Provider>
  )
}
