import { Autocomplete, TextField } from "@mui/material"
import { FC, useState } from "react"
import { useFetchMyCampaigns } from "../../../api/campaigns"
import { UserCampaign } from "../../../interfaces/campaign"

export const MyCampaignsAutoComplete: FC<{
  onChangeHandler: (campaign: UserCampaign | null) => void
}> = ({ onChangeHandler }) => {
  const { data: myCampaigns } = useFetchMyCampaigns()
  const [campaignSelected, setCampaignSelected] = useState<UserCampaign | null>(null)

  const sortedCampaigns = myCampaigns?.campaigns.sort((campaign1, campaign2) =>
    campaign1.name.toLowerCase() > campaign2.name.toLowerCase() ? 1 : -1,
  )

  const campaignChangeHandler = (campaign: UserCampaign | null) => {
    setCampaignSelected(campaign)
    onChangeHandler(campaign)
  }

  return (
    <Autocomplete
      options={sortedCampaigns || []}
      value={campaignSelected}
      getOptionLabel={(campaign: UserCampaign) => campaign.name}
      fullWidth
      onChange={(_, campaign) => campaignChangeHandler(campaign)}
      renderInput={(params) => <TextField {...params} label="Select Keywords from Campaign" />}
      renderOption={(props, campaign) => (
        <li {...props} key={campaign.id}>
          {campaign.name}
        </li>
      )}
    />
  )
}
