import { useMutation, useQuery, useQueryClient } from "react-query"
import { City, County, State } from "../interfaces/geography"
import { useAuthAxios } from "./axiosInstance"

export const useFetchGeographies = (userOrgId?: number | string) => {
  const axiosInstance = useAuthAxios()

  if (userOrgId) {
    return useQuery(["geographies", { user_organization_id: userOrgId.toString() }], async () => {
      const response = await (
        await axiosInstance
      ).get<{
        cities: City[]
        counties: County[]
        states: State[]
      }>(`/geographies?user_organiation_id=${userOrgId}`)

      return response.data
    })
  } else {
    return useQuery(["geographies"], async () => {
      const response = await (
        await axiosInstance
      ).get<{
        cities: City[]
        counties: County[]
        states: State[]
      }>("/geographies")

      return response.data
    })
  }
}

export const useFetchGeographiesMap = () => {
  const fetchData = useFetchGeographies()
  if (fetchData.data) {
    return {
      ...fetchData,
      data: {
        cities: new Map(fetchData.data.cities.map((city) => [city.id, city])),
        counties: new Map(fetchData.data.counties.map((county) => [county.id, county])),
        states: new Map(fetchData.data.states.map((state) => [state.id, state])),
      },
    }
  }
  return fetchData
}

export const useCreateGeography: any = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("admin/geographies", params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("geographies")
      },
    },
  )
}

export const useNewCreateGeography: any = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).post("admin/geographies/new_create", params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("geographies")
      },
    },
  )
}

export const useUpdateGeography: any = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).put(`admin/geographies/${params.geography_id}`, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("geographies")
      },
    },
  )
}

export const useDeleteGeography: any = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async (params: any) => {
      return (await axiosInstance).delete(`admin/geographies/${params.geography_id}?type=${params.type}`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("geographies")
      },
    },
  )
}

export const useValidateGeographies = (time: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(
    ["validate", time],
    async () => {
      const response = await (await axiosInstance).get("admin/geographies/validate")
      return response.data
    },
    { enabled: !!time },
  )
}

export const useCreateUpdateGeographies = () => {
  const axiosInstance = useAuthAxios()
  const queryClient = useQueryClient()

  return useMutation(
    async () => {
      return (await axiosInstance).post("admin/geographies/bulk_create_update")
    },
    {
      onSuccess: () => {
        queryClient.fetchInfiniteQuery("geographies")
      },
    },
  )
}
