import { Typography, Avatar, IconButton, Stack, Box } from "@mui/material"
import { FC, useContext } from "react"
import { ShareOptionsContext } from "."

export const SalesforceButton: FC = () => {
  const setActiveShare = useContext(ShareOptionsContext)

  return (
    <IconButton
      sx={{
        borderRadius: 1,
        backgroundColor: "#F9F9F9",
        "&:active": {
          backgroundColor: "neutral.main",
        },
      }}
      size="large"
      disableRipple
      onClick={() => setActiveShare("salesforce")}
    >
      <Stack direction="column" alignItems="center">
        <Avatar
          aria-label="salesforce"
          variant="circular"
          sx={{ backgroundColor: "#ffffff", height: "60px", width: "60px" }}
        >
          <Box
            component="img"
            width="96%"
            src="https://storage.googleapis.com/cloverleaf-ai-public-assets/Salesforce_Corporate_Logo_RGB.png"
          />
        </Avatar>
        <Typography variant="h5" sx={{ color: "black" }}>
          Salesforce
        </Typography>
      </Stack>
    </IconButton>
  )
}
