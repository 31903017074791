import moment from "moment"
import { Configuration } from "../interfaces/dashboard"

export const dateRangeLabel = (configuration: Configuration) => {
  let label = "Not Available"

  if (configuration.date_range) {
    const startDate = moment(configuration.date_range.start).format("MM/DD/YY")
    const endDate = moment(configuration.date_range.end).format("MM/DD/YY")

    if (configuration.day_range && configuration.day_range > 0) label = `Last ${configuration.day_range} days`
    else label = `${startDate} - ${endDate}`
  }

  return label
}
