import { Box } from "@mui/material"
import Lottie from "lottie-react"
import { FC } from "react"
import animationdata from "../../assets/CloverleafLogo.json"

export interface LoadingProps {
  useCloverleafIcon?: boolean
}

const loadingImg = "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg"

const Loading: FC<LoadingProps> = ({ useCloverleafIcon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
      }}
    >
      {useCloverleafIcon ? (
        <Lottie
          animationData={animationdata}
          style={{ width: "100%", height: "100%", maxWidth: "30vw", maxHeight: "30vw" }}
        />
      ) : (
        <img src={loadingImg} alt="Loading..." />
      )}
    </Box>
  )
}

export default Loading
