import { FC, useState, useEffect, useMemo, Dispatch, SetStateAction } from "react"
import { FormControlLabel, Box, Typography, Switch, Stack, Select, MenuItem } from "@mui/material"
import { TagsSearchInput } from "./TagsSearchInput"
import { useFetchCurrentUser, useFetchRecommendedCampaigns } from "../../api/users"
import { FilterParams } from "../Home/interface"
import { TimeDuration } from "./TimeDuration"

export interface AdvancedSearchTypes {
  filterParams: FilterParams
  searchTerms: string[]
  mustIncludeTerms: string[]
  searchInputTagsRef: any
  isSubscriptionEditForm?: boolean
  proximity: number | null
  exclude: boolean
  briefings: Set<number>
  objectSetters: {
    setFilterParams: Dispatch<SetStateAction<FilterParams>>
    setSearchTerms: Dispatch<SetStateAction<string[]>>
    setMustIncludeTerms: Dispatch<SetStateAction<string[]>>
    setSearchInputTagsRef: Dispatch<SetStateAction<any>>
    setProximity: Dispatch<SetStateAction<number | null>>
    setExclude: Dispatch<SetStateAction<boolean>>
    setBriefings: Dispatch<SetStateAction<Set<number>>>
  }
}

export const AdvancedSearch: FC<{ data: AdvancedSearchTypes }> = ({ data }) => {
  const { searchTerms, mustIncludeTerms, isSubscriptionEditForm, proximity, exclude } = data
  const { setSearchTerms, setMustIncludeTerms, setSearchInputTagsRef, setProximity, setExclude } = data.objectSetters
  const [_mustTagsRef, setMustTagsRef] = useState(null)
  const [isChecked, setIsChecked] = useState(
    (mustIncludeTerms !== null && mustIncludeTerms[0] !== "" && mustIncludeTerms.length > 0) ||
      (proximity !== null && proximity > 0),
  )
  const { data: recommendedCampaigns } = useFetchRecommendedCampaigns()
  const { data: currentUser } = useFetchCurrentUser()

  const suggestedTerms = useMemo(() => {
    if (recommendedCampaigns === undefined || currentUser === undefined) {
      return "loading..."
    }

    if (recommendedCampaigns?.length === 0) {
      return `${currentUser?.user_organization_name}, funding, budget, rfp`
    }

    const terms = recommendedCampaigns
      .sort(() => 0.5 - Math.random())[0]
      .data["searchTerms"].sort(() => 0.5 - Math.random())
      .slice(0, 4)
      .join(", ")
    return `${currentUser?.user_organization_name}, ${terms}`
  }, [recommendedCampaigns, currentUser])

  const checkboxChange = () => {
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    if (!isChecked) {
      setMustIncludeTerms([])
      setProximity && setProximity(null)
    }
  }, [isChecked])

  return (
    <>
      <Box>
        <Typography paddingLeft={1} display={isChecked ? "block" : "none"}>
          At least one of these terms:
        </Typography>
        <TagsSearchInput
          tags={searchTerms}
          setTags={setSearchTerms}
          setTagsRef={setSearchInputTagsRef}
          showSubmit
          advancedSearchCheckbox={
            <FormControlLabel
              control={<Switch size="small" checked={isChecked} onChange={checkboxChange} />}
              label="Proximity Search"
            />
          }
          isSubscriptionEditForm={isSubscriptionEditForm}
          placeholder={`What do you want to search? e.g. ${suggestedTerms}`}
        />
      </Box>
      {isChecked && (
        <Stack direction={{ sm: "column", md: "row" }} flexWrap="wrap" spacing={1}>
          <Stack direction="column" flex="1">
            <Typography paddingLeft={1}>Anchor Term:</Typography>
            <TagsSearchInput
              tags={mustIncludeTerms}
              setTags={setMustIncludeTerms}
              setTagsRef={setMustTagsRef}
              showSubmit
              isSubscriptionEditForm={isSubscriptionEditForm}
              isAdvancedSearch
            />
          </Stack>
          <Stack direction="column">
            <Typography paddingLeft={1}>Time range:</Typography>
            <TimeDuration proximity={proximity} setProximity={setProximity} />
          </Stack>
          <Stack>
            <Typography>&nbsp;</Typography>
            <Select
              value={exclude ? "exclude" : "include"}
              onChange={(event) => setExclude(event.target.value === "exclude")}
            >
              <MenuItem value="include">Must Include</MenuItem>
              <MenuItem value="exclude">Must Exclude</MenuItem>
            </Select>
          </Stack>
        </Stack>
      )}
    </>
  )
}
