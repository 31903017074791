import { Box, Button, DialogActions } from "@mui/material"
import { FC } from "react"

export interface DialogActionsContainerProps {
  handleOnClose: () => void
  handleOnBack?: () => void
  handleOnNext?: () => void
  handleSubmit?: () => void // Only used in the last step
}

export const DialogActionsContainer: FC<DialogActionsContainerProps> = ({
  handleOnClose,
  handleOnBack,
  handleOnNext,
  handleSubmit,
}) => {
  return (
    <DialogActions>
      <Box>
        <Button onClick={handleOnClose}>Cancel</Button>
        <Button onClick={handleOnBack && handleOnBack} disabled={!handleOnBack}>
          Back
        </Button>
        {handleSubmit ? (
          <Button onClick={handleSubmit} disabled={false}>
            Submit
          </Button>
        ) : (
          <Button onClick={handleOnNext && handleOnNext} disabled={!handleOnNext}>
            Next
          </Button>
        )}
      </Box>
    </DialogActions>
  )
}
