import { FC, useEffect, useState } from "react"
import { Typography, TextField, Stack, Box } from "@mui/material"
import { useParams } from "react-router-dom"
import { IOrganizationRouteParams } from "@api/interfaces"
import { ActionsMenu } from "./MeetingTable/ActionsMenu"
import { FetchedOrgMeeting, useFetchOrganizationMeetings } from "@api/admin/organizations"
import { DataTable } from "@components/shared/DataTable"
import { meetingViewColumns, transcriptionJobViewColumns } from "./MeetingTable/columns"
import { ClientSidePagination } from "@components/shared/DataTable/ClientSidePagination"
import { TableSettings } from "./MeetingTable/TableSettings"
import { ViewOptsInterface, ViewOptsConfigInterface } from "./interface"
import { ShowOpportunitiesCell } from "./ShowOpportunityCell"

export const MeetingsContainer: FC = () => {
  const { organizationId } = useParams<IOrganizationRouteParams>()
  const [selectedMeetings, setSelectedMeetings] = useState<number[]>([])
  const { data: meetingsData, isRefetching, isLoading } = useFetchOrganizationMeetings({ id: parseInt(organizationId) })
  const [nameFilter, setNameFilter] = useState("")
  const [paginatedData, setPaginatedData] = useState<FetchedOrgMeeting[]>([])
  const [filteredData, setFilteredData] = useState<FetchedOrgMeeting[]>([])
  const [columns, setColumns] = useState(transcriptionJobViewColumns)
  const [viewOpts, setViewOpts] = useState<ViewOptsInterface>({
    showRefresh: false,
    finishedOnly: false,
    incompleteOnly: false,
    spamOnly: false,
    transcriptionJobView: true,
    meetingView: false,
    all: true,
  })

  const updateView = <K extends keyof ViewOptsInterface>(key: K, opts?: ViewOptsInterface) => {
    let newOpts = { ...viewOpts }
    if (opts) {
      newOpts = opts
    }
    newOpts[key] = !viewOpts[key]
    setViewOpts(newOpts)
  }

  const clearViews = <K extends keyof ViewOptsInterface>(key: K, configType: ConfigTypes) => {
    const viewsToClear = getConfigsByType(configType)
    const newOpts = { ...viewOpts }
    viewsToClear.forEach((config) => {
      newOpts[config.id] = false
    })
    return newOpts
  }

  const changeColumns = <K extends keyof ViewOptsInterface>(key: K, configType: ConfigTypes) => {
    if (configType === ConfigTypes.view) {
      setColumns(optionsConfig.find((config) => config.id === key)?.column)
    }
  }

  const clearSelections = (configType: ConfigTypes) => {
    if (configType === ConfigTypes.filter) {
      setSelectedMeetings([])
    }
  }

  const selectAction = <K extends keyof ViewOptsInterface>(key: K, configType: ConfigTypes) => {
    if (viewOpts[key] === true) {
      return
    }
    const newOpts = clearViews(key, configType)
    updateView(key, newOpts)
    changeColumns(key, configType)
    setSelectedMeetings([])
    clearSelections(configType)
  }

  const enum ConfigTypes {
    view = "view",
    filter = "filter",
    other = "other",
  }

  const optionsConfig: ViewOptsConfigInterface[] = [
    {
      id: "showRefresh",
      label: "Show Refresh",
      value: viewOpts.showRefresh,
      setValue: () => updateView("showRefresh"),
      type: ConfigTypes.other,
    },
    {
      id: "all",
      label: "Show All",
      value: viewOpts.all,
      setValue: () => selectAction("all", ConfigTypes.filter),
      type: ConfigTypes.filter,
      filterFunc: (_meeting: unknown) => true,
    },
    {
      id: "incompleteOnly",
      label: "Show Incomplete",
      value: viewOpts.incompleteOnly,
      setValue: () => selectAction("incompleteOnly", ConfigTypes.filter),
      type: ConfigTypes.filter,
      filterFunc: (meeting: FetchedOrgMeeting) => !meeting.transcription_job?.finished_at,
    },
    {
      id: "finishedOnly",
      label: "Show Finished",
      value: viewOpts.finishedOnly,
      setValue: () => selectAction("finishedOnly", ConfigTypes.filter),
      type: ConfigTypes.filter,
      filterFunc: (meeting: FetchedOrgMeeting) => meeting.transcription_job?.finished_at !== null,
    },
    {
      id: "spamOnly",
      label: "Show Spam",
      value: viewOpts.spamOnly,
      setValue: () => selectAction("spamOnly", ConfigTypes.filter),
      type: ConfigTypes.filter,
      filterFunc: (meeting: FetchedOrgMeeting) => meeting.is_spam === true,
    },
    {
      id: "meetingView",
      label: "Meeting View",
      value: viewOpts.meetingView,
      setValue: () => selectAction("meetingView", ConfigTypes.view),
      type: ConfigTypes.view,
      column: meetingViewColumns,
    },
    {
      id: "transcriptionJobView",
      label: "Transcription Job View",
      value: viewOpts.transcriptionJobView,
      setValue: () => selectAction("transcriptionJobView", ConfigTypes.view),
      type: ConfigTypes.view,
      column: transcriptionJobViewColumns,
    },
  ]

  const getConfigsByType = (type: ConfigTypes) => optionsConfig.filter((config) => config.type === type)

  useEffect(() => {
    const config = getConfigsByType(ConfigTypes.filter).find((config) => config.value === true)
    const meetings = meetingsData?.meetings
      ?.map((meeting) => ({
        ...meeting,
        opportunities: meeting.opportunities_count > 0 ? <ShowOpportunitiesCell id={meeting.id} /> : null,
      }))
      .filter((meeting) => {
        const searchFilter = nameFilter === "" || meeting.title.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0
        const configFilter = config?.filterFunc ? config.filterFunc(meeting) : true
        return searchFilter && configFilter
      })
    setFilteredData(meetings || [])
  }, [meetingsData?.meetings, nameFilter, viewOpts])

  const tableSettings = new TableSettings(optionsConfig)

  return (
    <Stack paddingTop={3} spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack justifyContent="center">
          <Typography variant="h2">Settings</Typography>
          {tableSettings.getCheckBoxesByType("view")}
          {tableSettings.getCheckBoxesByType("filter")}
          {tableSettings.getCheckBoxesByType("other")}
        </Stack>
        <Typography variant="h2">Meetings</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box width={200}>
          <ActionsMenu meetingIds={selectedMeetings} />
        </Box>
        <TextField
          value={nameFilter}
          onChange={(event) => setNameFilter(event.target.value)}
          label="Filter by Name"
          variant="outlined"
          fullWidth
        />
      </Stack>
      <DataTable
        data={paginatedData}
        columns={columns}
        columnKey="id"
        selected={selectedMeetings}
        setSelected={setSelectedMeetings}
        virtualize={false}
        showSkeleton={(isRefetching || isLoading) && viewOpts.showRefresh}
        size="small"
      />
      <ClientSidePagination tableData={filteredData || []} setPaginatedData={setPaginatedData} />
    </Stack>
  )
}
