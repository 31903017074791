import { FC, useState, useContext, useMemo, ReactNode } from "react"
import { Chip, Divider, Link, Stack, CircularProgress, Typography, Box } from "@mui/material"
import { Transcript, TranscriptMeetingHits } from "../../../interfaces/transcript"
import { ClipShareContext } from "../../Promo/share/ClipShare"
import { useLocation } from "react-router-dom"
import { TranscriptList } from "./TranscriptList"
import { TranscriptListItem } from "./TranscriptListItem"
import { useAuthAxiosNoAuth } from "../../../api/axiosInstanceNoAuth"
import { getWordTime } from "../../../functions/transcript-words-array"
import { SearchTermChips } from "../../MeetingDetail/TranscriptsTab/SearchTermChips"
import { FetchedMeeting } from "../../../api/meetings"

const TranscriptListContainerItem: FC<{
  transcripts: [string, Transcript][]
  setVideoStartTime: (startTime: any) => void
}> = ({ transcripts, setVideoStartTime }) => {
  return (
    <Stack>
      <TranscriptList transcripts={transcripts} setVideoStartTime={setVideoStartTime} />
    </Stack>
  )
}

export const TranscriptListContainer: FC<{
  setVideoStartTime: (startTime: any) => void
  meeting: FetchedMeeting
  transcriptHits: TranscriptMeetingHits
  terms: string[]
}> = ({ setVideoStartTime, meeting, transcriptHits, terms }) => {
  const [loadingPdf, setLoadingPdf] = useState(false)
  const searchString = useLocation().search
  const params = new URLSearchParams(searchString)
  const selectedTranscriptId = params.get("transcript_id")
  let transcripts: [string, Transcript, number][] = []
  const searchTermHits = Object.fromEntries(
    transcriptHits.terms.map((transcriptHit) => [transcriptHit.term, transcriptHit.hits]),
  )

  transcriptHits.terms.forEach((transcriptHit) => {
    if (transcriptHit.hits > 0) {
      transcriptHit.transcripts.forEach((transcript) => {
        const wordTime = getWordTime(transcriptHit.term, transcript)
        transcripts.push([transcriptHit.term, transcript, wordTime])
      })
    }
    transcripts = transcripts.sort((a, b) => a[2] - b[2])
  })

  const transcriptsByPhraseComponent = !transcripts ? (
    "loading..."
  ) : (
    <Box sx={{ height: "100%", overflowY: "scroll" }}>
      {transcripts.length > 0 && (
        <TranscriptListContainerItem
          transcripts={transcripts.map((transcript) => [transcript[0], transcript[1]])}
          setVideoStartTime={setVideoStartTime}
        />
      )}
    </Box>
  )

  const axiosInstance = useAuthAxiosNoAuth()
  const { description } = useContext(ClipShareContext)
  const activeTerm = params.get("active-term")

  const selectedIdComponent: ReactNode = useMemo(() => {
    if (selectedTranscriptId) {
      let selectedTranscript: Transcript | undefined = undefined
      transcriptHits.terms.forEach((termHit) => {
        const transcript = termHit.transcripts.find((transcript) => {
          if (typeof selectedTranscriptId === "number") {
            return transcript.id === parseInt(selectedTranscriptId)
          }
          return transcript.id === selectedTranscriptId
        })
        if (transcript) {
          selectedTranscript = transcript
          return transcript
        }
      })

      if (selectedTranscript) {
        if (activeTerm) {
          return (
            <Box>
              <Divider sx={{ marginY: 1 }} />
              <TranscriptListItem
                searchTerm={activeTerm}
                transcript={selectedTranscript}
                setVideoStartTime={setVideoStartTime}
                isSelected
              />
              {description.trim().length !== 0 && (
                <Box fontWeight={40} border={1} padding={2}>
                  <Typography variant="h5" fontWeight="bold">
                    Notes
                  </Typography>
                  <Typography marginTop={1} fontWeight={500} variant="body2" whiteSpace="pre-wrap" color="black">
                    {description}
                  </Typography>
                </Box>
              )}
            </Box>
          )
        }
      }
    }
    return null
  }, [selectedTranscriptId, transcriptHits])

  return (
    <Stack height={"100%"}>
      <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" rowGap={2} mt={0}>
        {terms.length ? (
          <>
            <Chip
              color="primary"
              label={!transcriptHits ? "loading..." : `Total matches: ${transcriptHits.total_hits}`}
            />
            <SearchTermChips termHits={searchTermHits} />
          </>
        ) : null}
        <Link
          sx={{ cursor: "pointer" }}
          onClick={async () => {
            setLoadingPdf(true)
            const response = await (
              await axiosInstance
            ).get(`promotions/meetings/${meeting.id}/pdf`, {
              responseType: "blob",
            })
            setLoadingPdf(false)
            const href = URL.createObjectURL(response.data)

            const link = document.createElement("a")
            link.href = href
            link.setAttribute("download", `${meeting.id}_${meeting.title}.pdf`)
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
          }}
          target="_blank"
        >
          Full Transcript PDF
          {loadingPdf && <CircularProgress size="1em" sx={{ marginLeft: "1ch" }} />}
        </Link>
      </Stack>
      {selectedIdComponent}
      <Divider sx={{ marginY: 1 }} />
      {transcriptsByPhraseComponent}
    </Stack>
  )
}
