import { FormControlLabel, Checkbox, Stack, Box } from "@mui/material"
import { FC, useState, useMemo } from "react"
import { useFetchOrganizationsMonitors } from "../../../api/admin/organizations_monitors"
import { DataTable } from "../../shared/DataTable"
import { SearchTextField } from "../../shared/SearchTextField"
import { organizationsColumns as columns } from "./monitorColumns"

export const OrganizationsMonitor: FC = () => {
  const { data: monitorsData } = useFetchOrganizationsMonitors()
  const [search, setSearch] = useState("")
  const [checkedError, setCheckedError] = useState(true)
  const [checkedAutoUpdate, setCheckedAutoUpdate] = useState(true)

  const isError = (status: string) => status !== "ok"

  const monitors: any = useMemo(() => {
    return monitorsData?.monitors.filter((monitor) => {
      const searchMatch =
        monitor?.organization?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        monitor?.organization?.id?.toString()?.includes(search.toLowerCase())
      const errorMatch = checkedError === isError(monitor.status)
      let automaticUpdate = monitor?.organization?.automatic_update
      if (automaticUpdate === null) {
        automaticUpdate = false
      }
      const autoUpdateMatch = checkedAutoUpdate === automaticUpdate

      return searchMatch && errorMatch && autoUpdateMatch
    })
  }, [monitorsData?.monitors, checkedError, checkedAutoUpdate, search])

  return (
    <>
      <Stack direction="row" marginY={2} spacing={3}>
        <SearchTextField
          label="Search Org Name or Org Id"
          size="small"
          sx={{ width: "33.333%" }}
          setSearch={setSearch}
          search={search}
        />
        <FormControlLabel
          label="Errors Only"
          control={<Checkbox checked={checkedError} onChange={() => setCheckedError(!checkedError)} />}
        />
        <FormControlLabel
          label="Automatic Update On"
          control={<Checkbox checked={checkedAutoUpdate} onChange={() => setCheckedAutoUpdate(!checkedAutoUpdate)} />}
        />
      </Stack>
      <Box flex="1">
        <DataTable
          data={monitors}
          columnKey="id"
          columns={columns}
          defaultSort="down_date"
          tableStyle={{ width: "auto" }}
          virtualize={false}
        />
      </Box>
    </>
  )
}
