import { useQuery } from "react-query"
import { useAuthAxios } from "./axiosInstance"

const prefix = "/persons"

export const useFetchPerson = (id: number) => {
  const axiosInstance = useAuthAxios()

  return useQuery(["person", id], async () => {
    const response = await (await axiosInstance).get(`${prefix}/${id}`)
    return response.data
  })
}
